import { Component, ViewChild } from '@angular/core';
import {
  IToolPanel,
} from '@ag-grid-community/core';
import { ModelToleranceComponent } from '../../components/tradeorder/shared/modeltolerance.component';
import { ModelAnalysisComponent } from '../../components/tradeorder/shared/modelanalysis.component';

@Component({
  selector: 'eclipse-model-analyze-tool-panel',
  templateUrl: './model-analyze-tool-panel.component.html',
})
export class ModelAnalyzeToolPanel implements IToolPanel {
  @ViewChild('modelTolerance') modelTolerance: ModelToleranceComponent;
  @ViewChild('modelAnalysis') modelAnalysis: ModelAnalysisComponent;

  currentTab: 'modelTolerance' | 'modelAnalysis' = 'modelTolerance';
  params;

  agInit(params): void {
    this.params = params;
  }

  refresh() {
    // unused in this implementation, but required by IToolPanel.
  }
}
