import {Component} from '@angular/core';
import {IAlert} from '../../viewModels/alert';
import {AlertService} from '../../core';

declare var $: any;


@Component({
  selector: 'eclipse-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  alerts: IAlert[];
  alertSubscription: any;
  alertTimer: any;

  constructor(private _alertService: AlertService) {
  }

  ngOnInit() {
    this.alertSubscription = this._alertService.alert.subscribe((data: any) => {
      this.addMessage(data);
    });
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }

  /** show alert box */
  showAlert() {
    if ($('.db-message-bar').hasClass('db-message-bar-circle')) {
      $('.db-message-bar').removeClass('db-message-bar-circle');
    }
    $('.db-message-bar').animate({top: 20}, 500);
    this.startTimer();
  }

  addMessage(models: IAlert[]) {
    // eslint-disable-next-line eqeqeq
    if (models != undefined) {
      this.alerts = models;
      this.showAlert();
    }
  }

  startTimer() {
    this.alertTimer = setTimeout(() => {
      this.closeAlert();
    }, 5000);
  }

  clearTimer() {
    clearTimeout(this.alertTimer);
    this.alertTimer = undefined;
  }

  closeAlert() {
    $('.db-message-bar').animate({top: -1000}, 500);
  }
}
