<div class="pull-left analytics-count-update" *ngIf="showNotificationMessage">
  <div role="alert"
       class="update-message">
    <div class="p-message-wrapper ng-tns-c149-45">
      <em class="fas fa-info-circle"></em>
      <span>
        Analytics updated by another user on {{needAnalyticsUpdateCount}} {{moduleName}}(s).  Please refresh the grid to receive the updated information.
      </span>
    </div>
  </div>
</div>
