<p-dialog [(visible)]="showWarningDialog" header="Warning" modal="true" [draggable]="false"
          [resizable]="false" class="ui-dialog-xl">
  <div>
    <span class="text-danger">{{popupValidationMessage}}</span>
  </div>
  <div class="row">
    <div class="col-md-11">
      <div class="agcontainer">
        <ag-grid-angular #agGrid style="width: 100%; height: 350px;" class="ag-theme-alpine"
                         [gridOptions]="validationsResultGridOptions" rowSelection="single" [columnDefs]="validationsResultColDefs"
                         [rowData]="validationResultGridData" suppressContextMenu="true"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="col-md-1">
      <a href="javascript:void(0)" class="text-info" (click)="exportToExcel()">
        <i class="far fa-file-excel" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <p-footer>
    <button pButton type="button" (click)="refreshValidationGrid()" label="Refresh" icon="fas fa-refresh"></button>
    <button pButton type="button" [disabled]="disableContinueBtn" (click)="continueWithNextRule()" label="Continue"></button>
    <button pButton class="p-button-secondary" data-dismiss="modal" type="button" (click)="cancel()" label="Cancel"></button>
  </p-footer>
</p-dialog>
