import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISecurity } from '../../../models/security';
import { BaseComponent } from '../../../core/base.component';
import { SecurityService } from '../../../services/security.service';
import { AutocompleteHubService } from '../shared/autocomplete-hub.service';
import { Subscription } from 'rxjs';
import { SECURITY_STATUS } from '../../../libs/security.constants';

@Component({
  selector: 'eclipse-security-autocomplete',
  templateUrl: './security-autocomplete.component.html',
  styleUrls: ['./security-autocomplete.component.scss']
})
export class SecurityAutocompleteComponent extends BaseComponent {

  selectedSecurity: ISecurity;
  filteredSecurityResult: ISecurity[];
  private clearAutoSearchDataSubscription: Subscription;
  @Input() filterOutSecurityIds: number[] = [];
  @Input() isMoneyMarketSecurity: boolean;
  @Input() disableSecuritySearch: boolean;
  @Input() filterCustodialCash: boolean;
  @Output() onSearchSecuritySelect = new EventEmitter<ISecurity>();

  constructor(private readonly _securityService: SecurityService,
    private readonly _autocompleteHubService: AutocompleteHubService) {
    super();
    this.isMoneyMarketSecurity = null;
    this.disableSecuritySearch = false;
    this.filterCustodialCash = false;
  }

  ngOnInit(): void {
    this.subscribeClearAutoSearchData();
  }

  ngOnDestroy(): void {
    this.clearAutoSearchDataSubscription.unsubscribe();
  }

  private subscribeClearAutoSearchData(): void {
    this.clearAutoSearchDataSubscription = this._autocompleteHubService.clearSearchData.subscribe(() => {
      this.selectedSecurity = null;
    });
  }

  autoSecuritySearch(event: any): void {
    this._securityService.searchSecurityFromOrionEclipse(event.query, SECURITY_STATUS.OPEN, this.isMoneyMarketSecurity)
      .subscribe((securitiesResult: ISecurity[]) => {
        securitiesResult.forEach(x => x.nameSymbol = `${x.name} (${x.symbol})`);

        this.filteredSecurityResult = securitiesResult;
        this.filteredSecurityResult = this.filteredSecurityResult.filter(security => !this.filterOutSecurityIds.includes(security.id));

        if (this.filterCustodialCash) {
          this.filteredSecurityResult = this.filteredSecurityResult.filter(security => security.custodialCash === 0);
        }
      });
  }

  onSecuritySelect(item: ISecurity): void {
    this.onSearchSecuritySelect.emit(item);
    this._autocompleteHubService.requestToGetSelectedSecurity(item);
  }
}
