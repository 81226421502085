<p-autoComplete [(ngModel)]="selectedItem" [suggestions]="suggestions" placeholder="Portfolio ID / Portfolio Name / Tags or Account Number"
                (completeMethod)="loadSuggestions($event)" (onSelect)="onPortfolioSelect($event)" field="selectedItemFormat" class="autocomplete-search fs-mask"
                [minLength]="1">
  <ng-template let-selectedItem pTemplate="item">
    <ul class="ui-helper-clearfix show-hollding-dropdown">
      <li class="vertical-center">
        <i class="fas fa-cubes entity-icon-portfolio" aria-hidden="true"></i>
        <span class="fs-mask">{{selectedItem.displayFormat}}</span>
      </li>
    </ul>
  </ng-template>
</p-autoComplete>
