<div [ngSwitch]="displayPermission">
  <div *ngSwitchCase="true">
    <div class="firm-security-setting-header">
      <div class="form-inline">
        <div class="form-group">
          <select class="form-control watermarked gray" [(ngModel)]="selectedSecurityTypeId" (change)="handleSelectedSecurityType($event.target.value)">
            <option  value="null" selected>Please Select</option>
            <option *ngFor="let st of securityTypes" value={{st.id}}>{{st.name}}</option>
          </select>
          <button type="button" pButton title="Set Preferences" (click)="setPrefPopup(selectedSecurityType)" class="m-l-10"
                  [disabled]="btnDisableSetPref" label="Add"></button>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="true" class="grid-control">
    <ag-grid-angular #agGrid style="width: 100%; height: 100px;" class="ag-theme-alpine"
                     [gridOptions]="gridOptions"
                     rowSelection="single" [columnDefs]="columnDefs" [rowData]="PrefrowData" (rowClicked)="rowSelected($event)"
                     [getContextMenuItems]="getContextMenuItems" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <div *ngSwitchCase="false" class="grid-control">
    <ag-grid-angular class="ag-theme-alpine" #agGrid style="width: 100%; height: 100px;" [columnDefs]="columnDefs" [rowData]="PrefrowData"
                     [gridOptions]="gridOptions" [suppressContextMenu]="true" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <p-dialog header="Redemption Fee Preferences" modal="true" [resizable]="true" [(visible)]="displayRedemptionFeePref"
            class="ui-dialog-xl">
    <div class="col-sm-12 security-preferences-modal-body">
      <div class="form-group">
        Security Type:<span class="margin-left-05">{{selectedSecurityType}}</span>
      </div>

      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Redemption Fee</legend>
        <div class='row'>
          <div class="col-sm-4">
            <div class="form-group">
              Redemption Fee Amount:
              <div class="form-inline">
                <select id="ddlType" [(ngModel)]="type" (change)="onFeeTypeChange($event)" class="form-control">
                  <option value="$">$</option>
                  <option value="%">%</option>
                </select>
                <input placeholder="Enter amount" *ngIf="type=='$'" type="number" min="0" value="0" step="0.01" class="form-control" [(ngModel)]="redemptionfeePref.redemptionFeeAmount"
                       (keypress)="setValid($event)" />
                <input placeholder="Enter amount" *ngIf="type=='%'" type="number" min="0"  value="0" step="0.01" class="form-control" [(ngModel)]="redemptionfeePref.redemptionFeeAmount"
                       (keyup)="validatePercent(redemptionfeePref)" (keypress)="setValid($event)" />
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              Redemption Fee days:
              <input placeholder="Enter days" type="number" min="0" value="0" step="1" class="form-control" [(ngModel)]="redemptionfeePref.redemptionFeeDays"
                     (keypress)="setValid($event)" />
            </div>
          </div>
        </div>
        <div class='row' *ngIf="redemptionfeePref.redemptionFeeType === '%'">
          <div class='col-sm-4'>
            <div class="form-group">
              Minimum Redemption Fee ($):
              <input placeholder="Enter Amount" type="number" min="0" value="0" step="1" class="form-control" [(ngModel)]="redemptionfeePref.redemptionFeeMinAmount"
                     (keyup)="validateMinAmount(redemptionfeePref)" (keypress)="setValid($event)" />
            </div>
          </div>
          <div class='col-sm-4'>
            <div class="form-group">
              Maximum Redemption Fee ($):
              <input placeholder="Enter Amount" type="number" min="0" value="0" step="1" class="form-control" [(ngModel)]="redemptionfeePref.redemptionFeeMaxAmount"
                     (focusout)="validateMaxAmount(redemptionfeePref)" (keypress)="setValid($event)" />
            </div>
          </div>
          <div *ngIf="displayPermission == 'false'" class="col-sm-3">
            <div class="form-group">
              Inherited from:
              <label>{{redemptionfeePref.inheritedFrom}}</label>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <p-footer>
      <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="cancel()" label="Cancel"></button>
      <button type="button" pButton icon="fas fa-check" (click)="addtoGrid()" label="Add"></button>
    </p-footer>
  </p-dialog>
</div>
