import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {SidebarService} from './services/sidebar.service';
import {ILink, ISideBarMenuItem} from '../../models/sidebar';
import {IsActiveMatchOptions, Router} from '@angular/router';
import {TradeService} from 'src/app/services/trade.service';

@Component({
  selector: 'orion-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
})
export class SidebarNavComponent implements OnInit {
  @Output() sidebarChanged = new EventEmitter<boolean>();
  @ViewChild('sidebarBtn') sidebarButtonEl: ElementRef;

  hasCompactMenu: boolean = false;
  sideBarMenus: ISideBarMenuItem[] = [];

  constructor(private sidebarService: SidebarService, private tradeService: TradeService, private readonly _router: Router) {
  }

  ngOnInit() {
    this.loadAppMenu();
  }

  changeMenu() {
    this.hasCompactMenu = !this.hasCompactMenu;
    this.closeAllMenus();
    this.tradeService.setTradeCountsVisible(false);
    this.sidebarChanged.emit(this.hasCompactMenu);
  }

  /**
   * Menu link clicked.  Expand/collapse the menu item if it has child items.
   * @param item
   * @param event
   */
  onLinkClick(item: ISideBarMenuItem, event = null) {
    // if an event was provided, stop the click propagation on it to prevent
    // the underlying menu item from processing the event and toggling the expanded state
    if (event) {
      if (!item.ignoreLink) {
        this._router.navigateByUrl(item.link);
        return;
      } else {
        event.stopPropagation();
      }
    }

    const isTradeCountsVisible = !this.hasCompactMenu && !this.isClosingTradesParentMenu(item.id, item.hasOpen) && this.isTradesMenu(item);
    this.tradeService.setTradeCountsVisible(isTradeCountsVisible);

    // if the menu item is already open, toggle it closed
    if (item.hasOpen) {
      item.hasOpen = false;
      return;
    }

    // close all other menus.
    // this ensures only one menu item is open at a time.
    this.closeAllMenus(item.parent ? item.parent.children : null);

    if (item.children) {
      item.hasOpen = !item.hasOpen;
    }
  }

  /**
   * Closes all expanded menu items.
   * @param menu
   */
  closeAllMenus(menu?: ISideBarMenuItem[]) {
    if (!menu) {
      menu = this.sideBarMenus;
    }
    menu.forEach(menuItem => {
      menuItem.hasOpen = false;
      if (menuItem.children) {
        this.closeAllMenus(menuItem.children);
      }
    });
  }

  /**
   * Displays a sub-menu when in collapsed mode
   * @param item
   */
  showSubMenu(item: ISideBarMenuItem) {
    if (item.children && this.hasCompactMenu) {
      if (this.isTradesParentMenu(item.id) && !item.hasOpen) {
        this.tradeService.tryFetchTradeCounts();
      }
      item.hasOpen = true;
    }
  }

  /**
   * Hides a sub-menu when in collapsed mode
   * @param item
   */
  closeSubMenu(item: ISideBarMenuItem) {
    if (!item.childActive && this.hasCompactMenu) {
      item.hasOpen = item.childActive;
    }
  }

  /**
   * Displays a sub-menu when in collapsed mode and the mouse is hovering over the parent
   * @param item
   */
  onMouseOverChildMenu(item: ISideBarMenuItem) {
    if (!item.childActive && this.hasCompactMenu) {
      item.hasOpen = true;
    }
  }

  /**
   * Hides a sub-menu when in collapsed mode and the mouse has stopped hovering over the parent
   * @param item
   */
  onMouseOutChildMenu(item: ISideBarMenuItem) {
    if (!item.childActive && this.hasCompactMenu) {
      item.hasOpen = item.childActive = false;
    }
  }

  private isClosingTradesParentMenu(navItemId: string, isNavItemOpen?: boolean): boolean {
    return this.isTradesParentMenu(navItemId) && isNavItemOpen;
  }

  private isTradesParentMenu(id: string): boolean {
    return id === 'trades';
  }

  private isTradesMenu(menuItem: ISideBarMenuItem): boolean {
    let isTrades = false;
    let currentMenuItem = menuItem;
    do {
      isTrades = this.isTradesParentMenu(currentMenuItem.id);
      currentMenuItem = currentMenuItem.parent;
    } while (!isTrades && currentMenuItem);
    return isTrades;
  }

  /**
   * Loads the menu items
   * @private
   */
  private loadAppMenu() {
    this.sidebarService.getNavigationLinks().subscribe(links => {
      this.tradeService.setTradeCountsVisible(false);
      this.setSideBarMenu(links);
    });
  }

  /**
   * Creates the sidebar menu items based on the given links.
   * @param links
   * @private
   */
  private setSideBarMenu(links: ILink[]) {
    this.sideBarMenus = this.sidebarService.createMenuFromLinks(links);
  }

  /**
   * Checks the item, item alternate links, and item children
   * and returns true if any of the links match the active route.
   * @param item
   */
  public isActive(item: ISideBarMenuItem): boolean {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'ignored',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };

    // is the item link active?
    if (item.link && this._router.isActive(item.link, matchOptions)) {
      return true;
    }

    // are any of the items alternate links active?
    const alternatesActive = item.alternateLinks?.filter(alternate => this._router.isActive(alternate, matchOptions));
    if (alternatesActive?.length) {
      return true;
    }

    // are any of the items children active
    if (item.children?.length && item.children.some(childItem => this.isActive(childItem))) {
      return true;
    }
    return false;
  }
}
