<div *ngIf="showPageContent">
  <ag-grid-angular #agGrid style="width:100%;height:400px;" class="ag-theme-alpine grid-height-autosizefour"
                   [gridOptions]="gridOptions"
                   rowSelection="single"
                   [context]="gridContext"
                   [columnDefs]="columnDefs"
                   [rowData]="targetedSecurities"
                   (modelUpdated)="onModelUpdated()"
                   (gridReady)="onGridReady($event)"
                   [getContextMenuItems]="getContextMenuItems">
  </ag-grid-angular>

  <eclipse-security-preference-add-edit
    [preferenceLevel]="preferenceLevel"
    [securityPreferencesHiddenMap]="securityPreferencesHiddenMap"
    (addPreferenceToGrid)="addToGrid($event)">
  </eclipse-security-preference-add-edit>
</div>

<p-dialog header="Security Preferences" [resizable]="true" [(visible)]="displayEquivalentDialog"
          [style]="{'width': '1050px'}" (onHide)="cancel()">

  <eclipse-security-preference-equivalent (onEquivalentUpdate)="addToGrid($event)"></eclipse-security-preference-equivalent>
  <p-footer>
    <button type="button" icon="fas fa-times" pButton class="p-button-secondary" (click)="cancel()" label="Cancel"></button>
    <button type="button" icon="fas fa-plus" pButton (click)="addEquivalent()" label="Add"></button>
  </p-footer>
</p-dialog>
