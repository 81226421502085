import {Component} from '@angular/core';
import {BaseComponent} from '../core/base.component';
import {Router} from '@angular/router';

@Component({
  selector: 'eclipse-login-as',
  template: ''
})
export class LoginAsComponent extends BaseComponent {

  constructor(private _router: Router) {
    super();
  }

  ngOnInit() {
    this._router.navigate(['eclipse']);
  }

}
