<oe-spinner></oe-spinner>
<div id="main-app-content">
  <router-outlet></router-outlet>
</div>
<p-dialog header="Error" [(visible)]="showError" modal="true" [draggable]=false
          [resizable]=false class="ui-dialog-md" [closable]="showClose" [closeOnEscape]="false">
  <div>
    <div class="form-group">
      <div *ngIf="statusBodyTwo && statusBodyTwo != ''" class="status-body-two">{{statusBodyTwo}}</div>
      <div *ngIf="!statusBodyTwo || statusBodyTwo === '' || status === 500">
        <label>{{statusText}}</label><br/>
        <label>{{statusBody}}</label><br/>
      </div>
      <label>Reference Id: {{requestId}} </label>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton (click)="errorOK()" label="Ok"></button>
  </p-footer>
</p-dialog>

<p-dialog header="New Version Available" [(visible)]="showNewVersionAvailable" modal="true" [draggable]=false
          [resizable]=false class="ui-dialog-md" [closable]="true" [closeOnEscape]="true">
  A new version of the application is now available.  Please refresh your browser!
  <p-footer>
    <button type="button" pButton (click)="refreshPage()" label="Refresh Now"></button>
    <button type="button" pButton class="p-button-secondary" (click)="showNewVersionAvailable = false" label="Close"></button>
  </p-footer>
</p-dialog>
