import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import { ISavedGridView, ISavedViewResult, IView } from '../models/views';
import {Observable} from 'rxjs';
import { ViewTypeEnum } from '../libs/app.constants';

export type SavedViewsByTypeRequestOptions = {
  simple?: boolean;
  filterRequired?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class ViewsService {
  // v2 Saved Views
  private readonly _savedViewsEndpoint = 'v2/SavedView';
  private readonly _viewsEndPoint = 'settings/views';

  constructor(private _httpClient: OEHttpClient) { }

  /** To add View */
  addView(view: IView): Observable<ISavedGridView> {
    return this._httpClient.postData(this._viewsEndPoint, view);
  }

  /** To get views list by view type Id */
  getSavedViewsByTypeId(viewTypeId: number, options: SavedViewsByTypeRequestOptions = null): Observable<ISavedGridView[]> {
    const params = [`type=${viewTypeId}`];
    if (options?.simple) {
      params.push('simple=1');
    }
    if (options?.filterRequired) {
      params.push('filterRequired=1');
    }
    return this._httpClient.getData(`${this._viewsEndPoint}?${params.join('&')}`);
  }

  getGridViews(viewTypes: ViewTypeEnum[]): Observable<ISavedGridView[]> {
    return this._httpClient.postData(`${this._savedViewsEndpoint}/ViewType`, viewTypes);
  }

  /** To get View */
  getView(viewId: number): Observable<ISavedGridView> {
    return this._httpClient.getData(`${this._viewsEndPoint}/${viewId}`);
  }

  /** To delete view */
  deleteView(viewId: number) {
    return this._httpClient.deleteData(`${this._viewsEndPoint}/${viewId}`);
  }

  deleteGridView(viewId: number): Observable<boolean> {
    return this._httpClient.deleteData(`${this._savedViewsEndpoint}/${viewId}`);
  }

  deleteGridViews(viewIds: number[]): Observable<boolean> {
    return this._httpClient.postData(`${this._savedViewsEndpoint}/delete`, viewIds);
  }

  /** To update View */
  updateView(view: IView): Observable<ISavedGridView> {
    return this._httpClient.updateData(`${this._viewsEndPoint}/${view.id}`, view);
  }

  upsertGridViews(views: ISavedGridView[]): Observable<ISavedGridView[]> {
    return this._httpClient.postData(`${this._savedViewsEndpoint}`, views);
  }

  /** To set selected view as default view */
  setAsDefaultView(viewId: number, viewTypeId: number) {
    return this._httpClient.postData(`${this._viewsEndPoint}/defaultView/${viewId}?type=${viewTypeId}`, {});
  }

  /** To get the view by name and viewTypeId if exists*/
  checkViewNameExistence(viewName: string, viewTypeId: number) {
    return this._httpClient.getData(`${this._viewsEndPoint}?name=${viewName}&type=${viewTypeId}`);
  }

  /**
   * Executes a saved view and returns the result count
   * @param viewId ID of the saved view
   */
  getRowCountBySavedView(viewId: number): Observable<ISavedViewResult> {
    // Any errors encountered while executing are not displayed as a popup and should be handled by the consumer.
    return this._httpClient.get<ISavedViewResult>(`${this._savedViewsEndpoint}/execute/${viewId}`, { showError: false });
  }

  public addViewToDashboard(viewId: number, isFirmActionItems: boolean): Observable<void> {
    return this._httpClient.postData(`${this._savedViewsEndpoint}/${viewId}/dashboard?isFirmActionItems=${isFirmActionItems}`, null);
  }
}
