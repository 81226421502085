<div class="security-preference-equivalent">
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">Security Equivalents</legend>
    <div class="auto-search-security">
      <span>Search Securities</span>
      <div class="search">
        <eclipse-security-autocomplete (onSearchSecuritySelect)="onSearchSecuritySelect($event)"
          [filterOutSecurityIds]="filterOutSecurityIds" [isMoneyMarketSecurity]="false">
        </eclipse-security-autocomplete>
      </div>
    </div>
    <div class="equivalent-grid">
      <ag-grid-angular style="width:100%;height:280px;" class="ag-theme-alpine"
                       [gridOptions]="gridOptions"
                       (gridReady)="onGridReady($event)"
                       rowSelection="single" [columnDefs]="columnDefs"
                       [context]="gridContext"
                       (modelUpdated)="onModelUpdated()"
                       suppressContextMenu=true
                       [rowData]="this.equivalents">
      </ag-grid-angular>
    </div>
  </fieldset>
</div>
