<div *ngIf="showPageContent">
  <ag-grid-angular #agGrid style="width:100%;height:400px;" class="ag-theme-alpine grid-height-autosizefour"
                   [gridOptions]="gridOptions"
                   (gridReady)="onGridReady($event)"
                   rowSelection="single"
                   [context]="gridContext"
                   [columnDefs]="columnDefs"
                   [rowData]="esgRestrictedSecurities"
                   (modelUpdated)="onModelUpdated()"
                   [getContextMenuItems]="getContextMenuItems">
  </ag-grid-angular>
  <eclipse-security-preference-add-edit
    [preferenceLevel]="preferenceLevel"
    [securityPreferencesHiddenMap]="securityPreferencesHiddenMap"
    (addPreferenceToGrid)="addToGrid($event)">
  </eclipse-security-preference-add-edit>
</div>
