import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {
  ISystematicMultiplierPreference,
  ISystematicMultiplierRebalance,
  SystematicAutoRebalanceFrequency,
  SystematicFrequency
} from '../../models/preferences/systematic';

@Component({
  selector: 'eclipse-systematic-multiplier-grid',
  templateUrl: './systematic-multiplier-grid.component.html',
  styleUrls: ['./systematic-multiplier-grid.component.scss']
})
export class SystematicMultiplierGridComponent extends BaseComponent {
  @Input() preferenceData: ISystematicMultiplierPreference;
  @Input() canEdit: boolean = false;
  public rebalanceData: ISystematicMultiplierRebalance[];
  public errorMessage: string;
  public isValid = true;

  constructor() {
    super();
  }

  ngOnInit() {
    this.bindSystematicMultiplierData();
  }

  private createPlaceholderRebalanceItems(data: ISystematicMultiplierRebalance[]): ISystematicMultiplierRebalance[] {
    const values: ISystematicMultiplierRebalance[] = [];
    // for each type of rebalance frequency, create a placeholder object if one doesn't already exist
    Object.values(SystematicAutoRebalanceFrequency).forEach(freq => {
      if (!isNaN(Number(freq))) {
        const item = data.find(s => s.autoRebalanceTypeId === freq);
        if (!item) {
          values.push(<ISystematicMultiplierRebalance>{
            id: 0,
            autoRebalanceTypeId: freq,
          });
        } else {
          values.push(item);
        }
      }
    });
    return values;
  }

  bindSystematicMultiplierData(): void {
    if (this.preferenceData && this.preferenceData.systematicMultiplierPreferences) {
      this.rebalanceData = this.createPlaceholderRebalanceItems(this.preferenceData.systematicMultiplierPreferences);
    } else {
      this.rebalanceData = [];
    }
    this.resetForm();
  }

  resetForm(): void {
    this.isValid = true;
    this.errorMessage = null;
  }

  validate(value: ISystematicMultiplierRebalance): boolean {
    this.errorMessage = null;
    this.preferenceData.resetToParent = false;
    this.isValid = this.validateAll();

    // if the a value doesn't exist for the current auto-rebalance frequency, add it to the preference now
    if (this.isValid && !this.preferenceData.systematicMultiplierPreferences.find(s => s.autoRebalanceTypeId === value.autoRebalanceTypeId)) {
      this.preferenceData.systematicMultiplierPreferences.push(value);
    }

    return this.isValid;
  }

  validateAll(): boolean {
    const results = [];
    this.rebalanceData.forEach(row => {
      // validate each of the types of frequencies to ensure they're all valid
      results.push(...Object.values(SystematicFrequency).map(freq => {
        const min = row[`${freq}Min`];
        const max = row[`${freq}Max`];
        const target = row[`${freq}Target`];

        if (min !== null && min !== undefined) {
          // min must be < target
          if (target !== null && target !== undefined && min > target) {
            this.errorMessage = `${this.getRebalanceFrequencyDisplayName(row)} Auto-Rebal minimum must be less than target.`;
            return false;
          } else if (max !== null && max !== undefined && min > max) { // min must be < max
            this.errorMessage = `${this.getRebalanceFrequencyDisplayName(row)} Auto-Rebal minimum must be less than maximum.`;
            return false;
          }
        }

        // target must be < max
        if (target !== null && target !== undefined && max !== null && max !== undefined && target > max) {
          this.errorMessage = `${this.getRebalanceFrequencyDisplayName(row)} Auto-Rebal target must be less than maximum.`;
          return false;
        }
        return true;
      }));
    });

    this.isValid = results.filter(valid => !valid).length === 0;
    return this.isValid;
  }

  getRebalanceFrequencyDisplayName(item: ISystematicMultiplierRebalance): string {
    switch (item.autoRebalanceTypeId) {
      case SystematicAutoRebalanceFrequency.None:
        return 'None';
      case SystematicAutoRebalanceFrequency.Monthly:
        return 'Monthly';
      case SystematicAutoRebalanceFrequency.Quarterly:
        return 'Quarterly';
      case SystematicAutoRebalanceFrequency.SemiAnnual:
        return 'Semi Annual';
      case SystematicAutoRebalanceFrequency.Annual:
        return 'Annual';
      default:
        return 'Unknown';
    }
  }
}
