import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import {ITeam} from '../models/team';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private _teamEndPoint = 'v1/admin/teams';
  private _advisorPoint = 'v1/admin/Advisors';

  constructor(private _httpClient: OEHttpClient) {

  }

  /** Get team counts summary */
  getTeamSummary() {
    return this._httpClient.getData(`${this._teamEndPoint}/summary`);
  }

  /** Get teams */
  getTeamData() {
    return this._httpClient.getData(this._teamEndPoint);
  }

  /** Get active teams */
  getActiveTeamData() {
    return this._httpClient.getData(`${this._teamEndPoint}?isActive=true`);
  }

  /** Get team details by id */
  getTeamById(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}`);
  }

  /** Reassigns old Team to new Team */
  reassignTeamToNewTeam(teamIds: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/Action/ReassignTeam`, teamIds);
  }

  /** Create team */
  createTeam(team: ITeam) {
    return this._httpClient.postData(this._teamEndPoint, team);
  }

  /** Update team */
  updateTeam(team: ITeam) {
    return this._httpClient.updateData(`${this._teamEndPoint}/${team.id}`, team);
  }

  /** Delete team */
  deleteTeam(teamId: number) {
    return this._httpClient.deleteData(`${this._teamEndPoint}/${teamId}`);
  }

  /** Get team users data */
  getTeamUsers(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}/users`);
  }

  /** Get team portfolio data */
  getTeamPortfolios(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}/portfolios`);
  }

  /** Get team advisor static data */
  getTeamAdvisors(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}/advisors`);
  }

  /** Get team model static data */
  getTeamModels(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}/models`);
  }

  searchAdvisors(searString: string) {
    return this._httpClient.getData(`${this._advisorPoint}?search=${searString}`);
  }

  /** To get teams by team name */
  searchTeams(searchString: string) {
    return this._httpClient.getData(`${this._teamEndPoint}?search=${searchString}`);
  }

  searchActiveTeams(searchString: string) {
    return this._httpClient.getData(`${this._teamEndPoint}?search=${searchString}&isOnlyActive=true`);
  }

  /** To get teams by team name */
  searchTeamsLite(searchString: string) {
    return this._httpClient.getData(`${this._teamEndPoint}/names?search=${searchString}`);
  }

  addTeamUsers(teamId: number, userIds: number[]): Observable<any> {
    return this._httpClient.postData(`${this._teamEndPoint}/${teamId}/Users`, {userIds: userIds});
  }

  deleteTeamUsers(teamId: number, userIds: number[]): Observable<any> {
    return this._httpClient.deleteData(`${this._teamEndPoint}/${teamId}/Users`, {userIds: userIds});
  }

  saveTeamPortfolios(teamId: number, portfolioIds: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/${teamId}/Portfolios`, (portfolioIds));
  }

  saveTeamAdvisors(teamId: number, AdvisorIds: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/${teamId}/Advisors`, (AdvisorIds));
  }

  saveTeamModels(teamId: number, ModelIds: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/${teamId}/Models`, (ModelIds));
  }

  saveTeamSecuritySet(teamId: number, securitySets: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/${teamId}/securitySet`, (securitySets));
  }

  /** Update team portfolios */
  updateTeamPortfolios(teamId: number, portfolioIds: any) {
    return this._httpClient.updateData(`${this._teamEndPoint}/${teamId}/Portfolios`, portfolioIds);
  }

  /** Update team security Sets */
  updateTeamSecuritySet(teamId: number, securitySets: any) {
    return this._httpClient.updateData(`${this._teamEndPoint}/${teamId}/securitySet`, securitySets);
  }

  createAndUpdateSecuritySetTeam(securitySetId: number, securitySets: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/${securitySetId}/teams`, securitySets);
  }

  updatePrimaryTeamPortfolios(teamPortfolios: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/setPrimaryTeam`, teamPortfolios);
  }
  /** Update team advisors */
  updateTeamAdvisors(teamId: number, AdvisorIds: any) {
    return this._httpClient.updateData(`${this._teamEndPoint}/${teamId}/Advisors`, AdvisorIds);
  }

  /** Update team models */
  updateTeamModels(teamId: number, ModelIds: any) {
    return this._httpClient.updateData(`${this._teamEndPoint}/${teamId}/Models`, ModelIds);
  }

  /** Get static role types */
  getTeamStatus() {
    return [
      { typeId: 1, typeName: 'Active' },
      { typeId: 0, typeName: 'In Active' }
    ];
  }

  /** To get Portfolios by team id(check for primary team) */
  getPortfoliosByTeamId(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}/primaryPortfolios`);
  }

  /** To get security set by team id */
  getSecuritySetByTeamId(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${teamId}/securitySet`);
  }

  /** To get team set by security set id */
  getTeamBySecuritySetId(securitySetId: number) {
    return this._httpClient.getData(`${this._teamEndPoint}/${securitySetId}/team`);
  }

  /** To get sub model by team id */
  getSubModelByTeamId(teamId: number) {
    return this._httpClient.getData(`${this._teamEndPoint }/${teamId}/subModel`);
  }

/** Save and Update team sub model */
  saveAndUpdateTeamSubModels(teamId: number, subModels: any) {
    return this._httpClient.postData(`${this._teamEndPoint}/${teamId}/subModel`, subModels);
  }

  /** Get teams Accessible to user*/
  getTeamsAccessibleToUser() {
    return this._httpClient.getData(`${this._teamEndPoint}/userTeams`);
  }
}
