<div [ngSwitch]="displayPermission">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="control-label col-md-4 col-xs-10">Communities - Allowed Strategists
          <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="communitiesAllowedStratToolTip" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
        </label>
        <div class="col-md-1 col-xs-2"></div>
        <div class="col-md-7 col-xs-12">
          <div *ngSwitchCase="true" class="field-control">
            <p-multiSelect [options]="modelStrategistItems" appendTo="body" [(ngModel)]="selectedStrategistItems" [maxSelectedLabels]="1"
                           defaultLabel="Select Strategist" (onChange)="onStrategistChange(selectedStrategistItems)">
            </p-multiSelect>
          </div>

          <div *ngSwitchCase="false">
            <ul>
              <li *ngFor="let item of selectedStrategitNames">
                <em>
                  <label> {{item}}</label>
                </em>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="selectedStrategistItems != null">
        <div class="form-group">
          <label class="control-label col-md-4 col-xs-12">Communities - Allowed Strategist Models
            <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="communitiesAllowedStratModelsToolTip" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
          </label>
          <div class="col-md-1 col-xs-12"></div>
          <div class="col-md-7 col-xs-12">
            <span *ngSwitchCase="true" class="field-control">
              <select class="form-control" [(ngModel)]="modelAccessLevel"
                      (change)="onModelAccessLevelChange($event.target.value)">
                <option value=1>All</option>
                <option value=2>Selected Model</option>
              </select>
            </span>
            <span *ngSwitchCase="false">
              <label>{{selecetdModel}}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="modelAccessLevel == 2">
        <div class="form-group" *ngIf="communities.length > 0">
          <label class="control-label col-md-4 col-xs-12">Available Community
            <a class="text-info btn-help" data-toggle="tooltip" href="javascript:void(0);"
               title="Available Community"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
          </label>
          <div class="col-md-1 col-xs-12"></div>
          <div class="col-md-7 col-xs-12">
            <div *ngSwitchCase="true" class="available-community-list field-control">
              <div class="checkbox-ali" *ngFor="let c of communities">
                <label><input type="checkbox" value={{c.communityId}} [ngModel]="c.isSelected"
                              (change)="onCommunityChkChange($event)"/> {{c.community}} </label>
              </div>
            </div>
            <div *ngSwitchCase="false">
              <ul>
                <li *ngFor="let item of selectedCommunityNames">
                  <em>
                    <label> {{item}}</label>
                  </em>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
