import { Component, Input, Output, EventEmitter} from '@angular/core';
import {INotificationsList} from '../../models/notification';

@Component({
  selector: 'notification-item',
  templateUrl: './notificationitem.component.html'
})
export class NotificationItemComponent {
  @Input() notificationModel: any;
  @Input() notice: INotificationsList;
  notifications: any[];

  constructor() { }

  @Output() setReadStatus = new EventEmitter();
  @Output() viewNotification = new EventEmitter();
  takeAction(notificationId, status) {
    this.notifications = [];
    this.notifications.push(notificationId, status);
    this.setReadStatus.emit(this.notifications);

    // console.log('test',this.notice);
  }
  viewNotificationStory(notificationId) {
    this.viewNotification.emit(notificationId);
  }

}
