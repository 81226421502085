<section style="display: flex; flex-direction: column; height: 100%;">
  <div class="page-header-row">
    <h3>Accounts</h3>
  </div>

  <ag-grid-angular style="width: 100%; flex: 1;" class="ag-theme-alpine"
                   [context]="gridContext" [gridOptions]="gridOptions" rowSelection="single" [columnDefs]="columnDefs"
                   [rowData]="portfolioEditorService.accounts$ | async" (rowDoubleClicked)="onRowDoubleClicked($event)"
                   [getContextMenuItems]="getContextMenuItems" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</section>
