<p-dialog header="Compliance Update" [(visible)]="showComplianceUpdatePopup" [draggable]=false [resizable]=false
          modal="true" [closable]="!isDisableButton" (onHide)="complianceUpdateResetData()" [style]="{'width': '600px'}">
  <div class="col-md-12 compliance-update">
    <p>Type</p>
    <div *ngIf="isBasisCodeOverrideRestriction" class="field-radiobutton compliance-update-type">
      <p-radioButton name="city" value="Appeal" [(ngModel)]="complianceUpdate.type" inputId="appeal"></p-radioButton>
      <label for="appeal">Appeal</label>
      <p-radioButton name="city" value="Override" [(ngModel)]="complianceUpdate.type"
                     inputId="override"></p-radioButton>
      <label for="override">Override</label>
    </div>
    <p>Reason for {{complianceUpdate.type}}</p>
    <textarea class="form-control" [(ngModel)]="complianceUpdate.message" maxlength="500"></textarea>
    <p class="text-muted">{{complianceUpdate?.trades?.length}} trade(s) will
      be {{complianceUpdate.type === 'Appeal' ? 'appealed' : 'overridden'}}</p>
  </div>
  <p-footer>
    <button class="p-button-secondary" pButton (click)="complianceUpdateResetData()" [disabled]="isDisableButton">Cancel</button>
    <button pButton [disabled]="!complianceUpdate?.message?.length || isDisableButton"
            (click)="sendTradeToCompliance()">Send to
      Compliance
    </button>
  </p-footer>
</p-dialog>
