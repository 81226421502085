import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { ILevelPreference, ILevelPreferenceSave } from '../models/preferences/preference';
import {
  IPortfolioAccountPreferenceSecurity,
  ISecurityPreferenceSave,
  ISecurityPreferencesGet
} from '../models/preferences/securityPreference';
import { IRedemptionFeePreferencesGet } from '../models/preferences/redemptionfeepreference';
import { ILocationOptimizationCustom } from '../models/preferences/locationOptimization';
import { ITradeMaxPreferencesGet } from '../models/preferences/trademaxpreference';
import { ICapitalGainReinvestPreferencesGet } from '../models/preferences/capitalgainreinvestpreference';
import { IDividendReinvestPreferencesGet } from '../models/preferences/dividendreinvestpreference';
import { ITradeSettingsPreferencesGet } from '../models/preferences/tradesettingspreference';
import { IPreferenceCommunity } from '../models/preferences/preferenceCommunity';
import { ITradeMinPreferencesGet } from '../models/preferences/trademinpreference';
import { ISecurityPreferenceExcelImport } from '../models/preferences/securitypreferenceexcelimport';
import { ITransactionFeePreferencesGet } from '../models/preferences/transactionfeepreference';
import { ICustodianRedemptionFeePreferencesGet } from '../models/preferences/custodianredemptionfeepreference';
import { IDefaultPrimaryTeamPreferencesGet } from '../models/preferences/defaultprimaryteampreference';
import { Observable, forkJoin } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { IUser } from '../models/user';
import { ISystematicMultiplierPreference } from '../models/preferences/systematic';
import { IBillingCashMultiplierPreference } from '../models/preferences/billingcash';
import { Utils } from '../core/functions';
import { IAccountCashPreference } from '../models/tactical';
import {
  IMoneyMarketPreferenceSaveMessage,
  IMoneyMarketAllocationPreference,
  IMoneyMarketFundPreference,
  IMoneyMarketFunds,
  IMoneyMarketAllocationPreferenceSave,
  IMoneyMarketFundPreferenceSave
} from '../models/preferences/moneyMarketPreference';
import { IAstroInvestorPreferences, ISaveInvestorPreferencesRequest, ISaveInvestorPreferencesResponse } from '../models/astro';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  private _prefsForLevelEndPoint = 'v1/preference';
  private _prefsForLevelEndPointV2 = 'v2/preference';
  private _prefsForInvestorEndpoint = 'v2/Account/astroAccounts/preference';
  private _prefsForInvestorSaveEndpoint = 'v2/Account/astroAccounts/preference/SaveInvestorPreferences';
  private _prefsAuditLogEndPoint = 'auditlog';

  constructor(private _httpClient: OEHttpClient) {
  }

  /* Fetch Preference categories by Level */
  /**
   (new)  /preference/:levelName/categories
   */
  getPrefLevelCategories(levelName: string) {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/categories`);
  }

  /* Fetch Preference categories by Level */
  getpreferencesForLevel(levelName: string, levelId: number): Observable<ILevelPreference> {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${levelId}`);
  }

  /** Fetch Preference communities */
  /**
   (new)   /preference/:levelName/communityStrategist/:recordId/:communityStrategistPreferenceId/:preferenceValueId/:inheritedPreferenceValueId
   */
  getPreferenceCommunities(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<IPreferenceCommunity> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`);
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get Location Optimization custom component details */
  /**
   (new)  /preference/:levelName/locationOptimization/:recordId/:locationPreferenceId/:preferenceValueId/:inheritedPreferenceValueId
   */
  getLocationOptimizationPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any,
    value: any): Observable<ILocationOptimizationCustom> {
    if (recordId != null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}/${value}`);
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get Security Preference custom component details */
  /**
   (new)  levelName/securityPreference/:recordId/:securityPreferenceId/:preferenceValueId
   */
  getSecurityPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ISecurityPreferencesGet> {
    preferenceValueId = (preferenceValueId === 0) ? null : +preferenceValueId;
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get Redemption Fee Preference custom component details */
  /**
   (new)  levelName/redemptionFeePreference/:recordId/:redemptionFeePreferenceId/:preferenceValueId
   */
  getRedemptionFeePreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<IRedemptionFeePreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get Custodian Redemption Fee Preference custom component details */
  /**
   (new)  levelName/custodianRedemptionFeePreference/:recordId/:redemptionFeePreferenceId/:preferenceValueId
   */
  getCustodianRedemptionFeePreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ICustodianRedemptionFeePreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /**To update team */
  updatePreference(preference: ILevelPreferenceSave) {
    return this._httpClient.updateData(`${this._prefsForLevelEndPoint}/updateAll`, preference);
  }

  /** To get Master preferences for Bulk GET */
  /**
   (new)  /preference/:levelName/master
   */
  getMasterPreferences(master: string, levelName: string) {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${master}`);
  }

  /** Update Mass preferences */
  updateMassPreferences(preference: ILevelPreferenceSave) {
    return this._httpClient.updateData(`${this._prefsForLevelEndPoint}/Action/massUpdateAll`, preference);
  }

  /** To get Transaction Fee Preference custom component details */
  /**
   (new)  levelName/transactionFeePreference/:recordId/:transactionFeePreferenceId/:preferenceValueId
   */
  getTransactionFeePreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ITransactionFeePreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get trade Min Preference custom component details */
  /**
   (new)  levelName/tradeMinPreference/:recordId/:tradeMinPreferenceId/:preferenceValueId
   */
  getTradeMinPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ITradeMinPreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get trade Max Preference custom component details */
  /**
   (new)  levelName/tradeMaxPreference/:recordId/:tradeMaxPreferenceId/:preferenceValueId
   */
  getTradeMaxPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ITradeMaxPreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get dividend Reinvest Preference custom component details */
  /**
   (new)  levelName/dividendReinvestPreference/:recordId/:dividendReinvestPreferenceId/:preferenceValueId
   */
  getDividendReinvestPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<IDividendReinvestPreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get capital Gain Reinvest Fee Preference custom component details */
  /**
   (new)  levelName/capitalGainReinvestPreference/:recordId/:capitalGainReinvestPreference/:preferenceValueId
   */
  getCapitalGainReinvestPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ICapitalGainReinvestPreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get trade Settings Preference custom component details */
  /**
   (new)  levelName/tradeSettingsPreference/:recordId/:tradeSettingsPreferenceId/:preferenceValueId
   */
  getTradeSettingsPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ITradeSettingsPreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /** To get default primary team Preference custom component details */
  /**
   (new)  levelName/defaultPrimaryTeamPreference/:recordId/:defaultPrimaryTeamPreferenceId/:preferenceValueId
   */
  getDefaultPrimaryTeamPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<IDefaultPrimaryTeamPreferencesGet> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`
      );
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  getSystematicMultiplierPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<ISystematicMultiplierPreference> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`);
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  getBillingCashMultiplierPreferences(levelName: string,
    recordId: number,
    componentName: string,
    preferenceId: number,
    preferenceValueId: number,
    inheritedPreferenceValueId: any): Observable<IBillingCashMultiplierPreference> {
    if (recordId !== null) {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${recordId}/${preferenceId}/${preferenceValueId}/${inheritedPreferenceValueId}`);
    } else {
      return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/${componentName}/${preferenceId}/master`);
    }
  }

  /* Fetch Preference categories by Level */
  /**
   (new)  /preference/:levelName/categories
   */
  getPrefLevelSubCategories(levelName: string) {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/${levelName}/subcategories`);
  }

  /**
   * Generic service for Custom components
   * AUDIT && LOGGING
   **/
  getAuditLogForPreferenceHistory(preferenceName: string, preferenceId: number, fromDate: Date, toDate: Date) {
    return this._httpClient.getData(`${this._prefsAuditLogEndPoint}/${preferenceName}/${preferenceId}?toDate=${toDate}&fromDate=${fromDate}`);
  }

  /** Save Security Preferences */
  updateSecurityPreferences(securityPreference: ISecurityPreferenceSave) {
    return this._httpClient.updateData(`${this._prefsForLevelEndPoint}/Action/updateAll/securityPreferences`, securityPreference);
  }

  /** Update Mass preferences */
  updateMassSecurityPreferences(securityPreference: ISecurityPreferenceSave) {
    return this._httpClient.updateData(`${this._prefsForLevelEndPoint}/Action/massUpdateAll/securityPreferences`, securityPreference);
  }

  updateBatchJobForSecurityPreferenceChange(changes: any) {
    return this._httpClient.updateData(`${this._prefsForLevelEndPointV2}/preference/UpdateBatchJobForSecurityPreferenceChange`, changes);
  }

  setAstroInvestorPreferences(accountId: number, saveInvestorPreferenceRequest?: ISaveInvestorPreferencesRequest): Observable<ISaveInvestorPreferencesResponse> {
    return this._httpClient.updateData(`${this._prefsForInvestorSaveEndpoint}/${accountId}`, saveInvestorPreferenceRequest);
  }

  /** Get Security Preference Template */
  getPreferenceTemplate() {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/upload/templates`);
  }

  /** Upload selected file */
  uploadSecurityPreferenceTemplate(file: ISecurityPreferenceExcelImport) {
    const formData: any = new FormData();
    formData.append('file', file.file);
    return this._httpClient.uploadFile(`${this._prefsForLevelEndPoint}/upload`, formData);
  }

  getPreferenceApprovalStatus() {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/Firm/tradeApprovalPreference`);

  }

  getPreferenceOption(id: number) {
    return this._httpClient.getData(`${this._prefsForLevelEndPoint}/action/${id}/option`);
  }

  async isEnabled(user: IUser, preference: string): Promise<boolean> {
    let isEnabled = false;
    if (user) {
      const throttlePercent = await this.getThrottlePercent(user, preference);
      isEnabled = user.id % 100 < throttlePercent;
    }
    return isEnabled;
  }

  async getThrottlePercent(user: IUser, preference: string): Promise<number> {
    const preferenceThrottle = `${preference}Throttle`;
    await ConfigService.settings$.toPromise(); // This line stinks! We must wait for ConfigService to have settings before we can make calls to _httpClient.
    const throttlePercent: string = await new Promise((resolve) => {
      this.getPreferenceByName(preferenceThrottle)
        .subscribe(result => {
          result = Utils.convertIntoBooleanValue(result);
          resolve(result);
        });
    });
    return parseInt(throttlePercent, 10);
  }

  static setFormDefaultsForInheriteds(preferences: any) {
    preferences.forEach(pfsettings => {
      if (pfsettings.componentType !== 'custom') { // only Default componentType
        if (pfsettings.componentName === 'Dropdown') {
          this.setInheritedFirmDefaultsForDropdownComponent(pfsettings);
        } else {
          if (pfsettings.value === null || pfsettings.value === '') {
            // get Inherited value
            pfsettings.resetToParent = true;
            const InheritValue = this.getInheritedValue(pfsettings);
            pfsettings.value = InheritValue;
            pfsettings.selectedOptions = InheritValue;
            if (pfsettings.inheritedIndicatorValue !== null) {
              pfsettings.indicatorValue = pfsettings.inheritedIndicatorValue;
            }

            // indicator Value set MAX & MIN value
            for (let i = 0; i < pfsettings.indicatorOptions.length; i++) {
              const option = pfsettings.indicatorOptions[i];
              if (option.name === pfsettings.indicatorValue) {
                pfsettings.maxValue = option.maxValue === null ? Number.MAX_VALUE : option.maxValue;
                pfsettings.minValue = option.minValue === null ? 0 : option.minValue;
                break;
              }
            }
          } else {
            pfsettings.resetToParent = false;
          }
        }
      }
    });
  }

  /** Get inherited value */
  static getInheritedValue(pfsettings: any) {
    if (pfsettings.inheritedValue && pfsettings.isInherited) {
      return pfsettings.inheritedValue;
    } else {
      return '';
    }
  }

  static setInheritedFirmDefaultsForDropdownComponent(pfsettings: any): void {
    if (pfsettings.selectedOptions.length === 0) {
      pfsettings.resetToParent = true;
      if (pfsettings.inheritedSelectedOptions.length > 0 && pfsettings.isInherited) {
        pfsettings.value = pfsettings.inheritedSelectedOptions[0]['id'];
        pfsettings.selectedOptions = [
          {
            id: pfsettings.inheritedSelectedOptions[0]['id'],
            name: pfsettings.inheritedSelectedOptions[0]['name'],
            order: pfsettings.inheritedSelectedOptions[0]['order']
          }
        ];
      } else {
        pfsettings.value = '';
        pfsettings.selectedOptions = [];
      }
    } else {
      pfsettings.resetToParent = false;
    }
  }

  getPortfolioAccountPreferenceSecurities(levelName: string, recordId: number): Observable<IPortfolioAccountPreferenceSecurity> {
    return this._httpClient.getData(`${this._prefsForLevelEndPointV2}/preference/${levelName}/Securities/${recordId}`);
  }

  getPreferenceByName(preferenceName): Observable<any> {
    return this._httpClient.getData(`${this._prefsForLevelEndPointV2}/preference/GetPreference?preferenceName=${preferenceName}`);
  }

  getPreferencesForAccounts(preferenceNames: string[], accountIds: number[]): Observable<IAccountCashPreference[]> {
    return this._httpClient.postData(`${this._prefsForLevelEndPointV2}/preference/AccountPreferences`, { PreferenceNames: preferenceNames, AccountIds: accountIds });
  }

  getInvestorPreferencesForAccount(accountId: number): Observable<IAstroInvestorPreferences> {
    return this._httpClient.getData(`${this._prefsForInvestorEndpoint}/${accountId}/InvestorPreferences`);
  }

  getMoneyMarketAllocationPreference(relatedType: number, relatedTypeId: number): Observable<IMoneyMarketAllocationPreference> {
    let url = `${this._prefsForLevelEndPointV2}/preference/MoneyMarketPreference/Allocation/${relatedType}`;
    if (relatedTypeId) {
      url = `${url}/${relatedTypeId}`;
    } else{
      url = `${url}/master`;
    }
    return this._httpClient.getData(url);
  }

  getMoneyMarketFundPreference(relatedType: number, relatedTypeId: number): Observable<IMoneyMarketFundPreference> {
    let url = `${this._prefsForLevelEndPointV2}/preference/MoneyMarketPreference/Fund/${relatedType}`;
    if (relatedTypeId) {
      url = `${url}/${relatedTypeId}`;
    } else {
      url = `${url}/master`;
    }
    return this._httpClient.getData(url);
  }

  getMoneyMarketFundPreferenceBySecurityId(securityId: number): Observable<IMoneyMarketFunds[]> {
    return this._httpClient.getData(`${this._prefsForLevelEndPointV2}/preference/MoneyMarketPreference/Fund/Security/${securityId}`);
  }

  updateMoneyMarketAllocationPreference(moneyMarketAllocationPreference: IMoneyMarketAllocationPreferenceSave): Observable<IMoneyMarketPreferenceSaveMessage> {
    return this._httpClient.updateData(`${this._prefsForLevelEndPointV2}/preference/UpdateMoneyMarketAllocationPreference`, moneyMarketAllocationPreference);
  }

  updateMoneyMarketFundPreference(moneyMarketFundPreference: IMoneyMarketFundPreferenceSave): Observable<IMoneyMarketPreferenceSaveMessage> {
    return this._httpClient.updateData(`${this._prefsForLevelEndPointV2}/preference/UpdateMoneyMarketFundPreference`, moneyMarketFundPreference);
  }
}
