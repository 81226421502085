import { Injectable } from '@angular/core';
import {AlertEventEmitter, ErrorNotificationEventEmitter, NotifyEventEmitter, RequestEventEmitter, ResponseEventEmitter} from './emitter';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomSubService {
  beforeRequest: RequestEventEmitter;
  afterRequest: ResponseEventEmitter;
  beforeQueueRequest: RequestEventEmitter;
  afterQueueRequest: ResponseEventEmitter;
  forkJoinError: Subject<any>;

  constructor() {
    this.beforeRequest = new RequestEventEmitter();
    this.afterRequest = new ResponseEventEmitter();
    this.beforeQueueRequest = new RequestEventEmitter();
    this.afterQueueRequest = new ResponseEventEmitter();
    this.forkJoinError = new Subject();
  }
}

@Injectable()
export class ErrorNotificationService {
  errorResponse: ErrorNotificationEventEmitter;
  constructor() {
    this.errorResponse = new ErrorNotificationEventEmitter();
  }
}

@Injectable()
export class NotificationService {
  fileImportProgress: RequestEventEmitter;
  notify: NotifyEventEmitter;
  ordersNotify: NotifyEventEmitter;
  blocksNotify: NotifyEventEmitter;
  tomRightPanelMT: NotifyEventEmitter;
  tomRightPanelMA: NotifyEventEmitter;
  awaitingOrdersNotify: NotifyEventEmitter;
  fixedOrdersNotify: NotifyEventEmitter;
  constructor() {
    this.notify = new NotifyEventEmitter();
    this.ordersNotify = new NotifyEventEmitter();
    this.blocksNotify = new NotifyEventEmitter();
    this.tomRightPanelMT = new NotifyEventEmitter();
    this.tomRightPanelMA = new NotifyEventEmitter();
    this.fileImportProgress = new RequestEventEmitter();
    this.awaitingOrdersNotify = new NotifyEventEmitter();
    this.fixedOrdersNotify = new NotifyEventEmitter();
  }
}

@Injectable()
export class AlertService {
  alert: AlertEventEmitter;
  constructor() {
    this.alert = new AlertEventEmitter();
  }
}
