<table class="billing-cash-multiplier-table">
  <colgroup>
    <col class="row-header">
  </colgroup>
  <thead>
  <tr>
    <th rowspan="2" scope="colgroup">Auto-Rebalancing Frequency</th>
    <th colspan="3" scope="colgroup">Multiplier</th>
    <th colspan="2" scope="colgroup">Expiration</th>
  </tr>
  <tr>
    <th scope="col">Min</th>
    <th scope="col">Target</th>
    <th scope="col">Max</th>
    <th scope="col">Expire</th>
    <th scope="col">Tolerance Band</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of rebalanceData">
    <td class="row-header">{{getRebalanceFrequencyDisplayName(item)}} Auto-Rebalance</td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.minimum"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.target"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.maximum"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input type="checkbox" name="astroEnabledCheck" [checked]="item.expire" [(ngModel)]="item.expire">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" max="100" [(ngModel)]="item.toleranceBand" (ngModelChange)="validate(item)"
               (keyup)="validatePercent($event, item)" (keypress)="validateEventField($event)">%
      </div>
    </td>
  </tr>
  </tbody>
</table>
