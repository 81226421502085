<p-sidebar [(visible)]="visible" class="opl-editor" [fullScreen]="true" [modal]="true" [blockScroll]="true"
           [autoZIndex]="true" [showCloseIcon]="false">
  <ng-template pTemplate="header">
    <p-menubar class="opl-editor-header">
      <ng-template pTemplate="start">
        <div class="inline-flex align-items-center">
          <span class="opl-editor-header-icon portfolio">
              <p-skeleton *ngIf="!isEntityLoaded" size="40px"></p-skeleton>
              <i *ngIf="isEntityLoaded" height="40" class="far fa-cubes"></i>
          </span>
          <eclipse-portfolio-tree-dropdown [portfolioId]="componentInstance?.portfolioId"
                                           (entityChanged)="onTreeEntityChanged($event)"></eclipse-portfolio-tree-dropdown>
        </div>
      </ng-template>
      <ng-template pTemplate="end">
        <div class="inline-flex align-items-center" style="align-items: center;">
          <button *ngIf="newPortfolioDetailsFlag" class="p-button-sm m-r-15" pButton icon="fas fa-arrow-up-right" label="Open in new Portfolio editor" (click)="openInNewEditor()"></button>
          <div class="text-right" *ngIf="!isEntityLoaded">
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          </div>
          <div class="text-right" *ngIf="isEntityLoaded && componentInstance?.portfolio?.id">
            <p class="opl-editor-header-value">
              {{componentInstance?.portfolio?.summary?.AUM?.totalValue | currency }}
            </p>
            <p class="opl-editor-header-label">Total Value</p>
          </div>
          <p-divider layout="vertical"></p-divider>
          <div class="actionBtn btn-group pull-right">
            <button data-test-id="actions" data-target="#" label="Actions" iconPos="left" icon="fas fa-sparkles" pButton
                    class="p-button-text p-button-secondary" data-toggle="dropdown" aria-expanded="false" [disabled]="!componentInstance?.portfolio?.id"></button>
            <ul class="dropdown-menu" #menu *ngIf="!!model">
              <li data-test-id="refreshGrid" [hidden]="!model?.showRefreshGrid">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Refresh Grid')"><i class="fas fa-fw fa-sync" aria-hidden="true"></i> Refresh Grid</a>
              </li>
              <li role="separator" class="divider"></li>
              <li [hidden]="model.id === undefined">
                <a data-test-id="editPortfolio" href="javascript:void(0)"
                   *ngIf="model.type === 'P' && model.action !== 'V' && model.action !== 'E'"
                   [routerLink]="['/eclipse', 'portfolio', 'edit', model.id]"><i class="fas fa-fw fa-pen-to-square" aria-hidden="true"></i> Edit Portfolio</a>
              </li>
              <li data-test-id="assignPortfolio"
                  [hidden]="model.ids !== undefined || !model.canUpdate || model.isDisabled === 1 || model.typeId === 1 || model.action === 'AH' || model.type === 'A'">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Assign')"><i class="fas fa-fw fa-briefcase-arrow-right" aria-hidden="true"></i> Assign Portfolio</a>
              </li>
              <li data-test-id="addNewPortfolio"
                  [hidden]="!(model.id === undefined && model.ids === undefined) || !model.canAdd || (model.action !== 'L' && model.action !== 'D')">
                <a href="javascript:void(0)" (click)="addNewPortfolio()"><i class="fas fa-fw fa-plus" aria-hidden="true"></i> Add New Portfolio</a>
              </li>
              <li data-test-id="deletePortfolio"
                  [hidden]="!(model.id !== undefined || model.ids !== undefined) || !model.canDelete || model.action !== 'L' || model.typeId === 1 || model.type === 'A'">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Delete')"><i class="fas fa-fw fa-trash-can" aria-hidden="true"></i> Delete</a>
              </li>
              <li data-test-id="editPreferences"
                  [hidden]="!(model.id === undefined || model.ids === undefined) || !model.canUpdate || model.action === 'AH' || model.hasSMAs">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('PREFERENCES')"><i class="fas fa-fw fa-list-check" aria-hidden="true"></i> Edit Preferences</a>
              </li>
              <li data-test-id="excludeIncludePortfolios" id="excludeIncludePortfolios"
                  *ngIf="portfolioEditPermission && model?.type === 'P' && model?.action !== 'V' && (!!model.id || !!model.ids)">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('ExcludePortfolio')"><i class="fas fa-fw fa-object-exclude" aria-hidden="true"></i> Exclude/Include
                  Portfolio(s)</a>
              </li>
              <li data-test-id="equivalent" id="equivalent" class="dropdown-submenu pull-left"
                  [hidden]="model.action !== 'L' || !model.isPreferenceEditPermission">
                <a href="javascript:void(0)"><i class="fas fa-fw fa-equals" aria-hidden="true"></i> Portfolio Equivalents</a>
                <ul class="dropdown-menu">
                  <li data-test-id="importExportEquivalents">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('IMPORT_EXPORT_EQUIVALENTS')">Import/Export
                      Equivalents</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" [routerLink]="['/eclipse/admin','importlogs']">Import History</a>
                  </li>
                </ul>
              </li>
              <li [hidden]="model.id === undefined">
                <a data-test-id="editSleeves" href="javascript:void(0)"
                   *ngIf="model.typeId === 1  && model.type === 'P'" (click)="onSleeveEdit(model.id)"><i class="fas fa-fw fa-pencil" aria-hidden="true"></i> Edit Sleeves</a>
                <a data-test-id="sleeveTransfer" href="javascript:void(0)"
                   *ngIf="(model.typeId === 1 && model.type === 'P') || !model.canRead"
                   (click)="onTransferSleeve(model.id)"><i class="fas fa-fw fa-swap" aria-hidden="true"></i> Sleeve Transfer</a>
              </li>
              <li data-test-id="assignMAC" [hidden]="!model.displayMACFund">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('BlendedFund')"><i class="fas fa-fw fa-arrows-turn-to-dots" aria-hidden="true"></i> Assign a MAC fund</a>
              </li>

              <li role="separator" class="divider"></li>

              <li data-test-id="runPortfolioAnalytics" [class.disabled]="model?.needAnalyticsStatus === 'In Progress'"
                  [hidden]="!(model.id !== undefined || model.ids !== undefined) || !model.canDelete ">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Run Analysis')"><i class="fas fa-fw fa-bolt-lightning" aria-hidden="true"></i> Run Portfolio's
                  Analytics</a>
              </li>
              <li data-test-id="reverseSyncPortfolio"
                  [hidden]="(model.id === undefined && model.ids === undefined) || !reverseSyncPortfolioPreference">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Reverse Sync Portfolio')"><i class="fas fa-fw fa-reply-clock" aria-hidden="true"></i> Reverse
                  Sync Portfolio</a>
              </li>
              <li data-test-id="syncFromOrionPortfolio"
                  [hidden]="(model.id === undefined && model.ids === undefined)">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Sync Accounts From Orion')"><i class="far fa-fw fa-arrow-right-from-arc" aria-hidden="true"></i> Sync Accounts From Orion</a>
              </li>

              <li role="separator" class="divider"></li>

              <li data-test-id="tradeTools" id="tradeTools"
                  *ngIf="model.action !== 'AH' && (!model || model.hasTradables)" class="dropdown-submenu pull-left">
                <a href="javascript:void(0)"><i class="fas fa-fw fa-display-chart-up-circle-dollar" aria-hidden="true"></i> Trade Tools</a>
                <ul class="dropdown-menu">
                  <li data-test-id="cashNeeds" *ngIf="allowCashNeeds()" id="cashNeeds">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('CashNeeds')">Cash Needs</a>
                  </li>
                  <li data-test-id="globalTrades" *ngIf="model.globalTradesToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('GlobalTrades')">Global
                      Trades</a>
                  </li>
                  <li data-test-id="optionTrading" *ngIf="model.optionsTradeToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('OptionTradingTool')">Option
                      Trading</a>
                  </li>
                  <li data-test-id="raiseCash" *ngIf="model.raiseCashToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('RaiseCash')">Raise Cash</a>
                  </li>
                  <li data-test-id="rebalancer" id="rebalancer" *ngIf="allowRebalance()">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Rebalancer')">Rebalancer</a>
                  </li>
                  <li data-test-id="rebalanceSleeve" id="rebalanceSleeve" *ngIf="allowSleeveRebalance()">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('Rebalancer')">Individual Sleeve
                      Rebalance</a>
                  </li>
                  <li data-test-id="spendCash" *ngIf="model.spendCashToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('SpendCash')">Spend Cash</a>
                  </li>
                  <li data-test-id="tacticalTool" [hidden]="model.type === 'A' || !model.tacticalTradeToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('TacticalTool')">Tactical
                      Tool</a>
                  </li>
                  <li data-test-id="taxHarvesting" *ngIf="model.taxHarvestingToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance?.showPopup('TaxHarvesting')">Tax
                      Harvesting</a>
                  </li>
                  <li data-test-id="tradeToTarget" *ngIf="allowTradeToTarget()">
                    <a id="tradeToTarget" href="javascript:void(0)"
                       (click)="componentInstance?.showPopup('TradeToTarget')">Trade To Target (Account Level)</a>
                  </li>
                  <li data-test-id="liquidate" *ngIf="model?.liquidateToolPermission && massLiquidateFF">
                    <a id="liquidate" href="javascript:void(0)" (click)="componentInstance?.showPopup('Liquidate')">Liquidate</a>
                  </li>
                </ul>
              </li>

              <li role="separator" class="divider"></li>
              <li data-test-id="viewAuditHistory"
                  [hidden]="(model.id === undefined || model.ids !== undefined) || model.type === 'A'">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('VIEWAUDITHISTORY')"><i class="fas fa-fw fa-history" aria-hidden="true"></i> Audit
                  History</a>
              </li>
              <li data-test-id="viewHoldings"
                  [hidden]="model.id === undefined || !model.canRead || model.type === 'A' || !model.isUserHasHoldingPrev">
                <a [routerLink]="['/eclipse', 'holding', 'list' , 'portfolio', model.id, model.routeParam]"><i class="fas fa-fw fa-list-alt" aria-hidden="true"></i> Holdings</a>
              </li>
              <li data-test-id="viewAccounts"
                  [hidden]="(model.id === undefined || !model.canRead || model.type === 'A') || !accountAccessPermission || model.isTrue">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('ViewAccounts')"><i class="fas fa-fw fa-briefcase" aria-hidden="true"></i> View Accounts</a>
              </li>
              <li data-test-id="editDashboardDefaults" [hidden]="model.action !== 'D'">
                <a href="javascript:void(0)" (click)="componentInstance?.showPopup('EDITDASHBOARD')">Edit Dashboard
                  Defaults</a>
              </li>
            </ul>
          </div>
          <p-divider layout="vertical"></p-divider>
          <button type="button" pButton pRipple icon="pi pi-times"
                  class="p-button-text p-button-secondary" (click)="closeEditor()"></button>
        </div>
      </ng-template>
    </p-menubar>
  </ng-template>
  <div class="opl-editor-content-container">
    <div class="opl-editor-content">
      <ng-template #entityhost></ng-template>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="opl-editor-footer" *ngIf="model?.action === 'V'">
      <button pButton class="p-button-secondary p-button-text" (click)="onCancel()" label="Cancel"></button>
    </div>
    <div class="opl-editor-footer" *ngIf="model?.action === 'E' || model?.action === 'A'">
      <span *ngIf="componentInstance?.notHasPrimaryTeamId && componentInstance?.activeTabName !== 'CompareTool'" class="text-danger">Please select primary team </span>
      <span *ngIf="componentInstance?.showMacWarningMessage && componentInstance?.activeTabName !== 'CompareTool'"
            class="text-danger">{{componentInstance?.macWarningMessage}}</span>
      <button *ngIf="componentInstance?.activeTabName !== 'CompareTool'" type="button" name="portfolioSave" pButton label="Save"
              [disabled]="componentInstance?.isSaveButtonDisabled" (click)="componentInstance?.onSave(false)"></button>
      <button *ngIf="componentInstance?.activeTabName !== 'CompareTool'" type="button" name="portfolioSaveClose" pButton label="Save and Close"
              [disabled]="componentInstance?.isSaveButtonDisabled" (click)="componentInstance?.onSave(true)"></button>
      <button type="button" (click)="onCancel()" pButton class="p-button-secondary" label="Cancel"></button>
    </div>
  </ng-template>
</p-sidebar>
