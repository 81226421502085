<p-autoComplete [(ngModel)]="selectedItem" [suggestions]="suggestions" placeholder="Model ID / Model Name / Model Tags" (completeMethod)="loadSuggestions($event)"
                (onSelect)="onModelSelect($event)" field="name" class="autocomplete-search" [minLength]="1">
  <ng-template let-selectedItem pTemplate="item">
    <ul class="ui-helper-clearfix show-hollding-dropdown">
      <li class="vertical-center">
        <i class="fas fa-flip-vertical fa-code-branch" aria-hidden="true"></i>
        <span>{{selectedItem.displayFormat}}</span>
      </li>
    </ul>
  </ng-template>
</p-autoComplete>
