<h3 *ngIf="showHeader && !isRaiseCash">
    <strong>Set Aside Cash Details</strong>
</h3>
<div *ngIf="!isRaiseCash">
  <div class="row button-container">
    <div class="form-group">
      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
        <label for="1" class="radio-inline">
          <input id="1" type="radio" [checked]="setAsideGridType === 'Active'" name="setAsideGridTypeOp1" (click)="filterAsideGrid(0)">
          <span class="circle"></span>
          <span class="check"></span>
          Active
        </label>
        <label for="2" class="radio-inline">
          <input type="radio" [checked]="setAsideGridType === 'All'" name="setAsideGridTypeOp2" (click)="filterAsideGrid(1)" id="2">
          <span class="circle"></span>
          <span class="check"></span>
          All
        </label>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
        <div class="pull-right">
            <button type="button" pButton (click)="exportToExcel()"
                    label="Export to Excel" icon="far fa-fw fa-arrow-circle-down" class="p-button-secondary m-r-5"></button>
            <button type="button" pButton (click)="onCreateAsideCash()" icon="fas fa-plus-circle" label="Set Aside Cash"
                    *ngIf="sleeveType !== 'Contribution' && sleeveType !== 'Distribution'">
            </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row margin-top-15">
    <div class="col-md-12 multi-line-row-header-grid">
      <ag-grid-angular style="width: 100%; height: 350px;" class="ag-theme-alpine"
                       [rowData]="asideCashData" rowSelection="multiple"
                       [gridOptions]="asideCashGridOptions"
                       (gridReady)="onGridReady($event)"
                       [columnDefs]="columnDefs"
                       [getContextMenuItems]="getContextMenuItems"
                       [context]="gridContext">
      </ag-grid-angular>
    </div>
  </div>
  <p class="m-t-5" *ngIf="sleeveType !== 'Contribution' && sleeveType !== 'Distribution'">
    <i class="far fa-1x fa-file-alt" aria-hidden="true"></i> Only single selection will be allowed. </p>
  <p class="m-t-5" *ngIf="sleeveType === 'Contribution' || sleeveType === 'Distribution'">
    <i class="far fa-1x fa-file-alt" aria-hidden="true"></i> Navigate to the Portfolio level to Edit Or Add Set Aside. </p>
</div>

<p-dialog header="Set Aside Cash Details" [(visible)]="displayAsideCashEditor" modal="true" appendTo="body"
          [draggable]=false [resizable]=false class="ui-dialog-md" styleClass="set-aside-dialog-width" (onHide)="resetAsideCash()" (onShow)="resetSetAsideCashValues = true">
  <div class="row">
    <div class="col-xs-6">
      <div class="row" *ngIf="!asideCash?.id && !isRaiseCash">
        <div class="col-xs-12">
          <div class="form-group">
            <label for="individualSetAside" class="radio-inline">
              <input id="individualSetAside" data-test-id="individualSetAside" type="radio" checked name="op1" (click)="onSetAsideCreationTypeChange(setAsideCreationTypeConst.Individual)"
                     [checked]="setAsideCreationType === 1">
              <span class="circle"></span>
              <span class="check"></span>
              Individual Set Aside Cash
            </label>
          </div>
          <div class="form-group">
            <label for="dcaRadio" class="radio-inline">
              <input type="radio" value="DCA" name="op1" (click)="onSetAsideCreationTypeChange(setAsideCreationTypeConst.DollarCostAverage)"
                     [checked]="setAsideCreationType === 2" id="dcaRadio" data-test-id="dcaRadio">
              <span class="circle"></span>
              <span class="check"></span>
              Scheduled Dollar Cost Averaging Tranches
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="setAsideCreationType === 1">
        <div class="row">
          <div class="col-xs-12">
            <div class="col-xs-5">
              <div class="form-group">
                <label class="label-fontsize" for="selectCashAmountType">Set Aside Cash Amount</label>
                <div class="clearfix"></div>
                <select id="selectCashAmountType" name="selectAmountType" class="form-control pull-left cash-amount-type"
                  [(ngModel)]="asideCash.cashAmountTypeId" (change)="asideCashAmount($event.target)" [disabled]="isRaiseCash">
                  <option *ngFor="let ca of cashType" value={{ca.id}}> {{ca.name}}</option>
                </select>
                <input type="number" [(ngModel)]="asideCash.cashAmount" (keyup)="validatePercent($event)" step="0.01"
                  name="cashAmount" (ngModelChange)="validateInputFields($event); updateMultiplierValues()"
                  (keypress)="validateEventField($event)" placeholder="Enter Amount" class="form-control cash-amount"
                  [disabled]="isRaiseCash" />
              </div>
            </div>

            <div class="class-xs-5">
              <div class="form-group">
                <label><p-checkbox [(ngModel)]="showMinMaxCashAmount" binary="true" ngDefaultControl (onChange)="onAddMinMaxSetAsideChange()"></p-checkbox>&nbsp;&nbsp;
                  Add Min/Max Set Aside
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showMinMaxCashAmount">
          <div class="col-xs-12">
            <div class="col-xs-5">
              <div class="form-group">
                <label class="label-fontsize" for="minCashAmount">Minimum Set Aside Cash Amount</label>
                <input id="minCashAmount" [disabled]="asideCash.setAsideAccountTypeId == 1 || hasMultipleAccount"
                  name="minCashAmount" type="number" [(ngModel)]="asideCash.minCashAmount" (keyup)="validatePercent($event)"
                  step="0.01" (ngModelChange)=" updateMultiplierValues()"
                  (keypress)="validateEventField($event)" placeholder="Enter Amount" class="form-control" />
              </div>
            </div>
            <div class="col-xs-5 amount-border" *ngIf="asideCash.cashAmountTypeId === asideCashAmountTypeDollar">
              <div class="form-group">
                <label class="label-fontsize" for="minMultiplier">Minimum Multiplier(%)</label>
                <input id="minMultiplier" [disabled]="disableMultiplierFields()" name="minMultiplier" type="number"
                  [(ngModel)]="asideCash.minMultiplier" (keyup)="validateInputFields($event.target)" step="0.01"
                  (ngModelChange)="updateAsideCashValues()" (keypress)="validateEventField($event)"
                  placeholder="Enter Percent" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showMinMaxCashAmount">
          <div class="col-xs-12">
            <div class="col-xs-5">
              <div class="form-group">
                <label class="label-fontsize" for="maxCashAmount">Maximum Set Aside Cash Amount</label>
                <input id="maxCashAmount" [disabled]="asideCash.setAsideAccountTypeId == 1 || hasMultipleAccount"
                  name="maxCashAmount" type="number" [(ngModel)]="asideCash.maxCashAmount" (keyup)="validatePercent($event)"
                  step="0.01" (ngModelChange)="updateMultiplierValues()" (keypress)="validateEventField($event)"
                  placeholder="Enter Amount" class="form-control" />
              </div>
              </div>
              <div class="col-xs-5 amount-border" *ngIf="asideCash.cashAmountTypeId === asideCashAmountTypeDollar">
                <div class="form-group">
                  <label class="label-fontsize" for="maxMultiplier">Maximum Multiplier(%)</label>
                  <input id="maxMultiplier" [disabled]="disableMultiplierFields()"
                    name="maxMultiplier" type="number" [(ngModel)]="asideCash.maxMultiplier" (keyup)="validateInputFields($event.target)"
                    step="0.01" (ngModelChange)="updateAsideCashValues()" (keypress)="validateEventField($event)"
                    placeholder="Enter Percent" class="form-control" />
                </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group" *ngIf="portfolioId">
              <label class="label-fontsize">Set Aside Account</label>
              <select id="selectAccountType" name="selectAccountType" data-test-id="selectAccountType" class="form-control" (change)="onAccountTypeChange($event.target)"
                    [(ngModel)]="asideCash.setAsideAccountTypeId" style="width:212px">
              <option *ngFor="let sat of setAsideAccountType" id="{{sat.name}}" value={{sat.id}}> {{sat.name}}</option>
            </select>
          </div>

          <div class="col-xs-12" *ngIf="asideCash.cashAmountTypeId == 2">
            <div class="form-group">
              <label class="label-fontsize" for="percentCalculationType">Percent Calculation Type</label>
              <select id="percentCalculationType" name="percentCalculationType" [(ngModel)]="asideCash.percentCalculationTypeId" class="form-control">
                <option *ngFor="let item of setAsidePercentCalculationType" value={{item.id}}> {{item.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="setAsideCreationType === 2">
        <div>
          <div class="form-inline">
            <div class="form-group">
              <label class="label-fontsize" for="trancheCumulative">Cumulative DCA Amount</label>
              <div class="clearfix"></div>
              <div class="input-group mb-3">
                <div class="input-group-prepend" style="display: table-cell;">
                  <span>$</span>
                </div>
                <input type="number" id="trancheCumulative" data-test-id="trancheCumulative" name="trancheCumulative"
                       (ngModelChange)="trancheCumulativeChanged($event)"
                       [ngModel]="trancheCumulative" min="0" step="0.01" placeholder="Enter Amount"
                       class="form-control"/>
                <div class="input-group-append" style="display: table-cell;">
                  <button pButton type="button" (click)="calculateCumulativeTrancheAmount()"
                          id="btnCalculateTrancheCumulative" data-test-id="btnCalculateTrancheCumulative" icon="fas fa-calculator"
                          [disabled]="!trancheCount || !trancheInstallment"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="form-inline">
            <div class="form-group">
              <label class="label-fontsize" for="trancheCount">Number of Tranches</label>
              <div class="clearfix"></div>
              <div class="input-group mb-3">
                <div class="input-group-prepend" style="display: table-cell;">
                  <span>&nbsp;&nbsp;</span>
                </div>
                <input type="number" id="trancheCount" data-test-id="trancheCount" [ngModel]="trancheCount" min="1"
                       (ngModelChange)="trancheCountChanged($event)" name="trancheCount"
                       step="1" placeholder="Enter Amount" class="form-control"/>
                <div class="input-group-append" style="display: table-cell;">
                  <button pButton type="button" (click)="calculateTrancheCount()"
                          id="btnCalculateTrancheCount" data-test-id="btnCalculateTrancheCount" icon="fas fa-calculator"
                          [disabled]="!trancheCumulative || !trancheInstallment"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="form-inline">
            <div class="form-group">
              <label class="label-fontsize" for="trancheInstallment">Individual Tranche Amount</label>
              <div class="clearfix"></div>
              <div class="input-group mb-3">
                <div class="input-group-prepend" style="display: table-cell;">
                  <span>$</span>
                </div>
                <input type="number" id="trancheInstallment" name="trancheInstallment" data-test-id="trancheInstallment"
                       (ngModelChange)="trancheInstallmentChanged($event)"
                       [ngModel]="trancheInstallment" min="0" step="0.01" placeholder="Enter Amount"
                       class="form-control"/>
                <div class="input-group-append" style="display: table-cell;">
                  <button pButton type="button" (click)="calculateIndividualTrancheAmount()"
                          id="btnCalculateTrancheIndividual" data-test-id="btnCalculateTrancheIndividual" icon="fas fa-calculator"
                          [disabled]="!trancheCount || !trancheCount"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="portfolioId">
            <label class="label-fontsize">Set Aside Account</label>
            <select id="selectAccountTypeForDCA" name="selectAccountTypeForDCA" data-test-id="selectAccountTypeForDCA" class="form-control" (change)="onAccountTypeChangeForDCA($event.target)"
                  [(ngModel)]="setAsideAccountTypeIdForDCA" style="width:212px">
            <option *ngFor="let sat of setAsideAccountTypeForDCA" id="{{sat.name}}" value={{sat.id}}> {{sat.name}}</option>
          </select>
        </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6">
      <div class="row">
        <div class="col-xs-5">
          <div class="form-group">
            <label>Start Date</label>
            <p-calendar class="m-t-5" [showIcon]="true" [monthNavigator]="true"
                        placeholder="Select Start Date" name="startDateCalendar" id="startDate" data-test-id="startDate"
                        [yearNavigator]="true" [yearRange]="getYearRange(10)" [(ngModel)]="asideCash.startDate"
                        [minDate]="minDate" appendTo="body"
                        [readonlyInput]="true"></p-calendar>
          </div>
        </div>
      </div>
      <div class="row" id="IndividualExpiration" *ngIf="setAsideCreationType === 1">
        <div class="col-xs-3">
          <div class="form-group">
            <label class="label-fontsize" for="selectExpiration">Expiration Type</label>
            <div class="clearfix"></div>
            <select id="selectExpiration" name="selectExpiration" data-test-id="selectExpiration" class="form-control" (change)="onExpirationChange($event.target)"
                    [(ngModel)]="asideCash.expirationTypeId">
              <option *ngFor="let ce of cashExpiration" id="{{ce.name}}" value={{ce.id}}> {{ce.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="asideCash.expirationTypeId == 1" class="col-xs-5">
          <div *ngIf="datepicker" class="form-group">
            <label class="label-fontsize">&nbsp;</label>
            <p-calendar class="m-t-5" [showIcon]="true" [monthNavigator]="true"
                        placeholder="Select Expiry Date" name="expirationValueCalendar" id="expirationDateValue" data-test-id="expirationDateValue"
                        [yearNavigator]="true" [yearRange]="getYearRange(10)" [(ngModel)]="asideCash.expirationValue"
                        [minDate]="minDate" appendTo="body"
                        [readonlyInput]="true"></p-calendar>
          </div>
        </div>
        <div *ngIf="asideCash.expirationTypeId == 2" class="col-xs-5">
          <div class="form-group">
            <label class="label-fontsize" for="selectExpirationValue">&nbsp;</label>
            <select id="selectExpirationValue" name="selectExpirationValue" class="form-control m-t-5" [(ngModel)]="asideCash.expirationValue"
                    (change)="onTransactionExpirationChange($event)">
              <option *ngFor="let ct of cashTransaction" value="{{ct.id}}"> {{ct.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xs-4">
          <div class="form-group" *ngIf="toleranceBand">
            <label class="label-fontsize" for="toleranceValue">Tolerance Band (%)</label>
            <input id="toleranceValue" name="toleranceValue" type="number" min="0" max="100" style="width:158px"
                   [(ngModel)]="asideCash.toleranceValue"
                   (keyup)="validatePercent($event)"
                   (ngModelChange)="validateInputFields($event)"
                   (keypress)="validateEventField($event)" placeholder="Enter Tolerance Value"
                   class="form-control"/>
          </div>
        </div>
      </div>
      <div class="row" id="DCAExpiration" *ngIf="setAsideCreationType === 2">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-4">
              <label>First Expiration</label>
              <div class="form-group">
                <p-calendar [showIcon]="true" [monthNavigator]="true"
                            placeholder="Select Expiry Date" [(ngModel)]="startDateDCA"
                            name="dcaStartDate" appendTo="body"
                            id="dcaStartDate" data-test-id="dcaStartDate"
                            (ngModelChange)="refreshDCADateValues()"
                            [yearNavigator]="true" [yearRange]="getYearRange(10)"
                            [readonlyInput]="true"></p-calendar>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-12">
              <label>Subsequent Expirations</label>
              <i class="fas fa-question-circle fa-fw" aria-hidden="true" [pTooltip]="scheduleDescription"
              id="dcaDescription" data-test-id="dcaDescription"></i>
            </div>
            <div class="col-xs-2">
              <label for="everyValue">Every</label>
            </div>
            <div class="col-xs-3">
              <div class="form-group">
                <input id="everyValue" name="everyValue" data-test-id="everyValue" type="number" min="1" step="1" class="form-control" [(ngModel)]="everyValue"/>
              </div>
            </div>
            <div class="col-xs-5 form-group">
              <select id="selectEveryType" name="selectEveryType" data-test-id="selectEveryType" class="form-control" [(ngModel)]="periodType"
                      (ngModelChange)="refreshDCADateValues()">
                <option *ngFor="let pt of periodTypesList" [ngValue]="pt.value" id="{{pt.value}}">{{pt.name}}</option>
              </select>
            </div>
          </div>
          <div class="row" *ngIf="periodType === 2">
            <div class="col-xs-2">
              <label>On the</label>
            </div>
            <div class="col-xs-8">
              <p-selectButton [options]="dayOfWeekList" [(ngModel)]="dayOfWeek" id="dayOfWeekSelector" name="dayOfWeekSelector" data-test-id="dayOfWeekSelector"></p-selectButton>
            </div>
          </div>
          <div class="row" *ngIf="periodType === 3">
            <div class="col-xs-2">
              <label>On the</label>
            </div>
            <div class="col-xs-10">
              <div>
                <!-- Exact day of month:  3rd, 21st, etc -->
                <label for="onDirectRadio">
                  <input id="onDirectRadio" data-test-id="onDirectRadio" type="radio" [(ngModel)]="monthExactDynamicValue" name="onMonth" [value]="1">
                  <span class="circle"></span>
                  <span class="check"></span>
                  {{dayOfWeekSummary?.ordinal}}
                </label>
              </div>
              <div>
                <!-- Dynamic day of month:  3rd Wednesday, Last Thursday, etc -->
                <label for="onDynamicRadio">
                  <input id="onDynamicRadio" data-test-id="onDynamicRadio" type="radio" [(ngModel)]="monthExactDynamicValue" name="onMonth" [value]="2">
                  <span class="circle"></span>
                  <span class="check"></span>
                </label>
                <div class="form-group" style="display: inline-block;" *ngIf="dayOfWeekSummary?.rank >= 4">
                  <select id="onDynamicValue" name="onDynamicValue" data-test-id="onDynamicValue" class="form-control m-t-5" [(ngModel)]="onDynamicValue">
                    <option value="4" id="optMonthDynamicDayFourth">Fourth</option>
                    <option value="5" id="optMonthDynamicDayLast">Last</option>
                  </select>
                </div>
                <label>
                  <span class="m-l-5" *ngIf="dayOfWeekSummary?.rank < 4">{{dayOfWeekSummary?.rankName}}</span>
                  <span>&nbsp;{{dayOfWeekSummary?.dayName}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="periodType === 4">
            <div class="col-xs-2">
              <label>On the</label>
            </div>
            <div class="col-xs-10">
              <div>
                <!-- Exact day of month:  3rd, 21st, etc -->
                <label for="onDirectYearRadio">
                  <input id="onDirectYearRadio" data-test-id="onDirectYearRadio" type="radio" [(ngModel)]="monthExactDynamicValue" name="onDayOfMonth" [value]="1">
                  <span class="circle"></span>
                  <span class="check"></span>
                  {{dayOfWeekSummary?.ordinal}} of {{dayOfWeekSummary?.monthName}}
                </label>
              </div>
              <div>
                <!-- Dynamic day of month:  3rd Wednesday, Last Thursday, etc -->
                <label for="onDayOfMonthDynamicRadio">
                  <input id="onDayOfMonthDynamicRadio" data-test-id="onDayOfMonthDynamicRadio" type="radio" [(ngModel)]="monthExactDynamicValue"
                         name="onDayOfMonthDynamicRadio" [value]="2">
                  <span class="circle"></span>
                  <span class="check"></span>
                </label>
                <div class="form-group" style="display: inline-block;" *ngIf="dayOfWeekSummary?.rank >= 4">
                  <select id="dayOfWeekValueList" name="dayOfWeekValueList" data-test-id="dayOfWeekValueList" class="form-control m-t-5" [(ngModel)]="onDynamicValue">
                    <option value="4" id="optYearDynamicDayFourth">Fourth</option>
                    <option value="5" id="optYearDynamicDayLast">Last</option>
                  </select>
                </div>
                <label>
                  <span class="m-l-5" *ngIf="dayOfWeekSummary?.rank < 4">{{dayOfWeekSummary?.rankName}}</span>
                  <span>&nbsp;{{dayOfWeekSummary?.dayName}} of {{dayOfWeekSummary?.monthName}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-t-15">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="label-fontsize" for="description">Description</label>
            <textarea id="description"
              name="description"
              data-test-id="description"
              maxlength="250" class="form-control"
              [(ngModel)]="asideCash.description"
              placeholder="250 Characters Only"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <span class="text-danger" *ngIf="setAsideCashError"> {{setAsideCashError}} </span>
    <span class="text-danger span-message" *ngIf="hasMultipleAccount && showMinMaxCashAmount"> {{disableMinMaxFieldsWarning}} </span>
    <button type="button" class="p-button-secondary" pButton (click)="resetAsideCash();"
            id="btnCancel" data-test-id="btnCancel" label="Cancel"></button>
    <button *ngIf="!externalSavePrompt" type="button" pButton  (click)="onAsideCashSave()"
            id="btnSave" data-test-id="btnSave" [disabled]="!enableSaveButton()" label="Save">
    </button>
    <button *ngIf="externalSavePrompt" type="button" pButton (click)="onDoneEditing()"
            id="btnDoneEditing" data-test-id="btnDoneEditing" [disabled]="!enableSaveButton()" label="Done">
    </button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="displayEditBillAsideCashConfirm" modal="true" appendTo="body" [draggable]="false" [resizable]="false"
          class="ui-dialog-md">
  <div>
    <p>Please note: You are editing your billing set aside cash.
      <br/>The next cash funding sync from Orion Connect will override this setting,
      <br/>are you sure you want to continue?</p>
  </div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" (click)="displayEditBillAsideCashConfirm = false" label="No"
            id="btnCancelEditBillingConfirmation" data-test-id="btnCancelEditBillingConfirmation"></button>
    <button type="button" pButton icon="fas fa-check" (click)="onEditBillingSetAsideCash()" [disabled]="savingSetAside" label="Yes"
            id="btnAcceptEditBillingConfirmation" data-test-id="btnAcceptEditBillingConfirmation"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="displayDeleteConfirm" modal="true" appendTo="body" [draggable]="false" [resizable]="false"
          class="ui-dialog-md">
  <div>This action will delete the Set Aside Cash permanently.
    <br/>Do you wish to continue?
  </div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" (click)="displayDeleteConfirm = false" label="No"
            id="btnCancelDeleteConfirmation" data-test-id="btnCancelDeleteConfirmation"></button>
    <button type="button" pButton icon="fas fa-check" (click)="deleteAside()" [disabled]="savingSetAside" label="Yes"
            id="btnAcceptDeleteConfirmation" data-test-id="btnAcceptDeleteConfirmation"></button>
  </p-footer>
</p-dialog>
