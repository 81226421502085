<div class="row">
  <p>Money Market Fund</p>
  <div class="agcontainer audit-grid">
    <ag-grid-angular class="ag-theme-alpine grid-height-autosizefive" [gridOptions]="fundGridOptions"
      [columnDefs]="fundColumnDefinition" [rowData]="moneyMarketFundsAuditHistories" [context]="gridContext" (gridReady)="onFundGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
<div class="row">
  <p>Money Market Allocation</p>
  <div class="agcontainer audit-grid">
    <ag-grid-angular class="ag-theme-alpine grid-height-autosizefive" [gridOptions]="allocationGridOptions"
      [columnDefs]="allocationColumnDefinition" [rowData]="moneyMarketAllocationAuditHistories" [context]="gridContext" (gridReady)="onAllocationsGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
