import {NgModule} from '@angular/core';
import {LoginAsComponent} from './loginAs.component';
import {LoginService} from './login.service';
import {AuthorizeComponent} from './authorize.component';
import {SharedModule} from '../shared/shared.module';
import { LoginRedirectComponent } from './login-redirect.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LoginAsComponent, AuthorizeComponent, LoginRedirectComponent
  ],
  providers: [
    LoginService
  ]
})
export class LoginModule {

}
