import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { TagType } from '../../../shared/tags';
import { PortfolioEditorService } from './portfolio-editor.service';
import { Utils as Util } from '../../../core/functions';
import * as Consts from '../../../libs/app.constants';
import { AnalyticsStatus } from '../../../libs/app.constants';
import { IAnalyticsNotification } from '../../../models/notification';
import { EntityType as NoteEntityType } from '../../../libs/preference.enums';
import { forkJoin, Subject, switchMap, takeUntil, filter } from 'rxjs';
import { PreferenceService } from '../../../services/preference.service';
import { IModel } from '../../../models/modeling/model';
import { ModelService } from '../../../services/model.service';
import { IIdName } from '../../../models/tom';
import { IPreferences } from '../../../models/preferences/preference';

@Component({
  selector: 'eclipse-portfolio-editor-summary',
  templateUrl: './portfolio-summary.component.html'
})
export class PortfolioSummaryComponent extends BaseComponent {
  public readonly tacticalToolPrivilege: boolean = Util.hasTradeToolRight(Consts.PRIV_TACTICAL);
  public readonly cashNeedToolPrivilege: boolean = Util.hasTradeToolRight(Consts.PRIV_CASHNEEDS);
  public readonly rebalancerToolPrivilege: boolean = Util.hasTradeToolRight(Consts.PRIV_REBALANCER);
  public readonly tlhToolPrivilege: boolean = Util.hasTradeToolRight(Consts.PRIV_TAXHARVESTING);
  public readonly portfolioEditPermission: boolean = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;
  public readonly portfolioPreferencePermission: boolean = !!Util.getPermission(Consts.PRIV_PORTFOLIOPREF)?.canRead;

  public noteEntityType = NoteEntityType;
  public tagTypes = TagType;
  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public readonly MAX_NOTES_TO_DISPLAY: number = 4;
  public maxCapGainAmountPreference: IPreferences;
  public modelSuggestions: IModel[] = [];
  private destroyed$: Subject<void> = new Subject<void>();
  private readonly _preferenceService: PreferenceService = inject(PreferenceService);
  private readonly _modelService: ModelService = inject(ModelService);

  constructor() {
    super();
    this.portfolioEditorService.portfolio$
      .pipe(
        takeUntil(this.destroyed$),
        filter(p => !!p.id),
        switchMap((p) => forkJoin({
          preferences: this._preferenceService.getpreferencesForLevel('Portfolio', p.id)
        }))
      )
      .subscribe(({preferences}) => {
        this.maxCapGainAmountPreference = preferences.preferences.find(pref => pref.name === 'maxGainAmount');
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refreshData(analyticsNotification: IAnalyticsNotification): void {
    // this.isAnalyticsRan = true; // TODO JN needed for refreshing mac weights ?
    if (!analyticsNotification?.portfolioFlags?.length) {
      return;
    }
    const portfolio = analyticsNotification.portfolioFlags.find(flag => flag.portfolioId === +this.portfolioEditorService.portfolio?.id);
    if (portfolio && (portfolio.needAnalytics !== AnalyticsStatus.Running || analyticsNotification.isAnalyticsCompleted)) {
      if (!!this.portfolioEditorService.portfolio?.id) {
        this.portfolioEditorService.portfolioId$.next(this.portfolioEditorService.portfolio?.id);
        //this.getPortfolioDetailsById(this.portfolioId);
        //this.getOriginalAndSubstitutedModelDetailsByPortfolioId();
      } else {
        //this.getTeams(true);
      }
    } else if (portfolio && portfolio.needAnalytics === AnalyticsStatus.Running) {
      // this.portfolio.needAnalyticsStatus = Util.getNeedAnalyticsStatus(AnalyticsStatus.Running);
    }
  }

  onSelectedTeamsChanged() {
    this.portfolioEditorService.portfolio.teams = this.portfolioEditorService.teams.filter(team => this.portfolioEditorService.portfolio.teamIds.includes(team.id));
    if (this.portfolioEditorService.portfolio.primaryTeamId
      && !this.portfolioEditorService.portfolio.teamIds.includes(this.portfolioEditorService.portfolio.primaryTeamId)) {
      this.portfolioEditorService.portfolio.primaryTeamId = null;
    }
  }

  searchModels(event: any): void {
    this._modelService.getModelSearch(event.query.toLowerCase())
      .subscribe((models: IModel[]) => {
        this.modelSuggestions = models.filter(model => !model.isDeleted && model.statusId === Consts.modelStatusEnum.APPROVED);
        this.formatModelSuggestion(this.modelSuggestions, event);
      });
  }

  onModelSelect(model: IIdName) {
    console.log('new model', model);
    this.portfolioEditorService.portfolio.modelId = model.id;
    this.portfolioEditorService.portfolio.modelName = model.name;
    this.portfolioEditorService.portfolio.model = model;
    /*this.selectedModelObject = model;
    this.isAssignModel = true; // assign model flag
    // eslint-disable-next-line eqeqeq
    if ((this.initialModelId != model.id) && (this.securityWeightingComponent && this.securityWeightingComponent.macStatus === Consts.MACWeightingStatus.Portfolio.value)) {
      this.displayMacPopup = true; // Show MAC Weightings Confirm popup
    } else {
      this.onModelSelectConfirm(this.selectedModelObject, assignModelToPortfolio);
      this.showMACWarningForNewlyAssignedModel(model.isMAC);
    }*/
  }

  showModelClearConfirm: boolean = false;
  modelCleared() {
    this.showModelClearConfirm = true;
  }

  confirmModelClear() {
    this.portfolioEditorService.portfolio.model = null;
    this.portfolioEditorService.portfolio.modelId = null;
    this.portfolioEditorService.portfolio.modelName = null;
  }
}
