<p-dialog [(visible)]="analysePortfolio" header="{{analysePortfolioComponent?.portfolioSleeveComparison || ''}}" [resizable]="false" modal="true"
          class="ui-dialog-xl" (onHide)="onAfterHide()">
  <eclipse-analyse-portfolio [portfolioId]="portfolioId" [portfolioName]="portfolioName" [modelId]="modelId"
                     [modelName]="modelName" [sleeveId]="sleeveId" [sleeveName]="sleeveName" [sleeveAccountId]="sleeveAccountId"
                     [reserveCash]="reserveCash" [setAsideCash]="setAsideCash" [reserveCashPercent]="reserveCashPercent" [setasideCashPercent]="setasideCashPercent">
  </eclipse-analyse-portfolio>
  <p-footer>
    <button type="button" pButton label="Close" (click)="analysePortfolio = false"></button>
  </p-footer>
</p-dialog>
