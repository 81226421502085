import {DatePipe, DOCUMENT} from '@angular/common';
import {DateFormatPipe} from '../pipes/FilterPipe';
import {SessionHelper} from './session.helper';
import {IRole, IRolePrivilege} from '../models/users.models';
import moment from 'moment-timezone';
import * as moment_All from 'moment';
import * as Consts from '../libs/app.constants';
import {IAccountSimpleSearch, IFormattedAccountSuggestions} from '../models/account';
import {GridOptions, ValueFormatterParams} from '@ag-grid-community/core';
import {BaseGridConfiguration} from '../shared/gridextensions/basegrid';
import {IIdName} from '../models/tom';
import {Utils as Util} from './functions';

export class BaseComponent {
  private dom;
  public _sessionHelper: SessionHelper;
  public permission: IRolePrivilege;
  public activeRoute: string;
  public isOrionAdmin: boolean;
  public isFirmAdmin: boolean;
  public isTeamAdmin: boolean;
  public isUser: boolean;
  public isApiOnly: boolean;
  public get defaultGridOptions(): GridOptions { return BaseGridConfiguration.defaultGridOptions(); }
  public get defaultFilterableGridOptions(): GridOptions { return BaseGridConfiguration.defaultGridOptions(true); }

  formattedAccountSuggestions: IFormattedAccountSuggestions[] = [];

  /**
   * Contructor
   */
  constructor(privilegeCode: string = '', sessionHelper: SessionHelper = undefined) {
    this.dom = DOCUMENT;
    this._sessionHelper = sessionHelper || new SessionHelper();
    // eslint-disable-next-line eqeqeq
    if (this.permission == undefined && privilegeCode != '') {
      this.permission = this._sessionHelper.getPermission(privilegeCode);
    }
    // role type permission check implementation
    const role = this._sessionHelper.get<IRole>('role');
    if (role && role.roleTypeId > 0) {
      this.isOrionAdmin = (role.roleTypeId === Consts.UserType.OrionAdmin);
      this.isFirmAdmin = (role.roleTypeId === Consts.UserType.FirmAdmin);
      this.isTeamAdmin = (role.roleTypeId === Consts.UserType.TeamAdmin);
      this.isUser = (role.roleTypeId === Consts.UserType.User);
      this.isApiOnly = (role.roleTypeId === Consts.UserType.APIOnly);
    }
  }

  getPermission(privilegeCode: string = '') {
    return this._sessionHelper.getPermission(privilegeCode);
  }


  /**
   * spinner methods
   */
  showSpinner() {
    this.dom.removeClass(this.dom.query('spinner'), 'hide-spinner');
  }

  /**
   * spinner methods
   */
  hideSpinner() {
    this.dom.addClass(this.dom.query('spinner'), 'hide-spinner');
  }

  /*** date formate MM/dd/yyyy */
  dateRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params == undefined) { return; }
    // eslint-disable-next-line eqeqeq
    if (params.value == '0000-00-00 00:00:00' || params.value == '0000-00-00' || params.value == null || params.value == '0' || params.value == '') {
      return '';
    }
    return new DatePipe('en-US').transform(params.value, 'short');
  }

  /*** Time formate hh:mm:ss a */
  timeRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params.value == '0000-00-00 00:00:00') {
      return '';
    }
    return new DatePipe('en-US').transform(params.value, 'hh:mm:ss a');
  }

  /** formates the date to given format, default format is MM/dd/yyyy */
  formatDate(date, format = '') {
    // eslint-disable-next-line eqeqeq
    if (!date || date == '0000-00-00 00:00:00' || date == '0000-00-00') {
      return '';
    }
    return new DateFormatPipe().transform(date, format);
  }

  /**TODO: Remove this!!!! formates the date to given format, default format is 'yyyy-MM-dd' TODO: Remove this!!!!*/
  formatDateWithFormat(date, format = 'yyyy-MM-dd') {
    // eslint-disable-next-line eqeqeq
    if (date == '0000-00-00 00:00:00' || date == '0000-00-00') {
      return '';
    }
    return new DatePipe('en-US').transform(date, format);
  }

  getDate(days) {
    const date = new Date();
    if (days < 0 || days > 0) { date.setDate(date.getDate() + days); }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  resetTimeToZero(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  getDateForCounts(days: number = -30) {
    return this.getDate(days);
  }

  getToday() {
    return this.getDate(0);
  }

  getWorkday(date: Date = this.getToday()) {
    switch (date.getDay()) {
      // If it is Monday (1),Saturday(6), or Sunday (0), Get the previous Friday (5)
      // and ensure we are on the previous week
      case 0:
      case 6:
        date.setDate(date.getDate() - 6);
        date.setDate(date.getDate() + (5 - date.getDay()));
      // If it any other weekend, just return the previous day
      default:
    }
    return date;
  }

  /**Generate Dynamic Color Codes */
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /** */
  isValidExcelFile(fileType) {
    // eslint-disable-next-line eqeqeq
    return ((fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      // eslint-disable-next-line eqeqeq
      (fileType == 'application/vnd.ms-excel') || (fileType == 'text/csv'));
  }

  trueRenderer = params => params.value && params.value !== 'false'
    ? '<span><i class="fas fa-check-circle text-success" aria-hidden="true"></i></span>'
    : '';

  dateAndTimeRenderer(params, incomingFormat: string = null) {
    if (params.node && params.node.group) {
      return null;
    }
    if (params.value === '0000-00-00 00:00:00' || params.value === '0000-00-00T00:00:00Z' || params.value === '') {
      return '';
    }
    // eslint-disable-next-line eqeqeq
    const dateValue = params.value != undefined ? params.value : params;
    if (!!incomingFormat) {
      return new DateFormatPipe().transformWithFormat(dateValue, incomingFormat,'MM/DD/YYYY hh:mm:ss A');
    } else {
      return new DateFormatPipe().transform(dateValue, 'MM/DD/YYYY hh:mm:ss A');
    }
  }

  dateAndTimeRendererHighPrecision(params) {
    if (params.node && params.node.group) {
      return null;
    }
    // eslint-disable-next-line eqeqeq
    if (params.value == '0000-00-00 00:00:00' || params.value == '0000-00-00T00:00:00Z' || params.value == '') {
      return '';
    }
    // eslint-disable-next-line eqeqeq
    if (params.value != undefined) {
      return new DateFormatPipe().transform(params.value, 'MM/DD/YYYY hh:mm:ss SSS A');
    } else {
      return new DateFormatPipe().transform(params, 'MM/DD/YYYY hh:mm:ss A');
    }

  }

  /** Render date format */
  formatDateCellRenderer(params): string {
    // let column = params.column.colId;
    // eslint-disable-next-line eqeqeq
    if (params.value == '0000-00-00 00:00:00' || params.value == '0000-00-00' || params.value == null || params.value == '0' || params.value == '') { return ''; }
    // To restrict other than date columns when we applyed grouping on grid columns
    const date = new Date(params.value);
    if (isNaN(date.getMonth())) { return; }

    // Ignores timezone -> 2000-01-01T06:00:00.000Z becomes 01/01/2000
    return new DateFormatPipe().transform(params.value, 'MM/DD/YYYY');
  }

  /** Render UTC date format */
  formatUTCDateCellRenderer(params): string {
    // Ignore the UTC timezone when formatting the date
    return BaseComponent.formatUTCDate(params.value, 'MM/DD/YYYY');
  }

  /** Render Local date format */
  formatLocalDateCellRenderer(date: string): string {
    // Ignore the UTC timezone when formatting the date
    return BaseComponent.formatUTCDate(date, 'DD/MM/YYYY');
  }

  /** Formats a UTC date, ignoring the local browser's timezone
   *
   * 2000-01-01T06:00:00.000Z (MM/DD/YYYY format) becomes 01/01/2000
   * 2000-01-01T00:00:00.000Z (MM/DD/YYYY format) becomes 01/01/2000
   * **/
  static formatUTCDate(utcDate: string, format: string): string {
    // eslint-disable-next-line eqeqeq
    if (utcDate == '0000-00-00 00:00:00' || utcDate == '0000-00-00' || utcDate == null || utcDate == '0' || utcDate == '') { return ''; }
    const date = new Date(utcDate);
    if (isNaN(date.getMonth())) { return; }
    return moment_All.utc(utcDate).format(format);
  }

  /** Render date and time format */
  formatDateTimeCellRenderer(params): string {
    // let column = params.column.colId;
    // eslint-disable-next-line eqeqeq
    if (params.value == '0000-00-00 00:00:00' || params.value == '0000-00-00' || params.value == null || params.value == '0' || params.value == '') { return ''; }
    // To restrict other than date columns when we applyed grouping on grid columns
    const date = new Date(params.value);
    if (isNaN(date.getMonth())) { return; }
    return new DateFormatPipe().transform(params.value, 'MM/DD/YYYY hh:mm:ss A');
  }

  /** Render UTC date format */
  formatUTCDateTimeCellRenderer(params): string {
    if(!params.context?.self) {
      console.error('Grid context is missing `self`.  This property is required.');
      return null;
    }

    return params.context.self.formatUTCToLocal(params.value);
  }

  /**
   * Converts a UTC date/time to the browser local date/time.
   * @param value
   */
  formatUTCToLocal(value: string, format: string = 'MM/DD/YYYY hh:mm:ss A'): string {
    if (value === '0000-00-00 00:00:00' || value === '0000-00-00' || value === null || value === '0' || value === '') { return ''; }
    const date = new Date(value);
    if (isNaN(date.getMonth())) { return; }
    return moment_All.utc(value).local().format(format);
  }

  /** To render with currency format */
  currencyValueFormatter(params) {
    if (params.value === null || params.value === undefined) {
      return null;
    }
    const currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return currencyFormat.format(params.value);
  }

  /** To render with currency format */
  formatCurrencyCellRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params.value != null || params.value != undefined) {
      const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });
      return currencyFormat.format(params.value);
    }
    return null;
  }

  // Method to convert local date to UTC date
  getUTCFormattedDate(dateString, type = 0): string {
    // type = 0 : startdate/fromdate , type=1 enddate/todate
    const date = new Date(dateString);
    // eslint-disable-next-line eqeqeq
    if (type == 1) {
      // var date = new Date(dateString + " 23:59:59");//adding day end time
      date.setHours(23, 59, 59, 999); // adding day end time
    } else {
      date.setHours(0, 0, 0, 0); // adding day start time
    }
    let result = '';
    // eslint-disable-next-line prefer-template
    result = result + date.getUTCFullYear() + '-';
    // ("0" + "10").slice(-2) gives "10"
    // eslint-disable-next-line prefer-template
    result = result + (('0' + (date.getUTCMonth() + 1)).slice(-2)) + '-'; // month is zero- indexed, so incrementing by 1.
    // eslint-disable-next-line prefer-template
    result = result + ('0' + date.getUTCDate()).slice(-2) + ' ';
    // eslint-disable-next-line prefer-template
    result = result + ('0' + date.getUTCHours()).slice(-2) + ':';
    // eslint-disable-next-line prefer-template
    result = result + ('0' + date.getUTCMinutes()).slice(-2) + ':';
    result = result + (`0${  date.getUTCSeconds()}`).slice(-2);
    return result;

  }


  /** To apply Date time format for grid filter, based on value getter while applying filter on DateTime column */
  dateTimeFormatOnGridFilter(date) {
    if (date && date.colDef) { date = date.data[date.colDef.field]; } // Allow passing method definition rather than passing closure
    // eslint-disable-next-line eqeqeq
    if (date == '0000-00-00 00:00:00' || date == '0000-00-00' || date == null || date == '0' || date == '') { return ''; }
    // To restrict other than date columns when we applyed grouping on grid columns
    const dt = new Date(date);
    if (isNaN(dt.getMonth())) { return; }
    return new DateFormatPipe().transform(date, 'MM/DD/YYYY hh:mm:ss A');
  }

  /** To apply Date format for grid filter, based on value getter while applying filter on Date column */
  public dateFormatOnGridFilter(date) {
    if (date && date.colDef) { date = date.data[date.colDef.field]; } // Allow passing method definition rather than passing closure
    // eslint-disable-next-line eqeqeq
    if (date == '0000-00-00 00:00:00' || date == '0000-00-00' || date == null || date == '0') { return ''; }
    // To restrict other than date columns when we applyed grouping on grid columns
    const dt = new Date(date);
    if (isNaN(dt.getMonth())) { return; }
    return new DatePipe('en-US').transform(date, 'MM/dd/yyyy');
  }

  /** To apply Date time format for grid filter, based on value getter while applying filter on DateTime column */
  dateTimeFormatOnValueGetter(date) {
    if (date && date.colDef) { date = date.data[date.colDef.field]; } // Allow passing method definition rather than passing closure
    if (!date || date === '0000-00-00 00:00:00' || date === '0000-00-00' || date === null || date === '0' || date === '') { return null; }
    // To restrict other than date columns when we applied grouping on grid columns
    const dt = new Date(date);
    if (isNaN(dt.getMonth())) { return null; }
    return moment(dt).format('MM/DD/YYYY hh:mm:ss A');
  }

   /** To apply Date time format for grid filter, based on value getter while applying filter on DateTime column */
   dateTimeFormatHighPrecisionOnValueGetter(date) {
    if (date && date.colDef) { date = date.data[date.colDef.field]; } // Allow passing method definition rather than passing closure
    // eslint-disable-next-line eqeqeq
    if (date == '0000-00-00 00:00:00' || date == '0000-00-00' || date == null || date == '0' || date == '') { return ''; }
    // To restrict other than date columns when we applied grouping on grid columns
    const dt = new Date(date);
    if (isNaN(dt.getMonth())) { return; }
    return moment(dt).format('MM/DD/YYYY hh:mm:ss SSS A');
  }

  /** To apply Date format for grid filter, based on value getter while applying filter on Date column */
  dateFormatOnValueGetter(date) {
    if (date && date.colDef) { date = date.data[date.colDef.field]; } // Allow passing method definition rather than passing closure
    if (!date || date === '0000-00-00 00:00:00' || date === '0000-00-00' || date === null || date === '0' || date === '') { return null; }
    // To restrict other than date columns when we applied grouping on grid columns
    const dt = new Date(date);
    if (isNaN(dt.getMonth())) { return null; }
    return moment(dt).format('MM/DD/YYYY');
  }

  /** Returns a range of years used as a default for calendar picker year dropdowns. */
  getYearRange(yearsInFuture: number = 0): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() + yearsInFuture);
    return `2000:${date.getFullYear()}`; // 2000 -> current year
  }

  /**
   * Formats ID/Name objects for display in an autocomplete search.
   * `displayFormat` and `selectedItemFormat` properties are added to each object.
   * @param suggestions
   */
  formatIdNameSuggestions(suggestions: IIdName[]): void {
    suggestions.forEach(item => {
      const name = item.name || '<blank>';
      const displayFormat = `${item.id}: ${name}`;
      const selectedItemFormat = !name ? `${item.id}: <blank>` : name;
      Object.defineProperty(item, 'displayFormat', {value: displayFormat});
      Object.defineProperty(item, 'selectedItemFormat', {value: Util.escapeUnsafeText(selectedItemFormat)});
    });
  }

  /** Format suggestions to display on portfolio search */
  formatPortfolioSuggestions(suggestions, event) {
    suggestions.forEach(p => {
      // To display accountNumber and accountName when only search key matches with accountNumber
      const accName = !!p.accountName ? (`, ${p.accountName}`) : '';
      // eslint-disable-next-line prefer-template
      const accNoAndName = ((!!p.accountNumber && p.accountNumber.toLowerCase().search(event.query.toLowerCase()) !== -1) ? (', ' + p.accountNumber + accName) : '');

      // To search portfolio tag with 'search key' and dispalying matched tags on results
      let tag = '';
      if (p.tags !== undefined && p.tags !== null) {
        const matchedTags = p.tags.toLowerCase().split(',').filter(a => a.toLowerCase().search(event.query.toLowerCase()) !== -1).toString();
        tag = (matchedTags !== '') ? (`, ${matchedTags}`) : '';
      }
      // eslint-disable-next-line prefer-template
      const displayFormat = p.id + ': ' + p.name + ' (' + '$' + p.value + accNoAndName + tag + ')';
      const selectedItemFormat = !p.name ? `${p.id}: <blank portfolio name>` : p.name;
      Object.defineProperty(p, 'displayFormat', {value: displayFormat});
      Object.defineProperty(p, 'selectedItemFormat', {value: Util.escapeUnsafeText(selectedItemFormat)});
    });
  }

  /** Format suggestions to display on account search */
  formatAccountSuggestions(suggestions: IAccountSimpleSearch[], event) {
    this.formattedAccountSuggestions = [];
    suggestions.forEach(a => {
      const pfName = (a.portfolioName !== null && a.portfolioName.toLowerCase().search(event.query.toLowerCase()) !== -1) ? `, ${a.portfolioName}` : '';
      const displayFormat = `${a.accountId}: ${a.accountNumber} (${a.name}${pfName})`;
      const selectedItemFormat = !a.name ? `${a.accountId || '<blank account Id>'}: <blank account name>` : a.name;
      const acc = {
        id: a.id,
        name: a.name,
        accountId: a.accountId,
        accountType: a.accountType,
        accountNumber: a.accountNumber,
        portfolioName: a.portfolioName,
        isDeleted: a.isDeleted,
        isSleeve: a.isSleeve,
        modelId: a.modelId,
        portfolioId: a.portfolioId,
        value: a.value,
        createdOn: a.createdOn,
        createdBy: a.createdBy,
        editedOn: a.editedOn,
        editedBy: a.editedBy,
        macStatus: a.macStatus,
        displayFormat: displayFormat,
        selectedItemFormat: Util.escapeUnsafeText(selectedItemFormat),
        needAnalytics: a.needAnalytics,
        failedReason: a.failedReason,
        hasTaxLossHarvest: a.hasTaxLossHarvest,
        displayName: `${a.accountId} ${a.name} • ${a.accountType} • ${a.accountNumber}`,
        hasContribution: a.hasContribution,
        hasDistribution: a.hasDistribution
      };
      this.formattedAccountSuggestions.push(acc);
    });
  }

  /** Format suggestions to display on model search */
  formatModelSuggestion(suggestions, event) {
    const searchString = event.query.toLowerCase();
    suggestions.forEach(model => {
      // To search model tag with 'search key' and displaying matched tags on results
      let tag = '';
      if (model.tags) {
        const matchedTagsArray = model.tags.toLowerCase().split(',');
        const matchedTags = matchedTagsArray.filter(a => a.toLowerCase().includes(searchString)).toString();
        tag = (matchedTags !== '') ? (` (${matchedTags})`) : '';
      }
      const displayFormat = `${model.id}: ${model.name},${model.nameSpace}${tag}`;
      Object.defineProperty(model, 'displayFormat', { value: displayFormat });
    });
  }

  /** Using for date column sorting
   * Bug Fix:OEF-7464 Sorting Date Column on Users grid
   */
  dateComparator(date1, date2) {
    // both are null
    if(!date1 && !date2) {
      return 0;
    }
    // date1 is null but date 2 is not
    else if(!date1 && date2) {
      return -1;
    }
    //date1 is not null and date2 is null
    else if(date1 && !date2) {
      return 1;
    }

    //both are not null
    const dateA = new Date(date1);
    const dateB = new Date(date2);
    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else if (dateA.getDate() === dateB.getDate()) {
      return 0;
    }
  }

  isFiniteNumber(number) {
    return isFinite(number) && !isNaN(number);
  }

  numberStringComparator(valueA: any, valueB: any) {
    if (valueA === null || valueA === '') { return -1; }
    if (valueB === null || valueB === '') { return 1; }
    if (isNaN(parseInt(valueA, 10)) || isNaN(parseInt(valueB, 10))) {
      return (valueA?.toString() ?? '').localeCompare(valueB.toString());
    }
    return valueA - valueB;
  }

  /**
   * Prompts the browser to download the given data as a file, after first converting it from an ASCII string
   * representation of a byte array into an actual byte array.
   * @param bytes base64 string representing a byte array
   * @param fileName name of the file that will be written
   * @param type MIME type, e.g., application/json or text/vnd.ms-excel
   */
  downloadByteArrayFile(bytes: string, fileName: string, type: string) {
    const byteCharacters = atob(bytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    this.downloadFile(byteArray, fileName, type);
  }

  /**
   * Prompts the browser to download the given blob data as a file
   * @param data Blob data
   * @param fileName name of the file that will be written
   * @param type MIME type, e.g., application/json or text/vnd.ms-excel
   */
  downloadFile(data: any, fileName: string, type: string) {
    const blob = new Blob([data], { type: type });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', fileName);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  percentValueFormatter<T>(params: ValueFormatterParams<T>): string {
    if (params.value === null || params.value === undefined) {
      return null;
    }
    return new Intl.NumberFormat(Consts.LOCALS.EN_US, {
      style: Consts.NUMBER_FORMAT_STYLES.PERCENT,
      minimumFractionDigits: Consts.tacticalDecimalEnum.percentageDecimalForDisplay,
      maximumFractionDigits: Consts.tacticalDecimalEnum.percentageDecimalForDisplay
    })
      .format(params.value / 100);
  }
}
