<div class="col-md-12" *ngIf="dialogType==='portfolio'">

  <div class="form-group row">
    <label class="label-top-space col-sm-4 ">Select portfolio</label>
    <div class="col-sm-8">
      <eclipse-portfolio-auto-complete #portfolioSearchComp
                                       [forTactical]="forTacticalTool" (parentCallback)="getSelectedPortfolio($event)"></eclipse-portfolio-auto-complete>
    </div>
  </div>

  <div class="form-group row" *ngIf="!forTacticalTool && isSleevePortfolio">
    <label class="label-top-space col-sm-4">Account</label>
    <div class="col-sm-8">
      <p-multiSelect class="autocomplete-block" [(ngModel)]="sleevedAccounts" (onChange)="getSelectedAccount(sleevedAccounts)"
                     [options]="portfolioAccounts" defaultLabel="Choose Account(s)">
      </p-multiSelect>
    </div>
  </div>

  <div class="form-group row" *ngIf="showDefaultAction">
    <label class="col-sm-4">Default Action</label>
    <div class="col-sm-8">
      <select class="form-control" [(ngModel)]="queryparams.defaultAction" (change)="onDefaultAction($event.target.value)">
        <option value="N">Not Specified</option>
        <option value="BUY">BUY</option>
        <option value="SELL">SELL</option>
      </select>
    </div>
  </div>
</div>
<footer class="p-footer text-right" *ngIf="dialogType==='portfolio'">
    <button type="submit" pButton (click)="continueClick()" [disabled]="!(this.queryparams.portfolioId) || (errors.length!=0)">Continue</button>
    <button pButton class="p-button-secondary" (click)="closePopup()">Cancel</button>
</footer>

<div *ngIf="dialogType==='confirm'">
  <div>You may have Holding and Trade Details that have not been saved. Are you sure you want to continue?</div>
  <footer class="p-footer text-right">
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fas fa-check" (click)="continueDeleteTrades()" label="Yes"></button>
      <button type="submit" pButton icon="fas fa-times" class="p-button-secondary" (click)="closePopup()" label="No"></button>
    </div>
  </footer>
</div>
