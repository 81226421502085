import { Injectable } from '@angular/core';
import * as d3 from 'd3';

@Injectable()
export class ProgressBarCharts {

  public createProgressPieChart(parent, chartData) {

    // define variables
    const percent = chartData['warning'];
    const firm = chartData['moduleName'];
    const totalAmount = chartData['total'];
    const ratio = percent / totalAmount;
    const w = 240;
    const h = 240;
    const margintop = 20;
    const marginbottom = 20;
    const marginleft = 40;
    const marginright = 20;
    const outerRadius = (w / 2) - 10;
    const innerRadius = (w / 2) - 30;
    const color = ['#1B9ED0', '#07B88E', '#404F70'];

    // define pie
    const pie = d3.pie()
      .value(function (d) {
        return d.valueOf();
      })
      .sort(null);

    // function to apply color-gradient on pie arc
    const createGradient = function (svgObj, id, color1, color2) {

      const defs = svgObj.append('svg:defs');

      const red_gradient = defs.append('svg:linearGradient')
        .attr('id', id)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '50%')
        .attr('y2', '100%')
        .attr('spreadMethod', 'pad');

      red_gradient.append('svg:stop')
        .attr('offset', '10%')
        .attr('stop-color', color1)
        .attr('stop-opacity', 1);

      red_gradient.append('svg:stop')
        .attr('offset', '100%')
        .attr('stop-color', color2)
        .attr('stop-opacity', 1);
    };

    // define svg
    const svg = d3.select(parent)
      .append('svg')
      .attr('width', w + marginleft + marginright)
      .attr('height', h + margintop + marginbottom)
      .attr('class', `shadow${firm}`)
      .append('g')
      .attr( 'transform', `translate(${w / 2},${h / 2})`);

    createGradient(svg, 'gradient', color[0], color[1]);

    // define arc for pie
    const arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .endAngle(2 * Math.PI);

    // define arc for plot value
    const arcLine = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .cornerRadius(20);


    // tooltip
    const Tool_tip = d3.select('body').append('div')
      .attr('class', `tool-tip_progress${firm}`);

    // append path for pie chart
    const pathBackground = svg.append('path')
      .attr('d', arc)
      .attr('fill', color[2]).on('mousemove', function (d) {
        const padding = 1000;
        Tool_tip.style('position', 'relative');
        Tool_tip.style('left', `${d3.event['pageX'] + marginright / 2}px`);
        Tool_tip.style('top', `${d3.event['pageY'] - padding - margintop / 2}px`);
        Tool_tip.style('display', 'inline-block');
        Tool_tip.style('background-color', 'var(--tooltip-background-base');
        Tool_tip.style('color', 'black');
        Tool_tip.style('border', '3px solid var(--modal-stroke-primary)');
        Tool_tip.html(`${firm}<br>${100 - Math.round(ratio * 100)}%`)
          .style('font-weight', 'bold');
      }).on('mouseout', function (d) {
        Tool_tip.style('display', 'none');
      });


    // append background arc to plot value
    const pathChart = svg.append('path')
      .datum({ moduleName: firm, value: percent, endAngle: 0 })
      .attr('d', <any>arcLine)
      .attr('fill', 'url(#gradient)')
      .on('mousemove', function (d) {
        const padding = 1000;
        Tool_tip.style('position', 'relative');
        Tool_tip.style('left', `${d3.event['pageX'] + marginright / 2}px`);
        Tool_tip.style('top', `${d3.event['pageY'] - padding - margintop / 2}px`);
        Tool_tip.style('display', 'inline-block');
        Tool_tip.style('background-color', 'var(--tooltip-background-base)');
        Tool_tip.style('color', 'var(--tooltip-text-primary)');
        Tool_tip.style('border', '3px solid var(--modal-stroke-primary)');
        Tool_tip.html(`${d['moduleName']}<br>${Math.round(ratio * 100)}%`)
          .style('font-weight', 'bold');
      })
      .on('mouseout', function (d) {
        Tool_tip.style('display', 'none');
      });


    // append a circle of radius innerRadius  inside pie
    const circle = svg.append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', innerRadius)
      .style('fill', 'var(--black)')
      .style('fill-opacity', .5);

    // append text
    const middleCount = svg.append('text')
      .text(function (d) {
        return d.toString();
      })
      .attr('class', 'middleText')
      .attr('text-anchor', 'middle')
      .style('fill', 'var(--text-primary)')
      .style('font-size', '35px')
      .style('font-weight', 'bold')
      // .attr('x', -8)
      .attr('y', w / 8 - margintop);

    svg.append('text')
      .text(`OF ${totalAmount} ${firm}`)
      .style('fill', 'var(--text-primary)')
      .style('font-size', '13px')
      .attr('x', -(innerRadius / 2))
      .attr('y', (innerRadius / 2) - margintop);
    // function fopr transition of arc
    const arcTween = function (transition, newAngle) {
      transition.attrTween('d', function (d) {
        const interpolate = d3.interpolate(d.endAngle, newAngle);
        const interpolateCount = d3.interpolate(0, percent);
        return function (t) {
          d.endAngle = interpolate(t);
          middleCount.text(d.value);
          return arcLine(d);
        };
      });
    };

    // set animation to arc
    const animate = function () {
      pathChart.transition()
        .duration(750)
        .ease()
        .call(arcTween, ((2 * Math.PI)) * ratio);


    };

    setTimeout(animate, 0);
  }

}

