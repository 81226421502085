<div *ngIf="showProgressBar">
  <div class="running-bar">
    <a href="javascript:void(0);" class="progress-close"><i class="fas fa-thumbtack" aria-hidden="true"></i></a>
    <div class="row">
      <div class="col-md-8">
        <span class="text-xs-center">{{analyticsProgress}}<span></span> </span>
      </div>
    </div>
    <p-progressBar [value]="value"></p-progressBar>
  </div>
</div>
