import {Injectable} from '@angular/core';
import {AuthService, OEHttpClient} from '../core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private connection: HubConnection;

  private readonly subject = new Subject<any>();
  readonly events = this.subject.asObservable();

  constructor(private _httpClient: OEHttpClient, private _authService: AuthService) {
    this._authService.token$.subscribe(() => {
      this.init();
    });
  }

  private init() {
    if (this.connection && this.connection.state === HubConnectionState.Connected) {
      this.connection.stop();
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(this._httpClient.getApiUrl('v2/signal'), {
        accessTokenFactory: () => this._authService.getToken().eclipse_access_token,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(() => {
        this.connection.on('ReceiveMessage', (data) => this.subject.next(data));
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
}

