<div class="portfoliodetails-search-add form-group">
  <p-autoComplete class="autocomplete-search" [(ngModel)]="selectedCustodian" [suggestions]="custodianFilterList"
                  placeholder="Search Custodian" name="custodianSearch" (completeMethod)="autoCustodianSearch($event)" field="name"
                  [minLength]="1" (onSelect)="onCustodianSelect($event)" (keyup)="onCustodianSelect(selectedCustodian)">
    <ng-template let-selectedCustodian pTemplate="item">
      <ul class="ui-helper-clearfix show-hollding-dropdown">
        <li>
          <i class="lm lm-securities text-info" aria-hidden="true"></i>
          <span>{{ selectedCustodian.name +" (" + selectedCustodian.id +")"}}</span>
        </li>
      </ul>
    </ng-template>
  </p-autoComplete>
</div>
