export enum SystematicAutoRebalanceFrequency {
  None = 1,
  Monthly = 2,
  Quarterly = 3,
  SemiAnnual = 4,
  Annual = 5
}

export enum SystematicFrequency {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  SemiAnnual = 'semiAnnual',
  Annual = 'annual'
}

export interface ISystematicMultiplierRebalance {
  autoRebalanceTypeId: SystematicAutoRebalanceFrequency;
  monthlyMin?: number;
  monthlyMax?: number;
  monthlyTarget?: number;
  monthlyWindowDays?: number;
  quarterlyMin?: number;
  quarterlyMax?: number;
  quarterlyTarget?: number;
  quarterlyWindowDays?: number;
  semiAnnualMin?: number;
  semiAnnualMax?: number;
  semiAnnualTarget?: number;
  semiAnnualWindowDays?: number;
  annualMin?: number;
  annualMax?: number;
  annualTarget?: number;
  annualWindowDays?: number;
}

export interface ISystematicMultiplierPreference {
  levelName: string;
  recordId: number;
  id: number;
  preferenceId: number;
  name: string;
  displayName: string;
  categoryType: string;
  componentType: string;
  componentName: string;
  systematicMultiplierPreferences: ISystematicMultiplierRebalance[];
  inheritedSystematicMultiplierPreferences: ISystematicMultiplierRebalance[];
  isSetFromParent: boolean;
  resetToParent: boolean;
}

export interface ICustomSystematicMultiplierPreferenceSave {
  id: number;
  preferenceId: number;
  systematicMultipliers: ISystematicMultiplierPreference;
  resetToParent: boolean;
}
