import { Component, Input, HostListener } from '@angular/core';
import { GridOptions, ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import {BaseComponent} from '../../core/base.component';
import {IDividendReinvestPreference, IDividendReinvestPreferencesGet} from '../../models/preferences/dividendreinvestpreference';
import {SecurityService} from '../../services/security.service';
import { Utils as Util } from '../../core/functions';

@Component({
  selector: 'eclipse-dividend-reinvest',
  templateUrl: './dividendreinvest.component.html'
})
export class DividendReInvestComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  PrefrowData: IDividendReinvestPreference[] = [];
  selectedSecurityType: any = null;
  selectedSecurityTypeId: number = null;
  dividendReinvestPref: IDividendReinvestPreference = <IDividendReinvestPreference>{};
  securityTypeId: number;
  securitySymbol: string;
  type: string = '$';
  securityTypes: any[];
  securityTypesHidden: any[];
  securityTypesInherited: any[];
  yesOrNOoData: any[] = [];

  canShow: boolean = true;
  canRead: boolean = false;
  btnDisableSetPref: boolean = true;
  displayDividendReinvestPref: boolean = false;
  isInherited: boolean = false;
  isPreferenceEdited: boolean = false;

  @Input() displayPermission: string;
  @Input() fromParent: IDividendReinvestPreferencesGet;
  @Input() st: any[];
  fromParentPrefLevel: string;
  clonedSecurityTypes: any[]; /** Security Types :: New approach  */

  constructor(private _securityService: SecurityService) {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
    this.dividendReinvestPref = <IDividendReinvestPreference>{
      taxableDivReInvest: null,
      taxDefDivReinvest: null,
      taxExemptDivReinvest: null,
      inheritedFrom: null
    };
  }

  ngOnInit() {
    this.getYesOrNoOptions();
    this.PrefrowData = this.fromParent.dividendReinvestPreferences;
    this.fromParentPrefLevel = this.fromParent.levelName.toLowerCase();
    this.resetSecurityTypes();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  /** Reset all security types that are customized */
  resetSecurityTypes() {
    if (!this.isInherited) {
      this.securityTypesHidden = Util.deepClone(this.st);
      this.securityTypes = Util.deepClone(this.st);
      this.clonedSecurityTypes = Util.deepClone(this.st);
      /** Splice the existing security Types */
      this.spliceExistingSecTypes();
      this.PrepareColdef();
    } else {
      this.handleSecTypesAfterInherit(this.st);
      this.PrepareColdef();
    }
  }

  /** Splice already selected Security type/s from existing Security Types  */
  spliceExistingSecTypes() {
    if (this.PrefrowData) {
      const secTypes = this.PrefrowData.map(m => m.securityTypeId);
      secTypes.forEach(m => {
        // eslint-disable-next-line eqeqeq
        this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.id == m), 1);
        Util.sortBy(this.securityTypes, 'name');
      });
    }
  }

  /** Security Type on Dropdown selection changed event */
  handleSelectedSecurityType(securityTypeId) {
    if (+securityTypeId) {
      this.btnDisableSetPref = false;
      this.securityTypeId = securityTypeId;
      this.selectedSecurityTypeId = +securityTypeId;
      // eslint-disable-next-line eqeqeq
      const secType = this.securityTypes.filter(x => x.id == +securityTypeId);
      // eslint-disable-next-line eqeqeq
      this.selectedSecurityType = (secType.length > 0 && secType != null) ? secType[0].name : '';
    } else {
      this.btnDisableSetPref = true;
    }
  }

  /** to open preferences popup*/
  setPrefPopup(selectedSecurityType) {
    this.selectedSecurityTypeId = null;
    this.displayDividendReinvestPref = true;
    this.resetForm();
    this.bindDividendReinvestEmptyData(selectedSecurityType);
  }

  /** Find index of expanded row  */
  findExpandedRowIndex(array, key, val) {
    for (let i = 0; i < array.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (array[i][key] == val) {
        return i;
      }
    }
    return null;
  }

  /** To Reset Form */
  resetForm() {
    this.dividendReinvestPref = <IDividendReinvestPreference>{};
    this.btnDisableSetPref = true;
    this.isPreferenceEdited = false;
  }

  /** Bind Dividend Reinvest fee pref empty data */
  bindDividendReinvestEmptyData(selectedSecurityType) {
    this.dividendReinvestPref = <IDividendReinvestPreference>{
      id: selectedSecurityType,
      securityTypeId: selectedSecurityType,
      securityType: null,
      taxableDivReInvest: null,
      taxDefDivReinvest: null,
      taxExemptDivReinvest: null,
      inheritedFrom: null
    };
  }

  /** Prepare colDefs based on Preference level **/
  PrepareColdef() {
    const self = this;
    if (this.displayPermission) {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Taxable', field: 'taxableDivReInvest', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agTextColumnFilter' },
        <ColDef>{ headerName: 'Tax Deferred', field: 'taxDefDivReinvest', width: 180, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Tax Exempt', field: 'taxExemptDivReinvest', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
      ];
    } else {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Taxable', field: 'taxableDivReInvest', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agTextColumnFilter' },
        <ColDef>{ headerName: 'Tax Deferred', field: 'taxDefDivReinvest', width: 180, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Tax Exempt', field: 'taxExemptDivReinvest', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
        <ColDef>{
          headerName: 'Inherited from', field: 'inheritedFrom', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter'
        }
      ];
    }
    this.columnDefs.forEach(c => c.menuTabs = []);
  }

  /**Yes no Renderer */
  yesOrNoRenderer(params) {
    const col = params.column.colDef.field;
    const obj = params.data[col];
    // eslint-disable-next-line eqeqeq
    return obj == true ? 'Yes' : obj == false ? 'No' : '';
  }

  /** Fires when row is selected */
  rowSelected(event) {
    let result = '';
    // eslint-disable-next-line eqeqeq
    result = this.clonedSecurityTypes.find(m => m.id == +event.data.securityTypeId).name;
    this.selectedSecurityType = result;
  }

  /** security Type based on its id */
  securityTypeCellRenderer(params, self) {
    let result = '';
    const checkSecurityType = this.clonedSecurityTypes.filter(m => m.id === +params.node.data.securityTypeId);
    /** NEW LOGIC FOR SECURITY TYPES RENDERING */
    result = (checkSecurityType.length > 0) ? checkSecurityType[0].name : result;
    return result;
  }

  /** method to display context menu on accounts agGrid*/
  getContextMenuItems(params) {
    const contextResult = [];
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Edit Preferences">Edit Preferences</hidden>' });
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Delete Preferences">Delete Preferences</hidden>' });
    return contextResult;
  }

  /** Hostlistner  */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement.title === 'Edit Preferences') {
      this.isPreferenceEdited = true;
      this.displayDividendReinvestPref = true;
      this.dividendReinvestPref = Object.assign({}, this.PrefrowData.find(x => x.securityTypeId === +targetElement.id));
      this.securityTypeId = +this.dividendReinvestPref.securityTypeId;
    }

    if (targetElement.title === 'Delete Preferences') {
      const selectedPrefToDelete = +targetElement.id;
      const rowData = this.gridApi.getGridOption('rowData');
      rowData.splice(rowData.findIndex(x => x.securityTypeId === +selectedPrefToDelete), 1);
      this.gridApi.setGridOption('rowData', rowData);
      const deletedObject = this.securityTypesHidden.find(x => x.id === +selectedPrefToDelete);
      this.securityTypes.push(deletedObject);
      Util.sortBy(this.securityTypes, 'name');
    }
  }

  /** cancels the operation and closes the pop-up */
  cancel() {
    this.displayDividendReinvestPref = false;
    this.bindDividendReinvestEmptyData(this.dividendReinvestPref);
  }

  /** Adding to grid */
  addtoGrid() {
    this.displayDividendReinvestPref = false;
    this.dividendReinvestPref.id = (this.dividendReinvestPref.id && typeof(this.dividendReinvestPref.id) === 'number') ? +this.dividendReinvestPref.id : null;
    this.dividendReinvestPref.securityTypeId = +this.securityTypeId;
    this.dividendReinvestPref.securityType = null;
    // eslint-disable-next-line eqeqeq
    this.dividendReinvestPref.taxableDivReInvest = (this.dividendReinvestPref.taxableDivReInvest != undefined) ? this.dividendReinvestPref.taxableDivReInvest : null;
    // eslint-disable-next-line eqeqeq
    this.dividendReinvestPref.taxDefDivReinvest = (this.dividendReinvestPref.taxDefDivReinvest != undefined) ? this.dividendReinvestPref.taxDefDivReinvest : null;
    // eslint-disable-next-line eqeqeq
    this.dividendReinvestPref.taxExemptDivReinvest = (this.dividendReinvestPref.taxExemptDivReinvest != undefined) ? this.dividendReinvestPref.taxExemptDivReinvest : null;
    // eslint-disable-next-line eqeqeq
    const match = this.PrefrowData.filter(x => x.securityTypeId == +this.dividendReinvestPref.securityTypeId);
    if (match.length > 0) {
      match[0] = this.dividendReinvestPref;
      const indexVal = this.findExpandedRowIndex(this.PrefrowData, 'securityTypeId', match[0].securityTypeId);
      this.PrefrowData[indexVal] = match[0];
    } else {
      this.PrefrowData.push(this.dividendReinvestPref);
    }
    this.gridApi.setGridOption('rowData', this.PrefrowData);
    if (!this.isPreferenceEdited) {
      // eslint-disable-next-line eqeqeq
      this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.name == this.selectedSecurityType), 1);
      Util.sortBy(this.securityTypes, 'name');
    }
    this.resetForm();
  }

  /*  binding InheritedSecurity Preference to gridOptions */
  bindDividendReinvestData(securityResults) {
    this.isInherited = true;
    this.resetForm();
    this.PrefrowData = this.fromParent.dividendReinvestPreferences; /** TODO: UNCOMMENT THIS */
    this.PrepareColdef();
    this.resetSecurityTypes();
    this.dividendReinvestPref = <IDividendReinvestPreference>{
      id: null,
      securityTypeId: null,
      securityType: null,
      taxableDivReInvest: null,
      taxDefDivReinvest: null,
      taxExemptDivReinvest: null
    };
  }

  /** Handle the security types after inherit */
  handleSecTypesAfterInherit(securitieTypesResult: any) {
    this.securityTypesInherited = securitieTypesResult;
    const inheriteds = this.fromParent.inheritedDividendReinvestPreferences;
    const arrInheriteds = inheriteds.map(m => m.securityTypeId);
    if (inheriteds.length > 0) {
      arrInheriteds.forEach(secId => {
        // eslint-disable-next-line eqeqeq
        this.securityTypesInherited.splice(this.securityTypesInherited.findIndex(sc => sc.id == secId), 1);
      });
      this.securityTypes = this.securityTypesInherited;
      Util.sortBy(this.securityTypes, 'name');
    } else {
      this.securityTypes = securitieTypesResult;
      Util.sortBy(this.securityTypes, 'name');
    }
    this.isInherited = false;
  }

  /** Gets Yes or No options based on dropdown selection */
  getYesOrNoOptions() {
    this.yesOrNOoData = this._securityService.getYesOrNoValuesForPreferences();
  }

  /** Set Dropdown values based on selection */
  setDropdownSelectedOption(event, taxexemeptname) {
    // console.log("setDropdownSelectedOption", event, " ", taxexemeptname)
    // eslint-disable-next-line eqeqeq
    if (event == '0: null') {
      switch (taxexemeptname) {
        case 'taxExemptDivReinvest':
          this.dividendReinvestPref.taxExemptDivReinvest = null;
          break;
        case 'taxDefDivReinvest':
          this.dividendReinvestPref.taxDefDivReinvest = null;
          break;
        case 'taxableDivReInvest':
          this.dividendReinvestPref.taxableDivReInvest = null;
          break;
      }
    }
  }

}
