import { Component} from '@angular/core';
import {SessionHelper} from '../../core';

@Component({
  selector: 'eclipse-notauthorized',
  templateUrl: './notauthorized.component.html'
})
export class NotAuthorizedComponent {

  isAuthenticated: boolean;

  constructor(private _sessionHelper: SessionHelper) {
    this.isAuthenticated = _sessionHelper.isAuthenticated();
  }

}
