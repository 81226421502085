import {SessionHelper} from './session.helper';
import { inject, Injectable } from '@angular/core';
import {ILogin} from '../login/login';
import {BehaviorSubject, Observable} from 'rxjs';
import {IUser} from '../models/user';
import {share} from 'rxjs/operators';
import { RouterHelperService } from './router.helper.service';

@Injectable()
export class AuthService {
  private readonly _routerHelper: RouterHelperService = inject(RouterHelperService);
  private readonly _sessionHelper: SessionHelper = inject(SessionHelper);

  private isLoggedIn: boolean = false;
  private tokenSubject: BehaviorSubject<ILogin>;
  token$: Observable<ILogin>;
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser$: Observable<IUser>;

  constructor() {
    this.isLoggedIn = this._sessionHelper.isAuthenticated();

    this.tokenSubject = new BehaviorSubject<ILogin>(this._sessionHelper.getAccessToken('accessTokenInfo'));
    this.token$ = this.tokenSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<IUser>(this._sessionHelper.get('user'));
    this.currentUser$ = this.currentUserSubject.asObservable().pipe(share());
  }

  isUserLoggedIn() {
    this.isLoggedIn = this._sessionHelper.isAuthenticated();
    return this.isLoggedIn ;
  }

  setToken(tokenInfo: ILogin) {
    if(this.tokenSubject.value?.eclipse_access_token === tokenInfo?.eclipse_access_token) {
      return;
    }
    this._sessionHelper.set('accessTokenInfo', tokenInfo);
    this.tokenSubject.next(tokenInfo);
  }

  getToken = () => this.tokenSubject.getValue();

  public get currentUser(): IUser {
    return this._sessionHelper.getUser();
  }
  public set currentUser(value: IUser) {
    this._sessionHelper.set('user', value);
    this._sessionHelper.set('role', value?.role);
    this.currentUserSubject.next(this._sessionHelper.getUser());
  }

  public clearSession(): void {
    this._sessionHelper.removeAll();
    this._routerHelper.clearHistory();
    this.currentUser = null;
  }
}
