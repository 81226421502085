import {AfterViewInit, Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {IRaiseCash} from '../../models/raisecash';
import * as Consts from '../../libs/app.constants';
import {IContributionSleeveTrade, IExcelImport, IFileUploadResult, OptionFilterType} from '../../models/tradetools';
import {PortfolioAutoCompleteComponent} from '../search/portfolio.autocomplete.component';
import {SleevePfAutoCompleteComponent} from '../search/sleeveportfolio.autocomplete.component';
import {AccountsAutoCompleteComponent} from '../search/account.autocomplete.component';
import {ModelAutoCompleteComponent} from '../search/model.autocomplete.component';
import {SleevesAutoCompleteComponent} from '../search/sleeves.autocomplete.component';
import {TradeGroupsPortfolioAutoCompleteComponent} from '../search/tradegroupsportfolio.autocomplete.component';
import {TradeGroupsAccountAutoCompleteComponent} from '../search/tradegroupsaccount.autocomplete.component';
import {TradeGroupsAutoCompleteComponent} from '../search/tradegroups.autocomplete.component';
import {TradeToolsService} from '../../services/tradetools.service';
import {PortfolioService} from '../../services/portfolio.service';
import {SleeveService} from '../../services/sleeves.service';
import {AccountService} from '../../services/account.service';
import {ModelService} from '../../services/model.service';
import {ConfigService} from '../../config/config.service';
import { Utils as Util } from '../../core/functions';
import {BaseComponent} from '../../core/base.component';
import {TradeToolAnalyticsAnalyzerComponent} from '../tradetoolanalyticanalyzer/tradetool.analytics.component';
import * as _ from 'lodash';
import {ITradeGridItem} from '../../models/spendcash';
import {IIdName, INameValue} from '../../models/tom';
import {SLEEVE_ACCOUNT_METHOD, TradeFilterMethod} from '../../libs/rebalancer.constants';
import {TradeToolHelper} from '../../components/tradetool/shared/tradetool-helper';
import {BaseGridConfiguration} from '../gridextensions/basegrid';

@Component({
  selector: 'eclipse-trade-tool-trade-filter',
  styleUrls: ['./tradeorder.tradefilter.component.scss'],
  templateUrl: './tradeorder.tradefilter.component.html'
})
export class TradeOrderFilterComponent extends BaseComponent implements AfterViewInit {

  @Input() parentModel: any;
  @Input() tradeGroupTypeId: number;
  @Output() tradeFilterCallback = new EventEmitter();
  @Output() tabSelectionCallback = new EventEmitter();
  @Input() excelData: any;
  @Input() runFromTactical: boolean;
  disableContributionButton: boolean = false;
  showPortfolio: boolean = true;
  showAccounts: boolean = true;
  showSleevePortfolio: boolean;
  showModel: boolean = true;
  showSleeves: boolean = false; /** CUSTOMIZED */
  showExcelImport: boolean = true; /** CUSTOMIZED */
  showExcelImportPortfolio: boolean = false; /** CUSTOMIZED */
  showExcelImportSleeves: boolean = false; /** CUSTOMIZED */
  showtradeGroupsForPortfolio: boolean = false; /** CUSTOMIZED */
  showtradeGroupsForAccounts: boolean = false; /** CUSTOMIZED */
  showSpendContributionSleeveBal: boolean = false;
  showCalculateAtSleevePf: boolean = false;
  showAmountInput: boolean = false;
  private gridApi: GridApi;
  private fileImportGridApi: GridApi;
  protected tradesFilterGridOptions: GridOptions;
  protected tradesFilterColumnDefs: ColDef[];
  protected tradesFilterData: any[] = [];
  public tradeFilterMethod: string;
  portfolio: number = 1;
  account: number = 0;
  model: number = 0;
  sleevePortfolio: number = 0;
  tradeGroups: number = 0;
  sleeves: number = 0; /** CUSTOMIZED */
  tradeGroupsForPortfolio: number = 0; /** CUSTOMIZED */
  tradeGroupsForAccount: number = 0; /** CUSTOMIZED */
  excelImport: number = 0; /** CUSTOMIZED */
  excelImportForPf: number = 0; /** CUSTOMIZED */
  excelImportForSlvs: number = 0; /** CUSTOMIZED */
  // showPortfolio: boolean;
  disableAdd: boolean = true;
  selectedItem: any;
  suggestions: any[] = [];
  List: Array<ITradeGridItem> = [];
  selectedModel: IRaiseCash;
  disableFilterNextBtn: boolean = true;
  showtradeGroups: boolean = false;
  enteredAmount: any;
  isSpendCash: boolean = false;
  isRaiseCash: boolean = false;
  isCashNeed: boolean = false;
  isTaxHarvest: boolean = false;
  isGlobalTrade: boolean = false;
  harvestType: number = Consts.TaxHarvestType.AUTOMATED; // Set Automated as the top selection.
  harvestTypeData: IIdName[] = [
    {id: Consts.TaxHarvestType.AUTOMATED, name: 'Automated'},
    {id: Consts.TaxHarvestType.MANUAL, name: 'Manual'}];
  selectedSleeveAccountMethod: string = ''; // ="IndividualSleeves";
  isSleevePortfolio: boolean = false;
  portfolioContributionItem: any;
  portfolioManagedItem: any;
  isCalculateAtSleevePf: boolean = false;
  isProtectSetAsideInCont: boolean = true;
  isNotValidFile: boolean = false;
  contributionBalance: number;
  managedValue: number;
  spendRaiseCashRouteType: any = Consts.SpendRaiseCashRouteType;

  values = [];
  // Excel import
  file: IExcelImport = <IExcelImport>{};
  fileImportGridOptions: GridOptions;
  fileImportColumnDefs: ColDef[];
  fileImportContext = { self: this };
  displayImportModel: boolean = false;
  checkUploadFile: boolean = true;
  disableUploadBtn: boolean = true;
  checkDragFile: boolean = false;
  fileUploadError: string;
  showFileUploadError: boolean = false;
  modelErrors: boolean;
  dragFileName: string;
  tradeGroupAccounts: any[] = [];
  tradeGroupsErrorMsg: string = '';
  uploadResults: IFileUploadResult;
  tradeToolSettings: any;
  enableRequestToBucket: boolean = false;
  displayConfirm: boolean;
  isMultiple: boolean;
  portfolioFlags: any;
  disableAddBtn: boolean = false;
  showAnalyticsFailedWarning = false;
  showContributionAmountWarning: boolean;
  sleeveAccountMethods: INameValue[] = [{name: 'Select Sleeve Account Method', value: ''}];
  isLiquidate: boolean;

  @ViewChild(PortfolioAutoCompleteComponent) portfolioAutoCompleteComponent: PortfolioAutoCompleteComponent;
  @ViewChild(SleevePfAutoCompleteComponent) sleevePortfolioAutoCompleteComponent: SleevePfAutoCompleteComponent;
  @ViewChild(AccountsAutoCompleteComponent) accountsAutoCompleteComponent: AccountsAutoCompleteComponent;
  @ViewChild(ModelAutoCompleteComponent) modelAutoCompleteComponent: ModelAutoCompleteComponent;
  @ViewChild(SleevesAutoCompleteComponent) sleevesAutoCompleteComponent: SleevesAutoCompleteComponent;
  @ViewChild(TradeGroupsPortfolioAutoCompleteComponent) tradeGroupsPortfolioAutoCompleteComponent: TradeGroupsPortfolioAutoCompleteComponent;
  @ViewChild(TradeGroupsAccountAutoCompleteComponent) tradeGroupsAccountAutoCompleteComponent: TradeGroupsAccountAutoCompleteComponent;
  @ViewChild(TradeGroupsAutoCompleteComponent) tradeGroupsAutoCompleteComponent: TradeGroupsAutoCompleteComponent;
  @ViewChild(TradeToolAnalyticsAnalyzerComponent) tradeToolAnalyticsAnalyzerComponent: TradeToolAnalyticsAnalyzerComponent;
  constructor(private _tradeToolsService: TradeToolsService,
              private _portfolioService: PortfolioService,
              private _sleeveService: SleeveService,
              private _accountService: AccountService,
              private _modelService: ModelService,
              private activateRoute: ActivatedRoute,
              private _configService: ConfigService,
              private _router: Router) {
    super();

    this.tradesFilterGridOptions = <GridOptions>{
      ...this.defaultGridOptions,
      sideBar: null,
      statusBar: { ...BaseGridConfiguration.statusBarRowCounts }
    };
    // Editing a row at the bottom of a long list can cause the scrolling to reset (because the grid gets setRowData called
    // after checking analytics), so suppress scrolling when "new" data is applied.
    this.tradesFilterGridOptions.suppressScrollOnNewData = true;
    this.fileImportGridOptions = <GridOptions>{
      ...this.defaultFilterableGridOptions,
      sideBar: null,
      statusBar: { ...BaseGridConfiguration.statusBarRowCounts }
      };
    // this.createColDefs();
    this.tradeFilterMethod = 'portfolio';
    this.uploadResults = <IFileUploadResult>{};
    this.uploadResults.records = [];
    this.tradeToolSettings = ConfigService.settings['tradeToolSettings'];
    // eslint-disable-next-line eqeqeq
    if (this.tradeToolSettings != undefined && this.tradeToolSettings != null) {
      // eslint-disable-next-line eqeqeq
      if (this.tradeToolSettings.bucketSettings != undefined && this.tradeToolSettings.bucketSettings != null) {
        this.enableRequestToBucket = this.tradeToolSettings.bucketSettings.enableRequestToBucket;
      } else {
        this.enableRequestToBucket = false;
      }
    } else {
      this.enableRequestToBucket = false;
    }
  }

  ngAfterViewInit() {
    // eslint-disable-next-line eqeqeq
    if (this.excelData != undefined) {
      this.uploadResults = this.excelData;
    }
    // eslint-disable-next-line eqeqeq
    if (this.parentModel != undefined) {
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.excelRecords != undefined) {
        this.uploadResults.records = [];
        this.uploadResults.records = this.parentModel.excelRecords;
        this.uploadResults.recordType = this.parentModel.uploadedRecordType;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showModel != undefined) {
        this.showModel = this.parentModel.displayFilterOptions.showModel;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showAccounts != undefined) {
        this.showAccounts = this.parentModel.displayFilterOptions.showAccounts;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showPortfolio != undefined) {
        this.showPortfolio = this.parentModel.displayFilterOptions.showPortfolio;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showAmountInput != undefined) {
        this.showAmountInput = this.parentModel.displayFilterOptions.showAmountInput;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showSpendContributionSleeveBal != undefined) {
        this.showSpendContributionSleeveBal = this.parentModel.displayFilterOptions.showSpendContributionSleeveBal;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showSleevePortfolio != undefined) {
        this.showSleevePortfolio = this.parentModel.displayFilterOptions.showSleevePortfolio;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showCalculateAtSleevePf != undefined) {
        this.showCalculateAtSleevePf = this.parentModel.displayFilterOptions.showCalculateAtSleevePf;
      }

      /** CUSTOMIZED */
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showExcelImport != undefined) {
        this.showExcelImport = this.parentModel.displayFilterOptions.showExcelImport;
      }

      /** CUSTOMIZED */
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showSleeves != undefined) {
        this.showSleeves = this.parentModel.displayFilterOptions.showSleeves;
      }

      /** CUSTOMIZED */
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showExcelImportPortfolio != undefined) {
        this.showExcelImportPortfolio = this.parentModel.displayFilterOptions.showExcelImportPortfolio;
      }

      /** CUSTOMIZED */
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showExcelImportSleeves != undefined) {
        this.showExcelImportSleeves = this.parentModel.displayFilterOptions.showExcelImportSleeves;
      }

      /** CUSTOMIZED */
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showtradeGroupsForPortfolio != undefined) {
        this.showtradeGroupsForPortfolio = this.parentModel.displayFilterOptions.showtradeGroupsForPortfolio;
      }

      /** CUSTOMIZED */
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showtradeGroupsForAccounts != undefined) {
        this.showtradeGroupsForAccounts = this.parentModel.displayFilterOptions.showtradeGroupsForAccounts;
      }

      if (!this.parentModel.displayFilterOptions.showPortfolio) {
        this.tradeFilterMethod = 'account';
      }

      // eslint-disable-next-line eqeqeq
      if (this.parentModel.displayFilterOptions.showtradeGroups != undefined) {
        this.showtradeGroups = this.parentModel.displayFilterOptions.showtradeGroups;
      }

      // Check parent model values and assigning back, on next/back buttons clicks
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.selectedVal != '') {
        this.tradeFilterMethod = this.parentModel.selectedVal;
      }

      // eslint-disable-next-line eqeqeq
      if (this.parentModel.isCalculateAtSleevePf != undefined) {
        this.isCalculateAtSleevePf = this.parentModel.isCalculateAtSleevePf;
      }

      if (this.parentModel.isProtectSetAsideInCont !== undefined) {
        this.isProtectSetAsideInCont = this.parentModel.isProtectSetAsideInCont;
      }
      if (this.parentModel.selectedSleeveAccountMethod) {
        this.selectedSleeveAccountMethod = this.parentModel.selectedSleeveAccountMethod;
      }

      if (this.isCalculateAtSleevePf) {
        this.showSleevePortfolio = true;
        this.showAccounts = false;
        this.isSleevePortfolio = true;
        this.List = [];
      }
      this.setRadioBtnSelection();

      // eslint-disable-next-line eqeqeq
      if (this.parentModel.portfolioId != undefined && this.parentModel.portfolioId != '' && this.parentModel.selectedAccountsList.length == 0) {
        this.getPortfoliosByQueryString(this.parentModel.portfolioId);
      }

      // eslint-disable-next-line eqeqeq
      if (this.parentModel.isSpendCash != undefined) {
        this.isSpendCash = this.parentModel.isSpendCash;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.isRaiseCash != undefined) {
        this.isRaiseCash = this.parentModel.isRaiseCash;
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.isCashNeed != undefined) {
        this.isCashNeed = this.parentModel.isCashNeed;
      }
      if (this.parentModel.isTaxHarvest !== undefined) {
        this.isTaxHarvest = this.parentModel.isTaxHarvest;
      }
      if (this.parentModel.isGlobalTrade !== undefined) {
        this.isGlobalTrade = this.parentModel.isGlobalTrade;
      }
      this.isLiquidate = !!this.parentModel.isLiquidate;

      if (this.parentModel.selectedAccountsList.length > 0) {
        // if (this.isSpendCash)
        // eslint-disable-next-line eqeqeq
        if ((this.isRaiseCash || this.isSpendCash) && this.parentModel.filterMode != undefined) {
          // eslint-disable-next-line eqeqeq
          if (this.parentModel.filterMode == this.spendRaiseCashRouteType.portfolio) {
            this.getAccountsByPortfoliosIds(this.parentModel.selectedAccountsList);
          // eslint-disable-next-line eqeqeq
          } else if (this.parentModel.filterMode == this.spendRaiseCashRouteType.account) {
            this.bindSpendRaiseCashAccountDetailsByIds(this.parentModel.selectedAccountsList);
 }
        } else {
          this.harvestType = this.parentModel.harvestType;
          this.List = this.parentModel.selectedAccountsList;
        }
        this.disableFilterNextBtn = false;
        this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
      }
      // eslint-disable-next-line eqeqeq
      if (this.parentModel.selectedFile != undefined && this.parentModel.selectedFile.name != undefined) { // && this.parentModel.selectedFile.document != undefined
        // this.file.document = this.parentModel.selectedFile.document;
        this.file.name = this.parentModel.selectedFile.name; // this.parentModel.selectedFile.document.name;
        if (this.uploadResults.records.length > 0) {
          this.disableFilterNextBtn = false;
          this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
        } else {
          this.disableFilterNextBtn = true;
          this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
        }
      }

      this.createColDefs();
    }
    // Binding Selected ids Data - query string will have only Id's, will fetch other details and then bind the grid
    // eslint-disable-next-line eqeqeq
    if (this.parentModel != undefined && this.parentModel.selectedAccountsList.length != 0
      // eslint-disable-next-line eqeqeq
      && this.parentModel.selectedVal != '' && this.parentModel.isOnLoad) {
      this.values = [];
      this.List = [];
      this.tradeFilterMethod = this.parentModel.selectedVal;
      this.parentModel.selectedAccountsList.forEach(element => {
        // Query string data - directly ids passed &  Auto Complete-  Object.id
        // eslint-disable-next-line eqeqeq
        this.values.push(element.id == undefined ? element : element.id);
      });
      this.setRadioBtnSelection();
      // eslint-disable-next-line eqeqeq
      if (this.tradeFilterMethod == 'portfolio') {
        this.bindPortfolioDetailsByIds(this.values);
      // eslint-disable-next-line eqeqeq
      } else if (this.tradeFilterMethod == 'account') {
        if (!this.isSpendCash || !this.isRaiseCash) {
          this.bindAccountDetailsByIds(this.values);
        }
      // eslint-disable-next-line eqeqeq
      } else if (this.tradeFilterMethod == 'model') {
        this.bindModelDetailsByIds(this.values);
      } else {
        this.List = this.parentModel.selectedAccountsList;
      }
    }

    if (this.tradeToolAnalyticsAnalyzerComponent) {
      // eslint-disable-next-line eqeqeq
      if (this.tradeFilterMethod == 'excelImport') {
        this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.uploadResults.records, this.uploadResults.recordType);
      } else {
        // OE-2894
        // eslint-disable-next-line eqeqeq
        if (!(this.isRaiseCash || this.isSpendCash) && (this.tradeFilterMethod == 'tradeGroupsForAccount' || this.tradeFilterMethod == 'tradeGroupsForPortfolio')) {
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, 'tradeGroups');
        } else {
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
        }
      }
    }
    this.createFileImportColDefs();
    this.getSleeveAccountMethod();
  }

  /** To get accounts by portfolio ids */
  getAccountsByPortfoliosIds(portfolioIds: number[]) {
    const apiArray = [];
    if (this.enableRequestToBucket) {
      const bucketArray = Util.getRecordsBasedOnBucketSize(portfolioIds, this.tradeToolSettings.bucketSettings.portfoliosRequest);
      bucketArray.forEach(element => {
        apiArray.push(this._portfolioService.getAccountsSimpleListByPortfolioIds(element));
      });
      forkJoin(apiArray)
        .subscribe((modelsData: any[]) => {
          const accounts = [];
          modelsData.forEach(item => {
            item.forEach(account => {
              if (account.sleeveType !== Consts.sleeveTypeEnum.Custodial) {
                const custodial = item.find(s => s.sleeveType === Consts.sleeveTypeEnum.Custodial && s.portfolioId === account.portfolioId);
                accounts.push({
                  id: account.id,
                  name: account.name,
                  amount: 0,
                  accountId: account.accountId,
                  accountNumber: account.accountNumber,
                  custodialAccountNumber: custodial ? custodial.accountNumber : null,
                  portfolioId: account.portfolioId,
                  portfolioName: account.portfolioName,
                  sleeveType: account.sleeveType,
                  modelId: account.modelId,
                  needAnalytics: account.needAnalytics,
                  failedReason: account.failedReason,
                  isSleeve: account.isSleeve
                });
              }
            });
          });
          this.parentModel.selectedAccountsList = accounts;
          this.List = this.parentModel.selectedAccountsList;
          this.getSleeveAccountMethod();
          /* check the Calculate at Sleeve Portfolio (Include All Sleeves in Portfolio)
              Selected Portfolio will appear in the list multiple times */
          if (this.showSleevePortfolio && !this.runFromTactical) {
            this.confirmYes();
          }
          // eslint-disable-next-line eqeqeq
          if (this.parentModel.selectedAccountsList.length == 0) {
            this.disableFilterNextBtn = true;
            this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
          }
          this.bindPortfoliosList();
        });
    } else {
      this._portfolioService.getAccountsSimpleListByPortfolioIds(portfolioIds)
        .subscribe((modelsData: any[]) => {
          const accounts = [];
          modelsData.forEach(account => {
            if (account.sleeveType !== Consts.sleeveTypeEnum.Custodial) {
              accounts.push({ id: account.id, name: account.name, amount: 0, accountId: account.accountId,
                accountNumber: account.accountNumber, portfolioId: account.portfolioId, portfolioName: account.portfolioName,
                sleeveType: account.sleeveType, modelId: account.modelId, needAnalytics: account.needAnalytics,
                failedReason: account.failedReason, isSleeve: account.isSleeve });
            }
          });
          this.parentModel.selectedAccountsList = accounts;
          this.List = this.parentModel.selectedAccountsList;
          this.getSleeveAccountMethod();
          if (this.showSleevePortfolio) {
            this.confirmYes();
          }
          // eslint-disable-next-line eqeqeq
          if (this.parentModel.selectedAccountsList.length == 0) {
            this.disableFilterNextBtn = true;
            this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
          }
          this.bindPortfoliosList();
        });
    }
  }

  /** To get portfolios */
  private getPortfoliosByQueryString(PortfolioIds: string) {
    // splitting comma seprated team ids
    const arrPortfoliosIds = PortfolioIds.split(',');
    this.getPortfolioDetailsByIds(arrPortfoliosIds);
  }

  /**get portfolioIds from query string and bind the checkbox*/
  getPortfolioDetailsByIds(portfolioIds: any[]) {
    const apiArray = [];
    if (this.enableRequestToBucket) {
      const bucketArray = Util.getRecordsBasedOnBucketSize(portfolioIds, this.tradeToolSettings.bucketSettings.portfoliosRequest);
      bucketArray.forEach(element => {
        apiArray.push(this._portfolioService.getPortfolioSimpleListByIds(element));
      });
      forkJoin(apiArray)
        .subscribe((modelsData: any[]) => {
          this.List = [];
          modelsData.forEach(item => { item.forEach(element => { this.List.push(element); }); });
          // this.List = modelsData;
          this.bindPortfoliosList();
        });
    } else {
      this._portfolioService.getPortfolioSimpleListByIds(portfolioIds)
        .subscribe((modelsData: any[]) => {
          this.List = modelsData;
          this.bindPortfoliosList();
        });
    }
  }

  /**get portfolioIds from query string and bind the checkbox*/
  bindPortfolioDetailsByIds(portfolioIds: any[]) {
    const apiArray = [];
    if (this.enableRequestToBucket) {
      const bucketArray = Util.getRecordsBasedOnBucketSize(portfolioIds, this.tradeToolSettings.bucketSettings.portfoliosRequest);
      bucketArray.forEach(element => {
        apiArray.push(this._portfolioService.getPortfolioSimpleListByIds(element));
      });
      forkJoin(apiArray)
        .subscribe((modelsData: any[]) => {
          this.List = [];
          modelsData.forEach(item => { item.forEach(element => { this.List.push(element); }); });
          this.bindPortfoliosList();
          // this.List = modelsData;
          this.isDirectTabSelection();
        });
    } else {
      this._portfolioService.getPortfolioSimpleListByIds(portfolioIds)
        .subscribe((modelsData: any[]) => {
          this.List = modelsData;
          this.bindPortfoliosList();
          this.isDirectTabSelection();
        });
    }
  }

  /**get account details by ids and bind the grid */
  bindAccountDetailsByIds(AccountIds: any[]) {
    const apiArray = [];
    if (this.enableRequestToBucket) {
      const bucketArray = Util.getRecordsBasedOnBucketSize(AccountIds, this.tradeToolSettings.bucketSettings.accountsRequest);
      bucketArray.forEach(element => {
        apiArray.push(this._accountService.getAccountSimpleListByIds(element));
      });
      forkJoin(apiArray)
        .subscribe((modelsData: any[]) => {
          this.List = [];
          modelsData.forEach(item => { item.forEach(element => { this.List.push(element); }); });
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
          this.isDirectTabSelection();
        });
    } else {
      this._accountService.getAccountSimpleListByIds(AccountIds)
        .subscribe((modelsData: any[]) => {
          this.List = modelsData;
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
          this.isDirectTabSelection();
        });
    }
  }

  // For raise add spen cash trade tools, accounts data should contain model for Emphasis method
  // in bindAccountDetailsByIds() we are getting basic details only.
  bindSpendRaiseCashAccountDetailsByIds(accountIds: any[]) {
    const apiArray = [];
    if (this.enableRequestToBucket) {
      const bucketArray = Util.getRecordsBasedOnBucketSize(accountIds, this.tradeToolSettings.bucketSettings.accountsRequest);
      bucketArray.forEach(element => {
        apiArray.push(this._accountService.getAccountSimpleListByIds(element));
      });
      forkJoin(apiArray)
        .subscribe((modelsData: any[]) => {
          this.List = [];
          modelsData.forEach(item => {
            this.convertDataIntoTradeGridItem(item);
          });
          this.spendAndRaiseCashSleeveTypeCheck();
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
          this.parentModel.selectedAccountsList = this.List;
          this.gridApi.setGridOption('rowData', this.List);
          this.getSleeveAccountMethod();
        });
    } else {
      this._accountService.getAccountSimpleListByIds(accountIds)
        .subscribe((modelsData: any[]) => {
          this.convertDataIntoTradeGridItem(modelsData);
          this.spendAndRaiseCashSleeveTypeCheck();
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
          this.parentModel.selectedAccountsList = this.List;
          this.gridApi.setGridOption('rowData', this.List);
          this.getSleeveAccountMethod();
        });
    }
  }

  convertDataIntoTradeGridItem(item, includeAll = false) {
    item.forEach(account => {
      if (account.sleeveType !== Consts.sleeveTypeEnum.Custodial || includeAll) {
        this.List.push(
          <ITradeGridItem>{
            id: account.id,
            name: account.name,
            amount: 0,
            accountId: account.accountId,
            accountNumber: account.accountNumber,
            portfolioId: account.portfolioId,
            portfolioName: account.portfolioName,
            sleeveType: account.sleeveType,
            modelId: account.modelId,
            isSleeve: account.isSleeve,
            needAnalytics: account.needAnalytics,
            failedReason: account.failedReason,
            hasContribution: account.hasContribution,
            hasDistribution: account.hasDistribution,
          });
      }
    });
  }

  /**get Models details by ids and bind the grid */
  bindModelDetailsByIds(ModelIds: any[]) {
    const apiArray = [];
    if (this.enableRequestToBucket) {
      const bucketArray = Util.getRecordsBasedOnBucketSize(ModelIds, this.tradeToolSettings.bucketSettings.modelsRequest);
      bucketArray.forEach(element => {
        apiArray.push(this._modelService.getModelsSimpleListByIds(element));
      });
      forkJoin(apiArray)
        .subscribe((modelsData: any[]) => {
          this.List = [];
          modelsData.forEach(item => { item.forEach(element => { this.List.push(element); }); });
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
          this.isDirectTabSelection();
        });
    } else {
      this._modelService.getModelsSimpleListByIds(ModelIds)
        .subscribe((modelsData: any[]) => {
          this.List = modelsData;
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
          this.isDirectTabSelection();
        });
    }
  }

  isDirectTabSelection() {
    if (this.List.length > 0) {
      // eslint-disable-next-line eqeqeq
      if (this.parentModel != undefined && !this.parentModel.isBackButtonClick) {
        // Jump to Second tab as selection is already made in account/model/portfolio page.
        this.tabSelectionCallback.emit({ value: 'SelectionTab' });
      }
    } else {
      this.disableFilterNextBtn = true;
      this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
    }
  }

  /** To Create ColDefs */
  createColDefs() {
    this.tradesFilterColumnDefs = [];
    const self = this;
    if (this.isSpendCash || this.isRaiseCash) {
      // OE-2342 separated accoun tradegroup and portfolio tradegroup hence need to check below
      // eslint-disable-next-line eqeqeq
      if (this.tradeFilterMethod == 'account' || this.tradeFilterMethod == 'tradeGroupsForAccount' || this.tradeFilterMethod == 'tradeGroupsForPortfolio') {
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'ID', field: 'accountId', width: 100 });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Account Name', cellClass: 'fs-mask', field: 'name', width: 180 });
        this.tradesFilterColumnDefs.push(<ColDef>{
          headerName: 'Sleeve Type', field: 'isSleeve', width: 100, cellRenderer: function (params) {
            return self.typeCellRenderer(params);
          }
        });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Account Number', cellClass: 'fs-mask', field: 'accountNumber', width: 180 });
        this.tradesFilterColumnDefs.push(<ColDef>{
          headerName: 'Amount', field: 'amount', width: 190, cellClass: 'text-center', cellRenderer: function (params) {
            return self.amountCellRenderer(params, self);
          }
        });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: '', field: 'delete', width: 100, cellRenderer: this.deleteCellRenderer });
      // eslint-disable-next-line eqeqeq
      } else if (this.tradeFilterMethod == 'sleevePortfolio') {
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'ID', field: 'id', width: 100 });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Portfolio Name', cellClass: 'fs-mask', field: 'name', width: 180 });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Account Number', cellClass: 'fs-mask', field: 'custodialAccountNumber', width: 180 });
        this.tradesFilterColumnDefs.push(<ColDef>{
          headerName: 'Amount', field: 'amount', width: 190, cellClass: 'text-center', cellRenderer: function (params) {
            return self.amountCellRenderer(params, self);
          }
        });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: '', field: 'contributionAmount', width: 180, hide: true });
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: '', field: 'delete', width: 100, cellRenderer: this.deleteCellRenderer });
      }
    } else {
      if (this.tradeFilterMethod === 'account') {
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'ID', field: 'accountId', width: 120 });
      } else {
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'ID', field: 'id', width: 120 });
      }
      this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Name', cellClass: 'fs-mask', field: 'name', width: 280 });
      this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Tag', field: 'tags', width: 280 });
      if (this.isTaxHarvest) {
        this.tradesFilterColumnDefs.push(<ColDef>{ headerName: 'Has TLH Opportunity', field: 'hasTaxLossHarvest', width: 280, cellRenderer: this.TLHCellRenderer });
      }
      this.tradesFilterColumnDefs.push(<ColDef>{ headerName: '', field: 'delete', width: 120, cellRenderer: this.deleteCellRenderer });
    }
    return this.tradesFilterColumnDefs;
  }

  TLHCellRenderer(params) {
    let result = 'False';
    if (params && params.data && params.data.hasTaxLossHarvest) {
      result = params.data.hasTaxLossHarvest ? 'True' : 'False';
    } else if (params && params.data && params.data.portfolioFlags) {
      const hasTLHPortfolio = params.data.portfolioFlags.find(t => t.hasTaxLossHarvest);
      result = hasTLHPortfolio && hasTLHPortfolio.hasTaxLossHarvest ? 'True' : 'False';
    } else if (params && params.data && params.data.records) {
      const hasTLHAccount = params.data.portfolioFlags.find(t => t.hasTaxLossHarvest);
      result = hasTLHAccount && hasTLHAccount.hasTaxLossHarvest ? 'True' : 'False';
    }
    return result;
  }

  private isValidImportRecordCellClassRules = {
    'text-danger': function (params) {
      return params.data && !params.data.isValid;
    },
  };

  createFileImportColDefs() {
    const idField = (this.uploadResults && this.uploadResults.recordType === 'account') ? 'accountId' : 'id';

    this.fileImportColumnDefs = [
      <ColDef>{
        colId: 'delete',
        headerName: '',
        field: '',
        tooltipField: 'errorMessage',
        width: 43, maxWidth: 43, minWidth: 43, filter: false,
        cellRenderer: this.deleteImportRecordCellRenderer
      },
      <ColDef>{
        headerName: 'ID', field: idField, cellClassRules: this.isValidImportRecordCellClassRules, filter: 'text', tooltipField: 'errorMessage'
      },
      <ColDef>{
        headerName: 'Name', field: 'name', cellClassRules: this.isValidImportRecordCellClassRules, filter: 'text', tooltipField: 'errorMessage'
      }
    ];

    if (!this.isTaxHarvest && !this.isGlobalTrade) {
      this.fileImportColumnDefs.push(<ColDef>{
        headerName: 'Amount', field: 'amount', cellClassRules: this.isValidImportRecordCellClassRules, filter: 'number', tooltipField: 'errorMessage'
      });
    }

    if (this.isTaxHarvest) {
      this.fileImportColumnDefs.push(<ColDef>{
        headerName: 'Has TLH Opportunity', field: 'hasTaxLossHarvest', cellClassRules: this.isValidImportRecordCellClassRules, filter: 'number',
        cellRenderer: this.TLHCellRenderer, tooltipField: 'errorMessage'
      });
    }

    // To suppress floating filter button from all columns in the grid
    this.fileImportColumnDefs.forEach(e => {
      e.suppressFiltersToolPanel = true;
    });
  }

  private deleteImportRecordCellRenderer(params) {
    const id = params.context.self.uploadResults.recordType === 'account' ? params.node.data.accountId : params.node.data.id;
    return '<span><i title="Delete Imported Record" class="fas fa-times red"  aria-hidden="true"></i></span>';
  }

  /**To render Type cell */
  typeCellRenderer(params) {
    let cellValue = '';
    if (params.data !== undefined && params.data !== null && params.data['sleeveType'] !== Consts.sleeveTypeEnum.None) {
      cellValue = params.data['sleeveType'] === Consts.sleeveTypeEnum.Contribution || params.data['sleeveType'] === Consts.sleeveTypeEnum.Distribution ? (params.data['sleeveType']) : 'Sleeve';
    }
    return cellValue;
  }

  /** To render amount cell */
  amountCellRenderer(params, self) {
    const eInput = document.createElement('input');
    eInput.className = 'form-control grid-input';
    eInput.disabled = params.data.sleeveType === Consts.sleeveTypeEnum.Contribution || params.data.sleeveType === Consts.sleeveTypeEnum.Distribution;
    eInput.value = params.data[params.colDef.field] === undefined ? 0 : params.data[params.colDef.field];
    eInput.addEventListener('blur', function (event) {
      params.data[params.colDef.field] = (isNaN(parseFloat(eInput.value)) ? 0 : parseFloat(parseFloat(eInput.value).toFixed(2)));
      self.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(self.List, self.tradeFilterMethod);
    });

    // To validate contribution amount on row level
    eInput.addEventListener('keyup', () => {
      if (self.isCalculateAtSleevePf && eInput.value > params.data.contributionAmount) {
        eInput.value = params.data.contributionAmount;
        self.showContributionAmountWarning = self.isSpendCash;
      } else {
        self.showContributionAmountWarning = false;
      }

      const newValue = eInput.value;
      params.data[params.colDef.field] = newValue;
      eInput.value = params.data[params.colDef.field];

      // To accept two decimals
      const reg = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
      const val = reg.exec(eInput.value);
      if (val) { eInput.value = val[0]; }
    });

    eInput.addEventListener('keypress', function (event) {
      return Util.isValidDecimalInputKey(event, eInput.value);
    });
    return eInput;
  }

  /** Delete cell renderer */
  deleteCellRenderer(params) {
    let result = '<span>';
    // eslint-disable-next-line prefer-template
    result += '<i id =' + params.node.data.id + ' title="Delete" class="fas fa-times red" aria-hidden="true"></i></span>';
    return result;
  }

  public onImportCellClicked(param) {
   if (param.colDef.colId === 'delete') {
     const deleteRecordIndx = this.uploadResults.records.indexOf(param.data);
     if (deleteRecordIndx < 0) {
       return;
     }
     const deleteRecord = this.uploadResults.records[deleteRecordIndx];
     // add condtion to delete imported records in case of account file import in Spend case or Raise case.
    if (!deleteRecord.id) {
       // eslint-disable-next-line eqeqeq
       this.uploadResults.records.splice(this.uploadResults.records.findIndex(x => x.accountId == deleteRecord.accountId), 1);
     } else {
       // eslint-disable-next-line eqeqeq
       this.uploadResults.records.splice(this.uploadResults.records.findIndex(x => parseInt(`${x.id}`, 10) == +deleteRecord.id), 1);
     }
     const validRecords = this.uploadResults.records.filter(v => v.isValid);
     // eslint-disable-next-line eqeqeq
     if (this.uploadResults.records.length == 0 || validRecords.length == 0) {
       this.disableFilterNextBtn = true;
       this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn, isAllInvalid: true });
       this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(validRecords, this.uploadResults.recordType);
     } else {
       this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.uploadResults.records, this.uploadResults.recordType);
     }
     this.fileImportGridApi.setGridOption('rowData', this.uploadResults.records);
   }
  }

  /** Hostlistener for ag-grid context menu actions */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    const pattern = /[0-9]+/g;
    // eslint-disable-next-line eqeqeq
    if (targetElement.id == '' || !targetElement.outerHTML.match('<(hidden|i) id=')) {
      return;
    }
    const matches = targetElement.outerHTML.match(pattern);
    const [id = 0] = matches;
    if (targetElement.title === 'Delete') {
      this.DeleteItem(id);
    }
  }

  /** To bind portfolio list */
  bindPortfoliosList() {
    // eslint-disable-next-line eqeqeq
    if (this.List.length > 0 && this.gridApi) {
      this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
      this.gridApi.setGridOption('rowData', this.List);
    }
  }

  /** Refresh the ag-grid after delete performed */
  private DeleteItem(id) {
    const rowData = this.gridApi.getGridOption('rowData');
    rowData.splice(rowData.findIndex(x => x.id === parseInt(id, 10)), 1);
    this.gridApi.setGridOption('rowData', rowData);
    this.spendAndRaiseCashSleeveTypeCheck();
    this.analyzeAnalytics();
    this.checkForSleeveAccounts();
    this.getSleeveAccountMethod();
  }

  /** Fires on radio button change */
  radioBtnChange(val, selTradeFilterMethod) {
    if (this.tradeToolAnalyticsAnalyzerComponent) {
      this.tradeToolAnalyticsAnalyzerComponent.resetAnalytics();
    }
    this.tradeFilterMethod = selTradeFilterMethod;
    this.List = [];
    this.file.document = undefined;
    this.file.name = undefined;
    this.uploadResults.records = [];
    this.uploadResults.recordType = undefined;
    this.isNotValidFile = false;
    this.fileUploadError = undefined;
    this.enteredAmount = undefined;
    this.contributionBalance = undefined;
    this.managedValue = undefined;
    // To disable/enable next button
    this.disableFilterNextBtn = true;
    this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
    this.createColDefs();
    this.tradeGroupsErrorMsg = '';
    this.showAnalyticsFailedWarning = false;
  }

  /** To get selected item from auto complete */
  getSelectedItem(item) {
    this.selectedItem = item.value;
    this.isSleevePortfolio = this.selectedItem.sleevePortfolio;

    // To auto populate amount input with contribution amount for selected sleeve portfolio
    // eslint-disable-next-line eqeqeq
    if (this.selectedItem != undefined && this.tradeFilterMethod == 'sleevePortfolio') {
      if (this.isSpendCash) {
        this.disableAddBtn = true;
        this._portfolioService.getSpendCashMaxAmountByPortfolioId(this.selectedItem.id)
          .subscribe(model => {
            let m = model[0];
            if (!m) {
              m = {};
              m.id = this.selectedItem.id; // mock single obj
              m.amount = 0;
            }
            this.portfolioContributionItem = m;
            // eslint-disable-next-line eqeqeq
            const amount = m.amount == null ? '0' : `${m.amount}`;
            this.portfolioContributionItem.amount = amount;
            this.enteredAmount = amount;
            this.contributionBalance = +amount;
            this.disableAddBtn = false;
            // console.log('this.enteredAmount : ', this.enteredAmount);
          });
      } else if (this.isRaiseCash) {
        this.disableAddBtn = true;
          this._portfolioService.getPortfolioManagedValue(this.selectedItem.id)
          .subscribe(m => {
            // eslint-disable-next-line eqeqeq
            const amount = m.managedValue == null ? '0' : m.managedValue;
            this.portfolioManagedItem = { id: m.portfolioId, amount: amount };
            this.managedValue = amount;
            this.enteredAmount = undefined;
            this.disableAddBtn = false;
            // console.log('this.enteredAmount : ', this.enteredAmount);
          });
      }
    }
    // Get accounts under the selected trade group , it should at raise and spend tools only, based on CR: OEMP-23
    // eslint-disable-next-line eqeqeq
    if ((this.isRaiseCash || this.isSpendCash) && this.tradeFilterMethod == 'tradeGroupsForAccount') {
      this._tradeToolsService.getTradeGroupAccounts(this.selectedItem.id)
        .subscribe((model: any[]) => {
          this.tradeGroupsErrorMsg = '';
          // eslint-disable-next-line eqeqeq
          if (model.length != 0) {
            this.tradeGroupAccounts = model;
          } else {
            this.tradeGroupsErrorMsg = 'Selected Trade Group does not have any accounts';
          }
          this.getSleeveAccountMethod();
        },
          error => {
            this.tradeGroupsErrorMsg = error.message;
          });
    // eslint-disable-next-line eqeqeq
    } else if (!(this.isRaiseCash || this.isSpendCash) && (this.tradeFilterMethod == 'tradeGroupsForAccount' || this.tradeFilterMethod == 'tradeGroupsForPortfolio')) {     // OE-2894
      const id = this.selectedItem.id;
      this.portfolioFlags = [];
      this.tradeGroupsErrorMsg = '';
      this._tradeToolsService.getTradeGroupPortfolioFlags(id)
        .subscribe(flags => {
          if (flags && flags.length > 0) {
            this.portfolioFlags = flags;
          } else {
            this.tradeGroupsErrorMsg = 'Selected Trade Group does not have any matching accounts/portfolios';
          }
        },
          error => {
            this.tradeGroupsErrorMsg = error.message;
          }
        );
    }
  }

  /** To validate selected item */
  private validateSelectedItem() {
    switch (this.tradeFilterMethod) {
      case 'account':
        this.selectedItem = this.accountsAutoCompleteComponent.selectedItem;
        break;
      case 'portfolio':
        this.selectedItem = this.portfolioAutoCompleteComponent.selectedItem;
        break;
      case 'sleevePortfolio':
        this.selectedItem = this.sleevePortfolioAutoCompleteComponent.selectedItem;
        break;
      case 'model':
        this.selectedItem = this.modelAutoCompleteComponent.selectedItem;
        break;
      case 'sleeves':
        this.selectedItem = this.sleevesAutoCompleteComponent.selectedItem;
        break;
      case 'tradeGroupsForPortfolio': /**Need to verify and remove */
        this.selectedItem = this.tradeGroupsAutoCompleteComponent.selectedItem;
        break;
      case 'tradeGroupsForAccount': /**Need to verify and remove */
        this.selectedItem = this.tradeGroupsAutoCompleteComponent.selectedItem;
        break;
      case 'tradeGroups':
        this.selectedItem = this.tradeGroupsAutoCompleteComponent.selectedItem;
        break;
      default:
    }
  }

  /** To add selected item to grid */
  addItem() {
    this.validateSelectedItem();
    if (typeof (this.selectedItem) !== 'string' && this.selectedItem) {
      // Item pushing into grid
      if (this.isSpendCash || this.isRaiseCash) {
        if (this.enteredAmount === undefined || this.enteredAmount === null || this.enteredAmount === '') { return; }
        this.addItemForRaiseOrSpendCash();
      } else if (!(this.isSpendCash || this.isRaiseCash)
        && (this.tradeFilterMethod === 'tradeGroupsForAccount' || this.tradeFilterMethod === 'tradeGroupsForPortfolio')) {          // OE-2894
        if (this.List.length > 0) {
          const match = this.List.filter(m => m.id === this.selectedItem.id);
          if (match.length > 0) { return; }
        }
        const id = this.selectedItem.id;
        if (this.portfolioFlags && this.portfolioFlags.length > 0) {
          this.List.push(this.selectedItem);
          this.List.forEach(tg => {
            if (tg.id === id) {
              tg.portfolioFlags = this.portfolioFlags;
            }
          });
        } else {
          return;
        }
      } else {
        this.List.push(this.selectedItem);
      }

      this.analyzeAnalytics();
      this.showAnalyticsFailedWarning = this.showAnalyticsWarningMessage();
      this.gridApi.setGridOption('rowData', this.List);
      this.gridApi.refreshCells();
      this.gridApi.sizeColumnsToFit();
      this.selectedItem = undefined;
      this.enteredAmount = undefined;
      this.contributionBalance = undefined;
      this.managedValue = undefined;
      this.clearFilterAutoComplete();
      this.checkForSleeveAccounts();
      this.getSleeveAccountMethod();
    }
  }

  addItemForRaiseOrSpendCash() {
    if (this.tradeFilterMethod === Consts.TradeFilterMethod.SLEEVE_PORTFOLIO) {
      this.List.push(this.getTradeGridItemForSleevePortfolio());
    } else if (this.tradeFilterMethod === Consts.TradeFilterMethod.TRADE_GROUPS_FOR_ACCOUNT || this.tradeFilterMethod === Consts.TradeFilterMethod.TRADE_GROUPS_FOR_PORTFOLIO) {
      if (this.tradeGroupAccounts.length > 0) {
        this.tradeGroupAccounts.forEach(element => {
          const account = this.List.find(ele => ele.id === element.id);
          if (!account) {
            this.List.push(this.getTradeGridItemForAccount(element));
          }
        });
        this.tradeGroupAccounts = [];
      }
    } else {
      this.List.push(this.getTradeGridItemForAccount(this.selectedItem));
    }
    this.spendAndRaiseCashSleeveTypeCheck();
  }

  getTradeGridItemForSleevePortfolio(): ITradeGridItem {
    const item: ITradeGridItem = {
      id: this.selectedItem.id,
      portfolioId: this.selectedItem.id,
      name: this.selectedItem.name,
      amount: Number(this.enteredAmount),
      custodialAccountNumber: this.selectedItem.custodialAccountNumber,
      needAnalytics: this.selectedItem.needAnalytics,
      failedReason: this.selectedItem.failedReason,
      hasContribution: this.selectedItem.hasContribution,
      hasDistribution: this.selectedItem.hasDistribution
    };
    if (this.isSpendCash) {
      item.contributionAmount = Number(this.contributionBalance);
    } else {
      item.contributionAmount = Number(this.managedValue);
    }
    return item;
  }

  getTradeGridItemForAccount(element: any): ITradeGridItem {
    return {
      id: element.id,
      name: element.name,
      amount: Number(this.enteredAmount),
      accountId: element.accountId,
      accountNumber: element.accountNumber,
      portfolioId: element.portfolioId,
      portfolioName: element.portfolioName,
      sleeveType: element.isSleeve ? Consts.sleeveTypeEnum.Sleeve : Consts.sleeveTypeEnum.None,
      modelId: element.modelId,
      needAnalytics: element.needAnalytics,
      failedReason: element.failedReason,
      isSleeve: element.isSleeve,
      hasContribution: element.hasContribution,
      hasDistribution: element.hasDistribution
    };
  }

  /** To clear trade filters auto complete  */
  clearFilterAutoComplete() {
    switch (this.tradeFilterMethod) {
      case 'portfolio':
        this.portfolioAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'account':
        this.accountsAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'sleevePortfolio':
        this.sleevePortfolioAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'model':
        this.modelAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'sleeves':
        this.sleevesAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'tradeGroupsForPortfolio': /** Need to verify and remove */
        this.tradeGroupsAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'tradeGroupsForAccount': /** Need to verify and remove */
        this.tradeGroupsAutoCompleteComponent.selectedItem = undefined;
        break;
      case 'tradeGroups':
        this.tradeGroupsAutoCompleteComponent.selectedItem = undefined;
        break;
      default:
    }
  }

  /** Set redio button default selection */
  setRadioBtnSelection() {

    switch (this.tradeFilterMethod) {
      case 'portfolio': this.portfolio = 1; break;
      case 'account': this.account = 1; break;
      case 'sleevePortfolio': this.sleevePortfolio = 1; break;
      case 'model': this.model = 1; break;
      case 'sleeves': this.sleeves = 1; break;
      case 'tradeGroupsForPortfolio': this.tradeGroupsForPortfolio = 1; break; /**Need to verify and remove */
      case 'tradeGroupsForAccount': this.tradeGroupsForAccount = 1; break; /**Need to verify and remove */
      case 'excelImportForPf': this.excelImportForPf = 1; break;
      case 'excelImportForSlvs': this.excelImportForSlvs = 1; break;
      case 'excelImport': this.excelImport = 1; break;
      case 'tradeGroups': this.tradeGroups = 1; break;
      default:
    }
  }

  /** Excel Import Pop-up */
  excelImportProcess() {
    this.showFileUploadError = false;
    this.checkUploadFile = true;
    this.displayImportModel = true;
    this.modelErrors = false;
    this.checkDragFile = false;
  }

  /** To dragFile */
  dragFile(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /** To drop file */
  dropFile(event) {
    this.dragFileName = '';
    this.file.name = '';
    this.uploadResults.records = [];
    // eslint-disable-next-line eqeqeq
    if (event.dataTransfer.files.length != 1) {
      this.fileUploadError = 'Only one file can be uploaded at a time.';
      this.showFileUploadError = true;
    } else if (!this.isValidExcelFile(event.dataTransfer.files[0].type)) {
      this.fileUploadError = 'Only .xlsx or .csv file can be uploaded.';
      this.showFileUploadError = true;
    } else {
      this.checkDragFile = true;
      this.dragFileName = event.dataTransfer.files[0].name;
      this.selectedFile(event.dataTransfer.files);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  /** Fires, clicking on choose file */
  clickOnChooseFile(event) {
    event.target.value = null;
  }

  /** To select template */
  selectedTemplate(event) {
    this.uploadResults.records = [];
    this.dragFileName = '';
    this.file.name = '';
    this.checkDragFile = false;
    this.isNotValidFile = false;
    this.analyzeAnalytics();
    if (event.target.files.length > 0) {
      this.selectedFile(event.target.files);
    } else {
      this.disableFilterNextBtn = true;
      this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
    }
  }

  /** To select file  */
  selectedFile(file) {
    this.showFileUploadError = false;
    const selectedFile = file[0];
    if (this.isValidExcelFile(selectedFile.type)) {
      this.file.document = selectedFile;
      this.file.name = selectedFile.name;
      this.postFile(selectedFile);
    } else {
      this.fileUploadError = 'Only .xlsx or .csv file can be uploaded.';
      this.showFileUploadError = true;
      this.disableUploadBtn = true;
    }
  }

  /** To upload file to server */
  postFile(selectedFile) {
    if (selectedFile.name) {
      const uploadFileTemplate$ = this._tradeToolsService.uploadFileTemplate(selectedFile, this.showSleevePortfolio);
      const httpResult$ = uploadFileTemplate$.pipe(
        switchMap(uploadModel => {
          // Spend Cash:Import file validation, specific to account/sleeveportfolio
          if ((this.isSpendCash || this.isRaiseCash) && !this.isCalculateAtSleevePf) {
            this.isNotValidFile = uploadModel.recordType === 'portfolio';
          }
          if (this.isCalculateAtSleevePf || this.isCashNeed) {
            TradeToolHelper.validateSleeveTypeForFileImport(uploadModel.records);
          }
          // End

          // To enabling next button after file selection in spend cash
          if (this.isSpendCash) {
            this.disableFilterNextBtn = !this.isNotValidFile;
          } else if (this.isRaiseCash) {  // To enabling next button after file selection in raise cash
            if (!this.isNotValidFile) {
              this.disableFilterNextBtn = (this.selectedSleeveAccountMethod && this.selectedSleeveAccountMethod !== '');
            } else {
              this.disableFilterNextBtn = false;
            }
          } else {
            this.disableFilterNextBtn = false;
          }
          this.tradeFilterCallback.emit({value: this.disableFilterNextBtn});

          // To disable next button if all the records are invalid
          const match = uploadModel.records.filter(v => v.isValid);
          if (this.isSpendCash) {
            this.disableFilterNextBtn = (!this.isNotValidFile) ? match.length <= 0 : true;
          } else if (this.isRaiseCash) {
            if (this.isCalculateAtSleevePf) {
              this.disableFilterNextBtn = (!this.isNotValidFile) ? !(match.length > 0 && this.selectedSleeveAccountMethod && this.selectedSleeveAccountMethod !== '') : true;
            } else {
              this.disableFilterNextBtn = (!this.isNotValidFile) ? match.length <= 0 : true;
            }
          } else {
            this.disableFilterNextBtn = match.length <= 0;
          }
          this.tradeFilterCallback.emit({value: this.disableFilterNextBtn, isAllInvalid: true});

          // eslint-disable-next-line eqeqeq
          if (uploadModel.recordType == 'account') {
            const invalidRecords = uploadModel.records.filter(item => (!item.isValid));
            invalidRecords.forEach(item => {
              // eslint-disable-next-line eqeqeq
              if (item.accountId == null) {
                item.accountId = item.accountNumber;
              }
            });
          }

          // OE-4356 Fixed issue empty rows shown in table.
          if (this.isCalculateAtSleevePf || this.isCashNeed ||
            (uploadModel.recordType === Consts.LIQUIDATE_CONSTANTS.RECORD_TYPES.PORTFOLIO && this.isLiquidate)) {
            uploadModel.records = uploadModel.records.filter(item => (item.id));
          } else {
            uploadModel.records = uploadModel.records.filter(item => (item.accountId));
          }
          this.uploadResults = uploadModel;
          this.createFileImportColDefs();
          this.checkForSleeveAccounts();
          // OE-4356 if all trades are invalid then do not show analytic status tag.
          if (match.length > 0) {
            this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(uploadModel.records, uploadModel.recordType);
            this.showAnalyticsFailedWarning = this.showAnalyticsWarningMessage();
          }
          return this._portfolioService.getPortfolioSimpleListByIds(uploadModel.records.map(x => x.id));
        }),
      );

      httpResult$.subscribe(accounts => {
        this.convertDataIntoTradeGridItem(accounts, true);
        this.getSleeveAccountMethod();
      });
    }
  }


  /** Fires on checkbox 'Calculate at Sleeve Portfolio' check/uncheck */
  onChkChange(event) {
    if (event.checked) {
      this.showSleevePortfolio = true;
      this.showAccounts = false;
      this.showtradeGroups = false;
      this.isSleevePortfolio = true;
      this.isProtectSetAsideInCont = true;
      this.disableFilterNextBtn = true;
      this.tradeFilterMethod = 'sleevePortfolio';
      this.setRadioBtnSelection();
      // this.List = [];
      // commented because it update column definition either selecting NO from popup
      // this.createColDefs();
      // Fix for OE-2471
      if (this.List.length > 0) {
        this.displayConfirm = true;
      } else {
        this.createColDefs();
      }
      this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn, isAllRecordsInvalid : false});
    } else {
      this.isProtectSetAsideInCont = false;
      this.showSleevePortfolio = false;
      this.showAccounts = true;
      this.isSleevePortfolio = false;
      this.tradeFilterMethod = 'account';
      this.setRadioBtnSelection();
      this.List = [];
      this.createColDefs();
      this.showtradeGroups = true;
      this.setNextLevel();
      this.disableFilterNextBtn = true;
      this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
    }

    this.uploadResults.recordType = undefined;
    // commented because it always diavled next button
    // this.disableFilterNextBtn = true;
    // this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
    this.enteredAmount = undefined;
    this.contributionBalance = undefined;
    this.managedValue = undefined;
    this.isNotValidFile = false;
    this.selectedSleeveAccountMethod = '';
    this.getSleeveAccountMethod();
    this.checkForSleeveAccounts();
  }

  /** change event for sleeve account method*/
  changeSleeveAccountMethod(event) {
    // to check the Cash target selected or not for raise case scenario
    let isAllInvalid: boolean = false;
    if (this.isCalculateAtSleevePf && this.tradeFilterMethod === 'excelImport') {
      const match = this.uploadResults.records.filter(v => v.isValid);
      this.disableFilterNextBtn = (!(event.target.value !== '' && match.length > 0));
      isAllInvalid = true;
    } else {
      this.disableFilterNextBtn = (!(event.target.value !== '' && this.List.length > 0));
    }
    if (this.selectedSleeveAccountMethod === '' && this.isRaiseCash && this.isSleevePortfolioEnabled()) {
      this.disableFilterNextBtn = true;
    }
    this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn, isAllInvalid: isAllInvalid });
    if(this.isSpendCash) {
      this.isProtectSetAsideInCont = this.selectedSleeveAccountMethod === 'ContributionSleeve' ? true : false;
    }
    this.analyzeAnalytics();
    this.checkForSleeveAccounts();
  }

  /** To validate Amount input */
  validateAmount(event) {
    // // To accept two decimals
    // let reg = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
    // let val = reg.exec(event.target.value);
    // if (val) this.enteredAmount = val[0];

    // eslint-disable-next-line eqeqeq
    if (this.tradeFilterMethod == 'sleevePortfolio') {
      if (this.isRaiseCash) {

        const amount = _.get( this, 'portfolioManagedItem.amount');
        if (!_.isNil(amount)) {
          if (+this.enteredAmount > +amount) {
            // eslint-disable-next-line eqeqeq
            this.enteredAmount = (amount == 0) ? '0' : amount;
          }
        }
      } else {

        const amount = _.get( this, 'portfolioContributionItem.amount');
        if (!_.isNil(amount)) {
          if (+this.enteredAmount > + amount) {
            this.enteredAmount = amount;
          }
        }
      }
    }
  }

  /** To check entered amount */
  checkAmount(event, val) {
    return Util.isValidDecimalInputKey(event, val, 2);
  }

  /** To accept only numberic */
  amountInputkeyPress(event) {
    // eslint-disable-next-line eqeqeq
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  /** TODO: Needs to verify and remove */
  /** To get portfolio contribution amount */
  getPortfolioContributionAmount(sleevePortfolioId: number) {
    this._portfolioService.getPortfolioContributionAmount(sleevePortfolioId)
      .subscribe(m => {
        this.portfolioContributionItem = m;
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onFileImportGridReady(params) {
    this.fileImportGridApi = params.api;
    this.fileImportGridApi.autoSizeAllColumns();
  }

  updateAnalyticsStatus(disableNextButton) {
    if (typeof disableNextButton === 'object') {
      if (this.isCalculateAtSleevePf && this.selectedSleeveAccountMethod === '' && this.isRaiseCash) {
        disableNextButton.value = true;
      }
      this.tradeFilterCallback.emit(disableNextButton);
    } else if (this.isCalculateAtSleevePf && this.selectedSleeveAccountMethod === '' && this.isRaiseCash) {
      this.tradeFilterCallback.emit({ value: true });
    } else {
      this.tradeFilterCallback.emit({ value: disableNextButton });
    }
    this.showAnalyticsFailedWarning = this.showAnalyticsWarningMessage();
    if (this.tradeFilterMethod === OptionFilterType.Import && this.fileImportGridApi) {
      this.fileImportGridApi.setGridOption('rowData', this.uploadResults.records);
    } else if (this.gridApi) {
      if(this.runFromTactical && this.tradeFilterMethod === TradeFilterMethod.SLEEVE_PORTFOLIO && (this.isSpendCash || this.isRaiseCash)) {
        this.createColDefs();
        this.gridApi.setGridOption('columnDefs', this.tradesFilterColumnDefs);
        this.getSleeveAccountMethod();
      }
      this.gridApi.setGridOption('rowData', this.List);
    }
  }

  // OE-2471- Confirmation popup onClick "yes"
  confirmYes() {
    const sleevedList = [];
    // to prepare sleeved list
    this.List.forEach(element => {
      if (element.sleeveType !== Consts.sleeveTypeEnum.None) {
        sleevedList.push(element);
      }
    });
    // filtering unique portfolios as we have multiple accounts
    let filterdPortfolioData = [];
    const uniqPortfolioIds = sleevedList.map(r => r.portfolioId)
      .filter((value, index, self) => self.indexOf(value) === index);

    uniqPortfolioIds.forEach(val => {
      // eslint-disable-next-line eqeqeq
      const uniqElement = sleevedList.find(x => x.portfolioId == val);
      filterdPortfolioData.push(uniqElement);
    });
    this.displayConfirm = false;
    this.createColDefs();
    this.getSleeveAccountMethod();
    if (this.isSpendCash && uniqPortfolioIds.length > 0) {
      forkJoin([this._portfolioService.getSpendCashMaxAmountForPortfolios(uniqPortfolioIds),
        this.getSleevedPortfoliosByPortfolioIds(uniqPortfolioIds)])
        .subscribe((results: any[]) => {
            // OE-3709 transform the account objects into portfolio objects
          this.List = results[1].map(x => {
            const amountDetails = results[0].find(m => m.id === x.portfolioId);
            x.amount = amountDetails ? amountDetails.amount : 0;
            // OE-4235 Fixed the issue Spend Cash - ID is required error on calculate.
            return {
              id: x.portfolioId,
              portfolioId: x.portfolioId,
              custodialAccountNumber: x.custodialAccountNumber || x.accountNumber,
              name: x.portfolioName,
              amount: x.amount,
              contributionAmount: x.amount,
              isSleeve: x.isSleeve,
              needAnalytics: x.needAnalytics,
              failedReason: x.failedReason
            };
          });
          this.setNextLevel();
        });
    } else if (this.isRaiseCash && uniqPortfolioIds.length > 0) {
      this.getSleevedPortfoliosByPortfolioIds(uniqPortfolioIds).subscribe(results =>{
        this.List = results;
        this.setNextLevel();
      });
    } else {
      // OE-3709 transform the account objects into portfolio objects
      filterdPortfolioData = filterdPortfolioData.map(x => {
        return { id: x.portfolioId, portfolioId: x.portfolioId, custodialAccountNumber: x.custodialAccountNumber, name: x.portfolioName, needAnalytics: x.needAnalytics, failedReason: x.failedReason };
      });
      this.List = filterdPortfolioData;
      this.setNextLevel();
    }
  }

  private getSleevedPortfoliosByPortfolioIds(uniqPortfolioIds: number[]): Observable<any> {
    return this._portfolioService.getAccountsSimpleListByPortfolioIds(uniqPortfolioIds)
      .pipe(map(items => {
        return items
          .filter(account => account.sleeveType === Consts.sleeveTypeEnum.Custodial)
          .map(account => {
            return {
              id: account.portfolioId,
              name: account.name,
              amount: 0,
              accountId: account.accountId,
              accountNumber: account.accountNumber,
              custodialAccountNumber: account.accountNumber,
              portfolioId: account.portfolioId,
              portfolioName: account.portfolioName,
              sleeveType: account.sleeveType,
              isSleeve: account.isSleeve,
              modelId: account.modelId,
              needAnalytics: account.needAnalytics,
              failedReason: account.failedReason
            };
        });
      }));
  }

  // OE-2471- Confirmation popup onClick "No"
  confirmNo() {
    this.showSleevePortfolio = false;
    this.showAccounts = true;
    this.isSleevePortfolio = false;
    this.isCalculateAtSleevePf = false;
    this.isProtectSetAsideInCont = false;
    this.displayConfirm = false;
    this.tradeFilterMethod = TradeFilterMethod.ACCOUNT;
    if (this.List && this.List.length === 0) {
      this.tradeFilterCallback.emit({ value: true});
    } else {
      this.analyzeAnalytics();
    }
  }

  setNextLevel() {
    this.uploadResults.recordType = undefined;
    this.disableFilterNextBtn = false;
    if (this.isCalculateAtSleevePf && this.selectedSleeveAccountMethod === '' && this.isRaiseCash) {
      this.disableFilterNextBtn = true;
    }
    this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn, data: this.tradeFilterMethod });
    this.enteredAmount = undefined;
    this.contributionBalance = undefined;
    this.managedValue = undefined;
    this.isNotValidFile = false;
    this.analyzeAnalytics();
  }

  analyzeAnalytics() {
    if (this.tradeFilterMethod === TradeFilterMethod.EXCEL_IMPORT) {
      this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.uploadResults.records, this.uploadResults.recordType);
    } else if (this.tradeFilterMethod === TradeFilterMethod.TRADE_GROUPS_FOR_ACCOUNT || this.tradeFilterMethod === TradeFilterMethod.TRADE_GROUPS_FOR_PORTFOLIO) {
      if (this.isSpendCash || this.isRaiseCash) {
        this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, TradeFilterMethod.ACCOUNT);
      } else {
        if (this.List.length === 0) {
          this.tradeFilterCallback.emit({ value: true });
        }
        this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, TradeFilterMethod.TRADE_GROUP);
      }
    } else {
      this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
    }
  }

  addSleeveContributions() {
    // disable button;
    this.disableContributionButton = true;
    this._tradeToolsService.calculateContributionsForSleeves().subscribe(data => {
       this.List = _.map(data, (itm: IContributionSleeveTrade) => {
        const r: ITradeGridItem =     {
          id: itm.portfolioId,
          portfolioId: itm.portfolioId,
          name: itm.portfolioName,
          amount: itm.tradeAmount,
          contributionAmount: itm.tradeAmount,
          custodialAccountNumber: itm.accountNumber,
          accountNumber: itm.toId.toString(),
          needAnalytics: itm.needAnalytics,
          failedReason: null
        };
        return r;

      });
    }, error => {
      this.disableContributionButton = false;
      this.setNextLevel();

    }, () => {
      this.disableContributionButton = false;
      this.spendAndRaiseCashSleeveTypeCheck();
      this.setNextLevel();
      this.getSleeveAccountMethod();
    });
  }

  showContributionsButton() {
    return (this.isSpendCash && (this.tradeFilterMethod === 'sleevePortfolio'));
  }

  isSleevePortfolioEnabled() {
    return (this.tradeFilterMethod === 'sleevePortfolio');
  }

  goToImportBySecurity() {
    // route to Import by Security tool
    this._router.navigate(['/eclipse/tradetool/importbysecurity'], {onSameUrlNavigation: 'reload'});
  }

  /**
   * Method fired when user selects 'All Taxable Accounts' radio button.
   */
  getAllTaxableAccounts() {
    this._accountService.getAccountsByTaxableType('taxable')
      .subscribe(response => {
        if (response && response.length) {
          this.populateAllTaxableAccountsData(response);
          this.tradeToolAnalyticsAnalyzerComponent.setPortfolioAnalyticsDetails(this.List, this.tradeFilterMethod);
        }
      });
  }

  /**
   * Method to populate all taxable accounts data.
   * @param response - Response from accounts endpoint.
   */
  populateAllTaxableAccountsData(response) {
    const finalResponse = response.map(account => {
      return {
        id: account.id,
        name: account.name,
        amount: 0,
        accountId: account.accountId,
        accountNumber: account.accountNumber,
        portfolioId: account.portfolioId,
        portfolioName: account.portfolioName,
        sleeveType: account.sleeveType,
        modelId: account.modelId,
        needAnalytics: account.needAnalytics,
        failedReason: account.failedReason,
        hasTaxLossHarvest: account.hasTaxLossHarvest
      };
    });
    this.parentModel.selectedAccountsList = finalResponse;
    this.List = this.parentModel.selectedAccountsList;
    this.disableFilterNextBtn = false;
    this.tradeFilterCallback.emit({ value: this.disableFilterNextBtn });
  }

  /**
   * Method to check if any portfolio has analytics status 'failed' and show warning message.
   * @returns true or false - whether to show warning messsage or not.
   */
  showAnalyticsWarningMessage(): boolean {
    let showMessage = false;
    switch (this.tradeFilterMethod) {
      case 'portfolio':
      case 'account':
        showMessage = !!this.List.find(t => Number(t.needAnalytics) === Consts.AnalyticsStatus.Failed);
        break;
      case 'model':
      case 'tradeGroupsForPortfolio':
      case 'tradeGroupsForAccount':
        showMessage = this.showAnalyticsWarningMessageForTradeGroup();
        break;
      case 'excelImport':
        showMessage = !!this.uploadResults.records.find(t => Number(t.needAnalytics) === Consts.AnalyticsStatus.Failed);
        break;
    }
    return showMessage;
  }

  showAnalyticsWarningMessageForTradeGroup(): boolean {
    if (this.tradeFilterMethod === 'tradeGroupsForAccount' && (this.isRaiseCash || this.isSpendCash)) {
      return !!this.List.find(t => Number(t.needAnalytics) === Consts.AnalyticsStatus.Failed);
    } else {
      return !!this.List.find(t => t.portfolioFlags.find(pf => Number(pf.needAnalytics) === Consts.AnalyticsStatus.Failed));
    }
  }

  spendAndRaiseCashSleeveTypeCheck() {
    if((this.isSpendCash || this.isRaiseCash) && this.List) {
      const validRecord = this.List.find(item => item.sleeveType !== Consts.sleeveTypeEnum.Contribution && item.sleeveType !== Consts.sleeveTypeEnum.Distribution);
      let disableFilterNextBtn = false;
      if(!validRecord) {
        disableFilterNextBtn = true;
      }
      this.tradeFilterCallback.emit({ value: disableFilterNextBtn, isAllInvalidSleeveType: true });
    }
  }

  getSleeveAccountMethod() {
    if (this.isRaiseCash || this.isSpendCash) {
      this.addSleeveAccountMethods();
    }
  }

  addSleeveAccountMethods() {
    this.sleeveAccountMethods = [{ name: 'Select Sleeve Account Method', value: '' }];
    const addContributionDistribution = this.List.some(e =>
        e.sleeveType === Consts.sleeveTypeEnum.Contribution
        || e.sleeveType === Consts.sleeveTypeEnum.Distribution
        || e.hasContribution || e.hasDistribution);

    if (this.isRaiseCash) {
      this.addRaiseCashMethods(addContributionDistribution);
    } else if (this.isSpendCash && addContributionDistribution) {
      this.addSpendCashMethods(addContributionDistribution);
    }
  }

  addRaiseCashMethods(addContributionDistribution : boolean) {
    if (this.isCalculateAtSleevePf) {
      this.sleeveAccountMethods.push({name: 'Individual Sleeve', value: SLEEVE_ACCOUNT_METHOD.INDIVIDUAL_SLEEVES});
    }
    if (addContributionDistribution) {
      this.sleeveAccountMethods.push({ name: 'Distribution Sleeve', value: SLEEVE_ACCOUNT_METHOD.DISTRIBUTION_SLEEVE });
    }
  }

  addSpendCashMethods(addContributionDistribution : boolean) {
    if (addContributionDistribution) {
      this.sleeveAccountMethods.push({ name: 'Contribution Sleeve', value: SLEEVE_ACCOUNT_METHOD.CONTRIBUTION_SLEEVE });
    }
  }

  checkForSleeveAccounts(): void {
    if (this.isRaiseCash || this.isSpendCash) {
      let haveSleeveAccount: boolean = true;
      if (!this.isSleevePortfolioEnabled() && this.selectedSleeveAccountMethod !== '') {
        let sleeveAccount;
        if (this.uploadResults.recordType === TradeFilterMethod.ACCOUNT || this.uploadResults.recordType === TradeFilterMethod.PORTFOLIO) {
          sleeveAccount = this.uploadResults.records.find(v => v.isValid && (v.isSleeve || v.sleevePortfolio));
        } else {
          sleeveAccount = this.List.find(item => item.isSleeve);
        }
        haveSleeveAccount = sleeveAccount ? true : false;
      }
      this.tradeFilterCallback.emit({ value: haveSleeveAccount, haveValidSleeveAccount: true });
    }
  }

  setRowData() {
    if (this.tradeFilterMethod !== TradeFilterMethod.EXCEL_IMPORT && this.tradesFilterGridOptions && this.gridApi) {
      this.gridApi.setGridOption('rowData', this.List);
    } else if (this.fileImportGridApi){
      this.fileImportGridApi.setGridOption('rowData', this.uploadResults.records);
    }
  }

  showPortfolioAutocomplete() {
    return this.tradeFilterMethod === 'sleevePortfolio' && (this.isRaiseCash || this.isSpendCash || this.isCashNeed);
  }

  showSleeveAutocomplete() {
    return (this.tradeFilterMethod === 'sleevePortfolio' || this.tradeFilterMethod === 'individualSleeve') && !(this.isRaiseCash || this.isSpendCash || this.isCashNeed);
  }
}
