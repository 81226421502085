export const ERROR_MESSAGE = {
  VALUE_MUST_BE_LESS_THAN_EQUAL: (value1: string, value2: string) => `${value1} must be less than equal to ${value2}`,
  VALUE_MUST_BE_LESS_THAN: (value1: string, value2: string) => `${value1} must be less than ${value2}`,
  CASH_TARGET_RANGE_CANNOT_BE_OVERLAPPED: () => 'Cash Target Range cannot be overlapped.',
  CASH_TARGET_RANGE_ALREADY_EXIST: () => 'Cash Target Range already exist.'
};

export const COLUMN_NAME = {
  CASH_VALUE_FROM: 'Cash value: From',
  CASH_VALUE_TO: 'Cash value: To',
  TARGET_VALUE: 'Target value',
  MIN_VALUE: 'Min value',
  MAX_VALUE: 'Max value'
};
