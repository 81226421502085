import { Component, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { GridOptions, ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { BaseComponent } from '../../core/base.component';
import { Utils as Util } from '../../core/functions';
import { IPortfolio, IPortfolioCustomView, IPortfolioSimple, IAddAccounts, IPortfolioDetails, IPortfolioByHouseHold } from '../../models/portfolio';
import { IAccount, IAccountSimple, IAccountSimpleSearch, IFormattedAccountSuggestions } from '../../models/account';
import { PortfolioService } from '../../services/portfolio.service';
import { AccountService } from '../../services/account.service';
import { PreferenceService } from '../../services/preference.service';
import { PreferenceHubService } from '../../components/account/shared/preference-hub.service';
import { IBatchJobPreferenceParameter } from '../../models/preferences/preference';
import { SplitIoService } from '../../core/feature-flag/splitio.service';
import { EditorEntityType, EntityEditorService } from '../entity-editor';
import * as Consts from '../../libs/app.constants';
import { IDynamicGridColumnState, IDynamicGridViewState } from '../gridextensions/dynamic-column-filter.component';

@Component({
  selector: 'eclipse-assign-accounts-portfolio',
  templateUrl: './assign.accounts.html',
  providers: [PortfolioService]
})
export class Accounts extends BaseComponent {
  @Output() parentCallback = new EventEmitter();
  @Input() accountModel: IAccountSimple;
  @Input() portfolioModel: IPortfolio;
  @Input() householdModel: IPortfolioCustomView;
  @Input() showAssignPopup: boolean;
  @Output() reAssignPortfolio = new EventEmitter();

  private gridApiAccount: GridApi;
  private gridApiPortfolio: GridApi;
  accountGridOptions: GridOptions;
  accountsColumnDefs: ColDef[];
  accounts: IAccount[] = [];
  portfolioGridOptions: GridOptions;
  portfolioColumnDefs: ColDef[];
  portfolios: IPortfolio[] = [];
  household: IPortfolioCustomView;
  accountsSuggestions: IAccountSimpleSearch[] = [];
  portfolioSuggestions: IPortfolioSimple[] = [];
  householdSuggestions: IPortfolioCustomView[] = [];
  disableType: Boolean = false;
  accountFilterId: number = 0;
  portfolioFilterId: number = 0;
  accountType: number = 0;
  accountSearch: boolean = true;
  portfolioSearch: boolean = true;
  householdSearch: boolean;
  searchAccountString: string;
  searchPorfolioString: string;
  searchHouseHoldString: string;
  displayConfirm: boolean;
  hasDisabledAccounts: boolean = false;
  copyOfSelectedAccounts: any[] = [];
  copyOfSelectedPortfolio: any[] = [];
  route: string;
  isDisabledAssignBtn: boolean = true;
  enabledReverseSyncPortfolios: boolean = false;

  constructor(private _router: Router,
    private activateRoute: ActivatedRoute,
    private _portfolioService: PortfolioService,
    private _accountService: AccountService,
    private readonly _preferenceService: PreferenceService,
    private readonly _preferenceHubService: PreferenceHubService,
    private readonly _splitIoService: SplitIoService,
    private readonly _entityEditorService: EntityEditorService) {
    super();
    this.accountGridOptions = this.defaultGridOptions;
    this.portfolioGridOptions = this.defaultGridOptions;
    this.initAccountsColumnDefs();
    this.initPortfolioColumnDefs();
    this.route = Util.activeRoute(activateRoute);
    this._preferenceService.getPreferenceByName('reverseSyncPortfolios').subscribe(result => {
      this.enabledReverseSyncPortfolios = Util.convertIntoBooleanValue(result)
    });
  }

  onAccountGridReady(event: GridReadyEvent) {
    this.gridApiAccount = event.api;
  }

  onPortfolioGridReady(event: GridReadyEvent) {
    this.gridApiPortfolio = event.api;
  }

  /** Get portfolios by type */
  getPortfoliosByType(portfolioTypeId: number) {
    // eslint-disable-next-line eqeqeq
    if (portfolioTypeId == 0) {
      this._portfolioService.getPortfolios(+portfolioTypeId)
        .subscribe((model: IPortfolio[]) => {
            this.portfolios = model;
          },
          error => {
            throw error;
          });
      // eslint-disable-next-line eqeqeq
    } else if (portfolioTypeId == 1) {
      this._portfolioService.getNewPortfolios()
        .subscribe((newModel: IPortfolio[]) => {
            this.portfolios = newModel;
          },
          error => {
            throw error;
          });
    }
  }

  /** Search by Portfolio Name */
  autoPortfolioSearch(event) {
    this._portfolioService.searchPortfolio(event.query.toLowerCase())
      .subscribe((model: IPortfolioSimple[]) => {
        this.portfolioSuggestions = model.filter(a => !a.isDeleted && !a.sleevePortfolio);
        this.portfolios.forEach(element => {
          // eslint-disable-next-line eqeqeq
          this.portfolioSuggestions = this.portfolioSuggestions.filter(record => record.id != element.id);
        });
        this.formatPortfolioSuggestions(this.portfolioSuggestions, event);
      });
  }

  /** Search by HouseHold ID's */
  autoHouseholdSearch(event) {
    this._portfolioService.searchHousehold(event.query.toLowerCase())
      .subscribe((model: IPortfolioByHouseHold[]) => {
        this.householdSuggestions = model.filter(s => !s.isDeleted && !s.sleevePortfolio);
      });
  }

  updateBatchJobForAccountAssign(data: any, selectedPortfolioId: number): void {
    if (data && data.message === Consts.ACCOUNT_ASSIGN.SUCCESS) {
      const batchJobParams = {
        runFor: Consts.ModuleName.Portfolio,
        runForIds: [selectedPortfolioId],
        primaryTeamId: this._sessionHelper?.getUser()?.primaryTeam
      } as IBatchJobPreferenceParameter;
      this._preferenceHubService.updateBatchJobForSecurityPreferenceChange(batchJobParams);
    }
  }

  /** Confirmation for Re-Assign Portfolio/Account */
  assignConformation() {
    this.enableAssignButton();
    this.accounts = [];
    this.portfolios = [];
    const accountIds = this.gridApiAccount.getSelectedRows().map(element => element.id);

    const selectedPortfolios = this.gridApiPortfolio.getSelectedRows();
    if (Util.isNull(accountIds) || Util.isNull(selectedPortfolios)) { return; }
    const addAccountids = <IAddAccounts>{accountIds: accountIds};
    let selectedPortfolioId = selectedPortfolios[0].id;
    this._portfolioService.assignAccounts(selectedPortfolioId , addAccountids)
      .subscribe(response => {
        if (this.enabledReverseSyncPortfolios) {
          this._portfolioService.runReverseSyncPortfolios([selectedPortfolioId]).subscribe();
        }
        this.updateBatchJobForAccountAssign(response, selectedPortfolioId);
        this.showAssignPopup = false;
        this.parentCallback.emit('Parent callback event fired');
        this.reAssignPortfolio.emit();
        if (this.copyOfSelectedAccounts?.length) {
          this._entityEditorService.show({entityType: EditorEntityType.Account, data: {id: accountIds[0], editMode: false}});
        } else {
          const portfolioPriv = Util.getPermission(Consts.PRIV_PORTFOLIOS);
          this._entityEditorService.show({entityType: EditorEntityType.Portfolio, data: {id: selectedPortfolioId, editMode: portfolioPriv?.canUpdate}});
        }
      }, error => {
        console.log(error);
        throw error;
      });

    this.displayConfirm = false;
  }

  /** Assign Portfolio popup */
  assignAccountsToPortfolio() {
    const accountIds = this.gridApiAccount.getSelectedRows();
    let isPortfolio = false;

    /** To check if the selected accounts are associated with portfolios or not */
    const accountsData = [];
    accountIds.forEach(e => {
      accountsData.push((this._accountService.getPortfolioIdByAccountId(e.id)
      ));
    });

    // execute all requests one by one
    forkJoin(accountsData)
      .subscribe(data => {
        data.forEach((m: any) => {
          if (!!m.portfolioId) {
            isPortfolio = true;
            return;
          } else {
            isPortfolio = false;
          }

        });

        if (isPortfolio) {
          this.displayConfirm = true;
        } else {
          this.assignConformation();
        }

      }, error => {
        console.log(error);
      });

  }

  /** clear selected accounts and portfolio's */
  resetAssignPopup() {
    this.accountFilterId = 0;
    this.portfolioFilterId = 0;
    this.accountSearch = true;
    this.portfolioSearch = true;
    this.householdSearch = false;
    this.accounts = [];
    this.portfolios = [];
    this.searchAccountString = '';
    this.searchPorfolioString = '';
    this.searchHouseHoldString = '';
    this.hasDisabledAccounts = false;
    this.gridApiAccount.deselectAll();
    this.gridApiPortfolio.deselectAll();
    if (!this.showAssignPopup) { this.showAssignPopup = false; }
    this.parentCallback.emit('Parent callback event fired');
  }

  /** Assign Portfolio  */
  onPortfolioRowClicked(event) {
  }

  /** On row select */
  onRowSelect(event) {
    this.enableAssignButton();
  }

  /** Account DropDown selection */
  onAccountTypeChange(param) {
    this.accounts = [];
    // eslint-disable-next-line eqeqeq
    this.accountSearch = (param == 0);
    if (param > 0) {
      this.getAccountsByType(param);
    }
  }

  /** Portfolio DropDown Selection */
  onPortfolioTypeChange(param) {
    this.portfolios = [];
    // eslint-disable-next-line eqeqeq
    this.householdSearch = (param == 2);
    // eslint-disable-next-line eqeqeq
    this.portfolioSearch = !(param == 1 || param == 2);
    if (param > 0) {
      this.getPortfoliosByType(+param);
    }
  }

  /** selected item display in grid for accounts */
  onAccountSelect(account: IFormattedAccountSuggestions) {
    this.searchAccountString = '';
    const selectedAccount: IAccount = <IAccount>{
      id: account.id,
      accountId: account.accountId,
      accountName: account.name,
      accountNumber: account.accountNumber,
      portfolio: account.portfolioName,
      accountTypeName: account.accountType
    };
    this.accounts.push(selectedAccount);
    this.gridApiAccount.setGridOption('rowData', this.accounts);
    this.gridApiAccount.selectAll();
    this.enableAssignButton();
  }

  /** selected item disabled in grid for portfolio */
  onPortfolioSelect(params: any) {
    this.searchPorfolioString = '';
    this._portfolioService.getPortfolioById(params.id)
      .subscribe((result: IPortfolioDetails) => {
        if (!!result) {
          const assignTeam = result.teams.find(m => m.isPrimary);
          const portfolio = <IPortfolio>{
            id: result.id,
            name: result.general.portfolioName,
            team: assignTeam?.name ?? '',
            model: result.general.modelName,
            managedValue: result.summary.AUM.managedValue,
            excludedValue: result.summary.AUM.excludedValue,
            reserveCashActual: result.summary.reserveCashActual,
            reserveCashTarget: result.summary.reserveCashTarget,
            currentCash: result.summary.currentCash,
            pendingCash: result.summary.pendingCash,
            targetCash: result.summary.targetCash
          };
          this.portfolios.push(portfolio);
          this.gridApiPortfolio.setGridOption('rowData', this.portfolios);
          this.enableAssignButton();
        }
      });
  }

  /** selected item disabled in grid for HouseHold */
  onHouseHoldSelect(params: any) {
    this.searchHouseHoldString = '';
    this.portfolios.push(params);
    this.gridApiPortfolio.setGridOption('rowData', this.portfolios);
    this.enableAssignButton();
  }

  /** initialize Accounts Grid options */
  private initAccountsColumnDefs() {
    this.accountsColumnDefs = [
      <ColDef>{headerName: '', field: 'chck', width: 30, checkboxSelection: true, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'ID', field: 'accountId', width: 100, cellClass: 'text-center', filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Name', field: 'accountName', width: 140, cellClass: 'fs-mask', filter: 'agTextColumnFilter'},
      <ColDef>{headerName: 'Account Number', field: 'accountNumber', cellClass: 'fs-mask', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Type', field: 'accountTypeName', width: 90, cellClass: 'text-center', filter: 'agTextColumnFilter'},
      <ColDef>{headerName: 'Portfolio Name', field: 'portfolio', width: 90, cellClass: 'text-center fs-mask', filter: 'agTextColumnFilter', hide: true}
    ];
  }

  /** initialize Portfolio Grid options */
  private initPortfolioColumnDefs() {
    this.portfolioColumnDefs = [
      <ColDef>{headerName: 'Id', field: 'id', width: 100, cellClass: 'text-center', filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Name', field: 'name', width: 140, cellClass: 'fs-mask', filter: 'agTextColumnFilter'},
      <ColDef>{headerName: 'Team', field: 'team', width: 140, filter: 'agTextColumnFilter'},
      <ColDef>{headerName: 'Model', field: 'model', width: 140, filter: 'agTextColumnFilter'},
      <ColDef>{headerName: 'Managed Value', field: 'managedValue', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Excluded Value', field: 'excludedValue', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Reserve Cash Target', field: 'reserveCashTarget', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Reserve Cash Actual', field: 'reserveCashActual', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Target Cash $', field: 'targetCash', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Current Cash $', field: 'currentCash', width: 140, filter: 'agNumberColumnFilter'},
      <ColDef>{headerName: 'Pending Cash $', field: 'pendingCash', width: 140, filter: 'agNumberColumnFilter'},
    ];
  }

  /** Search by Account Name */
  autoAccountSearch(event) {
    this._accountService.searchAccounts(event.query.toLowerCase())
      .subscribe((model: IAccountSimpleSearch[]) => {
        this.accountsSuggestions = model.filter(a => !a.isDeleted && !a.isSleeve);
        this.accounts.forEach(element => {
          // eslint-disable-next-line eqeqeq
          this.accountsSuggestions = this.accountsSuggestions.filter(record => record.id != element.id);
        });
        this.formatAccountSuggestions(this.accountsSuggestions, event);
      });
  }

  /** Selected Account Name append to Assign Portfolio Grid*/
  initFromAccount(account, hasDisabledAccounts: boolean) {
    this.hasDisabledAccounts = hasDisabledAccounts;
    this.accounts = <IAccount[]>[];
    this.copyOfSelectedAccounts = account;
    if (!!account) {
      this.accounts = account;
    }
    this.showAssignPopup = true;
  }

  /** selected Portfolio Name to Assign Portfolio Grid */
  initFromPortfolio(portfolio) {
    this.portfolios = <IPortfolio[]>[];
    this.copyOfSelectedPortfolio = portfolio;
    if (!!portfolio) {
      this.portfolios = portfolio;
    }
    this.showAssignPopup = true;
  }

  /** Get All Accounts in AssginPorfolio */
  getAccountsByType(accountTypeId) {
    if (accountTypeId > 0) {
      const viewState: IDynamicGridViewState = <IDynamicGridViewState>{
        columns: <IDynamicGridColumnState[]>[
          {
            colId: 'id',
          },{
            colId: 'accountId',
          },{
            colId: 'accountName',
          },{
            colId: 'accountNumber',
          },{
            colId: 'accountTypeName',
          },{
            colId: 'portfolio',
          },
        ],
      };
      this._accountService.getAccountsDynamic(viewState, +accountTypeId)
        .subscribe((model: IAccount[]) => {
          this.accounts = model;
        });
    }
  }

  /** To reset assign popup */
  onBeforeHide() {
    this.resetAssignPopup();
  }

  /**Enable add button only after selection of User from auto search */
  enableAssignButton() {
    const selectedAccounts = this.gridApiAccount.getSelectedRows();
    const selectedPortfolios = this.gridApiPortfolio.getSelectedRows();

    if (selectedAccounts.length > 0 && selectedPortfolios.length > 0) {
      this.isDisabledAssignBtn = false;
    } else {
      this.isDisabledAssignBtn = true;
    }
  }
}
