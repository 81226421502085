import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../core/base.component';
import {LoginService} from './login.service';
import {AuthService} from '../core';

@Component({
  selector: 'eclipse-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent extends BaseComponent {
  constructor(private _loginService: LoginService, private _router: Router, private _authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.logoff();
  }

  /**
   * this method fires when we log off
   */
  logoff() {
    this._loginService.logout().subscribe({
      next: () => {
        this.clearSession();
      },
      error: () => {
      this.clearSession();
      }
    });
  }

  private clearSession() {
    this._authService.clearSession();
    this._router.navigate(['/']);
  }
}
