<div class="main">
  <eclipse-page-header pageName="Queries" [allowExcelExport]="dynamicResultActiveTabIndex > 0" (exportToExcel)="exportQueryResult(selectedQuery)">
    <div slot="action" class="actionBtn pull-right">
      <button data-target="#" *ngIf="dynamicResultActiveTabIndex === 0" label="Actions" iconPos="left" icon="fas fa-sparkles" pButton class="p-button-text p-button-secondary" data-toggle="dropdown" aria-expanded="false"></button>
      <ul class="dropdown-menu" *ngIf="dynamicResultActiveTabIndex === 0">
        <li>
          <a href="javascript:void(0)" (click)="createNewQuery('Standard')">Create Query</a>
        </li>
        <li *ngIf="selectedQuery !== 0">
          <a href="javascript:void(0)" (click)="copyQuery()">Copy Query</a>
        </li>
        <li *ngIf="selectedQuery !== 0">
          <a href="javascript:void(0)" (click)="confirmDeleteQuery(selectedQuery)">Delete Query</a>
        </li>
        <li *ngIf="selectedQuery !== 0">
          <a href="javascript:void(0)" (click)="runQuery(selectedQuery)">Run Query</a>
        </li>
      </ul>
    </div>
  </eclipse-page-header>
  <div class="dashboard-content" id="grid-height-top">
    <p-tabView styleClass="tabs-only" [(activeIndex)]="dynamicResultActiveTabIndex" [controlClose]="true" (onClose)="handleTabClose($event)" (onChange)="onTabChange($event)">
      <p-tabPanel header="Query Builder">
        <div id="querybuilder" class="tab-pane active fade in">
          <div class="col-md-3 col-sm-4 border-left">
            <div class="querybuilder">
              <div class="data-Query-list">
                <div class="searchquery">
                  <div class="form-group">
                    <div class="icon-addon addon-lg">
                      <input pInputText placeholder="Search" class="form-control custom-search-bar"
                             type="text" id="filtersubmodelData" placeholder="Search" [(ngModel)]="filterQueryText"
                             (ngModelChange)="filterDataQuery()">
                      <label class="fas fa-search custom-icon-addon" title="search" for="filtersubmodelData" aria-label="Filter queries"></label>
                    </div>
                  </div>
                </div>
                <span *ngIf="noRecordsFound" class="text-danger">No records found</span>
                <ul>
                  <!--Will apply active class when selected class="active"-->
                  <li *ngFor="let query of finalQueryObject" title="{{query.name}}">
                    <a href="javascript:void(0)" [ngClass]="{'active':query.id==selectedQuery}">
                      <span (click)="getQueryDetails(query.id)"> {{query.name}} </span>
                      <i *ngIf="query.isFavourite" class="fas fa-heart toggle-heart-icon heart-color-toggle" aria-hidden="true"></i>
                    </a>
                    <a *ngIf="query.id==queryDisplay.id" href="javascript:void(0)" required (click)="closeQuery(query.id)" class="btn-link btn-clear">Clear</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-sm-8 border-right">
            <div class="row">
              <div class="form-group col-md-5 col-sm-6 is-empty">
                <input class="form-control radius-none" placeholder="Data Query Name" (ngModelChange)="setChange()" type="text" [(ngModel)]="queryDisplay.name"
                       (blur)="(queryDisplay.isTradeGroup)?checkTradeGroupQueryName(queryDisplay.name + '_TG'):checkQueryName(queryDisplay.name)" aria-label="Data query name">
                <span class="material-input"></span>
                <span *ngIf="showQueryNameErr">
                <p style="color:red;">Query name already exists.</p>
              </span>
              </div>
              <div class="col-md-3 col-sm-6">
                <i class="fas fa-heart top-space toggle-heart-icon" [ngClass]="{'heart-color-toggle': queryDisplay.isFavourite == true}" aria-hidden="true"
                   (click)="setFavourite()"></i>
                <label class="margin-left-10 checkbox-inline">
                  <input type="checkbox" name="optcheck" [checked]="queryDisplay.isTradeGroup" [(ngModel)]="queryDisplay.isTradeGroup" (change)="setTradeGroup()"> Trade Group </label>
              </div>

            </div>
            <div>
              <div class="runquery">
                <div class="row">
                  <div class="col-md-5 col-sm-5">
                    <h4>Available Columns</h4>
                    <div class="available-column">
                      <div class="form-group top-group-space p-b-0">
                        <div class="icon-addon addon-lg">
                          <input placeholder="Search " class="form-control custom-search-bar" type="text" [(ngModel)]="filterAvailableColumnsText"
                                 (ngModelChange)="filterAvailableColumns()" id="filterAvailableColumns">
                          <label class="fas fa-search custom-icon-addon" title="search" for="filterAvailableColumns" aria-label="Search"></label>
                        </div>
                        <div class="clearfix"></div>
                        <select class="selected-column multiple-select" multiple (change)="onChangeAvailableColumn($event)"
                                [(ngModel)]="filterDisplayAvailableColumnsText" aria-label="Available columns list">
                          <option *ngFor="let acList of availableColumnList" [ngValue]="acList.id">{{acList.displayName}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 col-sm-1 p-a-0 arrow-box">
                    <button pButton (click)="dataQueryColumn_moveacrossAvailable('availableColumnList','selectedColumnList')"
                            [disabled]="isDisabledGreaterBtn()" icon="fas fa-angle-double-right" title="Select all columns"></button>
                    <br>
                    <button pButton (click)="dataQueryColumn_moveacrossSelected('selectedColumnList','availableColumnList')"
                            [disabled]="isDisabledLessThanBtn()" icon="fas fa-angle-double-left" title="Remove all columns"></button>
                  </div>
                  <div class="col-md-5 col-sm-5">
                    <h4>Selected Columns</h4>
                    <div class="selected-column">
                      <select id="dqSelectItems" #dqSelectItems multiple class="selected-column selected-column-right multiple-select" (change)="onChangeSelectedColumn($event)"
                              [(ngModel)]="filterSelectedColumns" aria-label="Selected columns list">
                        <option *ngFor="let scList of queryDisplay.selectedColumns" [selected]="scList.isSelected" [ngValue]="scList.id">{{scList.displayName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 col-sm-1 p-a-0 arrow-updown text-left">
                    <button pButton (click)="dataQueryColumn_move('up')" icon="fas fa-long-arrow-up" title="Move column up"></button>
                    <br>
                    <button pButton (click)="dataQueryColumn_move('down')" icon="fas fa-long-arrow-down" title="Move column down"></button>
                  </div>
                </div>
              </div>

              <div class="runquery">
                <h4 class="pull-left queryhd"> Query Output</h4>
                <div>
                <textarea readonly *ngIf="activeTabName === 'Standard'" aria-label="Query output">
          {{finalQueryStandard}}
            </textarea>
                  <textarea readonly *ngIf="activeTabName === 'Advanced'" aria-label="Advanced query output">
          {{finalQueryAdvanced}}
            </textarea>
                </div>
              </div>
              <div class="querytab">
                <p-tabView [activeIndex]="activeTabIndex" (onChange)="getDivId($event.index)">
                  <p-tabPanel header="Standard">
                    <eclipse-data-query-standard [standardQuery]="queryDisplay"></eclipse-data-query-standard>
                    {{autoGenerateQuery()}}
                  </p-tabPanel>
                  <p-tabPanel header="Advanced">
                    <div class="tree">
                      <eclipse-data-query-advance [currentAdvanceQueryGroups]="queryDisplay.where.groups" [selectedColumnList]="queryDisplay.selectedColumns"
                                                  [availableColumnList]="MasteravailableColumns" [advanceQueryChanges]="queryDisplay" [removeHide]="hideRemoveButton">
                      </eclipse-data-query-advance>
                      {{autoGenerateQuery()}}
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </div>
              <div class="clearfix"></div>
              <div *ngIf="showError">
                <p class="text-danger">To create a Trade Group, please select either Portfolio.Portfolio ID or Account.Account ID under selected column</p>
              </div>
              <div *ngIf="queryDisplay.showSameRuleError">
                <p class="text-danger">Same rule cannot be added</p>
              </div>
              <div *ngIf="queryDisplay.emptyRuleValidation">
                <p class="text-danger">Fields cannot be blank</p>
              </div>
              <div class="p-t-50"></div>
            </div>
          </div>
          <div class="button-footer">
            <span *ngIf="tabCountMaxm" class="text-danger pull-left">Maximum number of Query Output at a time is 5. Please close the executed query tab to Run a new Query.</span>

            <button type="button" pButton class="p-button-secondary" (click)="createNewQuery('Standard')" label="Cancel"
                    [disabled]="!queryDisplay.name==null || queryDisplay.name==undefined || queryDisplay.name=='' || queryDisplay.selectedColumns.length==0"></button>
            <button type="button" pButton (click)="saveQuery(queryDisplay.id, null)" label="Save"
                    [disabled]="queryDisplay.name==null || queryDisplay.name==undefined || queryDisplay.name=='' || queryDisplay.selectedColumns.length==0 || showError || hideSaveButton ||showQueryNameErr  || queryDisplay.showSameRuleError || saveDisable"></button>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel *ngFor="let tabData of runQueryTabsData" [header]="tabData.name" [closable]="true" [cache]="true">
        <div id="executeQuery" class="tab-pane active fade in">
          <div class="agcontainer">
            <ag-grid-angular class="ag-theme-alpine grid-height-autosizeThr" enableKeyboardSelectAllRows [context]="gridContext"
                             rowSelection="single" [columnDefs]="activeTabData.ColumnDef" [rowData]="activeTabData.result"
                             suppressContextMenu="true" [gridOptions]="activeTabData.GridOptions" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog [(visible)]="savedView.exitWarning.show" modal="true" [draggable]=false [resizable]=false class="ui-dialog-md" [closable]="true">
  <div>You will lose the unsaved changes. Do you want to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-check" (click)="confirmClick(false)" label="Yes"></button>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(true)" label="No"></button>
  </p-footer>
</p-dialog>
<p-dialog [(visible)]="isModified" modal="true" [draggable]=false [resizable]=false class="ui-dialog-md" [closable]="false">
  <div>You will lose the unsaved changes. Do you want to save & continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(false)" label="No"></button>
    <button type="button" [disabled]="queryDisplay.name==null || queryDisplay.name==undefined || queryDisplay.name=='' || queryDisplay.selectedColumns.length==0 || showError || hideSaveButton ||showQueryNameErr || saveHideInAdvanceTab || queryDisplay.showSameRuleError || saveDisable"
            pButton icon="fas fa-check" (click)="confirmClick(true, queryDisplay.id)" label="Yes"></button>
  </p-footer>
</p-dialog>
<p-dialog [(visible)]="isSaved" modal="true" [draggable]=false [resizable]=false class="ui-dialog-md" [closable]="false">
  <div>Save will update the existing Query. Do you wish to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(false)" label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="confirmUpdateClick(queryDisplay.id)" label="Yes"></button>
  </p-footer>
</p-dialog>
<p-dialog header="Delete Query" [(visible)]="displayDeleteConfirm" modal="true" [draggable]=false [resizable]=false [closable]="false" class="ui-dialog-md">
  <div>This will permanently delete the selected query. Do you want to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmDeleteClick(false)" label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="confirmDeleteClick(true)" label="Yes"></button>
  </p-footer>
</p-dialog>
