import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ColDef, GridApi, GridOptions, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import { BaseComponent } from '../../core/base.component';
import { ISecurity, ISellBuyPriorityMap } from '../../models/security';
import {
  IEquivalent,
  IHiddenSecurityPreference,
  ISecurityPreference,
  ISecurityPreferencesGet
} from '../../models/preferences/securityPreference';
import { Utils as Util } from '../../core/functions';
import { PreferenceService } from '../../services/preference.service';
import { PreferencesBusinessObjects } from '../../businessobjects/preference.businessobjects';
import { IPreferenceDataVM } from '../../viewModels/preference.data';
import { AstroService } from '../../services/astro.service';
import { AlertMessageType, ESGThemeMessages, PREFERENCE_LEVEL, ViewTypeEnum } from '../../libs/app.constants';
import { AlertService } from '../../core';
import { SecurityPreferenceHelper } from './shared/security-preference-helper';
import {
  SecurityPreferenceAddEditComponent
} from './shared/security-preference-add-edit/security-preference-add-edit.component';
import { SecurityPreferenceHubService } from './shared/security-preference-hub.service';
import { AutocompleteHubService } from '../search/shared/autocomplete-hub.service';
import { IExitWarning, ISavedView } from '../../viewModels/savedview';
import { BaseGridConfiguration } from '../gridextensions/basegrid';
import {
  SecurityPreferenceEquivalentComponent
} from './shared/security-preference-equivalent/security-preference-equivalent.component';
import * as _ from 'lodash';
import { IExcelDataSheetExportable } from '../../models/excel-export';

@Component({
  selector: 'eclipse-security-preference',
  templateUrl: './security.preference.component.html',
  styleUrls: ['./security.preference.component.scss']
})
export class SecurityPreferenceComponent extends BaseComponent implements IExcelDataSheetExportable {
  @Input() preferenceValueId: number;
  @Input() inheritedFromPreferenceValueId: number;
  @Input() SecurityPrefResult: ISecurityPreferencesGet;
  @Input() clonedSecurityPrefResult: ISecurityPreferencesGet;
  @Input() displayPermission: boolean;
  @Input() IsEnablePrefSecurity: boolean;
  @Input() preferenceLevel: string;
  preferenceVM: IPreferenceDataVM;
  @ViewChild(SecurityPreferenceAddEditComponent) securityPreferenceAddEditComponent: SecurityPreferenceAddEditComponent;
  @ViewChild(SecurityPreferenceEquivalentComponent) securityPreferenceEquivalentComponent: SecurityPreferenceEquivalentComponent;

  levelName: string;
  levelId: number;
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  savedView: ISavedView = <ISavedView>{};
  security: ISecurity = <ISecurity>{};
  preferenceRowData: ISecurityPreference[] = [];
  preData: ISecurityPreference[] = [];
  btnDisableSetPref: boolean = true;
  selectedLevelIds: number[];

  btnDisableSecurity = true;
  gridContext = {
    isGridModified: false,
    isNone: false,
    self: this,
    isFilterChanged: false
  };
  setFilter: boolean;
  displayConfirm: boolean;
  displayWarningMessage: boolean;
  deletedSecurityPreferenceId: number;
  showPageContent: boolean;
  securityPreferencesHiddenMap: IHiddenSecurityPreference = {};
  apiSecurityPreference: ISecurityPreferencesGet;
  filterOutSecurityIds: number[];
  displayEquivalentDialog: boolean;
  private getPrioritiesSubscription: Subscription;
  private addOrUpdateSecurityPreferenceSubscription: Subscription;
  private addSecurityPreferenceForEquivalentSubscription: Subscription;
  private refreshGridSubscription: Subscription;

  private get viewType(): ViewTypeEnum {
    if (this.preferenceLevel === PREFERENCE_LEVEL.PORTFOLIO) {
      return ViewTypeEnum.PortfolioSecurityPreference;
    } else if (this.preferenceLevel === PREFERENCE_LEVEL.ACCOUNT) {
      return ViewTypeEnum.AccountSecurityPreference;
    }
    return null;
  }

  constructor(private _securityPreferenceHubService: SecurityPreferenceHubService,
              public readonly _autocompleteHubService: AutocompleteHubService,
              private _preferenceService: PreferenceService,
              private preferencesbo: PreferencesBusinessObjects,
              private _astroService: AstroService,
              private _alertService: AlertService) {
    super();
    this.displayConfirm = false;
    this.filterOutSecurityIds = [];
  }

  ngOnInit(): void {
    this.initializeGridObjects();
    this._securityPreferenceHubService.getPriorities();
    this._securityPreferenceHubService.loadCustodiansData();
    this.getPriorities();
    this.addOrUpdateSecurityPreferenceSubscribe();
    this.addSecurityPreferenceForEquivalent();
    this.refreshGridSubscribe();
    Util.windowSize();
  }

  ngOnDestroy(): void {
    this.getPrioritiesSubscription.unsubscribe();
    this.addOrUpdateSecurityPreferenceSubscription.unsubscribe();
    this.addSecurityPreferenceForEquivalentSubscription.unsubscribe();
    this.refreshGridSubscription.unsubscribe();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.savedView.parentGridApi = params.api;
  }

  /** Load Grid with security results data */
  loadSecurityResults(securityResults, clearGrid = false): void {
    if (!clearGrid) {
      this.loadSecurities(securityResults);
    } else {
      this.preferenceRowData = [];
    }
  }

  // Find index of expanded row /
  findExpandedRowIndex(array, key, val): number {
    for (let i = 0; i < array.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (array[i][key] == val) {
        return i;
      }
    }
    return null;
  }

  /*** method to display context menu on accounts agGrid*/
  getContextMenuItems(params): any[] {
    const contextResult = [];
    const self = params.context.self;
    if (params.node) {
      contextResult.push({
        name: 'Edit Preferences',
        action: function () {
          self.securityPref = Object.assign({}, self.preferenceRowData.find(x => x.id === +params.node.data.id));
          self.securityPreferenceAddEditComponent.editSecurityPreference(self.securityPref);
        }.bind(params.context.self)
      });
      contextResult.push({
        name: 'Delete Preferences',
        action: function () {
          const isRestricted = self.preferenceRowData.find(x => x.id === +params.node.data.id)?.isRestricted;
          if (!isRestricted) {
            if (!self.SecurityPrefResult.deletedIds) {
              self.SecurityPrefResult.deletedIds = [];
            }
            self.checkEquivalentAndDeleteSecurityPreference(params.node.data.id);
          } else {
            const warnings = [{typeId: AlertMessageType.Warning, message: ESGThemeMessages.cannotDeleteESGSecurity}];
            self._alertService.alert.emit(warnings);
          }
        }.bind(params.context.self)
      });
    }
    return contextResult;
  }

  deleteSecurityPreferenceAndEquivalents(): void {
    this.deleteSecurityPreference(this.deletedSecurityPreferenceId);
    this.preferenceRowData.forEach(securityPreference => {
      securityPreference.isEdit = securityPreference.equivalents.some(e => e.id === this.deletedSecurityPreferenceId);
      securityPreference.equivalents = securityPreference.equivalents.filter(e => e.id !== this.deletedSecurityPreferenceId);
    });
    this.updateEquivalentInSecurityPreferenceIds();
    this._securityPreferenceHubService.securityPreferences.next(this.preferenceRowData);
    this._securityPreferenceHubService.deleteSecurityPreference.next(this.deletedSecurityPreferenceId);
    SecurityPreferenceHelper.securitySellBuyPriority.delete(this.deletedSecurityPreferenceId);
    this.displayWarningMessage = false;
    this.deletedSecurityPreferenceId = null;
  }

  deleteSecurityPreference(id: number): void {
    this.SecurityPrefResult.deletedIds.push(id);
    this.SecurityPrefResult.securityPreferences = this.SecurityPrefResult.securityPreferences.filter(pref => pref.id !== id);
    this.filterOutSecurityIds = this.filterOutSecurityIds.filter(secId => secId !== id);
    const rowData = this.gridApi.getGridOption('rowData');
    rowData.splice(rowData.findIndex(x => x.id === id), 1);
    this.updateEquivalentInSecurityPreferenceIds();
    this.gridApi.setGridOption('rowData', rowData);
  }

  // To add data to grid
  addToGrid(securityPreference: ISecurityPreference): void {
    this._securityPreferenceHubService.addUpdateSecurityPreference.next(securityPreference);
  }

  addOrUpdateSecurityPreference(securityPreference: ISecurityPreference): void {
    if (securityPreference.id && this.SecurityPrefResult) {
      let isInPreData = true;
      let match = this.preData.filter(x => x.id === securityPreference.id);
      if (match && match.length === 0) {
        match = this.SecurityPrefResult.securityPreferences.filter(x => x.id === securityPreference.id);
        isInPreData = false;
      }
      if (this.SecurityPrefResult && this.SecurityPrefResult.deletedIds && this.SecurityPrefResult.deletedIds.length > 0) {
        this.SecurityPrefResult.deletedIds = this.SecurityPrefResult.deletedIds.filter(id => id !== securityPreference.id);
      }
      securityPreference.isAdd = true;
      if (match.length > 0) {
        match[0] = securityPreference;
        if (isInPreData) {
          securityPreference.isAdd = false;
          securityPreference.isEdit = true; // SECURITY PREFERENCES BUCKETING ARRAYS
        }
        this.updateSecurityPreference(match[0]);
      } else {
        this.SecurityPrefResult.securityPreferences.push(securityPreference);
        this.filterOutSecurityIds.push(securityPreference.id);
      }
      this.preferenceRowData = this.SecurityPrefResult.securityPreferences.filter(security => !security.isRestricted);
      this.updateEquivalentInSecurityPreferenceIds();
      SecurityPreferenceHelper.updateSecuritySellBuyPriority(securityPreference);
      this.gridApi?.setGridOption('rowData', this.preferenceRowData);
      this.setFilter = true;
    }
  }

  updateSecurityPreference(securityPreference: ISecurityPreference): void {
    const indexVal = this.findExpandedRowIndex(this.SecurityPrefResult.securityPreferences, 'id', securityPreference.id);
    if (indexVal === 0 || indexVal) {
      this.SecurityPrefResult.securityPreferences[indexVal] = securityPreference;
    } else {
      this.SecurityPrefResult.securityPreferences.push(securityPreference);
      this.filterOutSecurityIds.push(securityPreference.id);
    }
  }

  loadSecurityData(preferenceVM: IPreferenceDataVM): void {
    this.preferenceVM = preferenceVM;
    this.levelName = preferenceVM.levelPreferences.level;
    this.levelId = preferenceVM.levelPreferences.id;
    const pref = preferenceVM.levelPreferences.preferences.find(a => a.componentName === 'SecurityDataGrid');
    this.SecurityPrefResult = <ISecurityPreferencesGet>{};
    this.preferenceRowData = [];
    forkJoin({
      securityPreferencesGet: this._preferenceService.getSecurityPreferences(this.levelName, +this.levelId, 'securityPreference',
        pref.preferenceId, pref.id, pref.inheritedFromValueId),
      securitiesRestrictions: this.levelName === 'Account' && this.levelId !== null ? this._astroService.getAstroSecuritiesRestrictions(+this.levelId) : of(null)
    })
      .subscribe(data => {
          this.SecurityPrefResult = data.securityPreferencesGet;
          this.SecurityPrefResult.id = (this.SecurityPrefResult.id === 0) ? null : this.SecurityPrefResult.id;
          this.SecurityPrefResult.isSetFromParent = !data.securityPreferencesGet.securityPreferences.length;
          this.SecurityPrefResult.resetToParent = false;
          this._astroService.mergeSecuritiesRestrictions(this.SecurityPrefResult.securityPreferences, data.securitiesRestrictions);
          this.loadSecurityResults(this.SecurityPrefResult);
          this.apiSecurityPreference = Util.deepClone(this.SecurityPrefResult);
          this.setClonedInheritedSecurityComponent();
          /** For Inherited Security popup purpose :: loading the inherited */
          this.updateEquivalentInSecurityPreferenceIds();
          SecurityPreferenceHelper.createSecuritySellBuyPriorityMap(this.SecurityPrefResult.securityPreferences);
          this._securityPreferenceHubService.securityPreferences.next(this.SecurityPrefResult.securityPreferences);
          this.setFilter = true;
        }
      );
  }

  setClonedInheritedSecurityComponent(): void {
    this.clonedSecurityPrefResult = Util.deepClone(this.SecurityPrefResult);
    this.clonedSecurityPrefResult.securityPreferences = this.SecurityPrefResult.inheritedSecurityPreferences
      ? Util.deepClone(this.SecurityPrefResult.inheritedSecurityPreferences) : null;
  }

  saveSecurities(): Observable<any>[] {
    const securityPrefBucket = this.preferencesbo.fetchEditedsecurityPreferrencePreferenceNew(this.SecurityPrefResult);
    return this.preferencesbo.saveSecurityPreferences(this.levelName, this.levelId, this.selectedLevelIds, securityPrefBucket, this.apiSecurityPreference);
  }

  setResetControl(): void {
    this.SecurityPrefResult.resetToParent = true;
    this.SecurityPrefResult.securityPreferences = this.SecurityPrefResult.inheritedSecurityPreferences ?
      Util.deepClone(this.SecurityPrefResult.inheritedSecurityPreferences) : null;
    this.bindSecurityData();
  }

  setPrefPopup(): void {
    this.securityPreferenceAddEditComponent.addSecurityPreference(this.security);
    this.btnDisableSetPref = true;
    this._autocompleteHubService.requestToClearSearchData();
  }

  resetData(): void {
    this.preferenceRowData = [];
  }

  onModelUpdated(): void {
    if (this.setFilter && !!this.viewType) {
      this.setFilter = false;
      SecurityPreferenceHelper.onGridModelUpdates(this.gridApi, this.gridContext);
    }
  }

  addEquivalent(): void {
    this.displayEquivalentDialog = false;
    this.securityPreferenceEquivalentComponent.addEquivalent();
  }

  cancel(): void {
    this.displayEquivalentDialog = false;
    this.securityPreferenceEquivalentComponent.cancel();
  }

  private checkEquivalentAndDeleteSecurityPreference(id: number): void {
    if (SecurityPreferenceHelper.equivalentIdsInSecurityPreference.has(id)) {
      this.displayWarningMessage = true;
      this.deletedSecurityPreferenceId = id;
    } else {
      this.deleteSecurityPreference(id);
      this._securityPreferenceHubService.deleteSecurityPreference.next(id);
    }
  }

  private updateEquivalentInSecurityPreferenceIds(): void {
    // get equivalents from the security preferences (filter out any missing/empty equivalent arrays to prevent undefined error)
    const equivalents = this.preferenceRowData.filter(s => !!s.equivalents && s.equivalents.length).map(s => s.equivalents);
    const flattened = _.flattenDeep(equivalents);
    const equivalentSecurityIds = flattened.map(s => s.id);
    SecurityPreferenceHelper.equivalentIdsInSecurityPreference = new Set<number>(equivalentSecurityIds);
  }

  private refreshSecurityPreferenceList(): void {
    if (this.preferenceRowData.length) {
      this.gridApi.setGridOption('rowData', this.preferenceRowData);
    }
    this.setFilter = true;
  }

  private initializeGridObjects(): void {
    this.gridOptions = this.viewType ? BaseGridConfiguration.generateExtendedFilterGridOptions(this.defaultFilterableGridOptions, this, this.viewType,
      this.refreshSecurityPreferenceList.bind(this), null, this.savedView) : this.defaultFilterableGridOptions;
    (<SideBarDef>this.gridOptions.sideBar).defaultToolPanel = '';
    this.securityPreferencesHiddenMap = SecurityPreferenceHelper.getHideSecurityPreferenceMap(this.preferenceLevel);
    this.createColumnDefs();
    this.savedView = <ISavedView>{
      parentColumnDefs: this.columnDefs,
      parentGridOptions: this.gridOptions,
      exitWarning: <IExitWarning>{}
    };
    this.showPageContent = true;
  }

  private addOrUpdateSecurityPreferenceSubscribe(): void {
    this.addOrUpdateSecurityPreferenceSubscription = this._securityPreferenceHubService.addUpdateSecurityPreference
      .subscribe((securityPreference: ISecurityPreference) => {
        this.addOrUpdateSecurityPreference(securityPreference);
      });
  }

  private refreshGridSubscribe(): void {
    this.refreshGridSubscription = this._securityPreferenceHubService.refreshGrid.subscribe(() => {
      this.gridApi?.refreshCells();
    });
  }

  /** Core method to load security preferences object */
  private loadSecurities(securityResults): void {
    if (this.SecurityPrefResult !== undefined) {
      this.SecurityPrefResult = securityResults;

      this.preferenceRowData = [];
      this.filterOutSecurityIds = [];
      this.SecurityPrefResult.securityPreferences.forEach(securityPref => {
        if (!securityPref.isRestricted) {
          securityPref.taxableAlternate = Util.getFieldValue(securityPref.alternatives[0], 'tSecurity');
          securityPref.taxDeferredAlternate = Util.getFieldValue(securityPref.alternatives[0], 'tdSecurity');
          securityPref.taxExemptAlternate = Util.getFieldValue(securityPref.alternatives[0], 'teSecurity');
          this.preferenceRowData.push(securityPref);
        }
        this.filterOutSecurityIds.push(securityPref.id);
      });
      this.gridApi?.setGridOption('columnDefs', this.columnDefs);
      this.gridApi?.setGridOption('rowData', this.preferenceRowData);
      this.preData = Util.deepClone(this.SecurityPrefResult.securityPreferences);
      this.getPriorities();
    }
  }

  /** Get priorities */
  private getPriorities(): void {
    this.getPrioritiesSubscription = this._securityPreferenceHubService.sellAndBuyPriority.subscribe((sellAndBuyPriority: ISellBuyPriorityMap) => {
      SecurityPreferenceHelper.sellPriorityList = sellAndBuyPriority.sellPriorities;
      SecurityPreferenceHelper.buyPriorityList = sellAndBuyPriority.buyPriorities;
    });
  }

  // binding InheritedSecurity Preference to gridOptions
  private bindSecurityData(): void {
    this.preferenceRowData = this.SecurityPrefResult.securityPreferences;
    this.createColumnDefs();
    this.securityPreferenceAddEditComponent.resetSecurityPreferenceData();
  }

  searchSecurity(security: ISecurity): void {
    this.security = security?.id ? security : null;
    this.btnDisableSetPref = !security?.id;
  }

  private accountPortfolioStandardView = [
    {groupId: 'securityDetailsGroup', children: ['symbol', 'securityType', 'inModel']},
    {groupId: 'tradingGroup', children: ['excludeHolding']},
    {groupId: 'tradeSettingsGroup', children: ['sellPriority', 'buyPriority']},
    {groupId: 'tradeMinGroup', children: ['sellTradeMinAmtBySecurity', 'sellTradeMinPctBySecurity', 'buyTradeMinAmtBySecurity', 'buyTradeMinPctBySecurity']},
    {groupId: 'tradeMaxGroup', children: ['sellTradeMaxAmtBySecurity', 'sellTradeMaxPctBySecurity', 'buyTradeMaxAmtBySecurity', 'buyTradeMaxPctBySecurity']},
    {groupId: 'optionTradingGroup', children: ['coveredCallPercent', 'protectivePutPercent']},
    {groupId: 'astroRestrictionsGroup', children: ['astroRestrictionType', 'astroRestrictionMin', 'astroRestrictionMax', 'astroRestrictionTypeMarsValue', 'astroRestrictionMinMarsValue', 'astroRestrictionMaxMarsValue']},
    {groupId: 'securityDetailsGroup', children: ['equivalent', 'doNotTLH']},
  ];

  private standardView = [
    {groupId: 'securityDetailsGroup', children: ['id', 'securityName', 'securityType', 'symbol', 'inModel', 'equivalent', 'doNotTLH']},
    {groupId: 'redemptionFeeGroup', children: ['redemptionFeeTypeId', 'redemptionFeeAmount', 'redemptionFeeDays']},
    {groupId: 'tradeMinGroup', children: ['sellTradeMinAmtBySecurity', 'sellTradeMinPctBySecurity', 'buyTradeMinAmtBySecurity', 'buyTradeMinPctBySecurity']},
    {groupId: 'tradeMaxGroup', children: ['sellTradeMaxAmtBySecurity', 'sellTradeMaxPctBySecurity', 'buyTradeMaxAmtBySecurity', 'buyTradeMaxPctBySecurity']},
    {groupId: 'custodianSpecificRedemptionFeeGroup', children: ['custodianRedemptionFeeTypeId', 'custodianRedemptionFeeAmount', 'custodianRedemptionDays', 'custodianRedemptionFeeMaxAmount', 'custodianRedemptionFeeMinAmount']},
    {groupId: 'transactionFeeGroup', children: ['sellTransactionFee', 'buyTransactionFee']},
    {groupId: 'minimumInitialBuyGroup', children: ['minInitialBuyAmount']},
    {groupId: 'taxAlternatesGroup', children: ['taxableAlternateSymbol', 'taxDeferredAlternateSymbol', 'taxExemptAlternateSymbol']},
    {groupId: 'tradeSettingsGroup', children: ['sellPriority', 'buyPriority']},
    {groupId: 'tradeSettingsByAccountTypeGroup', children: ['tSellPriority', 'tBuyPriority', 'teSellPriority', 'teBuyPriority', 'tdSellPriority', 'tdBuyPriority']},
    {groupId: 'optionTradingGroup', children: ['coveredCallPercent', 'protectivePutPercent']},
    {groupId: 'astroRestrictionsGroup', children: ['astroRestrictionType', 'astroRestrictionMin', 'astroRestrictionMax']},
    {groupId: 'tradingGroup', children: ['excludeHolding']},
  ];

  /**  create column headers for agGrid */
  private createColumnDefs(): void {
    const colDefs = SecurityPreferenceHelper.getSecurityPreferenceColumn();
    if (['account', 'portfolio'].includes(this.preferenceLevel?.toLowerCase())) {
      this.columnDefs = SecurityPreferenceHelper.applyStandardView(colDefs, this.accountPortfolioStandardView);
    } else {
      this.columnDefs = SecurityPreferenceHelper.applyStandardView(colDefs, this.standardView);
    }
  }

  @HostListener('click', ['$event.target'])
  private onClick(targetElement: HTMLElement): void {
    if (targetElement.title === 'Add Equivalent') {
      if (!targetElement.id) {
        return;
      }
      const id = Number(targetElement.id);
      const securityPreference = this.preferenceRowData.find(p => p.id === id);
      if (securityPreference) {
        this.displayEquivalentDialog = true;
        this.securityPreferenceEquivalentComponent.showSecurityPreferenceEquivalent(securityPreference);
      }
    }
  }

  private addSecurityPreferenceForEquivalent(): void {
    this.addSecurityPreferenceForEquivalentSubscription = this._securityPreferenceHubService.addSecurityPreferenceForEquivalent
      .subscribe((securities: IEquivalent[]) => {
        if (securities.length) {
          for (const security of securities) {
            let securityPreference = this.preferenceRowData.find(s => s.id === security.id);
            if (!securityPreference) {
              securityPreference = SecurityPreferenceHelper.bindSecurityEmptyData(security);
            }
            const sellPriority = Number(security?.sellPriority?.id);
            const buyPriority = Number(security?.buyPriority?.id);
            securityPreference.sellPriority = isNaN(sellPriority) ? null : sellPriority;
            securityPreference.buyPriority = isNaN(buyPriority) ? null : buyPriority;
            this.addToGrid(securityPreference);
          }
        }
      });
  }

  exportToExcelDataSheet(sheetName?: string): string {
    return this.gridApi?.getSheetDataForExcel({ sheetName: sheetName ?? 'Security Preferences' });
  }
}
