import { AfterViewInit, Component, Input } from '@angular/core';
import { GridOptions, ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { ModelService } from '../../services/model.service';
import { PortfolioService } from '../../services/portfolio.service';
import { BaseComponent } from '../../core/base.component';
import {
  IModelPortfolioAllocation,
  IPortfolioTotalTargetAllocationVM
} from '../../models/modeling/modelportfolioallocations';
import {
  ISleevedAccountAllocation,
  ISleevedAccountTotalTargetAllocationVM
} from '../../models/modeling/sleevedaccountallocations';
import { ConfigService } from '../../config/config.service';
import * as Consts from '../../libs/app.constants';
import { Utils as Util } from '../../core/functions';
import * as d3 from 'd3';
import { IPortfolioAnalytics } from '../../models/portfolio';
import { IAnalyticsNotification } from '../../models/notification';

@Component({
  selector: 'eclipse-analyse-portfolio',
  templateUrl: './analysePortfolio.component.html',
  providers: [ModelService, PortfolioService]
})
export class AnalysePortfolioComponent extends BaseComponent implements AfterViewInit {
  analyseSleeve: boolean = false;
  modelPortfolioAllocations: any = [];
  structMPAllocation: IPortfolioTotalTargetAllocationVM;
  structSlvAllocation: ISleevedAccountTotalTargetAllocationVM;
  modelSecurityAllocationGridOptions: GridOptions;
  modelSecurityAllocationColumnDefs: ColDef[];
  modelSubClassAllocationGridOptions: GridOptions;
  modelSubClassAllocationColumnDefs: ColDef[];
  modelClassAllocationGridOptions: GridOptions;
  modelClassAllocationColumnDefs: ColDef[];
  modelCategoryAllocationGridOptions: GridOptions;
  modelCategoryAllocationColumnDefs: ColDef[];
  modelSecuritySleeveAllocationGridOptions: GridOptions;
  modelSecuritySleeveAllocationColumnDefs: ColDef[];
  modelSecuritySetAllocationGridOptions: GridOptions;
  modelSecuritySetAllocationColumnDefs: ColDef[];
  modelSecurityPortfolioAllocations: any[] = [];
  modelCategoryPortfolioAllocations = [];
  modelSecuritySetPortfolioAllocations = [];
  modelSubClassPortfolioAllocations = [];
  modelClassPortfolioAllocations = [];
  portfolioSleeveComparison: string = '';
  smaPortfolioAllocation: any = [];
  sleeveAllocations = [];
  @Input() portfolioId: number;
  @Input() sleeveId: number;
  @Input() portfolioName: string = '';
  @Input() sleeveName: string = '';
  @Input() modelId: number;
  @Input() modelName: string;
  @Input() sleeveAccountId: string;
  @Input() reserveCash: number;
  @Input() setAsideCash: number;
  decimalsForDollar: number = 2;
  decimalsForPercent: number = 4;
  totalAum: string = '';
  selectedTab: string = 'Security';
  hasCategoryLevel: boolean = false;
  hasClassLevel: boolean = false;
  hasSubClassLevel: boolean = false;
  hasSecuritySetLevel: boolean = false;
  portfolioAnalytics: IPortfolioAnalytics[];
  @Input() setasideCashPercent: number;
  @Input() reserveCashPercent: number;
  /** Set grid context params  */
  gridContext = {
    self: this
  };
  private gridApiSleeve: GridApi;
  private gridApiSecurity: GridApi;
  private gridApiSecuritySet: GridApi;
  private gridApiSubClass: GridApi;
  private gridApiClass: GridApi;
  private gridApiCategory: GridApi;

  constructor(private _modelService: ModelService, private _configService: ConfigService,
              private _portfolioService: PortfolioService) {
    super(Consts.PRIV_MODELS);
    /** Getting configurable values for dollar decimals and percentage decimals*/
    // eslint-disable-next-line eqeqeq
    this.decimalsForDollar = ConfigService.settings.decimalsForDollar != undefined ? ConfigService.settings.decimalsForDollar : this.decimalsForDollar;
    // eslint-disable-next-line eqeqeq
    this.decimalsForPercent = ConfigService.settings.decimalsForPercent != undefined ? ConfigService.settings.decimalsForPercent : this.decimalsForPercent;

    this.portfolioAnalytics = [];
    this.modelSecurityAllocationGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
    };
    this.modelSubClassAllocationGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
    };
    this.modelClassAllocationGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
    };
    this.modelCategoryAllocationGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
    };
    this.modelSecuritySleeveAllocationGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
    };
    this.modelSecuritySetAllocationGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
    };
    this.initializeAllocations();
    this.modelSecurityAllocationColDefs();
    this.modelSubClassAllocationColDefs();
    this.modelClassesAllocationColumnDefs();
    this.modelCategoriesAllocationColumnDefs();
    this.modelSecuritySetsAllocationColumnDefs();
    this.modelSleeveSecurityAllocationColDefs();
    this.initialisingFloatingBottomRowData();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  onSleeveGridReady(event: GridReadyEvent) {
    this.gridApiSleeve = event.api;
  }

  onSecurityGridReady(event: GridReadyEvent) {
    this.gridApiSecurity = event.api;
  }

  onSecuritySetGridReady(event: GridReadyEvent) {
    this.gridApiSecuritySet = event.api;
  }

  onSubClassGridReady(event: GridReadyEvent) {
    this.gridApiSubClass = event.api;
  }

  onClassGridReady(event: GridReadyEvent) {
    this.gridApiClass = event.api;
  }

  onCategoryGridReady(event: GridReadyEvent) {
    this.gridApiCategory = event.api;
  }

  loadData() {
    // eslint-disable-next-line eqeqeq
    if (this.portfolioId != undefined) {
      this.displayPortfolioModelAllocation();
    }
    if (this.sleeveId) {
      this.displaySleeveModelAllocation();
    }
  }

  resizeGridOnTabChange(tabName) {
    this.selectedTab = tabName;
    switch (tabName) {
      case 'Security' :
        this.gridResize(this.gridApiSecurity);
        break;
      case 'SecuritySet' :
        this.gridResize(this.gridApiSecuritySet);
        break;
      case 'SubClass' :
        this.gridResize(this.gridApiSubClass);
        break;
      case 'Class' :
        this.gridResize(this.gridApiClass);
        break;
      case 'Category' :
        this.gridResize(this.gridApiCategory);
        break;
    }
  }

  private gridResize(gridApi: GridApi) {
    setTimeout(function () {
      gridApi.sizeColumnsToFit();
    }, 100);
  }

  /*Method used to define the column definitions when securities tab is selected in portfolio model comparison */
  modelSecurityAllocationColDefs() {
    this.modelSecurityAllocationColumnDefs = [
      <ColDef>{
        headerName: 'Securities',
        field: 'securityName',
        width: 275,
        cellClass: 'text-center',
        enableRowGroup: true,
        enablePivot: false,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target ($)',
        field: 'targetInAmt',
        width: 150,
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        cellClass: 'text-center',
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current ($)',
        field: 'currentInAmt',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target (%)',
        field: 'targetInPercent',
        width: 150,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Current (%)',
        field: 'currentInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Pending ($)',
        field: 'pendingInAmt',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending (%)',
        field: 'pendingInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
    ];
    // To suppress floating filter button from all columns in the grid
    this.modelSecurityAllocationColumnDefs.forEach(e => {
      e.suppressFloatingFilterButton = true;
    });
  }

  /*Method used to define the column definitions when sub class tab is selected in portfolio model comparison */
  modelSubClassAllocationColDefs() {
    this.modelSubClassAllocationColumnDefs = [
      <ColDef>{
        headerName: 'Sub Class',
        field: 'subclassName',
        width: 275,
        cellClass: 'text-center',
        enableRowGroup: true,
        enablePivot: false,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target ($)',
        field: 'targetInAmt',
        width: 150,
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        cellClass: 'text-center',
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current ($)',
        field: 'currentInAmt',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target (%)',
        field: 'targetInPercent',
        width: 150,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Current (%)',
        field: 'currentInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Pending ($)',
        field: 'pendingInAmt',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending (%)',
        field: 'pendingInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
    ];
    // To suppress floating filter button from all columns in the grid
    this.modelSubClassAllocationColumnDefs.forEach(e => {
      e.suppressFloatingFilterButton = true;
    });
  }

  /*Method used to define the column definitions when class tab is selected in portfolio model comparison */
  modelClassesAllocationColumnDefs() {
    this.modelClassAllocationColumnDefs = [
      <ColDef>{
        headerName: 'Class',
        field: 'className',
        width: 275,
        cellClass: 'text-center',
        enableRowGroup: true,
        enablePivot: false,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target ($)',
        field: 'targetInAmt',
        width: 150,
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        cellClass: 'text-center',
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current ($)',
        field: 'currentInAmt',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target (%)',
        field: 'targetInPercent',
        width: 150,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Current (%)',
        field: 'currentInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Pending ($)',
        field: 'pendingInAmt',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending (%)',
        field: 'pendingInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
    ];
    // To suppress floating filter button from all columns in the grid
    this.modelClassAllocationColumnDefs.forEach(e => {
      e.suppressFloatingFilterButton = true;;
    });
  }

  /*Method used to define the column definitions when category tab is selected in portfolio model comparison */
  modelCategoriesAllocationColumnDefs() {
    this.modelCategoryAllocationColumnDefs = [
      <ColDef>{
        headerName: 'Category',
        field: 'categoryName',
        width: 275,
        cellClass: 'text-center',
        enableRowGroup: true,
        enablePivot: false,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target ($)',
        field: 'targetInAmt',
        width: 150,
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        cellClass: 'text-center',
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current ($)',
        field: 'currentInAmt',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target (%)',
        field: 'targetInPercent',
        width: 150,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Current (%)',
        field: 'currentInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Pending ($)',
        field: 'pendingInAmt',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending (%)',
        field: 'pendingInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
    ];

    // To suppress floating filter button from all columns in the grid
    this.modelCategoryAllocationColumnDefs.forEach(e => {
      e.suppressFloatingFilterButton = true;
    });
  }

  /*Method used to define the column definitions when category tab is selected in portfolio model comparison */
  modelSecuritySetsAllocationColumnDefs() {
    this.modelSecuritySetAllocationColumnDefs = [
      <ColDef>{
        headerName: 'SecuritySets',
        field: 'securitySetName',
        width: 275,
        cellClass: 'text-center',
        enableRowGroup: true,
        enablePivot: false,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target ($)',
        field: 'targetInAmt',
        width: 150,
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        cellClass: 'text-center',
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current ($)',
        field: 'currentInAmt',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target (%)',
        field: 'targetInPercent',
        width: 150,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Current (%)',
        field: 'currentInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Pending ($)',
        field: 'pendingInAmt',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending (%)',
        field: 'pendingInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
    ];
    // To suppress floating filter button from all columns in the grid
    this.modelSecuritySetAllocationColumnDefs.forEach(e => {
      e.suppressFloatingFilterButton = true;
    });
  }

  /*Method used to define the column definitions when security set tab is selected in sleeve model comparison */
  modelSleeveSecurityAllocationColDefs() {
    this.modelSecuritySleeveAllocationColumnDefs = [
      <ColDef>{
        headerName: 'Securities',
        field: 'securityName',
        width: 275,
        cellClass: 'text-center',
        enableRowGroup: true,
        enablePivot: false,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target ($)',
        field: 'targetInAmt',
        width: 150,
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        cellClass: 'text-center',
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current ($)',
        field: 'currentInAmt',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target (%)',
        field: 'targetInPercent',
        width: 150,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Current (%)',
        field: 'currentInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Pending ($)',
        field: 'pendingInAmt',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatCurrencyCellRenderer,
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending (%)',
        field: 'pendingInPercent',
        width: 110,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        enableRowGroup: true,
        enablePivot: false,
        cellRenderer: this.formatPercentCellRenderer
      },
    ];
    // To suppress floating filter button from all columns in the grid
    this.modelSecuritySleeveAllocationColumnDefs.forEach(e => {
      e.suppressFloatingFilterButton = true;
    });
  }

  /** To round decimals up to 4 digits  */
  roundDecimals(val, precision) {
    const multiplier = Math.pow(10, precision || 0);
    const roundedVal = Math.round(val * multiplier) / multiplier;
    /** To round decimals up to 4 digits i.e 3.47627234 --> 3.4763 */
    return roundedVal.toFixed(this.decimalsForPercent);
    /** To fix decimals up to 4 digits i.e 3.47 --> 3.4700 */
  }

  /*Method used to define custom properties for portfolio and sleeve as the existed api data will not fit the requirement
  so handling the data by adding extra properties to match the requirement */
  addingCustomAttributesInModelPortfolioAllocation(data, type) {
    data.forEach(element => {
      element[type] = this.getSecurityDisplayName(type, element);
    });
    // eslint-disable-next-line eqeqeq
    if (data.length != 0) {
      const totalAllocation = {};
      totalAllocation[type] = 'Total';
      if (!this.analyseSleeve) {
        totalAllocation['currentInAmt'] = this.structMPAllocation.currentInAmt;
        totalAllocation['targetInAmt'] = this.structMPAllocation.targetInAmt;
        totalAllocation['pendingInAmt'] = this.structMPAllocation.pendingInAmt;
        totalAllocation['targetInPercent'] = this.structMPAllocation.targetInPercent;
        totalAllocation['currentInPercent'] = this.structMPAllocation.currentInPercent;
        totalAllocation['pendingInPercent'] = this.structMPAllocation.pendingInPercent;
      } else {
        totalAllocation['currentInAmt'] = this.structSlvAllocation.currentInAmt;
        totalAllocation['targetInAmt'] = this.structSlvAllocation.targetInAmt;
        totalAllocation['pendingInAmt'] = this.structSlvAllocation.pendingInAmt;
        totalAllocation['targetInPercent'] = this.structSlvAllocation.targetInPercent;
        totalAllocation['currentInPercent'] = this.structSlvAllocation.currentInPercent;
        totalAllocation['pendingInPercent'] = this.structSlvAllocation.pendingInPercent;
      }
      this.setFloatingBottomRowData(type, totalAllocation);
    }

  }

  getSecurityDisplayName(type: string, element: any) {
    let name: string;
    if (type === 'securityName') {
      if (element.symbol) {
        name = `${element.name} (${element.symbol})`;
      } else {
        name = element.name;
      }
      if (element.isPortfolioSecurity) {
        name = `${name}*`;
      }
    } else {
      name = element[type];
    }
    return name;
  }

  resetFloatingBottomRowData() {
    const totalAllocation = {};
    totalAllocation['currentInAmt'] = 0;
    totalAllocation['targetInAmt'] = 0;
    totalAllocation['pendingInAmt'] = 0;
    totalAllocation['targetInPercent'] = 0;
    totalAllocation['currentInPercent'] = 0;
    totalAllocation['pendingInPercent'] = 0;
    this.setFloatingBottomRowData('securityName', totalAllocation);
    this.setFloatingBottomRowData('subclassName', totalAllocation);
    this.setFloatingBottomRowData('className', totalAllocation);
    this.setFloatingBottomRowData('categoryName', totalAllocation);
    this.setFloatingBottomRowData('securitySetName', totalAllocation);
  }

  setFloatingBottomRowData(type, totalAllocation) {
    // eslint-disable-next-line eqeqeq
    if (type == 'securityName' && !this.analyseSleeve) {
      this.modelSecurityAllocationGridOptions.pinnedBottomRowData = [totalAllocation];
    }
    // eslint-disable-next-line eqeqeq
    if (type == 'securityName' && this.analyseSleeve) {
      this.modelSecuritySleeveAllocationGridOptions.pinnedBottomRowData = [totalAllocation];
    }
    // eslint-disable-next-line eqeqeq
    if (type == 'subclassName') {
      this.modelSubClassAllocationGridOptions.pinnedBottomRowData = [totalAllocation];
    }
    // eslint-disable-next-line eqeqeq
    if (type == 'className') {
      this.modelClassAllocationGridOptions.pinnedBottomRowData = [totalAllocation];
    }
    // eslint-disable-next-line eqeqeq
    if (type == 'categoryName') {
      this.modelCategoryAllocationGridOptions.pinnedBottomRowData = [totalAllocation];
      // this.modelCategoryAllocationGridOptions.floatingBottomRowData[0].currentInAmt = totalAllocation['curre];
    }
    // eslint-disable-next-line eqeqeq
    if (type == 'securitySetName') {
      this.modelSecuritySetAllocationGridOptions.pinnedBottomRowData = [totalAllocation];
    }
  }

  removeMacSecurity(data) {
    data.forEach((element, key) => {
      // eslint-disable-next-line eqeqeq
      if (element.categoryName == 'MAC Fund') {
        data.splice(key, 1);
      }
    });
    return data;
  }

  /*Method fired when we click on the Analyse portfolio button from view page and here we are making the
  default selected tab will be securities tab */

  displayPortfolioModelAllocation() {
    this.resetData();
    this.initializeAllocations();
    this.portfolioSleeveComparison = 'Portfolio-Model Comparison';

    this.analyseSleeve = false;

    if (this.portfolioId) {
      this.portfolioAnalytics = [];
      this._modelService.getModelPortfolioAllocations(this.portfolioId)
        .subscribe((model: any) => {
        this.totalAum = model.totalAUM;
        const portfolioAnalytic = {} as IPortfolioAnalytics;
        portfolioAnalytic.failedReason = model.failedReason;
        portfolioAnalytic.needAnalytics = model.needAnalytics;
        portfolioAnalytic.portfolioId = model.portfolioId;
        portfolioAnalytic.editedDate = model.analyticsEditedDate;
        this.portfolioAnalytics.push(portfolioAnalytic);
        this.setAsideCash = model.setAsideCash;
        this.reserveCash = model.reserveCash;
        this.setasideCashPercent = model.setAsideCashPercent;
        this.reserveCashPercent = model.reserveCashPercent;
        this.modelPortfolioAllocations = Util.deepClone(model.securities);
        // eslint-disable-next-line eqeqeq
        if (model.securities != undefined) {
          this.groupingModelAllocations(model, 'portfolio');

          this.modelCategoryPortfolioAllocations.sort(function (a, b) {
            if (a.categoryName.toLowerCase() < b.categoryName.toLowerCase()) { return -1; }
            if (a.categoryName.toLowerCase() > b.categoryName.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelClassPortfolioAllocations.sort(function (a, b) {
            if (a.className.toLowerCase() < b.className.toLowerCase()) { return -1; }
            if (a.className.toLowerCase() > b.className.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelSubClassPortfolioAllocations.sort(function (a, b) {
            if (a.subclassName.toLowerCase() < b.subclassName.toLowerCase()) { return -1; }
            if (a.subclassName.toLowerCase() > b.subclassName.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelSecurityPortfolioAllocations.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelSecuritySetPortfolioAllocations.sort(function (a, b) {
            if (a.securitySetName.toLowerCase() < b.securitySetName.toLowerCase()) { return -1; }
            if (a.securitySetName.toLowerCase() > b.securitySetName.toLowerCase()) { return 1; }
            return 0;
          });

          this.calculateMPAllocTotals(this.modelCategoryPortfolioAllocations);
          this.modelCategoryPortfolioAllocations = this.removeMacSecurity(this.modelCategoryPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelCategoryPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelCategoryPortfolioAllocations, 'categoryName');
          // this.removingCustodialCashSecurityInData(this.modelCategoryPortfolioAllocations, "category");


          this.calculateMPAllocTotals(this.modelClassPortfolioAllocations);
          this.modelClassPortfolioAllocations = this.removeMacSecurity(this.modelClassPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelClassPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelClassPortfolioAllocations, 'className');
          // this.removingCustodialCashSecurityInData(this.modelClassPortfolioAllocations, "class");

          this.calculateMPAllocTotals(this.modelSubClassPortfolioAllocations);
          this.modelSubClassPortfolioAllocations = this.removeMacSecurity(this.modelSubClassPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelSubClassPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelSubClassPortfolioAllocations, 'subclassName');
          // this.removingCustodialCashSecurityInData(this.modelSubClassPortfolioAllocations, "subclass");

          this.calculateMPAllocTotals(this.modelSecurityPortfolioAllocations);
          this.modelSecurityPortfolioAllocations = this.removeMacSecurity(this.modelSecurityPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelSecurityPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelSecurityPortfolioAllocations, 'securityName');
          // this.removingCustodialCashSecurityInData(this.modelSecurityPortfolioAllocations, "security");
          this.gridApiSecurity.setGridOption('rowData', this.modelSecurityPortfolioAllocations);

          this.calculateMPAllocTotals(this.modelSecuritySetPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelSecuritySetPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelSecuritySetPortfolioAllocations, 'securitySetName');
          // this.removingCustodialCashSecurityInData(this.modelSecuritySetPortfolioAllocations, "securitySet");

          // removingCustodialCashSecurityInData other locations uncommented, then comment this one line below
          this.removingCustodialCashSecurityInData(false);
        }
      });
    }
  }

  /*Method fired when we click on the Analyse sleeve button from view page and here we are making the default
  selected tab will be securities tab */
  displaySleeveModelAllocation() {
    this.initializeAllocations();
    this.portfolioSleeveComparison = 'Sleeve-Model Comparison';

    this.portfolioAnalytics = [];
    this._modelService.getModelSleeveAllocations(this.sleeveId)
      .subscribe((model: any) => {
        const modelResult = model;
        const portfolioAnalytic = {} as IPortfolioAnalytics;
        portfolioAnalytic.failedReason = model.failedReason;
        portfolioAnalytic.needAnalytics = model.needAnalytics;
        portfolioAnalytic.portfolioId = model.portfolioId;
        portfolioAnalytic.editedDate = model.analyticsEditedDate;
        this.portfolioAnalytics.push(portfolioAnalytic);
        this.totalAum = model.totalAUM;
        this.setAsideCash = model.setAsideCash;
        this.reserveCash = model.reserveCash;
        this.setasideCashPercent = model.setAsideCashPercent;
        this.reserveCashPercent = model.reserveCashPercent;
        // eslint-disable-next-line eqeqeq
        if (model.securities != undefined) {
          this.sleeveAllocations = Util.deepClone(model.securities);

          this.groupingModelAllocations(modelResult, 'sleeve');
          this.analyseSleeve = true;

          this.modelCategoryPortfolioAllocations.sort(function (a, b) {
            if (a.categoryName.toLowerCase() < b.categoryName.toLowerCase()) { return -1; }
            if (a.categoryName.toLowerCase() > b.categoryName.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelClassPortfolioAllocations.sort(function (a, b) {
            if (a.className.toLowerCase() < b.className.toLowerCase()) { return -1; }
            if (a.className.toLowerCase() > b.className.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelSubClassPortfolioAllocations.sort(function (a, b) {
            if (a.subclassName.toLowerCase() < b.subclassName.toLowerCase()) { return -1; }
            if (a.subclassName.toLowerCase() > b.subclassName.toLowerCase()) { return 1; }
            return 0;
          });
          this.modelSecuritySetPortfolioAllocations.sort(function (a, b) {
            if (a.securitySetName.toLowerCase() < b.securitySetName.toLowerCase()) { return -1; }
            if (a.securitySetName.toLowerCase() > b.securitySetName.toLowerCase()) { return 1; }
            return 0;
          });
          this.sleeveAllocations.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          });

          this.calculateSlvAllocTotals(this.modelCategoryPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelCategoryPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelCategoryPortfolioAllocations, 'categoryName');
          // this.removingCustodialCashSecurityInData(this.modelCategoryPortfolioAllocations, "category", true);

          this.calculateSlvAllocTotals(this.modelSecuritySetPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelSecuritySetPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelSecuritySetPortfolioAllocations, 'securitySetName');
          // this.removingCustodialCashSecurityInData(this.modelSecuritySetPortfolioAllocations, "securitySet", true);

          this.calculateSlvAllocTotals(this.modelClassPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelClassPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelClassPortfolioAllocations, 'className');
          // this.removingCustodialCashSecurityInData(this.modelClassPortfolioAllocations, "class", true);

          this.calculateSlvAllocTotals(this.modelSubClassPortfolioAllocations);
          this.roundingModelAllocationIndividualValues(this.modelSubClassPortfolioAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.modelSubClassPortfolioAllocations, 'subclassName');
          // this.removingCustodialCashSecurityInData(this.modelSubClassPortfolioAllocations, "subclass", true);

          this.calculateSlvAllocTotals(this.sleeveAllocations);
          this.roundingModelAllocationIndividualValues(this.sleeveAllocations);
          this.addingCustomAttributesInModelPortfolioAllocation(this.sleeveAllocations, 'securityName');
          // this.removingCustodialCashSecurityInData(this.sleeveAllocations, "security", true);

          // this.removingCustodialCashSecurityInData other locations uncommented, then comment this one line below
          this.removingCustodialCashSecurityInData(true);
        } else {
          this.sleeveAllocations = null;
        }
      });
  }

  /**
   * ::TOTAL CALCULATIONS::
   **/

  /** Calculate the total allocations of selected portfolio */
  calculateMPAllocTotals(targetAllocation: IModelPortfolioAllocation[]) {
    const mp_TargetAllocation = Util.deepClone(targetAllocation);
    if (mp_TargetAllocation.length > 0) {
      this.structMPAllocation = <IPortfolioTotalTargetAllocationVM>{
        targetInAmt: (mp_TargetAllocation.map(m => m.targetInAmt).length > 1) ?
          mp_TargetAllocation.map(m => m.targetInAmt).reduce((a, b) => a + b, 0)
          : mp_TargetAllocation[0].targetInAmt,
        currentInAmt: (mp_TargetAllocation.map(m => m.currentInAmt)) ?
          mp_TargetAllocation.map(m => m.currentInAmt).reduce((a, b) => a + b, 0)
          : mp_TargetAllocation[0].currentInAmt,
        targetInPercent: (mp_TargetAllocation.map(m => m.targetInPercent)) ?
          mp_TargetAllocation.map(m => m.targetInPercent).reduce((a, b) => a + b, 0)
          : mp_TargetAllocation[0].targetInPercent,
        currentInPercent: (mp_TargetAllocation.map(m => m.currentInPercent)) ?
          mp_TargetAllocation.map(m => m.currentInPercent).reduce((a, b) => a + b, 0)
          : mp_TargetAllocation[0].currentInPercent,
        pendingInPercent: (mp_TargetAllocation.map(m => m.pendingInPercent)) ?
          mp_TargetAllocation.map(m => m.pendingInPercent).reduce((a, b) => a + b, 0)
          : (mp_TargetAllocation[0].pendingInPercent) ? mp_TargetAllocation[0].pendingInPercent : 0,
        pendingInAmt: (mp_TargetAllocation.map(m => m.pendingInAmt)) ?
          mp_TargetAllocation.map(m => m.pendingInAmt).reduce((a, b) => a + b, 0)
          : (mp_TargetAllocation[0].pendingInAmt) ? mp_TargetAllocation[0].pendingInAmt : 0

      };
      /*Rounding the values to 2 digits */
      this.structMPAllocation.targetInAmt = this.roundingTheValues(this.structMPAllocation.targetInAmt);
      this.structMPAllocation.currentInAmt = this.roundingTheValues(this.structMPAllocation.currentInAmt);
      this.structMPAllocation.targetInPercent = this.roundingPercentValues(this.roundingTheValues(this.structMPAllocation.targetInPercent));
      this.structMPAllocation.currentInPercent = this.roundingPercentValues(this.roundingTheValues(this.structMPAllocation.currentInPercent));
      this.structMPAllocation.pendingInAmt = this.roundingTheValues(this.structMPAllocation.pendingInAmt);
      this.structMPAllocation.pendingInPercent = this.roundingPercentValues(this.roundingTheValues(this.structMPAllocation.pendingInPercent));
    }
  }

  /** Calculate the total allocations of selected portfolio */
  calculateSlvAllocTotals(targetAllocation: ISleevedAccountAllocation[]) {
    const slv_TargetAllocation = Util.deepClone(targetAllocation);
    if (slv_TargetAllocation.length > 0) {
      this.structSlvAllocation = <ISleevedAccountTotalTargetAllocationVM>{
        targetInAmt: (slv_TargetAllocation.map(m => m.targetInAmt).length > 1) ?
          slv_TargetAllocation.map(m => m.targetInAmt).reduce((a, b) => a + b, 0)
          : slv_TargetAllocation[0].targetInAmt,
        currentInAmt: (slv_TargetAllocation.map(m => m.currentInAmt)) ?
          slv_TargetAllocation.map(m => m.currentInAmt).reduce((a, b) => a + b, 0)
          : slv_TargetAllocation[0].currentInAmt,
        targetInPercent: (slv_TargetAllocation.map(m => m.targetInPercent)) ?
          slv_TargetAllocation.map(m => m.targetInPercent).reduce((a, b) => a + b, 0)
          : slv_TargetAllocation[0].targetInPercent,
        currentInPercent: (slv_TargetAllocation.map(m => m.currentInPercent)) ?
          slv_TargetAllocation.map(m => m.currentInPercent).reduce((a, b) => a + b, 0)
          : slv_TargetAllocation[0].currentInPercent,
        pendingInAmt: (slv_TargetAllocation.map(m => m.pendingInAmt)) ?
          slv_TargetAllocation.map(m => m.pendingInAmt).reduce((a, b) => a + b, 0)
          : (slv_TargetAllocation[0].pendingInAmt) ? slv_TargetAllocation[0].pendingInAmt : 0,
        pendingInPercent: (slv_TargetAllocation.map(m => m.pendingInPercent)) ?
          slv_TargetAllocation.map(m => m.pendingInPercent).reduce((a, b) => a + b, 0)
          : (slv_TargetAllocation.pendingInPercent) ? slv_TargetAllocation[0].pendingInPercent : 0
      };
      /*Rounding the values to 2 digits */
      this.structSlvAllocation.targetInAmt = this.roundingTheValues(this.structSlvAllocation.targetInAmt);
      this.structSlvAllocation.currentInAmt = this.roundingTheValues(this.structSlvAllocation.currentInAmt);
      this.structSlvAllocation.targetInPercent = this.roundingPercentValues(this.roundingTheValues(this.structSlvAllocation.targetInPercent));
      this.structSlvAllocation.currentInPercent = this.roundingPercentValues(this.roundingTheValues(this.structSlvAllocation.currentInPercent));
      this.structSlvAllocation.pendingInAmt = this.roundingTheValues(this.structSlvAllocation.pendingInAmt);
      this.structSlvAllocation.pendingInPercent = this.roundingPercentValues(this.roundingTheValues(this.structSlvAllocation.pendingInPercent));
    }
  }

  /*Rounding the values to two decimal places to show in portfolio comparison */
  roundingModelAllocationIndividualValues(data) {
    data.forEach(element => {
      element.currentInAmt = this.roundingTheValues(element.currentInAmt);
      element.currentInPercent = this.roundingTheValues(element.currentInPercent);
      element.targetInAmt = this.roundingTheValues(element.targetInAmt);
      element.targetInPercent = this.roundingTheValues(element.targetInPercent);
      element.pendingInPercent = this.roundingTheValues(element.pendingInPercent);
      element.pendingInAmt = this.roundingTheValues(element.pendingInAmt);
    });
  }

  /** initialize Allocation Vms*/
  initializeAllocations() {
    this.structMPAllocation = <IPortfolioTotalTargetAllocationVM>{
      symbol: '',
      name: '',
      targetInAmt: null,
      currentInAmt: null,
      targetInPercent: null,
      currentInPercent: null,
      pendingInPercent: null,
      pendingInAmt: null
    };

    this.structSlvAllocation = <ISleevedAccountTotalTargetAllocationVM>{
      currentInAmt: null,
      currentInPercent: null,
      symbol: '',
      name: '',
      targetInAmt: null,
      targetInPercent: null,
      pendingInAmt: null,
      pendingInPercent: null
    };

  }

  /*Method used to group the data based on the sub model type to show in model portfolio comparison grid  */
  groupingModelAllocations(model, type) {
    let cashSecurity = [];
    const unassignedSecurities: any = this.addingPortfolioSecurities(model.securities);
    const macSecurities: any = this.addingMacSecurities(model.securities);
    this.modelCategoryPortfolioAllocations = [];
    this.modelCategoryPortfolioAllocations = d3.nest()
      .key(function (d) {
        // eslint-disable-next-line eqeqeq
        if (d['categoryId'] != null && d['name'] != 'SMA_SECURITY') {
          return d['categoryId'];
        }
      })
      .entries(model.categories);
    this.modelClassPortfolioAllocations = [];
    this.modelClassPortfolioAllocations = d3.nest()
      .key(function (d) {
        // eslint-disable-next-line eqeqeq
        if (d['classId'] != null && d['name'] != 'SMA_SECURITY') {
          return d['classId'];
        }
      })
      .entries(model.classes);

    this.modelSubClassPortfolioAllocations = [];
    this.modelSubClassPortfolioAllocations = d3.nest()
      .key(function (d: any) {
        // eslint-disable-next-line eqeqeq
        if (d['subclassId'] != null && d['name'] != 'SMA_SECURITY') {
          return d['subclassId'];
        }
      })
      .entries(model.subClasses);

    // eslint-disable-next-line eqeqeq
    if (type == 'portfolio') {
      this.modelSecurityPortfolioAllocations = [];
      this.modelSecurityPortfolioAllocations = d3.nest()
        .key(function (d) {
          // eslint-disable-next-line eqeqeq
          if (d['securityId'] != null) {
            return `${d['securityId']}_${d['isPortfolioSecurity']}`;
          }
        })
        .entries(model.securities);

      this.modelSecuritySetPortfolioAllocations = [];
      this.modelSecuritySetPortfolioAllocations = d3.nest()
        .key(function (d) {
          // eslint-disable-next-line eqeqeq
          if (d['securityId'] != null && d['securitySetId'] != null) {
            return d['securityId'];
          }
        })
        .entries(model.securities);
      this.modelSecuritySetPortfolioAllocations = this.aggregatingGroupedObjectsFromSecuritySetData(this.modelSecuritySetPortfolioAllocations, 'securitySetName', 'securitySetId');
      this.modelSecurityPortfolioAllocations = this.aggregatingGroupedObjectsFromData(this.modelSecurityPortfolioAllocations, 'securityName', 'securityId');
      // eslint-disable-next-line eqeqeq
      this.smaPortfolioAllocation = model.securities.filter(spd => spd.name == 'SMA_SECURITY');

      // eslint-disable-next-line eqeqeq
      cashSecurity = this.modelSecurityPortfolioAllocations.filter(spd => spd.name == 'CUSTODIAL_CASH');

      // if (this.smaPortfolioAllocation.length > 0) {
      //     let aggregateSmaPortfolio = this.aggregateSmaPortfolioData(this.smaPortfolioAllocation);
      //     // Filtering SMA rows as in the below code line adding aggregated SMA
      //     this.modelSecurityPortfolioAllocations = this.modelSecurityPortfolioAllocations.filter(obj => obj.name !== "SMA_SECURITY");
      //     this.modelSecurityPortfolioAllocations.push(aggregateSmaPortfolio);
      // }
    } else {
      this.sleeveAllocations = [];
      this.sleeveAllocations = d3.nest()
        .key(function (d) {
          // eslint-disable-next-line eqeqeq
          if (d['id'] != null) {
            return d['id'];
          }
        })
        .entries(model.securities);
      this.modelSecuritySetPortfolioAllocations = [];
      this.modelSecuritySetPortfolioAllocations = d3.nest()
        .key(function (d) {
          // eslint-disable-next-line eqeqeq
          if (d['id'] != null && d['securitySetId'] != null) {
            return d['id'];
          }
        })
        .entries(model.securities);
      this.modelSecuritySetPortfolioAllocations = this.aggregatingGroupedObjectsFromSecuritySetData(this.modelSecuritySetPortfolioAllocations, 'securitySetName', 'securitySetId');
      this.sleeveAllocations = this.aggregatingGroupedObjectsFromData(this.sleeveAllocations, 'securityName', 'securityId');
      // eslint-disable-next-line eqeqeq
      cashSecurity = this.sleeveAllocations.filter(spd => spd.name == 'CUSTODIAL_CASH');
    }
    this.modelCategoryPortfolioAllocations = this.aggregatingGroupedObjectsFromData(this.modelCategoryPortfolioAllocations, 'categoryName', 'categoryId');
    this.modelClassPortfolioAllocations = this.aggregatingGroupedObjectsFromData(this.modelClassPortfolioAllocations, 'className', 'classId');
    this.modelSubClassPortfolioAllocations = this.aggregatingGroupedObjectsFromData(this.modelSubClassPortfolioAllocations, 'subclassName', 'subclassId');
    this.checkingTheModelLevels();

    // eslint-disable-next-line eqeqeq
    if (unassignedSecurities != undefined) {
      this.modelCategoryPortfolioAllocations.push(unassignedSecurities);
      this.modelClassPortfolioAllocations.push(unassignedSecurities);
      this.modelSubClassPortfolioAllocations.push(unassignedSecurities);
      this.modelSecuritySetPortfolioAllocations.push(unassignedSecurities);
    }
    // //SMA_SECURITY (OE-1294)
    if (this.smaPortfolioAllocation.length > 0) {
      const aggregateSmaPortfolio = this.aggregateSmaPortfolioData(this.smaPortfolioAllocation);
      // Filtering SMA rows as in the below code line adding aggregated SMA
      this.modelSecurityPortfolioAllocations = this.modelSecurityPortfolioAllocations.filter(obj => obj.name !== 'SMA_SECURITY');
      this.modelSecurityPortfolioAllocations.push(aggregateSmaPortfolio);
      this.smaPortfolioAllocation.forEach(sma => {
        // eslint-disable-next-line eqeqeq
        if (sma.smaLevel == 'SUBCLASS') {
          // this.modelSecuritySetPortfolioAllocations.push(this.setObject(this.smaPortfolioAllocation));
          // this.smaPresentation();
          // eslint-disable-next-line eqeqeq
          if (this.smaPortfolioAllocation == 1) {
            const objSMA = this.setObject(this.smaPortfolioAllocation);
            this.setSubClassLevelSMA(objSMA);
          } else { // multiple SMA at level
            //    this.smaPortfolioAllocation.forEach(smaElement => {
            const objSMA = this.setSMAObject(sma);
            this.setSubClassLevelSMA(objSMA);
            // });
          }
        }

        // eslint-disable-next-line eqeqeq
        if (sma.smaLevel == 'CLASS') {
          // eslint-disable-next-line eqeqeq
          if (this.smaPortfolioAllocation == 1) {
            const objSMA = this.setObject(this.smaPortfolioAllocation);
            this.setClassLevelSMA(objSMA);
          } else { // multiple SMA at level
            // this.smaPortfolioAllocation.forEach(smaElement => {
            const objSMA = this.setSMAObject(sma);
            this.setClassLevelSMA(objSMA);
            // });
          }
        }

        // eslint-disable-next-line eqeqeq
        if (sma.smaLevel == 'CATEGORY') {
          // this.modelClassPortfolioAllocations.push(this.setObject(this.smaPortfolioAllocation));
          // this.modelSubClassPortfolioAllocations.push(this.setObject(this.smaPortfolioAllocation));
          // this.modelSecuritySetPortfolioAllocations.push(this.setObject(this.smaPortfolioAllocation));
          // eslint-disable-next-line eqeqeq
          if (this.smaPortfolioAllocation == 1) {
            const objSMA = this.setObject(this.smaPortfolioAllocation);
            this.setCategoryLevelSMA(objSMA);
          } else { // multiple SMA at level
            //  this.smaPortfolioAllocation.forEach(smaElement => {
            const objSMA = this.setSMAObject(sma);
            this.setCategoryLevelSMA(objSMA);
            //  });
          }
        }
      });
    }
    // eslint-disable-next-line eqeqeq
    if (macSecurities != undefined) {
      // this.modelCategoryPortfolioAllocations.push(macSecurities);
      // this.modelClassPortfolioAllocations.push(macSecurities);
      // this.modelSubClassPortfolioAllocations.push(macSecurities);
      this.modelSecuritySetPortfolioAllocations.push(macSecurities);
    }
    // Cash security will be a single row so considered the first element
    if (cashSecurity.length > 0) {
      cashSecurity.forEach(element => {
        element.categoryName = 'CASH';
        element.className = 'CASH';
        element.subclassName = 'CASH';
        element.securitySetName = 'CASH';
      });
      this.modelSecuritySetPortfolioAllocations.push(cashSecurity[0]);
      this.modelCategoryPortfolioAllocations.push(cashSecurity[0]);
      this.modelClassPortfolioAllocations.push(cashSecurity[0]);
      this.modelSubClassPortfolioAllocations.push(cashSecurity[0]);
    }
  }

  private smaPresentation() {
    // eslint-disable-next-line eqeqeq
    if (this.smaPortfolioAllocation == 1) {
      const objSMA = this.setObject(this.smaPortfolioAllocation);
      this.setClassLevelSMA(objSMA);
    } else { // multiple SMA at level
      this.smaPortfolioAllocation.forEach(smaElement => {
        const objSMA = this.setSMAObject(smaElement);
        this.setClassLevelSMA(objSMA);
      });
    }
  }

  private setClassLevelSMA(objSMA: { categoryName: string; className: string; subclassName: string; securitySetName: string; currentInAmt: number; currentInPercent: number; targetInAmt: number; targetInPercent: number; pendingInAmt: number; pendingInPercent: number; modelElementId: any; categoryId: any }) {
    // let elementClass = this.modelClassPortfolioAllocations.find(c => c.classId == objSMA.modelElementId);
    // if (elementClass) {
    //     this.modelClassPortfolioAllocations.splice(this.modelClassPortfolioAllocations.findIndex(c => c.classId == objSMA.modelElementId), 1, this.aggregateLevelsData(elementClass, objSMA));
    // }
    // if(objSMA.categoryId){
    // let elementCategory = this.modelCategoryPortfolioAllocations.find(ct => ct.categoryId == objSMA.categoryId);
    // if (elementCategory) {
    //     this.modelCategoryPortfolioAllocations.splice(this.modelCategoryPortfolioAllocations.findIndex(ct => ct.categoryId == objSMA.categoryId), 1, this.aggregateLevelsData(elementCategory, objSMA));
    // }
    // }
    // eslint-disable-next-line eqeqeq
    const subClass = Util.deepClone(this.modelSubClassPortfolioAllocations.find(sc => sc.subclassName == 'SMA'));
    if (subClass) {
      // eslint-disable-next-line eqeqeq
      this.modelSubClassPortfolioAllocations.splice(this.modelSubClassPortfolioAllocations.findIndex(sc => sc.subclassName == 'SMA'), 1, this.aggregateSMA(subClass, objSMA));
    } else {
      this.modelSubClassPortfolioAllocations.push(objSMA);
    }
    // eslint-disable-next-line eqeqeq
    const securitySet = Util.deepClone(this.modelSecuritySetPortfolioAllocations.find(sc => sc.securitySetName == 'SMA_SecuritySet'));
    if (securitySet) {
      // eslint-disable-next-line eqeqeq
      this.modelSecuritySetPortfolioAllocations.splice(this.modelSecuritySetPortfolioAllocations.findIndex(st => st.securitySetName == 'SMA_SecuritySet'), 1, this.aggregateSMA(securitySet, objSMA));
    } else {
      this.modelSecuritySetPortfolioAllocations.push(objSMA);
    }
  }

  private setSubClassLevelSMA(objSMA: { categoryName: string; className: string; subclassName: string; securitySetName: string; currentInAmt: number; currentInPercent: number; targetInAmt: number; targetInPercent: number; pendingInAmt: number; pendingInPercent: number; modelElementId: any; classId: any; categoryId: any }) {
    // let elementSubClass = this.modelSubClassPortfolioAllocations.find(sc => sc.subclassId == objSMA.modelElementId);
    // if (elementSubClass) {
    //     this.modelSubClassPortfolioAllocations.splice(this.modelSubClassPortfolioAllocations.findIndex(sc => sc.subclassId == objSMA.modelElementId), 1, this.aggregateLevelsData(elementSubClass, objSMA));
    // }
    // let elementClass = this.modelClassPortfolioAllocations.find(c => c.classId == objSMA.classId);
    // if (elementClass) {
    //     this.modelClassPortfolioAllocations.splice(this.modelClassPortfolioAllocations.findIndex(c => c.classId == objSMA.classId), 1, this.aggregateLevelsData(elementClass, objSMA));
    // }
    // let elementCategory = this.modelCategoryPortfolioAllocations.find(ct => ct.categoryId == objSMA.categoryId);
    // if (elementCategory) {
    //     this.modelCategoryPortfolioAllocations.splice(this.modelCategoryPortfolioAllocations.findIndex(ct => ct.categoryId == objSMA.categoryId), 1, this.aggregateLevelsData(elementCategory, objSMA));
    // }
    // eslint-disable-next-line eqeqeq
    const securitySet = Util.deepClone(this.modelSecuritySetPortfolioAllocations.find(st => st.securitySetName == 'SMA_SecuritySet'));
    if (securitySet) {
      // eslint-disable-next-line eqeqeq
      this.modelSecuritySetPortfolioAllocations.splice(this.modelSecuritySetPortfolioAllocations.findIndex(st => st.securitySetName == 'SMA_SecuritySet'), 1, this.aggregateSMA(securitySet, objSMA));
    } else {
      this.modelSecuritySetPortfolioAllocations.push(objSMA);
    }
  }

  private setCategoryLevelSMA(objSMA: { categoryName: string; className: string; subclassName: string; securitySetName: string; currentInAmt: number; currentInPercent: number; targetInAmt: number; targetInPercent: number; pendingInAmt: number; pendingInPercent: number; modelElementId: any; classId: any; categoryId: any }) {
    // Class Tab
    // eslint-disable-next-line eqeqeq
    const classLevel = Util.deepClone(this.modelClassPortfolioAllocations.find(cl => cl.className == 'SMA'));
    if (classLevel) {
      // eslint-disable-next-line eqeqeq
      this.modelClassPortfolioAllocations.splice(this.modelClassPortfolioAllocations.findIndex(cl => cl.className == 'SMA'), 1, this.aggregateSMA(classLevel, objSMA));
    } else {
      this.modelClassPortfolioAllocations.push(objSMA);
    }

    // SubClass Tab
    // eslint-disable-next-line eqeqeq
    const subClass = Util.deepClone(this.modelSubClassPortfolioAllocations.find(sc => sc.subclassName == 'SMA'));
    if (subClass) {
      // eslint-disable-next-line eqeqeq
      this.modelSubClassPortfolioAllocations.splice(this.modelSubClassPortfolioAllocations.findIndex(sc => sc.subclassName == 'SMA'), 1, this.aggregateCatSMA(subClass, objSMA));
    } else {
      this.modelSubClassPortfolioAllocations.push(objSMA);
    }
    // SecuritySet Tab
    // eslint-disable-next-line eqeqeq
    const securitySet = Util.deepClone(this.modelSecuritySetPortfolioAllocations.find(s => s.securitySetName == 'SMA_SecuritySet'));
    if (securitySet) {
      // eslint-disable-next-line eqeqeq
      this.modelSecuritySetPortfolioAllocations.splice(this.modelSecuritySetPortfolioAllocations.findIndex(s => s.securitySetName == 'SMA_SecuritySet'), 1, this.aggregateSMA(securitySet, objSMA));
    } else {
      this.modelSecuritySetPortfolioAllocations.push(objSMA);
    }

    // //Category Tab
    // let elementCategory = this.modelCategoryPortfolioAllocations.find(ct => ct.categoryId == objSMA.modelElementId);
    // if (elementCategory) {
    //     this.modelCategoryPortfolioAllocations.splice(this.modelCategoryPortfolioAllocations.findIndex(ct => ct.categoryId == objSMA.modelElementId), 1, this.aggregateLevelsData(elementCategory, objSMA));
    // }
  }

  private aggregateLevelsData(element: any, objSMA: { categoryName: string; className: string; subclassName: string; securitySetName: string; currentInAmt: number; currentInPercent: number; targetInAmt: number; targetInPercent: number; pendingInAmt: number; pendingInPercent: number; modelElementId: any; categoryId: any }) {
    const ele = element;
    ele['targetInAmt'] += objSMA.targetInAmt;
    ele['currentInAmt'] += objSMA.currentInAmt;
    ele['targetInPercent'] += objSMA.targetInPercent;
    ele['currentInPercent'] += objSMA.currentInPercent;
    ele['pendingInAmt'] += (objSMA.pendingInAmt) ? objSMA.pendingInAmt : 0;
    ele['pendingInPercent'] += (objSMA.pendingInPercent) ? objSMA.pendingInPercent : 0;
    return ele;
  }

  /*Method used to delete the extra objects on nesting and made the data as array of objects  */
  aggregatingGroupedObjectsFromSecuritySetData(data, name, id) {
    const swapData = [];
    let count = 0.0;
    let hasUndefinedKey = false;
    let undefinedKey = null;
    data.forEach((element, key) => {
      let aggregateData = {};
      // eslint-disable-next-line eqeqeq
      if (element.key == 'undefined') {
        hasUndefinedKey = true;
        undefinedKey = key;
      } else {
        if (element.values.length > 0) {
          aggregateData['targetInAmt'] = 0;
          aggregateData['currentInAmt'] = 0;
          aggregateData['targetInPercent'] = 0;
          aggregateData['currentInPercent'] = 0;
          aggregateData['pendingInPercent'] = 0;
          aggregateData['pendingInAmt'] = 0;
          // eslint-disable-next-line no-shadow
          element.values.forEach((element, key) => {
            aggregateData = {...aggregateData};
            // eslint-disable-next-line eqeqeq
            if (element.name != 'Cash') {
              let isDataAlready = false;
              swapData.forEach(function (set) {
                // eslint-disable-next-line eqeqeq
                if (element[id] == set[id]) {
                  set['targetInAmt'] = set['targetInAmt'] + element.targetInAmt;
                  set['currentInAmt'] = set['currentInAmt'] + element.currentInAmt;
                  set['targetInPercent'] = set['targetInPercent'] + element.targetInPercent;
                  set['currentInPercent'] = set['currentInPercent'] + element.currentInPercent;
                  set['pendingInAmt'] = set['pendingInAmt'] + element.pendingInAmt;
                  set['pendingInPercent'] = set['pendingInPercent'] + element.pendingInPercent;
                  isDataAlready = true;
                }
              });

              if (!isDataAlready) {
                aggregateData[name] = element[name];
                aggregateData[id] = element[id];
                aggregateData['targetInAmt'] = element.targetInAmt;
                aggregateData['currentInAmt'] = element.currentInAmt;
                aggregateData['targetInPercent'] = element.targetInPercent;
                aggregateData['currentInPercent'] = element.currentInPercent;
                aggregateData['pendingInAmt'] = (element.pendingInAmt) ? element.pendingInAmt : 0;
                aggregateData['pendingInPercent'] = (element.pendingInPercent) ? element.pendingInPercent : 0;
                swapData.push(aggregateData);
              }
            }
            count = count + element.pendingInAmt;
          });
        }
      }
    });
    if (hasUndefinedKey) {
      data.splice(undefinedKey, 1);
    }
    return swapData;
  }

  /*Method used to delete the extra objects on nesting and made the data as array of objects  */
  aggregatingGroupedObjectsFromData(data, name, id) {
    const swapData = [];
    let hasUndefinedKey = false;
    let undefinedKey = null;
    data.forEach((element, key) => {
      const aggregateData = {};
      // eslint-disable-next-line eqeqeq
      if (element.key == 'undefined') {
        hasUndefinedKey = true;
        undefinedKey = key;
      } else {
        if (element.values.length > 0) {
          aggregateData['targetInAmt'] = 0;
          aggregateData['currentInAmt'] = 0;
          aggregateData['targetInPercent'] = 0;
          aggregateData['currentInPercent'] = 0;
          aggregateData['pendingInPercent'] = 0;
          aggregateData['pendingInAmt'] = 0;
          // eslint-disable-next-line no-shadow
          element.values.forEach((element, key) => {
            // eslint-disable-next-line eqeqeq
            if (key == 0) {
              // eslint-disable-next-line eqeqeq
              if (name == 'securityName') {
                // eslint-disable-next-line eqeqeq
                aggregateData['name'] = (element.name == 'Cash') ? 'CUSTODIAL_CASH' : element.name;
                aggregateData[id] = element[id];
                // eslint-disable-next-line eqeqeq
                aggregateData['symbol'] = (element['symbol'] == 'Cash') ? '' : element['symbol'];
                aggregateData['isPortfolioSecurity'] = element.isPortfolioSecurity;
              } else {
                aggregateData[name] = element[name];
                aggregateData[id] = element[id];
              }
            }
            aggregateData['targetInAmt'] += element.targetInAmt;
            aggregateData['currentInAmt'] += element.currentInAmt;
            aggregateData['targetInPercent'] += element.targetInPercent;
            aggregateData['currentInPercent'] += element.currentInPercent;
            aggregateData['pendingInAmt'] += (element.pendingInAmt) ? element.pendingInAmt : 0;
            aggregateData['pendingInPercent'] += (element.pendingInPercent) ? element.pendingInPercent : 0;
          });
          // eslint-disable-next-line no-shadow
          [aggregateData].forEach((val, key) => {
            swapData.push(val);
          });
        }
      }
    });
    if (hasUndefinedKey) {
      data.splice(undefinedKey, 1);
    }
    return swapData;
  }

  /*Method used to add portfolio securities which will be showing in unassigned section in Analyse portfolio or sleeve */
  addingPortfolioSecurities(data) {
    let count = 0;
    const unassignedSecurities = {
      categoryName: 'Unassigned',
      className: 'Unassigned',
      subclassName: 'Unassigned',
      securitySetName: 'Unassigned',
      currentInAmt: 0,
      currentInPercent: 0,
      targetInAmt: 0,
      targetInPercent: 0,
      pendingInAmt: 0,
      pendingInPercent: 0
    };
    data.forEach(element => {
      // eslint-disable-next-line eqeqeq
      if ((element.isPortfolioSecurity) && element.name != 'Cash') { // as per SMA changes,SMA should not be included in Unassigned
        count++;
        unassignedSecurities.currentInAmt += element.currentInAmt;
        unassignedSecurities.currentInPercent += element.currentInPercent;
        unassignedSecurities.targetInAmt += element.targetInAmt;
        unassignedSecurities.targetInPercent += element.targetInPercent;
        unassignedSecurities.pendingInAmt += (element.pendingInAmt) ? element.pendingInAmt : 0;
        unassignedSecurities.pendingInPercent += (element.pendingInPercent) ? element.pendingInPercent : 0;
      }
    });
    if (count > 0) {
      return unassignedSecurities;
    }
  }

  addingMacSecurities(data) {
    let count = 0;
    const macSecurities = {
      categoryName: 'MAC Fund',
      className: 'MAC Fund',
      subclassName: 'MAC Fund',
      securitySetName: 'MAC Fund',
      currentInAmt: 0,
      currentInPercent: 0,
      targetInAmt: 0,
      targetInPercent: 0,
      pendingInAmt: 0,
      pendingInPercent: 0
    };

    data.forEach(element => {
      // eslint-disable-next-line eqeqeq
      if (element.isMACSecurity == 1) {
        count++;
        macSecurities.currentInAmt += element.currentInAmt;
        macSecurities.currentInPercent += element.currentInPercent;
        macSecurities.targetInAmt += element.targetInAmt;
        macSecurities.targetInPercent += element.targetInPercent;
        macSecurities.pendingInAmt += (element.pendingInAmt) ? element.pendingInAmt : 0;
        macSecurities.pendingInPercent += (element.pendingInPercent) ? element.pendingInPercent : 0;
      }
    });
    if (count > 0) {
      return macSecurities;
    }
  }

  // /** Currency format */
  formatCurrency(value) {
    // eslint-disable-next-line eqeqeq
    if (value != null || value != undefined) {
      const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: this.decimalsForDollar
      });
      return currencyFormat.format(value);
    }
    return null;
  }

  /*Showing Model AUM in comparison popup for both portfolio and sleeve */
  modelAumForPortfolioAllocation() {
    // return (this.totalAum != null) ? this.formatCurrency(this.totalAum) : "$0";
    // if (!this.analyseSleeve) {
    //     return (this.structMPAllocation.currentInAmt != null) ? this.formatCurrency(this.structMPAllocation.currentInAmt) : "$0"
    // }
    // else {
    //     return (this.structSlvAllocation.currentInAmt != null) ? this.formatCurrency(this.structSlvAllocation.currentInAmt) : "$0";
    // }
  }

  /*Aggregating the sum of sma securities to show as dummy securities for the sma accounts  */
  aggregateSmaPortfolioData(data) {
    const aggregateObject = {
      name: 'SMA_SECURITY',
      targetInAmt: 0,
      currentInAmt: 0,
      pendingInAmt: 0,
      targetInPercent: 0,
      currentInPercent: 0,
      pendingInPercent: 0,
      smaLevel: null,
      modelElementId: null,
      categoryId: null
    };
    data.forEach(element => {
      aggregateObject.currentInAmt += element.currentInAmt;
      aggregateObject.currentInPercent += element.currentInPercent;
      aggregateObject.targetInAmt += element.targetInAmt;
      aggregateObject.targetInPercent += element.targetInPercent;
      aggregateObject.pendingInAmt += element.pendingInAmt;
      aggregateObject.pendingInPercent += element.pendingInPercent;
      aggregateObject.smaLevel = element.smaLevel;
      aggregateObject.modelElementId = element.modelElementId;
      aggregateObject.categoryId = element.categoryId;
    });
    return aggregateObject;
  }

  /*Method used to round the values to two decimal points */
  roundingTheValues(data) {
    // eslint-disable-next-line eqeqeq
    if (data != null) {
      // eslint-disable-next-line eqeqeq
      if (data.toString().indexOf('.') != -1) {
        if (data.toString().split('.')[1].length > 2) {
          return +(data).toFixed(2);
        }
      }
    }
    return data;
  }

  /** To render with currency format */
  formatCurrencyCellRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params.value != null || params.value != undefined) {
      const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: params.context.self.decimalsForDollar
      });
      return currencyFormat.format(params.value);
    }
    return null;
  }

  formatPercentCellRenderer(params) {
    let data = params.value || 0;
    data = params.context.self.roundDecimals(data, params.context.self.decimalsForPercent);
    return `${data} %`;
  }

  /*Getting the selected portfolio id from view details page */
  gettingPortfolioId(id: number) {
    this.portfolioId = id;
    this.displayPortfolioModelAllocation();
  }

  /*showing the value 100(as per client suggestion) if the value is greater than 100 and less than 100.05 */
  roundingPercentValues(value) {
    return (value >= 99.99 && value <= 100.05) ? 100 : value;
  }

  /*Applying row styles for the ag-grid */

  getRowStyle(params) {
    // eslint-disable-next-line eqeqeq
    if (params.data != undefined) {
      // eslint-disable-next-line eqeqeq
      if (params.data.securityName == 'Total' || params.data.categoryName == 'Total'
        // eslint-disable-next-line eqeqeq
        || params.data.className == 'Total' || params.data.subclassName == 'Total'
        // eslint-disable-next-line eqeqeq
        || params.data.securitySetName == 'Total') {
        return {'font-weight': 'bold', color: '#01acef'};
      }
    }
  }

  checkingTheModelLevels() {
    this.hasCategoryLevel = !!this.modelCategoryPortfolioAllocations?.length;
    this.hasClassLevel = !!this.modelClassPortfolioAllocations?.length;
    this.hasSubClassLevel = !!this.modelSubClassPortfolioAllocations?.length;
    this.hasSecuritySetLevel = !!this.modelSecuritySetPortfolioAllocations?.length;
  }

  initialisingFloatingBottomRowData() {
    this.modelSecurityAllocationGridOptions.pinnedBottomRowData = [
      {
        securityName: 'Total',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];
    this.modelSecurityAllocationGridOptions.pinnedTopRowData = [
      {
        securityName: 'Custodial_Cash',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];
    this.modelSubClassAllocationGridOptions.pinnedBottomRowData = [
      {
        subclassName: 'Total',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelSubClassAllocationGridOptions.pinnedTopRowData = [
      {
        subclassName: 'CASH',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelClassAllocationGridOptions.pinnedBottomRowData = [
      {
        className: 'Total',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelClassAllocationGridOptions.pinnedTopRowData = [
      {
        className: 'CASH',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelCategoryAllocationGridOptions.pinnedBottomRowData = [
      {
        categoryName: 'Total',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelCategoryAllocationGridOptions.pinnedTopRowData = [
      {
        categoryName: 'CASH',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];


    this.modelSecuritySetAllocationGridOptions.pinnedBottomRowData = [
      {
        securitySetName: 'Total',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelSecuritySetAllocationGridOptions.pinnedTopRowData = [
      {
        securitySetName: 'CASH',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelSecuritySleeveAllocationGridOptions.pinnedBottomRowData = [
      {
        securityName: 'Total',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];

    this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData = [
      {
        securityName: 'CUSTODIAL_CASH',
        currentInAmt: 0,
        targetInAmt: 0,
        pendingInAmt: 0,
        targetInPercent: 0,
        currentInPercent: 0,
        pendingInPercent: 0
      }
    ];
  }

  /*
  Method to remove custodial cash from the respective data and updating the floating top row data values
  for all grids
   */

  removingCustodialCashSecurityInData(isSleeve = false) {
    const custodialCash = [];
    const datalist = [];
    datalist.push(this.modelCategoryPortfolioAllocations);
    datalist.push(this.modelClassPortfolioAllocations);
    datalist.push(this.modelSubClassPortfolioAllocations);
    datalist.push(this.modelSecurityPortfolioAllocations);
    datalist.push(this.modelSecuritySetPortfolioAllocations);
    if (isSleeve) { datalist.push(this.sleeveAllocations); }
    datalist.forEach((ele, key) => {
      if (ele && ele.length > 0) {
        // eslint-disable-next-line no-shadow
        ele.forEach((element, key) => {
          // eslint-disable-next-line eqeqeq
          if (element.name == 'CUSTODIAL_CASH') {
            custodialCash.push(element);
            ele.splice(key, 1);
          }
        });
      }
    });
    if (custodialCash.length > 0) {
      // CurrentInAmount
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].currentInAmt] = Array(6).fill(custodialCash[0].currentInAmt);
      // TargetInAmount
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].targetInAmt] = Array(5).fill(custodialCash[0].targetInAmt);
      // pendingInAmt
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].pendingInAmt] = Array(5).fill(custodialCash[0].pendingInAmt);
      // target Percentage
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].targetInPercent] = Array(5).fill(custodialCash[0].targetInPercent);
      // current Percentage
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].currentInPercent] = Array(5).fill(custodialCash[0].currentInPercent);
      // pending Percentage
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].pendingInPercent] = Array(5).fill(custodialCash[0].pendingInPercent);
      if (isSleeve) {
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].currentInAmt = custodialCash[0].currentInAmt;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].targetInAmt = custodialCash[0].targetInAmt;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].pendingInAmt = custodialCash[0].pendingInAmt;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].targetInPercent = custodialCash[0].targetInPercent;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].currentInPercent = custodialCash[0].currentInPercent;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].pendingInPercent = custodialCash[0].pendingInPercent;
      }
    } else {
      // clear out previous custodial cash top row
      // CurrentInAmount
      [this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        // TargetInAmount
        this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].targetInAmt,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].currentInAmt,
        // pendingInAmt
        this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].pendingInAmt,
        // target Percentage
        this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].targetInPercent,
        // current Percentage
        this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].currentInPercent,
        // pending Percentage
        this.modelSecurityAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelSubClassAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelClassAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelCategoryAllocationGridOptions.pinnedTopRowData[0].pendingInPercent,
        this.modelSecuritySetAllocationGridOptions.pinnedTopRowData[0].pendingInPercent] = Array(30).fill(0);
      if (isSleeve) {
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].currentInAmt = 0;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].targetInAmt = 0;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].pendingInAmt = 0;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].targetInPercent = 0;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].currentInPercent = 0;
        this.modelSecuritySleeveAllocationGridOptions.pinnedTopRowData[0].pendingInPercent = 0;
      }
    }

  }

  // SMA related,list of element
  setObject(data) {
    const count = 0;
    const aggregateObject = {
      categoryName: 'SMA',
      className: 'SMA',
      subclassName: 'SMA',
      securitySetName: 'SMA_SecuritySet',
      currentInAmt: 0,
      currentInPercent: 0,
      targetInAmt: 0,
      targetInPercent: 0,
      pendingInAmt: 0,
      pendingInPercent: 0,
      modelElementId: null,
      categoryId: null,
      classId: null
      // subclassId:null
      // securitySetId:null
      // smaLevel:null
    };
    data.forEach(element => {
      aggregateObject.currentInAmt += element.currentInAmt;
      aggregateObject.currentInPercent += element.currentInPercent;
      aggregateObject.targetInAmt += element.targetInAmt;
      aggregateObject.targetInPercent += element.targetInPercent;
      aggregateObject.pendingInAmt += (element.pendingInAmt) ? element.pendingInAmt : 0;
      aggregateObject.pendingInPercent += (element.pendingInPercent) ? element.pendingInPercent : 0;
      aggregateObject.modelElementId = element.modelElementId;
      aggregateObject.categoryId = element.categoryId;
      aggregateObject.classId = element.classId;
      //  aggregateObject.subclassId=element.subclassId;;
      // aggregateObject.securitySetId=element.securitySetId;
    });
    return aggregateObject;
  }

  setSMAObject(data) {
    const count = 0;
    const aggregateObject = {
      categoryName: 'SMA',
      className: 'SMA',
      subclassName: 'SMA',
      securitySetName: 'SMA_SecuritySet',
      currentInAmt: 0,
      currentInPercent: 0,
      targetInAmt: 0,
      targetInPercent: 0,
      pendingInAmt: 0,
      pendingInPercent: 0,
      modelElementId: null,
      categoryId: null,
      classId: null
    };

    aggregateObject.currentInAmt += data.currentInAmt;
    aggregateObject.currentInPercent += data.currentInPercent;
    aggregateObject.targetInAmt += data.targetInAmt;
    aggregateObject.targetInPercent += data.targetInPercent;
    aggregateObject.pendingInAmt += (data.pendingInAmt) ? data.pendingInAmt : 0;
    aggregateObject.pendingInPercent += (data.pendingInPercent) ? data.pendingInPercent : 0;
    aggregateObject.modelElementId = data.modelElementId;
    aggregateObject.categoryId = data.categoryId;
    aggregateObject.classId = data.classId;
    return aggregateObject;
  }

  aggregateCatSMA(arg: any, argg: any): any {
    arg.currentInAmt += argg.currentInAmt;
    arg.currentInPercent += argg.currentInPercent;
    arg.targetInAmt += argg.targetInAmt;
    arg.targetInPercent += argg.targetInPercent;
    arg.pendingInAmt += (argg.pendingInAmt) ? argg.pendingInAmt : 0;
    arg.pendingInPercent += (argg.pendingInPercent) ? argg.pendingInPercent : 0;
    return arg;
  }

  aggregateSMA(arg0: any, arg1: any): any {
    arg0.currentInAmt += arg1.currentInAmt;
    arg0.currentInPercent += arg1.currentInPercent;
    arg0.targetInAmt += arg1.targetInAmt;
    arg0.targetInPercent += arg1.targetInPercent;
    arg0.pendingInAmt += (arg1.pendingInAmt) ? arg1.pendingInAmt : 0;
    arg0.pendingInPercent += (arg1.pendingInPercent) ? arg1.pendingInPercent : 0;
    return arg0;
  }

  getPercentage(value: any, check: boolean): any {
    if (value) {
      value = value.toFixed(2);
      if (!check) {
        return `${value}%`;
      } else {
        value = `${value}`;
        return value.charAt(0) === '-' ?
          // eslint-disable-next-line prefer-template
          '(' + value.substring(1, value.length) + '%)' :
          `${value}%`;
      }
    } else {
      return '0.00%';
    }
  }

  resetData() {
    this.selectedTab = 'Security';
    this.totalAum = null;
    this.setAsideCash = null;
    this.reserveCash = null;
    this.setasideCashPercent = null;
    this.reserveCashPercent = null;
    this.modelPortfolioAllocations = [];
    this.modelSecurityPortfolioAllocations = [];
    this.modelCategoryPortfolioAllocations = [];
    this.modelSecuritySetPortfolioAllocations = [];
    this.modelSubClassPortfolioAllocations = [];
    this.modelClassPortfolioAllocations = [];
    this.removingCustodialCashSecurityInData(true);
    this.resetFloatingBottomRowData();
  }

  refreshData(event: IAnalyticsNotification): void {
    if (event.portfolioFlags && event.portfolioFlags.length > 0) {
      const portfolio = event.portfolioFlags.find(flag => flag.portfolioId === this.portfolioId);
      if (portfolio) {
        this.loadData();
      }
    }
  }
}
