import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { IAllocationInstruction, ICustodianTagInfo, IEditCustodian } from '../models/custodian';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustodianService {

  private _custodianEndPoint = 'v1/admin/custodians';
  private _custodianEndPointV2 = 'v2/admin/custodian';
  private _securityTypesEndPoint = 'v1/security/securities/securitytype';
  private _tradeExeTypesEndPoint = 'v1/tradeorder/trades/tradeExecutionType';
  private _allocationInstructionsEndPoint = 'v2/admin/custodian/getAllocationInstructions';
  private _algoDetailsEndPoint = `${this._custodianEndPointV2}/getCustodianAlgoTagInfoByIds`;

  constructor(private _httpClient: OEHttpClient) { }

  /** Get security types */
  getSecurityTypes() {
    return this._httpClient.getData(this._securityTypesEndPoint);
  }

  /** Get trade execution types */
  getTradeExecutionTypes() {
    return this._httpClient.getData(this._tradeExeTypesEndPoint);
  }

  getAllocationInstructions(): Observable<IAllocationInstruction[]> {
    return this._httpClient.getData(this._allocationInstructionsEndPoint);
  }

  getCustodianAlgoTagInfoByIds(custodianIds: number[]): Observable<ICustodianTagInfo[]> {
    return this._httpClient.postData(`${this._algoDetailsEndPoint}`, custodianIds);
  }

  /** Get custodians
   * simple: true- To fetch only custodian id and name details,
   * false-To fetch custodian details
   */
  getCustodians(simple: boolean= false) {
    if (!simple) {
      return this._httpClient.getData(this._custodianEndPoint);
    } else {
      return this._httpClient.getData(`${this._custodianEndPoint}/simple`);
    }
  }

  /** Get custodians fix type and optional security Id */
  getCustodiansFixType(securityId: number = -1) {
    let securityString: string = '';
    if (securityId !== -1) {
      securityString = `?securityId=${securityId}`;
    }
    return this._httpClient.getData(`${this._custodianEndPoint}/fixType${securityString}`);
  }

  /** Load custodian details by custodianId */
  getCustodianById(custodianId: number) {
    return this._httpClient.getData(`${this._custodianEndPoint}/${custodianId}`);
  }

  /** Delete custodian by id */
  deleteCustodian(custodianId: number) {
    return this._httpClient.deleteData(`${this._custodianEndPoint}/${custodianId}`);
  }

  /** Update custodian */
  updateCustodian(custodianId: number, custodian: any) {
    return this._httpClient.updateData(`${this._custodianEndPoint}/${custodianId}`, custodian);
  }

  /** Custodian search for auto complete */
  custodianSearch(searchString: string) {
    return this._httpClient.getData(`${this._custodianEndPoint}?search=${searchString}`);
  }

  /** Get custodian accounts */
  getCustodianAccounts(custodianId: number) {
    return this._httpClient.getData(`${this._custodianEndPoint}/${custodianId}/accounts`);
  }

  searchCustodian(searchString: string) {
    return this._httpClient.getData(`${this._custodianEndPoint}?search=${searchString}`);
  }

  /** Get custodian summary */
  getCustodianSummary() {
    return this._httpClient.getData(`${this._custodianEndPoint}/summary`);
  }

  /** SF-115375 Load custodian algo tag info by custodianId */
  getCustodianAlgoTagInfoById(custodianId: number) {
    return this._httpClient.getData(`${this._custodianEndPoint}/getCustodianAlgoTagInfo/${custodianId}`);
  }

  /** SF-115375 Save or Update custodian Algo tag info*/
  saveCustodianAlgoTagInfo(custodianTagInfo: ICustodianTagInfo[], setAutomaticAlgo: boolean, custodianId: number) {
    return this._httpClient.postData(`${this._custodianEndPoint}/saveCustodianAlgoTagInfo`, {custodianTagInfo: custodianTagInfo, setAutomaticAlgo: setAutomaticAlgo, id: custodianId});
  }

  createCustodian(custodian: IEditCustodian): Observable<any> {
    return this._httpClient.postData(`${this._custodianEndPointV2}`, custodian);
  }
}
