import { Component, Input } from '@angular/core';
import {
  IMoneyMarketAllocationPreference,
  IMoneyMarketFundPreference
} from '../../../../models/preferences/moneyMarketPreference';

@Component({
  selector: 'eclipse-inherited-preference',
  templateUrl: './inherited-preference.component.html',
  styleUrls: ['./inherited-preference.component.scss']
})
export class InheritedPreferenceComponent {

  @Input() headerName: string;
  @Input() componentType: string;
  @Input() moneyMarketAllocationPreference: IMoneyMarketAllocationPreference;
  @Input() moneyMarketFundPreference: IMoneyMarketFundPreference;
  isShowInheritedPopup: boolean;
}
