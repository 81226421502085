import {Injectable} from '@angular/core';
import {OEHttpClient} from '../core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  private _importEndPoint = 'v2/Import';
  private _echoFileEndpoint = `${this._importEndPoint}/Echo`;

  constructor(private _httpClient: OEHttpClient) {
  }

  /** To add View */
  echoFile(file: any): Observable<any> {
    const formData: any = new FormData();
    formData.append('document', file);
    return this._httpClient.uploadFile(this._echoFileEndpoint, formData);
  }
}
