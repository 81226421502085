import {Injectable} from '@angular/core';
import {
  IDayOfWeekSummary,
  ISetAsideDCAFrequency,
  SetAsideCashDCAPeriodType, SetAsideDCAIntervalType
} from '../../models/account';
import { Utils as Util } from '../../core/functions';

@Injectable()
export class SetAsideCashService {

  public static readonly PeriodTypes = [
    {name: 'Day(s)', value: SetAsideCashDCAPeriodType.Daily},
    {name: 'Week(s)', value: SetAsideCashDCAPeriodType.Weekly},
    {name: 'Month(s)', value: SetAsideCashDCAPeriodType.Monthly},
    {name: 'Year(s)', value: SetAsideCashDCAPeriodType.Annual},
  ];

  public static readonly WeekDays = [
    {
      name: 'Sunday',
      label: 'S',
      value: 0
    },
    {
      name: 'Monday',
      label: 'M',
      value: 1
    },
    {
      name: 'Tuesday',
      label: 'T',
      value: 2
    },
    {
      name: 'Wednesday',
      label: 'W',
      value: 3
    },
    {
      name: 'Thursday',
      label: 'T',
      value: 4
    },
    {
      name: 'Friday',
      label: 'F',
      value: 5
    },
    {
      name: 'Saturday',
      label: 'S',
      value: 6
    }
  ];

  constructor() {
  }

  /**
   * Calculates the number of periods to achieve the a total DCA amount.
   * @param cumulativeAmount
   * @param installmentAmount
   */
  getPeriods(cumulativeAmount: number, installmentAmount: number): number {
    if (installmentAmount <= 0) {
      return null;
    }
    // round up because periods are whole numbers:
    // 500 / 200 = 2 periods of 200 and 1 period of 100
    const periods = cumulativeAmount / installmentAmount;
    return Math.ceil(periods);
  }

  /**
   * Calculates the amount needed per period to achieve the cumulative amount.
   * @param cumulativeAmount
   * @param periods
   */
  getInstallmentAmount(cumulativeAmount: number, periods: number): number {
    if (periods <= 0) {
      return null;
    }
    return Util.roundDecimal(cumulativeAmount / periods, 2);
  }

  /**
   * Calculates the cumulative amount, given the number of periods and per-period amount.
   * @param installmentAmount
   * @param periods
   */
  getCumulativeAmount(installmentAmount: number, periods: number): number {
    return Util.roundDecimal(installmentAmount * periods, 2);
  }

  /**
   * Returns a number with its ordinal name
   * Ex.  1:  1st.  2:  2nd.  234:  234th.
   * @param number
   */
  getNumberWithOrdinal(number: number): string {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const value = number % 100;
    return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  }

  /**
   * Creates a date schedule for DCA set aside transactions.
   * @param trancheCount
   * @param frequency
   */
  createSetAsideCashSchedule(trancheCount: number, frequency: ISetAsideDCAFrequency): Date[] {
    switch (frequency.period) {
      case SetAsideCashDCAPeriodType.Daily:
        return this.createDailySchedule(frequency.start, frequency.every, trancheCount);
      case SetAsideCashDCAPeriodType.Weekly:
        return this.createWeeklySchedule(frequency.start, frequency.on.day, frequency.every, trancheCount);
      case SetAsideCashDCAPeriodType.Monthly:
        return this.createMonthlySchedule(frequency.start, frequency.on.day, frequency.on.interval, frequency.every, trancheCount);
      case SetAsideCashDCAPeriodType.Annual:
        return this.createAnnualSchedule(frequency.start, frequency.on.day, frequency.on.interval, frequency.every, trancheCount);
      default:
        break;
    }
  }

  /**
   * Given a DCA date schedule, installment amounts are applied to each date with the remaining amount (if any) applied to the last period.
   * @param schedule
   * @param installmentAmount
   * @param cumulativeAmount
   */
  applyInstallmentsToSchedule(schedule: Date[], installmentAmount: number, cumulativeAmount: number): { date: Date, amount: number }[] {
    let total = cumulativeAmount;
    let currentAmount = installmentAmount;
    return schedule.map(scheduleDate => {
      // if the remaining total is less than the installment amount, then only apply the remaining total
      if (total - installmentAmount < 0) {
        currentAmount = total;
      }
      currentAmount = Util.roundDecimal(currentAmount, 2);
      total -= currentAmount;
      return {
        date: scheduleDate,
        amount: currentAmount
      };
    });
  }

  /**
   * Creates a DCA schedule based on a daily period.
   * @param start date of the first expiration
   * @param offset how many days between expiration dates
   * @param trancheCount number of tranches to create
   */
  createDailySchedule(start: Date, offset: number, trancheCount: number): Date[] {
    let currentDate = start;
    const result = [currentDate];
    for (let i = 1; i < trancheCount; i++) {
      currentDate = this.addDays(currentDate, offset);
      result.push(currentDate);
    }
    return result;
  }

  /**
   * Creates a DCA schedule based on a weekly period.
   * @param start date of the first expiration
   * @param dayOfWeek day of the week (M/T/W/etc) to create as the expiration
   * @param every how many weeks between expiration dates
   * @param trancheCount number of tranches to create
   */
  createWeeklySchedule(start: Date, dayOfWeek: number, every: number, trancheCount: number): Date[] {
    const result = [start];
    // get the first expiration date after the start date by adding 7 days to the start date and then finding
    // the next specified day of the week.
    let currentDate = this.getNextDayOfWeek(this.addDays(start, 7 * every), dayOfWeek);
    for (let i = 1; i < trancheCount; i++) {
      result.push(currentDate);
      currentDate = this.addDays(currentDate, 7 * every);
    }
    return result;
  }

  /**
   * Creates a DCA schedule based on a monthly period.
   * @param start date of the first expiration
   * @param day exact day (11th, 21st, etc), or day of the week (1=Monday, 2=Tuesday, etc)
   * @param interval method for determining date (exact, dynamic)
   * @param every number of months away from the previous expiration date.
   * @param trancheCount number of tranches to create
   */
  createMonthlySchedule(start: Date, day: number, interval: SetAsideDCAIntervalType, every: number, trancheCount: number): Date[] {
    const result = [start];
    for (let i = 1; i < trancheCount; i++) {
      let currentDate = this.getNextMonthDay(start, interval, every * i, day);
      result.push(currentDate);
    }
    return result;
  }

  /**
   * Creates a DCA schedule based on an annual period.
   * @param start date of the first expiration
   * @param day exact day (11th, 21st, etc), or day of the week (1=Monday, 2=Tuesday, etc)
   * @param interval method for determining date (exact, dynamic)
   * @param every number of years away from the previous expiration date.
   * @param trancheCount number of tranches to create
   */
  createAnnualSchedule(start: Date, day: number, interval: SetAsideDCAIntervalType, every: number, trancheCount: number): Date[] {
    const result = [start];
    let currentDate = this.getNextYearDay(start, interval, every, day);
    for (let i = 1; i < trancheCount; i++) {
      result.push(currentDate);
      currentDate = this.addYears(start, every * i);
      currentDate = this.getNextYearDay(currentDate, interval, every, day);
    }
    return result;
  }

  /**
   * Returns the name of the month for a date.
   * Ex.  1/1/2020:  January
   * @param date
   */
  getMonthName(date: Date): string {
    const formatter = new Intl.DateTimeFormat('en-us', {month: 'long'});
    return formatter.format(date);
  }

  /**
   * Returns summary information about a specific date, such as name, name with ordinal, month name, etc.
   * @param date
   */
  getDayOfWeekSummary(date: Date): IDayOfWeekSummary {
    const dayOfWeek = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    const testDate = new Date(year, month, date.getDate());
    const currDate = new Date(year, month, 1);
    const result: IDayOfWeekSummary = {
      rank: 0,
      rankName: '',
      total: 0,
      day: date.getDate(),
      dayName: this.getDayOfWeekName(date),
      dayOfWeek: dayOfWeek,
      monthName: this.getMonthName(date),
      ordinal: this.getNumberWithOrdinal(date.getDate())
    };

    // for the date's day of the week, find the total number of those days of the week in the month
    while (currDate.getMonth() === month) {
      if (currDate.getDay() === dayOfWeek) {
        result.total++;
        if (testDate.valueOf() === currDate.valueOf()) {
          result.rank = result.total;
          result.rankName = this.getRankOrdinal(result.rank);
        }
      }
      currDate.setDate(currDate.getDate() + 1);
    }

    return result;
  }

  /**
   * Returns the ordinal name for a number.
   * Ex.  1:  First.  2:  Second.
   * @param rank
   */
  getRankOrdinal(rank: number): string {
    switch (rank) {
      case 1:
        return 'First';
      case 2:
        return 'Second';
      case 3:
        return 'Third';
      case 4:
        return 'Fourth';
      default:
        return 'Last';
    }
  }

  /**
   * Returns the name of a day of week.
   * Ex.  1/1/2020: Wednesday
   * @param date
   */
  getDayOfWeekName(date): string {
    const dow = date.getDay();
    return SetAsideCashService.WeekDays.find(d => d.value === dow).name;
  }

  /**
   * Adds a specified number of days to a date and returns the new date.
   * Ex.  1/1/2020 + 5 days:  1/6/2020
   * @param startDate
   * @param days
   */
  addDays(startDate, days): Date {
    const date = new Date(startDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  /**
   * Adds a specified number of months to a date and returns the new date.
   * Ex.  1/1/2020 + 5 months:  6/1/2020
   * @param startDate
   * @param months
   */
  addMonths(startDate: Date, months: number): Date {
    const date = new Date(startDate.valueOf());
    const day = date.getDate();
    date.setMonth(date.getMonth() + months);
    // if the new date does not match the input date, reset the date to the first of the month.
    if (date.getDate() !== day) {
      date.setDate(0);
    }
    return date;
  }

  addYears(startDate: Date, years: number): Date {
    const date = new Date(startDate.valueOf());
    const day = date.getDate();
    date.setFullYear(date.getFullYear() + years);
    // if the day doesn't exist in the month, set the date to the last day in the month.
    // ex.  1/31/2020 + 1 month:  2/31/2020 (day doesn't exist, so set to 2/29/2020).
    if (date.getDate() !== day) {
      date.setDate(0); // setDate(0) sets date to last day in month
    }
    return date;
  }

  /**
   * Returns the next occurrence of a specific day of the week after a starting date.
   * Ex. 11/18/2020 is a Wednesday.
   * Finding the next Friday would yield 11/20/2020.
   * Finding the next Wednesday would yield 11/25/2020
   * @param date starting date from which to determine the next day of the week
   * @param dayOfWeek the day of the week (1=Monday, 2=Tuesday...) to find
   * @param dateMustChange indicates if the value -must- be different than the `date` param.
   */
  getNextDayOfWeek(date: Date, dayOfWeek: number, dateMustChange: boolean = false): Date {
    const dt = new Date(date.valueOf());
    dt.setDate(date.getDate() + (dayOfWeek + (7 - date.getDay())) % 7);
    // if the calculated date must change but it hasn't yet, add a week to the date
    if (dateMustChange && dt <= date) {
      dt.setDate(dt.getDate() + 7);
    }
    return dt;
  }

  /**
   * Returns the next occurrence of a day, X months from the date.
   * Ex.  11th day, 4 months from 7/4/2020 = 11/11/2020.
   * Ex.  Third Wednesday, 4 months from 7/15/2020 = 11/18/2020.
   * @param date starting date
   * @param interval method for determining date.
   *    0 = Exact (ex: day=11 will always find the 11th of the month)
   *    1-4 = Dynamic (ex. day=2 will always find the first, second, third, or fourth Tuesday of the month)
   *    5 = Dynamic (ex. day=2 will always find the last Tuesday of the month)
   * @param every number of months away from the starting date.
   * @param day exact day (11th, 21st, etc), or day of the week (1=Monday, 2=Tuesday, etc)
   */
  getNextMonthDay(date: Date, interval: SetAsideDCAIntervalType, every: number, day: number): Date {
    let dt = new Date(date.valueOf());
    dt = this.addMonths(dt, every);
    if (interval !== SetAsideDCAIntervalType.Exact) {
      // start at the first of the month
      dt.setDate(1);
      // use the interval to determine how many times to find the next occurrence of the day
      for (let i = 0; i < interval; i++) {
        let nextDate = dt;
        // if on the first interval and it matches the target day, then do nothing.
        // otherwise find the next day of the week in this month.
        if (!(dt.getDay() === day && i === 0)) {
          nextDate = this.getNextDayOfWeek(dt, day, true);
        }
        // if finding the last instance of the day in a month and we've switched months, then we've found the date
        if (interval === SetAsideDCAIntervalType.Last && nextDate.getMonth() !== dt.getMonth()) {
          return dt;
        }
        // otherwise we're still looking for the right date, find the next instance of the day in the month
        dt = nextDate;
      }
    }
    return dt;
  }

  /**
   * Returns the next occurrence of a day, X years from the date.
   * Ex.  11th day of July, 4 years from 7/11/2020 = 7/11/2024.
   * Ex.  Third Wednesday of July, 4 years from 7/15/2020 = 7/13/2024.
   * @param date starting date
   * @param interval method for determining date.
   *    0 = Exact (ex: day=11 will always find the 11th of the month)
   *    1-4 = Dynamic (ex. day=2 will always find the first, second, third, or fourth Tuesday of the month)
   *    5 = Dynamic (ex. day=2 will always find the last Tuesday of the month)
   * @param every number of years away from the starting date.
   * @param day exact day (11th, 21st, etc), or day of the week (1=Monday, 2=Tuesday, etc)
   */
  getNextYearDay(date: Date, interval: SetAsideDCAIntervalType, every: number, day: number): Date {
    let dt = new Date(date.valueOf());
    // start by adding X years to the input date
    dt = this.addYears(dt, every);

    if (interval !== SetAsideDCAIntervalType.Exact) {
      // use the interval to determine how many times to find the next occurrence of the day
      for (let i = 0; i < interval; i++) {
        // get the next occurrence of the day
        const nextDate = this.getNextMonthDay(dt, interval, 0, day);
        // if finding the last instance of a day and the years don't match, then we've found the target date
        if (interval === SetAsideDCAIntervalType.Last && nextDate.getFullYear() !== dt.getFullYear()) {
          return dt;
        }
        // otherwise we're still looking for the right date, find the next instance of the day in the month
        dt = nextDate;
      }
    }
    return dt;
  }
}
