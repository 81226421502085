import {Subject } from 'rxjs';

export class RequestEventEmitter extends Subject<String> {
  constructor() {
    super();
  }
  emit(value) { super.next(value); }
}

export class ResponseEventEmitter extends Subject<String> {
  constructor() {
    super();
  }
  emit(value) { super.next(value); }
}

export class NotifyEventEmitter extends Subject<String> {
  constructor() {
    super();
  }
  emit(value) { super.next(value); }
}

export class AlertEventEmitter extends Subject<String> {
  constructor() {
    super();
  }
  emit(value) { super.next(value); }
}

export class ErrorNotificationEventEmitter extends Subject<any> {
  constructor() {
    super();
  }
  emit(value) { super.next(value); }
}

export class MacFundTradeEventEmitter extends Subject<String> {
  constructor() {
    super();
  }
  emit(value) { super.next(value); }
}
