import {Injectable} from '@angular/core';
import {
  ICashProtection,
  IContributionSleeveTrade,
  IGenerateRebalance,
  IGenerateTrades,
  IModelTypes,
  ISellSecurity, ITaxHarvestingFilters,
  IGlobalTradeTaxOption,
  ITradeGroupsAccount,
  ITradeGroupsPortfolio,
  ITradeToolsFilter,
  ITradeTraget,
  OptionFilterType,
  TradeToolSelectionMethod,
  ILiquidateRequestModel,
  ILiquidateResult
} from '../models/tradetools';
import {OEHttpClient} from '../core';
import {IRaiseFullAmount} from '../models/raisecash';
import {Observable} from 'rxjs';
import {IRebalanceDropDownOption} from '../models/rebalance';
import {maximumCapitalGainsAllowedOption as MAX_CAPITAL_GAIN} from '../libs/rebalancer.constants';

@Injectable({
  providedIn: 'root'
})
export class TradeToolsService {

  constructor(private _httpClient: OEHttpClient) {}
  private _tradeToolsEndpoint = 'v1/tradetool';
  private _cashNeedGenerateTradeEndPoint = `${this._tradeToolsEndpoint}/cashneeds/action/generatetrade`;
  private _createTLHTradesEndPoint = `${this._tradeToolsEndpoint}/taxLossHarvesting/action/createTrade`;
  private _tlhEndpoints = `${this._tradeToolsEndpoint}/taxLossHarvesting`;
  private _rebalanceEndPoint = `${this._tradeToolsEndpoint}/rebalancer`;
  private _calculateContributionsForSleeves = 'v2/tradetool/calculateContributionsForSleeves'; // v2
  private _tradeGroups = 'v1/tradeGroup/tradeGroups';
  private _tradeGroupAccounts = 'v1/querybuilder/queries/';
  private _validateTLHTradesEndPoint = '/taxLossHarvesting/action/validateTLHSecurities';
  private _validateTHTradesEndPoint = '/taxLossHarvesting/action/validateBuyPreferredTHSecurities';
  private _tradeGroupPortfolioFlags = 'v1/querybuilder/queries/portfolioFlags/';
  private _getTradeToolEndPoint = 'v2/tradetool';
  private _liquidateEndpoint = `${this._tradeToolsEndpoint}/liquidate/generatetrade`;

  tradeTools: ITradeToolsFilter[] = [];
  sellSecuritiesList: ISellSecurity[] = [];
  tradeGroupsPortfolios: ITradeGroupsPortfolio[] = [];
  tradeGroupsAccounts: ITradeGroupsAccount[] = [];

  private _tradeOrderEndpoint = 'v2/tradeorder';

  /** TRADE TOOL: SPEND CASH */
  private _spendcashEndpoint = 'tradetool/spendcash';
  private _raisecashEndpoint = 'tradetool/raisecash';
  private _modelsEndpoint = 'modeling/models';
  private _accountsEndpoint = 'account/accounts';
  private _feeImportEndpoint = 'action/feeliquidation';

  private raiseFullAmount: IRaiseFullAmount[] = [];

  calculateContributionsForSleeves(): Observable<Array<IContributionSleeveTrade>> {
    return this._httpClient.getData(this._calculateContributionsForSleeves);
  }

  /** Upload selected file */
  uploadFileTemplate(file, isSleevePortfolio: boolean) {
    const formData: any = new FormData();
    formData.append('document', file);
    return (isSleevePortfolio) ? this._httpClient.uploadFile(`${this._tradeToolsEndpoint}/uploadfile?isSleeve=true&includeDetails=true`, formData) :
      this._httpClient.uploadFile(`${this._tradeToolsEndpoint}/uploadfile`, formData);
  }

  /* Upload selected file for Trade Tool Rebalancer */
  sleeveuploadFileTemplate(file) {
    const formData: any = new FormData();
    formData.append('document', file);
    return this._httpClient.uploadFile(`${this._tradeToolsEndpoint}/uploadfile?includeDetails=true`, formData);
  }

  /**Upload selected file for Trade Tool Fee Import */
  feeImportUploadFileTemplate(file) {
    const formData: any = new FormData();
    formData.append('document', file);
    return this._httpClient.uploadFile(`${this._tradeOrderEndpoint}/${this._feeImportEndpoint}/validate`, formData);
  }

  generateFeeImportTrades(batchId: number) {
    return this._httpClient.postData(`${this._tradeOrderEndpoint}/${this._feeImportEndpoint}/generate/${batchId.toString()}`, null);
  }

  /** To generate trades */
  generateTrades(generateTrades: any) {
    return this._httpClient.postData(`${this._tradeToolsEndpoint}/globaltrades/action/generateTrade`, generateTrades);
  }

  /**To get static trade tools data */
  getTradeToolsData() {
    this.tradeTools.push(<ITradeToolsFilter>{ id: 1, name: 'Steve and Smit', tag: 'Steve' });
    this.tradeTools.push(<ITradeToolsFilter>{ id: 2, name: 'Steve and Smit 2', tag: 'Steve' });
    this.tradeTools.push(<ITradeToolsFilter>{ id: 3, name: 'Steve and Smit 3', tag: 'Steve' });
    return this.tradeTools;
  }

  /** Test Method: To get sell securities */
  getSellSecurities() {
    this.sellSecuritiesList.push(<ISellSecurity>{ id: 1, name: 'test sell security 1' });
    this.sellSecuritiesList.push(<ISellSecurity>{ id: 2, name: 'test sell security 2' });
    this.sellSecuritiesList.push(<ISellSecurity>{ id: 3, name: 'test sell security 3' });
    this.sellSecuritiesList.push(<ISellSecurity>{ id: 4, name: 'test sell security 4' });
    this.sellSecuritiesList.push(<ISellSecurity>{ id: 5, name: 'sell security 5' });
    return this.sellSecuritiesList;
  }

  /** Get static trade groups portfolios to bind auto complete*/
  getTradeGroupsPortfolios() {
    this.tradeGroupsPortfolios.push(<ITradeGroupsPortfolio>{ id: 1, name: 'TradeGroupsPortfolio 1' });
    this.tradeGroupsPortfolios.push(<ITradeGroupsPortfolio>{ id: 2, name: 'TradeGroupsPortfolio 2' });
    this.tradeGroupsPortfolios.push(<ITradeGroupsPortfolio>{ id: 3, name: 'TradeGroupsPortfolio 3' });
    this.tradeGroupsPortfolios.push(<ITradeGroupsPortfolio>{ id: 4, name: 'TradeGroupsPortfolio 4' });
    return this.tradeGroupsPortfolios;
  }

  /** Get static trade groups accounts to bind auto complete*/
  getTradeGroupsAccounts() {
    this.tradeGroupsAccounts.push(<ITradeGroupsAccount>{ id: 1, name: 'TradeGroupsAccount 1' });
    this.tradeGroupsAccounts.push(<ITradeGroupsAccount>{ id: 2, name: 'TradeGroupsAccount 2' });
    this.tradeGroupsAccounts.push(<ITradeGroupsAccount>{ id: 3, name: 'TradeGroupsAccount 3' });
    this.tradeGroupsAccounts.push(<ITradeGroupsAccount>{ id: 4, name: 'TradeGroupsAccount 4' });
    this.tradeGroupsAccounts.push(<ITradeGroupsAccount>{ id: 5, name: 'TradeGroupsAccount 5' });
    return this.tradeGroupsAccounts;
  }

  CashNeedGenerateTrade(generateTrades: IGenerateTrades) {
    return this._httpClient.postData(this._cashNeedGenerateTradeEndPoint, generateTrades);
  }


  /** To get spendcash calculation methods*/
  getSpendCashCalculationMethods() {
    return this._httpClient.getData(`${this._spendcashEndpoint}/calculation_methods`);
  }

  /** To get raisecash calculation methods*/
  getRaiseCashCalculationMethods() {
    return this._httpClient.getData(`${this._raisecashEndpoint}/calculation_methods`);
  }

  /** To get Tradeside  */
  getTradeSide() {
    return this._httpClient.getData(`${this._tradeToolsEndpoint}/tradeside`);
  }

  /** To get ShortTermGain */
  getShortTermGain() {
    return this._httpClient.getData(`${this._tradeToolsEndpoint}/allowshorttermgains`);
  }

  /** Get list for allow Wash Sales */
  getWashSales() {
    return this._httpClient.getData(`${this._tradeToolsEndpoint}/allowwashsales`);
  }

  /** To Calculate TradeToTraget */
  calculateTarget(tradeTarget: ITradeTraget) {
    return this._httpClient.postData(`${this._tradeToolsEndpoint}/tradetotarget/action/generateTrade`, tradeTarget);
  }
  /** To get Spend full amount options */
  getTradeToolsFullCashOptions() {
    this.raiseFullAmount = [];
    this.raiseFullAmount.push(<IRaiseFullAmount>{ id: 1, name: 'Use Default' });
    this.raiseFullAmount.push(<IRaiseFullAmount>{ id: 2, name: 'Yes' });
    this.raiseFullAmount.push(<IRaiseFullAmount>{ id: 3, name: 'No' });
    return this.raiseFullAmount;
  }
  /**Create TLH trades */
  createTrades(tradeFilter) {
    return this._httpClient.postData(this._createTLHTradesEndPoint, tradeFilter);
  }

  /**get sign options master data */
  getSignOptions() {
    return this._httpClient.getData(`${this._tlhEndpoints}/sign/options`);
  }
  /**get gain loss options master data */
  getGainLossOptions() {
    return this._httpClient.getData(`${this._tlhEndpoints}/gainloss/options`);
  }
  /**get term options master data */
  getTermOptions() {
    return this._httpClient.getData(`${this._tlhEndpoints}/term/options`);
  }


  /**getting trade groups for models */
  getTrades(id) {
    return this._httpClient.getData(`${this._modelsEndpoint}/${id}/portfolios?includeaccountcount=true`);
  }

  /**Gets a count of accounts in a portfolio per each of the Account Ids that's passed to it */
  getAccountCountPerPortfolio(idListObj) {
    return this._httpClient.postData(`${this._accountsEndpoint}/portfolioAccountCount`, idListObj);
  }

  /**getting trade groups */
  getAccountsUsingListOfPortfolioIds(idListObj) {
    return this._httpClient.postData(`${this._tradeGroups}/accountsInPortfolioList`, idListObj);
  }
  /**Getting Rounding For Rebalance */
  getRebalanceWashSales() {
    return this._httpClient.getData(`${this._tradeToolsEndpoint}/allowwashsales`);
  }

  getRebalanceWashGains() {
    return this._httpClient.getData(`${this._tradeToolsEndpoint}/allowshorttermgains`);
  }

  /**Getting Priority Ranking */
  getPriority() {
    return this._httpClient.getData('tradetool/priorityrankings');
  }

  calculateRebalancer(rebalancer: IGenerateRebalance) {
    return this._httpClient.postData(`${this._rebalanceEndPoint}/action/generatetrade`, rebalancer);
  }

  /**search TLH alternative securities */
  searchTlhSecurities(tlsSearch) {
    return this._httpClient.postData(`${this._tlhEndpoints}/action/searchTLHsecurity`, tlsSearch);
  }
  /**getting amount based  Gain Loss selling certain percentage */
  checkGainLossAmount(gainLossParameters) {
    return this._httpClient.postData(`${this._tlhEndpoints}/action/checkGainLoss`, gainLossParameters);
  }
  /**Tlh Genearte Trades */
  generateTlhTrades(generateTradesParams) {
    return this._httpClient.postData(`${this._tlhEndpoints}/action/generateTrade`, generateTradesParams);
  }
  /**Get Account info based on portfolioIds */
  getTlhTradeInfoByPortfolioIds(portfolioIds) {
    return this._httpClient.postData(`${this._tlhEndpoints}/action/createTLHTrade`, portfolioIds);
  }

  /** Get Trade group accounts based on trade group number */
  /**Param added excludeNeedAnalytics to check for need analytics*/
  getTradeGroupAccounts(id: number) {
    return this._httpClient.getData(`${this._tradeGroupAccounts + id  }/accountdetails?excludeNeedAnalytics=true`);
  }

  getTradeGroupPortfolioFlags(id: number) {
    return this._httpClient.getData(`${this._tradeGroupPortfolioFlags + id}/execute`);
  }

  validateTLHTrades(trades) {
    return this._httpClient.postData(this._tradeToolsEndpoint + this._validateTLHTradesEndPoint, trades);
  }
  // Validate_Trades_for_TH
  validateTHTrades(trades) {
    return this._httpClient.postData(this._tradeToolsEndpoint + this._validateTHTradesEndPoint, trades);
  }

  /**Get model types on the basis of models or portfolios, or accounts */
  getModelTypesByIds(filter: any): Observable<IModelTypes[]> {
    return this._httpClient.postData('modeling/models/assignedSubmodels', filter);
  }

  /**Getting Tactical Rebalance Cash Protection */
  getRebalanceCashProtection() {
    return this._httpClient.getData('tradetool/tacticalRebalanceCashProtection');
  }

  /**Get Tax Harvesting Securities based on portfolio or account ids */
  getSecurities(ids: number[], type: string) {
    return this._httpClient.postData(`${this._tlhEndpoints}/securities`, {ids: ids, type: type});
  }

  /**Get Tax Harvesting Opportunity based on portfolio, account or model ids */
  getTLHOpportunity(ids: number[], type: string) {
    return this._httpClient.postData(`${this._getTradeToolEndPoint}/TlhOpportunityFlag`, {ids: ids, type: type});
  }
  /**Get Standard Portfolio Rebalance Target options */
  getRebalanceTargetOption(): IRebalanceDropDownOption[] {
    const rebalanceTargetOption = [];
    rebalanceTargetOption.push({id: 0, name: 'Default', value: null});
    rebalanceTargetOption.push({id: 1, name: 'Target - Include Tolerance Bands', value: 'Target - Include Tolerance Bands'});
    rebalanceTargetOption.push({id: 2, name: 'Target - Ignore Tolerance Bands', value: 'Target - Ignore Tolerance Bands'});
    rebalanceTargetOption.push({id: 3, name: 'Model Tolerance', value: 'Model Tolerance'});
    rebalanceTargetOption.push({id: 4, name: 'Trade Tolerance', value: 'Trade Tolerance'});
    return rebalanceTargetOption;
  }

  /**Get Sleeve Standard Portfolio Rebalance Target options */
  getSleevePortfolioRebalanceTargetOption(): IRebalanceDropDownOption[] {
    const sleevePortfolioRebalanceTargetOption = [];
    sleevePortfolioRebalanceTargetOption.push({id: 0, name: 'Default', value: null});
    sleevePortfolioRebalanceTargetOption.push({id: 1, name: 'Target - Default', value: 'Target - Default'});
    sleevePortfolioRebalanceTargetOption.push({id: 2, name: 'Target - Ignore Tolerance Bands', value: 'Target - Ignore Tolerance Bands'});
    sleevePortfolioRebalanceTargetOption.push({id: 3, name: 'Target - Include Tolerance Bands', value: 'Target - Include Tolerance Bands'});
    sleevePortfolioRebalanceTargetOption.push({id: 4, name: 'Trade Tolerance', value: 'Trade Tolerance'});
    sleevePortfolioRebalanceTargetOption.push({id: 5, name: 'Upper/Lower Tolerance', value: 'Upper/Lower Tolerance'});
    return sleevePortfolioRebalanceTargetOption;
  }

  /**Get Individual Sleeve Rebalance Method options */
  getIndividualSleeveRebalanceMethodOption(): IRebalanceDropDownOption[] {
    const individualSleeveRebalanceMethodOption = [];
    individualSleeveRebalanceMethodOption.push({id: 0, name: 'Default', value: null});
    individualSleeveRebalanceMethodOption.push({id: 1, name: 'Rebalance Every Sleeve', value: 'Rebalance Every Sleeve'});
    individualSleeveRebalanceMethodOption.push({id: 2, name: 'Rebalance Only Sleeves With Journals', value: 'Rebalance Only Sleeves With Journals'});
    return individualSleeveRebalanceMethodOption;
  }

  /**Get Sleeve Portfolio Rebalance Level options */
  getSleevePortfolioRebalanceLevelOption(): IRebalanceDropDownOption[] {
    const sleevePortfolioRebalanceLevelOption = [];
    sleevePortfolioRebalanceLevelOption.push({id: 0, name: 'Default', value: null});
    sleevePortfolioRebalanceLevelOption.push({id: 1, name: 'Sleeve Strategy', value: 'Sleeve Strategy'});
    sleevePortfolioRebalanceLevelOption.push({id: 2, name: 'Sleeve Model', value: 'Sleeve Model'});
    return sleevePortfolioRebalanceLevelOption;
  }

  getMaximumCapitalGainsAllowedOption(): IRebalanceDropDownOption[] {
    const maximumCapitalGainsAllowedOption = [];
    maximumCapitalGainsAllowedOption.push({id: MAX_CAPITAL_GAIN.DEFAULT, name: null, displayName: 'Default'});
    maximumCapitalGainsAllowedOption.push({id: MAX_CAPITAL_GAIN.NO_LIMIT, name: 'No Limit', displayName: 'No Limit'});
    maximumCapitalGainsAllowedOption.push({
      id: MAX_CAPITAL_GAIN.PORTFOLIO_MAX_GAIN,
      name: 'Use Portfolio Max Gain Amount',
      displayName: 'Use Portfolio Max Gain Amount'
    });
    maximumCapitalGainsAllowedOption.push({
      id: MAX_CAPITAL_GAIN.MAX_GAIN_AMOUNT,
      name: 'Max Gain Amount',
      displayName: 'Set One Time Max Gain Amount'
    });
    return maximumCapitalGainsAllowedOption;
  }

  getIncludeTargetCashOption(): IRebalanceDropDownOption[] {
    const IncludeTargetCashOption = [];
    IncludeTargetCashOption.push({ id: 0, name: 'Default', value: null });
    IncludeTargetCashOption.push({ id: 1, name: 'True', value: true });
    IncludeTargetCashOption.push({ id: 2, name: 'False', value: false });
    return IncludeTargetCashOption;
  }

  getTradeToolSelectionMethod(tradeFilterMethod: string): TradeToolSelectionMethod {
    switch (tradeFilterMethod) {
      case 'portfolio':
        return TradeToolSelectionMethod.Portfolio;
      case 'account':
      case 'Accounts':
        return TradeToolSelectionMethod.Account;
      case 'model':
        return TradeToolSelectionMethod.Model;
      case 'tradeGroups':
      case 'tradeGroupsForAccount':
        return TradeToolSelectionMethod.AccountTradeGroup;
      case 'excelImport':
        return TradeToolSelectionMethod.ExcelImport;
      case 'tradeGroupsForPortfolio':
        return TradeToolSelectionMethod.PortfolioTradeGroup;
      case 'IndividualSleeve':
      case 'individualSleeve':
        return TradeToolSelectionMethod.IndividualSleeve;
      case 'sleevePortfolio':
      case 'SleevePortfolio':
        return TradeToolSelectionMethod.SleevePortfolio;
      case 'tactical':
        return TradeToolSelectionMethod.TacticalTool;
      default:
        return null;
    }
  }

  getTradeToolOption(filterType) {
    switch (filterType) {
      case 'sleevePortfolio':
        return OptionFilterType.SleevePortfolio;
      case 'account':
      case 'Accounts':
        return OptionFilterType.Account;
      case 'excelImport':
        return OptionFilterType.Import;
      case 'tradeGroupsForAccount':
      case 'tradeGroups':
        return OptionFilterType.TradeGroup;
      default:
        return null;
    }
  }

   /* Get Rebalance Level Setting options */
   getRebalanceLevelOption(): IRebalanceDropDownOption[] {
    const rebalanceLevelOption = [];
    rebalanceLevelOption.push({id: 0, name: 'Default', value: null});
    rebalanceLevelOption.push({id: 1, name: 'Level 1', value: 'Level 1'});
    rebalanceLevelOption.push({id: 2, name: 'Level 2', value: 'Level 2'});
    rebalanceLevelOption.push({id: 3, name: 'Level 3', value: 'Level 3'});
    rebalanceLevelOption.push({id: 4, name: 'Level 4', value: 'Level 4'});
    rebalanceLevelOption.push({id: 5, name: 'Security Level', value: 'Security Level'});
    return rebalanceLevelOption;
  }

  createTLHTradeBatchId(filters: ITaxHarvestingFilters): Observable<string> {
    return this._httpClient.postData(`${this._tlhEndpoints}/action/createTLHTradeBatchId`, filters);
  }

  /* Get cash protection options */
  getCashProtectionOption(): ICashProtection[] {
    const cashProtections = [];
    cashProtections.push({ id: 0, name: 'Use Default', value: null });
    cashProtections.push({ id: 1, name: 'Protect Full Account Target', value: 'Protect Full Account Target' });
    cashProtections.push({ id: 2, name: 'Protect Only Excluded Cash', value: 'Protect Only Excluded Cash' });
    cashProtections.push({ id: 3, name: 'Allow Cash to Go to $0', value: 'Allow Cash to Go to $0' });
    cashProtections.push({ id: 4, name: 'Allow Cash to Go Negative', value: 'Allow Cash to Go Negative' });
    return cashProtections;
  }

  getGlobalTradeGainLossMethod(): IGlobalTradeTaxOption[] {
    const taxTickerOption = [];
    taxTickerOption.push({ value: 'Total Gain', label: 'Total Gains' });
    taxTickerOption.push({ value: 'Total Loss', label: 'Total Losses' });
    taxTickerOption.push({ value: 'Short Term Gain', label: 'Short Term Gain' });
    taxTickerOption.push({ value: 'Short Term Loss', label: 'Short Term Loss' });
    taxTickerOption.push({ value: 'Long Term Gain', label: 'Long Term Gain' });
    taxTickerOption.push({ value: 'Long Term Loss', label: 'Long Term Loss' });
    return taxTickerOption;
  }

  getGlobalTradeGainLossAmountType(): IGlobalTradeTaxOption[] {
    const taxTickerOption = [];
    taxTickerOption.push({ value: 'Percent', label: '%' });
    taxTickerOption.push({ value: 'Dollar', label: '$' });
    return taxTickerOption;
  }

  liquidateGenerateTrades(requestModel: ILiquidateRequestModel): Observable<ILiquidateResult> {
    return this._httpClient.postData(this._liquidateEndpoint, requestModel);
  }
}
