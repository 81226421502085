import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core';
import { ConfigService } from "../../../config/config.service";

@Injectable()
export class CompareInterceptor implements HttpInterceptor {
  constructor( private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.get('X-Api-Origin') === 'OrionConnect' || req.url.includes('orionadvisor')) {
      return this._handleLoggedIn(req, next);
    }
    return next.handle(req);
  }

  private _handleLoggedIn(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken().orion_access_token;
    const baseApiUrl = ConfigService.settings.orionAdvisorEndPoint + 'api/v1/';
    const url = req.url.startsWith('/') ? req.url.slice(1) : req.url;
    let headers = req.headers;

    if (headers.get('Authorization') === null) {
      headers = new HttpHeaders().set('Authorization', `Session ${token}`);
    } else if (headers.get('Authorization').toLowerCase() === 'bearer') {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    const request = req.clone({
      url: url.startsWith('http') ? url : `${baseApiUrl}${url}`,
      headers: headers
    });

    return next.handle(request);
  }


}
