import { ElementRef } from '@angular/core';

export class ActionMenuUtilities {
  /**
   * Prevents menu divider items from occurring back-to-back and from appearing as the leading/trailing item in the list.
   * @param menuElement UL element containing LI menu items.  Dividers should be decorated with the `divider` class.  Hidden elements
   * can be marked with the `hidden` attribute or the `hidden` class.
   */
  public static removeDuplicateMenuDividers(menuElement: ElementRef) {
    if (!menuElement?.nativeElement?.children) {
      return;
    }
    const links = [...menuElement.nativeElement.children];

    // Reset all dividers to visible
    links
      .filter(c => c.classList.contains('divider'))
      .forEach(c => {
        c.classList.remove('hidden');
      });
    const isVisible = (el: HTMLElement) => !(el.classList.contains('hidden') || el.hidden);
    const isDivider = (el: HTMLElement) => el.classList.contains('divider');
    const nextVisibleIndex = (currentIndex: number, list: any[]) => {
      if (currentIndex + 1 === list.length) {
        return -1;
      }
      for (let i = currentIndex + 1; i < list.length; i++) {
        if (isVisible(list[i])) {
          return i;
        }
      }
      return -1;
    };
    const previousVisibleIndex = (currentIndex: number, list: any[]) => {
      if (currentIndex - 1 < 0) {
        return -1;
      }
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (isVisible(list[i])) {
          return i;
        }
      }
      return -1;
    };

    const visiblelements = links.filter(l => isVisible(l));
    for (let i = 0; i < visiblelements.length; i++) {
      if (isDivider(visiblelements[i])) {
        const nextvis = nextVisibleIndex(i, visiblelements);
        const prevvis = previousVisibleIndex(i, visiblelements);
        if (nextvis >= 0 && isDivider(visiblelements[nextvis])) { // hide if the next visible element is a divider
          visiblelements[i].classList.add('hidden');
        } else if (i === 0 || prevvis < 0) { // hide if this is a leading divider
          visiblelements[i].classList.add('hidden');
        } else if (i === visiblelements.length - 1) { // hide if this is the trailing divider
          visiblelements[i].classList.add('hidden');
        }
      }
    }
  }
}
