import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'eclipse-dynamic-iframe',
  templateUrl: './dynamic-iframe.component.html',
  styleUrls: ['./dynamic-iframe.component.scss']
})
export class DynamicIFrameComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  iframeUrl: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  onSleeveEdit(connectSleeveEditUrl) {
    this.iframeUrl = connectSleeveEditUrl;
  }
}
