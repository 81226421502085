<div class="main">
  <eclipse-page-header pageName="Analytics Error Logs" [allowExcelExport]="true" (exportToExcel)="exportToExcel()">
    <div slot="action" class="actionBtn btn-group pull-right">
      <button data-target="#" label="Actions" iconPos="left" icon="fas fa-sparkles" pButton class="p-button-text p-button-secondary" data-toggle="dropdown" aria-expanded="false"></button>
      <ul class="dropdown-menu">
        <li><a href="javascript:void(0)" (click)="getAnalyticsErrorLogsData()">
          <i class="fas fa-sync" aria-hidden="true"></i> Refresh Grid</a>
        </li>
      </ul>
    </div>
  </eclipse-page-header>
  <div class="dashboard-content">
    <div class="agcontainer">
      <ag-grid-angular #agGrid class="ag-theme-alpine grid-height-autosizeThr" [gridOptions]="gridOptions"
                       [columnDefs]="columnDefs" [rowData]="errorLogsData" (gridReady)="onGridReady($event)"
                       [suppressContextMenu]="true">
      </ag-grid-angular>
    </div>
  </div>
</div>
