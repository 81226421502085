import {PreferenceService} from '../../services/preference.service';
import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {Observable, of} from 'rxjs';
import {SessionHelper} from '../session.helper';
import {map} from 'rxjs/operators';
import {SplitIoService} from './splitio.service';

export class FeatureFlagCheck {
  flags: FeatureFlag[];
  matchesAll: boolean;
  matchesAny: boolean;
}

export class FeatureFlag {
  key: string;
  value: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  cachedFeatures: FeatureFlag[];

  constructor(private readonly _authService: AuthService,
              private readonly _sessionHelper: SessionHelper,
              private readonly _splitIoService: SplitIoService,
              private readonly _preferenceService: PreferenceService) {
    _authService.token$.subscribe(() => {
      this.reset();
    });
  }

  private static createCheckResult(flags: FeatureFlag[]): FeatureFlagCheck {
    return <FeatureFlagCheck>{
      flags: flags,
      matchesAll: flags.length && flags.every(x => !!x.value),
      matchesAny: flags.some(x => !!x.value),
    };
  }

  /**
   * Future for 3rd party feature flag integration.
   * Return a promise so this function be called by the APP_INITIALIZER during application bootstrap.
   */
  loadConfig(): Promise<any> {
    return Promise.resolve();
  }

  /**
   * Resets the cache.
   */
  public reset(): void {
    this.cachedFeatures = [];
  }

  /**
   * Returns the value of a cached feature flag.
   * Returns null if the flag has not been cached yet.
   * @param featureFlagKey
   */
  public getCacheValue(featureFlagKey: string): boolean | undefined {
    return this.cachedFeatures.find(ff => ff.key === featureFlagKey)?.value;
  }

  public setCacheValue(key: string, value: boolean): void {
    const existingFlag = this.cachedFeatures.find(ff => ff.key === key);
    if (!existingFlag) {
      this.cachedFeatures.push(<FeatureFlag>{
        key: key,
        value: value,
      });
    } else {
      existingFlag.value = value;
    }
  }

  public getCachedValues(featureFlagKeys: string[]): FeatureFlag[] {
    return featureFlagKeys.map(key => {
      return {
        key: key,
        value: this.getCacheValue(key)
      };
    });
  }

  /**
   * Queries SplitIo feature flags and returns their values
   * @param featureFlagKeys Array of SplitIo feature flags to query
   */
  public isSplitFeatureEnabled(featureFlagKeys: string[]): Observable<FeatureFlagCheck> {
    return this._splitIoService.flagsEnabled(featureFlagKeys)
      .pipe(map(results => {
          const flagResults = Object.keys(results).map(key => (<FeatureFlag>{key: key, value: !!results[key]}));
          return FeatureFlagService.createCheckResult(flagResults)
        }));
  }

  /**
   * Queries Eclipse Preferences used as feature flags and returns their values.
   * Values are cached to prevent smashing the API with requests.
   * @param featureFlagKeys
   */
  public isFeatureEnabled(featureFlagKeys: string[]): Observable<FeatureFlagCheck> {
    let cachedValues = this.getCachedValues(featureFlagKeys);
    // if all values have been previously cached, return the cached values
    if (cachedValues.length === featureFlagKeys.length && cachedValues.every(x => !!x.value)) {
      return of(FeatureFlagService.createCheckResult(cachedValues));
    }
    const user = this._sessionHelper.getUser();
    if (!user) {
      return of(null);
    }
    // get the flags that have not been previously queried
    const keysToQuery = featureFlagKeys.filter(key => !cachedValues.find(x => x.key === key && x.value !== undefined));
    //TODO: We will replace this with Split.io
    return this._preferenceService.getpreferencesForLevel('Firm', user.firmId)
      .pipe(
        map(result => {
          const newValues = keysToQuery.map(key => {
            return {
              key: key,
              value: result.preferences.filter(pref => pref.name === key && pref.value === 'true').length > 0
            };
          });

          newValues.forEach(x => {
            this.setCacheValue(x.key, x.value);
          });
          cachedValues = this.getCachedValues(featureFlagKeys);
          return FeatureFlagService.createCheckResult(cachedValues);
        }));
  }
}
