import { Inject, Injectable, Optional } from '@angular/core';
import {datadogRum, RumInitConfiguration} from '@datadog/browser-rum';
import {VersionCheckService} from '../core/version-check.service';
import {AuthService} from '../core';
import {IUser} from '../models/user';
import {environment} from '../../environments/environment';
import {ConfigService, EnvironmentType} from '../config/config.service';
import {filter} from 'rxjs/operators';
import { WINDOW } from '../providers/window.provider';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  constructor(private readonly _authService: AuthService, private readonly _versionService: VersionCheckService,
              private readonly _configService: ConfigService, @Optional() @Inject(WINDOW) private window: Window) {
  }

  private setUserContext(newUser: IUser) {
    const fieldsToSendToDatadog = ['firmId', 'firmName'];

    // Remove any user currently in the Datadog context
    datadogRum.removeUser();

    if (!newUser) {
      return;
    }

    // if the user is impersonating another user, include the actual user id in the payload
    if (!!newUser.actualUserId) {
      fieldsToSendToDatadog.push('actualUserId');
    }

    const payload = {
      id: newUser.id.toString(),
      name: newUser.userLoginId
    };
    fieldsToSendToDatadog.forEach(field => payload[field] = newUser[field]);

    // Set the current user context wut
    datadogRum.setUser(payload);
  }

  public initialize() {
    let environmentType = this._configService.getEnvironment();
    let environmentName = (EnvironmentType[environmentType] || 'unknown').toLowerCase();
    // Don't track local development
    if (this._configService.isLocal()) {
      return;
    } else if (environmentType === EnvironmentType.Production) {
      const host = this.window.location.hostname.toLowerCase();
      environmentName = 'prod'; // to match prod env value in other applications
      if (host.startsWith('prod.')) {
        environmentName = 'newprod'; // Temporary to log prod.orioneclipse.com as a different environment than prod
      }
    }

    if (datadogRum.getInitConfiguration()) {
      throw new Error('Datadog RUM has already been initialized!');
    }

    const appVersion = this._versionService.currentVersion;
    const ddConfig = environment.datadog;
    if (!appVersion) {
      console.error('Application version has not been loaded.');
    }

    const dataDogInitConfig: RumInitConfiguration = {
      applicationId: ddConfig.applicationId,
      clientToken: ddConfig.clientToken,
      site: 'datadoghq.com',
      service: ddConfig.appName,
      version: appVersion?.timestamp || 'unknown',
      env: environmentName,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,  // if we want session replay enabled, increase this value and call datadogRum.startSessionReplayRecording() after init()
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask',
      actionNameAttribute: 'data-test-id',
      beforeSend: event => {
        event.view.url = event.view.url.split('#')[1];

        if (event.view.referrer?.includes('#')) {
          event.view.referrer = event.view.referrer.split('#')[1];
        }
      },
      allowedTracingOrigins: [/https:\/\/.*\.orioneclipse\.com/, /http:\/\/.*\.orioneclipse\.local/, /http:\/\/.*\.review\.apps/],
      excludedActivityUrls: [
        /https:\/\/.*\.aptrinsic\.com/,
        /https:\/\/.*\.split\.io/,
        /https:\/\/.*\.salemove.com/,
        /https:\/\/.*\.walkme\.com/,
        /https:\/\/.*\.glia\.com/,
        /https:\/\/.*\.fullstory\.com/
      ]
    };

    datadogRum.init(dataDogInitConfig);

    this._authService.currentUser$
      .pipe(filter((usr) => !!usr))
      .subscribe(usr => {
        this.setUserContext(usr);
      });
    if (!!this._authService.currentUser) {
      this.setUserContext(this._authService.currentUser);
    }
  }
}
