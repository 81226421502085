import { Injectable } from "@angular/core";
import { filter, Observable } from "rxjs";
import { SignalService } from "./signal.service";

@Injectable({
    providedIn: 'root',
})
export class EntityStateChangeService {
  readonly filtered: Observable<{topic: string}>

  constructor(private _signalService: SignalService) {
    this.filtered = this._signalService.events.pipe(filter(msg => /^state:\w+/.test(msg?.topic)))
  }
}
