import {ElementRef, Directive} from '@angular/core';
import * as _ from 'lodash';

/**
 * Creates a `data-test-id` attribute on an element if it doesn't already exist.  The value of the
 * attribute is a combination of a descrption of the element and parent nodes.
 */
@Directive({
  selector: `[click], [routerLink],
  input:not([data-test-id]), button:not([data-test-id]), textarea:not([data-test-id]), a:not([data-test-id]),
  li:not([data-test-id]), select:not([data-test-id]), option:not([data-test-id]),
  .checkbox, .ag-menu-option,
  p-autoComplete, p-dropDown, p-inputSwitch, p-multiSelect, p-multiselectitem, p-calendar`
})
export class DataTestIdDirective {
  private readonly debugMode: boolean = false; // set to true to highlight clickable elements.
  private attributeExists = false;

  constructor(public element: ElementRef) {
  }

  ngAfterContentChecked() {
    if (this.attributeExists || !this.element.nativeElement.isConnected) {
      return;
    }
    this.checkForDataTestIdAttribute();
  }

  ngAfterViewInit() {
    if (this.attributeExists) {
      return;
    }
    this.checkForDataTestIdAttribute();
  }

  /**
   * Checks for a data-test-id attribute on the element and creates one if it's missing.
   */
  public checkForDataTestIdAttribute() {
    if (!this.element.nativeElement.isConnected) {
      return;
    }
    const id = DataTestIdDirective.getDataTestIdAttribute(this.element.nativeElement);
    if (!id) {
      DataTestIdDirective.setDataTestIdAttribute(this.element.nativeElement);
    }
    this.attributeExists = true;
    this.addDebugStyling(!id);
  }

  /**
   * Adds styling around an element for debugging purposes.
   * @param autoGeneratedId
   * @private
   */
  private addDebugStyling(autoGeneratedId: boolean) {
    if (!!this.debugMode) {
      this.element.nativeElement.style.boxShadow = `0 0 0 2px ${autoGeneratedId ? 'hotpink' : 'orange'} inset`;
      this.element.nativeElement.setAttribute('title', DataTestIdDirective.getDataTestIdAttribute(this.element.nativeElement)?.value);
    }
  }

  /**
   * Returns the data-test-id attribute from an element, if any.
   * @param nativeElement
   */
  public static getDataTestIdAttribute(nativeElement: any) {
    return nativeElement.attributes.getNamedItem('data-test-id');
  }

  /**
   * Creates the data-test-id attribute on an element.
   * Example: <button id="someBtn"> ==> <button data-test-id="someBtn" id="someBtn">
   */
  public static setDataTestIdAttribute(nativeElement: any) {
    const elementLocation = this.findElementLocation(nativeElement);
    let elementText = _.camelCase(this.getElementDescriptor(nativeElement));
    // if no descriptive text could get generated for the element, then default to the tag name
    if (elementText === '') {
      elementText = nativeElement.localName;
    }

    const nameParts = [];
    if (elementLocation) {
      nameParts.push(elementLocation);
    }
    if (elementText) {
      nameParts.push(elementText);
    }

    const dataTestId = nameParts.join('-').replace(/\r?\n|\r/g, '').replace(/ /g, '');
    nativeElement.setAttribute('data-test-id', `${dataTestId}`);
    return dataTestId;
  }

  /**
   * Generates a descriptive name for the current element based on element type and attributes.
   * Examples:
   *  <input type="checkbox" aria-label="some checkbox" />   <!-- "some checkbox" -->
   *  <input id="chk1" type="checkbox" />   <!-- "chk1" -->
   *  <input type="checkbox" />   <!-- "checkbox" -->
   * @param nativeElement
   */
  public static getElementDescriptor(nativeElement: any): string {
    // aria-label attribute
    let label = nativeElement.attributes.getNamedItem('aria-label')?.value;
    if (!!label) {
      return label;
    }

    // name, id
    if (!!nativeElement.name || !!nativeElement.attributes.getNamedItem('ng-reflect-name')?.value) {
      return nativeElement.name || nativeElement.attributes.getNamedItem('ng-reflect-name').value;
    } else if (!!nativeElement.id) {
      return nativeElement.id;
    }

    // form control
    label = nativeElement.attributes.getNamedItem('formControlName')?.value;
    if (!!label) {
      return label;
    }

    // input type
    label = nativeElement.localName === 'input' && nativeElement.attributes.getNamedItem('type')?.value;
    if (!!label) {
      return label;
    }

    if (nativeElement.localName === 'i') {
      return 'icon';
    }

    // eclipse component tag
    label = nativeElement.localName.startsWith('eclipse-');
    if (!!label) {
      return nativeElement.localName.replace('eclipse-', '');
    }

    // anchor tag with a routerLink
    if (nativeElement.localName === 'a' && !!nativeElement.attributes.getNamedItem('ng-reflect-router-link')?.value) {
      return nativeElement.attributes.getNamedItem('ng-reflect-router-link').value.replace('eclipse', '');
    }

    // wrapper element with text content
    if (!!['a', 'span', 'li', 'button'].includes(nativeElement.localName) && !!nativeElement.textContent) {
      return nativeElement.textContent.slice(0, 20); // just use a slice of the text to avoid having a massive attribute value
    }

    return null;
  }

  // Collection of functions to help determine an elements location based on a distinct areas of the site (header, sidebar, dialog, etc).
  private static elementFinderFns = [
    (nativeElement) => {
      const topNavBar = document.getElementById('navbar');
      if (topNavBar && topNavBar.contains(nativeElement)) {
        return 'navbar';
      }
    },
    (nativeElement) => {
      const aside = document.getElementsByClassName('app-aside');
      for (let i = 0; i < aside.length; i++) {
        if (aside && aside[i].contains(nativeElement)) {
          return 'sidebar';
        }
      }
    },
    (nativeElement) => {
      const otherModals = document.getElementsByClassName('modal');
      for (let i = 0; i < otherModals.length; i++) {
        if (otherModals[i].contains(nativeElement)) {
          return otherModals[i].id;
        }
      }
    },
    (nativeElement) => {
      const otherModals = document.getElementsByTagName('p-dialog');
      for (let i = 0; i < otherModals.length; i++) {
        const pdlg = otherModals[i].attributes.getNamedItem('header')?.value;
        if (otherModals[i].contains(nativeElement)) {
          return otherModals[i].id.length > 0 ? otherModals[i].id : _.camelCase(otherModals[i].attributes.getNamedItem('header')?.value) + 'Modal';
        }
      }
    },
    (nativeElement) => {
      const parents = DataTestIdDirective.getParents(nativeElement);
      return parents.join('-');
    }
  ];

  /**
   * Walks up the HTML tree, starting with the current nodes parent, and constructs a list of
   * descriptive names along the way.
   * Example input:
   *  <section id="parent1">
   *    <div id="parent2">
   *      <button id="someBtn"><button>
   *    </div>
   *  </section>
   * Example output: ['parent1', 'parent2']
   * @param nativeElement
   * @private
   */
  private static getParents(nativeElement): string[] {
    const parents = [];
    while (nativeElement.parentNode && nativeElement.parentNode.nodeName.toLowerCase() !== 'body') {
      nativeElement = nativeElement.parentNode;
      const parentName = DataTestIdDirective.getElementDescriptor(nativeElement);
      if (!!parentName) {
        parents.push(`${_.camelCase(parentName)}`);
      }
    }

    // reverse the list so the highest node appears first
    return parents.reverse();
  }

  /**
   * Finds the elements location from some pre-determined locations (navbar, sidebar, dialogs, etc).
   * @param nativeElement
   * @private
   */
  private static findElementLocation(nativeElement) {
    for (let i = 0; i < DataTestIdDirective.elementFinderFns.length; i++) {
      const location = DataTestIdDirective.elementFinderFns[i](nativeElement);
      if (location) {
        return location;
      }
    }
  };
}
