import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eclipse-page-header',
  templateUrl: './pageheader.component.html'
})
export class PageHeaderComponent {
  @Input() pageName: string = 'Dashboard';
  @Input() allowExcelExport: boolean;
  @Output() exportToExcel = new EventEmitter();
  constructor() {

  }
}
