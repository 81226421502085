<p-autoComplete [(ngModel)]="selectedItem" [suggestions]="formattedAccountSuggestions"
                placeholder="Account ID / Account Number / Account Name / Tags or Portfolio name"
                (completeMethod)="loadSuggestions($event)" (onSelect)="onAccountSelect($event)"
                field="selectedItemFormat" class="autocomplete-search fs-mask" [minLength]="1"
                (keyup)="onAccountValueChange(selectedItem)" appendTo="body">
  <ng-template let-selectedItem pTemplate="item">
    <ul class="ui-helper-clearfix show-hollding-dropdown">
      <li class="vertical-center">
        <i class="fas fa-briefcase text-info" aria-hidden="true"></i>
        <div style="margin-left: 20px" class="fs-mask" [innerHTML]="selectedItem.displayFormat  | textHighlighting: searchText"></div>
      </li>
    </ul>
  </ng-template>
</p-autoComplete>
