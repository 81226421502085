<div class="flex" style="align-items: center; justify-content: flex-end;">
    <p-chip *ngIf="analyticsStatus === AnalyticsStatus.Good" label="Analytics is good" icon="fas fa-check fa-sm"
            styleClass="successAlt p-chip-analytics"></p-chip>
    <div *ngIf="analyticsStatus === AnalyticsStatus.NeedAnalytics">
        <p-chip label="Needs Analytics" icon="fas fa-exclamation fa-sm"
                styleClass="warningAlt p-chip-analytics"></p-chip>
        <button (click)="runAnalysis()"
                type="button"
                pButton class="p-button-sm" [disabled]="isButtonDisabled" label="Run Now">
        </button>
    </div>
    <div *ngIf="analyticsStatus === AnalyticsStatus.Running">
        <p-chip label="Analytics in Progress" icon="fas fa-spinner fa-pulse fa-sm"
                styleClass="primaryAlt p-chip-analytics"></p-chip>
        <p-chip label="Duration: {{duration}}" icon="far fa-clock fa-sm" styleClass="p-chip-analytics"></p-chip>
    </div>
    <div *ngIf="analyticsStatus === AnalyticsStatus.Failed">
        <p-chip label="Analytics Failed" icon="fas fa-exclamation-triangle fa-sm"
                styleClass="errorAlt p-chip-analytics cursor"
                (click)="isMultiSelect ? overlayAnalyticsErrorMulti.toggle($event) :
              overlayAnalyticsErrorSingle.toggle($event)">
        </p-chip>
        <p-overlayPanel #overlayAnalyticsErrorSingle [showCloseIcon]="false" [style]="{width: '450px'}">
            <ng-template pTemplate>
                <span>Error: <small>{{status}}</small></span>
                <div class="text-right">
                    <button (click)="runAnalysis()" type="button"
                            name="button" class="btn model-analytic-btn run-now-button" [disabled]="isButtonDisabled">Run
                        Now
                    </button>
                </div>
            </ng-template>
        </p-overlayPanel>
        <p-overlayPanel #overlayAnalyticsErrorMulti [showCloseIcon]="false">
            <ng-template pTemplate>
                <div style="max-height:400px; overflow-y: auto;">
                    <table class="table">
                        <caption class="hidden-element">Analytics Failed</caption>
                        <thead>
                        <tr>
                            <th scope="col">Portfolio Id</th>
                            <th scope="col">Status</th>
                            <th scope="col">Error</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let portfolioAnalytic of getFailedAnalytics()">
                            <td><span>{{portfolioAnalytic?.portfolioId}}</span></td>
                            <td>
                              <span *ngIf="portfolioAnalytic?.needAnalytics === AnalyticsStatus.Good"><i
                                      class="fas fa-check-circle text-success"
                                      aria-hidden="true"></i></span>
                                <span *ngIf="portfolioAnalytic?.needAnalytics === AnalyticsStatus.Failed"><i
                                        class="fas fa-exclamation-triangle text-danger"
                                        [pTooltip]="portfolioAnalytic?.failedReason" aria-hidden="true"></i></span>
                            </td>
                            <td><span>{{portfolioAnalytic?.failedReason}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right">
                    <button (click)="runAnalysis()" type="button" name="button"
                            class="btn model-analytic-btn run-now-button"
                            [disabled]="isButtonDisabled">Run Now
                    </button>
                </div>
            </ng-template>
        </p-overlayPanel>
    </div>
</div>
