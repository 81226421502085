import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {BlendedFundService} from '../../services/blendedFund.service';
import { Utils as Util } from '../../core/functions';
import * as Consts from '../../libs/app.constants';
import { GridOptions, ColDef, CsvExportParams, GridReadyEvent, GridApi } from '@ag-grid-community/core';
import {WINDOW} from '../../providers/window.provider';

@Component({
  selector: 'eclipse-macweighting-validation',
  templateUrl: './macweighting.validation.component.html'
})
export class MACWeightingValidationComponent extends BaseComponent {
  private gridApi: GridApi;
  public validationsResultGridOptions: GridOptions;
  public validationsResultColDefs: ColDef[] = [];
  public validationResultGridData: any[] = [];
  showWarningDialog: boolean = false;
  popupValidationMessage: string = '';
  // Flag - Not to check T/TD/TE atleast one optoin as 'YES'
  isModelView: boolean = false; // (Model Create -> View Structure option ) and ( Model View Details -> Assign Portfolio option )
  disableContinueBtn: boolean = false;

  /** Changes from MAC HELPER*/
  macWeightingsValidationObject: any = {};
  validationLevel: number = 1;
  isEditAction: boolean;

  // @Input() validationRuleLevel: number;
  @Output() weightingsCallback = new EventEmitter();

  constructor(private readonly _blendedFundService: BlendedFundService, @Inject(WINDOW) private readonly window: Window) {
    super();
    this.validationsResultGridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit() {
    this.createValidationsGrid();
  }

  createValidationsGrid() {
    const self = this;
    this.validationsResultColDefs = [];
    // eslint-disable-next-line eqeqeq
    if (self.validationLevel == 2) {
      this.validationsResultColDefs.push(<ColDef>{ headerName: 'Model Name ', field: 'modelName' });
      this.validationsResultColDefs.push(<ColDef>{ headerName: 'Ticker', field: 'securitySymbol' });
      this.validationsResultColDefs.push(<ColDef>{ headerName: 'Missing Node/s', field: 'missingNodes' });
    } else {
      this.validationsResultColDefs.push(<ColDef>{ headerName: 'Model Name ', field: 'modelName' });
      this.validationsResultColDefs.push(<ColDef>{ headerName: 'Security Set', field: 'securitySetName' });
      this.validationsResultColDefs.push(<ColDef>{ headerName: 'Ticker', field: 'securitySymbol' });
      this.validationsResultColDefs.push(<ColDef>{
        headerName: 'Edit', field: '', cellClass: 'text-center',
        cellRenderer: function (params) {
          return self.editCellRender(params, self);
        }
      });
    }
  }

  editCellRender(params, self) {
    const eInput = document.createElement('span');
    // eslint-disable-next-line prefer-template
    eInput.innerHTML = '<i class="fas fa-edit cursor" aria-hidden="true" id =' + params.node.data.securitySetId + ' value=' + params.node.data.securitySetId + ' title="Edit"></i>';
    eInput.addEventListener('click', (event) => {
      self.window.open('#/eclipse/strategy/securityset/edit/' + params.node.data.securitySetId);
    });
    return eInput;
  }

  /** Export the grid data to csv file */
  exportToExcel() {
    const validationsParams = <CsvExportParams>{
      skipFooters: true,
      skipGroups: true,
      fileName: 'MAC Validations.csv'
    };
    this.gridApi.exportDataAsCsv(validationsParams);
  }

  refreshValidationGrid() {
    this.disableContinueBtn = false;
    this.showWarningDialog = false;
    this.validateAndSaveMACWeightings(this.macWeightingsValidationObject, this.isEditAction);
  }

  continueWithNextRule() {
    this.showWarningDialog = false;
    // eslint-disable-next-line eqeqeq
    if (this.validationLevel != 4) {
      this.validationLevel += 1;
    } else {
      this.validationLevel = 4;
    }

    this.macWeightingsValidationObject.validationLevel = this.validationLevel;
    this.validateAndSaveMACWeightings(this.macWeightingsValidationObject, this.isEditAction);
  }

  cancel() {
    this.disableContinueBtn = false;
    this.showWarningDialog = false;
    this.validationLevel = 1;
    this.macWeightingsValidationObject.validationLevel = 1; // reset level if user cancels middle of validations.
    this.weightingsCallback.emit({ action: 'cancel' });
  }

  bindValidationPopup(invalidRecordsData, errorMessage) {
    // this.validationLevel = this.validationLevel;
    this.showWarningDialog = true;
    this.popupValidationMessage = errorMessage;
    this.createValidationsGrid();
    this.validationResultGridData = invalidRecordsData;
    this.gridApi?.setGridOption('rowData', invalidRecordsData);
    this.gridApi?.sizeColumnsToFit();
    this.weightingsCallback.emit({ action: 'level', validationLevel: this.validationLevel });
  }

  /** Grid has initialised  */
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.autoSizeColumns([]);
  }

  // VALIDATE & SAVE WEIGHTINGS

  validateAndSaveMACWeightings(validationObj, isEdit = false) {
    this.isEditAction = isEdit;
    this.macWeightingsValidationObject = Util.deepClone(validationObj);

    // In Case of Edit weightings, for validate api, we will not pass deleted securities
    // eslint-disable-next-line eqeqeq
    validationObj.weightings = validationObj.weightings.filter(record => record.action != Consts.MACEditActions.Delete);

    // Perform validations only if Mac Status is 'Yes', else save Mac Status directly.
    // eslint-disable-next-line eqeqeq
    if ((this.macWeightingsValidationObject.macStatus == Consts.MACWeightingStatus.Model.value || this.macWeightingsValidationObject.macStatus == Consts.MACWeightingStatus.No.value)) {
      this.saveMACWeightings();
    } else {
      this._blendedFundService.validateMACWeightings(validationObj)
        .subscribe(response => {
            if (response && response.length > 0) {
              // First result set will contain ValidationLevel...
              // 2nd Rule will have two result sets, others will contain only one (Excluding 1st result set).
              if (response[0].length > 0) {
                const item = response[0][0]; //// check validation level from first record of first result set.
                if (item.validationLevel) {
                  this.validationLevel = item.validationLevel; // updating level from SP result
                  if (response[1].length > 0) {
                    switch (item.validationLevel) {
                      case 1:
                      case 3:
                        // eslint-disable-next-line eqeqeq
                        const invalidRecords = response[1].filter(security => security.doNotBuySell == 1);
                        if (invalidRecords.length > 0) {
                          // eslint-disable-next-line eqeqeq
                          if (item.validationLevel == 1) {
                            this.bindValidationPopup(invalidRecords, Consts.MACValidationRulesMessages.donotBuySellWarningMessage);
                          } else {
                            this.bindValidationPopup(invalidRecords, Consts.MACValidationRulesMessages.alternativePureAllocationMessage);
                          }
                        } else {
                          this.validateNextRule();
                        }

                        break;
                      case 2:
                        if (response[2].length > 0) {
                          const uniqIds = [];
                          let relatedTypeIds = [];
                          let missingNodes = [];
                          const finalObject = [];
                          // eslint-disable-next-line no-shadow
                          response[1].filter(function (item) {
                            if (uniqIds.indexOf(item.modelId) <= -1) { // findIndex
                              uniqIds.push(item.modelId);
                            }// get uniq modelIds only
                            return;
                          });

                          // Foreach model,if its relatedTypeIds are not present in second resultset relatedTypeIds, treat them as missing.
                          uniqIds.forEach(function (modelId) {
                            relatedTypeIds = [];
                            // eslint-disable-next-line eqeqeq
                            const data = response[1].filter(m => m.modelId == modelId);
                            relatedTypeIds = data.map(p => p.relatedTypeId); // fetch each model Relative Type Ids
                            // eslint-disable-next-line no-shadow
                            response[2].forEach(function (item) {
                              if (relatedTypeIds.indexOf(item.relatedTypeId) <= -1) {
                                // eslint-disable-next-line eqeqeq
                                const model = response[1].find(x => x.modelId == modelId);
                                switch (model && model.relatedType) {
                                  case 'SUBCLASS':
                                    missingNodes.push({ modelId: model.modelId, modelName: model.modelName, securityId: item.securityId, securitySymbol: item.symbol, nodeType: item.assetSubClassName });
                                    break;
                                  case 'CATEGORY':
                                    missingNodes.push({ modelId: model.modelId, modelName: model.modelName, securityId: item.securityId, securitySymbol: item.symbol, nodeType: item.assetCategoryName });
                                    break;
                                  case 'CLASS':
                                    missingNodes.push({ modelId: model.modelId, modelName: model.modelName, securityId: item.securityId, securitySymbol: item.symbol, nodeType: item.assetClassName });
                                    break;
                                }
                              }
                            });
                          });
                          missingNodes = missingNodes.filter(f => f.nodeType);  // removes null values if any
                          console.log('Missing Nodes data: ', missingNodes);
                          if (missingNodes.length > 0) {
                            // grouping based on ModelId & securityId
                            const result = Util.groupBy(missingNodes, function (row) {
                              return [row.modelId, row.securityId];
                            });
                            // Making comma seperated nodes if modelId & securityId have duplicate nodes.
                            result.forEach(function (record) {
                              if (record.length > 1) {
                                // if grouped combination has multiple records, display comma seperated names.
                                let uniqNodeNames = record.map(r => r.nodeType)
                                  .filter((value, index, self) => self.indexOf(value) === index);

                                uniqNodeNames = uniqNodeNames.join(','); // comma seperated node names.

                                finalObject.push({ modelId: record[0].modelId, modelName: record[0].modelName, securitySymbol: record[0].securitySymbol, missingNodes: uniqNodeNames });
                              } else {
                                // grouped combination has only one record
                                finalObject.push({ modelId: record[0].modelId, modelName: record[0].modelName, securitySymbol: record[0].securitySymbol, missingNodes: record[0].nodeType });
                              }
                            });
                            this.bindValidationPopup(finalObject, Consts.MACValidationRulesMessages.pureAssetClassWarningMessage);
                          } else {
                            this.validateNextRule();
                          }
                        } else {
                          this.validateNextRule();
                        }

                        break;

                      case 4:
                        this.validateRank(validationObj, response);
                        break;

                      default:
                        break;
                    }
                  } else {
                    this.validateNextRule();
                  }
                } else {
                  this.validateNextRule();
                }
              } else {
                this.validateNextRule();
              }
            } else {
              this.validateNextRule();
            }
          },
          error => {
            this.weightingsCallback.emit({ action: 'save', status: 'failed' });
            this.validationLevel = 1;
          });
    }
  }


  saveMACWeightings() {
    delete this.macWeightingsValidationObject.validationLevel; // Level is not required for SAVE
    // this.macWeightingsValidationObject.weightings = [];
    const weightings = [];
    let isValidAlternates = true;
    if (this.macWeightingsValidationObject.macStatus === Consts.MACWeightingStatus.Yes.value
      || this.macWeightingsValidationObject.macStatus === Consts.MACWeightingStatus.Sleeve.value
      || this.macWeightingsValidationObject.macStatus === Consts.MACWeightingStatus.Portfolio.value) {
      this.macWeightingsValidationObject.weightings.filter(function (item) {
        // eslint-disable-next-line eqeqeq
        if (+item.taxableSecurity == 0 && +item.taxDeferredSecurity == 0 && +item.taxExemptSecurity == 0) {
          isValidAlternates = false;
          return;
        }
        weightings.push({
          securityId: item.securityId,
          weightingId: +item.weightingId,
          // eslint-disable-next-line eqeqeq
          rank: item.rank ? +item.rank : (item.rank == 0) ? 0 : null,
          // eslint-disable-next-line eqeqeq
          taxableSecurity: (+item.taxableSecurity == 1),
          // eslint-disable-next-line eqeqeq
          taxDeferredSecurity: (+item.taxDeferredSecurity) == 1,
          // eslint-disable-next-line eqeqeq
          taxExemptSecurity: (+item.taxExemptSecurity == 1),
          action: item.action,
          editId: item.editId
        });
      });
    }
    this.macWeightingsValidationObject.weightings = [];
    this.macWeightingsValidationObject.weightings = weightings;

    if (this.isModelView) {// for model related View Details & View Structure & Edit Structure screens, we don't show this validaiton
      isValidAlternates = true;
    }

    if (isValidAlternates) {
      if (!this.isEditAction) {
        this._blendedFundService.assignMACWeightings(this.macWeightingsValidationObject)
          .subscribe(response => {
            if (response && response.message) {
              this.weightingsCallback.emit({ action: 'save', status: 'success' });
            }
          }, error => {
            this.validationLevel = 1;
            this.weightingsCallback.emit({ action: 'save', status: 'failed' });
          });
      } else {
        this._blendedFundService.updateMACWeightings(this.macWeightingsValidationObject)
          .subscribe(response => {
            if (response && response.message) {
              this.weightingsCallback.emit({ action: 'save', status: 'success' });
            }
          }, error => {
            this.validationLevel = 1;
            this.weightingsCallback.emit({ action: 'save', status: 'failed' });
          });
      }
    } else {
      this.weightingsCallback.emit({ action: 'taxableError' });
      // this.taxableErrorMsg = "At least one of the T/TD/TE should be set to ‘Yes’ for each row.";
    }
  }

  validateNextRule() {
    // eslint-disable-next-line eqeqeq
    if (this.validationLevel == 3) {
      // If ranks entered for all securities, no need to call validation api for 4th level(RANK VALIDATION).
      // eslint-disable-next-line eqeqeq
      const rankArray = this.macWeightingsValidationObject.weightings.filter(x => x.rank == undefined);
      if (rankArray && rankArray.length > 0) {
        // continue calling VALIDATION API for 4th level (RANK VALIDATION).
        // To check whether any security requires rank to be entered.
      } else {
        // As ranks entered for all securities, SAVE data.
        this.validationLevel += 1;
      }
    }
    if (this.validationLevel < 4) {
      this.validationLevel += 1;
      this.macWeightingsValidationObject.validationLevel = this.validationLevel;
      this.validateAndSaveMACWeightings(this.macWeightingsValidationObject, this.isEditAction);
    } else {
      // All validation levels are passed so save the data.

      // when model is unassigned or new model assigned to portfolio, we will only validate data.
      if (!this.isModelView) {// from Model View - as Weightings already saved, we will avoid saving them again
        this.saveMACWeightings();
      } else {
        this.weightingsCallback.emit({ action: 'validate', status: 'success' });
      }
    }
  }

  validateRank(validationObj: any, response: any): void {
    if (validationObj.weightings.length > 1) {
      const ranks = validationObj.weightings.filter(s => {
        return (s.rank || s.rank === 0);
      });
      if (ranks && ranks.length > 0 && ranks.length === validationObj.weightings.length) {
        this.validateNextRule();
      } else {
        let securities = response[1].map(r => r.symbol)
          .filter((value, index, self) => self.indexOf(value) === index);
        securities = securities.join(','); // comma separated names.
        this.disableContinueBtn = true;
        const rankValidationMessage = `The securities ${securities} have overlap under same model nodes. Please assign unique ranks for the securities`;
        this.weightingsCallback.emit({ validationLevel: this.validationLevel, action: 'rankValidation', errorMessage: rankValidationMessage, securities: securities });
      }
    } else {
      this.validateNextRule();
    }
  }
}
