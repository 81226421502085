<div class="portfoliodetails-search-add form-group">
  <p-autoComplete class="autocomplete-search" [(ngModel)]="selectedSecurity" [suggestions]="filteredSecurityResult" [disabled]="disableSecuritySearch"
                  placeholder="Name or Ticker" name="tlhSearch" (completeMethod)="autoSecuritySearch($event)" field="nameSymbol" [minLength]="1"
                  (onSelect)="onSecuritySelect($event)">
    <ng-template let-selectedSecurity pTemplate="item">
      <ul class="ui-helper-clearfix show-hollding-dropdown">
        <li>
          <i class="lm lm-securities text-info" aria-hidden="true"></i>
          <span>{{selectedSecurity.name}} (<strong>{{selectedSecurity.symbol}}</strong>)</span>
        </li>
      </ul>
    </ng-template>
  </p-autoComplete>
</div>
