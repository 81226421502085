import { AstroAccountHubService } from '../services/astro-account-hub.service';
import { AstroBatchStatus, AstroOptimizationCompletionMessages, AstroOptimizationStackedBarLabel } from '../../../libs/astro.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../core/base.component';
import { Utils as Util } from '../../../core/functions';
import { AstroService } from '../../../services/astro.service';
import { IAstroBatchOptimization, IAstroBatchStatus } from '../../../models/astro';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';
import * as constants from '../../../libs/app.constants';

@Component({
  selector: 'eclipse-optimize-batch-status',
  styleUrls: ['./optimize.batchstatus.component.scss'],
  templateUrl: './optimize.batchstatus.component.html'
})
export class OptimizeBatchStatus extends BaseComponent implements OnInit, OnDestroy {
  displaySpinner: Map<string, boolean>;
  route: string;
  batchStatus: Map<string, IAstroBatchStatus>;
  pollBatchStatusSubscription: Subscription;
  displaySpinnerSubscription: Subscription;
  batchCountSubscription: Subscription;
  accountCountSubscription: Subscription;
  pollBatchStatusMap: Map<string, Subscription>;
  stackedBarData: unknown;
  stackedBarOptions: unknown;
  batchesForStackedBar: string[];
  batchesForDialog: string[];
  hideStatusDialog: Map<string, boolean>;
  isOptimizationComplete: Map<string, boolean>;
  batchCount: number;
  accountCount: number;
  isPollingMultipleRequest: boolean;
  isSingleAccountOptimization: boolean;
  isMultipleAccountOptimization: boolean;
  optimizationStackedBarFlag: boolean;

  constructor(private _router: Router, private activateRoute: ActivatedRoute, private _astroService: AstroService,
    private readonly _astroAccountHubService: AstroAccountHubService, private readonly _splitIoService: SplitIoService) {
    super();
    this.displaySpinner = new Map<string, boolean>();
    this.batchStatus = new Map<string, IAstroBatchStatus>();
    this.pollBatchStatusMap = new Map<string, Subscription>();
    this.stackedBarData = {};
    this.stackedBarOptions = {};
    this.batchesForStackedBar = [];
    this.batchesForDialog = [];
    this.hideStatusDialog = new Map<string, boolean>();
    this.isOptimizationComplete = new Map<string, boolean>();
    this.isPollingMultipleRequest = false;
    this.isSingleAccountOptimization = false;
    this.isMultipleAccountOptimization = false;
    this.route = Util.activeRoute(this.activateRoute);
  }

  ngOnInit(): void {
    this.subscribePollBatchStatus();
    this.subscribeDisplaySpinner();
    this.subscribeBatchCount();
    this.subscribeAccountCount();
    this.subscribePollingType();
    this.getOptimizationStackedBarFeatureFlag();
  }

  ngOnDestroy(): void {
    this.pollBatchStatusSubscription.unsubscribe();
    this.displaySpinnerSubscription.unsubscribe();
    this.batchCountSubscription.unsubscribe();
    this.accountCountSubscription.unsubscribe();
    this.batchesForDialog = [];
  }

  private pollBatchStatus(optimizationRequest: IAstroBatchOptimization[]): void {
    const batchName = optimizationRequest[0].batchIds[0];
    if (this.isPollingMultipleRequest) {
      this.pollMultiBatchRequest(optimizationRequest, batchName);
      this.isPollingMultipleRequest = false;
    } else {
      this.pollSingleBatchRequest(optimizationRequest, batchName);
    }
  }

  private pollMultiBatchRequest(optimizationRequest: IAstroBatchOptimization[], batchName: string): void {
    this.batchStatus[batchName] = {
      batchName: batchName,
      totalCount: this.accountCount,
      pendingCount: this.accountCount,
      completedCount: 0,
      isSingleBatchRequest: false,
      isMultiBatchRequest: true
    } as IAstroBatchStatus;

    this.pollBatchRequests(optimizationRequest, batchName, true);
  }

  pollSingleBatchRequest(optimizationRequest: IAstroBatchOptimization[], batchName: string): void {
    this.batchStatus[batchName] = {
      batchName: batchName,
      totalCount: this.accountCount,
      pendingCount: this.accountCount,
      isSingleBatchRequest: true
    } as IAstroBatchStatus;

    this.pollBatchRequests(optimizationRequest, batchName);
  }

  private pollBatchRequests(optimizationRequest: IAstroBatchOptimization[], batchName: string, isMultiBatch?: boolean): void {
    if (this.optimizationStackedBarFlag) {
      this.pollForStackBar(optimizationRequest, batchName);
    } else {
      this.pollForDialogBox(optimizationRequest, batchName, isMultiBatch);
    }
    this.isMultipleAccountOptimization = false;
    this.isSingleAccountOptimization = false;
  }

  getAstroBatchStatus(optimizationRequest: IAstroBatchOptimization[], batchName: string): Subscription {
    return interval(10000).pipe(startWith(0),
      switchMap(() => this._astroService.getBatchStatusDetail(optimizationRequest[0], batchName)))
      .subscribe({
        next: (batchStatus: IAstroBatchStatus) => {
          this.updateOptimizationStatusDialog(batchStatus[0]);
        },
        error: (error) => {
          this.displaySpinner[batchName] = false;
          throw error;
        }
      });
  }

  getAstroBatchStatusCount(optimizationRequest: IAstroBatchOptimization[], batch: string): Subscription {
    return interval(10000).pipe(startWith(0),
      switchMap(() => this._astroService.getBatchStatusCount(optimizationRequest, batch)))
      .subscribe({
        next: (batchStatus: IAstroBatchStatus) => {
          this.onOptimizationComplete(batchStatus);
          const batchName = batchStatus.batchName;
          const successCount = batchStatus.successCount;
          const failedCount = batchStatus.failedCount;
          this.batchStatus[batchName].pendingCount = this.batchStatus[batchName].totalCount - (successCount + failedCount);
          if (this.optimizationStackedBarFlag) {
            this.updateOptimizationStackedBar(batchName, successCount, failedCount, this.batchStatus[batchName].pendingCount, this.batchStatus[batchName].totalCount);
          } else {
            this.updateBatchStatusCounts(batchStatus.batchName, this.batchStatus[batchName].pendingCount);
          }
        },
        error: (error) => {
          this.batchStatus[batch].isError = true;
          throw error;
        }
      });
  }

  private getBatchStatus(batchId: string): Subscription {
    return interval(10000).pipe(startWith(0),
      switchMap(() => this._astroService.getBatchStatus(batchId)))
      .subscribe({
        next: (batchStatus: IAstroBatchStatus) => {
          if (batchStatus) {
            const batchDetail = this.batchStatus[batchStatus.batchName];
            this.isOptimizationComplete[batchStatus.batchName] = this.isOptimizationCompleted(batchDetail.status);
            this.setBatchStatus(batchStatus.comments, batchStatus.status, batchStatus.isReady, false, this.isOptimizationComplete[batchStatus.batchName], batchStatus.batchName);
          }
          this.unsubscribeBatchOnCompletion(batchStatus.batchName);
        },
        error: (error) => {
          this.batchStatus[batchId].isError = true;
          this.displaySpinner[batchId] = false;
          throw error;
        }
      });
  }

  private pollForStackBar(optimizationRequest: IAstroBatchOptimization[], batchName: string): void {
    this.batchesForStackedBar.push(batchName);
    this.pollBatchStatusMap[batchName] = this.getAstroBatchStatusCount(optimizationRequest, batchName);
  }

  private pollForDialogBox(optimizationRequest: IAstroBatchOptimization[], batchName: string, isMultiBatch?: boolean): void {
    this.batchesForDialog.push(batchName);
    this.pollBatchStatusMap[batchName] = this._astroAccountHubService.useNewAstroService
      ? isMultiBatch
        ? this.getAstroBatchStatusCount(optimizationRequest, batchName)
        : this.getAstroBatchStatus(optimizationRequest, batchName)
      : this.getBatchStatus(batchName);
  }

  navigateToAstroBatches(batchName: string): void {
    if (this.batchStatus[batchName]?.isMultiBatchRequest && this.batchStatus[batchName]?.pendingCount <= 0) {
      this._router.navigate(['/eclipse/tradeorder/batches']);
    } else if (!this.batchStatus[batchName]?.isMultiBatchRequest) {
      this._router.navigate(['/eclipse/tradeorder/batches', this.batchStatus[batchName]?.batchName ?? batchName]);
    }
    this.onHide(batchName);
  }

  onMinimize(batchName: string): void {
    this.hideStatusDialog[batchName] = true;
    this.displaySpinner[batchName] = false;
  }

  onMaximize(batchName: string): void {
    this.hideStatusDialog[batchName] = false;
    this.displaySpinner[batchName] = true;
  }

  private onOptimizationComplete(batchStatus: IAstroBatchStatus): void {
    const batchName = batchStatus.batchName;
    if (this.batchStatus[batchName].pendingCount === 0) {
      this.batchesForStackedBar = this.batchesForStackedBar.filter(batch => batch !== batchName);
      this.unsubscribeBatch(batchName);
      this.isOptimizationComplete[batchName] = true;
      this.setBatchStatus(batchName, AstroOptimizationCompletionMessages.Comment, true, false,
      this.isOptimizationComplete[batchName], batchName, this.batchStatus[batchName]?.isMultiBatchRequest);
      if (this.optimizationStackedBarFlag) {
        this.batchesForDialog.push(batchName);
        this.displaySpinner[batchName] = true;
      }
    }
  }

  onHide(batchId: string): void {
    this.displaySpinner[batchId] = false;
    this.batchesForDialog.filter(batchName => batchName !== batchId);
    this.unsubscribeBatch(batchId);
    this.isOptimizationComplete[batchId] = false;
  }

  setBatchStatus(message: string, status: string, isReady: boolean, isError: boolean, isOptimizationComplete: boolean,
      batchId: string, isMultipleBatchOptimization?: boolean): void {
    this.batchStatus[batchId].comments = isMultipleBatchOptimization
      ? AstroOptimizationCompletionMessages.MultipleBatchOptimizationComplete
      : this.setBatchStatusComment(isOptimizationComplete, message);
    this.batchStatus[batchId].status = status;
    this.batchStatus[batchId].isReady = !isOptimizationComplete ? isReady : true;
    this.batchStatus[batchId].isError = isError;
  }

  private setBatchStatusComment(isOptimizationComplete: boolean, message: string): string {
    return isOptimizationComplete
      ? message || AstroOptimizationCompletionMessages.Comment
      : message;
  }

  isOptimizationCompleted(batchStatus: string): boolean {
    return (batchStatus && (batchStatus === AstroBatchStatus.Completed || batchStatus === AstroBatchStatus.Complete));
  }

  isOptimizationMissed(batchStatus: string): boolean {
    return (batchStatus && (batchStatus === AstroBatchStatus.NeverStarted || batchStatus === AstroBatchStatus.NeverReceived
      || batchStatus === AstroBatchStatus.Unknown));
  }

  updateOptimizationStatusDialog(batchStatus: IAstroBatchStatus): void {
    const batchName = batchStatus.batchName;
    if (this.batchStatus[batchName]?.isMultiBatchRequest) {
      this.isOptimizationComplete[batchName] = this.batchStatus[batchName].pendingCount === 0;
      this.unsubscribeBatchOnCompletion(batchName);
    } else if (this.batchStatus[batchName]?.isSingleBatchRequest) {
      this.isOptimizationComplete[batchName] = this.isOptimizationCompleted(batchStatus.status);
      this.batchStatus[batchName].isError = batchStatus.isError;
      this.setBatchStatus(batchStatus.message, batchStatus.status, false, batchStatus.isError,
        this.isOptimizationComplete[batchName], this.batchStatus[batchName].batchName);
      if (this.isOptimizationComplete[batchName] || this.isOptimizationMissed(batchStatus.status)) {
        this.unsubscribeBatch(batchName);
        this.onMaximize(batchName);
      } else if (this.batchStatus[batchName].isError) {
        this.onMaximize(batchName);
      }
    }
  }

  updateOptimizationStackedBar(batchName: string, successCount: number, failedCount: number, pendingCount: number, totalCount: number): void {
    this.stackedBarData[batchName] = {
      labels: [''],
      datasets: [{
        label: AstroOptimizationStackedBarLabel.Done,
        backgroundColor: '#367ce6',
        data: [successCount]
      }, {
        label: AstroOptimizationStackedBarLabel.Failed,
        backgroundColor: '#e63636',
        data: [failedCount]
      }, {
        label: AstroOptimizationStackedBarLabel.Pending,
        backgroundColor: '#F4F5F7',
        data: [pendingCount]
      }]
    };

    this.stackedBarOptions[batchName] = {
      animation: false,
      plugins: {
        legend: {
          display: false
        }
      },
      indexAxis: 'y',
      scales: {
        x: {
          display: false,
          stacked: true,
          max: totalCount
        },
        y: {
          stacked: true
        }
      }
    };
  }

  updateBatchStatusCounts(batchName: string, pendingCount: number): void {
    this.batchStatus[batchName].pendingCount = pendingCount;
    this.batchStatus[batchName].completedCount = this.batchStatus[batchName].totalCount - pendingCount;
    this.updateOptimizationStatusDialog(this.batchStatus[batchName]);
  }

  private subscribePollBatchStatus(): void {
    this.pollBatchStatusSubscription = this._astroAccountHubService.pollBatchStatus
      .subscribe((optimizationRequest: IAstroBatchOptimization[]) => {
        this.pollBatchStatus(optimizationRequest);
      });
  }

  private subscribeBatchCount(): void {
    this.batchCountSubscription = this._astroAccountHubService.getBatchCount
      .subscribe((batchCount: number) => {
        this.batchCount = batchCount;
      });
  }

  private subscribeDisplaySpinner(): void {
    this.displaySpinnerSubscription = this._astroAccountHubService.displaySpinner
      .subscribe((batchName: string) => {
        if (batchName !== AstroBatchStatus.Initiated) {
          this.displaySpinner[AstroBatchStatus.Initiated] = false;
        }
        this.displaySpinner[batchName] = true;
      });
  }

  private subscribePollingType(): void {
    this.batchCountSubscription = this._astroAccountHubService.getPollingType
      .subscribe((isMultiBatch: boolean) => {
        this.isPollingMultipleRequest = isMultiBatch;
      });
  }

  private subscribeAccountCount(): void {
    this.accountCountSubscription = this._astroAccountHubService.getAccountCount
      .subscribe((accountCount: number) => {
        this.accountCount = accountCount;
        this.isSingleAccountOptimization = this.accountCount === 1;
        this.isMultipleAccountOptimization = this.accountCount > 1;
      });
  }

  getOptimizationStackedBarFeatureFlag(): void {
    this._splitIoService.flagEnabled$(constants.SPLIT_IO_FLAG.SHOW_OPTIMIZATION_STACKED_BAR).subscribe(
      (enabled: boolean) => {
        this.optimizationStackedBarFlag = enabled;
      });
  }

  private unsubscribeBatchOnCompletion(batchName: string): void {
    if (this.isOptimizationComplete[batchName]) {
      this.onMaximize(batchName);
      this.unsubscribeBatch(batchName);
    }
  }

  private unsubscribeBatch(batchName: string): void {
    if (this.pollBatchStatusMap?.[batchName]) {
      this.pollBatchStatusMap[batchName].unsubscribe();
    }
  }
}
