import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import {
  ISmaWeighting,
  ISmaWeightingSaveModel,
  ITacticalSmaWeightingsModel,
  SMAWeightPostSaveActions,
} from '../../models/tactical';
import { Utils } from '../../core/functions';
import { AccountService } from '../../services/account.service';
import { IAccountSMA, IModelSubNode, IModelTypes } from '../../models/account';
import * as _ from 'lodash';
import { BaseComponent } from '../../core/base.component';
import * as Const from '../../libs/app.constants';

@Component({
  selector: 'eclipse-sma-weightings',
  templateUrl: './sma-weightings.component.html',
  styleUrls: ['./sma-weightings.component.scss']
})
export class SmaWeightingsComponent extends BaseComponent implements OnChanges {

  showSmaWeightingsPopup: boolean;
  SmaWeightingsModel: ITacticalSmaWeightingsModel;
  smaWeightingsGridOptions: GridOptions;
  smaWeightingsColumnDefs: ColDef[];
  modelSubNodes: IModelSubNode[] = [];
  isShowSmaSaveWarningPopup: boolean;
  nodesToBeSaved: ISmaWeightingSaveModel[];
  gridContext: { self: SmaWeightingsComponent };
  private gridApi: GridApi;

  @Input() accountId: number;
  @Input() isLoadedFromTactical: boolean;
  @Output() onPopupClose = new EventEmitter<void>();
  @Output() weightingsSaved: EventEmitter<SMAWeightPostSaveActions> = new EventEmitter<SMAWeightPostSaveActions>();

  constructor(private readonly _accountService: AccountService) {
    super();
    this.SmaWeightingsModel = <ITacticalSmaWeightingsModel>{};
    this.smaWeightingsGridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
    this.createSMAWeightingsColDefs();
    this.isShowSmaSaveWarningPopup = false;
    this.gridContext = {
      self: this,
    };

  }

  ngOnChanges(): void {
    if (this.accountId) {
      this.initializeSmaWeightingPopup();
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  createSMAWeightingsColDefs(): void {
    this.smaWeightingsColumnDefs = [
      <ColDef>{
        headerName: 'Id',
        field: 'id',
        width: 240,
        cellClass: 'text-center',
        hide: true
      },
      <ColDef>{
        headerName: 'Node Name',
        field: 'name',
        width: 230,
        cellClass: 'text-center',
        cellRenderer: this.nodeNameCellRenderer
      },
      <ColDef>{
        headerName: 'Percentage (%)',
        field: 'percent',
        width: 230,
        cellClass: 'text-center',
        cellRenderer: this.smaPercentCellRenderer
      },
      <ColDef>{
        headerName: 'Delete',
        field: 'delete',
        width: 162,
        cellClass: 'text-center',
        cellRenderer: this.deleteCellRenderer
      }
    ];
  }

  initializeSmaWeightingPopup(): void {
    this.SmaWeightingsModel.smaWeightings = [];
    this.getAccountModelTypes();
    this.showSmaWeightingsPopup = true;
  }

  nodeNameCellRenderer(params: ICellRendererParams<ISmaWeighting>): HTMLDivElement {
    const self = params.context.self;
    const SelectElementContainer = document.createElement('div');
    const selectElement = document.createElement('select');

    for (const weightings of self.modelSubNodes) {
      const optionElement = document.createElement('option');
      optionElement.innerHTML = weightings.subModelName;
      optionElement.setAttribute('value', weightings.subModelId);
      optionElement.setAttribute('modelId', weightings.modelId);
      optionElement.setAttribute('modelDetailId', weightings.modelDetailId);
      selectElement.appendChild(optionElement);
    }

    selectElement.value = String(params.data.subModelId);
    params.data.modelId = Number(selectElement.selectedOptions[0]?.getAttribute('modelId')) ?? null;
    params.data.modelDetailId = Number(selectElement.selectedOptions[0]?.getAttribute('modelDetailId')) ?? null;
    SelectElementContainer.appendChild(selectElement);
    selectElement.focus();

    selectElement.addEventListener('change', () => {
      self.onChangeSubNode(selectElement, params);
    });
    return SelectElementContainer;
  }

  smaPercentCellRenderer(params: ICellRendererParams<ISmaWeighting>): HTMLInputElement {
    const inputElement = document.createElement('input');
    inputElement.value = params.data.percent.toString() ?? '';
    inputElement.addEventListener('blur', () => {
      let updatedValue = Number(inputElement.value);
      if (updatedValue > 100) {
        updatedValue = 100;
      } else if (updatedValue < 0) {
        updatedValue = 0;
      }
      params.data.percent = updatedValue;
      inputElement.value = updatedValue.toString() ?? '';
      params.context.self.validateSmaWeightings();
    });

    inputElement.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        inputElement.blur();
        return;
      }
      let updatedValue = isNaN(Number(inputElement.value)) ? params.data.percent.toString() : inputElement.value;
      updatedValue = Number(updatedValue) > 100 || Number(updatedValue) < 0
        || (updatedValue.split('.')[1]?.length > 2) ? params.data.percent.toString() : updatedValue;
      params.data.percent = Number(updatedValue);
      inputElement.value = updatedValue.toString();
    });

    return inputElement;
  }

  deleteCellRenderer(params: ICellRendererParams<ISmaWeighting>): HTMLSpanElement {
    const DeleteButton = document.createElement('span');
    DeleteButton.innerHTML = `<i id= ${params.rowIndex} title="Delete" class="fas fa-times red" aria-hidden="true"></i>`;
    DeleteButton.addEventListener('click', () => {
      params.context.self.onDeleteRow(params);
    });
    return DeleteButton;
  }

  onChangeSubNode(selectElement: HTMLSelectElement, params: ICellRendererParams<ISmaWeighting>): void {

    const self = params.context.self;
    const newValue = selectElement.value ? selectElement.selectedOptions[0].textContent : '';

    // @ts-ignore
    params.data[params.colDef.field] = newValue;
    params.data.subModelId = Number(selectElement.value);
    params.data.modelId = Number(selectElement.selectedOptions[0].getAttribute('modelId'));
    params.data.modelDetailId = Number(selectElement.selectedOptions[0].getAttribute('modelDetailId'));

    const updatedNodes = [];
    updatedNodes.push(params.node);
    self.validateSmaWeightings();
    self.gridApi.refreshCells({ rowNodes: updatedNodes });
  }

  onDeleteRow(params: ICellRendererParams<ISmaWeighting>): void {
    this.gridApi.applyTransaction({ remove: [params.data] });
    const index = this.SmaWeightingsModel.smaWeightings.indexOf(params.data, 0);
    if (index > -1) {
      this.SmaWeightingsModel.smaWeightings.splice(index, 1);
      this.validateSmaWeightings();
      this.calculateTotalPercent();
    }
  }

  getAccountModelTypes(): void {
    this._accountService.getAccountModelTypes(this.accountId)
      .subscribe({
        next: (modelType: IModelTypes[]) => {
          this.SmaWeightingsModel.modelTypes = modelType;
          this.getSmaList();
        }
      });
  }

  getSmaList(): void {
    this._accountService.getSMAList(this.accountId)
      .subscribe({
        next: (smaListModel: IAccountSMA) => {
          this.SmaWeightingsModel.originalSmaList = _.cloneDeep(smaListModel);
          if (smaListModel.selectedLevelId === null || smaListModel.selectedLevelId === undefined && this.SmaWeightingsModel.modelTypes?.length > 0) {
            this.SmaWeightingsModel.selectedLevel = this.SmaWeightingsModel.modelTypes[0].id;
          } else {
            this.SmaWeightingsModel.selectedLevel = smaListModel.selectedLevelId;
          }
          this.SmaWeightingsModel.smaList = smaListModel;

          this.getSubNodesForModel(smaListModel);
        }
      });
  }

  getSubNodesForModel(smaListModel: IAccountSMA): void {
    this._accountService.getSubNodesForModel(this.accountId, this.SmaWeightingsModel.selectedLevel)
      .subscribe({
        next: (modelSubNode: IModelSubNode[]) => {
          this.modelSubNodes = modelSubNode;

          if (smaListModel.weightings?.length > 0) {
            for (const smaListItem of smaListModel.weightings) {
              const row = {
                id: smaListItem.id,
                subModelId: smaListItem.subModelId,
                name: smaListItem.subModelName,
                percent: smaListItem.weightPercent,
                modelId: smaListItem.modelId,
                modelDetailId: smaListItem.modelDetailId
              };
              this.SmaWeightingsModel.smaWeightings.push(row);
            }
          }

          this.gridApi.setGridOption('rowData', this.SmaWeightingsModel.smaWeightings);
          this.gridApi.sizeColumnsToFit();
          this.validateSmaWeightings();
        }
      });
  }

  calculateTotalPercent(): void {
    this.SmaWeightingsModel.smaTotalPercent = 0;
    this.SmaWeightingsModel.isSmaValidationError = false;
    if (!this.SmaWeightingsModel?.smaWeightings?.length) {
      return;
    }
    for (const smaWeighting of this.SmaWeightingsModel.smaWeightings) {
      if (!smaWeighting.percent) {
        this.SmaWeightingsModel.smaValidationError = smaWeighting.percent === 0 ? Const.SMA_EDIT_POPUP.ERROR_MESSAGES.ZERO_PERCENT_ERROR :
          Const.SMA_EDIT_POPUP.ERROR_MESSAGES.EMPTY_PERCENT_ERROR;
        this.SmaWeightingsModel.isSmaValidationError = true;
      } else {
        this.SmaWeightingsModel.smaTotalPercent = Utils.roundDecimal(this.SmaWeightingsModel.smaTotalPercent + smaWeighting.percent);
      }
    }
    if (this.SmaWeightingsModel.smaTotalPercent !== 100) {
      this.SmaWeightingsModel.smaValidationError = Const.SMA_EDIT_POPUP.ERROR_MESSAGES.TOTAL_NOT_HUNDRED_ERROR;
      this.SmaWeightingsModel.isSmaValidationError = true;
    }
  }

  onSmaEditCancel(): void {
    this.SmaWeightingsModel = <ITacticalSmaWeightingsModel>{};
    this.showSmaWeightingsPopup = false;
    this.isShowSmaSaveWarningPopup = false;
    this.onPopupClose.emit();
  }

  onAddRow(): void {
    if (this.SmaWeightingsModel.smaWeightings?.length === this.modelSubNodes?.length) {
      this.SmaWeightingsModel.smaValidationError = Const.SMA_EDIT_POPUP.ERROR_MESSAGES.INVALID_NODE_COUNT_ERROR;
      this.SmaWeightingsModel.isSmaValidationError = true;
      return;
    }
    const row = <ISmaWeighting>{
      id: null,
      percent: 0,
      subModelId: this.modelSubNodes[0].subModelId || null
    };
    this.SmaWeightingsModel.smaWeightings.push(row);
    this.gridApi.setGridOption('rowData', this.SmaWeightingsModel.smaWeightings);
    this.validateSmaWeightings();
  }

  onSmaSelectLevelChange(): void {
    this.SmaWeightingsModel.smaWeightings = [];
    this.validateSmaWeightings();
    if (this.SmaWeightingsModel.originalSmaList.selectedLevelId === Number(this.SmaWeightingsModel.selectedLevel)) {
      this.SmaWeightingsModel.selectedLevel = this.SmaWeightingsModel.originalSmaList.selectedLevelId;
      this.SmaWeightingsModel.smaList = _.cloneDeep(this.SmaWeightingsModel.originalSmaList);
      this.getSubNodesForModel(this.SmaWeightingsModel.smaList);
    } else {
      this.SmaWeightingsModel.smaList.weightings = [];
      this.SmaWeightingsModel.smaList.selectedLevelId = this.SmaWeightingsModel.selectedLevel;
      this.getSubNodesForModel(this.SmaWeightingsModel.smaList);
      this.SmaWeightingsModel.smaWeightings = [];
      this.gridApi.setGridOption('rowData', this.SmaWeightingsModel.smaWeightings);
      this.gridApi.sizeColumnsToFit();
    }
  }

  onSmaWeightingsSave(): void {
    this.gridApi.stopEditing();
    this.validateSmaWeightings();
    const nodes = <ISmaWeightingSaveModel[]>[];
    for (const smaWeight of this.SmaWeightingsModel.smaWeightings) {
      if (!smaWeight.subModelId) {
        this.SmaWeightingsModel.smaValidationError = Const.SMA_EDIT_POPUP.ERROR_MESSAGES.NO_NODE_SELECTED_ERROR;
        this.SmaWeightingsModel.isSmaValidationError = true;
        return;
      }
      if (smaWeight.id === null || smaWeight.id === undefined) {
        smaWeight.id = null;
        smaWeight.subModelId = Number(smaWeight.subModelId);
      }
      nodes.push({
        id: smaWeight.id,
        subModelId: smaWeight.subModelId,
        weightPercent: smaWeight.percent,
        modelId: Number(smaWeight.modelId),
        modelDetailId: Number(smaWeight.modelDetailId)
      });
    }
    this.nodesToBeSaved = nodes;
    if (!this.SmaWeightingsModel.isSmaValidationError) {
      if (this.isLoadedFromTactical) {
        this.isShowSmaSaveWarningPopup = true;
      } else {
        this.onSmaWeightingsSaveConfirm();
      }
    }
  }

  onSmaWeightingsSaveConfirm(isDiscardUnsavedTrades = false): void {
    this._accountService.updateSmaNodeDetails(this.accountId,
      {
        selectedLevelId: Number(this.SmaWeightingsModel.selectedLevel),
        weightings: this.nodesToBeSaved,
      },
    )
      .subscribe(() => {
        if (this.isLoadedFromTactical) {
          // Emit from here that trades should be saved (if launched from tactical).  When complete, run analytics and reload the portfolio.
          const postSaveActions: SMAWeightPostSaveActions = {
            saveTrades: !isDiscardUnsavedTrades,
            runAnaltyics: true,
            reloadPortfolio: true,
          };
          this.weightingsSaved.emit(postSaveActions);
        }
        this.onSmaEditCancel();
      });
  }

  validateSmaWeightings(): void {
    this.SmaWeightingsModel.isSmaValidationError = false;
    this.SmaWeightingsModel.smaValidationError = null;
    this.calculateTotalPercent();
    if (!this.SmaWeightingsModel?.smaWeightings?.length) {
      return;
    }
    if (this.SmaWeightingsModel.smaWeightings.filter(node => node.subModelId === undefined).length > 0) {
      this.SmaWeightingsModel.smaValidationError = Const.SMA_EDIT_POPUP.ERROR_MESSAGES.NO_NODE_SELECTED_ERROR;
      this.SmaWeightingsModel.isSmaValidationError = true;
      return;
    }
    if (this.SmaWeightingsModel.smaWeightings.filter(node => !node.percent).length > 0) {
      this.SmaWeightingsModel.smaValidationError = Const.SMA_EDIT_POPUP.ERROR_MESSAGES.EMPTY_PERCENT_ERROR;
      this.SmaWeightingsModel.isSmaValidationError = true;
      return;
    }
    if (new Set(this.SmaWeightingsModel.smaWeightings.map(node => Number(node.subModelId))).size !== this.SmaWeightingsModel.smaWeightings.length) {
      this.SmaWeightingsModel.smaValidationError = Const.SMA_EDIT_POPUP.ERROR_MESSAGES.DUPLICATE_NODE_ERROR;
      this.SmaWeightingsModel.isSmaValidationError = true;
      return;
    }
  }

  resetSmaWeightings(accountId: number): void {
    this.accountId = accountId;
    this.SmaWeightingsModel.selectedLevel = Const.SMA_EDIT_POPUP.DEFAULT_LEVEL;
    this.nodesToBeSaved = <ISmaWeightingSaveModel[]>[];
    this.onSmaWeightingsSaveConfirm();
  }

}
