import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { IExcludeFilterSave } from '../models/excludefilter';

@Injectable({
  providedIn: 'root'
})
export class ExcludeFilterService {

  private _excludeFilterEndPoint = 'v1/excludefilter/';

  constructor(private _httpClient: OEHttpClient) { }

  saveOrUpdateExcludeFilter(filters: IExcludeFilterSave, portfolioIds: number[]) {
    return this._httpClient.updateData(this._excludeFilterEndPoint, {filters: filters, portfolioIds: portfolioIds});
  }

  getExcludeFilter(portfolioId: number) {
    return this._httpClient.getData(`${this._excludeFilterEndPoint}?portfolioId=${portfolioId}`);
  }
}
