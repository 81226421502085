import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {ModelService} from '../../services/model.service';
import {IModel} from '../../models/modeling/model';
import { Utils as Util } from '../../core/functions';
import {ITradeGridItem} from '../../models/spendcash';

@Component({
  selector: 'eclipse-model-auto-complete',
  templateUrl: './model.autocomplete.component.html'
})
export class ModelAutoCompleteComponent extends BaseComponent {

  suggestions: any[] = [];
  selectedItem: string;

  @Input() isMacAssignment: boolean;
  @Output() parentCallback = new EventEmitter();
  @Input() List: Array<ITradeGridItem> = [];

  constructor(private _modelService: ModelService) {
    super();
  }


  /** To get models for search autoComplete  */
  loadSuggestions(event) {
    this._modelService.getModelSearch(event.query.toLowerCase())
      .subscribe((model: IModel[]) => {
        if (this.isMacAssignment) {
          this.suggestions = model.filter(a => !a.isDeleted && Util.getModelStatusId(a) === 1 && !a.isCommunityModel && !a.isDynamic);
        } else {
          const addedIds = this.List.map(l => l.id);
          this.suggestions = model.filter(a => !a.isDeleted && a.statusId !== null && !addedIds.includes(a.id));
        }
        this.formatModelSuggestion(this.suggestions, event);
      });
  }

  /** Fires on item select */
  onModelSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({value: this.selectedItem});
  }

}
