import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from '@ag-grid-community/angular';
import { IStatusPanelParams, Events } from '@ag-grid-community/core';

@Component({
  template: `
    <div class="ag-status-name-value" *ngIf="!!count">
      <span>Selected:&nbsp;</span>
      <span class="ag-status-name-value-value">{{ count }}</span>
    </div>
  `
})
export class SelectedCountComponent implements IStatusPanelAngularComp {
  private params!: IStatusPanelParams;
  public count: number | null = null;

  agInit(params: IStatusPanelParams): void {
    this.params = params;

    this.params.api.addEventListener(Events.EVENT_SELECTION_CHANGED, this.onSelectionChanged.bind(this));
    this.params.api.addEventListener(Events.EVENT_MODEL_UPDATED, this.onSelectionChanged.bind(this));
  }

  onSelectionChanged(): void {
    this.count = this.params.api.getSelectedNodes()?.filter(node => !node.group).length;
  }
}
