import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

type RequestLog = {
  url: string;
  uuid: string;
  timestamp: string;
};

/**
 * HTTP interceptor that monitors requests for duplicate x-requestIds.
 * Logs the offending requests to the console.
 */
@Injectable()
export class DuplicateRequestHttpInterceptor implements HttpInterceptor {
  readonly QUEUE_HISTORY_LENGTH: number = 50;
  private requestHistory: RequestLog[] = [];

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      if (request.headers.has('x-requestId')) {
        const uuid = request.headers.get('x-requestId');
        const currentRequest: RequestLog = {url: request.url, uuid: uuid, timestamp: new Date().toString()};

        const originalRequest = this.requestHistory.find(req => req.uuid === uuid);
        if (!!originalRequest) {
          console.error('ORIGINAL REQUEST', originalRequest.url, originalRequest.uuid, originalRequest.timestamp);
          console.error('DUPLICATE REQUEST', currentRequest.url, currentRequest.uuid, currentRequest.timestamp);
          console.trace('Call Stack:');
        }

        this.requestHistory.push(currentRequest);
        if (this.requestHistory.length > this.QUEUE_HISTORY_LENGTH) {
          this.requestHistory.shift(); // ensure the stack doesn't get too large
        }
      }
    } catch (ex) {
      console.error('Error logging duplicate request', ex);
    }
    return next.handle(request);
  }
}
