import { Component, inject, ViewEncapsulation } from '@angular/core';
import {
  EditorEntityType,
  EntityEditorComponent,
  EntityEditorService,
  IEntityEditorComponent,
} from '../../../shared/entity-editor';
import * as Consts from '../../../libs/app.constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoginAsService } from '../../../services/loginas.service';
import { PortfolioService } from '../../../services/portfolio.service';
import { PreferenceService } from '../../../services/preference.service';
import { WINDOW } from '../../../providers/window.provider';
import { Utils as Util } from '../../../core/functions';
import { IEntityChangedEvent } from '../../../shared/portfoliotree/portfolio-tree-item';
import { EntityType } from '../../../libs/preference.enums';
import { SplitIoService } from '../../../core/feature-flag';
import { MenuItem } from 'primeng/api';
import { PortfolioEditorService } from './portfolio-editor.service';
import {
  PortfolioAccountsComponent,
  PortfolioCompareToolComponent,
  PortfolioNotesComponent,
  PortfolioSummaryComponent
} from './';
import { catchError, filter, map, merge, Observable, of, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { PortfolioEditorPage, PortfolioEntity } from './types';
import { IAccount, IPortfolioDetails, IToken } from '../../../models/portfolio';
import { ConfigService } from '../../../config/config.service';

@Component({
  selector: 'eclipse-portfolio-editor',
  templateUrl: './portfolio-editor.component.html',
  styleUrls: ['./portfolio-editor.component.scss'],
  providers: [PortfolioEditorService],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioEditorComponent extends EntityEditorComponent implements IEntityEditorComponent {
  public accountAccessPermission: boolean = false;
  public accountEditPermission: boolean = false;
  public portfolioEditPermission: boolean = false;
  public readonly _portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public portfolio$: Observable<PortfolioEntity> = this._portfolioEditorService.portfolio$;
  massLiquidateFF: boolean = Util.hasTradeToolRight(Consts.PRIV_TACTICAL);
  compareToolEnabled: boolean = false;
  compareToolEnabledPreference: boolean = false;
  protected EditorPages: { [key in string]: { name: string; component: any } } =
    {
      Summary: {name: PortfolioEditorPage.Summary, component: PortfolioSummaryComponent},
      Cash: {name: PortfolioEditorPage.Cash, component: null},
      Accounts: {name: PortfolioEditorPage.Accounts, component: PortfolioAccountsComponent},
      Model: {name: PortfolioEditorPage.Model, component: null},
      Notes: {name: PortfolioEditorPage.Notes, component: PortfolioNotesComponent},
      GainLoss: {name: PortfolioEditorPage.GainLoss, component: null},
      Holdings: {name: PortfolioEditorPage.Holdings, component: null},
      TradeHistory: {name: PortfolioEditorPage.TradeHistory, component: null},
      AuditHistory: {name: PortfolioEditorPage.AuditHistory, component: null},
      SleeveMaintenance: {name: PortfolioEditorPage.SleeveMaintenance, component: null},
      Preferences: {name: PortfolioEditorPage.Preferences, component: null},
      CompareTool: {name: PortfolioEditorPage.CompareTool, component: PortfolioCompareToolComponent},
    };
  private _router: Router = inject(Router);
  private _loginAsService: LoginAsService = inject(LoginAsService);
  private _portfolioService: PortfolioService = inject(PortfolioService);
  private readonly _preferenceService: PreferenceService = inject(PreferenceService);
  private readonly _entityEditorService: EntityEditorService = inject(EntityEditorService);
  private readonly _splitIoService: SplitIoService = inject(SplitIoService);
  private readonly _window: Window = inject<Window>(WINDOW);
  private reverseSyncPortfolioPreference: boolean = false;
  private _activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  constructor() {
    super();
    const accountPriv = Util.getPermission(Consts.PRIV_ACCOUNTS);
    this.accountAccessPermission = !!accountPriv?.canRead;
    this.accountEditPermission = !!accountPriv?.canUpdate;
    this.portfolioEditPermission = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;

    this._preferenceService.getPreferenceByName('reverseSyncPortfolios')
      .subscribe(result => {
        this.reverseSyncPortfolioPreference = Util.convertIntoBooleanValue(result);
      });
    this._splitIoService.flagsEnabled$(['mass_liquidation_f211351'])
      .subscribe((flags: { [key: string]: boolean }) => {
        this.massLiquidateFF = flags['mass_liquidation_f211351'];
      });

    const compareToolFlag$ = this._splitIoService.flagEnabled('Eclipse_CompareTool_257656')
      .pipe(
        switchMap(flag => {
          this.compareToolEnabled = !!flag;
          if (this.isOrionAdmin) {
            return of(true);
          } else {
            return this._preferenceService.getPreferenceByName('compareToolEnabled')
              .pipe(
                map(result => Util.convertIntoBooleanValue(result))
              );
          }
        }),
        tap(result => this.compareToolEnabledPreference = result)
      );

    this.sidebarMenuItems$ = merge(this._portfolioEditorService.portfolio$, this._portfolioEditorService.notes$, this._activePage.asObservable(), compareToolFlag$)
      .pipe(
        map(() => this.getSidebarMenuItems())
      );

    this._router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(evt => evt instanceof NavigationEnd && evt.urlAfterRedirects.indexOf('editEntity') < 0))
      .subscribe(() => {
        this.visible = false;
      });
  }

  public get isFooterVisible(): boolean {
    return ![
      PortfolioEditorPage.Accounts,
      PortfolioEditorPage.CompareTool,
      PortfolioEditorPage.AuditHistory,
      PortfolioEditorPage.TradeHistory
    ]
      .includes(this._activePage.getValue());
  }

  public buildActionsMenu(): void {
    const options = {
      reverseSyncPortfolioPreference: this.reverseSyncPortfolioPreference,
      massLiquidateFF: this.massLiquidateFF,
    };
    this.actionItems = this.getActionsMenu(this._portfolioEditorService.portfolio, options);
  }

  ngOnInit() {
    this.contentContainer.clear();

    let initialTab: PortfolioEditorPage = PortfolioEditorPage.Summary;
    const currentParams = {...this._activatedRoute.snapshot.queryParams};
    if (!!currentParams.tab) {
      switch (currentParams.tab.toLowerCase()) {
        case 'cash':
          initialTab = PortfolioEditorPage.Cash;
          break;
        case 'accounts':
          initialTab = PortfolioEditorPage.Accounts;
          break;
        case 'notes':
          initialTab = PortfolioEditorPage.Notes;
          break;
        case 'model':
          initialTab = PortfolioEditorPage.Model;
          break;
        case 'gainloss':
          initialTab = PortfolioEditorPage.GainLoss;
          break;
        case 'holdings':
          initialTab = PortfolioEditorPage.Holdings;
          break;
        case 'preferences':
          initialTab = PortfolioEditorPage.Preferences;
          break;
        case 'comparetool':
          initialTab = PortfolioEditorPage.CompareTool;
          break;
        case 'details':
        case 'summary':
        default:
          break;
      }
    }

    this.loadComponent(initialTab);
    if (!this.entityEditorConfig.skipAddingQueryParameter) {
      this._entityEditorService.addQueryParameter();
    }
    this._portfolioEditorService.portfolioId$.next(this.entityEditorConfig?.data?.id);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  public closeEditor(): void {
    this._entityEditorService.close();
  }

  public savePortfolio(closeOnSuccess: boolean = false): void {
    this._portfolioEditorService.savePortfolio()
      .pipe(
        catchError(errors => {
          return throwError(() => errors);
        }),
        tap(result => {
          if (closeOnSuccess) {
            this.visible = false;
            return;
          }
          this._portfolioEditorService.portfolioId$.next(result.id);
        })
      ).subscribe();
  }

  public onTreeEntityChanged(entity: IEntityChangedEvent): void {
    switch (entity.entityType) {
      case EntityType.Portfolio:
        this._entityEditorService.show({
          entityType: EditorEntityType.Portfolio,
          data: {id: entity.id, editMode: !!this.portfolioEditPermission},
        });
        break;
      case EntityType.Account:
        this._entityEditorService.show({
          entityType: EditorEntityType.Account,
          data: {id: entity.id, editMode: false}, // always go to View page by default
        });
        break;
      default:
        console.error('Unsupported entity type', entity);
    }
  }

  /**
   * Opens the portfolio in the new editor experience.
   */
  openInLegacyEditor(): void {
    this._entityEditorService.show({
      entityType: EditorEntityType.Portfolio,
      data: {newEditor: false, editMode: this.portfolioEditPermission, id: this._portfolioEditorService.portfolio?.id}
    });
  }

  private getActionsMenu(portfolio: PortfolioEntity, options: any): MenuItem[] {
    if (!portfolio?.id) {
      return null;
    }

    const portfolioPrivilege = this._sessionHelper.getPermission(Consts.PRIV_PORTFOLIOS);
    const accountPrivilege = this._sessionHelper.getPermission(Consts.PRIV_ACCOUNTS);
    const preferencePermission = Util.getPermission(Consts.PRIV_PORTFOLIOPREF);
    const menuItems: MenuItem[] = [
      {
        label: 'Assign Portfolio',
        icon: 'fas fa-fw fa-briefcase-arrow-right',
        visible: !!portfolioPrivilege.canUpdate && !!accountPrivilege.canUpdate && !portfolio.isDisabled && !portfolio.isSleevePortfolio
      },
      {
        label: 'Delete',
        icon: 'fas fa-fw fa-trash-can',
        disabled: !!this._portfolioEditorService.accounts?.length,
        visible: !!portfolioPrivilege.canDelete && !portfolio.isSleevePortfolio
      },
      {
        label: 'Preferences',
        icon: 'fas fa-fw fa-list-check',
        visible: !!portfolioPrivilege.canUpdate && !!this._portfolioEditorService.portfolioId$.getValue(), /*&& !portfolio?.hasSMAs */
        command: () => {
          this._router.navigate(['/eclipse/admin/preferences/portfolio', this._portfolioEditorService.portfolio.id]);
        }
      },
      {
        label: 'Exclude/Include Portfolio(s)',
        icon: 'fas fa-fw fa-object-exclude',
        visible: !!portfolioPrivilege.canUpdate && !portfolio.isSleevePortfolio
      },
      {
        label: 'Portfolio Equivalents',
        icon: 'fas fa-fw fa-equals',
        visible: !!preferencePermission.canAdd || !!preferencePermission.canUpdate,
        items: [
          {
            label: 'Import/Export Equivalents',
          },
          {
            label: 'Import History',
          }
        ]
      },
      {
        label: 'Edit Sleeves',
        icon: 'fas fa-fw fa-pencil',
        visible: !!portfolioPrivilege.canUpdate && !!portfolio.isSleevePortfolio
      },
      {
        label: 'Sleeve Transfer',
        icon: 'fas fa-fw fa-swap',
        visible: !!portfolioPrivilege.canUpdate && !!portfolio.isSleevePortfolio
      },
      {
        label: 'Assign a MAC fund',
        icon: 'fas fa-fw fa-arrows-turn-to-dots',
        visible: !!portfolioPrivilege.canUpdate && !!portfolio.modelId
      },
      {
        separator: true
      },
      {
        label: 'Run Portfolio\'s Analytics',
        icon: 'fas fa-fw fa-bolt-lightning',
        disabled: portfolio.needAnalyticsStatus === 'In Progress'
      },
      {
        label: 'Reverse Sync Portfolio',
        icon: 'fas fa-fw fa-reply-clock',
        visible: options.reverseSyncPortfolioPreference
      },
      {
        label: 'Sync Accounts from Orion',
        icon: 'fas fa-fw fa-arrow-right-from-arc',
      },
      {
        separator: true
      },
      {
        label: 'Trade Tools',
        icon: 'fas fa-fw fa-display-chart-up-circle-dollar',
        items: [
          {
            label: 'Cash Needs',
            visible: Util.hasTradeToolRight(Consts.PRIV_CASHNEEDS),
            command: () => this._portfolioEditorService.navigateToTradeTool('CashNeeds'),
          },
          {
            label: 'Global Trades',
            visible: Util.hasTradeToolRight(Consts.PRIV_GLOBALTRADES),
            command: () => this._portfolioEditorService.navigateToTradeTool('GlobalTrades'),
          },
          {
            label: 'Option Trading',
            visible: Util.hasTradeToolRight(Consts.PRIV_OPTION_TRADING),
            command: () => this._portfolioEditorService.navigateToTradeTool('Options'),
          },
          {
            label: 'Raise Cash',
            visible: Util.hasTradeToolRight(Consts.PRIV_RAISECASH),
            command: () => this._portfolioEditorService.navigateToTradeTool('RaiseCash'),
          },
          {
            label: 'Rebalancer',
            visible: !portfolio.isDisabled && portfolio.doNotTrade && Util.hasTradeToolRight(Consts.PRIV_REBALANCER),
            command: () => this._portfolioEditorService.navigateToTradeTool('Rebalancer'),
          },
          {
            label: 'Spend Cash',
            visible: Util.hasTradeToolRight(Consts.PRIV_SPENDCASH),
            command: () => this._portfolioEditorService.navigateToTradeTool('SpendCash'),
          },
          {
            label: 'Tactical Tool',
            visible: Util.hasTradeToolRight(Consts.PRIV_TACTICAL),
            command: () => this._portfolioEditorService.navigateToTradeTool('Tactical'),
          },
          {
            label: 'Tax Harvesting',
            visible: Util.hasTradeToolRight(Consts.PRIV_TAXHARVESTING),
            command: () => this._portfolioEditorService.navigateToTradeTool('TLH'),
          },
          {
            label: 'Trade To Target (Account Level)',
            visible: Util.hasTradeToolRight(Consts.PRIV_TRADETOTARGET),
            command: () => this._portfolioEditorService.navigateToTradeTool('TradeToTarget'),
          },
          {
            label: 'Liquidate',
            visible: !!options.massLiquidateFF && Util.hasTradeToolRight(Consts.PRIV_LIQUIDATE),
            command: () => this._portfolioEditorService.navigateToTradeTool('Liquidate'),
          },
        ]
      }
    ];
    return menuItems;
  }

  private getSidebarMenuItems() {
    let menuItems: MenuItem[] = [
      {
        label: 'Portfolio Details',
        items: [
          {
            id: PortfolioEditorPage.Summary,
            label: 'Summary',
            styleClass: 'editor-active-nav-menu-item',
            command: (evt) => this.loadComponent(evt.item.id),
          },
          {
            id: PortfolioEditorPage.Cash,
            label: 'Cash',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: true,
            visible: false,// !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.Accounts,
            label: 'Accounts',
            disabled: !this._portfolioEditorService.portfolio?.id,
            command: (evt) => this.loadComponent(evt.item.id),
            visible: !!this._portfolioEditorService.portfolio?.id && this.accountAccessPermission,
          },
          {
            id: PortfolioEditorPage.Model,
            label: 'Model',//'<span>Model <i class="fas fa-octagon-xmark text-danger m-l-5"></i></span>',
            escape: false,
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: true,
            visible: false // !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.Notes,
            label: 'Notes',
            badge: this._portfolioEditorService.notes ? this._portfolioEditorService.notes.length.toString() : null,
            badgeStyleClass: this._portfolioEditorService.notes ? 'badge primaryAlt' : null,
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: true,
            visible: false, // !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.GainLoss,
            label: 'Gain/Loss',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: true,
            visible: false, // !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.Holdings,
            label: 'Holdings',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: true,
            visible: false, // !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.CompareTool,
            label: 'Compare Tool',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: !this.compareToolEnabled || !this.compareToolEnabledPreference || !this._portfolioEditorService.portfolio?.id,
            visible: !this.compareToolEnabled || !this.compareToolEnabledPreference || !!this._portfolioEditorService.portfolio?.id,
          },
        ],
      }, {
        label: 'Portfolio History',
        items: [
          {
            id: PortfolioEditorPage.TradeHistory,
            label: 'Trade History',
            visible: false, // !!this._portfolioEditorService.portfolio?.id,
            disabled: true,
          },
          {
            id: PortfolioEditorPage.AuditHistory,
            label: 'Audit History',
            //command: (evt) => this.loadComponent(evt.item.id),
            command: () => { // Temporary until Audit History editor page is created
              this._router.navigate(['/eclipse/portfolio/audit', this._portfolioEditorService.portfolio.id]);
            },
            disabled: !this._portfolioEditorService.portfolio?.id,
            visible: !!this._portfolioEditorService.portfolio?.id,
          },
        ],
      }, {
        label: 'Portfolio Configuration',
        items: [
          {
            id: PortfolioEditorPage.SleeveMaintenance,
            label: 'Sleeve Maintenance',
            visible: false, // Disabled for now until Sleeve Maintenance page is created //!!this._portfolioEditorService.portfolio?.isSleevePortfolio,
            disabled: !this._portfolioEditorService.portfolio?.id,
            command: () => {
              const portfolioId = this._portfolioEditorService.portfolio.id;
              this._portfolioService.getPortfolioAccounts(portfolioId)
                .pipe(
                  switchMap((accounts: IAccount[]) => {
                    const accountOrionFirmId = accounts[0].ocFirmId;
                    return this._loginAsService.getFirmToken(accountOrionFirmId);
                  }),
                  switchMap((token: IToken) => {
                    return this._portfolioService.getPortfolioById(portfolioId)
                      .pipe(
                        tap((portfolio: IPortfolioDetails) => {
                          const registrationId = portfolio.general.registrationId;
                          const connectSleeveEditUrl = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/integration.html?p=/portfolio/edit/registrations/${registrationId}?tabCode=sleeveSetup&m=crm&t=${token.orion_access_token}`;
                          this._window.open(connectSleeveEditUrl);
                        })
                      );
                  })
                ).subscribe();
            }
          },
          {
            id: PortfolioEditorPage.Preferences,
            label: 'Preferences',
            visible: false, // Hide until Preferences component is added to the editor
            disabled: !this._portfolioEditorService.portfolio?.id,
            command: () => {
              this._router.navigate(['/eclipse/admin/preferences/portfolio', this._portfolioEditorService.portfolioId$.value]);
              this.visible = false;
            },
          },
        ],
      },
    ];

    // If a root menu item has child items and they're all hidden, hide the root menu item too.
    menuItems = menuItems.filter(rootMenuItem => {
      return rootMenuItem.items?.length // must have child items
        && rootMenuItem.items.some(childItem => childItem.visible === undefined || childItem.visible); // items must be visible
    });

    // Set the active menu style on the item for the current page
    const setMenuItemStyle = (item: MenuItem) => {
      if (item.items?.length) {
        item.items.forEach(setMenuItemStyle);
        return;
      }
      item.styleClass = this._activePage.getValue() === item.id ? 'editor-active-nav-menu-item' : null;
    };
    menuItems.forEach(setMenuItemStyle);

    return menuItems;
  }
}
