import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  share,
  shareReplay,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { PortfolioService } from '../../../services/portfolio.service';
import { IEditPortfolio, IPortfolioTeam } from '../../../models/portfolio';
import { INote } from '../../../models/notes';
import { NotesService } from '../../../services/notes.service';
import { EntityType } from '../../../libs/preference.enums';
import { PortfolioEntity, PortfolioTacticalValidation } from './types';
import { ITeam } from '../../../models/team';
import { UserService } from '../../../services/user.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AlertService, SessionHelper } from '../../../core';
import { Router } from '@angular/router';
import { LIQUIDATE_CONSTANTS } from '../../../libs/app.constants';
import { Utils } from '../../../core/functions';
import { sortBy as _sortBy } from 'lodash';
import { IAccount } from '../../../models/account';

@Injectable()
export class PortfolioEditorService {
  public portfolioId$ = new BehaviorSubject<number>(undefined);
  public accounts$: Observable<IAccount[]>;
  public teams$: Observable<IPortfolioTeam[]>;
  public notes$: Observable<INote[]>;
  public portfolioNotes$: Observable<INote[]>;
  public teamNotes$: Observable<INote[]>;
  public portfolio$: Observable<PortfolioEntity>;
  private readonly _userService: UserService = inject(UserService);
  private readonly _portfolioService: PortfolioService = inject(PortfolioService);
  private readonly _notesService: NotesService = inject(NotesService);
  private _alertService: AlertService = inject(AlertService);
  private _localStorageService: StorageMap = inject(StorageMap);
  private _router: Router = inject(Router);
  private _sessionHelper: SessionHelper = inject(SessionHelper);

  constructor() {
    this.portfolio$ = this.portfolioId$
      .pipe(
        switchMap(id => forkJoin({
            portfolio: id ? this._portfolioService.getPortfolioSummary(id) : of(<PortfolioEntity>{teams: []}),
            accounts: id ? this._portfolioService.getPortfolioAccounts(id) : of([]),
          })
        ),
        tap(({portfolio, accounts}) => {
          portfolio.teamIds = portfolio.teams.map(team => team.id);
          portfolio.primaryTeamId = portfolio.teams.find(team => team.isPrimary)?.id;
          portfolio.model = {id: portfolio.modelId, name: portfolio.modelName, displayName: portfolio.modelName};
          portfolio.analytics = [{
            portfolioId: portfolio.id,
            editedDate: portfolio.analyticsEditedDate,
            needAnalytics: portfolio.needAnalytics,
            failedReason: portfolio.failedReason,
          }];
        }),
        tap(({portfolio, accounts}) => {
          // Create a backup of the portfolio.  Used to determine if property values have changed.
          this._portfolioOriginal.next(Object.freeze(Utils.deepClone(portfolio)));
          this._portfolio.next(portfolio);
          this._accounts.next(accounts);
        }),
        map(({portfolio}) => portfolio), // destructure to just return the portfolio
        shareReplay());

    this.accounts$ = this.portfolio$
      .pipe(
        map(() => this.accounts),
        shareReplay()
      );

    this.notes$ = this.portfolioId$
      .pipe(
        filter(id => !!id),
        switchMap(id => this._notesService.getNotesByEntity(id, EntityType.Portfolio)),
        tap(notes => this._notes.next(notes)),
        shareReplay());

    this.portfolioNotes$ = this.notes$
      .pipe(
        map(notes => notes.filter(note => note.displayNote && note.relatedType === EntityType.Portfolio || note.relatedType === EntityType.Account)),
        share());

    this.teamNotes$ = this.notes$
      .pipe(
        map(notes => notes.filter(note => note.relatedType === EntityType.Team)),
        share());

    this.teams$ = this.portfolio$.pipe(
      switchMap(() => this._userService.getUserSpecificTeams()),
      map((teams: ITeam[]) => {
        const primaryTeamId = this._portfolio.getValue().primaryTeamId;
        return teams.map<IPortfolioTeam>(team => ({
          id: team.id,
          name: team.name,
          isPrimary: primaryTeamId === team.id,
          portfolioAccess: team.portfolioAccess,
        }));
      }),
      map(teams => _sortBy(teams, ['name'])), // sort alphabetically
      tap(teams => this._teams.next(teams)),
      tap(teams => {
        this.portfolio.teams = teams.filter(team => this.portfolio.teamIds?.includes(team.id));
      }),
      shareReplay());
  }

  private _selectedTeams = new BehaviorSubject<IPortfolioTeam[]>([]);

  public get selectedTeams(): IPortfolioTeam[] {
    return this._selectedTeams.getValue();
  }

  private _teams = new BehaviorSubject<IPortfolioTeam[]>([]);

  public get teams(): IPortfolioTeam[] {
    return this._teams.getValue();
  }

  private _accounts = new BehaviorSubject<IAccount[]>(undefined);

  public get accounts(): IAccount[] {
    return this._accounts.getValue();
  }

  private _notes = new BehaviorSubject<INote[]>(undefined);

  public get notes(): INote[] {
    return this._notes.getValue();
  }

  private _portfolioOriginal = new BehaviorSubject<PortfolioEntity>(undefined);
  /**
   * The portfolio object as initially loaded.
   */
  public get portfolioOriginal(): PortfolioEntity {
    return this._portfolioOriginal.getValue();
  }

  private _portfolio = new BehaviorSubject<PortfolioEntity>(undefined);

  public get portfolio(): PortfolioEntity {
    return this._portfolio.getValue();
  }

  public validatePortfolio(): string[] {
    const errors: string[] = [];
    const portfolio = this.portfolio;
    if (!portfolio.name?.trim().length) {
      errors.push('Portfolio must have a name.');
    }
    if (!portfolio.teamIds?.length) {
      errors.push('Portfolio must be assigned to at least one team.');
    }
    if (!portfolio.primaryTeamId) {
      errors.push('Portfolio does not have a Primary Team assigned.');
    }
    if (portfolio.primaryTeamId && portfolio.teamIds?.length && !portfolio.teamIds.includes(portfolio.primaryTeamId)) {
      errors.push('Primary Team must be in the list of selected teams.');
    }
    return errors;
  }

  public savePortfolio(): Observable<any> {
    const validationErrors = this.validatePortfolio();
    if (validationErrors.length) {
      this._alertService.alert.emit(validationErrors.map(err => ({message: err, typeId: 4})));
      return throwError(() => validationErrors);
    }

    const editPortfolio: IEditPortfolio = <IEditPortfolio>{
      doNotTrade: +this.portfolio.doNotTrade,
      isSleevePortfolio: this.portfolio.isSleevePortfolio,
      modelId: this.portfolio.modelId,
      name: this.portfolio.name,
      primaryTeamId: this.portfolio.primaryTeamId,
      isMac: this.portfolio.isMac,
      rebalanceDay: this.portfolio.rebalanceDay,
      rebalanceTypeId: this.portfolio.rebalanceTypeId,
      rebalanceOptionId: this.portfolio.rebalanceOptionId,
      rebalanceOption: this.portfolio.rebalanceOption,
      buyEmphasisOn: this.portfolio.buyEmphasisOn,
      sellEmphasisOn: this.portfolio.sellEmphasisOn,
      tags: this.portfolio.tags,
      teamIds: this.portfolio.teamIds
    };

    if (!this.portfolio.id) {
      return this._portfolioService.createPortfolio(editPortfolio);
    } else {
      return this._portfolioService.updatePortfolio(this.portfolio.id, editPortfolio);
    }
  }

  navigateToTradeTool(tradeToolName: string): void {
    switch (tradeToolName) {
      case 'CashNeeds':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/cashneed'], {onSameUrlNavigation: 'reload'});
        break;
      case 'GlobalTrades':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/globaltrades', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'Liquidate':
        this._sessionHelper.set(LIQUIDATE_CONSTANTS.SESSION_SELECTED_IDS_KEY, {
          type: LIQUIDATE_CONSTANTS.TRADE_FILTER_METHOD.PORTFOLIO,
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate([LIQUIDATE_CONSTANTS.TOOL_ROUTE], {onSameUrlNavigation: 'reload'});
        break;
      case 'Options':
        this._portfolioService.getAccountsSimpleListByPortfolioIds([this.portfolio.id])
          .subscribe(result => {
            const accountIds = result.filter(a => !a.isSMA && (!a.isSleeve || a.sleeveType === 'Normal')).map(a => a.id);
            this._sessionHelper.set('selectedIds', {type: 'account', ids: accountIds.join()});
            this._router.navigate(['/eclipse/tradetool/option', 'account'], {onSameUrlNavigation: 'reload'});
          });
        break;
      case 'RaiseCash':
        this._sessionHelper.set('selectedIds', {type: 'portfolio', ids: this.portfolio.id.toString()});
        this._router.navigate(['/eclipse/tradetool/raisecash', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'SpendCash':
        this._sessionHelper.set('selectedIds', {type: 'portfolio', ids: this.portfolio.id.toString()});
        this._router.navigate(['/eclipse/tradetool/spendcash', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'Rebalancer':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/rebalancer', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'TLH':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/tlh'], {onSameUrlNavigation: 'reload'});
        break;
      case 'Tactical':
        const validationResults = this.validatePortfoliosForTacticalTool(this.portfolio);
        if (validationResults.errors.length > 0) {
          this._alertService.alert.emit(validationResults.errors);
        } else {
          this._localStorageService.set('TacticalPortfolioIds', [{
            id: validationResults.portfolioId,
            name: validationResults.portfolioName
          }]).subscribe();
          this._router.navigate(['/eclipse/tradetool/tactical'], {onSameUrlNavigation: 'reload'});
        }
        break;
      case 'TradeToTarget':
        this._portfolioService.getAccountsSimpleListByPortfolioIds([this.portfolio.id])
          .subscribe(result => {
            const accountIds = result.filter(a => !a.isSMA).map(a => a.id);
            if (!accountIds.length) {
            }
            this._sessionHelper.set('selectedIds', {type: 'account', ids: accountIds.join()});
            this._router.navigate(['/eclipse/tradetool/tradetotarget', 'account'], {onSameUrlNavigation: 'reload'});
          });
        break;
    }
  }

  private validatePortfoliosForTacticalTool(portfolio: PortfolioEntity): PortfolioTacticalValidation {
    const errors: { typeId: number; message: string }[] = [];

    if (portfolio.managedValue <= 0) {
      errors.push({typeId: 4, message: 'Portfolio with no Accounts or Managed Value is not > $0.'});
    }
    if (!portfolio.primaryTeamId) {
      errors.push({typeId: 4, message: 'Portfolio does not have \'Primary Team\' assigned.'});
    }
    if (portfolio.isDisabled) {
      errors.push({typeId: 4, message: 'Portfolio is disabled.'});
    }

    return {portfolioId: portfolio.id, portfolioName: portfolio.name, errors: errors};
  }
}

