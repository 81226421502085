import {Component} from '@angular/core';
import {AnalyticsCountUpdateHubService} from './shared/analytics-count-update-hub.service';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {BaseComponent} from '../../core/base.component';
import {
  IAnalyticsAccountDetail,
  IAnalyticsCountUpdate
} from '../../models/AnalyticsCountUpdate';
import {ModuleName} from '../../libs/app.constants';

@Component({
  selector: 'eclipse-analytics-count-update',
  templateUrl: './analytics-count-update.component.html',
  styleUrls: ['./analytics-count-update.component.scss']
})
export class AnalyticsCountUpdateComponent extends BaseComponent {
  public needAnalyticsUpdateCount: Number;
  public showNotificationMessage: boolean;
  public moduleName: string;
  private _userPortfolioName = 'userPortfolios';
  private showAndHideCountMessageNotificationSubscription: Subscription;
  private updateAnalyticsCountUpdateSubscription: Subscription;
  private removeIdsFromListSubscription: Subscription;
  private _portfolioIds = [] as number[];
  get portfolioIds(): number[] {
    return this._portfolioIds;
  }

  set portfolioIds(value: number[]) {
    this._portfolioIds = value;
  }

  private _accounts = [] as IAnalyticsAccountDetail[];
  get accounts(): IAnalyticsAccountDetail[] {
    return this._accounts;
  }

  set accounts(value: IAnalyticsAccountDetail[]) {
    this._accounts = value;
  }

  private _tradeIds = [] as number[];
  get tradeIds(): number[] {
    return this._tradeIds;
  }

  set tradeIds(value: number[]) {
    this._tradeIds = value;
  }

  constructor(private readonly _analyticsCountUpdateHubService: AnalyticsCountUpdateHubService) {
    super();
  }

  ngOnInit(): void {
    this.showAndHideCountMessageNotificationSubscription = this._analyticsCountUpdateHubService.showAndHideCountMessageNotification.subscribe((flag: boolean) => {
      this.showAndHideCountMessage(flag);
    });

    this.updateAnalyticsCountUpdateSubscription = this._analyticsCountUpdateHubService.updateAnalyticsCountUpdate.subscribe((analyticsCountUpdate: IAnalyticsCountUpdate) => {
      this.updateAnalyticsUpdateCount(analyticsCountUpdate);
    });

    this.removeIdsFromListSubscription = this._analyticsCountUpdateHubService.removeIdsFromListAndUpdateCount.subscribe((analyticsCountUpdate: IAnalyticsCountUpdate) => {
      this.removeIdsFromListAndUpdateCount(analyticsCountUpdate);
    });
  }

  ngOnDestroy() {
    this.showAndHideCountMessageNotificationSubscription?.unsubscribe();
    this.updateAnalyticsCountUpdateSubscription?.unsubscribe();
    this.removeIdsFromListSubscription?.unsubscribe();
  }

  public showAndHideCountMessage(flag: boolean): void {
    this.showNotificationMessage = flag;
    if (!this.showNotificationMessage) {
      this.portfolioIds = [];
      this.accounts = [];
    }
  }

  private updateAnalyticsUpdateCount(analyticsCountUpdate: IAnalyticsCountUpdate): void {
    this.moduleName = analyticsCountUpdate.moduleName;
    if (this.moduleName === ModuleName.Portfolio) {
      this.updatePortfolioAnalyticsUpdateCount(analyticsCountUpdate.portfolioIds);
    } else if (this.moduleName === ModuleName.Account) {
      this.updateAccountAnalyticsUpdateCount(analyticsCountUpdate.accounts);
    } else if (this.moduleName === ModuleName.Trade) {
      this.updateTradeAnalyticsUpdateCount(analyticsCountUpdate.tradeIds);
    }
  }

  public updatePortfolioAnalyticsUpdateCount(portfolioIds: number[]): void {
    if (portfolioIds && portfolioIds.length) {
      const permissionPortfolioIds = this._sessionHelper.get<number[]>(this._userPortfolioName);
      this.portfolioIds = [...this.portfolioIds, ...portfolioIds];
      this.portfolioIds = this.portfolioIds.filter(portfolioId => {
        return permissionPortfolioIds && permissionPortfolioIds.includes(portfolioId);
      });
      this.portfolioIds = _.uniq(this.portfolioIds.map(Number));
      this.showNotificationMessage = !!this.portfolioIds.length;
      this.needAnalyticsUpdateCount = this.portfolioIds.length;
    }
  }

  public updateAccountAnalyticsUpdateCount(accounts: IAnalyticsAccountDetail[]): void {
    if (accounts && accounts.length) {
      const permissionPortfolioIds = this._sessionHelper.get<number[]>(this._userPortfolioName);
      accounts = accounts.filter(account => {
        return permissionPortfolioIds && permissionPortfolioIds.includes(account.portfolioId);
      });
      this.accounts = [...this.accounts, ...accounts];
      this.accounts = _.uniqBy(this.accounts, 'accountId');
      this.showNotificationMessage = !!this.accounts.length;
      this.needAnalyticsUpdateCount = this.accounts.length;
    }
  }

  private removeIdsFromListAndUpdateCount(analyticsCountUpdate: IAnalyticsCountUpdate): void {
    this.moduleName = analyticsCountUpdate.moduleName;
    if (this.moduleName === ModuleName.Portfolio) {
      this.removePortfolioFromListAndUpdateCount(analyticsCountUpdate.portfolioIds);
    } else if (this.moduleName === ModuleName.Account) {
      this.removeAccountsFromListAndUpdateCount(analyticsCountUpdate.accounts);
    } else if (this.moduleName === ModuleName.Trade) {
      this.removeTradesFromListAndUpdateCount();
    }
  }

  public removePortfolioFromListAndUpdateCount(portfolioIds: number[]) {
    if (portfolioIds && portfolioIds.length) {
      this.portfolioIds = _.difference(this.portfolioIds, portfolioIds);
      this.portfolioIds = _.uniq(this.portfolioIds.map(Number));
      this.showNotificationMessage = !!this.portfolioIds.length;
      this.needAnalyticsUpdateCount = this.portfolioIds.length;
    }
  }

  public removeAccountsFromListAndUpdateCount(accounts: IAnalyticsAccountDetail[]) {
    if (accounts && accounts.length) {
      this.accounts = _.differenceBy(this.accounts, accounts, 'accountId');
      this.accounts = _.uniqBy(this.accounts, 'accountId');
      this.showNotificationMessage = !!this.accounts.length;
      this.needAnalyticsUpdateCount = this.accounts.length;
    }
  }

  public updateTradeAnalyticsUpdateCount(tradeIds: number[]): void {
    if (tradeIds?.length) {
      this.tradeIds = [...this.tradeIds, ...tradeIds];
      this.tradeIds = _.uniq(this.tradeIds);
      this.showNotificationMessage = !!this.tradeIds.length;
      this.needAnalyticsUpdateCount = this.tradeIds.length;
    }
  }

  public removeTradesFromListAndUpdateCount() {
    this.tradeIds = [];
    this.showNotificationMessage = !!this.tradeIds.length;
    this.needAnalyticsUpdateCount = this.tradeIds.length;
  }
}
