<div class="firm-security-setting-header">
  <div class="form-inline">
    <div class="form-group" [hidden]="!canEdit">
      <div class="portfoliodetails-search-add">
        <eclipse-security-autocomplete
          [filterOutSecurityIds]="filterOutSecurityIds"
          [isMoneyMarketSecurity]="true"
          (onSearchSecuritySelect)="searchSecurity($event) ">
        </eclipse-security-autocomplete>
      </div>
      <span class="add-button">
          <a href="javascript:void(0)" (click)="addSecurity()" [class.disabled]="isDisableAddButton"><i
            class="fas fa-plus-circle" aria-hidden="true"></i></a>
        </span>
    </div>

    <div class="grid-control">
      <ag-grid-angular style="width: 100%; height: 150px;" class="ag-theme-alpine"
                       [gridOptions]="gridOptions"
                       (gridReady)="onGridReady($event)"
                       rowSelection="single"
                       [columnDefs]="columnDefinitions"
                       [rowData]="moneyMarketFundPreference.moneyMarketFunds"
                       [context]="gridContext"
                       [getContextMenuItems]="getContextMenuItems"
                       [components]="frameworkComponents">
      </ag-grid-angular>
    </div>
  </div>
</div>
