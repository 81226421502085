import {ITooltipAngularComp} from '@ag-grid-community/angular';
import {ITooltipParams} from '@ag-grid-community/core';
import {Component} from '@angular/core';

/**
 * Ag-grid tooltip component for displaying an array of values on multiple lines.
 */
@Component({
	selector: 'eclipse-aggrid-multiline-tooltip-component',
	template: `
        <div *ngIf="!!this.values?.length" class="multiline-tooltip-container">
            <p *ngFor="let value of values" class="p-tooltip-text">{{value}}</p>
        </div>
	`,
  styleUrls: ['./multiline-tooltip.component.scss']
})
export class MultilineTooltipComponent implements ITooltipAngularComp {
	public values = [];

	agInit(params: ITooltipParams): void {
		if (!!params.value?.length) {
			this.values = params.value.filter(x => x);
		}
	}
}
