import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { filter } from 'rxjs/operators';
import { IUser } from '../../models/user';
import { UserService } from '../../services/user.service';
import { SessionHelper } from '../session.helper';

export enum Theme {
  Light = 'light',
  Dark = 'dark'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _currentTheme: Theme;
  public get currentTheme(): Theme {
    return this._currentTheme;
  }

  public set currentTheme(value: Theme) {
    if(value !== this._currentTheme) {
      this._currentTheme = value;
      this.updateUserTheme();
    }
    this.updateBodyTheme();
  }

  constructor(private readonly _authService: AuthService, private readonly _sessionHelper: SessionHelper, private readonly _userService: UserService) {
    this.setThemeFromUser(this._authService.currentUser);
    this._authService.currentUser$
      .pipe(filter(x => !!x))// filter out null users
      .subscribe(newUser => {
        this.setThemeFromUser(newUser);
      });
  }

  private setThemeFromUser(user: IUser): void {
    if (!user) {
      return;
    }
    this._currentTheme = user?.theme || Theme.Dark;
    this.updateBodyTheme();
  }

  public updateUserTheme(): void {
    const changes = [{
      op: 'replace',
      path: 'Theme',
      value: this.currentTheme
    }];
    this._userService.patchUser(this._authService.currentUser.id, changes).subscribe(() => {
      const user = this._sessionHelper.getUser();
      user.theme = this.currentTheme;
      this._sessionHelper.set('user', user);
    });
  }

  private updateBodyTheme(): void {
    if ((document.body.classList.contains('dark') && this.currentTheme === Theme.Light)
      || (!document.body.classList.contains('dark') && this.currentTheme === Theme.Dark)) {
      document.body.classList.toggle('dark');
    }
  }
}
