import { Component, Input, HostListener } from '@angular/core';
import { GridOptions, ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import {ICapitalGainReinvestPreference, ICapitalGainReinvestPreferencesGet} from '../../models/preferences/capitalgainreinvestpreference';
import {SecurityService} from '../../services/security.service';
import { Utils as Util } from '../../core/functions';
import {BaseComponent} from '../../core/base.component';

@Component({
  selector: 'eclipse-capitalgain-reinvest',
  templateUrl: './capitalgainreinvest.component.html'
})
export class CapitalGainReInvestComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  PrefrowData: ICapitalGainReinvestPreference[] = [];
  selectedSecurityType: any = null;
  selectedSecurityTypeId: number = null;
  capitalGainReinvestPref: ICapitalGainReinvestPreference = <ICapitalGainReinvestPreference>{};
  securityTypeId: number;
  securitySymbol: string;
  type: string = '$';
  securityTypes: any[];
  securityTypesHidden: any[];
  securityTypesInherited: any[];
  yesOrNOoData: any[] = [];

  canShow: boolean = true;
  canRead: boolean = false;
  btnDisableSetPref: boolean = true;
  displayCapitalGainReinvestPref: boolean = false;
  isInherited: boolean = false;
  isPreferenceEdited: boolean = false;

  @Input() displayPermission: string;
  @Input() fromParent: ICapitalGainReinvestPreferencesGet;
  @Input() st: any[];
  fromParentPrefLevel: string;
  clonedSecurityTypes: any[]; /** Security Types :: New approach  */

  constructor(private _securityService: SecurityService) {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit() {
    this.PrefrowData = this.fromParent.capitalGainReinvestPreferences;
    this.fromParentPrefLevel = this.fromParent.levelName.toLowerCase();
    this.resetSecurityTypes();
    this.getYesOrNoOptions();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  /** Reset all security types that are customized */
  resetSecurityTypes() {
    if (!this.isInherited) {
      this.securityTypesHidden = Util.deepClone(this.st);
      this.securityTypes = Util.deepClone(this.st);
      this.clonedSecurityTypes = Util.deepClone(this.st);
      /** Splice the existing security Types */
      this.spliceExistingSecTypes();
      this.PrepareColdef(); /** Security Types :: New approach  */
    } else {
      this.handleSecTypesAfterInherit(this.st);
      this.PrepareColdef(); /** Security Types :: New approach  */
    }
  }

  /** Splice already selected Security type/s from existing Security Types  */
  spliceExistingSecTypes() {
    if (this.PrefrowData) {
      const secTypes = this.PrefrowData.map(m => m.securityTypeId);
      secTypes.forEach(m => {
        // eslint-disable-next-line eqeqeq
        this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.id == m), 1);
        Util.sortBy(this.securityTypes, 'name');
      });
    }
  }

  /** Security Type on Dropdown selection changed event */
  handleSelectedSecurityType(securityTypeId) {
    if (+securityTypeId) {
      this.btnDisableSetPref = false;
      this.securityTypeId = securityTypeId;
      this.selectedSecurityTypeId = +securityTypeId;
      // eslint-disable-next-line eqeqeq
      const secType = this.securityTypes.filter(x => x.id == +securityTypeId);
      // eslint-disable-next-line eqeqeq
      this.selectedSecurityType = (secType.length > 0 && secType != null) ? secType[0].name : '';
    } else {
      this.btnDisableSetPref = true;
    }
  }

  /** to open preferences popup*/
  setPrefPopup(selectedSecurityType) {
    this.selectedSecurityTypeId = null;
    this.displayCapitalGainReinvestPref = true;
    this.resetForm();
    this.bindCapitalGainReinvestEmptyData(selectedSecurityType);
  }

  /** Find index of expanded row  */
  findExpandedRowIndex(array, key, val) {
    for (let i = 0; i < array.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (array[i][key] == val) {
        return i;
      }
    }
    return null;
  }

  /** To Reset Form */
  resetForm() {
    this.capitalGainReinvestPref = <ICapitalGainReinvestPreference>{};
    this.btnDisableSetPref = true;
    this.isPreferenceEdited = false;
  }

  /** Bind Capital Gain Reinvest fee pref empty data */
  bindCapitalGainReinvestEmptyData(selectedSecurityType) {
    this.capitalGainReinvestPref = <ICapitalGainReinvestPreference>{
      id: selectedSecurityType,
      securityTypeId: selectedSecurityType,
      securityType: null,
      capGainReinvestTaxable: null,
      capGainReinvestTaxExempt: null,
      capGainReinvestTaxDef: null,
      inheritedFrom: null
    };
  }

  /** Prepare colDefs based on Preference level **/
  PrepareColdef() {
    const self = this;
    if (this.displayPermission) {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Taxable', field: 'capGainReinvestTaxable', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agTextColumnFilter' },
        <ColDef>{ headerName: 'Tax Deferred', field: 'capGainReinvestTaxDef', width: 180, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Tax Exempt', field: 'capGainReinvestTaxExempt', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
      ];
    } else {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Taxable', field: 'capGainReinvestTaxable', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agTextColumnFilter' },
        <ColDef>{ headerName: 'Tax Deferred', field: 'capGainReinvestTaxDef', width: 180, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Tax Exempt', field: 'capGainReinvestTaxExempt', width: 110, cellClass: 'text-center', cellRenderer: this.yesOrNoRenderer, filter: 'agNumberColumnFilter' },
        <ColDef>{
          headerName: 'Inherited from', field: 'inheritedFrom', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter'
        }
      ];
    }
    this.columnDefs.forEach(c => c.menuTabs = []);
  }

  /**Yes no Renderer */
  yesOrNoRenderer(params) {
    const col = params.column.colDef.field;
    const obj = params.data[col];
    // eslint-disable-next-line eqeqeq
    return obj == true ? 'Yes' : obj == false ? 'No' : '';
  }

  /** Fires when row is selected */
  rowSelected(event) {
    let result = '';
    // eslint-disable-next-line eqeqeq
    result = this.clonedSecurityTypes.find(m => m.id == +event.data.securityTypeId).name;
    this.selectedSecurityType = result;
  }

  /** security Type based on its id */
  securityTypeCellRenderer(params, self) {
    let result = '';
    const checkSecurityType = this.clonedSecurityTypes.filter(m => m.id === +params.node.data.securityTypeId);
    /** NEW LOGIC FOR SECURITY TYPES RENDERING */
    result = (checkSecurityType.length > 0) ? checkSecurityType[0].name : result;
    return result;
  }

  /** method to display context menu on accounts agGrid*/
  getContextMenuItems(params) {
    const contextResult = [];
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Edit Preferences">Edit Preferences</hidden>' });
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Delete Preferences">Delete Preferences</hidden>' });
    return contextResult;
  }

  /** Hostlistner  */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement.title === 'Edit Preferences') {
      this.isPreferenceEdited = true;
      this.displayCapitalGainReinvestPref = true;
      // eslint-disable-next-line eqeqeq
      this.capitalGainReinvestPref = Object.create(this.PrefrowData.find(x => x.securityTypeId == targetElement.id));
      this.securityTypeId = +this.capitalGainReinvestPref.securityTypeId;
      /** Assiging preferences because to get all fields in edit.
       (getting only changed fields because of object.create)
       (object.create is for clearing the fields on cancel)
       */
      this.capitalGainReinvestPref.securityTypeId = +this.capitalGainReinvestPref.securityTypeId;
      // this.capitalGainReinvestPref.capGainReinvestTaxable = this.capitalGainReinvestPref.capGainReinvestTaxable;
      // this.capitalGainReinvestPref.capGainReinvestTaxExempt = this.capitalGainReinvestPref.capGainReinvestTaxExempt;
      // this.capitalGainReinvestPref.capGainReinvestTaxExempt = this.capitalGainReinvestPref.capGainReinvestTaxExempt;
    }

    if (targetElement.title === 'Delete Preferences') {
      const selectedPrefToDelete = +targetElement.id;
      const rowData = this.gridApi.getGridOption('rowData');
      // eslint-disable-next-line eqeqeq
      rowData.splice(rowData.findIndex(x => x.securityTypeId == +selectedPrefToDelete), 1);
      this.gridApi.setGridOption('rowData', rowData);
      // eslint-disable-next-line eqeqeq
      const deletedObject = this.securityTypesHidden.find(x => x.id == +selectedPrefToDelete);
      this.securityTypes.push(deletedObject);
      Util.sortBy(this.securityTypes, 'name');
    }
  }

  /** cancels the operation and closes the pop-up */
  cancel() {
    this.displayCapitalGainReinvestPref = false;
    this.bindCapitalGainReinvestEmptyData(this.capitalGainReinvestPref);
  }

  /** Adding to grid */
  addtoGrid() {
    this.displayCapitalGainReinvestPref = false;
    // eslint-disable-next-line eqeqeq
    this.capitalGainReinvestPref.id = (this.capitalGainReinvestPref.id != undefined) ? +this.capitalGainReinvestPref.id : null;
    this.capitalGainReinvestPref.securityTypeId = +this.securityTypeId;
    this.capitalGainReinvestPref.securityType = null;
    // eslint-disable-next-line eqeqeq
    this.capitalGainReinvestPref.capGainReinvestTaxable = (this.capitalGainReinvestPref.capGainReinvestTaxable != undefined) ? this.capitalGainReinvestPref.capGainReinvestTaxable : null;
    // eslint-disable-next-line eqeqeq
    this.capitalGainReinvestPref.capGainReinvestTaxDef = (this.capitalGainReinvestPref.capGainReinvestTaxDef != undefined) ? this.capitalGainReinvestPref.capGainReinvestTaxDef : null;
    // eslint-disable-next-line eqeqeq
    this.capitalGainReinvestPref.capGainReinvestTaxExempt = (this.capitalGainReinvestPref.capGainReinvestTaxExempt != undefined) ? this.capitalGainReinvestPref.capGainReinvestTaxExempt : null;
    // eslint-disable-next-line eqeqeq
    const match = this.PrefrowData.filter(x => x.securityTypeId == +this.capitalGainReinvestPref.securityTypeId);
    if (match.length > 0) {
      match[0] = this.capitalGainReinvestPref;
      const indexVal = this.findExpandedRowIndex(this.PrefrowData, 'securityTypeId', match[0].securityTypeId);
      this.PrefrowData[indexVal] = match[0];
    } else {
      this.PrefrowData.push(this.capitalGainReinvestPref);
    }
    this.gridApi.setGridOption('rowData', this.PrefrowData);
    if (!this.isPreferenceEdited) {
      // eslint-disable-next-line eqeqeq
      this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.name == this.selectedSecurityType), 1);
      Util.sortBy(this.securityTypes, 'name');
    }
    this.resetForm();
  }

  /*  binding InheritedSecurity Preference to gridOptions */
  bindCapitalGainReinvestData(securityResults) {
    this.isInherited = true;
    this.resetForm();
    this.PrefrowData = this.fromParent.capitalGainReinvestPreferences;
    this.PrepareColdef();
    this.resetSecurityTypes();
    this.capitalGainReinvestPref = <ICapitalGainReinvestPreference>{
      id: null,
      securityTypeId: null,
      securityType: null,
      capGainReinvestTaxable: null,
      capGainReinvestTaxDef: null,
      capGainReinvestTaxExempt: null,
      inheritedFrom: null
    };
  }

  /** Handle the security types after inherit */
  handleSecTypesAfterInherit(securitieTypesResult: any) {
    this.securityTypesInherited = securitieTypesResult;
    const inheriteds = this.fromParent.inheritedCapitalGainReinvestPreferences;
    const arrInheriteds = inheriteds.map(m => m.securityTypeId);
    if (inheriteds.length > 0) {
      arrInheriteds.forEach(secId => {
        // eslint-disable-next-line eqeqeq
        this.securityTypesInherited.splice(this.securityTypesInherited.findIndex(sc => sc.id == secId), 1);
      });
      this.securityTypes = this.securityTypesInherited;
      Util.sortBy(this.securityTypes, 'name');
    } else {
      this.securityTypes = securitieTypesResult;
      Util.sortBy(this.securityTypes, 'name');
    }
    this.isInherited = false;
  }

  /** Gets Yes or No options based on dropdown selection */
  getYesOrNoOptions() {
    this.yesOrNOoData = this._securityService.getYesOrNoValuesForPreferences();
  }

  /** Set Dropdown values based on selection */
  setDropdownSelectedOption(event, taxexemptname) {
    // eslint-disable-next-line eqeqeq
    if (event == '0: null') {
      switch (taxexemptname) {
        case 'capGainReinvestTaxable':
          this.capitalGainReinvestPref.capGainReinvestTaxable = null;
          break;
        case 'capGainReinvestTaxDef':
          this.capitalGainReinvestPref.capGainReinvestTaxDef = null;
          break;
        case 'capGainReinvestTaxExempt':
          this.capitalGainReinvestPref.capGainReinvestTaxExempt = null;
          break;
      }
    }
  }

}
