import {Injectable} from '@angular/core';
import {ISecuritySet} from '../models/securityset';
import {OEHttpClient} from '../core';
import {SECURITY_SET_TEMPLATE} from "../libs/template.constants";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SecuritySetService {

  private _securitySetEndPoint = 'v1/security/securityset';
  private _securitySetEndPointV2 = 'v2/securityset';
  private _sellPriorityEndPoint = 'v1/security/securityset/sellpriority';
  private _buyPriorityEndPoint = 'v1/security/securityset/buypriority';
  private _equivalentTypeEndpoint = 'v1/security/securityset/equivalentType';
  private _blankTemplate = 'v2/Trading/Template';

  constructor(private _httpClient: OEHttpClient) {
  }

  getSecuritySetData(securitySetFilterTypeId?: number, excludeCommunitiesModels?: boolean) {
    if(!excludeCommunitiesModels){
      excludeCommunitiesModels = false;
    }

    return (+securitySetFilterTypeId === 0)
      ? this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?excludeCommunitiesModels=${excludeCommunitiesModels}`)
      : this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?includeUnassignedAccounts=1&excludeCommunitiesModels=${excludeCommunitiesModels}`);
  }

  getSecuritySetById(securitySetId: number) {
    return this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?securitySetId=${securitySetId}`);
  }


  getSecuritySetFilters() {
    return this._httpClient.getData(`${this._securitySetEndPoint}/filterTypes`);
  }

  /** get self directed model by accountId */
  getSelfDirectedModelDataByAccountId(accountId: number) {
    return this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?includeSelfDirected=2&selfDirectedAccountId=${accountId}`);
  }

  /** get all models including self directed */
  getModelsIncludingSelfDirected(includeSelfDirected: Boolean) {
    if (includeSelfDirected) {
      return this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?includeSelfDirected=1`);
    } else {
      return this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets`);
    }
  }

  /** get only self directed models */
  getOnlySelfDirectedModels() {
    return this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?includeSelfDirected=2`);
  }

  /** get all unassigned models */
  getUnassignedAccounts() {
    return this._httpClient.getData(`${this._securitySetEndPointV2}/GetSecuritySets?includeSelfDirected=1&includeUnassignedAccounts=1`);
  }

  getAllSecuritySet() {
    return this._httpClient.getData(`${this._securitySetEndPoint}/all`);
  }

  canDeleteSecuritySet(modelId: number, securitySetId: number, modelDetailId: number) {
    return this._httpClient.getData(`${this._securitySetEndPoint}/${securitySetId}/canDelete?modelId=${modelId}&modelDetailId=${modelDetailId}`);
  }

  deleteSecuritySet(securitySetId: number) {
    return this._httpClient.deleteData(`${this._securitySetEndPoint}/${securitySetId}`);
  }

  getSecuritySetDetail(id: number) {
    return this._httpClient.getData(`${this._securitySetEndPoint}/${id}`);
  }

  getSecuritySetDetailHistory(id: number, startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._securitySetEndPointV2}/${id}/detailHistory?fromDate=${startDate}&toDate=${endDate}`)
  }

  getSecuritySetHistory(id: number, startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._securitySetEndPointV2}/${id}/history?fromDate=${startDate}&toDate=${endDate}`)
  }

  getAllSecuritySetDetail(ids) {
    return this._httpClient.downloadFile(`${this._securitySetEndPoint}/detail`, ids);
  }

  saveSecuritySet(securitySet: ISecuritySet) {
    return this._httpClient.postData(this._securitySetEndPoint, securitySet);
  }

  updateSecuritySet(securityset: ISecuritySet) {
    return this._httpClient.updateData(`${this._securitySetEndPoint}/${securityset.id}`, securityset);
  }

  getSellPriorityList() {
    return this._httpClient.getData(this._sellPriorityEndPoint);
  }

  getBuyPriorityList() {
    return this._httpClient.getData(this._buyPriorityEndPoint);
  }

  searchSecuritySet(searchString: string) {
    return this._httpClient.getData(`${this._securitySetEndPoint}?search=${searchString}`);
  }

  updateSecuritySetFavorite(securitySetid: number, isFavoriteStatus: boolean) {
    return this._httpClient.updateData(`${this._securitySetEndPoint}/favorites/${securitySetid}`, {isFavorite: isFavoriteStatus});
  }

  getSecuritySetByAssestId(searchString: string) {
    return this._httpClient.getData(this._securitySetEndPoint + searchString);
  }

  canDeleteSecuritySetWithoutModel(securitySetId: number) {
    return this._httpClient.getData(`${this._securitySetEndPoint}/${securitySetId}/canDelete`);
  }

  /** Get all equivalent types */
  getEquivalentTypes() {
    return this._httpClient.getData(this._equivalentTypeEndpoint);
  }

  /*Getting required fields for securities in security set */

  getSecurityDetailInSecuritySet(id: number, excluded?: boolean, targetPercentZeroExcluded?: boolean, modelId?: number) {
    if (excluded && targetPercentZeroExcluded) {
      return this._httpClient.getData(`${this._securitySetEndPoint}/details/${id}?excluded=${excluded}&targetPercentZeroExcluded=${targetPercentZeroExcluded}&modelId=${modelId}`);
    } else {
      return this._httpClient.getData(`${this._securitySetEndPoint}/details/${id}`);
    }
  }

  getDetailedSecurityDetailInSecuritySet(id: number) {
    return this._httpClient.getData(`${this._securitySetEndPoint}/${id}`);
  }

  exportSecuritySetBlankTemplate(): Observable<any> {
    const fileName = SECURITY_SET_TEMPLATE.SECURITY_SET;
    return this._httpClient.getData(`${this._blankTemplate}?path=${fileName}`);
  }
}
