import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { OEHttpClient } from '../core';
import { ICategory } from '../models/category';
import { IClass } from '../models/class';
import { ISubClass } from '../models/subClass';
import { ISecurity, ISecurityModel } from '../models/security';
import { ISecurityPreference, ISecuritySimple } from '../models/preferences/securityPreference';
import { Utils as Util } from '../core/functions';
import { ConfigService } from '../config/config.service';

@Injectable()
export class SecurityService {
  private _categoryEndPoint = 'v1/security/categories';
  private _classEndPoint = 'v1/security/classes';
  private _subClassEndPoint = 'v1/security/subclasses';
  private _securityEndPoint = 'v1/security/securities';
  private _securityTypeEndPoint = 'v1/security/securities/securitytype';
  private _securitySearchEndPoint = 'v1/security/securities/orion';
  private _securityStatusEndPoint = 'v1/security/securities/securitystatus';
  private _securityAuditEndPoint = 'v1/auditlog/security/';
  private _tradeSymbolAuditEndPoint = 'v1/auditlog/securityTradeSymbol/';
  private _securityMaintainEndpoint = 'v1/security/securities/maintain';
  private _v2SecurityEndPoint = 'v2/Security';
  private readonly _topResults = 20;

  private yesOrNoOptions: any[] = [];

  constructor(private _httpClient: OEHttpClient, private _configService: ConfigService, ) {
    const searchProperty = ConfigService.settings.searchProperties.find(t => t.moduleName === 'securities');
    this._topResults = searchProperty.top;
  }

  /**
   * Get all categories
   */
  getCategoryData(): Observable<ICategory[]> {
    return this._httpClient.getData(this._categoryEndPoint);
  }

  /**
   * Get all classes
   */
  getClassData(): Observable<IClass[]> {
    return this._httpClient.getData(this._classEndPoint);
  }

  /**
   * Get all sub classes
   */
  getSubClassData(): Observable<ISubClass[]> {
    return this._httpClient.getData(this._subClassEndPoint);
  }

  saveCategory(category: ICategory) {
    return this._httpClient.postData(this._categoryEndPoint, category);
  }

  saveClass(assetClass: IClass) {
    return this._httpClient.postData(this._classEndPoint, assetClass);
  }

  saveSubClass(subClass: ISubClass) {
    return this._httpClient.postData(this._subClassEndPoint, subClass);
  }

  updateCategory(category: ICategory) {
    return this._httpClient.updateData(`${this._categoryEndPoint}/${category.id}`, category);
  }

  updateClass(assetClass: IClass) {
    return this._httpClient.updateData(`${this._classEndPoint  }/${assetClass.id}`, assetClass);
}

  updateSubClass(subClass: ISubClass) {
    return this._httpClient.updateData(`${this._subClassEndPoint}/${subClass.id}`, subClass);
  }

  deleteCategory(categoryId: number) {
    return this._httpClient.deleteData(`${this._categoryEndPoint}/${categoryId}`);
  }

  deleteClass(assetClassId: number) {
    return this._httpClient.deleteData(`${this._classEndPoint}/${assetClassId}`);
  }

  deleteSubClass(subClassId: number) {
    return this._httpClient.deleteData(`${this._subClassEndPoint}/${subClassId}`);
  }

  getSecurityData(filterId: number = 0) {
    if (filterId !== 0) {
      return this._httpClient.getData(`${this._securityEndPoint}?filter=${filterId}`);
    } else {
      return this._httpClient.getData(this._securityEndPoint);
    }
  }

  getSecurityDetail(id) {
    return this._httpClient.getData(`${this._securityEndPoint}/${id}`);
  }

  getSecurityType() {
    return this._httpClient.getData(this._securityTypeEndPoint);
  }

  searchSecurity(searchString: string) {
    return this._httpClient.getData(`${this._securitySearchEndPoint}?search=${searchString}&top=${this._topResults}`);
  }

  searchSecurityFromOrionEclipse(searchString: string, securityStatus: string, isMoneyMarketSecurity = null): Observable<ISecurity[]> {
    let url = `${this._securityEndPoint}?search=${searchString}&status=${securityStatus}&top=${this._topResults}`;
    if (Util.getBooleanValue(isMoneyMarketSecurity) !== null) {
      url = `${url}&isMoneyMarketSecurity=${isMoneyMarketSecurity}`;
    }
    return this._httpClient.getData(url);
  }

  saveSecurity(securityId: number) {
    return this._httpClient.postData(this._securityEndPoint, { id: securityId });
  }

  updateSecurity(security: ISecurity) {
    return this._httpClient.updateData(`${this._securityEndPoint}/${security.id}`, security);
  }


  deleteSecurity(securityId: number) {
    return this._httpClient.deleteData(`${this._securityEndPoint}/${securityId}`);
  }

  getSecuritiesByCategory(id: number, securityStatus: string) {
    return this._httpClient.getData(`${this._securityEndPoint}?assetCategoryId=${id}&status=${securityStatus}`);
  }

  getSecuritiesByClass(id: number, securityStatus: string) {
    return this._httpClient.getData(`${this._securityEndPoint}?assetClassId=${id}&status=${securityStatus}`);
  }

  getSecuritiesBySubClass(id: number, securityStatus: string) {
    return this._httpClient.getData(`${this._securityEndPoint}?assetSubClassId=${id}&status=${securityStatus}`);
  }

  getSecurityStatusList(): Observable<string[]> {
    return this._httpClient.getData(this._securityStatusEndPoint);
  }

  getSecurityPreferences(searchString: string) {
    const securityPref = <ISecurityPreference[]>[];
    securityPref.push(<ISecurityPreference>{
      id: 1, securityName: 'Security Test',
      redemptionFeeTypeId: 1, redemptionFeeAmount: 40, redemptionFeeDays: 4, sellTradeMinAmtBySecurity: 100,
      sellTradeMinPctBySecurity: 20, buyTradeMinAmtBySecurity: 200, buyTradeMinPctBySecurity: 30, buyTradeMaxAmtBySecurity: 800,
      buyTradeMaxPctBySecurity: 60, sellTradeMaxAmtBySecurity: 900, sellTradeMaxPctBySecurity: 80,
      alternatives: [{taxableAlternate: { id: 12, name: 'Apple' },
        taxDeferredAlternate: { id: 14, name: 'Intel' },
        taxExemptAlternate: { id: 12, name: 'Apple' }}],
      taxableDivReInvest: true, taxDefDivReinvest: false, taxExemptDivReinvest: false,
      capGainReinvestTaxable: true, capGainsReinvestTaxDef: false, capGainsReinvestTaxExempt: true,
      sellTransactionFee: 1000, buyTransactionFee: 2000,
      custodianRedemptionFeeTypeId: 2,
      custodianRedemptionDays: 20,
      custodianRedemptionFeeAmount: 56, excludeHolding: true
    });
    securityPref.push(<ISecurityPreference>{
      id: 1, securityName: 'Security Test 2',
      redemptionFeeTypeId: 2, redemptionFeeAmount: 40, redemptionFeeDays: 4, sellTradeMinAmtBySecurity: 100,
      sellTradeMinPctBySecurity: 20, buyTradeMinAmtBySecurity: 200, buyTradeMinPctBySecurity: 30, buyTradeMaxAmtBySecurity: 800,
      buyTradeMaxPctBySecurity: 60, sellTradeMaxAmtBySecurity: 900, sellTradeMaxPctBySecurity: 80,
      alternatives: [{taxableAlternate: { id: 12, name: 'Apple' },
        taxDeferredAlternate: { id: 13, name: 'Google' },
        taxExemptAlternate: { id: 12, name: 'Apple' }}],
      taxableDivReInvest: true, taxDefDivReinvest: false, taxExemptDivReinvest: false,
      capGainReinvestTaxable: true, capGainsReinvestTaxDef: false, capGainsReinvestTaxExempt: true,
      sellTransactionFee: 1000, buyTransactionFee: 2000,
      custodianRedemptionFeeTypeId: 2,
      custodianRedemptionDays: 20,
      custodianRedemptionFeeAmount: 90, excludeHolding: false
    });
    return securityPref;
  }

  getTaxalternates(searchString: string) {
    const alternate = <ISecuritySimple[]>[];
    alternate.push(<ISecuritySimple>{ id: 12, symbol: 'Apple' });
    alternate.push(<ISecuritySimple>{ id: 13, symbol: 'Google' });
    alternate.push(<ISecuritySimple>{ id: 14, symbol: 'Intel' });
    alternate.push(<ISecuritySimple>{ id: 15, symbol: 'Microsoft' });

    return alternate;
  }
  getYesOrNoValuesForPreferences() {
    this.yesOrNoOptions = [];
    this.yesOrNoOptions.push(
      { id: 'null', value: null, name: 'Select' },
      { id: true, value: true, name: 'Yes' },
      { id: false, value: false, name: 'No' }
    );
    return this.yesOrNoOptions;
  }
  /**get Corporate Action details */
  getCorporateActions(id) {
    return this._httpClient.getData(`${this._securityEndPoint}/${id}/corporateAction`);
  }
  /**add Corporate Actions */
  addCorporateActions(id, corporateAction) {
    return this._httpClient.postData(`${this._securityEndPoint}/${id}/corporateAction`, corporateAction);
  }

  /** To search sell securitites for trades */
  /**
	 * The "excludeCustodialCash" field is currently set to true only for quick trades.
	 * And it will exclude custodial cash securities, except for money market securities.
	 */
  getSellSecurities(searchString: string, tradeFilterMethod: string, selectedIds: number[], excludeCustodialCash: boolean = true) {
    if (tradeFilterMethod === 'portfolio') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&portfolioIds=${selectedIds.join()}&excludeCustodialCash=${excludeCustodialCash}`);
    }
    if (tradeFilterMethod === 'account') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&accountIds=${selectedIds.join()}&excludeCustodialCash=${excludeCustodialCash}`);
    }
    if (tradeFilterMethod === 'model') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&modelIds=${selectedIds.join()}&excludeCustodialCash=${excludeCustodialCash}`);
    }
    if (tradeFilterMethod === 'tradeGroupsForPortfolio') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&tradeGroupsForPortfolioIds=${selectedIds.join()}&excludeCustodialCash=${excludeCustodialCash}`);
    }
    if (tradeFilterMethod === 'tradeGroupsForAccount') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&tradeGroupsForAccountId=${selectedIds.join()}&excludeCustodialCash=${excludeCustodialCash}`);
    }
    if (tradeFilterMethod === 'tradeGroups') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&tradeGroupIds=${selectedIds.join()}&excludeCustodialCash=${excludeCustodialCash}`);
    }
  }

  /** To search buy securities */
  searchBuySecurity(searchString: string, isFromGlobalTrades: boolean = false) {
    searchString = searchString.trim();
    if (searchString === '') {
      return EMPTY;
    } else if (isFromGlobalTrades) {  /** Removed 'excludeCashSecurity=true' flag, to include 'custodial cash' on BUY security search as per the BA comments - #OEMP-48 */
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}`);
    } else {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}`);
    }
  }

  /** To Search by Trade Groups for Trade To Target */
  searchByTradeSecurities(searchString: string, tradeFilterMethod: string, selectedIds: number[]) {
    if (tradeFilterMethod === 'account' || tradeFilterMethod === 'model' || tradeFilterMethod === 'excelImport') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&excludeCashSecurity=true&top=${this._topResults}`);
    } else if (tradeFilterMethod === 'tradeGroups') {
      return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&tradeGroupIds${selectedIds.join()}`);
    }
  }

  /** To get accounts count of a security */
  getAcountsCountOfSecurity(securityId: number, tradeFilterMethod: string, selectedIds: number[]) {
    // SF-145656 convert the get request to post request for avoiding the 414 error.
    if (tradeFilterMethod === 'portfolio') {
      return this._httpClient.postData(`${this._securityEndPoint}/${securityId}/count`, {portfolioIds:  selectedIds.join()});
    }
    if (tradeFilterMethod === 'account') {
      return this._httpClient.postData(`${this._securityEndPoint}/${securityId}/count`, {accountIds: selectedIds.join()});
    }
    if (tradeFilterMethod === 'model') {
      return this._httpClient.postData(`${this._securityEndPoint}/${securityId}/count`, {modelIds : selectedIds.join()});
    }
    if (tradeFilterMethod === 'tradeGroupsForPortfolio') { /** Need to verify and remove */
      return this._httpClient.postData(`${this._securityEndPoint}/${securityId}/count`, {tradeGroupsForPortfolioIds: selectedIds.join()});
    }
    if (tradeFilterMethod === 'tradeGroups') {
      return this._httpClient.postData(`${this._securityEndPoint}/${securityId}/count`, {tradeGroupIds: selectedIds.join()});
    }
  }

  /** To search buy securities */
  searchSecurityforBlocks(searchString: string) {
    return this._httpClient.getData(`${this._securityEndPoint}?search=${searchString}&excludeMutualFund=true` + `&top=${this._topResults}`);
  }
  getSellSecuritiesByActionMenu(searchString: string, selectedIds: any) {
    let urlParams: any = `${this._securityEndPoint}?search=${searchString}&top=${this._topResults}&portfolioIds=${selectedIds.portfolioIds[0]}`;
    if (!Util.isNull(selectedIds.securityId)) { urlParams += `&securityId=${selectedIds.securityId}`; }
    if (!Util.isNull(selectedIds.accountId)) { urlParams += `&accountIds=${selectedIds.accountId}`; }
    if (!Util.isNull(selectedIds.modelIds)) { urlParams += `&modelIds=${selectedIds.modelIds}`; }
    return this._httpClient.getData(urlParams);
  }
  /** get corporate action types master data*/
  corporateActionsMasterdata() {
    return this._httpClient.getData(`${this._securityEndPoint}/corporateActionTypes`);
  }

  /** Get Security Audit Data */
  getSecurityAuditData(id: number, startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._securityAuditEndPoint + id}?toDate=${endDate}&fromDate=${startDate}`);
  }

  /** Get Trade symbol Audit Data */
  getTradeSymbolAuditData(id: number, startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._tradeSymbolAuditEndPoint + id}?toDate=${endDate}&fromDate=${startDate}`);
  }

  /** patch update security detail */
  patchUpdateSecurity(securityId: number, keyName: string, value: string) {
    const patachdata = {
      data: [
        {
          op: 'replace',
          path: `/${keyName}`,
          value: value.toString()
        }
      ]
    };
    return this._httpClient.patchData(`${this._securityEndPoint}/${securityId}`, patachdata);
  }

  updateSecurityMaintaenceLocation(location: string) {
    return this._httpClient.getData(`${this._securityMaintainEndpoint}/${location}`);
  }

  /**get security alternate preferences which are set at preferences */
  getSecurityAlternatePreferences(securityId: number) {
    return this._httpClient.getData(`${this._securityEndPoint}/${securityId}/securityAlternatePreferences`);
  }

  /**get security minimum initial buy amount values which are set at preferences */
  getSecurityMinimumInitialBuyAmountPreferences(securityId: number) {
    return this._httpClient.getData(`${this._securityEndPoint}/${securityId}/securityMinimumInitialBuyAmountPreferences`);
  }

  /**get security trade priority (buy/sell) values which are set at preferences */
  getSecurityTradePriorityPreferences(securityId: number, priority: string) {
    return this._httpClient.getData(`${this._securityEndPoint}/${securityId}/securityPriorityPreferences?priority=${priority}`);
  }

  /**Get security filters */
  getSecurityFilters() {
    return this._httpClient.getData(`${this._securityEndPoint}/securityFilters`);
  }
  getSleeveSellSecurities(securityId: number, portfolioId: number, accountId: number) {
    return this._httpClient.getData(`${this._securityEndPoint}?search=${securityId}&top=${this._topResults}&portfolioIds=${portfolioId}&accountIds=${accountId}`);
  }

  setdoNotTLH(securityIds) {
    return this._httpClient.postData(`${this._securityEndPoint}/setDoNotTLH` , {securityIds: securityIds});
  }

  getSecurityModels(securityIds: number[]): Observable<ISecurityModel[]> {
    return this._httpClient.postData(`${this._v2SecurityEndPoint}/getSecurityModels`, securityIds);
  }
}
