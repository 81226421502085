import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export const confirmDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (target) => {
  return target?.canDeactivate ? target.canDeactivate() : true;
};
