<div class="row">
  <div class="col-lg-12">
    <div class="form-group">
      <label [title]="" class="control-label col-md-4 col-xs-8">
        <span [innerText]="toleranceSettingsBuy[0].displayName"></span>
        <a href="javascript:void();" data-toggle="tooltip" [title]="toleranceSettingsBuy[0].helpText"
           class="btn-help">
          <i class="fas fa-question-circle" aria-hidden="true"></i>
        </a>
      </label>

      <div class="col-md-1 col-xs-4"></div>
      <div class="col-md-4 col-xs-12" style="display: inline-flex;">
        <div class="checkbox">
          <label>
            <input type="Checkbox" [id]='toleranceSettingsBuy[0].name'
                   [checked]="(toleranceSettingsBuy[0].value !=null && toleranceSettingsBuy[0].value !='')? true : false"
                   (change)="setMaxPecentNode($event)"/>
          </label>
        </div>
        <div class="field-control">
          <input *ngIf="showMaxPercentNode" class="form-control placeholder-input"
                 [(ngModel)]="toleranceSettingsBuy[0].value"
                 (keydown)="toleranceSettingsBuy[0].value=$event.target.value"
                 [id]="toleranceSettingsBuy[0].name" [type]="toleranceSettingsBuy[0].valueType"
                 [pattern]="toleranceSettingsBuy[0].pattern"
                 [step]="(toleranceSettingsBuy[0].valueType =='number')? '1':'0.0'"
                 (keypress)="setValid($event,toleranceSettingsBuy[0])"
                 [min]="toleranceSettingsBuy[0].minValue" [max]="toleranceSettingsBuy[0].maxValue"
                 (keyup)="emptyError()"
                 (invalid)="validateTextBox(toleranceSettingsBuy[0].value)"
                 [placeholder]="toleranceSettingsBuy[0].watermarkText"
                 onpaste="return false"/>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="form-group">
      <label class="control-label col-md-4 col-xs-8">
        <span [innerText]="toleranceSettingsBuy[1].displayName"></span>
        <a href="javascript:void(0);" data-toggle="tooltip" [title]="toleranceSettingsBuy[1].helpText"
           class="btn-help">
          <i class="fas fa-question-circle" aria-hidden="true"></i>
        </a>
      </label>
      <div class="col-md-1 col-xs-2"></div>
      <div class="col-md-7 col-xs-12">
        <div class="field-control">
          <select [id]="toleranceSettingsBuy[1].name" class="form-control" [(ngModel)]="toleranceSeleted"
                  (change)="dropdownSelectedOption($event)"
                  title="Default">
            <option *ngFor="let opt of toleranceSettingsBuy[1].options" [id]="opt.name"
                    [value]="opt.name">{{opt.name}}</option>
          </select>
        </div>
        <a href="javascript:void(0)" class="btn-reset">
          <i class="fas fa-undo" (click)="setResetControl($event,toleranceSettingsBuy[1])"
             [id]="toleranceSettingsBuy[1].name" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div *ngIf="toleranceSeleted =='Out of Tolerance'" class="form-group">
      <div *ngFor="let pref of objToleranceSettingBuy" class="row">
        <label class="control-label col-md-4 col-xs-12">
          <span [innerText]="pref.displayName"></span>
          <a href="javascript:void(0);" data-toggle="tooltip" [title]="pref.helpText" class="text-info btn-help">
            <i class="fas fa-question-circle" aria-hidden="true"></i>
          </a>
        </label>

        <div class="col-md-1 col-xs-12"></div>
        <div class="col-md-7 col-xs-12">
          <div class="field-control">
            <label [title]="">
              <input type="Checkbox" [id]="pref.name" [name]="pref.name" [value]="pref.value"
                     [checked]="(pref.value == 'true')? true : false"
                     (change)="setCheckBoxValue($event)"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
