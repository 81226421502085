import {
  IComplianceAccount,
  ICompliancePortfolio,
  IComplianceSecurity,
  IComplianceTrade,
  IComplianceTradeRequest,
  IComplianceTrader,
  ITradeReviewRequest
} from '../../../../models/compliance';
import {SessionHelper} from '../../../../core';
import {
  IAccountAndCashDetail,
  IPortfolioSummary, ITacticalSecurity,
  ITacticalTaxLot,
  ITacticalTrade,
  TradeAction
} from '../../../../models/tactical';
import {ORDER_TYPE, tacticalDecimalEnum} from '../../../../libs/app.constants';

export class ComplianceHelper {

  static portfolioTolerance: string;
  static portfolioCashTolerance: string;

  static getComplianceTrades(trades: ITacticalTrade[], portfolio: IPortfolioSummary, accounts: IAccountAndCashDetail[],
                             taxLots: ITacticalTaxLot[], securities: ITacticalSecurity[]): ITradeReviewRequest {
    const account = accounts.find(a => a.id === trades[0].accountId);
    const security = securities.find(s => s.id === trades[0].securityId);
    account.postTradeCashAmount = trades[0].cashValuePostTrade;

    const quantity = trades.reduce((prev, next) => prev + next.tradeShares, 0);
    const tradeAmount = Math.abs(trades.reduce((prev, next) => prev + next.tradeAmount, 0));

    const marketValue = taxLots.filter(t => t.accountId === trades[0].accountId && t.securityId === trades[0].securityId)
      .reduce((prev, next) => prev + next.marketValue, 0);

    const accountOrderPercent = (tradeAmount / (account.managedValue + account.excludedValue)) * 100;
    const portfolioOrderPercent = (tradeAmount / (portfolio.managedValue + portfolio.excludedValue)) * 100;

    const accountHoldingUnits = taxLots.filter(t => t.accountId === trades[0].accountId && t.securityId === trades[0].securityId)
      .reduce((prev, next) => prev + next.quantity, 0);

    const accountTaxLots = taxLots.filter(t => t.accountId === trades[0].accountId && t.securityId === trades[0].securityId);
    const accountTotalHolding = ComplianceHelper.getAccountAndPortfolioHoldingUnit(accountTaxLots, trades[0].action, tradeAmount);

    const portfolioTaxLots = taxLots.filter(t => t.securityId === trades[0].securityId);
    const portfolioTotalHolding = ComplianceHelper.getAccountAndPortfolioHoldingUnit(portfolioTaxLots, trades[0].action, tradeAmount);

    return {
      ...ComplianceHelper.getComplianceTrade(trades, portfolio, account, quantity, accountOrderPercent, portfolioOrderPercent, marketValue),
      account: ComplianceHelper.getComplianceAccount(account, accountHoldingUnits, accountTotalHolding),
      portfolio: ComplianceHelper.getCompliancePortfolio(portfolio, portfolioTotalHolding),
      security: ComplianceHelper.getComplianceSecurity(security, trades[0]),
      trader: ComplianceHelper.getComplianceTrader()
    };
  }

  private static getComplianceTrade(trades: ITacticalTrade[], portfolio: IPortfolioSummary, account: IAccountAndCashDetail,
                                    quantity: number, accountOrderPercent: number, portfolioOrderPercent: number,
                                    marketValue: number): IComplianceTrade {
    const portfolioAccount = portfolio.accounts.find(a => a.accountId === trades[0].accountId);
    const sessionHelper = new SessionHelper();
    return {
      tradesId: trades[0]?.tradeId ? trades[0].tradeId.toString() : null,
      tradeIdentityUuid: trades[0].complianceTradeId,
      alClientId: portfolioAccount.orionConnectFirmId,
      firmId: sessionHelper.getUser()?.firmId,
      action: trades[0].action,
      amount: Math.abs(trades.reduce((prev, next) => prev + next.tradeAmount, 0)),
      quantity: Math.abs(quantity),
      modelId: portfolioAccount.modelId ?? 0,
      custodian: trades[0].custodian,
      teamId: portfolio.teamId,
      managementStyle: portfolioAccount.modelManagementStyle,
      marketValue: marketValue,
      tradePercentOfAccount: Number(accountOrderPercent.toFixed(tacticalDecimalEnum.percentageDecimal)),
      tradePercentOfPortfolio: Number(portfolioOrderPercent.toFixed(tacticalDecimalEnum.percentageDecimal)),
      washAmount: trades[0].washAmount,
      washUnits: trades[0].washUnits,
      orderType: ORDER_TYPE.MARKET,
      brokerDealerId: account.brokerDealerId,
      riaId: account.riaId
    };
  }

  private static getComplianceTrader(): IComplianceTrader {
    const sessionHelper = new SessionHelper();
    const user = sessionHelper.getUser();
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      tags: user.tags
    };
  }

  private static getComplianceAccount(account: IAccountAndCashDetail, accountHoldingUnits: number, accountTotalHolding: number): IComplianceAccount {
    return {
      id: account.id,
      accountType: account.accountTypeName,
      accountValue: account.managedValue + account.excludedValue,
      cashValue: account.currentCash,
      cashValuePostTrade: account.postTradeCashAmount,
      cashValuePostTradePercent: account.managedValue ? (account.postTradeCashAmount / account.managedValue) * 100 : 0,
      holdingUnits: accountHoldingUnits,
      accountPostTradeHoldingPercent: account.managedValue ? (accountTotalHolding / account.managedValue) * 100 : 0
    };
  }

  private static getCompliancePortfolio(portfolio: IPortfolioSummary, portfolioTotalHolding: number): ICompliancePortfolio {
    return {
      id: portfolio.id,
      name: portfolio.name,
      tags: portfolio.tags,
      totalValue: portfolio.managedValue + portfolio.excludedValue,
      managedValue: portfolio.managedValue,
      tolerance: ComplianceHelper.portfolioTolerance,
      cashTolerance: ComplianceHelper.portfolioCashTolerance,
      postTradeHoldingPercent: portfolio.managedValue ? (portfolioTotalHolding / portfolio.managedValue) * 100 : 0
    };
  }

  private static getComplianceSecurity(security: ITacticalSecurity, trade: ITacticalTrade): IComplianceSecurity {
    return {
      id: security ? security.id : trade.securityId,
      identifier: security ? security.symbol : trade.symbol,
      instrument: (security ? security.name : trade.securityName) ?? trade.symbol,
      isin: security ? security.cusip : trade.symbol,
      price: security ? security.price : trade.price,
      securityType: security ? security.securityType : trade.securityType
    };
  }

  private static getAccountAndPortfolioHoldingUnit(taxLots: ITacticalTaxLot[], action: string, amount: number): number {
    let totalHolding;
    const pendingValue = taxLots.reduce((prev, next) => prev + (next.quantity * next.securityPrice), 0);
    if (action === TradeAction.Buy) {
      totalHolding = pendingValue + amount;
    } else {
      totalHolding = pendingValue - amount;
    }
    return totalHolding;
  }

  static getComplianceTradeRequestObject<T>(): IComplianceTradeRequest<T> {
    return {
      complianceTrades: [],
      isCommitted: false,
      isSaveComplianceStatus: false
    } as IComplianceTradeRequest<T>;
  }
}
