import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import {
  ColDef, GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, MenuItemDef,
} from '@ag-grid-community/core';
import {
  IMoneyMarketAllocation, IMoneyMarketAllocationPreference
} from '../../../models/preferences/moneyMarketPreference';
import { Utils as Util } from '../../../core/functions';
import { COLUMN_NAME, ERROR_MESSAGE } from '../../../libs/money-market.constants';
import * as _ from 'lodash';
import { RELATED_TYPE_NAME } from '../../../libs/app.constants';

@Component({
  selector: 'eclipse-money-market-preference',
  templateUrl: './money-market-allocation.component.html',
  styleUrls: ['./money-market-allocation.component.scss']
})
export class MoneyMarketAllocationComponent extends BaseComponent {
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridContext = {
    self: this
  };
  columnDefinitions: ColDef[];
  isShowEditPreferencePopup: boolean;
  isShowErrorMessage: boolean;
  errorMessage: string;
  editedIndex: number;

  @Input() canEdit: boolean;
  @Input() isInheritedView: boolean;
  @Input() moneyMarketAllocationPreference = {} as IMoneyMarketAllocationPreference;
  moneyMarketAllocation: IMoneyMarketAllocation;

  constructor() {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
    this.moneyMarketAllocation = <IMoneyMarketAllocation>{};
  }

  ngOnInit(): void {
    this.createColumnDefinition();
  }

  getContextMenuItems(params: GetContextMenuItemsParams): MenuItemDef[] {
    const self = params.context.self;
    const contextMenu = [];
    if (self.canEdit) {
      contextMenu.push({
        name: 'Edit Preferences', action: () => self.editPreferencesAction(params)
      }, {
        name: 'Delete Preferences', action: () => self.deletePreferencesAction(params)
      });
    }
    return contextMenu;
  }

  editPreferencesAction(params: GetContextMenuItemsParams<IMoneyMarketAllocation>): void {
    const data = params.node.data;
    this.editedIndex = params.node.rowIndex;
    this.moneyMarketAllocation = _.cloneDeep(data);
    this.isShowEditPreferencePopup = true;
  }

  deletePreferencesAction(params: GetContextMenuItemsParams<IMoneyMarketAllocation>): void {
    this.moneyMarketAllocationPreference.moneyMarketAllocations.splice(params.node.rowIndex, 1);
    this.gridApi.setGridOption('rowData', this.moneyMarketAllocationPreference.moneyMarketAllocations);
  }

  addAllocation(): void {
    this.moneyMarketAllocationPreference.resetToParent = false;
    this.isShowEditPreferencePopup = true;
    this.calculateDefaultAllocations();
  }

  calculateDefaultAllocations(): void {
    const maxMoneyMarketCashValueTo = _.max(this.moneyMarketAllocationPreference.moneyMarketAllocations.map(a => a.moneyMarketCashValueTo));
    this.moneyMarketAllocation = {
      id: null,
      moneyMarketCashValueFrom: maxMoneyMarketCashValueTo !== 100 ? maxMoneyMarketCashValueTo ?? 0 : null,
      moneyMarketCashValueTo: maxMoneyMarketCashValueTo !== 100 ? 100 : null,
      moneyMarketMinValue: 70,
      moneyMarketTargetValue: 75,
      moneyMarketMaxValue: 80
    };
  }

  addToGrid(): void {
    this.parseMoneyMarketValues();
    this.validateAllocationPreferenceValue();
    if (!this.isShowErrorMessage) {
      if (this.editedIndex > -1) {
        this.moneyMarketAllocationPreference.moneyMarketAllocations[this.editedIndex] = this.moneyMarketAllocation;
      } else {
        this.moneyMarketAllocationPreference.moneyMarketAllocations.push(this.moneyMarketAllocation);
      }
      this.gridApi.setGridOption('rowData', this.moneyMarketAllocationPreference.moneyMarketAllocations);
      this.gridApi.sizeColumnsToFit();
      this.cancel();
    }
  };

  cancel(): void {
    this.errorMessage = null;
    this.isShowErrorMessage = null;
    this.moneyMarketAllocation = <IMoneyMarketAllocation>{};
    this.isShowEditPreferencePopup = false;
    this.editedIndex = -1;
  }

  checkPercentage(event: KeyboardEvent, value: number): boolean {
    const DECIMAL_PRECISION = 2;
    const target = event.target as HTMLInputElement;
    value = target.selectionStart !== target.selectionEnd ? Number(Util.removeSubstringInRange(value.toString(), target.selectionStart, target.selectionEnd)) : value;
    return Util.isValidPercentInputKey(event, value, DECIMAL_PRECISION);
  }

  validatePercent(columnName: string): void {
    if (this.moneyMarketAllocation[columnName] > 100) {
      this.moneyMarketAllocation[columnName] = 100;
    }
  }

  validateAllocationPreferenceValue(): void {
    this.errorMessage = null;
    const isCashTargetRangeAlreadyExist = this.isCashTargetRangeAlreadyExist();
    const isCashTargetRangeOverlap = this.isCashTargetRangeOverlap();
    if (isCashTargetRangeAlreadyExist) {
      this.errorMessage = ERROR_MESSAGE.CASH_TARGET_RANGE_ALREADY_EXIST();
    } else if (isCashTargetRangeOverlap) {
      this.errorMessage = ERROR_MESSAGE.CASH_TARGET_RANGE_CANNOT_BE_OVERLAPPED();
    } else if (Number(this.moneyMarketAllocation.moneyMarketCashValueFrom) > Number(this.moneyMarketAllocation.moneyMarketCashValueTo)) {
      this.errorMessage = ERROR_MESSAGE.VALUE_MUST_BE_LESS_THAN(COLUMN_NAME.CASH_VALUE_FROM, COLUMN_NAME.CASH_VALUE_TO);
    } else if (Number(this.moneyMarketAllocation.moneyMarketMinValue) > Number(this.moneyMarketAllocation.moneyMarketTargetValue)) {
      this.errorMessage = ERROR_MESSAGE.VALUE_MUST_BE_LESS_THAN_EQUAL(COLUMN_NAME.MIN_VALUE, COLUMN_NAME.TARGET_VALUE);
    } else if (Number(this.moneyMarketAllocation.moneyMarketTargetValue) > Number(this.moneyMarketAllocation.moneyMarketMaxValue)) {
      this.errorMessage = ERROR_MESSAGE.VALUE_MUST_BE_LESS_THAN_EQUAL(COLUMN_NAME.TARGET_VALUE, COLUMN_NAME.MAX_VALUE);
    }
    this.isShowErrorMessage = !!this.errorMessage;
  };

  isCashTargetRangeOverlap(): boolean {
    const moneyMarketAllocations = this.moneyMarketAllocationPreference.moneyMarketAllocations?.filter((value, index) => index !== this.editedIndex);
    return moneyMarketAllocations?.some(m => (this.moneyMarketAllocation.moneyMarketCashValueFrom < m.moneyMarketCashValueTo
      && this.moneyMarketAllocation.moneyMarketCashValueTo > m.moneyMarketCashValueFrom));
  }

  isCashTargetRangeAlreadyExist(): boolean {
    const moneyMarketAllocations = this.moneyMarketAllocationPreference.moneyMarketAllocations?.filter((value, index) => index !== this.editedIndex);
    return moneyMarketAllocations?.some(m => (this.moneyMarketAllocation.moneyMarketCashValueFrom === m.moneyMarketCashValueFrom
      && this.moneyMarketAllocation.moneyMarketCashValueTo === m.moneyMarketCashValueTo));
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi.sizeColumnsToFit();
  }

  private parseMoneyMarketValues(): void {
    this.moneyMarketAllocation.moneyMarketCashValueFrom = this.moneyMarketAllocation.moneyMarketCashValueFrom ? Number(this.moneyMarketAllocation.moneyMarketCashValueFrom) : 0;
    this.moneyMarketAllocation.moneyMarketCashValueTo = this.moneyMarketAllocation.moneyMarketCashValueTo ? Number(this.moneyMarketAllocation.moneyMarketCashValueTo) : 0;
    this.moneyMarketAllocation.moneyMarketTargetValue = this.moneyMarketAllocation.moneyMarketTargetValue ? Number(this.moneyMarketAllocation.moneyMarketTargetValue) : 0;
    this.moneyMarketAllocation.moneyMarketMinValue = this.moneyMarketAllocation.moneyMarketMinValue ? Number(this.moneyMarketAllocation.moneyMarketMinValue) : 0;
    this.moneyMarketAllocation.moneyMarketMaxValue = this.moneyMarketAllocation.moneyMarketMaxValue ? Number(this.moneyMarketAllocation.moneyMarketMaxValue) : 0;
  }

  private createColumnDefinition(): void {
    this.columnDefinitions = [
      <ColDef>{
        headerName: 'Cash Target Range From',
        field: 'moneyMarketCashValueFrom',
        width: 200,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: this.percentColumnCellRender
      }, <ColDef>{
        headerName: 'Cash Target Range To',
        field: 'moneyMarketCashValueTo',
        width: 200,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: this.percentColumnCellRender
      }, <ColDef>{
        headerName: 'MM Min',
        field: 'moneyMarketMinValue',
        width: 100,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: this.percentColumnCellRender
      }, <ColDef>{
        headerName: 'MM Target',
        field: 'moneyMarketTargetValue',
        width: 120,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: this.percentColumnCellRender
      }, <ColDef>{
        headerName: 'MM Max',
        field: 'moneyMarketMaxValue',
        width: 100,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: this.percentColumnCellRender
      }
    ];

    if (this.isInheritedView) {
      this.columnDefinitions.push(<ColDef>{
        headerName: 'Inherited From',
        field: 'relatedType',
        width: 180,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams) => RELATED_TYPE_NAME[params.value]
      });
    }
  };

  private percentColumnCellRender(params: ICellRendererParams): string {
    return params.value ? `${params.value} %` : '0 %';
  }
}
