<div *ngIf="standardQuery">
  <div *ngIf="standardQuery.where">
    <div class="conditions row vertical-center">
      <div class="col-md-2 col-sm-4">
        <label class="top-space"> Condition:</label>
      </div>
      <div class="col-md-2 col-sm-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="standardQuery.where.condition" (ngModelChange)="setChange()">
            <option>AND </option>
            <option>OR </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-4">
        <button type="button" pButton title="Add Condition" (click)="addQueryRule(standardQuery.where.rules)" icon="fas fa-plus-circle" label="Add Rule"></button>
      </div>
    </div>
    <div class="row" *ngFor="let standardlist of standardQuery.where.rules">
      <div class="col-md-3">
        <div class=" custom-group">
          <div class="form-group">
            <select class="form-control" [(ngModel)]="standardlist.selectedColumnId" (change)="setColumnDataType(standardlist,$event)">
              <!--<option value="0">Select column</option>-->
              <option *ngFor="let item of standardQuery.selectedColumns" class={{item.dataType}} [value]="item.id">{{item.displayName}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class=" custom-group">
          <div class="form-group">
            <div *ngIf="standardlist.columnDateType == '' || standardlist.columnDateType == undefined">
              <select class="form-control" [(ngModel)]="standardlist.operator" (ngModelChange)="setChange()">
                <option *ngFor="let item of stringOperatorsList" [value]="item.value">{{item.value}}</option>
              </select>
            </div>
            <div *ngIf="standardlist.columnDateType!= undefined && !standardlist.isPredefined && (standardlist.columnDateType =='VARCHAR' || standardlist.columnDateType =='LONGTEXT' || standardlist.columnDateType =='TEXT')&& standardlist.selectedColumnId!=56">
              <select class="form-control" [(ngModel)]="standardlist.operator" (ngModelChange)="setChange()">
                <option *ngFor="let item of stringOperatorsList" [value]="item.value">{{item.value}}</option>
              </select>
            </div>
            <div *ngIf="standardlist.columnDateType!= undefined && !standardlist.isPredefined  && (standardlist.columnDateType =='INT' || standardlist.columnDateType =='BIGINT' || standardlist.columnDateType == 'DECIMAL' || standardlist.columnDateType == 'DATETIME' || standardlist.columnDateType == 'DATE' || standardlist.columnDateType == 'BIT' )">
              <select class="form-control" [(ngModel)]="standardlist.operator" (ngModelChange)="setChange()">
                <option *ngFor="let item of integerOperatorsList" [value]="item.value">{{item.value}}</option>
              </select>
            </div>
            <div *ngIf="standardlist.columnDateType== 'TINYINT' || standardlist.isPredefined || standardlist.selectedColumnId==56">
              <select class="form-control" [(ngModel)]="standardlist.operator" (ngModelChange)="setChange()">
                <option *ngFor="let item of booleanOperatorsList" [value]="item.value">{{item.value}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="standardlist.columnDateType !='DATETIME' && standardlist.columnDateType !='DATE' && !standardlist.isPredefined && standardlist.columnDateType!='TINYINT' && standardlist.selectedColumn!='Account.SleeveType'">
        <div class=" custom-group">
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="standardlist.value" (ngModelChange)="setChange()" (keypress)="validateValue(standardlist.columnDateType, $event)">
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="standardlist.columnDateType=='TINYINT'">
        <div class=" custom-group">
          <div class="form-group">
            <select class="form-control" [(ngModel)]="standardlist.value" (ngModelChange)="setChange()">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="standardlist.selectedColumn=='Account.SleeveType'">
        <div class=" custom-group">
          <div class="form-group">
            <select class="form-control" [(ngModel)]="standardlist.value" (ngModelChange)="setChange()">
              <option value="Normal">Normal</option>
              <option value="None">None</option>
              <option value="Custodial">Custodial</option>
              <option value="Distribution">Distribution</option>
            </select>
          </div>
        </div>
      </div>
      <!--Account.sleeveType-->
      <div class="col-md-2" *ngIf="standardlist.isPredefined">
        <div class=" custom-group">
          <div class="form-group">
            <select class="form-control" [(ngModel)]="standardlist.predefinedValue" (change)="setPredefinedvalue(standardlist,$event)"
                    (ngModelChange)="setPredefinedvalueValidate(standardlist,$event)">
              <!--<option value="0"> Select value </option>							  -->
              <option *ngFor="let value of standardlist.predefinedList.values" class={{value.name}} [value]="value.id">{{value.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="(standardlist.columnDateType =='DATETIME' || standardlist.columnDateType =='DATE' )&& standardlist.columnDateType !=null">
        <div class="custom-group">
          <div class="form-group">
            <select class="form-control" [(ngModel)]="standardlist.macro" (ngModelChange)="clearDate(standardlist)">
              <option value="0"> Specific date </option>
              <option *ngFor="let macro of macroList" [value]="macro.id">{{macro.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="standardlist.macro =='0' && (standardlist.columnDateType =='DATETIME' || standardlist.columnDateType =='DATE')">
        <div class=" custom-group">
          <div class="form-group">
            <p-calendar class="form-control" [(ngModel)]="standardlist.date" [showIcon]="true" aria-hidden="true"
                        (ngModelChange)="setChange()" [yearNavigator]="true" [yearRange]="getYearRange()" [readonlyInput]="true"></p-calendar>
          </div>
        </div>
      </div>
      <div class="col-md-1 col-md-push-1">
        <button type="button" pButton [disabled]="standardlist?.length ==0" class="p-button-sm p-button-danger" (click)="deleteRow(standardlist)" icon="fas fa-minus-circle"></button>
      </div>
    </div>
  </div>
</div>
