import { Component } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';

@Component({
  selector: 'eclipse-portfolio-editor-notes',
  templateUrl: './portfolio-notes.component.html'
})
export class PortfolioNotesComponent extends BaseComponent {
  constructor() {
    super();
  }

  ngOnDestroy() {
  }
}
