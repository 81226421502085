import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {
  IComplianceTradeUpdateRequest,
  IComplianceTradeUpdateResponse,
  IComplianceUpdate
} from '../../models/compliance';
import {AppealErrorMessage, ComplianceStatus, ComplianceUpdateType} from '../../libs/compliance.constants';
import {ComplianceService} from '../../services/compliance.service';
import {lastValueFrom} from 'rxjs';
import {Logger} from '../../core/logging';
import {AlertService} from '../../core';
import {Utils} from '../../core/functions';
import * as constants from '../../libs/app.constants';
import {ComplianceHelper} from '../../components/tradetool/tactical/shared/compliance-helper';

@Component({
  selector: 'eclipse-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent extends BaseComponent {
  @Input() isTacticalTradeTool =  false;
  @Output() parentCallback = new EventEmitter();
  showComplianceUpdatePopup: boolean;
  complianceUpdate: IComplianceUpdate = {
    trades: [],
    type: ComplianceUpdateType.APPEAL,
  };
  isBasisCodeOverrideRestriction: boolean;
  isDisableButton = false;
  private readonly _logger: Logger;

  constructor(private readonly _complianceService: ComplianceService,
              private readonly _alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.isBasisCodeOverrideRestriction = !!Utils.getPermission(constants.PRIV_BASISCODEOVERRIDE)?.canUpdate;
  }

  complianceUpdateResetData(): void {
    this.complianceUpdate = {
      trades: [],
      type: ComplianceUpdateType.APPEAL,
      message: null
    };
    this.showComplianceUpdatePopup = false;
    this.isDisableButton = false;
  }

  async sendTradeToCompliance(): Promise<void> {
    this.isDisableButton = true;
    const complianceResponse = await this.sendTradeForComplianceUpdate(this.complianceUpdate);
    complianceResponse.tradeIdentityUuids = this.complianceUpdate.trades.map(t => t.tradeIdentityUuid);
    this._alertService.alert.emit([{typeId: complianceResponse.isError ? 4 : 1, message: complianceResponse.message}]);
    this.parentCallback.emit(complianceResponse);
    this.complianceUpdateResetData();
  }

  private async sendTradeForComplianceUpdate(complianceUpdate: IComplianceUpdate): Promise<IComplianceTradeUpdateResponse> {
    try {
      const complianceTradeUpdateRequest = ComplianceHelper.getComplianceTradeRequestObject<IComplianceTradeUpdateRequest>();
      complianceTradeUpdateRequest.isCommitted = !this.isTacticalTradeTool;
      if (complianceUpdate.trades?.length) {
        for (const trade of complianceUpdate.trades) {
          const user = this._sessionHelper.getUser();
          complianceTradeUpdateRequest.complianceTrades.push({
            firmId: user?.firmId,
            alClientId: trade.alClientId,
            tradeIdentityUuid: trade.tradeIdentityUuid,
            reason: complianceUpdate.message,
            requestedBy: user.name,
            brokerDealerId: trade.brokerDealerId,
            riaId: trade.riaId
          });
        }
      }
      const complianceUpdateObservable = this._complianceService.complianceTradeUpdate(complianceTradeUpdateRequest, complianceUpdate.type);
      return await lastValueFrom(complianceUpdateObservable) as IComplianceTradeUpdateResponse;
    } catch (error) {
      this._logger.error(error, 'Error in sendTradeToComplianceUpdate()');
      return {isError: true, message: AppealErrorMessage, complianceStatus: ComplianceStatus.SERVICE_NOT_AVAILABLE};
    }
  }
}
