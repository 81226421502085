import { Directive, EventEmitter, Output } from '@angular/core';
import {Input} from '@angular/core';
import { TabView } from 'primeng/tabview';

/**
 * Sets the selected tab by tabName, rather than activeTabIndex.
 */
@Directive({
  selector: 'p-tabView:not([activeIndex])'
})
export class TabPanelSelectedDirective {
  private _activeTabName: string | number;

  @Input()
  public get activeTabName(): string | number {
    return this._activeTabName;
  }

  public set activeTabName(value: string | number) {
    this._activeTabName = value;
    this.onActiveTabChanged();
  }

  constructor(private readonly host: TabView) {
  }

  onActiveTabChanged() {
    if (!this.host.tabs) {
      return;
    }
    const findTab = this.host.tabs.find(t => t.viewContainer.element.nativeElement.getAttribute('tabName') === this.activeTabName
    || t.viewContainer.element.nativeElement?.id === this.activeTabName);
    if (!!findTab) {
      const index = this.host.findTabIndex(findTab);
      this.host.activeIndex = index;
    }
  }
}
