import {Component, Output, EventEmitter} from '@angular/core';
import {IDashboardFilter, IDashboardFilterSave} from '../../models/dashboardfilter';
import {DashboardFilterService} from '../../services/dashboardfilter.service';
import {BaseComponent} from 'src/app/core/base.component';

@Component({
  selector: 'eclipse-main-dashboard-filter',
  templateUrl: './dashboardfilter.component.html',
  styleUrls: ['./dashboardfilter.component.scss']
})
export class MainDashboardFilterComponent extends BaseComponent {
  @Output() resetDashboardSetting = new EventEmitter();

  dashboardType: string;
  filterId: number;
  displayMessage: boolean;
  filters: IDashboardFilter[];

  constructor(private _dashboardFilterService: DashboardFilterService) {
    super();
  }

  public getDashboardFilterData(dashboardType: string, filterId: number, displayMessage: boolean = false) {
    this.filters = null;
    this.dashboardType = dashboardType;
    this.filterId = filterId;
    this.displayMessage = displayMessage;
    this._dashboardFilterService.getEditDashBoardData(dashboardType, filterId)
      .subscribe((filters: IDashboardFilter[]) => {
        this.filters = filters;
      }, err => {
        this.closeDashboardSettingPopUp();
      });
  }

  saveDashboardSetting() {
    const dataForSave: IDashboardFilterSave = {
      filters: this.filters
    };
    this._dashboardFilterService.saveOrUpdateDashboardFilter(dataForSave)
      .subscribe((filters: IDashboardFilter[]) => {
        this.closeDashboardSettingPopUp();
      }, err => {
        this.closeDashboardSettingPopUp();
      });
  }

  closeDashboardSettingPopUp() {
    this.filters = null;
    this.resetDashboardSetting.emit();
  }

  onOptionChange(filter: IDashboardFilter) {
    if (filter && filter.dashboardSubLevels && filter.dashboardSubLevels.length > 0) {
      const option = filter.dashboardSubLevels.find(s => +s.selectedOptionId === 3);
      if (option) {
        filter.dashboardSubLevels
          .filter(subLevel => {
            return +subLevel.selectedOptionId !== 3;
          })
          .forEach(subLevel => {
            subLevel.options.forEach(opt => {
              if (opt.id === 3) {
                opt.isDisable = true;
              }
            });
          });
      } else {
        filter.dashboardSubLevels
          .filter(subLevel => +subLevel.selectedOptionId !== 3)
          .forEach(subLevel => {
            subLevel.options.forEach(opt => {
              opt.isDisable = false;
            });
          });
      }
    }
  }

  resetDashboardFilters() {
    this._dashboardFilterService.resetDashboardFilter(this.filterId)
      .subscribe(() => {
        this.closeDashboardSettingPopUp();
      }, err => {
        this.closeDashboardSettingPopUp();
      });
  }
}
