import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {PortfolioService} from '../../services/portfolio.service';
import {IPortfolioSimple} from '../../models/portfolio';
import {Subscription} from 'rxjs';
import {AutoComplete} from 'primeng/autocomplete';
import {ITradeGridItem} from '../../models/spendcash';

@Component({
  selector: 'eclipse-portfolio-auto-complete',
  templateUrl: './portfolio.autocomplete.component.html'
})
export class PortfolioAutoCompleteComponent extends BaseComponent {
  @ViewChild('autoCompleteSearchObject') autoCompleteSearchObject: AutoComplete;
  suggestions: any[] = [];
  selectedItem: string;
  searchText: string;
  sub: Subscription;
  @Input() forTactical: boolean = false;
  @Input() isMacAssignment: boolean;
  @Input() List: Array<ITradeGridItem> = [];
  @Input() excludeDoNotTrade: boolean = false;
  @Output() parentCallback = new EventEmitter();
  @Output() onValueChange = new EventEmitter();

  constructor(private _portfolioService: PortfolioService) {
    super();
  }

  /** To get portfolios for search autoComplete  */
  loadSuggestions(event) {
    this.searchText = event.query.trim();
    // eslint-disable-next-line eqeqeq
    if (event.query.trim() == '') {
      this.selectedItem = event.query.trim();
    }

    if (this.sub && !this.sub.closed) {
      this.sub.unsubscribe();
    }

    if (this.forTactical) {
      this.sub = this._portfolioService.GetPortfolioListApprovedAndBalancedModel(event.query.toLowerCase())
        .subscribe((model: IPortfolioSimple[]) => {
          this.suggestions = model;
          this.formatPortfolioSuggestions(this.suggestions, event);
        });
    } else {
      this.sub = this._portfolioService.searchPortfolioSimple(event.query.toLowerCase(), this.excludeDoNotTrade)
        .subscribe((model: IPortfolioSimple[]) => {
          if (this.isMacAssignment) {
            // eslint-disable-next-line eqeqeq
            this.suggestions = model.filter(a => a.isDeleted == 0 && a.modelId != undefined);
          } else {
            const addedIds = this.List.map(l => l.id);
            this.suggestions = model.filter(element => !addedIds.includes(element.id) && !element.isDeleted);
          }
          this.formatPortfolioSuggestions(this.suggestions, event);
        });
    }
  }

  /** Fires on item select */
  onPortfolioSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({value: this.selectedItem});
  }

  onPortfolioValueChange(value) {
    this.onValueChange.emit({value: value});
  }

  /**
   * Sets focus to the autocomplete input
    */
  setFocus() {
    if (this.autoCompleteSearchObject) {
      this.autoCompleteSearchObject.focusInput();
    }
  }
}
