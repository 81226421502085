import {Component, EventEmitter, Output} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {TradeToolsService} from '../../services/tradetools.service';

@Component({
  selector: 'eclipse-trade-groups-account-auto-complete',
  templateUrl: './tradegroupsaccount.autocomplete.component.html'
})
export class TradeGroupsAccountAutoCompleteComponent extends BaseComponent {
  suggestions: any[] = [];
  selectedItem: string;

  @Output() parentCallback = new EventEmitter();
  constructor(private _tradeToolsService: TradeToolsService) {
    super();
  }

  /** To get trade group accounts for search autoComplete  */
  loadSuggestions(event) {
    this.suggestions = [];
    this.suggestions = this._tradeToolsService.getTradeGroupsAccounts();
  }

  /** Fires on item select */
  onTradeGroupsAccountSelect(item) {
    this.parentCallback.emit({value: item});
  }

}
