import { NgModule } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { SharedModule } from '../../shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  DashboardItemFilterPipe,
  MainDashboardComponent,
  MainDashboardContainerComponent,
  MainDashboardFilterComponent,
  MetricDashboardItemComponent,
  SavedViewDashboardItemComponent
} from './';

@NgModule({
  imports: [
    SharedModule,
    DragDropModule,
  ],
  declarations: [
    DashboardItemFilterPipe,
    MainDashboardFilterComponent,
    MainDashboardComponent,
    MainDashboardContainerComponent,
    MetricDashboardItemComponent,
    SavedViewDashboardItemComponent
  ],
  providers: [
    DashboardService,
  ],
  exports: [
    MainDashboardComponent
  ]
})
export class DashboardModule {
}
