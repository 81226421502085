import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils as Util, Utils } from '../../../core/functions';
import { PreferenceService } from '../../../services/preference.service';
import { EntityType } from '@ret/ng-lib/shared';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BaseComponent } from '../../../core/base.component';
import { EntityType as CompareToolEntityType } from '@oat/web-components/shared';
import { MessageModule } from 'primeng/message';
import { CommonModule } from '@angular/common';
import { WebComponentsModule } from '@oat/web-components';

@Component({
  selector: 'eclipse-compare-tool-wrapper',
  templateUrl: './compare-tool-wrapper.component.html',
  styleUrls: ['./compare-tool-wrapper.component.scss'],
  imports: [
    CommonModule,
    MessageModule,
    WebComponentsModule,
  ],
  standalone: true,
})
export class CompareToolWrapperComponent extends BaseComponent {
  ctx: any = {};
  scenarioId: string = '';
  compareToolEnabledPreference: boolean = false;

  @Input() isCommitTradesEnabled: boolean = true;

  private _id: number;
  @Input()
  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
    this.setContext();
  }

  private _entityType: CompareToolEntityType;
  @Input()
  public get entityType(): CompareToolEntityType {
    return this._entityType;
  }

  public set entityType(value: CompareToolEntityType) {
    this._entityType = value;
    this.setContext();
  }

  private _entityId: number;
  @Input()
  public get entityId(): number {
    return this._entityId;
  }

  public set entityId(value: number) {
    this._entityId = value;
    this.setContext();
  }

  private _portfolioName: string;
  @Input()
  public get portfolioName(): string {
    return this._portfolioName;
  }

  public set portfolioName(value: string) {
    this._portfolioName = value;
    this.setContext();
  }

  private _portfolioId: number;
  @Input()
  public get portfolioId(): number {
    return this._portfolioId;
  }

  public set portfolioId(value: number) {
    this._portfolioId = value;
    this.setContext();
  }

  constructor(private activatedRoute: ActivatedRoute, private _preferenceService: PreferenceService,
              private readonly _router: Router,
              private readonly _localStorageService: StorageMap) {
    super();
    this.id = Util.getQueryParam<number>(activatedRoute, 'id');
    this.entityId = Util.getQueryParam(activatedRoute, 'entityId');
    this.entityType = Util.getQueryParam(activatedRoute, 'entityType');
  }

  setContext() {
    if (this.id && this.entityType && this.entityId) {
      this.ctx = {
        id: this.id,
        entityType: this.entityType,
        entityId: this.entityId,
        commitTradesEnabled: true,
      };
    }
  }

  ngAfterViewInit() {
    this.isCommitTradesEnabled = true;
    this.scenarioId = Utils.getQueryParam(this.activatedRoute, 'scenarioId');

    if (this.isOrionAdmin) {
      this.compareToolEnabledPreference = true;
    } else {
      this._preferenceService.getPreferenceByName('compareToolEnabled').subscribe(result => {
        this.compareToolEnabledPreference = Util.convertIntoBooleanValue(result);
      });
    }
  }

  onTradesCommitted(committed: boolean, context: { clientId: any, entityId: number, entityType: EntityType }) {
    if (committed) {
      let portfolioIds = [];
      portfolioIds.push({id: this._portfolioId, name: this._portfolioName});

      this._localStorageService.set('TacticalPortfolioIds', portfolioIds).subscribe();
      this._router.navigate(['/eclipse/tradetool/tactical'], {onSameUrlNavigation: 'reload'});
    }
  }
}
