import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { AnalyticsStatus } from '../../libs/app.constants';
import { IPortfolioAnalytics } from '../../models/portfolio';
import { PortfolioService } from '../../services/portfolio.service';
import { AnalyticsHelper } from './analytics-helper';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { IAnalyticsNotification, INotificationVM } from '../../models/notification';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'eclipse-analytics-duration',
  templateUrl: './analytics-duration.component.html'
})
export class AnalyticsDurationComponent implements OnChanges, OnDestroy {
  @Input() portfolioAnalytics: IPortfolioAnalytics[];
  @Output() updateAnalyticsData = new EventEmitter<IAnalyticsNotification>();
  @Output() updateNextButtonStatus = new EventEmitter<boolean>();
  analyticsStatus: number;
  duration: string;
  portfolioId: number;
  status: string;
  durationInterval: number;
  portfolioStatusInterval: number;
  isButtonDisabled: boolean;
  isMultiSelect: boolean;
  analyticsPortfolioStatusSubscription: Subscription;

  constructor(private readonly _portfolioService: PortfolioService,
              private readonly _notificationService: NotificationService,
              private readonly _analyticsService: AnalyticsService) {
    this.portfolioAnalytics = [];
    this.subscribeAnalyticsPortfolioStatusNotification();
    this.checkPortfolioStatusInEveryTwoMinutes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.portfolioAnalytics.currentValue.length) {
      this.updateAnalyticsDurationObjects();
    } else {
      this.portfolioAnalytics = [];
    }
  }


  runPortfolioAnalysis(portfolioIds: number[]): void {
    if (portfolioIds) {
      this._portfolioService.runPortfolioAnalysis(portfolioIds)
        .subscribe();
    }
  }

  runAnalysis(): void {
    const statuses = [AnalyticsStatus.NeedAnalytics, AnalyticsStatus.Failed];
    const portfolioIds = AnalyticsHelper.filterPortfolioIdsByStatuses(this.portfolioAnalytics, statuses);
    this.runPortfolioAnalysis(portfolioIds);
  }

  updateAnalyticsDurationObjects(): void {
    this.analyticsStatus = AnalyticsHelper.getAnalyticsStatus(this.portfolioAnalytics);
    if (this.analyticsStatus === AnalyticsStatus.Running) {
      this.checkDateAndStartAnalyticsDurationTimer();
    }
    this.status = AnalyticsHelper.getRejectReason(this.portfolioAnalytics, this.analyticsStatus);
    this.isMultiSelect = this.portfolioAnalytics.length > 1;
    this.sendNextButtonStatus();
  }

  subscribeAnalyticsPortfolioStatusNotification(): void {
    this.analyticsPortfolioStatusSubscription = this._notificationService.analyticsPortfolioStatus
      .subscribe((notification: INotificationVM): void => {
        this.processAnalyticsNotification(notification);
      });
  };

  clearInterval(isAnalyticsCompleted: boolean): void {
    const isClearInterval = AnalyticsHelper.isClearInterval(isAnalyticsCompleted, this.portfolioAnalytics);
    if (isClearInterval && this.durationInterval) {
      this.duration = null;
      clearInterval(this.durationInterval);
    }
  }

  checkDateAndStartAnalyticsDurationTimer(): void {
    clearInterval(this.durationInterval);
    const startedDate = AnalyticsHelper.getStartDate(this.portfolioAnalytics);
    this.durationInterval = window.setInterval(() => this.duration = AnalyticsHelper.getDurationTimer(startedDate), 1000);
  }

  ngOnDestroy(): void {
    this.analyticsPortfolioStatusSubscription.unsubscribe();
    clearInterval(this.durationInterval);
    clearInterval(this.portfolioStatusInterval);
  }

  processAnalyticsNotification(notification: INotificationVM): void {
    const analyticsNotification = notification?.analyticsNotification;

    if (!AnalyticsHelper.isValidNotification(analyticsNotification)) {
      return;
    }

    if (analyticsNotification.analyticsStatus === AnalyticsStatus.Running && analyticsNotification.portfolioIds?.length > 0) {
      analyticsNotification.portfolioFlags = AnalyticsHelper.createDummyPortfolioFlags(analyticsNotification);
      analyticsNotification.portfolioIds = null;
    }

    this.handleAnalyticsPortfolioStatus(analyticsNotification);
  }

  handleAnalyticsPortfolioStatus(analyticsNotification: IAnalyticsNotification): void {
    AnalyticsHelper.updatePortfolioDetails(this.portfolioAnalytics, analyticsNotification.portfolioFlags);
    this.updateAnalyticsDurationObjects();
    this.clearInterval(analyticsNotification.isAnalyticsCompleted);
    this.updateAnalyticsData.emit(analyticsNotification);
  }

  /**
   * This method is used to check and update the portfolio analytics status in every 2 minute.
   */
  private checkPortfolioStatusInEveryTwoMinutes(): void {
    clearInterval(this.portfolioStatusInterval);
    this.portfolioStatusInterval = window.setInterval((): void => {
      if (this.analyticsStatus === AnalyticsStatus.Running) {
        const statuses = [AnalyticsStatus.Running];
        const portfolioIds = AnalyticsHelper.filterPortfolioIdsByStatuses(this.portfolioAnalytics, statuses);

        this._analyticsService.getPortfoliosAnalyticsStatus(portfolioIds)
          .subscribe((portfolioAnalytics: IPortfolioAnalytics[]) => {
            AnalyticsHelper.updatePortfolioDetails(this.portfolioAnalytics, portfolioAnalytics);
            this.updateAnalyticsDurationObjects();
          });
      }
    }, 120000); // Two Minutes
  }

  private sendNextButtonStatus(): void {
    const isButtonDisable = AnalyticsHelper.getNextButtonStatus(this.portfolioAnalytics);
    this.updateNextButtonStatus.next(isButtonDisable);
  }

  protected readonly AnalyticsStatus = AnalyticsStatus;

  public getFailedAnalytics(): IPortfolioAnalytics[] {
    return this.portfolioAnalytics.filter(p => p?.needAnalytics === AnalyticsStatus.Failed);
  }
}
