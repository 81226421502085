import { Component, Input, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import {
  IMoneyMarketFunds,
  IMoneyMarketFundPreference
} from '../../../models/preferences/moneyMarketPreference';
import {
  ColDef,
  GetContextMenuItemsParams, GridApi,
  GridOptions, GridReadyEvent, ICellRendererParams,
  MenuItemDef,
} from '@ag-grid-community/core';
import { ISecurity } from '../../../models/security';
import { AutocompleteHubService } from '../../search/shared/autocomplete-hub.service';
import { CustomInputBoxRendererComponent } from '../../custom-input-box/custom-input-box-renderer.component';
import { RELATED_TYPE_NAME } from '../../../libs/app.constants';

@Component({
  selector: 'eclipse-money-market-fund',
  templateUrl: './money-market-fund.component.html',
  styleUrls: ['./money-market-fund.component.scss']
})
export class MoneyMarketFundComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  gridContext = {
    self: this
  };
  columnDefinitions: ColDef[];
  security: ISecurity;
  filterOutSecurityIds: number[] = [];
  isDisableAddButton: boolean;
  isShowErrorMessage: boolean;
  errorMessage: string;
  frameworkComponents;

  @Input() canEdit: boolean;
  @Input() isInheritedView: boolean;
  @Input() moneyMarketFundPreference = {} as IMoneyMarketFundPreference;

  constructor(private readonly _autocompleteHubService: AutocompleteHubService) {
    super();
    this.isDisableAddButton = true;
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit(): void {
    this.createColumnDefinition();
    this.frameworkComponents = {
      customInputRenderer: CustomInputBoxRendererComponent,
    };
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  searchSecurity(security: ISecurity): void {
    this.security = security?.id ? security : null;
    this.isDisableAddButton = !security?.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const moneyMarketPreference = changes.moneyMarketFundPreference.currentValue as IMoneyMarketFundPreference;
    if (moneyMarketPreference?.moneyMarketFunds?.length) {
      this.filterOutSecurityIds = moneyMarketPreference.moneyMarketFunds.map(p => Number(p.securityId));
    }
    this.gridApi?.sizeColumnsToFit();
  }

  addSecurity(): void {
    this.moneyMarketFundPreference.moneyMarketFunds.push({
      securityId: this.security.id,
      symbol: this.security.symbol,
      securityName: this.security.name,
      moneyMarketMinInitialInvestment: 0
    } as IMoneyMarketFunds);
    this.gridApi.setGridOption('rowData', this.moneyMarketFundPreference.moneyMarketFunds);
    this.filterOutSecurityIds.push(this.security.id);
    this.cancel();
  }

  cancel(): void {
    this.isShowErrorMessage = null;
    this._autocompleteHubService.requestToClearSearchData();
    this.isDisableAddButton = true;
  }

  getContextMenuItems(params: GetContextMenuItemsParams): MenuItemDef[] {
    const self = params.context.self;
    const data = params.node.data;
    const contextMenu = [];
    if (self.canEdit) {
      contextMenu.push(
        {
          name: 'Delete Preferences',
          action: () => {
            self.filterOutSecurityIds = self.filterOutSecurityIds.filter(s => s !== Number(data?.securityId));
            self.moneyMarketFundPreference.moneyMarketFunds = self.moneyMarketFundPreference.moneyMarketFunds.filter(s => Number(s.securityId) !== Number(data?.securityId));
          }
        }
      );
    }
    return contextMenu;
  }

  private createColumnDefinition(): void {
    this.columnDefinitions = [
      <ColDef>{
        headerName: 'Ticker',
        field: 'symbol',
        width: 200,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Minimum Initial Investment $',
        field: 'moneyMarketMinInitialInvestment',
        width: 250,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'customInputRenderer'
      }
    ];


    if (this.isInheritedView) {
      this.columnDefinitions.push(<ColDef>{
        headerName: 'Inherited From',
        field: 'relatedType',
        width: 180,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: ICellRendererParams) => RELATED_TYPE_NAME[params.value]
      });
    }
  }
}
