import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ISecurity} from '../../../models/security';
import {IAutoSelectCustodian} from '../../../models/custodian';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteHubService {

  private readonly _getSelectedSecurity: Subject<ISecurity> = new Subject();
  public get getSelectedSecurity(): Observable<ISecurity> {
    return this._getSelectedSecurity.asObservable();
  }

  private readonly _getSelectedCustodian: Subject<IAutoSelectCustodian> = new Subject();
  public get getSelectedCustodian(): Observable<IAutoSelectCustodian> {
    return this._getSelectedCustodian.asObservable();
  }

  private readonly _clearSearchData: Subject<void> = new Subject();
  public get clearSearchData(): Observable<void> {
    return this._clearSearchData.asObservable();
  }

  constructor() {
  }

  public requestToGetSelectedSecurity(security: ISecurity): void {
    this._getSelectedSecurity.next(security);
  }

  public requestToGetSelectedCustodian(custodian: IAutoSelectCustodian): void {
    this._getSelectedCustodian.next(custodian);
  }

  public requestToClearSearchData(): void {
    this._clearSearchData.next();
  }
}
