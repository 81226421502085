{{text}}
<span class="ng2-tag-input-remove" (click)="removeTag()">&times;</span>

<style>
  :host {
    display: inline-block;
    background: var(--badge-background-primary);
    padding: 7px;
    border-radius: 90px;
    margin-right: 10px;
    color: var(--badge-text-primary);
    font-weight: normal;
  }

  :host.ng2-tag-input-item-selected {
    color: var(--badge-text-primary);
    background: var(--badge-background-primary);
  }

  .ng2-tag-input-remove {
    cursor: pointer;
    display: inline-block;
    padding: 0 3px;
  }
</style>
