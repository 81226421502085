import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class QueryBuilderDataService {

  selectedQueryId: number;
  actionType: string;
  runQueryIds: number[] = []; // To enable/disable Export option based on query running status.

  // Observable string sources
  contentFromDataQueries = new Subject<string>();
  contentFromLeftPanel = new Subject<string>();
  // actionFromLeftPanel = new Subject<string>();

  // Observable streams
  contentFromPage$ = this.contentFromDataQueries.asObservable();
  contentLeftPanel$ = this.contentFromLeftPanel.asObservable();
  // actionFromLeftPanel$ = this.actionFromLeftPanel.asObservable();

  // Service message commands
  performActionOnLPanel(key: string) {
    this.contentFromDataQueries.next(key);
  }

  performActionOnPage(key: string) {
    this.contentFromLeftPanel.next(key);
  }
}
