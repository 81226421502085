<eclipse-rl-tag-input-item [text]="tag" [index]="index" [selected]="selectedTag === index" (tagRemoved)="_removeTag($event)" *ngFor="let tag of tagsList; let index = index">
</eclipse-rl-tag-input-item>

<form #tagInputForm="ngForm" class="padding-0">
  <input class="ng2-tag-input-field form-control placeholder-input" type="text" [(ngModel)]="inputValue" name="tagInputField" [placeholder]="placeholder"
         (paste)="inputPaste($event)" (keydown)="inputChanged($event)" (blur)="inputBlurred($event)" (focus)="inputFocused($event)">
</form>

<style>

</style>
