import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';

@Injectable()
export class BlendedFundService {
  private _blendedFundEndPoint = 'v1/macWeighting';

  constructor(private _httpClient: OEHttpClient) { }

  /** Get specified BlendedFund where id equals*/
  getBlendedFunds(securityId: number) {
    return this._httpClient.getData(`${this._blendedFundEndPoint}?securityId=${securityId}`);
  }

  getBlendedFundsMacWeighting(blendedFundId: number) {
    return this._httpClient.getData(`${this._blendedFundEndPoint}?id=${blendedFundId}`);
  }

  addBlendedFundsMacWeighting(blendedFundWeightings: any, securityId: number) {
    return this._httpClient.postData(`${this._blendedFundEndPoint}?securityId=${securityId}`, blendedFundWeightings);
  }
  /**Using this will check MAC BlendedFund Delete possible */
  validateDelete(blendedFundIds: any, securityId: number) {
    const ids = { ids: blendedFundIds };
    return this._httpClient.deleteData(`${this._blendedFundEndPoint}/macWeighting?securityId=${securityId}&validateDelete=${true}`, ids);
  }
  /**Using this will check MAC Enable/Disable possible */
  validateSecurityMACDelete(securityId: number, unBlend) {
    return this._httpClient.deleteData(`${this._blendedFundEndPoint}?securityId=${securityId}&unBlend=${unBlend}&validateDelete=${true}`);
  }
  deleteBlendedFund(blendedFundIds: any, securityId: number) {
    const ids = { ids: blendedFundIds };
    return this._httpClient.deleteData(`${this._blendedFundEndPoint}/macWeighting?securityId=${securityId}`, ids);
  }
  manageBlendedFund(unBlend: boolean, securityId: number) {
    return this._httpClient.deleteData(`${this._blendedFundEndPoint}?securityId=${securityId}&unBlend=${unBlend}`);
  }
  forceDeleteBlendedFund(unBlend: boolean, securityId: number) {
    return this._httpClient.deleteData(`${this._blendedFundEndPoint}?securityId=${securityId}&unBlend=${unBlend}&forceDelete=true`);
  }
  searchMACSecurities(searchString: string) {
    return this._httpClient.getData(`${this._blendedFundEndPoint}/searchSecurity?searchString=${searchString}`);
  }
  getAssignedMACsecuritiesAndWeightings(level: string, id: number) {
    return this._httpClient.getData(`${this._blendedFundEndPoint}/assignedMacWeightingList/${id}?level=${level}`);
  }

  validateMacConvert(securityId: number) {
    return this._httpClient.getData(`${this._blendedFundEndPoint}/validateMacConvert?securityId=${securityId}`);
  }

  validateMACWeightings(weightings: any) {
    return this._httpClient.postData(`${this._blendedFundEndPoint}/validate`, weightings);
  }

  editBlendedFundsMacWeighting(blendedFundWeightings: any, securityId: number) {
    return this._httpClient.updateData(`${this._blendedFundEndPoint}?securityId=${securityId}`, blendedFundWeightings);
  }

  assignMACWeightings(data: any) {
    return this._httpClient.postData(`${this._blendedFundEndPoint}/assignMACWeightings`, data);
  }
  updateMACWeightings(data: any) {
    return this._httpClient.updateData(`${this._blendedFundEndPoint}/assignMACWeightings`, data);
  }
  getSecuritySetMACModels(securitySetId: any) {
    return this._httpClient.getData(`${this._blendedFundEndPoint}/securitySetMACModels/${securitySetId}`);
  }
}
