<div [ngSwitch]="displayPermission" class="field-control">
  <div *ngSwitchCase="true"
       cdkDropList
       [cdkDropListData]="model"
       (cdkDropListDropped)="onDrop($event)" >
    <ul class="list-drag-group" >
      <li *ngFor="let item of model   | itemSort" class="list-drag-group-item" cdkDrag ><i class="fas fa-arrows" aria-hidden="true"></i> {{item.name}}</li>
    </ul>
  </div>
  <div *ngSwitchCase="false">
    <ul>
      <li *ngFor="let item of model ">
        <em>
          {{item.name}}
        </em>
      </li>
    </ul>
  </div>
</div>
