import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { TagType } from '../shared/tags';
import { Observable } from 'rxjs';
import { ITag } from '../models/tags';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private _tagsEndPointV2 = 'v2/tags';

  constructor(private _httpClient: OEHttpClient) {
  }

  /**
   * Get tags by tag type.
   * @tagType is type tag (account, portfolio, model, etc.)
   */
  getTagsByByTagType(tagType: TagType): Observable<ITag[]> {
    return this._httpClient.getData(`${this._tagsEndPointV2}/tagType/${tagType}`);
  }

  /**
   * Creates new tags.
   * @param tags
   */
  createTags(tags: ITag[]): Observable<ITag[]> {
    return this._httpClient.postData(`${this._tagsEndPointV2}`, tags);
  }

  /**
   * Deletes a tag.
   * @param tag
   */
  deleteTag(tag: ITag): Observable<void> {
    return this._httpClient.postData(`${this._tagsEndPointV2}/delete`, tag);
  }
}
