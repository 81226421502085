import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'eclipse-custom-input-box',
  templateUrl: './custom-input-box-renderer.component.html',
  styleUrls: ['./custom-input-box-renderer.component.scss']
})
export class CustomInputBoxRendererComponent implements ICellRendererAngularComp {
  inputValue = 0;
  maxDecimalPlaces = 7;
  private params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.inputValue = this.params.value ?? 0;
  }

  refresh(params: any): boolean {
    this.params = params;
    this.inputValue = this.params.value ?? 0;
    return true;
  }

  onInputBlur(): void {
    const numericValue = Number(this.inputValue);
    if (isNaN(numericValue) || !this.inputValue) {
      this.params.setValue(0);
      this.inputValue = 0;
    } else {
      this.params.setValue(numericValue);
    }
  }

  onInputKeypress(event: KeyboardEvent): void {
    const inputKey = event.key;
    const number = Number(`${this.inputValue}${inputKey}`);
    const splitNumber = `${this.inputValue}${inputKey}`.split('.');
    if (!/^\d*\.?\d*$/.test(inputKey) || isNaN(number) || (splitNumber?.length && splitNumber[1]?.length > this.maxDecimalPlaces)) {
      event.preventDefault();
    }
  }
}
