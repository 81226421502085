import { Component, inject, NgZone, OnInit } from '@angular/core';
import {AlertService, CustomSubService, ErrorNotificationService} from './core/customSubService';
import {VersionCheckService} from './core/version-check.service';
import {Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {SpinnerService} from './shared/spinner/spinner.service';
import {environment} from '../environments/environment';
import { Utils } from './core/functions';
import {DatadogService} from './services/datadog.service';
import {filter, take} from 'rxjs/operators';
import { SplitIoService } from './core/feature-flag/splitio.service';
import { AuthService } from './core';

@Component({
  selector: 'orion-eclipse-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly _subService: CustomSubService = inject(CustomSubService);
  private readonly _errorNotificationService: ErrorNotificationService = inject(ErrorNotificationService);
  private readonly ngZone: NgZone = inject(NgZone);
  private readonly _router: Router = inject(Router);
  private readonly _authService: AuthService = inject(AuthService);
  private readonly spinnerService: SpinnerService = inject(SpinnerService);
  private readonly _alertService: AlertService = inject(AlertService);
  private readonly versionCheckService: VersionCheckService = inject(VersionCheckService);
  private readonly _datadogService: DatadogService = inject(DatadogService);
  private readonly _splitService: SplitIoService = inject(SplitIoService);

  status: number = 0;
  statusText: string = '';
  statusBody: string = '';
  statusBodyTwo: string = '';
  showError: boolean = false;
  showClose: boolean = true;
  requestId: any;
  requestQueue: any = [];
  showNewVersionAvailable: boolean = false;

  constructor() {
    /** Checking router events to display loading icon when switching from one module to other as PRELOAD is disabled.*/
    this._router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });
  }

  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    this.versionCheckService.versionChanged.subscribe(() => {
      if (this._router.url === '/login' || this._router.url === '/') {
        Utils.refreshPage();
        return;
      }
      this.showNewVersionAvailable = true;
    });

    this._splitService.flagsEnabled(['eclipse_ui_datadog', 'eclipse_ui_datadog_on_app_start_210974'])
      .subscribe((flags) => {
        if(!!flags['eclipse_ui_datadog'] && !!flags['eclipse_ui_datadog_on_app_start_210974']) {
          // Once the app version has been determined, initialize Datadog
          this.versionCheckService.currentVersion$
            .pipe(
              filter(appVersion => !!appVersion?.timestamp), // ignore null app versions
              take(1) // initialize after the first valid app version is seen
            ) // only take the first version
            .subscribe(() => {
              this._datadogService.initialize();
            });
        }
      });

    this._subService.beforeQueueRequest.subscribe(requestData => {
      this.requestQueue.push(requestData);
      if (this.requestQueue.length > 0) {
        this.spinnerService.changeVisible(true);
      }
    });

    this._subService.afterQueueRequest.subscribe(requestData => {
      const index = this.requestQueue.findIndex(t => t.uuid === requestData['uuid']);
      this.requestQueue.splice(index, 1);
      if (this.requestQueue.length === 0) {
        this.spinnerService.changeVisible(false);
      }
    });

    // Temp Fix for Handling error in fork join
    this._subService.forkJoinError.subscribe(() => {
      this.requestQueue = [];
      this.spinnerService.changeVisible(false);
    });

    this._errorNotificationService.errorResponse.subscribe(error => {
      if (!this.showError && this._router.url.toLowerCase() !== '/logout' && this._router.url.toLowerCase() !== '/login' && this._router.url.toLowerCase() !== '/') {
        this.status = error.status;
        // eslint-disable-next-line eqeqeq
        this.requestId = error.requestId != undefined ? error.requestId : '';
        let showErrorDialog = false;
        this.showClose = true;

        switch (this.status) {
          case 401:
            this._authService.clearSession();
            this.statusText = `Status: ${this.status}`;
            this.statusBody = 'Your session has expired.';
            this.statusBodyTwo = 'Click OK to log in again.';
            showErrorDialog = true;
            this.showClose = false;
            break;
          case 403:
            this.statusText = `Status: ${this.status}`;
            this.statusBody = 'Forbidden Access';
            this.statusBodyTwo = '';
            showErrorDialog = true;
            break;
          case 400:
          case 404:
          case 422:
          case 500:
            this.statusText = `Status: ${this.status}`;
            this.statusBody = error.message || error.Message;
            // include error property, ignoring the error if it's a Blob (would display as [Blob])
            const message = error.error ? (error.error.message || error.error.Message) : '';
            this.statusBodyTwo = !!message ? message : error.error && !(error as Blob) ? error.error : '';
            showErrorDialog = true;
            break;
          case 502:
          case 0:
            const route = this._router.url.toLowerCase();
            if (route.indexOf('tradetool/tlh') > 0 || route === '/eclipse/tradetool/tlh') {
              this._alertService.alert.emit([{
                typeId: 2,
                message: 'TH/TLH process is running in the background and may take some time to complete'
              }]);
            }

            break;
          case 504:
            this.statusText = `Status: ${this.status}`;
            this.statusBody = 'Request Time Out';
            this.statusBodyTwo = '';
            showErrorDialog = true;
            break;
          default:
        }

        if (showErrorDialog) {
          this.showError = true;
        }
      }
    });
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.spinnerService.changeVisible(true); // displaying Loader icon
    }
    if (event instanceof NavigationEnd) {
      this.spinnerService.changeVisible(false); // Hiding Loader icon
    }
    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.spinnerService.changeVisible(false); // Hiding Loader icon
    }
    if (event instanceof NavigationError) {
      this.spinnerService.changeVisible(false); // Hiding Loader icon
    }
  }

  errorOK() {
    if (this.status === 401) {
      this.ngZone.run(() => {
        this._router.navigate(['/logout']);
      });
    }
    this.showError = false;
  }

  refreshPage(): void {
    Utils.refreshPage();
  }
}
