import {Injectable} from '@angular/core';
import {filter, startWith, pairwise, tap} from 'rxjs/operators';
import {Router, RoutesRecognized} from '@angular/router';

interface IRouteHistory {
  url: string;
  redirect: boolean;
}

@Injectable()
export class RouterHelperService {
  readonly MAX_ROUTE_HISTORY: number = 10;

  private routeHistory: IRouteHistory[] = [];

  get lastRoute(): string {
    if (this.routeHistory.length > 0) {
      return this.routeHistory[this.routeHistory.length - 1].url;
    }
    return null;
  }

  constructor(private _router: Router) {
    // subscribe to router navigation events to record the previous route
    this._router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        startWith(null),
        pairwise() // emits previous and current values as an array
      ).subscribe((events: any[]) => {
      const redirectedTo = this._router.getCurrentNavigation()?.extras?.state?.redirectedTo;
      if (events && events.length && !!events[0]) {
        this.routeHistory.push({url: events[0].urlAfterRedirects, redirect: !!redirectedTo});
        if (this.routeHistory.length > this.MAX_ROUTE_HISTORY) {
          this.routeHistory.shift(); // ensure the stack doesn't get too large
        }
      }
    });
  }

  /**
   * Clears the route history
   */
  clearHistory(): void {
    this.routeHistory = [];
  }

  /**
   * Navigates to the previous route.
   * @param ignoreRedirects if true, ignores routes that were the source of a redirect.
   *
   * Ex. /first > /second > redirected to /third.
   * ignoreRedirects=true will navigate the user back to /first
   * ignoreRedirects=false will navigate the user back to /second
   */
  goToPreviousRoute(ignoreRedirects: boolean = true): void {
    let lastRoute = this.lastRoute;
    if (ignoreRedirects) {
      // ignore redirects and the current route, since the current route will not appear as a redirectg
      const pureRoutes = this.routeHistory.filter(r => !r.redirect && r.url !== this._router.url);
      if (pureRoutes.length) {
        lastRoute = pureRoutes[pureRoutes.length - 1].url;
      }
    }
    if (lastRoute) {
      this._router.navigateByUrl(lastRoute); // navigateByUrl because navigate encodes queryparams and breaks navigation
    }
  }
}
