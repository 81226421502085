import { GridOptions, ColDef, GridApi } from '@ag-grid-community/core';
import {IExitWarning} from './exitwarning';

export * from './exitwarning';

/**
 * model for saved views
 */
export interface ISavedView {
  id: number;
  name: string;
  parentGridOptions: GridOptions;
  parentGridApi: GridApi;
  parentColumnDefs: ColDef[];
  exitWarning: IExitWarning;
  initialSavedViewId?: number;
  allowAddToDashboard: boolean;
  allowAddToFirmDashboard: boolean;
}
