export const ESG_TEMPLATE = {
  ESG_ASSIGNMENTS_IMPORT: "templates/esg/ESG_Assignments_Import.xlsx"
};

export const EQUIVALENT_TEMPLATE = {
  ACCOUNT_EQUIVALENT: "templates/equivalent/AccountEquivalent.xlsx",
  PORTFOLIO_EQUIVALENT: "templates/equivalent/PortfolioEquivalent.xlsx"
}

export const MODEL_TEMPLATE = {
  MODEL: "templates/model/model.xls",
  MODEL_IMPORT_EXPORT: "templates/model/Model Import_Export.xlsx",
  MODEL_IMPORT_SAMPLE: "templates/model/ModelImportSample.xlsx",
  MODEL_IMPORT_TEMPLATE: "templates/model/ModelImportTemplate.xlsx"
};

export const SECURITY_SET_TEMPLATE = {
  SECURITY_SET: "templates/securityset/securityset.xlsx"
}
