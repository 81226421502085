<div [formGroup]="form">
  <div [attr.for]="dynamicControlData.name">
    <div [ngSwitch]="dynamicControlData.componentType">
      <div *ngSwitchCase="'default'">
        <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}">{{dynamicControlData.displayName}}
          <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
        </label>
        <div class="col-md-1 col-xs-4"><span *ngIf="dynamicControlData.symbol !=null" class="input-group-addon-symbol">{{dynamicControlData.symbol }}</span>
          <span class="preference-indicator-select">
               <select class="form-control form-control-addon-symbol" *ngIf="dynamicControlData.indicatorOptions != undefined && dynamicControlData.indicatorOptions.length > 0"
                       (change)="setDynamicDataValue($event)"
                       [(ngModel)]="dynamicControlData.indicatorValue"
                       [formControlName]="indicatorControlValue" style="width:auto;">
                  <option value="null" *ngIf="!dynamicControlData.indicatorValue">Select Indicator</option>
                  <option *ngFor="let option of dynamicControlData.indicatorOptions" value={{option.name}}>{{option.name}}</option>
                </select>
          </span>
        </div>
        <div [ngSwitch]="dynamicControlData.componentName" class="col-md-7 col-xs-12">
          <div *ngSwitchCase="'Textbox'" class="field-control">
            <div [ngSwitch]="dynamicControlData.valueType">
              <div *ngSwitchCase="'text'">
                  <input [title]="showTooltip(dynamicControlData)" class="form-control placeholder-input" [(ngModel)]="dynamicControlData.value"
                  (keydown)="dynamicControlData.value=$event.target.value" [formControlName]="dynamicControlData.name" [id]="dynamicControlData.name"
                  [type]="dynamicControlData.valueType"
                  (keypress)="setValid($event)"
                   [placeholder]="dynamicControlData.watermarkText" onpaste="return false">
              </div>
              <div *ngSwitchDefault>
                  <input [title]="showTooltip(dynamicControlData)" class="form-control placeholder-input" [(ngModel)]="dynamicControlData.value"
                  (keydown)="dynamicControlData.value=$event.target.value" [formControlName]="dynamicControlData.name" [id]="dynamicControlData.name"
                  [type]="dynamicControlData.valueType" [pattern]="dynamicControlData.pattern" [step]="(dynamicControlData.valueType=='number')? '1':'0.01'"
                  (keypress)="setValid($event)" [min]="dynamicControlData.minValue" [max]="dynamicControlData.maxValue" (keyup)="emptyError()"
                  [ngClass] = "{'disableInputField': dynamicControlData.disabled === true}"
                  (invalid)="validateTextBox(dynamicControlData.value)" [placeholder]="dynamicControlData.watermarkText" onpaste="return false">
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'Dropdown'" class="field-control">
            <select [id]="dynamicControlData.name" [title]="showTooltipdropdown(dynamicControlData)" class="form-control" (change)="setDropdownSelectedOption($event)"
                    [formControlName]="dynamicControlData.name">
              <option value="" selected="selected">Please Select</option>
              <option *ngFor="let opt of dynamicControlData.options" [id]="dynamicControlData.name" [value]="opt.id">{{opt.name}}</option>
            </select>
          </div>


          <div *ngSwitchCase="'Checkbox'" [id]="dynamicControlData.name" class="field-control">
            <div [ngSwitch]="dynamicControlData.valueType">
              <div *ngSwitchCase="'Boolean'" >
                <label [title]="showTooltip(dynamicControlData)">
                  <p-checkbox [id]="dynamicControlData.name" [name]="dynamicControlData.name" [binary]="true"
                              [(ngModel)]="dynamicControlData.value" [formControlName]="dynamicControlData.name"
                              [trueValue]="'true'" [falseValue]="'false'" (onChange)="setCheckBoValue($event)"></p-checkbox>
                </label>
              </div>

              <div *ngSwitchCase="'List'">
                <div *ngFor="let opt of dynamicControlData.options">
                  <label>
                    <input type="checkbox" [id]="dynamicControlData.name" [name]="dynamicControlData.name" [value]="opt.id" [formControlName]="dynamicControlData.name"
                           [ngModel]="dynamicControlData.selectedOptions.indexOf(opt.id) > -1" (change)="setToCheckBoxControl(opt.id,dynamicControlData,dynamicControlData.selectedOptions)"> {{opt.name}}
                  </label>
                </div>
              </div>
            </div>
          </div>


          <div *ngSwitchCase="'Radio'" class="field-control">
            <div *ngFor="let opt of dynamicControlData.options">
              <input type="Radio" [id]="dynamicControlData.name" [formControlName]="dynamicControlData.name" [ngModel]="{value:dynamicControlData.value }"
                     name="radioBtn" [checked]="getRadioChecked(opt,dynamicControlData)" (click)="setToRadioControl(opt,dynamicControlData)"
                     [value]="opt.id"> {{opt.name}}
            </div>
          </div>
          <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
        </div>
      </div>
      <!--MAC control display / rendering-->
      <div *ngSwitchCase="'MACBUY'" class="form-group">
        <div *ngIf="dynamicControlData.watermarkText =='MACBuy'" class="col-xs-12">
          <div>
            <eclipse-mac-buy-preference [preferenceViewModel]="preferenceResults" [toleranceSettingsBuy]="toleranceSettingsBuy"></eclipse-mac-buy-preference>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'MACSELL'" class="form-group">
        <div *ngIf="dynamicControlData.watermarkText =='MACSell'" class="col-xs-12">
          <div>
            <eclipse-mac-sell-preference [preferenceViewModel]="preferenceResults" [toleranceSettingsSell]="toleranceSettingsSell"></eclipse-mac-sell-preference>
          </div>
        </div>
      </div>


      <!--Custom control display / rendering-->
      <div *ngSwitchCase="'custom'">
        <div [ngSwitch]="dynamicControlData.componentName">

          <div *ngSwitchCase="'TaxLotDepletionMethodSortedlist'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i
              class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12" [ngClass] = "{'disableInputField': dynamicControlData.disabled === true}">
              <eclipse-sort-objects [sortData]="dynamicControlData" [displayPermission]="true"
                                     [(model)]="(dynamicControlData.selectedOptions.length >0) ? dynamicControlData.selectedOptions : dynamicControlData.options"></eclipse-sort-objects>
              <a href="javascript:void(0)" *ngIf = "!dynamicControlData.disabled" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
            </div>
          </div>

          <div *ngSwitchCase="'PriorityRankingSortedlist'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i
              class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-md-2"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-sort-objects [sortData]="dynamicControlData" [displayPermission]="true"
                                     [(model)]="(dynamicControlData.selectedOptions.length > 0) ? dynamicControlData.selectedOptions : dynamicControlData.options"></eclipse-sort-objects>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
            </div>
          </div>

          <div *ngSwitchCase="'CommunityStrategistEnabledListCascadingControl'" class="form-group">
            <eclipse-community-strategist [displayPermission]="true" [(CommunityStrategies)]="communityStrategies"></eclipse-community-strategist>
            <a href="javascript:void(0)" class="btn-reset btn-reset-community"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

            <!-- INHERITED POPUP SECTION  !-->
            <p-dialog header="Community-Strategists" [resizable]="false" [(visible)]="isEnableComStr"
                      class="ui-dialog-lg" [closable]="true" modal="false">
              <div *ngIf="inheritedFromTxt != null" class="form-group">
                <label class="col-md-4 col-xs-12">Inherited From:</label>
                <div class="col-md-6 col-md-pull-1 col-xs-12">
                  <label class="col-md-3">{{inheritedFromTxt}}</label>
                </div>
              </div>
              <eclipse-community-strategist [displayPermission]="false" [(CommunityStrategies)]="clonedCommunityStrategies"></eclipse-community-strategist>
              <p-footer>
                <button pButton (click)="isEnableComStr = false" label="Close"></button>
              </p-footer>
            </p-dialog>
            <!-- INHERITED POPUP SECTION  !-->

          </div>

          <div *ngSwitchCase="'LocationOptimizationDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-location-optimization [displayPermission]="true" [(LocationOptimizations)]="LocationOptimizations"></eclipse-location-optimization>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" aria-hidden="true"></i></a>
              <p-dialog header="Location Optimization" [resizable]="false" [(visible)]="isEnableLocOpt"
                        class="ui-dialog-lg" [closable]="true" modal="false">
                <div class="fullWidth-grid-parent row">
                  <div class="col-md-12 margin-top-15">
                    <eclipse-location-optimization class = "location-popup" [displayPermission]="false" [(LocationOptimizations)]="clonedLocationOptimizations"></eclipse-location-optimization>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableLocOpt = false" label="Close"></button>
                </p-footer>
              </p-dialog>
            </div>
          </div>

          <!--REDEMPTION FEE PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'RedemptionFeeDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-redemption-fee [displayPermission]="true" [(fromParent)]="RedemptionFeePrefResult" [(st)]="secTypes"></eclipse-redemption-fee>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Redemption Fee" [resizable]="false" [(visible)]="isEnableRedFee" class="ui-dialog-lg"
                        [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-redemption-fee [displayPermission]="false" [(fromParent)]="clonedRedemptionFeePrefResult" [(st)]="secTypes"></eclipse-redemption-fee>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableRedFee = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>

          <!--CUSTODIAN REDEMPTION FEE PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'CustodianRedemptionFeeDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-custodian-redemption-fee [displayPermission]="true" [(fromParent)]="CustodianRedemptionFeePrefResult" [(st)]="secTypes"></eclipse-custodian-redemption-fee>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Custodian Redemption Fee" [resizable]="false" [(visible)]="isEnableCustRedFee"
                        class="ui-dialog-lg" [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-custodian-redemption-fee [displayPermission]="false" [(fromParent)]="clonedCustodianRedemptionFeePrefResult" [(st)]="secTypes"></eclipse-custodian-redemption-fee>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableCustRedFee = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>

          <!--TRADE MIN PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'TradeMinDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-trade-min [displayPermission]="true" [(fromParent)]="TradeMinPrefResult" [(st)]="secTypes"></eclipse-trade-min>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Trade Min" [resizable]="false" [(visible)]="isEnableTradeMin" class="ui-dialog-lg"
                        [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-trade-min [displayPermission]="false" [(fromParent)]="clonedTradeMinPrefResult" [(st)]="secTypes"></eclipse-trade-min>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableTradeMin = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>

          <!--TRADE MAX PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'TradeMaxDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-trade-max [displayPermission]="true" [(fromParent)]="TradeMaxPrefResult" [(st)]="secTypes"></eclipse-trade-max>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Trade Max" [resizable]="false" [(visible)]="isEnableTradeMax" class="ui-dialog-lg"
                        [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-trade-max [displayPermission]="false" [(fromParent)]="clonedTradeMaxPrefResult" [(st)]="secTypes"></eclipse-trade-max>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableTradeMax = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>

          <!--TRANSACTION FEE PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'TransactionFeeDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-transaction-fee [displayPermission]="true" [(fromParent)]="TransactionFeePrefResult" [(st)]="secTypes"></eclipse-transaction-fee>
              <!--<DividendReinvest-DataGrid [displaypermission]="true" [(fromParent)]="TransactionFeePrefResult"></DividendReinvest-DataGrid>-->
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Transaction Fee" [resizable]="false" [(visible)]="isEnableTransFee"
                        class="ui-dialog-lg" [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-transaction-fee [displayPermission]="false" [(fromParent)]="clonedTransactionFeePrefResult" [(st)]="secTypes"></eclipse-transaction-fee>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableTransFee = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>

          <!--DIVIDEND REINVEST PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'DividendReinvestDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-dividend-reinvest [displayPermission]="true" [(fromParent)]="DividendReinvestPrefResult" [(st)]="secTypes"></eclipse-dividend-reinvest>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Dividend Reinvest" [resizable]="false" [(visible)]="isEnableDivReinv"
                        class="ui-dialog-lg" [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-dividend-reinvest [displayPermission]="false" [(fromParent)]="clonedDividendReinvestPrefResult" [(st)]="secTypes"></eclipse-dividend-reinvest>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableDivReinv = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->
            </div>
          </div>

          <!--CAPITAL GAIN REINVEST PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'CapitalGainReinvestDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-capitalgain-reinvest [displayPermission]="true" [(fromParent)]="CapitalGainReinvestPrefResult" [(st)]="secTypes"></eclipse-capitalgain-reinvest>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Capital Gain Reinvest" [resizable]="false" [(visible)]="isEnableCapReinv"
                        class="ui-dialog-lg" [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-capitalgain-reinvest [displayPermission]="false" [(fromParent)]="clonedCapitalGainReinvestPrefResult" [(st)]="secTypes"></eclipse-capitalgain-reinvest>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableCapReinv = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>

          <!--TRADE SETTINGS PREFERENCE SETTING PLACEHOLDER-->
          <div *ngSwitchCase="'TradeSettingsDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-trade-settings [displayPermission]="true" [(fromParent)]="TradeSettingsPrefResult" [(st)]="secTypes"></eclipse-trade-settings>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <!-- INHERITED POPUP SECTION  !-->
              <p-dialog header="Trade Settings" [resizable]="false" [(visible)]="isEnableTradeSet"
                        class="ui-dialog-lg" [closable]="true" modal="false">
                <div class="row fullWidth-grid-parent margin-top-15">
                  <div class="col-md-12">
                    <eclipse-trade-settings [displayPermission]="false" [(fromParent)]="clonedTradeSettingsPrefResult" [(st)]="secTypes"></eclipse-trade-settings>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableTradeSet = false" label="Close"></button>
                </p-footer>
              </p-dialog>
              <!-- INHERITED POPUP SECTION  !-->

            </div>
          </div>


          <!--DEFAULT PRIMARY TEAM PREFERENCE PLACEHOLDER-->
          <div *ngSwitchCase="'DefaultPrimaryTeamDropdownSearch'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-default-primary-team [displayPermission]="true" [(fromParent)]="DefaultPrimaryTeamsPrefResult"></eclipse-default-primary-team>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
            </div>
          </div>

          <div *ngSwitchCase="'SystematicMultiplierLink'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <a class="field-control p-t-10" href="javascript:void(0);" (click)="isEnableSystematicMultiplier = true">Click here to view</a>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-question-circle" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <p-dialog header="Systematic Set Aside Cash Multiplier {{systematicMultiplier.isValid}}" [(visible)]="isEnableSystematicMultiplier" modal="true" [draggable]="false" [resizable]="false" class="ui-dialog-xl" [closable]="false" [closeOnEscape]="false">
                <div class="col-sm-12 breakWord p-content p-0 table-responsive">
                  <div class="col-sm-12 p-0">
                    <div class="form-group is-empty">
                      <eclipse-systematic-multiplier-grid #systematicMultiplier [canEdit]="true" [preferenceData]="SystematicMultiplierPrefResult"></eclipse-systematic-multiplier-grid>
                    </div>
                  </div>
                </div>
                <p-footer>
                    <span *ngIf="!systematicMultiplier.isValid" class="text-danger m-r-10">{{systematicMultiplier.errorMessage}}</span>
                    <button pButton class="p-button-secondary" (click)="setResetControl($event)" [id]="dynamicControlData.name" label="Reset"></button>
                    <button pButton [disabled]="!systematicMultiplier.isValid" (click)="isEnableSystematicMultiplier = false" label="Close"></button>
                </p-footer>
              </p-dialog>

              <p-dialog header="Inherited Systematic Set Aside Cash Multiplier" [(visible)]="isEnableInheritedSystematicMultiplier" modal="true"
                        [draggable]="false" [resizable]="false" class="ui-dialog-xl" [closable]="true" [closeOnEscape]="true">
                <div class="col-sm-12 breakWord p-content p-0 table-responsive">
                  <div class="col-sm-12 p-0">
                    <div class="form-group is-empty">
                      <eclipse-systematic-multiplier-grid [canEdit]="false" [preferenceData]="clonedSystematicMultiplierPrefResult"></eclipse-systematic-multiplier-grid>
                    </div>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableInheritedSystematicMultiplier = false" label="Close"></button>
                </p-footer>
              </p-dialog>
            </div>
          </div>
          <div *ngSwitchCase="'BillingCashMultiplierLink'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <a class="field-control p-t-10" href="javascript:void(0);" (click)="isEnableBillingCashMultiplier = true">Click here to view</a>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-question-circle" (click)="setResetControl($event)" [id]="dynamicControlData.name" aria-hidden="true"></i></a>
              <a *ngIf="levelName != 'Firm'" href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="viewInheritedPopups()" [id]="dynamicControlData.name" aria-hidden="true"></i></a>

              <p-dialog header="Billing Set Aside Cash Multiplier {{billingCashMultiplier.isValid}}" [(visible)]="isEnableBillingCashMultiplier" modal="true" [draggable]="false" [resizable]="false" class="ui-dialog-xl" [closable]="false" [closeOnEscape]="false">
                <div class="col-sm-12 breakWord p-content p-0 table-responsive">
                  <div class="col-sm-12 p-0">
                    <div class="form-group is-empty">
                      <eclipse-billing-cash-multiplier-grid #billingCashMultiplier [canEdit]="true" [preferenceData]="BillingCashMultiplierPrefResult"></eclipse-billing-cash-multiplier-grid>
                    </div>
                  </div>
                </div>
                <p-footer>
                  <span *ngIf="!billingCashMultiplier.isValid" class="text-danger m-r-10">{{billingCashMultiplier.errorMessage}}</span>
                  <button pButton class="p-button-secondary" (click)="setResetControl($event)" [id]="dynamicControlData.name" label="Reset"></button>
                  <button pButton [disabled]="!billingCashMultiplier.isValid" (click)="isEnableBillingCashMultiplier = false" label="Close"></button>
                </p-footer>
              </p-dialog>

              <p-dialog header="Inherited Billing Set Aside Cash Multiplier" [(visible)]="isEnableInheritedBillingCashMultiplier" modal="true"
                        [draggable]="false" [resizable]="false" class="ui-dialog-xl" [closable]="true" [closeOnEscape]="true">
                <div class="col-sm-12 breakWord p-content p-0 table-responsive">
                  <div class="col-sm-12 p-0">
                    <div class="form-group is-empty">
                      <eclipse-billing-cash-multiplier-grid [canEdit]="false" [preferenceData]="clonedBillingCashMultiplierPrefResult"></eclipse-billing-cash-multiplier-grid>
                    </div>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="isEnableInheritedBillingCashMultiplier = false" label="Close"></button>
                </p-footer>
              </p-dialog>
            </div>
          </div>

          <div *ngSwitchCase="'MoneyMarketFundsGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8"
                   [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText" tooltipPosition="bottom" class="btn-help">
                 <i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-money-market-fund [canEdit]="true"
                                         [moneyMarketFundPreference]="MoneyMarketFundPreference"></eclipse-money-market-fund>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)"
                                                                [id]="dynamicControlData.name"
                                                                aria-hidden="true"></i></a>
              <eclipse-inherited-preference headerName="Money Market Funds"
                                            [componentType]="dynamicControlData.componentName"
                                            [moneyMarketFundPreference]="cloneMoneyMarketFundPreference"></eclipse-inherited-preference>
            </div>
          </div>

          <div *ngSwitchCase="'MoneyMarketAllocationGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8"
                   [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" tooltipStyleClass="preference-tooltip" [pTooltip]="dynamicControlData.helpText"
                 tooltipPosition="bottom" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-money-market-preference [canEdit]="true"
                                               [moneyMarketAllocationPreference]="MoneyMarketAllocationPreference">
              </eclipse-money-market-preference>
              <a href="javascript:void(0)" class="btn-reset"><i class="fas fa-undo" (click)="setResetControl($event)"
                                                                [id]="dynamicControlData.name"
                                                                aria-hidden="true"></i></a>
              <eclipse-inherited-preference headerName="Money Market Allocation"
                                            [componentType]="dynamicControlData.componentName"
                                            [moneyMarketAllocationPreference]="cloneMoneyMarketAllocationPreference"></eclipse-inherited-preference>
            </div>
          </div>

        </div>
      </div>
      <span class="cat-pref-error">{{errorMessage }}</span>
    </div>
  </div>
</div>
