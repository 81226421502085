import { EventEmitter } from '@angular/core';
import { EntityType } from "../../libs/preference.enums";

export enum EditorEntityType {
  Account = 'account',
  Portfolio = 'portfolio'
}

export interface IEntityEditorConfig {
  entityType: EditorEntityType;
  data?: any;
  skipAddingQueryParameter?: boolean;
}

export interface IEntityEditorComponent {
  entityEditorConfig: IEntityEditorConfig;
  visible: boolean;
  visibleChange: EventEmitter<boolean>;
}

export interface IEntityMessage {
  relatedType: EntityType,
  relatedId: number;
}
