import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../core/base.component';
import { UserService } from '../services/user.service';
import { LoginService } from './login.service';
import { AuthService, SessionHelper } from '../core';
import { UserType } from '../libs/app.constants';
import { LoggerFactory } from '../core/logging';
import { take } from 'rxjs/operators';

enum PageStatus {
  Starting,
  Authenticating,
  GettingUserDetails,
  Error,
  Success,
  NoToken
}

@Component({
  selector: 'eclipse-authorize',
  templateUrl: './authorize.component.html'
})
export class AuthorizeComponent extends BaseComponent {
  private readonly _userService: UserService = inject(UserService);
  private readonly _loginService: LoginService = inject(LoginService);
  private readonly _router: Router = inject(Router);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _authService: AuthService = inject(AuthService);

  private readonly ACCEPTED_REDIRECT_QUERY_PARAMETERS = ['entityType', 'entityId', 'scenarioId', 'id'];
  private token: any;
  private connectAccessToken: string;
  public errorMessage: string;
  public pageStatus: PageStatus = PageStatus.Starting;
  public statuses = PageStatus;

  public readonly errorMessages = {
    APIUserNotAllowed: 'API users are blocked from logging into the site interactively.',
    AccessTokenNotProvided: 'Access token was not provided.',
    InvalidCredentials: 'Invalid credentials.',
    InvalidUserAccount: 'Invalid user account.',
    UnknownError: 'An unknown error has occurred.',
  };

  constructor(private readonly sessionHelper: SessionHelper) {
    super('', sessionHelper);
    this._authService.clearSession();
    // Get the token from a query parameter (ex. eclipse/#/authorize?t=TOKEN).
    // Used when OC after the user logs in and gets redirected to this page.
    this._activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['t'] !== undefined) {
        this.connectAccessToken = queryParams['t'];
      }
    });
    // Get the token from the route (ex. eclipse/#/authorize/TOKEN).
    // Used when the user SSOs (clicks on the Eclipse app) in OC.
    this._activatedRoute.params.subscribe(params => {
      if (params && params['accessToken'] !== undefined) {
        this.connectAccessToken = params['accessToken'];
      }
    });
  }

  ngOnInit() {
    // A token should be present by this point, so either validate it or show an error.
    if (this.connectAccessToken !== undefined) {
      this.validateAccessToken();
    } else {
      this.setPageStatus(PageStatus.Error, this.errorMessages.AccessTokenNotProvided);
    }
  }

  /**
   * Validates the provided access token.  If valid, proceeds with loading the user.
   */
  validateAccessToken() {
    this.setPageStatus(PageStatus.Authenticating);
    // Validate target url here
      this._loginService.authorizeOrionConnectToken(this.connectAccessToken)
        .subscribe({
          next: token => {
            this.token = token;
            this.token.isAuthenticated = true;
            this._authService.setToken(this.token);
            this.getUserDetails();
          },
          error: error => {
            this.setPageStatus(PageStatus.Error, error.status === 401 ? this.errorMessages.InvalidCredentials : this.errorMessages.UnknownError);
            this._sessionHelper.set('isValidAccessToken', false);
          }
        });
  }

  /**
   * Retrieves the user details.  If successful, the user is navigated into Eclipse.
   * If the user is an API only user, they will be denied access.
   * If the user details cannot be retrieved (invalid record, etc), they will be deined access.
   * If a redirect URL is present (authorize?t=TOKEN&r=portfolio), the user is initially navigated to
   *  the redirect page, otherwise they are sent to the Overview dashboard.
   */
  getUserDetails(): void {
    this.setPageStatus(PageStatus.GettingUserDetails);
    // load user data
    this._userService.getUser()
      .subscribe({
        next: user => {
          if (user.role && user.role.roleTypeId !== UserType.APIOnly) {
            this.setPageStatus(PageStatus.Success);
            LoggerFactory.initForUser(user);
            this._loginService.registerGainsightSession(user);

            this._authService.currentUser = user;

            // Look for the 'r' (redirect) query param by taking the first value from the query param and completing
            this._activatedRoute.queryParams
              .pipe(take(1))
              .subscribe(queryParams => {
                // Check for a redirect query parameter
                if (queryParams.r) {
                  // Parse the redirect param into a route and query parameters
                  const parsedRoute = this.extractQueryParams(queryParams.r);

                  // Parse all the other query parameters in the url to get all related to the redirect.
                  this.ACCEPTED_REDIRECT_QUERY_PARAMETERS
                    .filter(param => queryParams[param] !== undefined)
                    .forEach(param => {
                      parsedRoute.queryParams[param] = queryParams[param];
                    });

                  this._router.navigate([`eclipse/${parsedRoute.route}`], {queryParams: parsedRoute.queryParams});
                } else {
                  // redirects to dashboard page on successful login
                  this._router.navigate(['eclipse/dashboard']);
                }
              });
          } else { // API Only users cannot log in
            this.setPageStatus(PageStatus.Error, this.errorMessages.APIUserNotAllowed);
            this._sessionHelper.set('isApiOnly', true);
          }
        },
        error: (error) => {
          this.setPageStatus(PageStatus.Error, error.status === 401 ? this.errorMessages.InvalidUserAccount : this.errorMessages.UnknownError);
          this._sessionHelper.set('isValidAccessToken', false);
        }
      });
  }

  /**
   * Sets the page status and message.
   * @param newStatus
   * @param message
   * @private
   */
  private setPageStatus(newStatus: PageStatus, message: string = null): void {
    this.pageStatus = newStatus;
    this.errorMessage = null;

    switch (this.pageStatus) {
      case PageStatus.Error:
        console.warn(this.errorMessage);
        this.errorMessage = message;
        break;
    }
  }

  /**
   * Extracts the root route and any query parameters from a url string.
   * @param url
   * Example: a/b/c?x=1&y=z
   * Result: { route: 'a/b/c', queryParams: { x: '1', y: 'z' } }
   */
  extractQueryParams(url: string): {route: string, queryParams: { [key: string]: any; }} {
    let queryParamsData = {};
    const urlSplit = url.split('?');
    const route = urlSplit[0];
    const queryParams = urlSplit[1];
    if (queryParams?.length) {
      const params = queryParams.split('&');
      let pair = null;
      params.forEach((d) => {
        pair = d.split('=');
        queryParamsData[`${pair[0]}`] = pair[1];
      });
    }
    return {route: route, queryParams: queryParamsData };
  }
}
