import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'checkbox-renderer',
  template: `
    <input
      type="checkbox"
      (click)="checkedHandler($event)"
      [checked]="params.value"
      [disabled]="isDisabled()"
    />
  `,
})
export class CheckboxRenderer implements ICellRendererAngularComp {
  public params: any;
  public isDisabled = () => { return false; };

  agInit(params: any): void {
    this.params = params;
    if (!!params.disabled) {
      this.isDisabled = params.disabled;
    }
  }

  checkedHandler(event) {
    const checked = event.target.checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(params: any): boolean {
    return false;
  }
}
