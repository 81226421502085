import {Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {IAccount} from '../../../models/portfolio';
import {PortfolioAutoCompleteComponent} from '../../../shared/search/portfolio.autocomplete.component';
import {ITacticalQuery} from '../../../models/tactical';
import {PortfolioService} from '../../../services/portfolio.service';
import {AlertService} from '../../../core';
import { Utils as Util } from '../../../core/functions';

@Component({
  selector: 'eclipse-tactical-trades-pop-up',
  templateUrl: './tacticaltradespopup.component.html'
})
export class TacticalTradesPopupComponent {
  @Output() callParentMethod = new EventEmitter();
  @ViewChild(PortfolioAutoCompleteComponent) portfolioSearchComp: PortfolioAutoCompleteComponent;
  @Output() callContinueMethod = new EventEmitter();
  @Output() callContinueAndDelete = new EventEmitter();

  selectedItem: any;
  isSleevePortfolio: boolean;
  queryparams: ITacticalQuery;
  portfolioAccounts: IAccount[];
  sleevedAccounts: any[] = [];
  @Input() forTacticalTool: boolean = false;
  @Input() showDefaultAction: boolean = true;
  @Input() dialogType;
  errors = [];

  constructor(private _portfolioService: PortfolioService, private _alertService: AlertService) {
    this.queryparams = <ITacticalQuery>{defaultAction: 'N', usePending: true};
  }

  onDefaultAction(val) {
    this.queryparams.defaultAction = val;
  }

  onShow() {
    this.selectedItem = undefined;
    this.portfolioSearchComp.selectedItem = undefined;
    this.portfolioSearchComp.setFocus();
  }

  closePopup() {
    if (this.dialogType === 'portfolio') {
      this.selectedItem = undefined;
      this.portfolioSearchComp.selectedItem = undefined;
      this.sleevedAccounts = [];
      this.isSleevePortfolio = false;
      this.queryparams.defaultAction = 'N';
    }
    this.callParentMethod.emit();
  }

  continueClick() {
    this.callContinueMethod.emit();
  }

  continueDeleteTrades() {
    this.callContinueAndDelete.emit();
  }
  getSelectedPortfolio(item) {
    this.selectedItem = item.value;
    this.errors = [];
    if (this.selectedItem.doNotTrade && !this.forTacticalTool) { // Tactical Tool can load Do Not Trade portfolios
      this.errors.push({typeId: 4, message: 'Portfolio is marked as \'Trade Blocked\' or \'Do not Trade\'.'});
    }
    if (this.selectedItem.managedValue <= 0 || !this.selectedItem.isAccountsHaving) {// Bug fix:OEF-4810
      this.errors.push({typeId: 4, message: 'Portfolio with no Accounts or Managed Value is not > $0.'});
    }
    if (!this.selectedItem.isPrimary) {
      this.errors.push({typeId: 4, message: 'Portfolio does not have \'Primary Team\' assigned.'});
    }
    if (this.selectedItem.isDisabledForTactical) {
      this.errors.push({typeId: 4, message: 'Portfolio is disabled.'});
    }
    if (this.errors.length === 0) {
      this.queryparams.portfolioId = this.selectedItem.id;
      this.queryparams.portfolioName = this.selectedItem.name;
      this.isSleevePortfolio = this.selectedItem.sleevePortfolio;
      this.getPortfolioSleevedAccounts(this.selectedItem.id);
    } else {
      this._alertService.alert.emit(this.errors);
    }
  }

  getPortfolioSleevedAccounts(portfolioId: number) {
    this.sleevedAccounts = [];
    this.portfolioAccounts = [];
    if (portfolioId !== undefined && this.isSleevePortfolio) {
      this._portfolioService.getSleevedAccountsById(portfolioId)
        .subscribe((sleeves: IAccount[]) => {
          const accountsList = [];
          sleeves.forEach(element => {
            accountsList.push({label: element.name, value: element.id});
          });
          this.portfolioAccounts = Util.sortBy(accountsList, 'label');
        });
    }
  }

  getSelectedAccount(item) {
    if (!!item) {
      this.queryparams.accountId = item.toString();
    }
  }

}
