import { Component, ComponentRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { IEntityEditorComponent, IEntityEditorConfig } from './entity-editor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

export class EditorPageLookup {
  name: string;
  component: any;
}

@Component({
  template: ''
})
export abstract class EntityEditorComponent extends BaseComponent implements IEntityEditorComponent {
  @ViewChild('contentContainer', {read: ViewContainerRef, static: true}) contentContainer: ViewContainerRef;
  public entityEditorConfig: IEntityEditorConfig;
  public sidebarMenuItems$: Observable<MenuItem[]>;
  public actionItems: MenuItem[];
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected EditorPages: { [key in string]: EditorPageLookup };
  protected destroyed$: Subject<void> = new Subject<void>();
  protected _activePage: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private componentCache = new Map<string, ComponentRef<any>>();

  constructor() {
    super();
  }

  private _visible: boolean = false;

  @Input()
  public get visible(): boolean {
    return this._visible;
  }

  public set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.contentContainer?.clear();
    this.componentCache.clear();
  }

  /**
   * Loads a component into the content container.  If the component has been previously created, the view
   * is pulled from cache.  Otherwise, a new instance is created.
   * @param key
   * @private
   */
  protected loadComponent(key: string): void {
    // Detach the existing component view (don't destroy, because it'll be reused).
    this.contentContainer.detach();
    // If the new component has been cached, attempt to restore its view.
    if (this.componentCache.has(key)) {
      // Get the component from the view cache.
      const componentRef = this.componentCache.get(key);
      // If the component exists and the view hasn't been destroyed, insert it back into the content container.
      if (componentRef && !componentRef.hostView.destroyed) {
        this.contentContainer.insert(componentRef.hostView);
        this._activePage.next(key);
        return;
      }
    }
    const component = this.EditorPages[key]?.component;
    if (!component) {
      console.error('Invalid component for type', key);
      return;
    }
    // The component either hasn't been cached or has been destroyed, so create it.
    this.createAndCacheComponent(key, component);
    this._activePage.next(key);
  }

  /**
   * Creates a component and caches the instance.
   * @param key
   * @param component
   * @private
   */
  private createAndCacheComponent(key: string, component: any): void {
    const componentRef = this.contentContainer.createComponent(component);
    this.componentCache.set(key, componentRef);
  }
}
