export class CustomNoRowsOverlay {
  eGui: HTMLElement;
  init(params): void {
    this.eGui = document.createElement('span');
    this.eGui.innerHTML = `<span> ${params.noRowsMessageFunc()}</span>`;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  refresh(): boolean {
    return false;
  }
}
