import {Component, HostListener} from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { GridOptions, ColDef, CsvExportParams, GridReadyEvent, GridApi } from '@ag-grid-community/core';
import { IAnalyticsErrorLog } from '../../models/errorlog';
import { ErrorLogService } from '../../services/errorlog.service';
import { Router, ActivatedRoute } from '@angular/router';
import {BaseGridConfiguration} from '../../shared/gridextensions/basegrid';
import { Utils as Util } from '../../core/functions';


@Component({
  selector: 'eclipse-analytics-errors',
  templateUrl: './analyticserrorlog.component.html',
  providers: [ErrorLogService]
})

export class AnalyticsErrorLogComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  type: string;
  errorLogsData: IAnalyticsErrorLog[] = [];

  constructor(private _errorLogService: ErrorLogService, private activateRoute: ActivatedRoute, private _router: Router) {
    super();
    this.gridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: BaseGridConfiguration.sideBarColumnsRowGroup,
      statusBar: {
          ...BaseGridConfiguration.statusBarRowCounts
        }
      };
    Util.windowSize();
  }

  ngOnInit() {
    Util.windowSize();
    this.createColumnDefs();
    this.getAnalyticsErrorLogsData();
  }

  @HostListener('window:resize')
  onResize() {
    Util.windowSize();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  /** Create column headers for agGrid */
   createColumnDefs() {
    this.columnDefs = [
      <ColDef>{ headerName: 'Portfolio ID', field: 'portfolioId', width: 100, cellClass: 'text-center', filter: 'agNumberColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Portfolio Name', field: 'portfolioName', width: 150, cellClass: 'text-center fs-mask', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Analytics Type', field: 'analyticsType', width: 100, cellClass: 'text-center', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Error', field: 'error', width: 150, cellClass: 'text-center', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
    ];
  }

  getAnalyticsErrorLogsData() {
    this._errorLogService.getAnalyticsErrorLogs()
      .subscribe((errorLogs: IAnalyticsErrorLog[] )  => {
        this.errorLogsData = errorLogs;
        this.gridApi.setGridOption('rowData', this.errorLogsData);
        this.gridApi.sizeColumnsToFit();
      });
  }

  /** Export the grid data to csv file */
  exportToExcel() {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipGroups: true,
      fileName: 'Analytics Errors Details.csv'
    };

    this.gridApi.exportDataAsCsv(params);
  }

}
