import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FeatureFlagService } from '../feature-flag';
import { map } from 'rxjs/operators';

/**
 *  Service for guarding routes protected by feature flags.
 *  If a feature flag is not enabled, the router will redirect the user to a provided url.
 *
 *  Route requires `featureFlags` array, `replaceUrl`, and `withUrl` properties.
 *
 *  Example:  if SomeFeatureFlag is not enabled, navigating to /someRoute will redirect the user to /someRoute/somewhereElse
 *  ```
 *  routes = [{
 *  path: '/someRoute',
 *  component: RestrictedPlansComponent,
 *    data: {
 *      featureFlags: ['SomeFeatureFlag'],
 *      replaceUrl: 'someRoute',
 *      withUrl: 'someRoute/somewhereElse/',
 *    },
 *  }];
 *  ```
 */
export const featureFlagGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const featureFlagService: FeatureFlagService = inject(FeatureFlagService);
  const router: Router = inject(Router);
  if (!route.data) {
    return of(true);
  }

  const featureFlags: string[] = route.data.featureFlags;
  const splitFlags: string[] = route.data.splitFlags;
  const replaceUrl = route.data.replaceUrl;
  const withUrl = route.data.withUrl;

  if (splitFlags?.length || featureFlags?.length) {
    if (!replaceUrl || !withUrl) {
      console.error('`replaceUrl` and `withUrl` are required for feature flag guarding.');
      return of(false);
    }

    if (featureFlags?.length) {
      return featureFlagService.isFeatureEnabled(featureFlags)
        .pipe(map((result: any) => {
          if (!result?.matchesAll) {
            const finalUrl = state.url.replace(replaceUrl, withUrl);
            router.navigateByUrl(finalUrl, {replaceUrl: true});
            return false;
          }

          return true;
        }));
    } else {
      return featureFlagService.isSplitFeatureEnabled(splitFlags)
        .pipe(map((result: any) => {
          if (!result?.matchesAll) {
            const finalUrl = state.url.replace(replaceUrl, withUrl).replace('//', '/');
            router.navigateByUrl(finalUrl, {replaceUrl: true, state: {redirected: true, redirectedTo: finalUrl}});
            return false;
          }

          return true;
        }));
    }
  } else if (replaceUrl && withUrl) {
    const finalUrl = state.url.replace(replaceUrl, withUrl);
    router.navigateByUrl(finalUrl, {replaceUrl: true, state: {redirected: true, redirectedTo: finalUrl}});
    return of(false);
  }
  return of(true);
};
