<p-autoComplete [(ngModel)]="selectedItem" [suggestions]="formattedAccountSuggestions" placeholder="Portfolio ID / Portfolio Name / Tags or Account Number"
                (completeMethod)="loadSuggestions($event)" (onSelect)="onSleeveSelect($event)" field="name" class="autocomplete-search fs-mask" [styleClass]="'fs-mask'"
                [minLength]="1">
  <ng-template let-selectedItem pTemplate="item">
    <ul class="ui-helper-clearfix show-hollding-dropdown">
      <li class="vertical-center">
        <i class="fas fa-cubes entity-icon-portfolio" aria-hidden="true"></i>
        <div style="margin-left: 20px" class="fs-mask" [innerHTML]="selectedItem.displayFormat | textHighlighting: searchText"></div>
      </li>
    </ul>
  </ng-template>
</p-autoComplete>
