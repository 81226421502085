import { inject, Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IDashboard, IDashboardField } from '../models/dashboard';
import { catchError, filter, mergeMap, share, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITomDashBoardSummary } from '../models/trade';
import { IModelDashboard } from '../models/modeling/modeldashboard';
import { IAccountDashboard } from '../models/account';
import { IPortfolioDashboard } from '../models/portfolio';
import { Utils as Util } from '../core/functions';
import * as Consts from '../libs/app.constants';
import { IAnalyticsRunHistory } from '../models/mainDashboard';
import { ViewsService } from './views.service';
import { ISavedViewResult } from "../models/views";

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private _savedViewService = inject(ViewsService);
  private _httpClient = inject(OEHttpClient);

  private readonly acceptImport = 'v1/dataimport/action/accept';
  private readonly acceptImportStatusEndPoint = 'v1/dataimport/acceptImport/status';
  private readonly post_import = 'v1/postimport/post_import_analysis';
  private readonly run_need_analysis = 'v1/postimport/run_need_analysis';
  private readonly donutchartAPI = 'v1/dashboard/main/summary';
  private readonly checkImportProcessEndPoint = 'v1/dataimport/status';
  private readonly reverseSyncHistoryEndPoint = 'v1/reversesynch';
  private readonly analyticsStatusProcessEndPoint = 'v1/dataimport/analysis/status';
  private readonly resetAnalyticsEndPoint = 'v1/postimport/resetanalysis';
  private readonly resetAnalyticsStatusEndPoint = 'v1/postimport/resetanalysisflag';
  private readonly getImportHistoryEndPoint = 'v1/dataimport/importLogHistory';
  private readonly v2DataImportEndpoint = 'v2/DataImport/Action';
  private readonly dashboardEndpoint = 'v2/Dashboard';
  private readonly maxConcurrentSavedViewRequests = 3;
  private readonly savedViewSubject = new Subject<number>();
  private readonly destroy$ = new Subject<void>();
  private readonly _portfolioDashboardEndPoint = 'v1/dashboard/portfolio/summary';
  private readonly _accountDashboardEndPoint = 'v2/Dashboard/AccountDashboard';
  private readonly _modelDashboardEndPoint = 'v1/dashboard/model/summary';
  private readonly _tradeOrderDashboardEndPoint = 'v1/dashboard/tradeorder/summary';
  private readonly _analyticsRunHistory = 'v2/Dashboard/AnalyticsRunHistory';
  private readonly _savedViewsEndpoint = 'v2/SavedView';
  private readonly _refreshPortfolioDashboardData$ = new BehaviorSubject<void>(undefined);
  private readonly _refreshAccountDashboardData$ = new BehaviorSubject<void>(undefined);
  private readonly _refreshModelDashboardData$ = new BehaviorSubject<void>(undefined);
  private readonly _refreshTradeOrderDashboardData$ = new BehaviorSubject<void>(undefined);

  public readonly savedViewRequestQueue: Observable<ISavedViewResult>;

  constructor() {
    this.savedViewRequestQueue = this.savedViewSubject
      .pipe(
        mergeMap((viewId: number) => {
          return this._savedViewService.getRowCountBySavedView(viewId)
            .pipe( catchError(error => {
              return of(<ISavedViewResult>{id: viewId, rowCount: 0, errorMessage: error.error.Message});
            }));
        }, this.maxConcurrentSavedViewRequests),
        takeUntil(this.destroy$),
        share()
      );
    this.savedViewRequestQueue.subscribe();
  }

  public getSavedViewCount(savedViewId: number): Observable<ISavedViewResult> {
    this.savedViewSubject.next(savedViewId);
    return this.savedViewRequestQueue.pipe(filter(res => res.id === savedViewId));
  }

  private _portfolioDashboardData: Observable<IPortfolioDashboard>;

  public get portfolioDashboardData(): Observable<IPortfolioDashboard> {
    if (!this._portfolioDashboardData) {
      this._portfolioDashboardData = this._refreshAccountDashboardData$.pipe(
        switchMap(() => this.getPortfolioDashboardData()),
        shareReplay(1));
    }
    return this._portfolioDashboardData;
  }

  private _accountDashboardData: Observable<IAccountDashboard>;
  public get accountDashboardData(): Observable<IAccountDashboard> {
    if (!this._accountDashboardData) {
      this._accountDashboardData = this._refreshAccountDashboardData$.pipe(
        switchMap(() => this.getAccountDashboardSummary()),
        shareReplay(1));
    }
    return this._accountDashboardData;
  }

  private _modelDashboardData: Observable<IModelDashboard>;

  public get modelDashboardData(): Observable<IModelDashboard> {
    if (!this._modelDashboardData) {
      this._modelDashboardData = this._refreshModelDashboardData$.pipe(
        switchMap(() => this.getModelDashboardSummary()),
        shareReplay(1));
    }
    return this._modelDashboardData;
  }

  private _tradeOrderDashboardData: Observable<ITomDashBoardSummary>;

  public get tradeOrderDashboardData(): Observable<ITomDashBoardSummary> {
    if (!this._tradeOrderDashboardData) {
      this._tradeOrderDashboardData = this._refreshTradeOrderDashboardData$.pipe(
        switchMap(() => this.getTradeOrderDashboardSummary()),
        shareReplay(1));
    }
    return this._tradeOrderDashboardData;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPortfolioDashboardData() {
    return this._httpClient.getData(this._portfolioDashboardEndPoint);
  }

  getAccountDashboardSummary() {
    return this._httpClient.getData(this._accountDashboardEndPoint);
  }

  getModelDashboardSummary() {
    return this._httpClient.getData(this._modelDashboardEndPoint);
  }

  getTradeOrderDashboardSummary() {
    return this._httpClient.getData(this._tradeOrderDashboardEndPoint);
  }

  /**
   * Causes the dashboard observables to refresh with new data
   */
  public refreshDashboardData() {
    this._refreshPortfolioDashboardData$?.next();
    this._refreshAccountDashboardData$?.next();
    this._refreshModelDashboardData$?.next();
    this._refreshTradeOrderDashboardData$?.next();
  }

  /** DASHBOARD SUMMARY*/
  getDashboardSummary(): Observable<any> {
    return this._httpClient.getData(this.donutchartAPI);
  }

  /** Refresh Analytics */
  refreshAnalytics(): Observable<any> {
    return this._httpClient.getData(this.post_import);
  }

  /** Refresh Analytics */
  runNeedAnalytics(): Observable<any> {
    return this._httpClient.getData(this.run_need_analysis);
  }

  /**Check Import Process is Running */
  checkImportProcess(): Observable<any> {
    return this._httpClient.getData(this.checkImportProcessEndPoint);
  }

  /** Get Reverse Sync History Data */
  getReverseSyncHistory() {
    return this._httpClient.getData(this.reverseSyncHistoryEndPoint);
  }

  /** Accept Import Process */
  acceptImportProcess() {
    return this._httpClient.postData(this.acceptImport, {});
  }

  /** Accept Import Status */
  acceptImportStatus() {
    return this._httpClient.getData(this.acceptImportStatusEndPoint);
  }

  /** Get Analytics status */
  getAnalyticsStatus() {
    return this._httpClient.getData(this.analyticsStatusProcessEndPoint);
  }

  getAnalyticsRunHistory(): Observable<IAnalyticsRunHistory[]> {
    return this._httpClient.getData(this._analyticsRunHistory);
  }

  /** Get Reset analytics status */
  resetAnalyticsStatus() {
    return this._httpClient.getData(this.resetAnalyticsStatusEndPoint);
  }

  /** Reset analytics */
  resetAnalytics() {
    return this._httpClient.getData(this.resetAnalyticsEndPoint);
  }

  getImportHistoryData(startDate: string, endDate: string) {
    return this._httpClient.getData(`${this.getImportHistoryEndPoint}?toDate=${endDate}&fromDate=${startDate}`);
  }

  initiateNewImport(requestObj) {
    return this._httpClient.postData(`${this.v2DataImportEndpoint}/StartExtraction`, requestObj);
  }

  continueNewImport(requestObj) {
    return this._httpClient.postData(`${this.v2DataImportEndpoint}/CallConnectSSIS`, requestObj);
  }

  restartImport(correlationId: { correlationId: string }): Observable<any> {
    return this._httpClient.updateData(`${this.v2DataImportEndpoint}/EndExtraction`, correlationId);
  }

  getDashboardFields(): Observable<IDashboardField[]> {
    return this._httpClient.get<IDashboardField[]>(`${this.dashboardEndpoint}/Fields`);
  }

  getDashboardForUser(userId?: number, teamId?: number): Observable<IDashboard[]> {
    const arrParams = [];
    if (userId) {
      arrParams.push(`userId=${userId}`);
    }
    if (teamId) {
      arrParams.push(`teamId=${teamId}`);
    }
    const params = arrParams.length ? `?${arrParams.join('&')}` : '';
    return this._httpClient.get<IDashboard[]>(`${this.dashboardEndpoint}${params}`);
  }

  updateDashboard(dashboard: IDashboard): Observable<IDashboard> {
    return this._httpClient.updateData(this.dashboardEndpoint, dashboard);
  }

  isDashboardFieldVisible(field: IDashboardField): boolean {
    // ASTRO fields require the ASTRO privilege
    if (field?.fieldName.toLowerCase().indexOf('astro') >= 0) {
      const astroPrivilege = Util.getPermission(Consts.PRIV_ASTRO);
      return !!astroPrivilege?.canRead;
    }
    return true;
  }
}
