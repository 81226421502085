import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { ColDef, GridApi, GridOptions, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import { Utils as Util } from '../../../core/functions';
import { PortfolioEditorService } from './portfolio-editor.service';
import { EditorEntityType, EntityEditorService } from '../../../shared/entity-editor';
import * as Consts from '../../../libs/app.constants';
import { IAccount } from '../../../models/account';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { PortfolioEntity } from './types';
import { BaseGridConfiguration } from '../../../shared/gridextensions/basegrid';
import { AccountService } from '../../../services/account.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { PRIV_ACCOUNTS } from '../../../libs/app.constants';

@Component({
  selector: 'eclipse-portfolio-editor-accounts',
  templateUrl: './portfolio-accounts.component.html'
})
export class PortfolioAccountsComponent extends BaseComponent {
  gridContext = {
    self: this
  };
  columnDefs: ColDef[];
  portfolio: PortfolioEntity;
  accounts: IAccount[];
  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public readonly _entityEditorService: EntityEditorService = inject(EntityEditorService);
  public readonly _portfolioService: PortfolioService = inject(PortfolioService);
  public readonly _accountService: AccountService = inject(AccountService);
  private readonly _accountPermissions = Util.getPermission(PRIV_ACCOUNTS);
  public gridOptions: GridOptions;
  private gridApi: GridApi<IAccount>;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor() {
    super();
    this.gridOptions = BaseGridConfiguration.generateGridOptions({
      gridOptions: this.defaultFilterableGridOptions,
      context: this
    });
    this.gridOptions.suppressColumnVirtualisation = true;
    (<SideBarDef>this.gridOptions.sideBar).defaultToolPanel = ''; // the Filters panel shouldn't be expanded by default
    this.portfolioEditorService.portfolio$
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this.createColumnDefs();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onRowDoubleClicked(event) {
    this._entityEditorService.show({entityType: EditorEntityType.Account, data: {id: event.data.id, editMode: false}});
  }

  public onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  public getContextMenuItems(params) {
    const self = params.context.self;
    const contextResult = [];
    const accountPrev = Util.getPermission(Consts.PRIV_ACCOUNTS);
    if (accountPrev.canRead) {
      contextResult.push({
        name: 'View Details',
        icon: '<i class="fas fa-fw fa-briefcase" aria-hidden="true"></i>',
        action: () => {
          self._entityEditorService.show({
            entityType: EditorEntityType.Account,
            data: {id: params.node.data.id, editMode: false}
          });
        }
      });
    }
    if (accountPrev.canUpdate) {
      contextResult.push({
        name: 'Edit Account Details',
        icon: '<i class="fas fa-fw fa-briefcase" aria-hidden="true"></i>',
        action: () => {
          self._entityEditorService.show({
            entityType: EditorEntityType.Account,
            data: {id: params.node.data.id, editMode: true}
          });
        }
      });
    }

    contextResult.push({
      name: 'View Account in OC',
      icon: '<i class="fas fa-fw fa-briefcase" aria-hidden="true"></i>',
      action: () => {
        self._accountService.goToAccountDetailsInOC(params.node.data.id);
      },
    });

    return contextResult;
  }

  private createColumnDefs() {
    this.columnDefs = [
      <ColDef>{
        headerName: 'Account ID',
        field: 'accountId',
        width: 102,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Name',
        field: 'name',
        width: 160,
        cellClass: 'text-center fs-mask',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Account Number',
        field: 'accountNumber',
        width: 194,
        cellClass: 'text-center fs-mask',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Account Type',
        field: 'accountType',
        width: 140,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Managed Value',
        field: 'managedValue',
        width: 140,
        cellClass: 'text-center',
        cellRenderer: this.formatCurrencyCellRenderer,
        filter: 'agNumberColumnFilter',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Excluded Value',
        field: 'excludedValue',
        width: 140,
        cellClass: 'text-center',
        cellRenderer: this.formatCurrencyCellRenderer,
        filter: 'agNumberColumnFilter',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Total Value',
        field: 'totalValue',
        width: 140,
        cellClass: 'text-center',
        cellRenderer: this.formatCurrencyCellRenderer,
        filter: 'agNumberColumnFilter',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Pending Value',
        field: 'pendingValue',
        width: 140,
        cellClass: 'text-center',
        cellRenderer: this.formatCurrencyCellRenderer,
        filter: 'agNumberColumnFilter',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Status',
        field: 'status',
        width: 75,
        cellClass: 'text-center',
        filter: false,
        cellRenderer: this.statusRenderer
      },
      <ColDef>{
        headerName: 'Do Not Trade',
        field: 'doNotTrade',
        width: 135,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
      }
    ];

    if (this.portfolioEditorService.portfolio.isSleevePortfolio) {
      this.columnDefs.push(<ColDef>{
        headerName: 'Sleeve Type',
        field: 'sleeveType',
        width: 125,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Sleeve Target',
        field: 'sleeveTarget',
        width: 117,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Sleeve Contribution %',
        field: 'sleeveContributionPercent',
        width: 165,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Sleeve Distribution %',
        field: 'sleeveDistributionPercent',
        width: 160,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Sleeve Tolerance Lower',
        field: 'sleeveToleranceLower',
        width: 174,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Sleeve Tolerance Upper',
        field: 'sleeveToleranceUpper',
        width: 174,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Model',
        field: 'model',
        width: 162,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Management Style',
        field: 'managementStyle',
        width: 145,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      });
      this.columnDefs.push(<ColDef>{
        headerName: 'Excluded Rebalance Sleeve',
        field: 'excludeRebalanceSleeve',
        width: 195,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        enableValue: false
      });
    } else if (this._accountPermissions?.canDelete) {
      this.columnDefs.push(<ColDef>{
        headerName: 'Delete',
        // pinned: 'right',
        field: '',
        width: 80,
        cellClass: 'text-center',
        cellRenderer: this.deleteRenderer,
        filter: false
      });
    }
  }

  private statusRenderer(params) {
    let result;
    if (params.value === 'Ok') {
      result = `<i class="fas fa-check-circle text-success" title="${params.value}" aria-hidden="true"></i>`;
    } else if (params.value === 'Warning') {
      result = `<i class="fas fa-exclamation-triangle text-erorr-warning" title="${params.value}" aria-hidden="true"></i>`;
    } else {
      result = `<i class="fas fa-times-circle text-danger" title="${params.value}" aria-hidden="true"></i>`;
    }
    return `<span class="status-icon">${result}</span>`;
  }

  private deleteRenderer(params) {
    const result = `<span><i id="${params.data.id}" title="Delete" class="fas fa-trash text-danger" aria-hidden="true"></i></span>`;
    return result;
  }
}
