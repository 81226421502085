import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INote, IRelatedEntity } from '../../models/notes';
import { BaseComponent } from '../../core/base.component';
import { EntityType } from '../../libs/preference.enums';
import { tap } from 'rxjs/operators';
import { NotesService } from '../../services/notes.service';
import { orderBy, uniqBy } from 'lodash';
import { SessionHelper } from '../../core';

@Component({
  selector: 'eclipse-note-editor',
  templateUrl: './note-editor.component.html',
})
export class NoteEditorComponent extends BaseComponent {
  @Output() onNoteChanged: EventEmitter<INote[]> = new EventEmitter<INote[]>(); //Emits when a note has been added or updated.
  @Input() entityId: number; // Id of the current entity
  @Input() entityType: EntityType; // EntityType of the current entity
  private _relatedEntities: IRelatedEntity[]; // Entities related to the current entity
  @Input()
  public get relatedEntities(): IRelatedEntity[] {
    return this._relatedEntities;
  }
  public set relatedEntities(value: IRelatedEntity[]) {
    this._relatedEntities = value;
    this.groupEntities();
  }

  private _selectedEntity: IRelatedEntity; // The selected level (entity) the note is assigned to.
  public get selectedEntity(): IRelatedEntity {
    return this._selectedEntity;
  }
  public set selectedEntity(value: IRelatedEntity) {
    this._selectedEntity = value;
    this.note.relatedId = value?.id;
    this.note.relatedType = value?.entityType;
    this.note.entityName = value?.name;
    this.note.canEdit = value && this._notesService.canUserEditNote(this.note, this._sessionHelper.getUser(), this.entityType);
  }

  isSaving: boolean = false; // Flag indicating the note being saved
  submitted = false; // Flag indicating the user has tried to save this note.  Used to display validation.
  groupedEntityTypes = []; // Array of entity types grouped together
  note: INote = <INote>{}; // The note being edited
  entityTypes = EntityType;

  constructor(private readonly _notesService: NotesService, public readonly _sessionHelper: SessionHelper) {
    super('', _sessionHelper);
  }

  /**
   * Groups entities together by type.
   * Example:  [Team1, Portfolio1, Account1, Account2, Portfolio2, Team2] =>
   *  [
   *    Teams: [Team1, Team2],
   *    Portfolios: [Portfolio1, Portfolio2],
   *    Accounts: [Account1, Account2]
   *  ]
   */
  groupEntities(): void {
    // Get the list of related entities.
    let entities = [];

    if (this.entityType === EntityType.Team) {
      entities = this._relatedEntities?.filter(e => {
        return this._notesService.canUserEditEntity(e.entityType) // user must have the edit privilege for each entity type
          && e.entityType === EntityType.Team;
      });
    } else {
      entities = this._relatedEntities?.filter(e => {
        return this._notesService.canUserEditEntity(e.entityType) // user must have the edit privilege for each entity type
          && e.entityType !== EntityType.Team;
      });
    }

    // Get the unique list of entity types
    let uniqueEntityTypes = uniqBy(entities?.map(e => ({
      entityType: e.entityType,
      entityTypeName: e.entityTypeName,
    })), 'entityType');
    // Order the unique types (Team, Portfolio, Account)
    uniqueEntityTypes = orderBy(uniqueEntityTypes, 'entityType');
    this.groupedEntityTypes = [];
    // Add each of the unique entity types as a group, then fill its `items` array with related entities of that type
    uniqueEntityTypes.forEach(entityType => {
      const et = {
        label: entityType.entityTypeName,
        value: entityType.entityType,
        items: [],
      };
      et.items = (this.relatedEntities.filter(e => e.entityType === et.value));
      this.groupedEntityTypes.push(et);
    });
  }

  /**
   * Creates a new note object for editing.
   * The current entity is selected as the default level.
   */
  addNewNote(displayNoteDefault: boolean) {
    this.submitted = false;
    this.note = <INote>{
      relatedType: this.entityType,
      relatedId: +this.entityId,
      startDate: new Date(),
      displayNote: displayNoteDefault
    };
    this.note.canEdit = this._notesService.canUserEditNote(this.note, this._sessionHelper.getUser(), this.entityType);
    // Find the current entity in the related entities list and select it.
    this.selectedEntity = this.relatedEntities?.find(s => s.id === +this.entityId && s.entityType === this.entityType);
  }

  /**
   * Loads a note for editing
   * @param editingNote
   */
  editNote(editingNote: INote): void {
    this.submitted = false;
    this.note = {...editingNote};
    this.selectedEntity = this.relatedEntities?.find(entity => editingNote.relatedId === entity.id && editingNote.relatedType === entity.entityType);
  }

  /**
   * Returns true if the note has all required information.
   */
  isNoteValid(): boolean {
    return !!this.note
      && !!this.note.notes?.length
      && !!this.note.relatedId
      && !!this.note.startDate
      && (!this.note.endDate || this.note.endDate > this.note.startDate);
  }

  /**
   * Saves changes made to the note.
   * Emits an onNoteChanged event after successfully saving.
   */
  saveNote() {
    this.submitted = true;

    if (!this.isNoteValid()) {
      return false;
    }

    // Keep a copy of the selected level's name for later use
    const entityName = this.selectedEntity?.name;
    this.note.displayNote = this.entityType === EntityType.Team ? true : this.note.displayNote;
    this.note.startDate = <any>this.note.startDate.toLocaleDateString();
    if (this.note.endDate) {
      this.note.endDate = <any>this.note.endDate.toLocaleDateString();
    }
    const action = this.note.id > 0 ? this._notesService.updateNotes([this.note]) : this._notesService.createNotes([this.note]);
    this.isSaving = true;
    action
      .pipe(tap(notes => {
        notes.forEach(n => {
          // Entity name (ex. "Default Team") is not handled by CRUD methods, so we have to set it ourselves based on what
          // is currently selected in the dropdown.
          n.entityName = entityName;
          // Process the note to set the entity levels, format dates, etc.
          this._notesService.processNote(n, this._sessionHelper.getUser(), this.entityId, this.entityType);
        });
      }))
      .subscribe({
        next: notes => {
          this.onNoteChanged.next(notes);
        },
        complete: () => {
          this.submitted = false;
          this.isSaving = false;
        },
      });
  }
}
