import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { AccountService } from '../../services/account.service';
import { PortfolioService } from '../../services/portfolio.service';
import {
  IAccountSetAsidePercentCalculationType,
  IAsideCash,
  IAsideCashAmountType,
  IAsideCashExpirationType,
  IAsideCashAccountType,
  IAsideCashTransactionType,
  IDayOfWeekSummary,
  ISetAsideDCAFrequency,
  SetAsideCashDCAPeriodType,
  SetAsideCreationType,
  SetAsideDCAIntervalType,
  IAsideCashAmountSchedule
} from '../../models/account';
import * as Consts from '../../libs/app.constants';
import { ColDef, CsvExportParams, GridApi, GridOptions, GridReadyEvent, ITooltipParams } from '@ag-grid-community/core';
import { tap } from 'rxjs/operators';
import { Utils as Util } from '../../core/functions';
import moment from 'moment';
import * as _ from 'lodash';
import { forkJoin, Observable, zip } from 'rxjs';
import { SetAsideCashService } from './set-aside-cash.service';
import {AlertService, SessionHelper} from '../../core';
import { IKeyboardEventWithHTMLInputElement } from '../../models/event';

@Component({
  selector: 'eclipse-set-aside-cash',
  templateUrl: './set-aside-cash.component.html',
  styleUrls: ['./set-aside-cash.component.scss'],
  providers: [SetAsideCashService]
})
export class SetAsideCashComponent extends BaseComponent {
  public readonly className: string = 'SetAsideCashComponent';

  @Output() afterSave = new EventEmitter<IAsideCash | IAsideCash[]>();
  private _accountId: number;
  private _portfolioId: number;
  private _sleeveType: string;
  private _isRaiseCash: boolean;

  @Input()
  public get accountId(): number {
    return this._accountId;
  }

  public set accountId(value: number) {
    this._accountId = value;
  }

  @Input()
  public get portfolioId(): number {
    return this._portfolioId;
  }


  public set portfolioId(value: number) {
    this._portfolioId = value;
  }

 @Input()
  public get sleeveType(): string {
    return this._sleeveType;
  }

  public set sleeveType(value: string) {
    this._sleeveType = value;
  }

  @Input()
  public get isRaiseCash(): boolean {
    return this._isRaiseCash;
  }

  public set isRaiseCash(value: boolean) {
    this._isRaiseCash = value;
  }


  @Input() externalSavePrompt: boolean = false;
  @Input() allowMultipleSaves: boolean = false;
  @Input() isTacticalTradeTool: boolean = false;
  @Input() showHeader: boolean = true;

  public asideCashData: IAsideCash[] = [];
  displayAsideCashEditor: boolean;
  asideCashList: IAsideCash[] = [];
  asideCashGridOptions: GridOptions;
  private gridApi: GridApi;
  setAsideCashError: string = null;
  asideCash: IAsideCash = <IAsideCash>{};
  billingSetAsideCashToEdit:  IAsideCash = <IAsideCash>{};
  expirationType: boolean;
  toleranceBand: boolean;
  datepicker: boolean = true;
  setAsidePercentCalculationType: IAccountSetAsidePercentCalculationType[];
  cashExpirationForContribution: IAsideCashExpirationType[] = [];
  cashExpirationForDistribution: IAsideCashExpirationType[] = [];
  columnDefs: ColDef[];
  type: string = '$';
  savingSetAside = false;
  cashTransaction: IAsideCashTransactionType[] = [];
  cashExpiration: IAsideCashExpirationType[] = [];
  cashType: IAsideCashAmountType[] = [];
  setAsideAccountType: IAsideCashAccountType[] = [];
  setAsideAccountTypeForDCA: IAsideCashAccountType[] = [];
  setAsideAccountTypeIdForDCA: number = 1;
  selectedAside: IAsideCash[];
  displayDeleteConfirm: boolean;
  displayEditBillAsideCashConfirm: boolean = false;
  asideCashAmountTypeDollar: number;
  setAsideCreationTypeConst: typeof SetAsideCreationType;
  resetSetAsideCashValues: boolean;
  private _tempId: number = -1; // tracker used to get a unique id for new records
  public startDateDCA: Date = new Date();
  public everyValue: number = 1;
  public trancheCount: number = 0;
  public trancheCumulative: number = 0;
  public trancheInstallment: number = 0;
  public dayOfWeekSummary: IDayOfWeekSummary;
  public onDynamicValue: number = 1;
  public monthExactDynamicValue: number = 1;
  public get dayOfWeekName(): string {
    return this._setAsideService.getDayOfWeekName(this.startDateDCA);
  }

  private getTempId(): number {
    return --this._tempId;
  }

  public setAsideCreationType = SetAsideCreationType.Individual;
  public dayOfWeekList = SetAsideCashService.WeekDays;
  public periodTypesList = SetAsideCashService.PeriodTypes;
  public periodType: SetAsideCashDCAPeriodType = SetAsideCashDCAPeriodType.Daily;
  public dayOfWeek = new Date().getDay();
  setAsideGridType = 'Active';
  minDate = new Date();
  hasMultipleAccount: boolean;
  disableMinMaxFieldsWarning: string;
  showMinMaxCashAmount: boolean;
  gridContext = {
    self: this
  };

  constructor(private _accountService: AccountService,
              private _portfolioService: PortfolioService,
              private _setAsideService: SetAsideCashService,
              private _alertService: AlertService,
              private sessionHelper: SessionHelper) {
    super(Consts.PRIV_ACCOUNTS);
    this.asideCashGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
      headerHeight: 48,
      tooltipShowDelay: 0,
    };
    this.asideCashGridOptions.defaultColDef.tooltipValueGetter = this.setAsideTooltip;
    this.asideCash.expirationTypeId = 1;
    this.asideCash.setAsideAccountTypeId = 2;
    this.asideCash.cashAmountTypeId = 1;
    this.asideCashAmountTypeDollar = Consts.AsideCashAmountType.Dollar;
    this.setAsideCreationTypeConst = SetAsideCreationType;
    this.showMinMaxCashAmount = true;
    this.createColumnDefs();
    this.resetSetAsideCashValues = true;
  }

  ngOnInit() {
    this.initializeData();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  initializeData() {
    forkJoin([
        this.getCashExpirationType(),
        this.getAsideCashAccountType(),
        this.getCashTransactionType(),
        this.getCashAmountType()
      ]
    ).subscribe(() => {
      if (this.accountId > 0) {
        this.getAccountSetAsideData();
      }
      if (this.portfolioId > 0) {
        this.onPortfolioAsideCashLoad();
      }
      this.asideCash.percentCalculationTypeId = 0;
      this.refreshDCADateValues();
    });
  }

  public onEditBillingSetAsideCashClose(): void {
    this.displayEditBillAsideCashConfirm = false;
  }

  public onEditBillingSetAsideCash(): void {
    this.displayEditBillAsideCashConfirm = false;
    this.getAsideDetailsById(this.billingSetAsideCashToEdit.id);
  }

  getAccountSetAsideData() {
    this.onAccountAsideCashLoad();
  }

  /** Dropdown Change for Expiration Type */
  onExpirationChange(params) {
    this.asideCash.expirationTypeId = +params.value;
    if (this.asideCash.expirationTypeId === 1 || this.asideCash.expirationTypeId === 3) {
      this.expirationType = false;
      this.toleranceBand = false;
      this.datepicker = true;
      this.asideCash.expirationValue = '';
      this.asideCash.toleranceValue = undefined;
    } else {
      this.asideCash.expirationValue = '1';
      this.expirationType = true;
      this.toleranceBand = true;
      this.datepicker = false;
      this.asideCash.toleranceValue = undefined;
    }
  }

  /** Dropdown Change for Account Type */
  onAccountTypeChange(params) {
    this.datepicker = true;
    this.asideCash.expirationValue = '';
    this.asideCash.expirationTypeId = 1;
    this.asideCash.minCashAmount = null;
    this.asideCash.maxCashAmount = null;
    this.asideCash.minMultiplier = null;
    this.asideCash.maxMultiplier = null;
    this.asideCash.cashAmount = null;
    this.asideCash.percentCalculationTypeId = 0;
    this.asideCash.setAsideAccountTypeId = +params.value;
    if (this.asideCash.setAsideAccountTypeId === 2){
      this.cashExpiration = this.cashExpirationForDistribution;
    } else {
      this.toleranceBand = false;
      this.cashExpiration = this.cashExpirationForContribution;
    }
  }

  onAccountTypeChangeForDCA(params) {
    this.asideCash.setAsideAccountTypeId = +params.value;
  }

  /** Dropdown Change for Expiration Transaction */
  onTransactionExpirationChange(params) {
    this.asideCash.expirationValue = params.target.value;
  }

  /** Clear the popup data */
  resetAsideCash() {
    if (this.resetSetAsideCashValues) {
      this.asideCash.id = undefined;
      this.asideCash.expirationValue = null;
      this.datepicker = true;
      this.displayAsideCashEditor = false;
      this.asideCash.cashAmountTypeId = 1;
      this.asideCash.expirationTypeId = 1;
      this.asideCash.setAsideAccountTypeId = 2;
      this.asideCash.minCashAmount = null;
      this.asideCash.maxCashAmount = null;
      this.asideCash.cashAmount = null;
      this.asideCash.toleranceValue = null;
      this.toleranceBand = false;
      this.asideCash.description = '';
      this.asideCash.percentCalculationTypeId = 0;
      this.asideCash.modified = false;
      this.savingSetAside = false;
      this.setAsideCashError = null;
      this.startDateDCA = new Date();
      this.everyValue = 1;
      this.trancheCount = 0;
      this.trancheCumulative = 0;
      this.trancheInstallment = 0;
      this.dayOfWeekSummary = null;
      this.onDynamicValue = 1;
      this.monthExactDynamicValue = 1;
      this.setAsideCreationType = SetAsideCreationType.Individual;
      this.periodType = SetAsideCashDCAPeriodType.Daily;
      this.dayOfWeek = new Date().getDay();
      this.asideCash.startDate = moment().toDate();
      this.savingSetAside = false;
      this.showMinMaxCashAmount = true;
    }
  }

  /** Aside Cash popup */
  onCreateAsideCash() {
    this.type = '$';
    this.asideCash.id = null;
    this.setAsideCashError = null;
    this.displayAsideCashEditor = true;
    this.asideCash.expirationTypeId = 1;
    this.asideCash.setAsideAccountTypeId = 2;
    this.toleranceBand = false;
    this.datepicker = true;
    this.asideCash.cashAmount = null;
    this.asideCash.expirationValue = '';
    this.asideCash.toleranceValue = null;
    this.asideCash.description = '';
    this.asideCash.minCashAmount = null;
    this.asideCash.minMultiplier = null;
    this.asideCash.maxCashAmount = null;
    this.asideCash.maxMultiplier = null;
    this.asideCash.tempId = this.getTempId();
    this.cashExpiration = this.cashExpirationForDistribution;
    this.asideCash.startDate = moment().toDate();
    if (!this.setAsidePercentCalculationType || (this.setAsidePercentCalculationType && !this.setAsidePercentCalculationType.length)) {
      this.getAccountSetAsidePercentCalculationType();
    }
    this.asideCash.cashAmountTypeId = Consts.AsideCashAmountType.Dollar;
    this.showMinMaxCashAmount = true;
  }

  /** Create column headers for agGrid */
  createColumnDefs() {
    this.columnDefs = [
      <ColDef>{
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        width: 92,
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        colId: 'cashAmountTypeName',
        headerName: 'Amount Type',
        field: 'cashAmountTypeName',
        cellRenderer: this.cashTypeRender,
        width: 108,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'cashAmount',
        headerName: 'Amount',
        field: 'cashAmount',
        width: 100,
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        colId: 'minCashAmount',
        headerName: 'Min Amount',
        field: 'minCashAmount',
        width: 110,
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        colId: 'maxCashAmount',
        headerName: 'Max Amount',
        field: 'maxCashAmount',
        width: 108,
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        colId: 'frequency',
        headerName: 'Frequency',
        field: 'frequency',
        width: 113,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        colId: 'description',
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        width: 221,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'expirationTypeName',
        headerName: 'Expiration Type',
        field: 'expirationTypeName',
        width: 122,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'startDate',
        headerName: 'Start Date',
        field: 'startDate',
        cellRenderer: this.StartDateCellRenderer,
        width: 119,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'expirationValue',
        headerName: 'Expiration Date',
        field: 'expirationValue',
        cellRenderer: this.ExpirationDateCellRenderer,
        width: 119,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'expirationTransactionType',
        headerName: 'Expiration Transaction Type',
        field: 'expirationValue',
        cellRenderer: this.expirationTransactionTypeRenderer,
        width: 187,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'toleranceValue',
        headerName: 'Tolerance Band',
        field: 'toleranceValue',
        width: 106,
        cellRenderer: this.toleranceValueTypeRenderer,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'expiredOn',
        headerName: 'Expired On Date',
        field: 'expiredOn',
        cellRenderer: this.formatUTCDateCellRenderer,
        width: 109,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'percentCalculationType',
        headerName: 'Percent Calculation Type',
        field: 'percentCalculationType',
        width: 218,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'setAsideAccount',
        headerName: 'Set Aside Account',
        field: 'setAsideAccountTypeName',
        width: 140,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        colId: 'expiredBy',
        headerName: 'Manually Expired By',
        field: 'expiredByUser',
        width: 180,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        hide: true
      },
    ];
  }

  /**Cell Render For Description in Set Aside Cash */
  descriptionCellRender(params) {
    return `<span title="${params.value}">${params.value}</span>`;
  }

  /** To render Tolerance value on cell */
  toleranceValueTypeRenderer(params) {
    return params.node.data.expirationTypeId === 2 ? params.node.data.toleranceValue : '-';
  }

  /** Cell Rendor for Expiration Date*/
  ExpirationDateCellRenderer(params) {
    if (params.node.data.expirationTypeId === 1) {
      return Util.getDateFromUTC(params);
    } else {
      return '-';
    }
  }

  /** Cell Rendor for Expiration Date*/
  StartDateCellRenderer(params) {
    if (params.value) {
      return Util.getDateFromUTC(params);
    } else {
      return '-';
    }
  }

  /** Render Cash Type */
  cashTypeRender(params) {
    return params.data.cashAmountTypeId === 1 ? '$' : '%';
  }

  /** Cell Rendor for Expiration Transaction Type*/
  expirationTransactionTypeRenderer(params) {
    const self = <SetAsideCashComponent>params.context.self;
    if (params.node.data.expirationTypeId === 2) {
      const val = self.cashTransaction.find(c => c.id === +params.node.data.expirationValue || c.name === params.node.data.expirationValue);
      if (val) {
        return val.name;
      }
      return '?';
    }
    return '-';
  }

  /** Get set aside cash list for account */
  onAccountAsideCashLoad() {
    if(this.accountId) {
      this._accountService.getAccountAsideCash(this.accountId)
      .subscribe((model: IAsideCash[]) => {
        this.asideCashList = _.sortBy(model, ['expirationValue']);
        this.asideCashData = this.asideCashList.filter(a => a.isExpired === false && !a.delete && a.isActive);
      });
    }
  }

  /** Get set aside cash list for Portfolio */
  onPortfolioAsideCashLoad() {
    if(this.portfolioId) {
      this._portfolioService.getPortfolioAsideCash(this.portfolioId)
      .subscribe((model: IAsideCash[]) => {
        this.asideCashList = _.sortBy(model, ['expirationValue']);
        this.asideCashData = this.asideCashList.filter(a => a.isExpired === false && !a.delete && a.isActive);
      });
    }
  }

  /** set Aside Cash Dropdown Change  */
  asideCashAmount(target: HTMLSelectElement): void {
    this.asideCash.cashAmount = null;
    if (Number(target.value) === Consts.AsideCashAmountType.Percentage) {
      this.type = '%';
      if (this.asideCash.cashAmount > 100) {
        this.asideCash.cashAmount = 100;
      }
    } else {
      this.type = '$';
    }
    this.asideCash.cashAmountTypeId = Number(this.asideCash.cashAmountTypeId);
    this.asideCash.minCashAmount = null;
    this.asideCash.minMultiplier = null;
    this.asideCash.maxCashAmount = null;
    this.asideCash.maxMultiplier = null;
  }

  validateNegativePercent(event) {
    if (this.asideCash.cashAmount < -100) {
      this.asideCash.cashAmount = -100;
    }
    if (this.asideCash.toleranceValue < -100) {
      this.asideCash.toleranceValue = -100;
    }
    if (this.asideCash.minCashAmount < -100) {
      this.asideCash.minCashAmount = -100;
    }
    if (this.asideCash.maxCashAmount < -100) {
      this.asideCash.maxCashAmount = -100;
    }
  }

  /** validating Set Aside Cash value */
  validatePercent(event: IKeyboardEventWithHTMLInputElement): boolean {
    this.validateInputFields(event.target);
    if (this.type === '%') {
      if (this.asideCash.cashAmount > 100) {
        this.asideCash.cashAmount = 100;
      }
      if (this.asideCash.toleranceValue > 100) {
        this.asideCash.toleranceValue = 100;
      }
      if (this.asideCash.minCashAmount > 100) {
        this.asideCash.minCashAmount = 100;
      }
      if (this.asideCash.maxCashAmount > 100) {
        this.asideCash.maxCashAmount = 100;
      }
      this.validateNegativePercent(event);
    } else {
      if (this.asideCash.toleranceValue > 100) {
        this.asideCash.toleranceValue = 100;
      }
    }

    return event.key !== undefined && this.checkSpecialCharacter(event);
  }

  public refreshDCADateValues(): void {
    this.asideCash.setAsideAccountTypeId = Consts.AsideCashAccountType.Contribution;
    this.setAsideAccountTypeIdForDCA = Consts.AsideCashAccountType.Contribution;
    this.dayOfWeekSummary = this._setAsideService.getDayOfWeekSummary(this.startDateDCA);
    if (this.dayOfWeekSummary.rank >= 4) {
      this.onDynamicValue = this.dayOfWeekSummary.rank === this.dayOfWeekSummary.total ? 5 : 4;
    }
  }

  public get scheduleDescription(): string {
    const result = this.createScheduledSetAsideCash();
    const schedules = result.map(item => {
      return `${Util.formatCurrency(item.cashAmount, 2)} expiring on ${this.formatDateWithFormat(item.expirationValue, 'MM/dd/yyyy')}`;
    }).join('\r\n');
    return `The following will be created:\r\n${schedules.length ? schedules : 'None'}`;
  }

  public createScheduledSetAsideCash(): IAsideCash[] {
    if (!this.dayOfWeekSummary) {
      return [];
    }
    const frequency: ISetAsideDCAFrequency = {
      period: this.periodType,
      start: this.startDateDCA,
      every: this.everyValue,
      on: {day: this.everyValue, interval: SetAsideDCAIntervalType.Exact}
    };
    switch (this.periodType) {
      case SetAsideCashDCAPeriodType.Weekly:
        frequency.on = {day: this.dayOfWeek, interval: SetAsideDCAIntervalType.Exact};
        break;
      case SetAsideCashDCAPeriodType.Monthly:
      case SetAsideCashDCAPeriodType.Annual:
        if (this.monthExactDynamicValue === 1) {
          frequency.on.day = this.dayOfWeekSummary.day; // for Exact Date, use the day of the month (11, 21, etc)
        } else {
          frequency.on.day = this.dayOfWeekSummary.dayOfWeek; // for Dyanmic Date, use the day of the week (1=Monday, 2=Tuesday, etc)
          if (this.dayOfWeekSummary.rank >= 4) { // if on the last instance of a day in a month, use the Fourth or Last selected by the user
            frequency.on.interval = this.onDynamicValue;
          } else {
            frequency.on.interval = this.dayOfWeekSummary.rank; // if on first, second, or third instance of a day in a month, use the rank (1, 2, or 3)
          }
        }
        break;
    }
    const schedules = this._setAsideService.createSetAsideCashSchedule(this.trancheCount, frequency);
    const schedulesWithAmounts = this._setAsideService.applyInstallmentsToSchedule(schedules, this.trancheInstallment, this.trancheCumulative);

    return this.getScheduleSetAsideCash(schedulesWithAmounts);
  }

  public calculateIndividualTrancheAmount(): void {
    this.trancheInstallment = this._setAsideService.getInstallmentAmount(this.trancheCumulative, this.trancheCount);
    this.validateDCAInputField();
  }

  public calculateCumulativeTrancheAmount(): void {
    this.trancheCumulative = this._setAsideService.getCumulativeAmount(this.trancheInstallment, this.trancheCount);
    this.validateDCAInputField();
  }

  public calculateTrancheCount(): void {
    const periods = this._setAsideService.getPeriods(this.trancheCumulative, this.trancheInstallment);
    this.trancheCount = !periods ? null : periods;
    this.validateDCAInputField();
  }

  getSetAsideCash(data: IAsideCash): IAsideCash {
    data.cashAmountTypeId = +data.cashAmountTypeId;
    data.expirationTypeId = +data.expirationTypeId;
    data.percentCalculationTypeId = +data.percentCalculationTypeId;
    if (data.cashAmountTypeId === 1) {
      data.percentCalculationTypeId = 0;
    }
    data.expirationTypeName = this.cashExpiration.find(c => c.id === +data.expirationTypeId).name;
    const setAsideAccountTypeName = data.setAsideAccountTypeId ? this.setAsideAccountType.find(c => c.id === data.setAsideAccountTypeId).name : null;
    data.setAsideAccountTypeName = this.sleeveType ? this.sleeveType : setAsideAccountTypeName;
    if (this.setAsidePercentCalculationType) {
      const percentType = this.setAsidePercentCalculationType.find(c => c.id === +data.percentCalculationTypeId);
      data.percentCalculationType = percentType ? percentType.name : null;
    } else {
      data.percentCalculationType = null;
    }

    const objAsideCash: IAsideCash = Util.deepClone(data);
    if (data.expirationTypeId === 1) { // Convert to UTC when Expiration Type is Date
      objAsideCash.expirationValue = Util.formatDate(data.expirationValue, 'yyyy-MM-dd');
    }
    objAsideCash.startDate =Util.formatDate(data.startDate, 'yyyy-MM-dd');
    return objAsideCash;
  }

  validateNegativeAsideCash(asideCash: IAsideCash): boolean {
    let isValid = true;
    if (asideCash.cashAmount && asideCash.cashAmount < 0) {
      const warningMessage = 'If Set Aside is negative, Minimum Set Aside should be negative and Maximum Set Aside should either be negative or 0.';

      const isMinCashAmountPositive = asideCash.minCashAmount && asideCash.minCashAmount > 0;
      const isMaxCashAmountPositive = asideCash.maxCashAmount && asideCash.maxCashAmount > 0;

      if (isMinCashAmountPositive || isMaxCashAmountPositive) {
        this.setAsideCashError = warningMessage;
        isValid = false;
      }
    }
    return isValid;
  }

  validatePositiveAsideCash(asideCash: IAsideCash): boolean {
    let isValid = true;
    if (asideCash.cashAmount && asideCash.cashAmount > 0) {
      const warningMessage = 'If Set Aside is positive, Minimum Set Aside should either be positive or 0 and Maximum Set Aside should be positive.';

      const isMinCashAmountNegative = asideCash.minCashAmount && asideCash.minCashAmount < 0;
      const isMaxCashAmountNegative = asideCash.maxCashAmount && asideCash.maxCashAmount < 0;

      if (isMinCashAmountNegative || isMaxCashAmountNegative) {
        this.setAsideCashError = warningMessage;
        isValid = false;
      }
    }
    return isValid;
  }

  validateZeroAsideCash(asideCash: IAsideCash): boolean {
    let isValid = true;
    if (asideCash.cashAmount === 0 && asideCash.minCashAmount && asideCash.maxCashAmount) {
      const hasDifference = (asideCash.minCashAmount > 0 && asideCash.maxCashAmount < 0) || (asideCash.minCashAmount < 0 && asideCash.maxCashAmount > 0);
      if (hasDifference) {
        this.setAsideCashError = 'Minimum and Maximum Set Aside Cash Amount should be either both Negative or both Positive.';
        isValid = false;
      }
    }
    return isValid;
  }

  isSetAsideCashValid(asideCash: IAsideCash): boolean {
    let isValid = true;
    if (asideCash.minCashAmount !== null && asideCash.minCashAmount > asideCash.cashAmount) {
      this.setAsideCashError = 'Minimum Set Aside Cash Amount should be less than Set Aside Cash Amount';
      isValid = false;
    } else if (asideCash.maxCashAmount !== null && asideCash.maxCashAmount < asideCash.cashAmount) {
      this.setAsideCashError = 'Maximum Set Aside Cash Amount should be more than Set Aside Cash Amount';
      isValid = false;
    }
    return isValid;
  }

  isDCAValid(): boolean {
    const totalCalculated = Util.roundDecimal(this.trancheInstallment * this.trancheCount, 1);
    const totalCumulative = Util.roundDecimal(this.trancheCumulative, 1);
    // Check that the user total and calculated total are within 1 decimal place of each other
    if (totalCumulative === totalCalculated) {
      return true;
    }
    this.setAsideCashError = 'Tranche amount x number of tranches does not equal cumulative DCA amount.';
    return false;
  }

  /**
   * Done editing a record when the component supports batch saving.  Mark the record as modified and push it back into the list.
   */
  onDoneEditing() {
    let itemsToSave = [];

    if (this.setAsideCreationType === SetAsideCreationType.DollarCostAverage) {
      if (!this.isDCAValid()) {
        return;
      }
      itemsToSave.push(...this.createScheduledSetAsideCash());
    } else {
      itemsToSave = [this.asideCash];
    }

    itemsToSave.forEach(sacItem => {
      const isInValid = this.validation(sacItem);
      if (isInValid) {
        return;
      }

      // mark this record as modified
      sacItem.modified = true;
      const objAsideCash = this.getSetAsideCash(sacItem);
      // Try to find the record in the list we already have.  Use id if greater than 0 (existing record), or tempId if less than 0 (new record)
      const existingObj = this.asideCashData.find(ac => (objAsideCash.id > 0 && objAsideCash.id === ac.id) || (objAsideCash.tempId < 0 && objAsideCash.tempId === ac.tempId));
      if (existingObj) {
        this.asideCashData[this.asideCashData.indexOf(existingObj)] = objAsideCash;
      } else {
        this.asideCashData.push(objAsideCash);
      }
    });

    this.gridApi.setGridOption('rowData', this.asideCashData.filter(a => !a.delete));
    this.displayAsideCashEditor = false;
  }

  /** Save Aside Cash popup data  */
  onAsideCashSave(): void {
    this.resetSetAsideCashValues = false;
    this.savingSetAside = true;
    const saveObs = [];
    let itemsToSave;

    // if there was an external save prompt, then the DCA items have already been added to the SAC list
    if (!this.externalSavePrompt && this.setAsideCreationType === SetAsideCreationType.DollarCostAverage) {
      if (!this.isDCAValid()) {
        this.savingSetAside = false;
        return;
      }
      this.asideCashData.push(...this.createScheduledSetAsideCash());
    }

    // if the user can save multiple items at once, get all the items that are new or modified
    if (this.allowMultipleSaves) {
      itemsToSave = this.asideCashData.filter(item => item.tempId < 0 || item.modified || item.delete);
    } else { // otherwise just save the item in context
      // if there was an external save prompt, then the DCA items have already been added to the SAC list
      if (!this.externalSavePrompt && this.setAsideCreationType === SetAsideCreationType.DollarCostAverage) {
        itemsToSave = this.createScheduledSetAsideCash();
      } else {
        itemsToSave = [this.asideCash];
      }
    }

    itemsToSave.forEach(item => {
      const isInValid = this.validation(item);
      if (isInValid) {
        this.savingSetAside = false;
        return;
      }
      const objAsideCash = this.getSetAsideCash(item);
      saveObs.push(this.saveSetAsideCash(objAsideCash));
    });

    // if there was a last second validation error, bail
    if (this.setAsideCashError) {
      this.resetSetAsideCashValues = true;
      return;
    }
    if(this.isRaiseCash) {
      this.displayAsideCashEditor = false;
      return;
    }

    // Fire the save for all the modified items
    zip(saveObs)
      .subscribe((results: any) => {
        this.displayAsideCashEditor = false;
        this.onAccountAsideCashLoad();
        this.onPortfolioAsideCashLoad();
        this.resetSetAsideCashValues = true;
        this.resetAsideCash();
        this.afterSave.emit(results.filter(r => (<IAsideCash>r.id !== undefined) || (Array.isArray(r) && <IAsideCash>r[0].id !== undefined)));
      });
  }

  validation(asideCash: IAsideCash) {
    return (!asideCash.delete && (!this.validateNegativeAsideCash(asideCash)
      || !this.validatePositiveAsideCash(asideCash)
      || !this.validateZeroAsideCash(asideCash)
      || !this.isSetAsideCashValid(asideCash)
      || !this.validateMultiplierValues(asideCash)));
  }

  saveSetAsideCash(asideCash: IAsideCash): Observable<any> {
    if(this.portfolioId) {
      let result;
      asideCash.id = asideCash.id ? asideCash.id : 0; // set the id to 0 so the api knows it's a new record
      if (asideCash.delete) {
        if (asideCash.id > 0) {
          result = this._portfolioService.deleteAsideDetailsById(Number(this.portfolioId), asideCash.id);
        }
      } else {
        // in case of ScheduledSetAsideCash, new record has negative set asideCash id, set it to 0 so the api knows it's a new record
        asideCash.id = asideCash.id < 0 ? 0 : asideCash.id;
        result = this._portfolioService.addAsideCash(Number(this.portfolioId), asideCash);
      }
      return result;
    } else {
      asideCash.isTacticalTradeTool = this.isTacticalTradeTool;
      if (asideCash.id > 0) {
        if (asideCash.delete) {
          return this._accountService.deleteAsideCash(+this.accountId, asideCash);
        }
        return this._accountService.updateAsideCash(+this.accountId, asideCash);
      }
      asideCash.id = 0; // set the id to 0 so the api knows it's a new record
      return this._accountService.addAsideCash(+this.accountId, asideCash);
    }
  }

  /** method to display context menu on ag-grid */
  getContextMenuItems(params) {
    if (!params.node || !params.node.data) {
      return null;
    }
    const self = <SetAsideCashComponent>params.context.self;
    const contextMenu = [];
    const sleeveAccount = self.sleeveType === 'Contribution' || self.sleeveType === 'Distribution';

    // To display context menu only when we right click on selected row
    const selectedRows = params.api.getSelectedRows();
    const clickedCell = selectedRows.find(s => s.id === params.node.data.id);

    const nonEditableRows = clickedCell.portfolioSetAsideCashId && !self.portfolioId;

    if (sleeveAccount || clickedCell === undefined || nonEditableRows) {
      return null;

    }

    if (!params.node.data.isExpired) {
      if (selectedRows.length === 1) {
        contextMenu.push({
          name: 'Edit',
          action: function () {
            if (params.node.data.isSystematic) {
              self._alertService.alert.emit([{ typeId: 3, message: 'Systematic set aside cash sync from Orion Connect. To edit this set aside cash, please edit the systematic in Orion Connect (Portfolio Audit > Accounts > Edit accounts > Systematics)' }]);
            } else if (params.node.data.isBilling) {
              self.billingSetAsideCashToEdit = params.node.data;
              self.displayEditBillAsideCashConfirm = true;
            } else if (self.allowMultipleSaves && params.node.data.modified) {
              self.editAsideCash(params.node.data);
            } else {
              self.getAsideDetailsById(params.node.data.id);
            }
          }
        });

        if(!self.portfolioId && self.setAsideGridType === 'Active') {
          contextMenu.push({
            name: 'Expire',
            action: function () {
              if (params.node.data.isSystematic) {
                self._alertService.alert.emit([{ typeId: 3, message: 'Systematic set aside cash sync from Orion Connect. To expire this set aside cash, please expire the systematic in Orion Connect (Portfolio Audit > Accounts > Edit accounts > Systematics)' }]);
              } else if (params.node.data.isBilling) {
                self._alertService.alert.emit([{ typeId: 3, message: 'Billing set aside cash sync from Orion Connect.' }]);
              } else {
                self.expireAsideCash(params.node.data);
              }
            }
          });
        }
      }

      contextMenu.push({
        name: 'Delete',
        action: function () {
          if (params.node.data.isSystematic) {
            self._alertService.alert.emit([{ typeId: 3, message: 'Systematic set aside cash sync from Orion Connect. To edit this set aside cash, please edit the systematic in Orion Connect (Portfolio Audit > Accounts > Edit accounts > Systematics)' }]);
          } else {
            self.deleteConfirm(selectedRows);
          }
        }
      });
    }
    return contextMenu;
  }

  getAsideDetailsById(asideCashId) {
    if(this.portfolioId) {
      this._portfolioService.getAsideDetailsById(this.portfolioId, asideCashId)
        .subscribe((model: IAsideCash) => {
          if (!this.setAsidePercentCalculationType || (this.setAsidePercentCalculationType && !this.setAsidePercentCalculationType.length)) {
            this.getAccountSetAsidePercentCalculationType();
          }
          this.editAsideCash(model);
      });
    } else {
      this._accountService.getAsideDetailsById(this.accountId, asideCashId)
        .subscribe((model: IAsideCash) => {
          if (!this.setAsidePercentCalculationType || (this.setAsidePercentCalculationType && !this.setAsidePercentCalculationType.length)) {
            this.getAccountSetAsidePercentCalculationType();
          }
          this.editAsideCash(model);
      });
    }
  }

  /* To get Cash Expiration types */
  getCashExpirationType(): Observable<IAsideCashExpirationType[]> {
    return this._accountService.getCashExpiration()
      .pipe(tap((model: IAsideCashExpirationType []) => {
        this.cashExpiration = model;
        this.cashExpiration = Util.sortBy(this.cashExpiration, 'id');
        this.cashExpirationForDistribution= this.cashExpiration;
        this.cashExpirationForContribution = this.cashExpiration.filter(c=> c.id !== 2);
      }));
  }

  /* To get set aside account type for sleeves */
  getAsideCashAccountType(): Observable<IAsideCashAccountType[]> {
    return this._accountService.getAccountType()
      .pipe(tap((accountType: IAsideCashAccountType []) => {
        this.setAsideAccountType = accountType;
        this.setAsideAccountTypeForDCA = accountType.filter(a => a.id === Consts.AsideCashAccountType.Contribution || a.id ===  Consts.AsideCashAccountType.ProRatedBasedOnContribution);
      }));
  }

  /** To Get Cash Transaction Type */
  getCashTransactionType(): Observable<IAsideCashTransactionType[]> {
    return this._accountService.getCashTransaction()
      .pipe(tap((model: IAsideCashTransactionType[]) => {
        this.cashTransaction = model;
      }));
  }

  /** Dropdown for Get aside Cash Amount Type */
  getCashAmountType(): Observable<IAsideCashAmountType[]> {
    return this._accountService.getCashAmountType()
      .pipe(tap((model: IAsideCashAmountType[]) => {
        this.cashType = model;
      }));
  }

  /**Edit a set aside cash row  */
  editAsideCash(asideCashData: IAsideCash) {
    this.displayAsideCashEditor = true;
    this.setAsideCreationType = SetAsideCreationType.Individual;
    this.asideCash.id = asideCashData.id;
    this.asideCash.tempId = asideCashData.tempId;
    this.asideCash.cashAmount = asideCashData.cashAmount;
    this.asideCash.cashAmountTypeId = asideCashData.cashAmountTypeId;
    this.asideCash.minCashAmount = asideCashData.minCashAmount;
    this.asideCash.maxCashAmount = asideCashData.maxCashAmount;
    this.asideCash.expirationTypeId = asideCashData.expirationTypeId;
    this.asideCash.percentCalculationTypeId = asideCashData.percentCalculationTypeId;
    this.type = asideCashData.cashAmountTypeId === Consts.AsideCashAmountType.Percentage ? '%' : '$';
    if (this.asideCash.expirationTypeId === 1) {
      this.asideCash.expirationValue = Util.stringToDate(asideCashData.expirationValue);
      this.asideCash.toleranceValue = null;
    } else {
      this.asideCash.expirationValue = asideCashData.expirationValue;
    }
    this.asideCash.toleranceValue = asideCashData.toleranceValue;
    this.asideCash.description = asideCashData.description;
    this.toleranceBand = this.asideCash.expirationTypeId !== 1 && this.asideCash.expirationTypeId !== 3;
    this.asideCash.setAsideAccountTypeId = (this.sleeveType === 'None' || this.sleeveType === 'Normal') ? 2 : asideCashData.setAsideAccountTypeId;
    this.asideCash.startDate = asideCashData.startDate ? Util.stringToDate(asideCashData.startDate) : moment().toDate();
    if (!this.isRaiseCash) {
      this.showMinMaxCashAmount = !!this.asideCash.minCashAmount || !!this.asideCash.maxCashAmount;
    }
    this.updateMultiplierValues();
  }

  /**Expire a set aside cash row  */
  expireAsideCash(asideCashData: IAsideCash) {
    const setAsideSaveObs = [];

    // UPDATE expiration type to date and set expiration date to yesterday
    asideCashData.expirationTypeId = 1;
    let yesterdayDate = new Date();
    // Subtract one day from current time
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);

    let startDateOnly = new Date(asideCashData.startDate);

    if(startDateOnly.getTime() > yesterdayDate.getTime()) {
      asideCashData.startDate = yesterdayDate;
    }
    asideCashData.expirationValue = Util.formatDate(yesterdayDate, 'yyyy-MM-dd');
    asideCashData.toleranceValue = null;
    asideCashData.startDate = asideCashData.startDate ? Util.formatDate(asideCashData.startDate, 'yyyy-MM-dd') : asideCashData.startDate;

    const user = this.sessionHelper.getUser();
    asideCashData.expiredBy = user.actualUserId ? user.actualUserId : user.id;
    setAsideSaveObs.push(this.saveSetAsideCash(asideCashData));

    forkJoin(setAsideSaveObs
    ).subscribe((model: IAsideCash[]) => {
      this.onAccountAsideCashLoad();
      this.onPortfolioAsideCashLoad();
      this.resetAsideCash();
      this.afterSave.emit(model);
    });
  }

  /** Delete Conformation */
  deleteAside() {
    const setAsideSaveObs = [];
    this.displayDeleteConfirm = false;
    this.selectedAside = this.selectedAside.filter(a => !a.portfolioSetAsideCashId);
    this.selectedAside.forEach(sa => {
      if (!sa.id || sa.tempId < 0) {
        // new record, just delete it from the list
        const indx = this.asideCashData.indexOf(sa);
        if (indx >= 0) {
          this.asideCashData.splice(indx);
        }
        return;
      }

      sa.delete = true;
      sa.modified = true;
      setAsideSaveObs.push(this.saveSetAsideCash(sa));
    });

    if (this.allowMultipleSaves) {
      this.gridApi.setGridOption('rowData', this.asideCashData.filter(a => !a.delete));
      return;
    }

    forkJoin(setAsideSaveObs
    ).subscribe((model: IAsideCash[]) => {
      this.displayAsideCashEditor = false;
      this.onAccountAsideCashLoad();
      this.onPortfolioAsideCashLoad();
      this.resetAsideCash();
      this.afterSave.emit(model);
    });
  }

  /** Refresh Aside Cash Grid */
  refreshAsideCashList() {
    this.onAccountAsideCashLoad();
    this.onPortfolioAsideCashLoad();
  }

  /** Delete seleted Record */
  deleteConfirm(asidecashdata: IAsideCash[]) {
    this.selectedAside = asidecashdata;
    this.displayDeleteConfirm = true;
  }

  /**Enable Save buttom when CashAmount and Date */
  enableSaveButton() {
    if (this.savingSetAside && !this.isRaiseCash) {
      return false;
    }

    if(!this.asideCash.startDate) {
      return false;
    }

    if (this.setAsideCreationType === SetAsideCreationType.Individual) {
      const isValidSetCashAmount = this.asideCash.cashAmount !== null || (this.isRaiseCash && this.hasMultipleAccount);
      const setAsideDate = this.asideCash.expirationValue;
      const toleranceValue = this.asideCash.toleranceValue;
      if (isValidSetCashAmount !== null && setAsideDate !== '') {
        return true;
      } else if (this.asideCash.expirationValue === '2' && isValidSetCashAmount !== null && toleranceValue !== null) {
        return true;
      } else {
        return this.asideCash.expirationTypeId === 3 && isValidSetCashAmount !== null;
      }
    } else {
      if (!this.trancheCount || !this.trancheInstallment || !this.everyValue || !this.startDateDCA) {
        return false;
      }
    }

    return true;
  }

  /** To filter Active AsideCash */
  filterAsideGrid(all: number) {
    // to hide right side menu options
    this.setAsideGridType = all ? 'All' : 'Active';
    const asideCashData = !all ? this.asideCashList.filter(a => a.isActive && !a.isExpired && !a.delete) : this.asideCashList.filter(a => !a.delete);
    this.gridApi.setGridOption('rowData', asideCashData);

    if(this.setAsideGridType === 'All') {
      this.gridApi.setColumnsVisible(['expiredBy'],true);
    } else {
      this.gridApi.setColumnsVisible(['expiredBy'],false);
    }
  }

  trancheCumulativeChanged(evt) {
    this.trancheCumulative = evt;
    this.validateDCAInputField();
  }

  trancheCountChanged(evt) {
    this.trancheCount = evt;
    this.validateDCAInputField();
  }

  trancheInstallmentChanged(evt) {
    this.trancheInstallment = evt;
    this.validateDCAInputField();
  }

  validateDCAInputField() {
    this.setAsideCashError = null;
    this.isDCAValid();
  }

  /** Restrict double dot's */
  validateInputFields(eInput: HTMLInputElement): void {
    this.setAsideCashError = null;
    if (eInput?.value?.indexOf('.') >= 0) {
      const decimalValue = eInput.value.split('.')[1];
      if (decimalValue.length > Consts.tacticalDecimalEnum.amountDecimalForDisplay) {
        const reg = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
        const validValue = reg.exec(eInput.value);
        eInput.value = validValue ? validValue[0] : eInput.value;
      }
    }
  }

  checkNegativeCharacter(event) {
    return !(event.key === '-' && (event.target.value.indexOf('-') !== -1 || event.target.id === 'toleranceValue'));
  }

  checkDecimalCharacter(event) {
    let isValid = true;
    if (event.target.value.includes('.')) {
      if (event.key === '.') {
        isValid = false;
      } else if (event.key === '0' && event.target.value.toString().indexOf('.') !== -1) {
        const decimalValue = event.target.value.toString().split('.')[1];
        if (decimalValue.length === 2) {
          isValid = false;
        }
      }
    }
    return isValid;
  }

  checkSpecialCharacter(event) {
    return !(event.key.toLowerCase() === 'e'
      || event.key === '+'
      || event.key === ','
      || event.key === '/'
      || event.key === '?'
      || event.key === '*'
      || event.key === '=');
  }

  /** validate negative, decimal and special characters  */
  validateEventField(event) {
    return event.key !== undefined
      && this.checkNegativeCharacter(event)
      && this.checkDecimalCharacter(event)
      && this.checkSpecialCharacter(event);
  }

  private getAccountSetAsidePercentCalculationType() {
    this._accountService.getAccountSetAsidePercentCalculationType()
      .subscribe((setAsidePercentCalculationType: IAccountSetAsidePercentCalculationType[]) => {
          this.setAsidePercentCalculationType = _.sortBy(setAsidePercentCalculationType, 'id');
        },
        error => {
          console.log('getAccountSetAsidePercentCalculationType  error : ', error);
        });
  }

  public isModified(): boolean {
    return this.asideCashData.length && this.asideCashData.filter(d => d.tempId < 0 || d.modified).length > 0;
  }

  setAsideTooltip(params: ITooltipParams) {
    if (params && params.data && params.data.portfolioSetAsideCashId && !params.context.self.portfolioId) {
      return 'Navigate to the Portfolio level to Edit';
    }
    return null;
  }

  updateAsideCashForRaiseCash(selectedAccountsList: any): void {
    if (!this.savingSetAside) {
      this.onCreateAsideCash();
      this.hasMultipleAccount = selectedAccountsList?.length > 1;
      this.disableMinMaxFieldsWarning = this.hasMultipleAccount ? Consts.Messages.disableMinMaxAmountFieldsWarning : null;
      if (selectedAccountsList?.length === 1 && this.asideCash) {
        this.asideCash.cashAmount = selectedAccountsList[0].amount;
        this.updateMultiplierValues();
      }
    } else {
      this.editAsideCash(this.asideCash);
    }
  }

  getScheduleSetAsideCash(schedulesWithAmounts: IAsideCashAmountSchedule[]): IAsideCash[] {
    return schedulesWithAmounts.map(item => {
      const id = this.getTempId();
      return {
        id: id,
        tempId: id,
        modified: true,
        cashAmount: item.amount,
        cashAmountTypeId: 1,
        cashAmountTypeName: '$',
        description: this.asideCash.description,
        expirationTypeId: 1,
        expirationTypeName: 'Date',
        expirationValue: Util.formatDate(item.date, 'yyyy-MM-dd'),
        maxCashAmount: item.amount,
        minCashAmount: item.amount,
        setAsideAccountTypeId: this.asideCash.setAsideAccountTypeId,
        startDate: this.asideCash.startDate
      } as IAsideCash;
    });
  }

  updateMultiplierValues(): void {
    if (this.asideCash.cashAmountTypeId === Consts.AsideCashAmountType.Dollar && this.asideCash.cashAmount) {
      if (this.asideCash.minCashAmount) {
        this.asideCash.minMultiplier = Util.getPercentage(this.asideCash.minCashAmount, this.asideCash.cashAmount);
      } else {
        this.asideCash.minMultiplier = null;
      }

      if (this.asideCash.maxCashAmount) {
        this.asideCash.maxMultiplier = Util.getPercentage(this.asideCash.maxCashAmount, this.asideCash.cashAmount);
      } else {
        this.asideCash.maxMultiplier = null;
      }
    }
  }

  updateAsideCashValues(): void {
    if (this.asideCash.cashAmountTypeId === Consts.AsideCashAmountType.Dollar && this.asideCash.cashAmount && !this.hasMultipleAccount) {
      if (this.asideCash.minMultiplier) {
        this.asideCash.minCashAmount = Util.getAmount(this.asideCash.minMultiplier, this.asideCash.cashAmount);
      } else {
        this.asideCash.minCashAmount = null;
      }
      if (this.asideCash.maxMultiplier) {
        this.asideCash.maxCashAmount = Util.getAmount(this.asideCash.maxMultiplier, this.asideCash.cashAmount);
      } else {
        this.asideCash.maxCashAmount = null;
      }
    }
  }

  disableMultiplierFields(): boolean {
    return this.asideCash.setAsideAccountTypeId === Consts.AsideCashAccountType.Contribution || (!this.asideCash.cashAmount && !this.hasMultipleAccount);
  }

  onSetAsideCreationTypeChange(setAsideCreationType: number): void {
    this.setAsideCreationType = setAsideCreationType;
    if (setAsideCreationType === SetAsideCreationType.Individual) {
      this.asideCash.setAsideAccountTypeId = Consts.AsideCashAccountType.Distribution;
    } else if (setAsideCreationType === SetAsideCreationType.DollarCostAverage) {
      this.refreshDCADateValues();
    }
  }

  private isPercentBetweenZeroToHundred(percent: number): boolean {
    return !(percent !== null && (percent < 0 || percent > 100));
  }

  private isPercentLessThanHundred(percent: number): boolean {
    return percent !== null && percent < 100;
  }

  validateMultiplierValues(asideCash: IAsideCash): boolean {
    let isValid = true;
    if (!asideCash.cashAmount || asideCash?.cashAmount >= 0) {
      if (!this.isPercentBetweenZeroToHundred(asideCash.minMultiplier)) {
        this.setAsideCashError = Consts.Messages.percentBetweenZeroToHundred('Minimum Multiplier');
        isValid = false;
      } else if (this.isPercentLessThanHundred(asideCash.maxMultiplier)) {
        this.setAsideCashError = Consts.Messages.percentMoreThanHundred('Maximum Multiplier');
        isValid = false;
      }
    } else if (this.isPercentLessThanHundred(asideCash.minMultiplier)) {
      this.setAsideCashError = `${Consts.Messages.percentMoreThanHundred('Minimum Multiplier')} for negative Set Aside cash`;
      isValid = false;
    } else if (!this.isPercentBetweenZeroToHundred(asideCash.maxMultiplier)) {
      this.setAsideCashError = `${Consts.Messages.percentBetweenZeroToHundred('Maximum Multiplier')} for negative Set Aside cash`;
      isValid = false;
    }
    return isValid;
  }

  onAddMinMaxSetAsideChange(): void {
    this.asideCash.minCashAmount = null;
    this.asideCash.maxCashAmount = null;
    this.asideCash.minMultiplier = null;
    this.asideCash.maxMultiplier = null;
  }

  exportToExcel(): void {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipRowGroups: true,
      fileName: 'SetAsideCash.csv'
    };
    this.gridApi.exportDataAsCsv(params);
  }
}
