<p-dialog
  header="Trade Logs"
  [(visible)]="showTradeLogs"
  modal="true"
  [draggable]="true"
  [resizable]="true"
  (onHide)="hideDialog()"
  class="ui-dialog-xl"
  [style]="{'width': 'calc(70vw'}">
  <div class="form-group">
    <div class="row" *ngIf="hasTradeLogs">
      <ag-grid-angular
        [context]="gridContext"
        (rowDoubleClicked)="onRowDoubleClicked($event)"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered()"
        [gridOptions]="tradeLogsGridOptions"
        style="height:800px;"
        class="ag-theme-alpine grid-height-autosizeThr"
        rowSelection="single"
        [columnDefs]="tradeLogsColumnDefs"
        [getContextMenuItems]="getContextMenuItems"
        [rowData]="tradeLogs">
      </ag-grid-angular>
    </div>
    <div class="row" *ngIf="!hasTradeLogs">
      <p>No trade log files are available.</p>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton (click)="hideDialog()" label="Close"></button>
  </p-footer>
</p-dialog>
