import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {

  private _securitySetEndPoint = 'v1/security/securityset';

  constructor(private _httpClient: OEHttpClient) { }

  /** Upload selected file */
  uploadSecuritySetTemplate(file) {
    const formData: any = new FormData();
    formData.append('file', file.file);
    return this._httpClient.uploadFile(`${this._securitySetEndPoint}/upload`, formData);
  }

}
