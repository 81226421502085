<p-sidebar [(visible)]="visible" class="opl-editor" [fullScreen]="true" [modal]="true" [blockScroll]="true"
           [showCloseIcon]="false">
  <ng-template pTemplate="header">
    <p-menubar class="opl-editor-header">
      <ng-template pTemplate="start">
        <div class="inline-flex align-items-center">
          <span class="opl-editor-header-icon portfolio">
            <i *ngIf="portfolio$ | async; else loading" class="fal fa-cubes"></i>
            <ng-template #loading>
              <p-skeleton size="40px"></p-skeleton>
            </ng-template>
          </span>
          <eclipse-portfolio-tree-dropdown [portfolioId]="_portfolioEditorService.portfolioId$ | async"
                                           (entityChanged)="onTreeEntityChanged($event)"></eclipse-portfolio-tree-dropdown>
        </div>
      </ng-template>
      <ng-template pTemplate="end">
        <div class="inline-flex align-items-center" style="align-items: center;">
          <button class="p-button-sm m-r-15" pButton icon="fas fa-arrow-up-right" label="Open in legacy Portfolio editor" (click)="openInLegacyEditor()"></button>
          <div class="text-right" *ngIf="portfolio$ | async as portfolio; else loading">
            <p class="opl-editor-header-value">
              {{ portfolio?.totalValue || 0 | currency }}
            </p>
            <p class="opl-editor-header-label">Total Value</p>
          </div>
          <ng-template #loading>
            <div class="text-right">
              <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
            </div>
          </ng-template>
          <p-divider layout="vertical"></p-divider>
          <div class="actionBtn btn-group pull-right">
            <button data-test-id="actions" label="Actions" iconPos="left" icon="fas fa-sparkles" pButton
                    class="p-button-text p-button-secondary" [disabled]="!(portfolio$ | async)?.id"
                    (click)="buildActionsMenu();menu.toggle($event)"></button>
            <p-tieredMenu #menu [model]="actionItems" [popup]="true" appendTo="body" baseZIndex="100"></p-tieredMenu>
          </div>
          <p-divider layout="vertical"></p-divider>
          <button type="button" pButton pRipple icon="pi pi-times" aria-label="Close Editor"
                  class="p-button-text p-button-secondary" (click)="closeEditor()"></button>
        </div>
      </ng-template>
    </p-menubar>
  </ng-template>
  <div class="opl-editor-content-container">
    <p-menu [model]="sidebarMenuItems$ | async" class="opl-editor-sidebar-menu"></p-menu>
    <div class="opl-editor-content">
      <ng-template #contentContainer></ng-template>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="opl-editor-footer" *ngIf="isFooterVisible">
      <ng-container *ngIf="portfolioEditPermission">
        <button pButton label="Save" [disabled]="!(portfolio$ | async)" (click)="savePortfolio()"></button>
        <button pButton label="Save and Close" [disabled]="!(portfolio$ | async)" (click)="savePortfolio(true)"></button>
      </ng-container>
      <button pButton class="p-button-secondary" (click)="closeEditor()" label="Cancel"></button>
    </div>
  </ng-template>
</p-sidebar>
