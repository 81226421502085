<div>
  <eclipse-file-import-export [downloadFileName]="downloadFileName" [path]="path"
                              (parentExportCallBack)="exportPortfolioEquivalentData()"
                              (parentImportCallBack)="importSecurityEquivalent($event)"
                              (parentBlankTemplateCallBack)="getBlankTemplate()"
                              (parentAboutImportCallBack)="showAboutImportPopUp=true">
  </eclipse-file-import-export>
</div>
<p-dialog [(visible)]="showAboutImportPopUp" header="About Import" [resizable]="false" modal="true"
          class="ui-dialog-lg" (onHide)="showAboutImportPopUp=false">
  <header><strong>{{preferenceLevel}} Import Details</strong></header>
  <div class="about-import">

    <span>Required Fields: </span>
    <ul>
      <li>{{preferenceLevel}} ID</li>
      <li>Targeted</li>
      <li>Equivalent</li>
      <li>
        Equivalent Buy Priority (Default, Can Buy, Do Not Buy, Priority Buy, Buy to Target, Hard to Buy, Preferred if Held)
      </li>
      <li>
        Equivalent Sell Priority (Default, Can Sell, Do Not Sell, Priority Sell, Sell to Target, Hard to Sell, Sell if No Gain, Preferred if Held)
      </li>
    </ul>

    <span>Optional Field(s): </span>
    <ul>
      <li>Delete - YES if an existing equivalent needs to be deleted.</li>
    </ul>

    <p>
      <strong>Export equivalents for all {{preferenceLevel?.toLowerCase()}}s -</strong>>
      {{preferenceLevel}}s Grid > Actions > {{preferenceLevel}} Equivalents > Import/Export Equivalents >
      Existing Data
    </p>

    <p>
      <strong>Export equivalents for specific {{preferenceLevel?.toLowerCase()}}(s) -</strong>
      {{preferenceLevel}}s Grid > Highlight the {{preferenceLevel}}(s) > Actions
      > {{preferenceLevel}} Equivalents > Import/Export Equivalents > Existing Data
    </p>

    <p>
      Equivalents for a single {{preferenceLevel?.toLowerCase()}} can also be exported from
      the {{preferenceLevel?.toLowerCase()}} preferences page by going to Actions >
      {{preferenceLevel}} Equivalents > Import/Export Equivalents > Existing Data
    </p>

  </div>
</p-dialog>
