import { Directive, HostListener } from '@angular/core';
import { GetContextMenuItems, GridReadyEvent } from '@ag-grid-community/core';

/**
 * Directive to select the row when right-clicking on an ag-grid row.
 * Base behavior is to create a context menu based on the rows already selected.
 *
 */
@Directive({
  selector: 'ag-grid-angular'
})
export class AgGridSelectRowOnRightClickDirective {
  private originalGetContextMenuItems: GetContextMenuItems;

  constructor() {
  }

  /**
   * Intercepts the getContextMenuItems call and injects a step before it which
   * selects the clicked row if not already selected, then continues executing the
   * original getContextMenuItems call.
   */
  @HostListener('gridReady', ['$event']) onHandler(gridReadyEvent: GridReadyEvent): void {
    // only inject the custom selection code if the grid has a context menu
    if (!!gridReadyEvent.api.getGridOption('getContextMenuItems') && !this.originalGetContextMenuItems) {
      // create a backup of the current getContextMenuItems function
      this.originalGetContextMenuItems = gridReadyEvent.api.getGridOption('getContextMenuItems');
      // replace the current getContextMenuItems function with a version that pre-selects rows
      gridReadyEvent.api.setGridOption('getContextMenuItems', (contextMenuParams) => {
        this.setSelectedRows(contextMenuParams);
        // execute the original getContextMenuItems function
        return this.originalGetContextMenuItems(contextMenuParams);
      });
    }
  }

  public setSelectedRows(params) {
    // if the clicked row is not selected, do so now
    if (params.node && !params.node.isSelected() && params.node.selectable) {
      params.node.setSelected(true, true);
    }
  }
}
