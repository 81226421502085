<a *ngIf="!isEditing || isCustomizing" class="a-pointer flex" style="flex: 1;" (click)="navigate()">
  <div class="dashboard-item-panel" [ngClass]="{'customizing': isCustomizing}">
    <span class="detail-title"><i class="fas fa-fw" [ngClass]="entityModule?.icon" aria-hidden="true"></i> {{ detail.dashboardField?.displayName }}</span>
    <div class="progress">
      <div class="progress-bar model" [style.width]="(percentValue || 0) + '%'"></div>
    </div>
    <div class="detail-value-container">
      <span class="dashboard-value detail-value">{{ dataValue || 0 | number }} Actions Needed</span>
      <span class="dashboard-value detail-value detail-total">{{ moduleSummaryTotal | number }} Total</span>
    </div>
  </div>
</a>

<div class="dashboard-item-panel editing" *ngIf="isEditing && !isCustomizing">
  <div class="panel-header">
    <div class="metric-title">
      <span class="detail-title">{{ detail.dashboardField?.displayName }}</span>
      <p-tag [value]="dataValue || 0 | number"></p-tag>
    </div>
    <div class="detail-edit-buttons">
      <button type="button" pButton class="p-button-secondary action-button" *ngIf="isCustomizable && canEditOptions"
              (click)="showOptions()" icon="fas fa-cog" pTooltip="Customize" aria-label="Customize">
      </button>
      <button type="button" pButton class="p-button-secondary action-button" (click)="requestDelete()"
              icon="fas fa-trash" pTooltip="Remove" aria-label="Remove">
      </button>
    </div>
  </div>
  <div class="item-drag-handle">
    <i class="fas fa-grip-horizontal m-r-5" cdkDragHandle aria-hidden="true"></i>
  </div>
</div>
