<section class="flex-column" style="gap: 30px;" *ngIf="note">
  <div>
    <label for="level">Level</label>
    <p-dropdown name="level" id="level" [options]="groupedEntityTypes" [(ngModel)]="selectedEntity" optionLabel="name"
                [style]="{'width': '100%'}" [group]="true" ngDefaultControl
                placeholder="Select a Level" filterBy="name" [required]="true">
      <ng-template let-group pTemplate="group">
        <span>{{group.label}}</span>
      </ng-template>
      <ng-template let-item pTemplate="selectedItem">
        <span style="color: var(--text-secondary);">{{item.entityTypeName}}:</span> <span
          class="m-l-5">{{item.name}}</span>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <span>{{item.name}}</span>
      </ng-template>
    </p-dropdown>
    <small class="p-error" *ngIf="submitted && !selectedEntity?.id">Level is required.</small>
  </div>

  <div>
    <label for="description">Note</label>
    <textarea id="description" pInputTextarea placeholder="Enter a note" maxlength="512" [(ngModel)]="note.notes"
              required autofocus rows="4" [required]="true"></textarea>
    <div class="flex justify-content-end">
      <small style="color: var(--text-secondary);">{{note?.notes?.length || 0}} / 512</small>
    </div>
    <small class="p-error" *ngIf="submitted && !note?.notes?.length">Level is required.</small>
  </div>

  <div class="flex-row" style="align-items: center; gap: 5px;" *ngIf="entityType !== entityTypes.Team">
    <p-checkbox id="displayNote" name="displayNote" ngDefaultControl binary="true" [(ngModel)]="note.displayNote"></p-checkbox>
    <label for="displayNote" style="margin-bottom: 0;">Display Note?</label>
    <i class="fas fa-question-circle" aria-hidden="true"
       pTooltip="If checked, this note will be displayed in lists."></i>
  </div>

  <div class="flex-row" style="gap: 5px; justify-content: space-between;">
    <div class="flex-column">
      <label for="startDate">Start Date<i class="fas fa-question-circle m-l-5" aria-hidden="true"
                                          pTooltip="Date this note will become active."></i></label>
      <p-calendar name="startDate" id="startDate" [showIcon]="true" [monthNavigator]="true" placeholder="Select Date"
                  [showClear]="false" appendTo="body" ngDefaultControl [required]="true"
                  [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="note.startDate"
                  [readonlyInput]="true"></p-calendar>
    </div>
    <div class="flex-column">
      <label for="endDate">End Date<i class="fas fa-question-circle m-l-5" aria-hidden="true"
                                      pTooltip="(Optional) Date this note will no longer be active."></i></label>
      <p-calendar name="endDate" id="endDate" [showIcon]="true" [monthNavigator]="true" placeholder="Select Date"
                  [showClear]="true" appendTo="body" ngDefaultControl
                  [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="note.endDate"
                  [readonlyInput]="true"></p-calendar>
    </div>
    <small class="p-error" *ngIf="submitted && note.endDate && note.startDate && note.endDate < note.startDate">End Date must be after Start Date.</small>
  </div>
</section>
