<div class="form-group">
  <div class="custom-form-group">
    <div class="text-left row">
      <label class="radio-inline">Search By: </label>
      <p-radioButton id="radioAccount" label="Account" name="radioEntity" class="m-r-5" value="1"
                     [(ngModel)]="radioBtnChecked"
                     (onClick)="radioBtnChange('account')"></p-radioButton>
      <p-radioButton id="radioPortfolio" label="Portfolio" name="radioEntity" class="m-r-5" value="0"
                     [(ngModel)]="radioBtnChecked"
                     (onClick)="radioBtnChange('portfolio')"></p-radioButton>
    </div>
  </div>
</div>
<div *ngIf="selectedItem && selectedItem.id">
  <eclipse-analytics-duration *ngIf="portfolioAnalytics?.length" [portfolioAnalytics]=
          "portfolioAnalytics" (updateAnalyticsData)="disableAndEnableButtonByAnalyticsStatus($event,
             quickTradeData.actionId, true)"></eclipse-analytics-duration>
</div>
<div class="form-group is-empty">
  <div class="custom-form-group">
    <label class="label-right-space" *ngIf="tradeFilterMethod === 'account'">Account</label>
    <label class="label-right-space" *ngIf="tradeFilterMethod === 'portfolio'">Portfolio</label>
  </div>
  <div *ngIf="tradeFilterMethod == 'portfolio'">
    <eclipse-portfolio-auto-complete (parentCallback)="getSelectedItem($event)" (onValueChange)="getSelectedItem($event)" [excludeDoNotTrade]="true"></eclipse-portfolio-auto-complete>
  </div>
  <div *ngIf="tradeFilterMethod == 'account'">
    <eclipse-account-auto-complete (parentCallback)="getSelectedItem($event)" (onValueChange)="getSelectedItem($event)" [excludeDoNotTrade]="false" [excludeSMA]="false"></eclipse-account-auto-complete>
  </div>

</div>
<div class="form-group">
  <div class="custom-form-group">
    <label class="label-right-space">Action</label>
    <p-dropdown id="action" class="form-control" [options]="actions" [(ngModel)]="quickTradeData.action"
                optionValue="id" optionLabel="name"
                (onChange)="onActionChange($event.value)" appendTo="body">
    </p-dropdown>
  </div>
</div>

<div class="form-group" *ngIf="showSellSecuritySearch">
  <div class="portfoliodetails-search-add">
    <label class="label-right-space">{{placeHolderAction}}</label>
    <p-autoComplete id="sellSecuritySearch" [(ngModel)]="selectedSellSecurity" [suggestions]="sellSecuritySuggestions" placeholder="Name or Ticker"
                    (completeMethod)="sellSecuritySearch($event)" (onSelect)="onSellSecuritySelect($event)" field="nameSymbol" class="autocomplete-search"
                    [minLength]="1" (keyup)="onSecurityChange($event, selectedSellSecurity)" appendTo="body">
      <ng-template let-selectedSellSecurity pTemplate="item">
        <ul class="ui-helper-clearfix show-hollding-dropdown">
          <li>
            <i class="lm lm-securities text-info" aria-hidden="true"></i>
            <span>{{ selectedSellSecurity.name }} (<strong>{{selectedSellSecurity.symbol}}</strong>)</span>
          </li>
        </ul>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
<div class="form-group" *ngIf="showBuySecuritySearch">
  <div class="portfoliodetails-search-add">
    <label class="label-right-space">{{placeHolderAction}}</label>
    <p-autoComplete id="buySecuritySearch" [(ngModel)]="selectedBuySecurity" [suggestions]="buySecuritySuggestions" placeholder="Name or Ticker"
                    (completeMethod)="buySecuritySearch($event)" (onSelect)="onBuySecuritySelect($event)" field="nameSymbol" class="autocomplete-search"
                    [minLength]="1" (keyup)="onSecurityChange($event, selectedSellSecurity)" appendTo="body">
      <ng-template let-selectedBuySecurity pTemplate="item">
        <ul class="ui-helper-clearfix show-hollding-dropdown">
          <li>
            <i class="lm lm-securities text-info" aria-hidden="true"></i>
            <span>{{ selectedBuySecurity.name }} (<strong>{{selectedBuySecurity.symbol}}</strong>)</span>
          </li>
        </ul>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
<div class="form-group is-empty" *ngIf="showDollar">
  <div class="custom-form-group">
    <div>
      <p-radioButton id="radioOpDollars" label="Dollars" name="opradio" class="m-r-5" value="0" [(ngModel)]="selectedOperation"
                     (onClick)="disableShares(1)"></p-radioButton>
    </div>
    <p-inputNumber #dollarsInput [disabled]="isDisableDollor" [placeholder]="dollarsPlaceHolder"
                   [(ngModel)]="quickTradeData.tradeAmount"
                   inputId="dollars" mode="currency" currency="USD" locale="en-US">
    </p-inputNumber>
  </div>
</div>
<div class="form-group is-empty" *ngIf="showShares">
  <div class="custom-form-group">
    <div>
      <p-radioButton id="radioOpShares" label="Shares" name="opradio" class="m-r-5" value="1" [(ngModel)]="selectedOperation"
                     (onClick)="disableDollor(1)"></p-radioButton>
    </div>
    <p-inputNumber #sharesInput [disabled]="isDisableShare" [placeholder]="placeHolderShares"
                   [min]="0" [step]="0.01" [minFractionDigits]="0" [maxFractionDigits]="4"
                   [(ngModel)]="quickTradeData.tradeShares" inputId="shares" mode="decimal">
    </p-inputNumber>
  </div>
</div>
<div class="form-group is-empty" *ngIf="isTradeActionSell">
  <div class="custom-form-group">
    <div>
      <p-radioButton id="radioOpPercent" label="Percent" name="opradio" class="m-r-5" value="2" [(ngModel)]="selectedOperation"
                     (onClick)="disableOthers(1)"></p-radioButton>
    </div>
    <p-inputNumber #percentInput [disabled]="isDisablePercent" [placeholder]="'Percent to sell'"
                   (onBlur)="validatePercentValue(quickTradeData.tradePercent)"
                   [min]="0" [max]="100" [step]="0.01" [minFractionDigits]="2" suffix="%"
                   [(ngModel)]="quickTradeData.tradePercent" inputId="percent" mode="decimal">
    </p-inputNumber>
    <div>
      <span *ngIf="isInValidPercent" class="text-danger">Should be greater than 0 and less than or equals to 100</span>
      <span *ngIf="isTotalPercentExceed" class="text-danger">Cannot sell more than 100% of a Security</span>
    </div>
  </div>
</div>
<div class="form-group" *ngIf="showSendTradeImmediatley">
  <div class="custom-form-group">
    <p-checkbox id="sendTradeImmediately" name="sendTradeImmediately" ngDefaultControl binary="true"
                [(ngModel)]="quickTradeData.isSendImmediately" label="Send Trade Immediately"></p-checkbox>
  </div>
</div>
<div class="form-group row">
  <div class="pull-right">
    <button pButton type="button" *ngIf="showValidate" [disabled]="isDisableValidate" (click)="validateTrade(quickTradeData)" label="Validate"></button>
    <button pButton type="button" [disabled]="isDisableAdd" (click)="saveTrade(quickTradeData)" class="p-button-success" label="Add"></button>
    <button pButton type="button" [disabled]="isDisableReset" class="p-button-secondary" (click)="resetForm()" label="Reset"></button>
  </div>
</div>


<div class="alert alert-danger" *ngIf="showErrorMessage" role="alert">
  <h4><i class="fas fa-exclamation-circle" aria-hidden="true"></i> Error</h4>
  {{errorMessage}}</div>

<div *ngIf="showWarningMessage">
  <div class="alert alert-warning " *ngFor="let item of warningMessages">
    <h4 *ngIf="item != 'Short Term Fee'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i>Warning</h4>
    <h4 *ngIf="item == 'Short Term Fee'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i>Short Term Fees may be applied</h4>
    {{ item }} .
  </div>
</div>

<div class="custom-tab-container fullList-scroll" *ngIf="isSearchSelected">
  <p-tabView *ngIf="showTabs">
    <p-tabPanel [selected]="true" tabName="account" header="Account">
      <div id="account">
        <div class="row" *ngIf="showAccountsTab">
          <div class="col-md-6">Acct ID </div>
          <div class="col-md-6">{{accountId}} </div>
          <div class="col-md-6">Acct Value</div>
          <div class="col-md-6">$ {{accountValue}}</div>
          <div class="col-md-6">Current </div>
          <div class="col-md-6">$ {{currentCash}}</div>
          <div class="col-md-6">Post Trade </div>
          <div class="col-md-6">$ {{postTrade}}</div>
          <div class="col-md-6">Minimum </div>
          <div class="col-md-6">$ {{cashReserv}}</div>
          <div class="col-md-6">Instructions</div>
          <div class="col-md-6">{{instructions}} </div>
        </div>

        <div class="row" *ngIf="showPortfolioTab">
          <div class="col-md-6">Portfolio ID</div>
          <div class="col-md-6">{{accountId}} </div>
          <div class="col-md-6">Portfolio Value</div>
          <div class="col-md-6">$ {{accountValue}}</div>
          <div class="col-md-6">Current </div>
          <div class="col-md-6">$ {{currentCash}}</div>
          <div class="col-md-6">Minimum</div>
          <div class="col-md-6">$ {{cashReserv}}</div>
          <div class="col-md-6">Model </div>
          <div class="col-md-6">{{modelName}}</div>
          <div class="col-md-6">Instructions</div>
          <div class="col-md-6">{{instructions}} </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel tabName="assets" header="Assets">
      <div id="assets">
        <table class="table table-small">
          <caption class="hidden-element">Account Assets</caption>
          <thead>
          <tr>
            <th scope="col"><strong>Ticker</strong> </th>
            <th scope="col"><strong>Shares</strong> </th>
            <th scope="col"><strong>Value</strong> </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of accountAssets">
            <td class="text-left"> <span>{{item.securitySymbol}}</span>
            <td> <span>{{item.shares}}</span></td>
            <td> <span>${{item.value}}</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </p-tabPanel>
    <p-tabPanel tabName="price" header="Price" *ngIf="showPriceTab">
      <div id="price">
        <div class="row">
          <div class="col-md-6">Ticker </div>
          <div class="col-md-6">{{tickerSymbol}} </div>
          <div></div>
          <div class="col-md-6">Last</div>
          <div class="col-md-6">{{LastPrice | currency:'USD'}}</div>
          <div></div>
          <div *ngIf="!isRealTimeQuote" class="col-md-6">As of </div>
          <div *ngIf="!isRealTimeQuote" class="col-md-6">{{ asof | date }}</div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Time </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ asof | date : 'medium' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Prev Close </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ previousClose | currency:'USD' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Change </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ change | currency:'USD' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Change % </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ changePercent }}%</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Volume </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ volume }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Open </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ open | currency:'USD' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Bid </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ bid | currency:'USD' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Bid Size </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ bidSize }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Ask </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ ask | currency:'USD' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Ask Size </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ askSize }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Day High </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ dayHigh | currency:'USD' }}</div>
          <div *ngIf="isRealTimeQuote"></div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">Day Low </div>
          <div *ngIf="isRealTimeQuote" class="col-md-6">{{ dayLow | currency:'USD' }}</div>
        </div>
        <div>
          <em style="color: lightslategray; font-size: x-small; font-weight: bold">* Realtime quote and/or trade prices are not sourced from all markets.</em>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
