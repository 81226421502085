import { Component, ViewChild } from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {AnalysePortfolioComponent} from './analysePortfolio.component';

@Component({
  selector: 'eclipse-model-portfolio-comparison',
  templateUrl: './modelPortfolioComparison.component.html'
})
export class ModelPortfolioComparisonComponent extends BaseComponent {

  sleeveAccountId: string;
  sleeveName: string;
  sleeveId: number;
  modelName: string;
  portfolioId: number;
  portfolioName: string;
  modelId: number;
  analysePortfolio: boolean = false;
  reserveCash: number;
  setAsideCash: number;
  setasideCashPercent: number;
  reserveCashPercent: number;
  @ViewChild(AnalysePortfolioComponent) analysePortfolioComponent: AnalysePortfolioComponent;

  constructor() {
    super();
  }

  gettingPortfolioId(id) {
    this.analysePortfolioComponent.gettingPortfolioId(id);
    this.analysePortfolio = true;
  }

  displaySleeveModelAllocation() {
    this.analysePortfolioComponent.sleeveId = this.sleeveId;
    this.analysePortfolioComponent.displaySleeveModelAllocation();
    this.analysePortfolio = true; // Fix:OE-880 Models > Model Analyzer pop up is not displaying for Sleeve Accounts
  }

  onAfterHide() {
    this.analysePortfolioComponent.resetData();
  }
}
