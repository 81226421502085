import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import { Observable } from 'rxjs';
import { IIdName } from '../models/tom';
import { IFirmProfile } from '../models/firm';

@Injectable({
  providedIn: 'root'
})
export class FirmService {

  private _firmEndPoint = 'admin/firms/profile';
  private readonly _firmEndPointv2 = 'v2/firm'

  constructor(private _httpClient: OEHttpClient) { }

  /** Get firm profile */
  getFirmProfile() {
    return this._httpClient.getData(this._firmEndPoint, false);
  }

  /**
   * Returns the firm logo as the raw binary.
   * @param asBase64 if false, returns the logo as a raw file.  If true, returns the logo file as a base64 encoded string.
   */
  getFirmLogo(asBase64: boolean = false): Observable<any> {
    if(asBase64) {
      return this._httpClient.getData(`${this._firmEndPointv2}/logo/Base64`);
    } else {
      return this._httpClient.getImage(`${this._firmEndPointv2}/logo`);
    }
  }

  /**
   * Uploads a new firm logo
   * @param logoFile
   */
  updateFirmLogo(logoFile: any) {
    const formData: any = new FormData();
    formData.append('image', logoFile);
    return this._httpClient.uploadFile(`${this._firmEndPointv2}/logo`, formData);
  }

  /** Get firm types */
  getFirmTypes(): Observable<IIdName[]> {
    return this._httpClient.getData(`${this._firmEndPointv2}/FirmTypes`, false);
  }

  /** Get firm profile */
  getFirmUrlInBase64() {
    return this._httpClient.getData(`${this._firmEndPoint}/base64`);
  }

  /** Update a firm profile */
  updateFirmProfile(logo, firmProfile: IFirmProfile) {
    const formData: any = new FormData();
    formData.append('logo', logo);
    formData.append('name', firmProfile.name);
    formData.append('firmTypeId', firmProfile.firmTypeId);
    formData.append('demo', firmProfile.demo);
    return this._httpClient.uploadFile(this._firmEndPoint, formData);
  }
}
