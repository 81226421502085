<div class="row">
  <div class="col-sm-4">
    <label class="primar-label">
      <p-checkbox binary="true" [(ngModel)]="isBalanceIndividualSecurities" ngDefaultControl name="BalanceIndividualSecurities"
                  type="checkbox" (onChange)="onBalanceIndividualCheckBoxChange()"></p-checkbox>
      Balance Individual Securities
    </label>
  </div>
  <div class="col-sm-4">
    <label class="primar-label">
      <p-checkbox binary="true" [(ngModel)]="isIncludeSubstituteNodes" ngDefaultControl name="IncludeSubstituteNodes" type="checkbox"
                  (onChange)="onIncludeSubstituteNodesCheckBoxChange()"></p-checkbox>
      Include Substitute Nodes
    </label>
  </div>
</div>
<div class=" row agcontainer securities-grid">
  <ag-grid-angular #agGrid style="height:300px !important; width:100%;" class="ag-theme-alpine"
                   [rowData]="securityList" [gridOptions]="securityListGridOptions" rowSelection="multiple"
                   [columnDefs]="securityListColumnDefs" [suppressRowClickSelection]="!isBalanceIndividualSecurities"
                   (rowSelected)="onRowSelected($event)" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
