import {Component, HostListener, Input } from '@angular/core';
import { GridOptions, ColDef, GridReadyEvent, GridApi } from '@ag-grid-community/core';
import {BaseComponent} from '../../core/base.component';
import {SecurityService} from '../../services/security.service';
import {ITradeMaxPreference, ITradeMaxPreferencesGet} from '../../models/preferences/trademaxpreference';
import { Utils as Util } from '../../core/functions';

@Component({
  selector: 'eclipse-trade-max',
  templateUrl: './trademax.component.html'
})
export class TradeMaxComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  PrefrowData: ITradeMaxPreference[] = [];
  selectedSecurityType: any = null;
  selectedSecurityTypeId: number = null;
  trademaxPref: ITradeMaxPreference = <ITradeMaxPreference>{};
  securityTypeId: number;
  securitySymbol: string;
  type: string = '$';
  securityTypes: any[];
  securityTypesHidden: any[];
  securityTypesInherited: any[];
  canShow: boolean = true;
  canRead: boolean = false;
  btnDisableSetPref: boolean = true;
  displayTradeMaxPref: boolean = false;
  isInherited: boolean = false;
  isPreferenceEdited: boolean = false;

  @Input() displayPermission: string;
  @Input() fromParent: ITradeMaxPreferencesGet;
  @Input() st: any[];
  fromParentPrefLevel: string;
  clonedSecurityTypes: any[]; /** Security Types :: New approach  */

  constructor(private _securityService: SecurityService) {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit() {
    this.PrefrowData = this.fromParent.tradeMaxPreferences; /** TODO: CHANGE IT TO REDEMPTION FEE PREFERENCES */
    this.fromParentPrefLevel = this.fromParent.levelName.toLowerCase();
    this.resetSecurityTypes();
  }

  /** Reset all security types that are customized */
  resetSecurityTypes() {
    if (!this.isInherited) {
      this.securityTypesHidden = Util.deepClone(this.st);
      this.securityTypes = Util.deepClone(this.st);
      this.clonedSecurityTypes = Util.deepClone(this.st);
      /** Splice the existing security Types */
      this.spliceExistingSecTypes();
      this.PrepareColdef();
    } else {
      this.handleSecTypesAfterInherit(this.st);
      this.PrepareColdef();
    }
  }

  /** Splice already selected Security type/s from existing Security Types  */
  spliceExistingSecTypes() {
    if (this.PrefrowData) {
      const secTypes = this.PrefrowData.map(m => m.securityTypeId);
      secTypes.forEach(m => {
        // eslint-disable-next-line eqeqeq
        this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.id == m), 1);
        Util.sortBy(this.securityTypes, 'name');
      });
    }
  }

  /** Security Type on Dropdown selection changed event */
  handleSelectedSecurityType(securityTypeId) {
    if (+securityTypeId) {
      this.btnDisableSetPref = false;
      this.securityTypeId = securityTypeId;
      this.selectedSecurityTypeId = +securityTypeId;
      // eslint-disable-next-line eqeqeq
      const secType = this.securityTypes.filter(x => x.id == +securityTypeId);
      // eslint-disable-next-line eqeqeq
      this.selectedSecurityType = (secType.length > 0 && secType != null) ? secType[0].name : '';
    } else {
      this.btnDisableSetPref = true;
    }
  }

  /** to open preferences popup*/
  setPrefPopup(selectedSecurityType) {
    this.selectedSecurityTypeId = null;
    this.displayTradeMaxPref = true;
    this.resetForm();
    this.bindTradeMaxEmptyData(selectedSecurityType);
  }

  /** Find index of expanded row  */
  findExpandedRowIndex(array, key, val) {
    for (let i = 0; i < array.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (array[i][key] == val) {
        return i;
      }
    }
    return null;
  }

  /** To Reset Form */
  resetForm() {
    this.trademaxPref = <ITradeMaxPreference>{};
    this.btnDisableSetPref = true;
    this.isPreferenceEdited = false;
  }

  /** Bind Trademax fee pref empty data */
  bindTradeMaxEmptyData(selectedSecurityType) {
    this.trademaxPref = <ITradeMaxPreference>{
      id: +selectedSecurityType,
      securityTypeId: +selectedSecurityType,
      sellMaxAmount: null,
      sellMaxPercent: null,
      buyMaxAmount: null,
      buyMaxPercent: null,
      inheritedFrom: null
    };
  }

  /** Prepare colDefs based on Preference level **/
  PrepareColdef() {
    const self = this;
    if (this.displayPermission) {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Sell Max Amount', field: 'sellMaxAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Sell Max Percent', field: 'sellMaxPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Max Amount', field: 'buyMaxAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Max Percent', field: 'buyMaxPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
      ];
    } else {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Sell Max Amount', field: 'sellMaxAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Sell Max Percent', field: 'sellMaxPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Max Amount', field: 'buyMaxAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Max Percent', field: 'buyMaxPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{
          headerName: 'Inherited from', field: 'inheritedFrom', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter'
        }
      ];
    }
    this.columnDefs.forEach(c => c.menuTabs = []);
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  /** Fires when row is selected */
  rowSelected(event) {
    if (!this.displayPermission) {
      return;
    }
    let result = '';
    // eslint-disable-next-line eqeqeq
    result = this.clonedSecurityTypes.find(m => m.id == +event.data.securityTypeId).name;
    this.selectedSecurityType = result;
  }

  /** security Type based on its id */
  securityTypeCellRenderer(params, self) {
    let result = '';
    const currentSecurityType = +params.node.data.securityTypeId;
    const checkSecurityType = this.clonedSecurityTypes.filter(m => m.id === +params.node.data.securityTypeId);
    /** NEW LOGIC FOR SECURITY TYPES RENDERING */
    result = (checkSecurityType.length > 0) ? checkSecurityType[0].name : result;
    return result;
  }

  /** method to display context menu on accounts agGrid*/
  getContextMenuItems(params) {
    const contextResult = [];
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Edit Preferences">Edit Preferences</hidden>' });
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Delete Preferences">Delete Preferences</hidden>' });

    return contextResult;
  }

  /** Hostlistner  */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement.title === 'Edit Preferences') {
      this.isPreferenceEdited = true;
      this.displayTradeMaxPref = true;
      this.trademaxPref = Object.assign({}, this.PrefrowData.find(x => x.securityTypeId === +targetElement.id));
      this.securityTypeId = +this.trademaxPref.securityTypeId;
    }

    if (targetElement.title === 'Delete Preferences') {
      const selectedPrefToDelete = Number(targetElement.id);
      const rowData = this.gridApi.getGridOption('rowData');
      rowData.splice(rowData.findIndex(x => x.securityTypeId === selectedPrefToDelete), 1);
      this.gridApi.setGridOption('rowData', rowData);
      const deletedObject = this.securityTypesHidden.find(x => x.id === +selectedPrefToDelete);
      this.securityTypes.push(deletedObject);
      Util.sortBy(this.securityTypes, 'name');
    }
  }

  /** cancels the operation and closes the pop-up */
  cancel() {
    this.displayTradeMaxPref = false;
    this.bindTradeMaxEmptyData(this.trademaxPref);
  }

  /** Adding to grid */
  addtoGrid() {
    this.displayTradeMaxPref = false;
    // eslint-disable-next-line eqeqeq
    this.trademaxPref.id = (this.trademaxPref.id != undefined) ? +this.trademaxPref.id : null;
    this.trademaxPref.securityTypeId = +this.securityTypeId;
    this.trademaxPref.securityType = null;
    // eslint-disable-next-line eqeqeq
    this.trademaxPref.sellMaxAmount = (this.trademaxPref.sellMaxAmount != undefined) ? this.trademaxPref.sellMaxAmount : null;
    // eslint-disable-next-line eqeqeq
    this.trademaxPref.sellMaxPercent = (this.trademaxPref.sellMaxPercent != undefined) ? this.trademaxPref.sellMaxPercent : null;
    // eslint-disable-next-line eqeqeq
    this.trademaxPref.buyMaxAmount = (this.trademaxPref.buyMaxAmount != undefined) ? this.trademaxPref.buyMaxAmount : null;
    // eslint-disable-next-line eqeqeq
    this.trademaxPref.buyMaxPercent = (this.trademaxPref.buyMaxPercent != undefined) ? this.trademaxPref.buyMaxPercent : null;
    // eslint-disable-next-line eqeqeq
    const match = this.PrefrowData.filter(x => x.securityTypeId == this.trademaxPref.securityTypeId);
    if (match.length > 0) {
      match[0] = this.trademaxPref;
      const indexVal = this.findExpandedRowIndex(this.PrefrowData, 'securityTypeId', match[0].securityTypeId);
      this.PrefrowData[indexVal] = match[0];
    } else {
      this.PrefrowData.push(this.trademaxPref);
    }
    this.gridApi.setGridOption('rowData', this.PrefrowData);
    if (!this.isPreferenceEdited) {
      // eslint-disable-next-line eqeqeq
      this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.name == this.selectedSecurityType), 1);
      Util.sortBy(this.securityTypes, 'name');
    }
    this.resetForm();
  }

  /*  binding InheritedSecurity Preference to gridOptions */
  bindTradeMaxData(securityResults) {
    this.isInherited = true;
    this.resetForm();
    this.PrefrowData = this.fromParent.tradeMaxPreferences;
    this.PrepareColdef();
    // this.getSecurityTypes();
    this.resetSecurityTypes();
    this.trademaxPref = <ITradeMaxPreference>{
      id: null,
      securityTypeId: null,
      sellMaxAmount: null,
      sellMaxPercent: null,
      buyMaxAmount: null,
      buyMaxPercent: null,
      inheritedFrom: null
    };
  }

  /** validate percent */
  validatePercent(value) {
    if (value.sellMaxPercent > 100) {
      this.trademaxPref.sellMaxPercent = 100;
    }
    if (value.buyMaxPercent > 100) {
      this.trademaxPref.buyMaxPercent = 100;
    }
  }

  /** Handle the security types after inherit */
  handleSecTypesAfterInherit(securitieTypesResult: any) {
    this.securityTypesInherited = securitieTypesResult;
    const inheriteds = this.fromParent.inheritedTradeMaxPreferences;
    const arrInheriteds = inheriteds.map(m => m.securityTypeId);
    if (inheriteds.length > 0) {
      arrInheriteds.forEach(secId => {
        // eslint-disable-next-line eqeqeq
        this.securityTypesInherited.splice(this.securityTypesInherited.findIndex(sc => sc.id == secId), 1);
      });
      this.securityTypes = this.securityTypesInherited;
      Util.sortBy(this.securityTypes, 'name');
    } else {
      this.securityTypes = securitieTypesResult;
      Util.sortBy(this.securityTypes, 'name');
    }
    this.isInherited = false;
  }
  /** Handle the special characters */
  setValid(event) {
    // eslint-disable-next-line eqeqeq
    if (event.key.toLowerCase() == 'e' || event.key == '-' || event.key == '+' || event.key == ',' || event.key == '/'
      // eslint-disable-next-line eqeqeq
      || event.key == '?' || event.key == '*') {
      return false;
    }
    return true;
  }

}
