import { Component, Input } from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {IPreferences} from '../../models/preferences/preference';
import {PreferenceService} from '../../services/preference.service';

@Component({
  selector: 'eclipse-mac-buy-preference',
  templateUrl: './macbuy.component.html',
  providers: [PreferenceService]
})
export class MacBuyComponent extends BaseComponent {
  showMaxPercentNode: boolean;
  selectedSetting: any;
  toleranceSeleted: string;
  @Input() toleranceSettingsSell: IPreferences[];
  @Input() toleranceSettingsBuy: IPreferences[];
  @Input() preferenceViewModel: IPreferences[];
  toleranceSettingSellOptions: any[];
  toleranceSettingBuyOptions: any[];
  objToleranceSettingBuy: any[] = [];

  constructor(private _preferenceService: PreferenceService) {
    super();
  }

  ngOnInit() {
    // eslint-disable-next-line eqeqeq
    this.showMaxPercentNode = (this.toleranceSettingsBuy[0].value != null && this.toleranceSettingsBuy[0].value != '');
    // eslint-disable-next-line eqeqeq
    this.toleranceSeleted = this.toleranceSettingsBuy.filter(s => s.name == 'toleranceSettingBuy')[0].selectedOptions[0].name;
    // eslint-disable-next-line eqeqeq
    this.toleranceSettingBuyOptions = this.toleranceSettingsBuy.filter(s => s.name == 'toleranceSettingBuy')[0].options;
    this.toleranceSettingsBuy.forEach((setting, index) => {
      // Code from here onward will only run for entries that aren't
      // the first entry
      if (index > 1) {
        this.objToleranceSettingBuy.push(setting);
      }
    });

  }

  dropdownSelectedOption(event) {
    this.toleranceSeleted = event.currentTarget.value;
    // eslint-disable-next-line eqeqeq
    const optionSel = this.toleranceSettingBuyOptions.find(o => o.name == this.toleranceSeleted);
    const preff = this.preferenceViewModel;
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).selectedOptions.splice(0, 1, optionSel);
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = false;
  }
  /** Set Checkbox value */
  setCheckBoxValue(event) {
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value = (event.target.checked) ? 'true' : 'false';
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = false;
  }
  setMaxPecentNode(event) {
    this.showMaxPercentNode = event.target.checked;
    if (!event.target.checked) {
      // eslint-disable-next-line eqeqeq
      this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value = null;
      // eslint-disable-next-line eqeqeq
      this.preferenceViewModel.find(p => p.name == event.currentTarget.id).isSetFromParent = false;
      // eslint-disable-next-line eqeqeq
      this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = false;
    // eslint-disable-next-line eqeqeq
    } else if (event.target.checked && (this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value == null || this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value == '')) {
      // eslint-disable-next-line eqeqeq
      this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value = 100;
      // eslint-disable-next-line eqeqeq
      this.preferenceViewModel.find(p => p.name == event.currentTarget.id).isSetFromParent = false;
      // eslint-disable-next-line eqeqeq
      this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = false;
    }
  }

  setResetControl(event, dynamicControlData) {
    // eslint-disable-next-line eqeqeq
    if (dynamicControlData.componentName == 'Dropdown') {
      if (dynamicControlData.inheritedSelectedOptions.length > 0 && dynamicControlData.isInherited) {
        const InheritValue = dynamicControlData.inheritedSelectedOptions[0]['id'];
        // (document.getElementById(event.currentTarget.id))["value"] = InheritValue;
        // eslint-disable-next-line eqeqeq
        this.preferenceViewModel.find(p => p.name == event.currentTarget.id).selectedOptions = this.preferenceViewModel.find(p => p.name == event.currentTarget.id).options[0];
        // eslint-disable-next-line eqeqeq
        this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value = InheritValue;
        // eslint-disable-next-line eqeqeq
        this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = true;
        // this.form.controls[event.currentTarget.id]["_value"] = InheritValue;
        // eslint-disable-next-line eqeqeq
        this.toleranceSeleted = this.preferenceViewModel.find(p => p.name == event.currentTarget.id).options[0].name;
      }
    }
  }
  /** Set Valid :: contol validations */
  setValid(event, pref) {
    pref.resetToParent = false;
    /** For resolving mozilla issue added the below condition for backspace */
    // eslint-disable-next-line eqeqeq
    if (event.keyCode == 8) { return true; }
    // eslint-disable-next-line eqeqeq
    if (pref.valueType == 'Number') {
      // eslint-disable-next-line eqeqeq
      if (event.key.toLowerCase() == 'e' || event.key == '+' || event.key == ',' || event.key == '/'
        // eslint-disable-next-line eqeqeq
        || event.key == '?' || event.key == '*') {
        return false;
      }
      if (pref.value.includes('.')) {
        // eslint-disable-next-line eqeqeq
        if (event.key == '.') {
          return false;
        }
      }
      // check for Substract symbol (-)
      // eslint-disable-next-line eqeqeq
      if (event.key == '-') {
        // check for negative value for Min value field
        // eslint-disable-next-line eqeqeq
        if (pref.minValue != null && pref.minValue < 0) {
          // check for already substract symbol (-) is present
          if (pref.value.includes('-')) {
            return false;
          } else {
            // check for first time the value is added
            // eslint-disable-next-line eqeqeq
            if (event.target.value != '') {
              const regexDashvalidate = /^-(?:\d*\.\d{1,2}|\d+)$/;
              return regexDashvalidate.test(event.key + event.target.value);
            }
            return true;
          }
        }
      }
      // eslint-disable-next-line eqeqeq
      if (event.key != '.') {
        // eslint-disable-next-line eqeqeq
        if (pref.minValue != null && pref.minValue < 0) {
          // eslint-disable-next-line eqeqeq
          if (event.target.value != '' && pref.value.includes('-')) {
            const regexDotvalidate = /^-(?:\d*\.\d{1,2}|\d+)$/;
            return regexDotvalidate.test(event.target.value + event.key);
          }
        }
        const regexvalidate = /^(?:\d*\.\d{1,2}|\d+)$/;
        return regexvalidate.test(event.target.value + event.key);
      }
    // eslint-disable-next-line eqeqeq
    } else if (pref.valueType == 'number') {
      // eslint-disable-next-line eqeqeq
      if (event.key.toLowerCase() == 'e' || event.key == '-' || event.key == '+' || event.key == ',' || event.key == '/'
        // eslint-disable-next-line eqeqeq
        || event.key == '?' || event.key == '*' || event.key == '.') {
        return false;
      }
      // eslint-disable-next-line radix
      const parsetxt = parseInt(event.target.value + event.key);
      // eslint-disable-next-line eqeqeq
      const maxValue = event.target.max == 'null' ? Number.MAX_VALUE : event.target.max;
      // eslint-disable-next-line eqeqeq
      const minValue = event.target.min == 'null' ? 0 : event.target.min;
      pref.resetToParent = false;
      return (!isNaN(parsetxt) && parsetxt >= event.target.min && parsetxt <= event.target.max);
    }
  }
  /** Validate Text box */
  validateTextBox(event) {
    // this.errorMessage = "please enter valid data!.";
    // this.dynamicControlData.isValid = false;
  }
  emptyError() { }

}
