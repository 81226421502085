export const ComplianceStatus = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING_COMPLIANCE_REVIEW: 'Pending Compliance Review',
  APPEALED: 'Appealed',
  OVERRIDDEN: 'Overridden',
  SERVICE_NOT_AVAILABLE: 'Service Not Available'
};

export const ComplianceUpdateType = {
  APPEAL: 'Appeal'
};

export const ComplianceRejectMessage = 'Rejected By Compliance.';

export const AppealErrorMessage = 'Appeal request has Failed. Please contact your service representative.'
