import { Component, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'eclipse-sort-objects',
  templateUrl: './sortobjects.component.html'
})
export class SortObjectsComponent {
  @Input() model: any;
  @Input() displayPermission: string;
  @Input() sortData: any;

  constructor() { }

  resetToParent() {
    this.sortData.resetToParent = false;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex, event.currentIndex);
    }
    this.resetToParent();
  }
}
