import { Component, inject, Inject } from '@angular/core';
import { BaseComponent } from '../core/base.component';
import { ConfigService, EnvironmentType } from '../config/config.service';
import { WINDOW } from '../providers/window.provider';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../core';

/**
 * Redirects the user to the OrionConnect login page and supplies the Eclipse authorization page URL
 * as a "redirect back on successful login".
 */
@Component({
  selector: 'eclipse-redirect-login',
  template: `
    <div class="authenticate" *ngIf="error">
      <p-message severity="error" text="Unknown Environment"></p-message>
    </div>
  `,
})
export class LoginRedirectComponent extends BaseComponent {
  private readonly _configService: ConfigService = inject(ConfigService);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _authService: AuthService = inject(AuthService);
  public error: boolean = false;

  constructor(@Inject(WINDOW) private readonly _window: Window) {
    super();
    // Clear the session first to prevent the values in session storage from leaking
    // over when the user gets redirected back after logging in.
    this._authService.clearSession();
    const environmentType = this._configService.getEnvironment();
    let redirectUrl = '';
    if (environmentType === EnvironmentType.Unknown) {
      this.error = true;
      console.error("Unknown Environment", environment);
      return;
    }
    this._activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams.r) {
        // Append the redirect query parameter to the OC redirect url.
        // Then encode the whole thing because otherwise the OC SSO redirect handler will get confused and just navigate the
        // user into OC.
        redirectUrl = environment.orionConnect.loginRedirectUrl + encodeURIComponent(`${location.protocol}//${location.host}/#/authorize?r=${queryParams.r}`);
      } else {
        redirectUrl = `${environment.orionConnect.loginRedirectUrl}${location.protocol}//${location.host}/#/authorize`;
      }
      _window.location.replace(redirectUrl);
    });
  }
}
