import {Injectable} from '@angular/core';
import {ConsoleFormattedStream, createLogger, LogStream, stdSerializers, TRACE} from 'browser-bunyan';
import {OEHttpClient} from '../http.client';
import {PreferenceService} from '../../services/preference.service';
import {SessionHelper} from '../session.helper';
import {IUser} from '../../models/user';
import {ServerStream} from './server.stream';
import {AsyncSubject} from 'rxjs';
import {Logger} from './types';

@Injectable()
export class LoggerFactory {
  static user$: AsyncSubject<IUser> = new AsyncSubject<IUser>();
  private consoleStream: LogStream;
  private serverStream: ServerStream;

  static initForUser(user: IUser) {
    this.user$.next(user);
    this.user$.complete();
  }

  constructor(httpClient: OEHttpClient, sessionHelper: SessionHelper, preferenceService: PreferenceService) {
    this.consoleStream = new ConsoleFormattedStream({logByLevel: true});
    this.serverStream = new ServerStream();
    this.init(httpClient, sessionHelper, preferenceService);
  }

  async init(httpClient: OEHttpClient, sessionHelper: SessionHelper, preferenceService: PreferenceService) {
    let user: IUser = sessionHelper.getUser();
    if (!user) {
      user = await LoggerFactory.user$.toPromise();
    }
    let isLoggingEnabled;
    const rolePrivileges = sessionHelper.getPrivileges();
    const firmPreferencesPrivilege = rolePrivileges.find(t => t.code.toLocaleUpperCase() === 'FIRMPREF');
    if (typeof(firmPreferencesPrivilege) !== 'undefined' && !!firmPreferencesPrivilege && firmPreferencesPrivilege.canRead) {
      isLoggingEnabled = await preferenceService.isEnabled(user, 'logging');
    }
    user && isLoggingEnabled ? this.serverStream.init(httpClient, 1000) : this.serverStream.finalize();
  }

  createLogger(name: string): Logger {
    const logger: Logger = createLogger({
      name,
      streams: [
        {
          level: TRACE,
          stream: this.consoleStream,
        },
        {
          level: TRACE,
          stream: this.serverStream
        }
      ],
      serializers: {
        err: stdSerializers.err
      }
    });
    return logger;
  }
}
