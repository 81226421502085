import {
  ColDef,
  GridApi,
  GridOptions, IsExternalFilterPresentParams,
  IToolPanel, MenuItemDef,
  SideBarDef,
  StatusPanelDef,
  ToolPanelDef,
} from '@ag-grid-community/core';
import * as Consts from '../../libs/app.constants';
import {
  ExtendedGridFilterToolPanel,
  IExtendedFilterDateRange,
  IExtendedFilterToolPanelFilter,
} from './extended-grid-filter.component';
import { ISavedView } from '../../viewModels/savedview';
import {
  IDynamicGridColumnFilter,
  IDynamicGridColumnState,
  IDynamicGridViewState
} from './dynamic-column-filter.component';
import * as _ from 'lodash';
import { QuickTradeToolPanel } from './quick-trade-tool-panel.component';
import { ModelAnalyzeToolPanel } from './model-analyze-tool-panel.component';
import { IModelToleranceData } from '../../components/tradeorder/shared/modeltolerance.component';
import { DataType, IColumnFilter } from './data-filters';

/**
 * Configuration options for extending ag-grid functionality.
 * @property {GridOptions} gridOptions - base grid options that will be modified to include functionality
 * @property {any} context - the component that owns the grid
 * @property {ViewTypeEnum} viewTypeId - View ID of the dashboard filter
 * @property {Function} refreshData - callback function to refresh data once a filter has been changed
 * @property {IExtendedFilterToolPanelFilter} filter - options for canned (pre-made) filters
 * @property {ISavedView} savedView - the saved view currently selected
 * @property {IExtendedFilterDateRange} dateRange - config values for the date selectors
 * @property {boolean} isDynamicGrid - does the grid have dynamic columns/filters.  If true, filters will not be applied
 *  automatically on the UI.  This is used when filters are passed to the API.
 */
export interface IExtendedGridOptions {
  gridOptions: GridOptions,
  context: any;
  viewTypeId?: Consts.ViewTypeEnum;
  refreshData?: () => void;
  filter?: IExtendedFilterToolPanelFilter;
  filterChanged?: (filter: IColumnFilter) => void;
  viewChanged?: () => void;
  viewReset?: () => void;
  savedView?: ISavedView;
  dateRange?: IExtendedFilterDateRange;
  isDynamicGrid?: boolean;
}

export interface IQuickTradePanelOptions {
  showQuickTrade: boolean;
  showFileImport: boolean;
  fileImport?: {
    onFileUpload?: (evt) => void;
    onSubmitNotify?: (evt) => void;
    isFixedIncome?: boolean;
    importType: 'QuickTrade' | 'FixedIncome';
  };
}

export interface IModelAnalyzePanelOptions {
  showModelTolerance?: boolean;
  modelTolerance?: {
    entity?: IModelToleranceData;
  };
}

export enum ToolPanelKeys {
  extendedFilter = 'extendedGridFilterToolPanel',
  quickTrade = 'quickTradeToolPanel',
  modelAnalyze = 'modelAnalyzeToolPanel'
}

export class BaseGridConfiguration {
  static toolPanelParamsAllHidden = {
    suppressRowGroups: true,
    suppressValues: true,
    suppressPivots: true,
    suppressPivotMode: true,
    suppressSideButtons: false,
    suppressColumnFilter: false,
    suppressColumnSelectAll: false,
    suppressColumnExpandAll: false
  };

  static columnsToolPanel: ToolPanelDef = {
    id: 'columns',
    labelDefault: 'Columns',
    labelKey: 'columns',
    iconKey: 'columns',
    toolPanel: 'agColumnsToolPanel',
    toolPanelParams: BaseGridConfiguration.toolPanelParamsAllHidden
  };

  static sideBarColumns: SideBarDef = {
    toolPanels: [
      BaseGridConfiguration.columnsToolPanel
    ]
  };

  static sideBarColumnsRowGroup: SideBarDef = {
    ...BaseGridConfiguration.sideBarColumns,
    toolPanels: [
      {
        ...BaseGridConfiguration.columnsToolPanel,
        toolPanelParams: {
          ...BaseGridConfiguration.toolPanelParamsAllHidden,
          suppressRowGroups: false
        }
      },
    ]
  };

  static sideBarColumnsRowGroupValues: SideBarDef = {
    ...BaseGridConfiguration.sideBarColumns,
    toolPanels: [
      {
        ...BaseGridConfiguration.columnsToolPanel,
        toolPanelParams: {
          ...BaseGridConfiguration.toolPanelParamsAllHidden,
          suppressRowGroups: false,
          suppressValues: false
        }
      },
    ]
  };

  static statusBarRowCounts: { statusPanels: StatusPanelDef[] } = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left'
      },
      {statusPanel: 'agFilteredRowCountComponent'},
      {statusPanel: 'agSelectedRowCountComponent'},
      {statusPanel: 'agAggregationComponent'},
    ]
  };

  static defaultColDef: ColDef = <ColDef>{
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: false,
    tooltipValueGetter: function (params) {
      return params.valueFormatted || params.value;
    },
  };

  static defaultFilterableColDef: ColDef = <ColDef>{
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressFloatingFilterButton: true,
    tooltipValueGetter: function (params) {
      return params.valueFormatted || params.value;
    },
  };

  static icons = {
    groupExpanded: '<i class="fas fa-minus-square"/>',
    groupContracted: '<i class="fas fa-plus-square"/>',
  };

  static defaultGridOptions(allowFiltering: boolean = false): GridOptions {
    const defaultColDef = allowFiltering ? BaseGridConfiguration.defaultFilterableColDef : BaseGridConfiguration.defaultColDef;
    return <GridOptions>{
      rowHeight: 29, // variable used to calculate Y-axis position transform of a row (moves the row, doesn't actually control the height).
      headerHeight: 32,  // height of header row with column names
      floatingFiltersHeight: 26, // height of header row with floating filters
      allowDragFromColumnsToolPanel: true,
      suppressCopyRowsToClipboard: true, // suppress row copy so only the selected cell is copied on a Ctrl+C
      preventDefaultOnContextMenu: true, // suppress the browser context menu
      animateRows: false, // turn off row animations for quicker interactions
      sideBar: {...BaseGridConfiguration.sideBarColumns},
      defaultColDef: defaultColDef,
      icons: {...BaseGridConfiguration.icons},
      tooltipInteraction: true, // let user interact (select/copy) tooltip text
    };
  }

  /**
   * Generates a GridOptions object that supports an ExtendedGridFilterToolPanel
   * @param baseGridOptions - base grid options that will be modified to include functionality for the extended filter
   * @param context - the component that owns the grid
   * @param viewTypeId - View ID of the dashboard filter
   * @param refreshData - function to refresh data once a filter has been changed
   * @param filter - options for canned filters
   * @param savedView - the saved view currently selected
   * @param dateRange - config values for the date selectors
   *
   * @deprecated The method should not be used.  Please use `generateGridOptions` instead.
   */
  static generateExtendedFilterGridOptions(baseGridOptions: GridOptions, context: any, viewTypeId?: Consts.ViewTypeEnum,
                                           refreshData?: any, filter?: IExtendedFilterToolPanelFilter, savedView?: ISavedView, dateRange?: IExtendedFilterDateRange): GridOptions {
    return this.generateGridOptions({
      gridOptions: baseGridOptions,
      context: context,
      viewTypeId: viewTypeId,
      refreshData: refreshData,
      filter: filter,
      savedView: savedView,
      dateRange: dateRange
    });
  }

  /**
   * Generates a GridOptions object that supports an extended filtering and saved views.
   * @param options - configuration options for the grid
   */
  static generateGridOptions(options: IExtendedGridOptions): GridOptions {
    const gridOptions = <GridOptions>{
      ...options.gridOptions,
      isExternalFilterPresent: (params: IsExternalFilterPresentParams) => {
        const extToolPanel = ExtendedGridFilterToolPanel.getToolPanel(params.api);
        const hasEnabledFilters = !!extToolPanel?.filters?.filter(f => f.enabled).length;
        return !options.isDynamicGrid && hasEnabledFilters;
      },
      doesExternalFilterPass: (node) => {
        // HACK: have to use `beans` because we don't have direct access to the grid api from the RowNode
        const extToolPanel = ExtendedGridFilterToolPanel.getToolPanel((<any>node).beans.gridApi);
        if (extToolPanel) {
          return extToolPanel.conditionsMet(node);
        }
        return false;
      },
      sideBar: {
        toolPanels: [
          // Extended Grid Filter tool panel
          {
            id: 'extendedGridFilterToolPanel',
            labelDefault: 'Filters',
            labelKey: 'Filters',
            iconKey: 'filter',
            toolPanel: ExtendedGridFilterToolPanel,
            toolPanelParams: {
              parentComponent: options.context,
              viewTypeId: options.viewTypeId,
              filter: options.filter,
              refreshData: options.refreshData,
              filterChanged: options.filterChanged,
              viewChanged: options.viewChanged,
              viewReset: options.viewReset,
              savedView: options.savedView,
              dateRange: options.dateRange,
              additionalFilters: null
            }
          },
          ...BaseGridConfiguration.sideBarColumnsRowGroup.toolPanels
        ],
        defaultToolPanel: 'extendedGridFilterToolPanel'
      },
      statusBar: {
        ...BaseGridConfiguration.statusBarRowCounts
      },
      icons: {
        ...BaseGridConfiguration.icons,
      },
      components: {},
    };
    return gridOptions;
  }

  static addQuickTradeToolPanel(gridOptions: GridOptions, quickTradePanelOptions: IQuickTradePanelOptions) {
    (<SideBarDef>gridOptions.sideBar).toolPanels.push(
      {
        id: ToolPanelKeys.quickTrade,
        labelDefault: 'Quick Trade',
        labelKey: 'quickTrade',
        iconKey: 'quickTradeToolPanel',
        toolPanel: QuickTradeToolPanel,
        width: 545,
        toolPanelParams: quickTradePanelOptions
      }
    );
    gridOptions.icons.quickTradeToolPanel = '<span class="fas fa-bolt"></span>';
  }

  static addModelAnalyzeToolPanel(gridOptions: GridOptions, modelAnalyzePanelOptions: IModelAnalyzePanelOptions) {
    (<SideBarDef>gridOptions.sideBar).toolPanels.push(
      {
        id: ToolPanelKeys.modelAnalyze,
        labelDefault: 'Analyze',
        labelKey: 'modelAnalyze',
        iconKey: 'modelAnalyzeToolPanel',
        toolPanel: ModelAnalyzeToolPanel,
        width: 621,
        toolPanelParams: modelAnalyzePanelOptions
      }
    );
    gridOptions.icons.modelAnalyzeToolPanel = '<span class="fas fa-crosshairs"></span>';
  }

  static getToolPanel(gridApi: GridApi, toolPanelKey: string): IToolPanel {
    try {
      if (!!gridApi?.getSideBar()) {
        return gridApi.getToolPanelInstance(toolPanelKey);
      }
      return null;
    } catch (error) {
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
      return null;
    }
  }

  static isToolPanelOpen(gridApi: GridApi, toolPanelKey: string): boolean {
    try {
      if (!!gridApi?.getSideBar()) {
        return gridApi.getOpenedToolPanel() === toolPanelKey;
      }
      return false;
    } catch (error) {
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
      return false;
    }
  }

  /**
   * Suppresses the "Row Groups" section at the bottom of the Columns tool panel.
   * @param gridOptions
   */
  static suppressRowGroups(gridOptions: GridOptions) {
    const toolPanels = <ToolPanelDef[]>(<SideBarDef>gridOptions?.sideBar)?.toolPanels;
    if (Array.isArray(toolPanels)) {
      const columnsToolPanel = toolPanels.find((tp: ToolPanelDef) => tp.toolPanel === 'agColumnsToolPanel');
      if (columnsToolPanel) {
        columnsToolPanel.toolPanelParams = columnsToolPanel.toolPanelParams || {};
        columnsToolPanel.toolPanelParams.suppressRowGroups = true;
      }
    }
  }

  /**
   * Returns the grid column and filter states.
   * @param gridApi
   */
  static getViewState(gridApi: GridApi) {
    return {
      colState: gridApi!.getColumnState() || [],
      rowGroupColumns: gridApi!.getRowGroupColumns() || [],
      filterState: ExtendedGridFilterToolPanel.getToolPanel(gridApi)?.getFilterModel(),
    };
  }

  /**
   * Adds a column filter to the grid view state
   * @param gridApi
   * @param colId
   * @param columnFilter
   */
  static addColumnFilter(gridApi: GridApi, colId: string, columnFilter: IDynamicGridColumnFilter): void {
    ExtendedGridFilterToolPanel.getToolPanel(gridApi)?.addFilter(colId, columnFilter);
  }

  /**
   * Adds a column and filter to a dynamic grid view state. Useful for adding a filter
   * immediately before sending the grid state to the API.
   * @param viewState
   * @param colId
   * @param columnFilter
   */
  static addDynamicColumnFilter(viewState: IDynamicGridViewState, colId: string, columnFilter: IDynamicGridColumnFilter): void {
    let column = viewState.columns.find(c => c.colId === colId);
    if (!column) {
      column = <IDynamicGridColumnState>{
        colId: colId
      };
      viewState.columns.push(column);
    }
    column.filters = [...column.filters || [], columnFilter];
  }

  /**
   * Returns the current state of the grid.
   * Creates an array of columns that are visible, grouped, or included in a filter.
   * Enabled filters are added directly to the column object.
   * @param gridApi
   */
  static getDynamicGridViewState(gridApi: GridApi): IDynamicGridViewState {
    const resultViewState = <IDynamicGridViewState>{};
    const state = BaseGridConfiguration.getViewState(gridApi);
    const filteredColumns = ExtendedGridFilterToolPanel.getToolPanel(gridApi)?.getFilterModel();

    // Get visible columns
    let visibleColumns = state.colState
      ?.filter(c => !c.hide && !!gridApi.getColumn(c.colId)?.getColDef()?.field) // visible and filtered columns bound to a field
      .map(c => {
        const col = <IDynamicGridColumnState>{
          colId: c.colId
        };
        if (c.sort !== null) {
          col.sort = c.sort;
        }
        if (c.sortIndex !== null) {
          col.sortIndex = c.sortIndex;
        }
        return col;
      });

    // Get grouped columns
    const groupedColumns = state.rowGroupColumns.map(c => {
      const col = <IDynamicGridColumnState>{
        colId: c.getColId()
      };
      const sort = c.getSort();
      const sortIndex = c.getSortIndex();
      if (!(sort === null || sort === undefined)) {
        col.sort = sort;
      }
      if (!(sortIndex === null || sortIndex === undefined)) {
        col.sortIndex = sortIndex;
      }
      return col;
    });

    // Add any grouped columns that are not already visible
    visibleColumns.push(...groupedColumns.filter(gc => !visibleColumns.map(vc => vc.colId).includes(gc.colId)));
    let queryColumns = [...(<any[]>visibleColumns)];

    // Get enabled filters and add their columns to the columns list
    let enabledFilters = filteredColumns?.filters?.filter(f => f.enabled);
    if (enabledFilters?.length) {
      enabledFilters = JSON.parse(JSON.stringify(enabledFilters));
      resultViewState.matchAll = filteredColumns.all;
      // Get columns from filters that are not already included in the final list
      const missingFilteredCols = [...new Set(enabledFilters.map(c => c.key))]
        .filter(key => !queryColumns.map(fc => fc.colId).includes(key))
        .map(key => <IDynamicGridColumnState>{
          colId: key
        });
      queryColumns = [...(<any[]>queryColumns), ...(<any[]>missingFilteredCols)];

      // Add the filter to the corresponding filtered column.
      // Format the filter for the API.
      queryColumns
        .forEach(qc => {
          const filterCols = enabledFilters.filter(fc => fc.key === qc.colId);
          const filters = filterCols.map(f => {
            const filterDecode = gridApi.getColumn(f.key).getColDef().filterParams?.filterDecode;
            const apiDataType = gridApi.getColumn(f.key).getColDef().filterParams?.apiDataType;
            if (filterDecode) {
              f.values = f.values.map(val => filterDecode(val));
            }
            const filter = <IDynamicGridColumnFilter>{
              comparator: f.comparator,
              dataType: apiDataType !== undefined ? DataType[apiDataType] : DataType[f.dataType],
              values: f.values,
            };
            if (f.condition) {
              filter.values.push(f.condition);
            }
            return filter;
          });
          if (!!filters?.length) { // send the filters if any have been set on the column
            qc.filters = filters;
          }
        });
    }

    resultViewState.columns = queryColumns;
    return resultViewState;
  }

  /**
   * Sorts an array of ColDefs.  Visible columns are left in the current order but are moved to the front of the array.
   * Hidden columns are moved to the end of the array and sorted alphabetically by header name.
   * @param colDefs
   */
  public static sortColumnDefs(colDefs: ColDef[]): ColDef[] {
    const visibleCols = colDefs.filter(cd => !cd.hide);
    let hiddenCols = colDefs.filter(cd => cd.hide);
    hiddenCols = _.sortBy(hiddenCols, 'headerName');
    return [...visibleCols, ...hiddenCols];
  }

  /**
   * Cleans the context menu item array to remove separators that are:
   *  -at the start of the list
   *  -at the end of the list
   *  -next to another separator
   * @param contextMenuItems
   */
  public static cleanContextMenu(contextMenuItems: (string | MenuItemDef)[]): (string | MenuItemDef)[] {
    contextMenuItems = contextMenuItems.filter((item, pos, arr) => {
      return pos === 0 || item !== arr[pos-1];
    });
    if(contextMenuItems.length && contextMenuItems.slice(0,1)[0] === 'separator') {
      contextMenuItems.shift();
    }
    if(contextMenuItems.length && contextMenuItems.slice(-1)[0] === 'separator') {
      contextMenuItems.pop();
    }
    return contextMenuItems;
  }
}
