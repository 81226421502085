import { ErrorHandler, Injectable} from '@angular/core';
import {LoggerFactory} from './logging';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private logger;
  constructor(private loggerFactory: LoggerFactory) {
    window.addEventListener('error', (error) => this.handleError(error));
    this.logger = loggerFactory.createLogger('GlobalErrorHandler');
  }
  handleError(error) {
    // Uncomment if errors are not logging as expected, or the aforementioned packages are removed.
    //console.log(error.stack);
    this.logger.error(error);
  }
}
