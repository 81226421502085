import { Injectable } from '@angular/core';
import { OEHttpClient, SessionHelper } from '../core';
import { IRole, IRoleType } from '../models/role';
import { UserType } from '../libs/app.constants';

@Injectable()
export class RoleService {
  private _rolesEndPoint = 'v1/admin/roles';
  private _rolesSummaryEndPoint = `${this._rolesEndPoint}/summary`;
  private _privilegesEndPoint = 'v1/admin/Privileges';
  private roleTypes: IRoleType[] = [];

  constructor(private _httpClient: OEHttpClient, private readonly _sessionHelper: SessionHelper) { }

  /**
   * Get all roles
   */
  getRoles() {
    return this._httpClient.getData(this._rolesEndPoint);
  }

  /**
   * Get all roles
   */
  getRolesByRoleType(roleType: string) {
    return this._httpClient.getData(`${this._rolesEndPoint}?roleType=${roleType}`);
  }

  /**
   * Get role by given role type
   */
  getRoleById(roleId: number) {
    return this._httpClient.getData(`${this._rolesEndPoint}/${roleId}`);
  }

  /**
   * Create a new role
   */
  createRole(role: IRole) {
    return this._httpClient.postData(this._rolesEndPoint, role);
  }

  /**
   * Delete a role
   */
  deleteRole(roleId: number) {
    return this._httpClient.deleteData(`${this._rolesEndPoint}/${roleId}`);
  }

  /**
   * Update a role
   */
  updateRole(role: IRole) {
    return this._httpClient.updateData(`${this._rolesEndPoint}/${role.id}`, role);
  }

  /**
   * Get all privileges
   */
  getPrivileges(roleTypeId: number = 0) {
    return (roleTypeId === 0)
      ? this._httpClient.getData(this._privilegesEndPoint)
      : this._httpClient.getData(`${this._privilegesEndPoint}/?roleTypeId=${roleTypeId}`);
  }

  /** Get an entity option for a firm */
  getEntityOption(code: string) {
    return this._httpClient.getData(`${this._privilegesEndPoint}/orion/entityoption/code/${code}`);
  }

  /**
   * Reassign a role to users who are under given role
   */
  reassignRoleToUser(roleIds: any) {
    return this._httpClient.postData(`${this._rolesEndPoint}/Action/ReassignRole`, roleIds);
  }

  /**To get static role types */
  getStaticRoleTypes() {
    this.roleTypes.push(<IRoleType>{ id: +UserType.FirmAdmin, roleType: 'FIRM ADMIN' });
    this.roleTypes.push(<IRoleType>{ id: +UserType.TeamAdmin, roleType: 'TEAM ADMIN' });
    this.roleTypes.push(<IRoleType>{ id: +UserType.User, roleType: 'USER' });
    this.roleTypes.push(<IRoleType>{ id: +UserType.APIOnly, roleType: 'API ONLY' });

    const role = this._sessionHelper.get<IRole>('role');
    if (role?.roleTypeId === UserType.OrionAdmin) {
      this.roleTypes.push(<IRoleType>{ id: +UserType.OrionAdmin, roleType: 'ORION ADMIN' });
    }

    return this.roleTypes;
  }

  /** get roles summary */
  getRolesSummary() {
    return this._httpClient.getData(this._rolesSummaryEndPoint);
  }

  searchRoles(searchString: string) {
    return this._httpClient.getData(`${this._rolesEndPoint}?search=${searchString}`);
  }

  /** Get Number of Users */
  getUsers(roleId: number) {
    return this._httpClient.getData(`${this._rolesEndPoint}/${roleId}/users`);
  }

}
