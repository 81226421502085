import { Component, Input, HostListener } from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import { GridOptions, ColDef, GridReadyEvent, GridApi } from '@ag-grid-community/core';
import {ITransactionFeePreference, ITransactionFeePreferencesGet} from '../../models/preferences/transactionfeepreference';
import {SecurityService} from '../../services/security.service';
import { Utils as Util } from '../../core/functions';

@Component({
  selector: 'eclipse-transaction-fee',
  templateUrl: './transactionfee.component.html'

})
export class TransactionFeeComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  PrefrowData: ITransactionFeePreference[] = [];
  selectedSecurityType: any = null;
  selectedSecurityTypeId: number = null;
  transactionfeePref: ITransactionFeePreference = <ITransactionFeePreference>{};
  securityTypeId: number;
  securityTypes: any[];
  securityTypesHidden: any[];
  securityTypesInherited: any[];
  canShow: boolean = true;
  canRead: boolean = false;
  btnDisableSetPref: boolean = true;
  displayTransactionFeePref: boolean = false;
  isInherited: boolean = false;
  isPreferenceEdited: boolean = false;

  @Input() displayPermission: string;
  @Input() fromParent: ITransactionFeePreferencesGet;
  @Input() st: any[];
  fromParentPrefLevel: string;
  clonedSecurityTypes: any[]; /** Security Types :: New approach  */

  constructor(private _securityService: SecurityService) {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit() {
    this.PrefrowData = this.fromParent.transactionFeePreferences;
    this.fromParentPrefLevel = this.fromParent.levelName.toLowerCase();
    this.resetSecurityTypes();
  }

  /** Reset all security types that are customized */
  resetSecurityTypes() {
    if (!this.isInherited) {
      this.securityTypesHidden = Util.deepClone(this.st);
      this.securityTypes = Util.deepClone(this.st);
      this.clonedSecurityTypes = Util.deepClone(this.st);
      /** Splice the existing security Types */
      this.spliceExistingSecTypes();
      this.PrepareColdef();
    } else {
      this.handleSecTypesAfterInherit(this.st);
      this.PrepareColdef();
    }
  }

  /** Splice already selected Security type/s from existing Security Types  */
  spliceExistingSecTypes() {
    if (this.PrefrowData) {
      const secTypes = this.PrefrowData.map(m => m.securityTypeId);
      secTypes.forEach(m => {
        // eslint-disable-next-line eqeqeq
        this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.id == m), 1);
        Util.sortBy(this.securityTypes, 'name');
      });
    }
  }

  /** Security Type on Dropdown selection changed event */
  handleSelectedSecurityType(securityTypeId) {
    if (+securityTypeId) {
      this.btnDisableSetPref = false;
      this.securityTypeId = securityTypeId;
      this.selectedSecurityTypeId = +securityTypeId;
      // eslint-disable-next-line eqeqeq
      const secType = this.securityTypes.filter(x => x.id == +securityTypeId);
      // eslint-disable-next-line eqeqeq
      this.selectedSecurityType = (secType.length > 0 && secType != null) ? secType[0].name : '';
    } else {
      this.btnDisableSetPref = true;
    }
  }

  /** to open preferences popup*/
  setPrefPopup(selectedSecurityType) {
    this.selectedSecurityTypeId = null;
    this.displayTransactionFeePref = true;
    this.resetForm();
    this.bindTransactionFeeEmptyData(selectedSecurityType);
  }

  /** Find index of expanded row  */
  findExpandedRowIndex(array, key, val) {
    for (let i = 0; i < array.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (array[i][key] == val) {
        return i;
      }
    }
    return null;
  }
  /** To Reset Form */
  resetForm() {
    this.transactionfeePref = <ITransactionFeePreference>{};
    this.btnDisableSetPref = true;
    this.isPreferenceEdited = false;
  }

  /** Bind Transaction fee pref empty data */
  bindTransactionFeeEmptyData(selectedSecurityType) {
    this.transactionfeePref = <ITransactionFeePreference>{
      id: +selectedSecurityType,
      securityTypeId: +selectedSecurityType,
      sellTransactionFee: null,
      buyTransactionFee: null,
      inheritedFrom: null
    };
  }

  /** Prepare colDefs based on Preference level **/
  PrepareColdef() {
    const self = this;
    if (this.displayPermission) {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Sell Transaction Fee Amount', field: 'sellTransactionFee', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Transaction Fee Amount', field: 'buyTransactionFee', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
      ];
    } else {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Sell Transaction Fee Amount', field: 'sellTransactionFee', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Transaction Fee Amount', field: 'buyTransactionFee', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{
          headerName: 'Inherited from', field: 'inheritedFrom', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter'
        }
      ];
    }

  }

  /** Fires when row is selected */
  rowSelected(event) {
    if (!this.displayPermission) {
      return;
    }
    let result = '';
    // eslint-disable-next-line eqeqeq
    result = this.clonedSecurityTypes.find(m => m.id == +event.data.securityTypeId).name;
    this.selectedSecurityType = result;
  }

  /** security Type based on its id */
  securityTypeCellRenderer(params, self) {
    let result = '';
    const currentSecurityType = +params.node.data.securityTypeId;
    const checkSecurityType = this.clonedSecurityTypes.filter(m => m.id === +params.node.data.securityTypeId);
    /** NEW LOGIC FOR SECURITY TYPES RENDERING */
    result = (checkSecurityType.length > 0) ? checkSecurityType[0].name : result;
    return result;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  /*** method to display context menu on accounts agGrid*/
  getContextMenuItems(params) {
    const contextResult = [];
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Edit Preferences">Edit Preferences</hidden>' });
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Delete Preferences">Delete Preferences</hidden>' });

    return contextResult;
  }

  /** Hostlistner  */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement.title === 'Edit Preferences') {
      this.isPreferenceEdited = true;
      this.displayTransactionFeePref = true;
      this.transactionfeePref = Object.assign({}, this.PrefrowData.find(x => x.securityTypeId === +targetElement.id));
      this.securityTypeId = this.transactionfeePref.securityTypeId;
    }

    if (targetElement.title === 'Delete Preferences') {
      const selectedPrefToDelete = +targetElement.id;
      const rowData = this.gridApi.getGridOption('rowData');
      rowData.splice(rowData.findIndex(x => x.securityTypeId === selectedPrefToDelete), 1);
      this.gridApi.setGridOption('rowData', rowData);
      const deletedObject = this.securityTypesHidden.find(x => x.id === +selectedPrefToDelete);
      this.securityTypes.push(deletedObject);
      Util.sortBy(this.securityTypes, 'name');
    }
  }

  /** cancels the operation and closes the pop-up */
  cancel() {
    this.displayTransactionFeePref = false;
    this.bindTransactionFeeEmptyData(this.transactionfeePref);
  }

  /** Adding to grid */
  addtoGrid() {
    this.displayTransactionFeePref = false;
    // eslint-disable-next-line eqeqeq
    this.transactionfeePref.id = (this.transactionfeePref.id != undefined) ? +this.transactionfeePref.id : null;
    this.transactionfeePref.securityTypeId = +this.securityTypeId;
    this.transactionfeePref.securityType = null;
    // eslint-disable-next-line eqeqeq
    this.transactionfeePref.sellTransactionFee = (this.transactionfeePref.sellTransactionFee != undefined) ? this.transactionfeePref.sellTransactionFee : null;
    // eslint-disable-next-line eqeqeq
    this.transactionfeePref.buyTransactionFee = (this.transactionfeePref.buyTransactionFee != undefined) ? this.transactionfeePref.buyTransactionFee : null;
    // eslint-disable-next-line eqeqeq
    const match = this.PrefrowData.filter(x => x.securityTypeId == this.transactionfeePref.securityTypeId);
    if (match.length > 0) {
      match[0] = this.transactionfeePref;
      const indexVal = this.findExpandedRowIndex(this.PrefrowData, 'securityTypeId', match[0].securityTypeId);
      this.PrefrowData[indexVal] = match[0];
    } else {
      this.PrefrowData.push(this.transactionfeePref);
    }
    this.gridApi.setGridOption('rowData', this.PrefrowData);
    if (!this.isPreferenceEdited) {
      // eslint-disable-next-line eqeqeq
      this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.name == this.selectedSecurityType), 1);
      Util.sortBy(this.securityTypes, 'name');
    }
    this.resetForm();
  }

  /*  binding InheritedSecurity Preference to gridOptions */
  bindTransactionFeeData(securityResults) {
    this.isInherited = true;
    this.resetForm();
    this.PrefrowData = this.fromParent.transactionFeePreferences;
    this.PrepareColdef();
    this.resetSecurityTypes();
    this.transactionfeePref = <ITransactionFeePreference>{
      id: null,
      securityTypeId: null,
      sellTransactionFee: null,
      buyTransactionFee: null,
      inheritedFrom: null
    };
  }

  /** Handle the security types after inherit */
  handleSecTypesAfterInherit(securitieTypesResult: any) {
    this.securityTypesInherited = securitieTypesResult;
    const inheriteds = this.fromParent.inheritedTransactionFeePreferences;
    const arrInheriteds = inheriteds.map(m => m.securityTypeId);
    if (inheriteds.length > 0) {
      arrInheriteds.forEach(secId => {
        // eslint-disable-next-line eqeqeq
        this.securityTypesInherited.splice(this.securityTypesInherited.findIndex(sc => sc.id == secId), 1);
      });
      this.securityTypes = this.securityTypesInherited;
      Util.sortBy(this.securityTypes, 'name');
    } else {
      this.securityTypes = securitieTypesResult;
      Util.sortBy(this.securityTypes, 'name');
    }
    this.isInherited = false;
  }
  /** Handle the special characters */
  setValid(event) {
    // eslint-disable-next-line eqeqeq
    return !(event.key.toLowerCase() == 'e' || event.key == '-' || event.key == '+' || event.key == ',' || event.key == '/'
      // eslint-disable-next-line eqeqeq
      || event.key == '?' || event.key == '*');

  }

}
