import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import { Observable } from 'rxjs';
import { IMoneyMarketPreferenceAuditHistory } from '../models/preferences/moneyMarketPreference';

@Injectable({
  providedIn: 'root'
})
export class PreferenceAuditHistoryService {
  private _customPreferenceEndPoint = 'auditlog/preference/custom';
  private _generalPreferenceEndPoint = 'auditlog/preference/all';
  private _moneyMarketPreferenceEndpoint = 'v2/PreferenceAuditHistory/MoneyMarket';

  constructor(private _httpClient: OEHttpClient) { }

  getTransactionFeeAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getRedemptionFeeAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getTradeMinRedemptionAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getDividendReinvestAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getTradeMaxFeeAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getCustodianSpecificRedemptionAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getCapitalGainReinvestAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getLocationOptimizationAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getSecurityAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getCommunityStrategistAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getPriorityRankingAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getTaxLotDepletionMethodAuditData(name, startDate, endDate) {
    return this._httpClient.getData(`${this._customPreferenceEndPoint}/${name}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getGeneralPreferenceAuditData(startDate, endDate) {
    return this._httpClient.getData(`${this._generalPreferenceEndPoint}?toDate=${endDate}&fromDate=${startDate}`);
  }

  getMoneyMarketAuditHistory(startDate: Date, endDate: Date): Observable<IMoneyMarketPreferenceAuditHistory> {
    return this._httpClient.getData(`${this._moneyMarketPreferenceEndpoint}?startDate=${startDate}&endDate=${endDate}`);
  }
}
