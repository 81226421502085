export interface Logger {
  // trace
  trace(obj, msg: string, ...args: any[]): void;
  trace(err: Error, msg: string, ...args: any[]): void;
  trace(msg: string, ...args: any[]): void;
  trace(): boolean;

  // debug
  debug(obj: any, msg: string, ...args: any[]): void;
  debug(err: Error, msg: string, ...args: any[]): void;
  debug(msg: string, ...args: any[]): void;
  debug(): boolean;

  // info
  info(obj: any, msg: string, ...args: any[]): void;
  info(err: Error, msg: string, ...args: any[]): void;
  info(msg: string, ...args: any[]): void;
  info(): boolean;

  // warn
  warn(obj: any, msg: string, ...args: any[]): void;
  warn(err: Error, msg: string, ...args: any[]): void;
  warn(msg: string, ...args: any[]): void;
  warn(): boolean;

  // error
  error(obj: any, msg: string, ...args: any[]): void;
  error(err: Error, msg: string, ...args: any[]): void;
  error(msg: string, ...args: any[]): void;
  error(): boolean;

  // fatal
  fatal(obj: any, msg: string, ...args: any[]): void;
  fatal(err: Error, msg: string, ...args: any[]): void;
  fatal(msg: string, ...args: any[]): void;
  fatal(): boolean;
}

export enum LogCode {
  // Messages 0 - 999
  Unknown = 0,
  // Errors 1000 - 1999
  UnknownError,
  // PerformanceEntries 2000 - 2999
  PerformanceMark = 1000,
  PerformanceMeasure = 1001
}

export interface RemoteRecord {
  system: string;
  subsystem: string;
  logLevel: string;
  logCode: LogCode;
  message: any;
}

export interface PerfMark {
  measure(name: string): void;
}

export interface PerfTimer {
  timerName: string;
  start(): void;
  measure(name: string): void;
  stop(): void;
}
