
/* Defines the Model entity */
export interface ITrade {
  id: number;
  tradeAmount: number;
  action: string;
  portfolioId: number;
  portfolioName: string;
  modelId: number;
  modelName: string;
  securityName: string;
  accountId: number;
  accountNumber: string;
  accountType: string;
  createdOn: Date;
}

/* Defines the Order summary entity */
export interface IOrderSummary {
  totalAmount: number;
  buy: number;
  sell: number;
  count: number;
}

/* Defines the Filter summary entity */
export interface IFilterSummary {
  filterId: number;
  filterType: string;
  filterName: string;
  total: number;
  value: number;
}

export interface IQueueSummary {
  count: number;
  accounts: number;
}

/* Defines the TOM Dashboard summary entity */
export interface ITomDashBoardSummary {
  analyticsOn: Date;
  openOrders: IOrderSummary;
  pendingOrders: IOrderSummary;
  closedOrders: IOrderSummary;
  blocks: IOrderSummary;
  allocations: IOrderSummary;
  queue: IQueueSummary;
  tomFilterSummary: IFilterSummary[];
}

export interface IOrionRealTimePrice {
  marketClosePrice: number;
  date: Date;
  last: number;
  symbol: string;
  isRealTimeQuote: boolean;
  isDelayedQuote: boolean;
}

export interface ISecurityPrice {
  id: number;
  isDeleted: boolean;
  price: number;
  priceDateTime: Date;
  priceType: string;
  securityId: number;
  createdBy: number;
  createddate: Date;
  editedBy: number;
  editeddate: Date;
  updatedBy: string;
}

export interface ITradesCount {
  openOrdersCount: number;
  noOfTradeBlocks: number;
  unallocatedBlocks: number;
  pendingOrdersCount: number;
  noOfClosedTrades: number;
  tradesFileCount: number;
  noOfFixedTrades: number;
  tradeQueueCount: number;
  mfBatchesAndAwaitingOrderCount: number;
}

export interface ITradesCountUpdate {
  openOrdersCount: ITradeCounter;
  noOfTradeBlocks: ITradeCounter;
  unallocatedBlocks: ITradeCounter;
  pendingOrdersCount: ITradeCounter;
  noOfClosedTrades: ITradeCounter;
  tradesFileCount: ITradeCounter;
  noOfFixedTrades: ITradeCounter;
  tradeQueueCount: ITradeCounter;
  mfBatchesAndAwaitingOrderCount: ITradeCounter;
}

export interface ITradeCounterDelta {
  count: number;
  isDown: boolean;
}

export interface ITradeCounter {
  total: number;
  delta?: ITradeCounterDelta;
}

export interface ITradeCountsPoll {
  isTradeCountsVisible: boolean;
  lastTradeCounts?: ITradesCount
}

export const OrderCountsType = {
  OPEN: {
    name: 'openOrdersCount',
    notificationType: ''
  },
  BLOCKS: {
    name: 'noOfTradeBlocks',
    notificationType: 'BLOCK_ORDERS'
  },
  ALLOCATIONS: {
    name: 'unallocatedBlocks',
    notificationType: 'ALLOCATION_ORDERS'
  },
  MF_BATCHES: {
    name: 'mfBatchesAndAwaitingOrderCount',
    notificationType: 'FIX_MF_BATCH_AND_PENDING_CUSTODIAN_ACCEPTANCE_ORDER'
  },
  PENDING: {
    name: 'pendingOrdersCount',
    notificationType: 'PENDING_ORDERS'
  },
  CLOSED: {
    name: 'noOfClosedTrades',
    notificationType: 'CLOSED_ORDERS'
  },
  TRADE_FILES: {
    name: 'tradesFileCount',
    notificationType: 'FILE_ORDERS'
  },
  QUEUE: {
    name: 'tradeQueueCount',
    notificationType: 'TRADE_QUEUE'
  }
}
