<p-dialog header="Dashboard Setting" [resizable]="false" [(visible)]="showDashboardSetting" [modal]="true"
    (onHide)="closeDashboardSettingPopUp()" class="ui-dialog-xl">
  <div class="row edit-dashboard-setting">
    <div *ngIf="!(isFirmAdmin || isOrionAdmin)"  class="pull-right ">
      <span *ngIf="displayMessage">User has overridden the firm dashboard defaults </span>
      <a href="javascript:void(0)" class="btn-reset fl-none" data-toggle="tooltip" title="Reset settings to firm default">
          <i class="fas fa-undo" (click)="resetDashboardFilters()" aria-hidden="true"></i>
      </a>
    </div>
    <ng-template *ngIf="filters && filters.length > 0" ngFor [ngForOf]="filters" let-filter="$implicit" let-index="index">
        <div [ngClass]="mainDivClass">
            <div class="dashboard-setting">
                <h4>{{filter.name}}</h4>
                <ng-template *ngIf="filter.dashboardSubLevels && filter.dashboardSubLevels.length > 0" ngFor [ngForOf]="filter.dashboardSubLevels" let-subLevel="$implicit">
                    <strong class='display-inline-block'>{{subLevel.name}}</strong>
                    <ul class="form-inline tabs-data" >
                      <li *ngFor="let option of subLevel.options" class="radio purple_radio">
                        <label><input type="radio" [disabled] ="option.isDisable" name="{{subLevel.shortCode}}" [checked]="subLevel.selectedOptionId == option.id" (click)="subLevel.selectedOptionId = option.id ; onOptionChange(filter)"> <span
                          class="circle"></span><span class="check"></span> {{option.name}}</label>
                      </li>
                    </ul>
                </ng-template>
            </div>
        </div>
    </ng-template>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="closeDashboardSettingPopUp()" label="Cancel"></button>
    <button [disabled]="filters && filters.length == 0" type="button" pButton data-dismiss="modal" (click)="saveDashboardSetting()" label="Save"></button>
  </p-footer>
</p-dialog>
