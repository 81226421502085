import {Injectable} from '@angular/core';
import {SecuritySetService} from '../../../services/securityset.service';
import {CustodianService} from '../../../services/custodian.service';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {ISellBuyPriority, ISellBuyPriorityMap} from '../../../models/security';
import {ICustodian} from '../../../models/custodian';
import {
  IEquivalent,
  IPortfolioAccountPreferenceSecurity,
  ISecurityPreference
} from '../../../models/preferences/securityPreference';
import {PreferenceService} from '../../../services/preference.service';
import {SecurityPreferenceHelper} from './security-preference-helper';

@Injectable({
  providedIn: 'root'
})
export class SecurityPreferenceHubService {

  sellAndBuyPriority: BehaviorSubject<ISellBuyPriorityMap>;
  taxableSellAndBuyPriority: BehaviorSubject<ISellBuyPriorityMap>;
  custodian: BehaviorSubject<ICustodian[]>;
  portfolioAccountPreferenceSecurity: BehaviorSubject<IPortfolioAccountPreferenceSecurity>;
  securityPreferences: BehaviorSubject<ISecurityPreference[]>;
  addUpdateSecurityPreference: BehaviorSubject<ISecurityPreference>;
  addSecurityPreferenceForEquivalent: BehaviorSubject<IEquivalent[]>;
  deleteSecurityPreference: BehaviorSubject<number>;
  refreshGrid: BehaviorSubject<void>;

  constructor(private readonly _securitySetService: SecuritySetService,
              private readonly _custodianService: CustodianService,
              private readonly _preferenceService: PreferenceService) {

    this.sellAndBuyPriority = new BehaviorSubject<ISellBuyPriorityMap>(<ISellBuyPriorityMap>{});
    this.taxableSellAndBuyPriority = new BehaviorSubject<ISellBuyPriorityMap>(<ISellBuyPriorityMap>{});
    this.custodian = new BehaviorSubject<ICustodian[]>(<ICustodian[]>[]);
    this.portfolioAccountPreferenceSecurity = new BehaviorSubject<IPortfolioAccountPreferenceSecurity>(<IPortfolioAccountPreferenceSecurity>{});
    this.securityPreferences = new BehaviorSubject<ISecurityPreference[]>(<ISecurityPreference[]>[]);
    this.addUpdateSecurityPreference = new BehaviorSubject<ISecurityPreference>(<ISecurityPreference>{});
    this.addSecurityPreferenceForEquivalent = new BehaviorSubject<IEquivalent[]>(<IEquivalent[]>[]);
    this.deleteSecurityPreference = new BehaviorSubject<number>(<number>null);
    this.refreshGrid = new BehaviorSubject<void>(<void>null);
  }

  private static updateInModelAndEquivalentSecurities(portfolioAccountPreferenceSecurityMap: IPortfolioAccountPreferenceSecurity): void {
    const targetedSecurityIds = portfolioAccountPreferenceSecurityMap.targetedSecurities.map(s => s.id);
    SecurityPreferenceHelper.securityIdsInModel = new Set<number>(targetedSecurityIds);
    const equivalentSecurityIds = portfolioAccountPreferenceSecurityMap.equivalentSecurities.map(s => s.id);
    SecurityPreferenceHelper.equivalentIdsInModel = new Set<number>(equivalentSecurityIds);
  }

  getPortfolioAccountPreferenceSecurities(levelName: string, id: number): void {
    if (levelName && id) {
      this._preferenceService.getPortfolioAccountPreferenceSecurities(levelName, id)
        .subscribe((portfolioAccountPreferenceSecurityMap: IPortfolioAccountPreferenceSecurity) => {
          SecurityPreferenceHubService.updateInModelAndEquivalentSecurities(portfolioAccountPreferenceSecurityMap);
          this.portfolioAccountPreferenceSecurity.next(portfolioAccountPreferenceSecurityMap);
          this.refreshGrid.next();
        });
    }
  }

  getPriorities() {
    forkJoin([
      this._securitySetService.getBuyPriorityList(),
      this._securitySetService.getSellPriorityList()
    ]).subscribe(data => {
      const buyPriorityList = data[0] as ISellBuyPriority[];
      const sellPriorityList = data[1] as ISellBuyPriority[];
      const taxableBuyPriorityList = buyPriorityList.filter(element => element.id === 1);
      const taxableSellPriorityList = sellPriorityList.filter(element => element.id === 1);
      this.sellAndBuyPriority.next({
        buyPriorities: buyPriorityList,
        sellPriorities: sellPriorityList
      });
      this.taxableSellAndBuyPriority.next({
        buyPriorities: taxableBuyPriorityList,
        sellPriorities: taxableSellPriorityList
      });
    });
  }

  loadCustodiansData() {
    this._custodianService.getCustodians(true)
      .subscribe((custodians: ICustodian[]) => {
        custodians.push({id: -1, name: 'ALL'} as ICustodian);
        this.custodian.next(custodians);
      });
  }
}
