import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { LogoutComponent } from './login/logout.component';
import { EclipseRoutes } from './eclipse.routes';
import { AuthorizeComponent } from './login/authorize.component';
import { NotAuthorizedComponent } from './shared/notauthorized/notauthorized.component';
import { LoginAsComponent } from './login/loginAs.component';
import { NotFoundComponent } from './shared/notfound/notfound.component';
import { EclipseRouteReuseStrategy } from './core/eclipse-router-reuse-strategy';
import { TitleTemplateStrategy } from './services/title-template.service';
import { LoginRedirectComponent } from './login/login-redirect.component';

export const routes: Routes = [
  {path: '', title: 'Login', component: LoginRedirectComponent},
  {path: 'login', title: 'Login', component: LoginRedirectComponent},
  {path: 'logout', title: 'Logout', component: LogoutComponent},
  {path: 'loginAs', component: LoginAsComponent},
  {path: 'authorize', component: AuthorizeComponent},
  {path: 'authorize/:accessToken', title: 'Authorization', component: AuthorizeComponent},
  ...EclipseRoutes,
  {path: 'notauthorized', title: 'Not Authorized', component: NotAuthorizedComponent},
  {path: '**', title: 'Page Not Found', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore'})],
  providers: [
    {provide: RouteReuseStrategy, useClass: EclipseRouteReuseStrategy},
    {
      provide: TitleStrategy,
      useClass: TitleTemplateStrategy,
    },
  ],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
