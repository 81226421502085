import {Inject, Injectable, Optional} from '@angular/core';
import {AsyncSubject} from 'rxjs';
import {WINDOW} from '../providers/window.provider';
import {environment} from '../../environments/environment';
import {development} from '../../environments/development';

export enum EnvironmentType {
  ReviewApp,
  Test,
  Staging,
  Production,
  Unknown,
  Review,
  RC,
  RCI,
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  static settings: any;
  static settings$: AsyncSubject<any> = new AsyncSubject<any>();

  constructor(@Optional() @Inject(WINDOW) private window: Window) {
  }

  load() {
    ConfigService.settings = environment.settings;
    ConfigService.settings$.next(environment.settings);
    ConfigService.settings$.complete();
  }

  public isLocal(): boolean {
    return development.local || this.window.location.hostname.toLowerCase().includes('local');
  }

  public getEnvironment(): EnvironmentType {
    const host = this.window.location.hostname.toLowerCase();
    const environmentName = environment.name ?? null;

    if (host.includes('review.apps') || host.includes('reviewapps.run')) {
      return EnvironmentType.ReviewApp;
    } else if (environmentName === 'review'
      || environmentName === 'test' // deprecated environment
      || host.includes('dev3')) {
      return EnvironmentType.Review;
    } else if (environmentName === 'rci') {
      return EnvironmentType.RCI;
    } else if (environmentName === 'rc') {
      return EnvironmentType.RC;
    } else if (environmentName === 'staging') { // deprecated environment
      return EnvironmentType.Staging;
    } else if (environment.production || host.startsWith("www") || host === 'orioneclipse.com') {
      return EnvironmentType.Production;
    } else {
      console.warn('Unknown environment name', host);
      return EnvironmentType.Unknown;
    }
  }
}
