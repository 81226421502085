<div *ngIf="compareToolEnabledPreference && ctx?.id && ctx?.entityType && ctx?.entityId">
  <wc-portfolio-compare [context]="ctx"
                        [scenarioId]="scenarioId"
                        [isCommitTradesEnabled]="isCommitTradesEnabled"
                        [pvTradeOrdersEnabled]="true"
                        (tradesCommitted)="onTradesCommitted($event, ctx)"></wc-portfolio-compare>
</div>
<div *ngIf="!compareToolEnabledPreference" class="flex p-t-20" style="justify-content: center;">
  <p-message severity="error" text="You do not have access to the Compare Tool."></p-message>
</div>
