import {Component, Input} from '@angular/core';

@Component({
  selector: 'eclipse-grid-row-count',
  templateUrl: './gridrowcount.component.html'
})

export class GridRowCountComponent {
  @Input() totalRowsCount: number;
  @Input() filteredRowsCount: number;
  @Input() hasChildren: boolean;
  @Input() filterApplied: boolean;
  @Input() childRowCount: number;
}

