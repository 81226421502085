import {LogStream} from 'browser-bunyan';
import {OEHttpClient} from '../http.client';
import {LogCode, RemoteRecord} from './types';

export class ServerStream implements LogStream {
  static remoteRecord(data: any): RemoteRecord {
    let msg = data.msg,
      logCode = LogCode.Unknown;

    if (data.err) {
      msg = data.err;
      logCode = LogCode.UnknownError; // How do we map this to a logCode?
    } else if (data.obj instanceof PerformanceEntry) {
      const perfEntry = data.obj;
      msg = perfEntry.toJSON();
      logCode = perfEntry.entryType === 'mark' ? LogCode.PerformanceMark : LogCode.PerformanceMeasure;
    }

    return {
      system: 'Eclipse',
      subsystem: data.name,
      logLevel: data.levelName,
      logCode: logCode,
      message: msg
    };
  }

  private records: Array<RemoteRecord>;
  private httpClient: OEHttpClient;
  private interval: number;
  private timeoutToken: NodeJS.Timeout;

  constructor() {
    this.records = [];
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
  }

  init(httpClient: OEHttpClient, interval: number) {
    this.httpClient = httpClient;
    this.interval = interval;
    this.flush();
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  onBeforeUnload() {
    this.flush();
  }

  write(input: object): void {
    if (this.timeoutToken) {
      clearTimeout(this.timeoutToken);
    }
    this.timeoutToken = setTimeout(() => this.flush(), this.interval);
    this.records.push(ServerStream.remoteRecord(input));
  }

  flush(): void {
    if (this.httpClient && this.records.length) {
      this.httpClient.postData('v2/log', this.records).subscribe();
      this.records = [];
    }
  }

  finalize() {
    this.records = [];
    this.write = () => {}; // make write a noop
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }
}
