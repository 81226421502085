import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { PreferenceAuditHistoryService } from '../../../services/preferenceaudithistory.service';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { IMoneyMarketPreferenceAuditHistory } from '../../../models/preferences/moneyMarketPreference';
import { Utils as Util } from '../../../core/functions';

@Component({
  selector: 'eclipse-money-market-preference-audit-history',
  templateUrl: './money-market-preference-audit-history.component.html',
  styleUrls: ['./money-market-preference-audit-history.component.scss']
})
export class MoneyMarketPreferenceAuditHistoryComponent extends BaseComponent {

  @Input() startDate: Date;
  @Input() endDate: Date;

  private gridApiAllocations: GridApi;
  private gridApiFund: GridApi;
  allocationGridOptions: GridOptions;
  fundGridOptions: GridOptions;
  allocationColumnDefinition: ColDef[];
  fundColumnDefinition: ColDef[];

  moneyMarketAllocationAuditHistories = [];
  moneyMarketFundsAuditHistories = [];

  gridContext = {
    self: this
  };

  constructor(private readonly _preferenceAuditHistoryService: PreferenceAuditHistoryService) {
    super();
    this.allocationGridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
    this.fundGridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit(): void {
    this.createAllocationColumnDefinition();
    this.createFundColumnDefinition();
    this.getMoneyMarketPreferenceAuditHistory();
  }

  onAllocationsGridReady(event: GridReadyEvent) {
    this.gridApiAllocations = event.api;
  }
  onFundGridReady(event: GridReadyEvent) {
    this.gridApiFund = event.api;
  }

  getMoneyMarketPreferenceAuditHistory(): void {
    const startDate = Util.getUTCStartDateAndTime(this.startDate);
    const endDate = Util.getUTCEndDateAndTime(this.endDate);
    this._preferenceAuditHistoryService.getMoneyMarketAuditHistory(startDate, endDate)
      .subscribe((moneyMarketAuditHistory: IMoneyMarketPreferenceAuditHistory) => {
        this.moneyMarketFundsAuditHistories = moneyMarketAuditHistory.marketMarketFundAuditHistories;
        this.moneyMarketAllocationAuditHistories = moneyMarketAuditHistory.marketMarketAllocationAuditHistories;
        this.gridApiFund.setGridOption('rowData', this.moneyMarketFundsAuditHistories);
        this.gridApiAllocations.setGridOption('rowData', this.moneyMarketAllocationAuditHistories);
        this.gridApiFund.sizeColumnsToFit();
        this.gridApiAllocations.sizeColumnsToFit();
      });
  }

  private getCommonColumnDefinition(): ColDef[] {
    return [
      <ColDef>{
        headerName: 'Record Id',
        field: 'recordId',
        width: 100,
        cellClass: 'text-center'
      },
      <ColDef>{
        headerName: 'Record Name',
        field: 'recordName',
        width: 150,
        cellClass: 'text-center'
      },
      <ColDef>{
        headerName: 'Change Type',
        field: 'changeType',
        width: 100,
        cellClass: 'text-center'
      },
      <ColDef>{
        headerName: 'Level',
        field: 'level',
        width: 100,
        cellClass: 'text-center'
      },
      <ColDef>{
        headerName: 'Edited On',
        field: 'editedOn',
        width: 150,
        cellClass: 'text-center',
        cellRenderer: this.formatUTCDateTimeCellRenderer
      },
      <ColDef>{
        headerName: 'Edited By',
        field: 'editedBy',
        width: 100,
        cellClass: 'text-center'
      }
    ];
  }

  private createAllocationColumnDefinition(): void {
    this.allocationColumnDefinition = this.getCommonColumnDefinition();
    this.allocationColumnDefinition.push(<ColDef>{
      headerName: 'Cash Target Range From',
      field: 'moneyMarketCashValueFrom',
      width: 200,
      cellClass: 'text-center',
      cellRenderer: this.percentColumnCellRender
    }, <ColDef>{
      headerName: 'Cash Target Range To',
      field: 'moneyMarketCashValueTo',
      width: 200,
      cellClass: 'text-center',
      cellRenderer: this.percentColumnCellRender
    }, <ColDef>{
      headerName: 'MM Min',
      field: 'moneyMarketMinValue',
      width: 100,
      cellClass: 'text-center',
      cellRenderer: this.percentColumnCellRender
    }, <ColDef>{
      headerName: 'MM Target',
      field: 'moneyMarketTargetValue',
      width: 120,
      cellClass: 'text-center',
      cellRenderer: this.percentColumnCellRender
    }, <ColDef>{
      headerName: 'MM Max',
      field: 'moneyMarketMaxValue',
      width: 100,
      cellClass: 'text-center',
      cellRenderer: this.percentColumnCellRender
    });
  };

  private createFundColumnDefinition(): void {
    this.fundColumnDefinition = this.getCommonColumnDefinition();
    this.fundColumnDefinition.push(<ColDef>{
        headerName: 'Ticker',
        field: 'symbol',
        width: 200,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Minimum Initial Investment $',
        field: 'moneyMarketMinInitialInvestment',
        width: 250,
        cellClass: 'text-center',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'customInputRenderer'
      });
  }

  private percentColumnCellRender(params: ICellRendererParams): string {
    return params.value ? `${params.value} %` : '0 %';
  }
}
