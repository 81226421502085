<table class="systematic-table">
  <colgroup>
    <col class="row-header">
  </colgroup>
  <thead>
  <tr>
    <th rowspan="2" scope="colgroup">Auto-Rebalancing Frequency</th>
    <th colspan="4" scope="colgroup">Monthly Systematic</th>
    <th colspan="4" scope="colgroup">Quarterly Systematic</th>
    <th colspan="4" scope="colgroup">Semi-Annual Systematic</th>
    <th colspan="4" scope="colgroup">Annual Systematic</th>
  </tr>
  <tr>
    <th scope="col">Min</th>
    <th scope="col">Target</th>
    <th scope="col">Max</th>
    <th scope="col">Window for Raising Cash</th>
    <th scope="col">Min</th>
    <th scope="col">Target</th>
    <th scope="col">Max</th>
    <th scope="col">Window for Raising Cash</th>
    <th scope="col">Min</th>
    <th scope="col">Target</th>
    <th scope="col">Max</th>
    <th scope="col">Window for Raising Cash</th>
    <th scope="col">Min</th>
    <th scope="col">Target</th>
    <th scope="col">Max</th>
    <th scope="col">Window for Raising Cash</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of rebalanceData">
    <td class="row-header">{{getRebalanceFrequencyDisplayName(item)}} Auto-Rebalance</td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.monthlyMin"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.monthlyTarget"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.monthlyMax"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell window-range-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.monthlyWindowDays">
        <span>days</span>
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.quarterlyMin"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.quarterlyTarget"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.quarterlyMax"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell window-range-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.quarterlyWindowDays">
        <span>days</span>
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.semiAnnualMin"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.semiAnnualTarget"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.semiAnnualMax"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.semiAnnualWindowDays">
        <span>days</span>
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.annualMin"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.annualTarget"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.annualMax"
               (ngModelChange)="validate(item)">
      </div>
    </td>
    <td>
      <div class="data-cell">
        <input class="form-control" type="number" step="1" min="0" [(ngModel)]="item.annualWindowDays">
        <span>days</span>
      </div>
    </td>
  </tr>
  </tbody>
</table>
