import { Component, HostListener, Input } from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {IDefaultPrimaryTeamPreference, IDefaultPrimaryTeamPreferencesGet} from '../../models/preferences/defaultprimaryteampreference';
import {TeamService} from '../../services/team.service';
import {ITeam} from '../../models/team';
import { AlertService } from 'src/app/core';

@Component({
  selector: 'eclipse-default-primary-team',
  templateUrl: './defaultprimaryteam.component.html',
  providers: [TeamService]
})
export class DefaultPrimaryTeamComponent extends BaseComponent {

  @Input() displayPermission: string;
  @Input() fromParent: IDefaultPrimaryTeamPreferencesGet;
  fromParentPrefLevel: string;
  teamsSuggesions: any[] = [];
  isInherited: boolean = false;
  defaultPrimaryTeamPref: IDefaultPrimaryTeamPreference = <IDefaultPrimaryTeamPreference>{};
  bindingDefaultPrimartTeams: IDefaultPrimaryTeamPreference[] = [];
  teamName: any = '';
  teamId: number;

  constructor(private _teamService: TeamService, private _alertService: AlertService) {
    super();
    // console.log("pref level from constructor", this.fromParentPrefLevel);
  }

  /** On init event */
  ngOnInit() {
    // console.log("fromParentPrefLevel data:", this.fromParent);
    this.fromParentPrefLevel = this.fromParent.levelName.toLowerCase();
    // eslint-disable-next-line eqeqeq
    if (this.fromParent.defaultPrimaryTeamPreferences != undefined && this.fromParent.defaultPrimaryTeamPreferences != null) {
      this.bindingDefaultPrimartTeams = this.fromParent.defaultPrimaryTeamPreferences;
      const team = (this.fromParent.defaultPrimaryTeamPreferences.length > 0) ? this.fromParent.defaultPrimaryTeamPreferences[0] : null;
      if (team) {
        this.teamName = team;
        this.teamId = team.id;
        this.teamsSuggesions = [{name: this.teamName, id: this.teamId}];
      }
    } else {
      this.bindingDefaultPrimartTeams = [];
    }
  }

  /** Get teams list */
  getTeamList(event, type) {
    /** OEF-6151 changes  */
    // eslint-disable-next-line eqeqeq
    if (type == 'dropDownClick') {
      event.query = '';
      this.teamId = undefined;
      this.teamName = undefined;
    }
      this._teamService.searchTeamsLite(event.query)
      .subscribe((data: ITeam[]) => {
          // eslint-disable-next-line eqeqeq
          if (type == 'dropDownClick') {
            this.teamsSuggesions = data;
          } else {
            // eslint-disable-next-line eqeqeq
            this.teamsSuggesions = data.filter(x => (x.id.toString().toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) != -1 || x.name.toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) != -1));
          }
        },
        error => {
        });
  }

  /**Method to get selectd value and enable/disable button */
  teamChange(val) {
    // eslint-disable-next-line eqeqeq
    if (val == undefined || val == 'undefined') {
      this.teamId = undefined;
      // this.teamName = undefined;
    } else {
      this.teamId = +val;
      // eslint-disable-next-line eqeqeq
      const selectedTeam = this.teamsSuggesions.find(firm => firm.id == this.teamId);
      if (selectedTeam.portfolioAccess === 2 || selectedTeam.subModelAccess === 2) {
        this.fromParent.defaultPrimaryTeamPreferences = this.bindingDefaultPrimartTeams;
        this.bindDefaultPrimaryTeamssData(this.fromParent.defaultPrimaryTeamPreferences);
        let warningMessage = null;
        if (selectedTeam.portfolioAccess === 2) {
          warningMessage = 'No new portfolio access set on team';
        } else if (selectedTeam.subModelAccess === 2) {
          warningMessage = 'No new sub model access set on team';
        }
        this._alertService.alert.emit([{ typeId: 3, message: warningMessage }]);
      } else {
        this.bindingDefaultPrimartTeams[0] = <IDefaultPrimaryTeamPreference>{
          id: +val,
          // eslint-disable-next-line eqeqeq
          name: this.teamsSuggesions.find(firm => firm.id == this.teamId).name
        };
        this.fromParent.defaultPrimaryTeamPreferences = this.bindingDefaultPrimartTeams;
      }
    }
  }

  /*  binding Inherited default primary team Preference to custom control */
  bindDefaultPrimaryTeamssData(defaultPrimaryTeamsResults) {
    this.isInherited = true;
    this.bindingDefaultPrimartTeams = this.fromParent.defaultPrimaryTeamPreferences;
    this.defaultPrimaryTeamPref = <IDefaultPrimaryTeamPreference>{
      id: null,
      name: null
    };

    // assign reset link data
    if (this.bindingDefaultPrimartTeams.length > 0) {
      if (this.teamId && this.teamId === this.bindingDefaultPrimartTeams[0].id) {
        return;
      }

      this.teamsSuggesions = [{name: this.bindingDefaultPrimartTeams[0].name, id: this.bindingDefaultPrimartTeams[0].id}];
      this.teamId = this.bindingDefaultPrimartTeams[0].id;
      this.teamName = this.fromParent.defaultPrimaryTeamPreferences[0];
    } else {
      this.teamsSuggesions = [];
      this.teamId = undefined;
      this.teamName = undefined;
    }
  }
  @HostListener('focusout', ['$event.target'])
  onFocusout(target) {
    // eslint-disable-next-line eqeqeq
    if (target.value == null || target.value == '') {
      this.fromParent.defaultPrimaryTeamPreferences = [];
      this.defaultPrimaryTeamPref[0] = [];
    }
  }

}
