import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OEHttpClient, SessionHelper } from '../core';
import { INote, IRelatedEntity } from '../models/notes';
import { EntityType } from '../libs/preference.enums';
import { Utils as Util } from '../core/functions';
import { tap } from 'rxjs/operators';
import { IUser } from '../models/user';
import * as Consts from '../libs/app.constants';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  private _notesEndpoint = 'v2/notes';

  constructor(private _httpClient: OEHttpClient, private _sessionHelper: SessionHelper) {
  }

  getRelatedEntities(entityId: number, entityType: EntityType): Observable<IRelatedEntity[]> {
    return this._httpClient.getData(`${this._notesEndpoint}/RelatedEntities?entityId=${entityId}&entityType=${entityType}`)
      .pipe(tap(response => {
        response.forEach(entity => {
          switch (entity.entityType){
            case EntityType.Account:
              entity.entityTypeName = 'Account';
              break;
            case EntityType.Portfolio:
              entity.entityTypeName = 'Portfolio';
              break;
            case EntityType.Team:
              entity.entityTypeName = 'Team';
              break;
            default:
              entity.entityTypeName = 'Unknown';
          }
        });
      }));
  }

  getNotesByEntity(entityId: number, entityType: EntityType): Observable<INote[]> {
    return this._httpClient.getData(`${this._notesEndpoint}?relatedId=${entityId}&relatedType=${entityType}`)
      .pipe(tap(notes => {
       const user = this._sessionHelper.getUser();
       notes.forEach(note => this.processNote(note, user, entityId, entityType));
      }));
  }

  createNotes(notes: INote[]): Observable<INote[]> {
    return this._httpClient.postData(`${this._notesEndpoint}/addList`, notes);
  }

  updateNotes(notes: INote[]): Observable<INote[]> {
    return this._httpClient.postData(`${this._notesEndpoint}/updateList`, notes);
  }

  deleteNotes(ids: number[]): Observable<any> {
    return this._httpClient.postData(`${this._notesEndpoint}/deleteList`, ids);
  }

  /**
   * Processes notes for display.
   * Dates - converts UTC date to a local date.
   * Can Edit - sets the edit flag on the note
   * Level - sets the readable version of the level
   * @param note
   * @param user
   * @param entityId
   * @param entityType
   */
  processNote(note: INote, user: IUser, entityId: number, entityType: EntityType): void {
    note.startDate = new Date(note.startDate);
    note.endDate = note.endDate ? new Date(note.endDate) : null;
    note.canEdit = !!this._sessionHelper.getPermission(Consts.PRIV_NOTES)?.canUpdate && this.canUserEditNote(note, user, entityType);
    note.canDelete = !!this._sessionHelper.getPermission(Consts.PRIV_NOTES)?.canDelete && this.canUserEditNote(note, user, entityType);
    this.setNoteEntityLevel(note, entityId, entityType);
  }

  /**
   * Returns true if the user can edit the note; false otherwise.
   * User must own the correct level Edit privilege (e.g. Portfolio CanEdit for Portfolio notes) to edit a note.
   * @param note
   * @param user
   * @param contextEntityType the type of entities the note was loaded from.  Example,
   * if this note was loaded with all the notes for a Portfolio, the contextEntityType is Portfolio.
   */
  public canUserEditNote(note: INote, user: IUser, contextEntityType: EntityType): boolean {
    if(!!note.orionConnectNote) {
      return false;
    }
    const canUserEditEntityType = this.canUserEditEntity(note.relatedType);
    switch (note.relatedType) {
      case EntityType.Team:
        // Team note - only allow users assigned to the team and have Team CanEdit access to edit the note.
        return canUserEditEntityType && contextEntityType === EntityType.Team && user.teams.some(t => t.id === note.relatedId);
      case EntityType.Portfolio:
      case EntityType.Account:
        return canUserEditEntityType;
      default:
        console.warn('Unknown note entity type:', note.relatedType);
        return false;
    }
  }

  /**
   * Returns true if the user can add/edit/delete notes for an entity type.
   * Based on user privileges.
   * @param entityType
   */
  public canUserEditEntity(entityType: EntityType): boolean {
    switch (entityType) {
      case EntityType.Team:
        return !!this._sessionHelper.getPermission(Consts.PRIV_TEAMS)?.canUpdate;
      case  EntityType.Portfolio:
        return !!this._sessionHelper.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;
      case EntityType.Account:
        return !!this._sessionHelper.getPermission(Consts.PRIV_ACCOUNTS)?.canUpdate;
      default:
        console.warn('Unknown note entity type:', entityType);
        return false;
    }
  }

  /**
   * Sets a readable version of the note's entity level.  Any note for an entity other than the current entity
   * will have the entity name appended to it.
   * Example:
   *  Current Entity:  Portfolio 123
   *    Note for Portfolio 123 will have a level of "Portfolio".
   *    Note for "My Team" will have a level of "Team - My Team".
   * @param note
   * @param entityId
   * @param entityType
   */
  setNoteEntityLevel(note: INote, entityId: number, entityType: EntityType): void {
    const isEntityNote = note.relatedType === entityType && note.relatedId === entityId; // Is this note assigned to the current entity
    switch (note.relatedType) {
      case EntityType.Account:
        note.level = `Account${isEntityNote ? '' : ` - ${note.entityName}`}`;
        break;
      case EntityType.Portfolio:
        note.level = `Portfolio${isEntityNote ? '' : ` - ${note.entityName}`}`;
        break;
      case EntityType.Team:
        note.level = `Team${isEntityNote ? '' : ` - ${note.entityName}`}`;
        break;
      default:
        note.level = `Unknown Entity Type - ${note.entityName}`;
        break;
    }
  }

  /**
   * checks if user can add note.
   */
  canUserAddNotes() {
    return !!this._sessionHelper.getPermission(Consts.PRIV_NOTES)?.canAdd
      && (!!this.canUserEditEntity(EntityType.Team)
        || !!this.canUserEditEntity(EntityType.Portfolio)
        || !!this.canUserEditEntity(EntityType.Account));
  }
}
