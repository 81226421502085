import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OEHttpClient} from '../core';
import {IModelExcelImport} from '../models/modeling/modelexcelimport';
import { RELATED_TYPE } from "../libs/app.constants";
import { EQUIVALENT_TEMPLATE } from "../libs/template.constants";

@Injectable({
  providedIn: 'root'
})
export class EquivalentImportExportService {

  private _portfolioEquivalent = 'v2/SecuritySettingPreference/Equivalents';
  private _blankTemplate = 'v2/Trading/Template';

  constructor(private readonly _httpClient: OEHttpClient) {
  }

  exportEquivalentData(portfolioIds: number[], level: number): Observable<any> {
    return this._httpClient.postData(`${this._portfolioEquivalent}/${level}`, portfolioIds);
  }

  importEquivalentTemplate(file: IModelExcelImport): Observable<any> {
    const formData: any = new FormData();
    formData.append('document', file.file);
    return this._httpClient.uploadFile(`${this._portfolioEquivalent}/Upload`, formData);
  }

  exportEquivalentBlankTemplate(level: number): Observable<any> {
      var fileName = level === RELATED_TYPE.PORTFOLIO
        ? EQUIVALENT_TEMPLATE.PORTFOLIO_EQUIVALENT
        : EQUIVALENT_TEMPLATE.ACCOUNT_EQUIVALENT;

      return this._httpClient.getData(`${this._blankTemplate}?path=${fileName}`);
  }
}
