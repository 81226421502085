<p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>

<div *ngIf="entityType !== entityTypes.Team && teamNotes?.length">
  <span>Team Notes:</span>
  <ul class="list-unstyled">
    <li *ngFor="let teamNote of teamNotes">{{teamNote.notes}}</li>
  </ul>
</div>

<p-table #dt [value]="filteredNotes" responsiveLayout="scroll" [(selection)]="selectedNote" dataKey="id"
         (contextMenuSelectionChange)="contextMenuSelectionChange($event)"
         styleClass="p-datatable-sm" [rowHover]="true" [scrollable]="true" scrollHeight="flex"
         [filterDelay]="0" [loading]="loading" [(contextMenuSelection)]="selectedNote" [contextMenu]="cm"
         [globalFilterFields]="['notes', 'level']" *ngIf="canReadNotes">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-space-between">
      <span class="p-input-icon-left">
        <i class="far fa-search"></i>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
               placeholder="Search Notes"/>
      </span>
      <span class="p-input-icon-right flex align-items-center">
          <p-radioButton label="Active" name="notesActiveFilter" class="m-r-5" value="Active"
                         [(ngModel)]="notesActiveFilter"
                         (onClick)="changeActiveFilter()"></p-radioButton>
          <p-radioButton label="All" name="notesActiveFilter" class="m-r-5" value="All"
                         [(ngModel)]="notesActiveFilter"
                         (onClick)="changeActiveFilter()" class="m-r-10"></p-radioButton>
          <button pButton label="Add New" icon="fas fa-plus" *ngIf="canAddNotes" (click)="addNewNote()"></button>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem;">ID
      </th>
      <th pSortableColumn="level" style="width: 12rem;">Level
        <p-sortIcon field="level"></p-sortIcon>
      </th>
      <th *ngIf="entityType !== entityTypes.Team" pSortableColumn="displayNote" style="width: 10rem;">Display
        <p-sortIcon field="displayNote"></p-sortIcon>
      </th>
      <th pSortableColumn="notes" style="min-width: 15rem;">Note
        <p-sortIcon field="notes"></p-sortIcon>
      </th>
      <th pSortableColumn="startDate" style="width: 9rem;">Start Date
        <p-sortIcon field="startDate"></p-sortIcon>
      </th>
      <th pSortableColumn="endDate" style="width: 9rem;">End Date
        <p-sortIcon field="endDate"></p-sortIcon>
      </th>
      <th pSortableColumn="editedDate" style="width: 9rem;">Edited By
        <p-sortIcon field="editedDate"></p-sortIcon>
      </th>
      <th style="width: 6rem;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-note>
    <tr class="p-selectable-row" [pContextMenuRow]="note">
      <td>
        <span class="p-column-title">ID</span>
        {{note.id}}
      </td>
      <td>
        <span class="p-column-title">Level</span>
        {{note.level}}
        <span *ngIf="note.orionConnectNote" > *</span>
      </td>
      <td *ngIf="entityType !== entityTypes.Team">
        <span class="p-column-title">Display</span>
        <div class="text-center" *ngIf="note.relatedType !== entityTypes.Team">
          <p-checkbox readonly="true" disabled="true" name="filter-checkbox" [(ngModel)]="note.displayNote" binary="true"></p-checkbox>
        </div>
      </td>
      <td>
        <span class="p-column-title">Note</span>
        <span class="note-text">{{note.notes}}</span>
      </td>
      <td>
        <span class="p-column-title">Start Date</span>
        <span>{{note.startDate | date:'M/d/yyyy'}}</span>
      </td>
      <td>
        <span class="p-column-title">End Date</span>
        <span>{{note.endDate | date:'M/d/yyyy'}}</span>
      </td>
      <td>
        <span class="p-column-title">Edited</span>
        <span>{{note.editedDate | date:'M/d/yyyy'}}</span><br/>
        <span>{{note.editedByUser}}</span>
      </td>
      <td>
        <div class="text-right">
          <button pButton icon="fas fa-fw fa-pencil" class=" p-button-sm mr-2"
                  *ngIf="note.canEdit && ((entityType === entityTypes.Team && note.relatedType === entityTypes.Team) || (entityType !== entityTypes.Team && note.relatedType !== entityTypes.Team))"
                  (click)="editNote(note)"></button>
          <button pButton icon="fas fa-fw fa-trash" class="p-button-danger p-button-sm" *ngIf="note.canDelete" (click)="deleteNote(note)"></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9">No notes found.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <span *ngIf="showOCNoteLegend">* = trading instructions synced from Orion Connect</span>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td *ngIf="entityType !== entityTypes.Team"><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
    </tr>
    <tr>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td *ngIf="entityType !== entityTypes.Team"><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
    </tr>
    <tr>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td *ngIf="entityType !== entityTypes.Team"><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
    </tr>
  </ng-template>
</p-table>

<p-dialog [(visible)]="noteDialog" header="Notes" [modal]="true" class="ui-dialog-md" appendTo="body">
  <eclipse-note-editor #noteEditor [entityId]="entityId" [entityType]="entityType" [relatedEntities]="relatedEntities"
                       (onNoteChanged)="onNoteChanged($event)"></eclipse-note-editor>
  <ng-template pTemplate="footer">
    <button pButton label="Cancel" icon="fas fa-times" class="p-button-secondary" (click)="hideDialog()"></button>
    <button pButton label="Save" icon="fas fa-check" [disabled]="noteEditor?.isSaving || !noteEditor?.note?.canEdit" (click)="noteEditor.saveNote()"></button>
  </ng-template>
</p-dialog>
