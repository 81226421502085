import { Component } from '@angular/core';
import * as d3 from 'd3';
import { Utils } from '../../../core/functions';
declare var $: any;

@Component({
  selector: 'eclipse-group-bar-chart',
  templateUrl: './groupbarchart.component.html'
})

export class GroupBarChartComponent {

  width: number;
  height: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  xaxisLength: number;
  yaxisLength: number;
  previousXdomain: any = [];
  svg: any;
  xScale: any;
  yScale: any;
  xaxis: any;
  yaxis: any;
  xdomain: any;
  xGroup: any;
  tooltip_trade: any;
  svgGroup: any;
  LegendsGroup: any;
  tempData: any;
  targetValue: any;
  xLabelsArray: any = [];
  ticketName: string;

  constructor() {}

  /* method used to create svg and axis of chart*/
  public CreateSvgForgroupChart(groupData) {
    const that = this;
    const labelArray = [];
    that.xLabelsArray = [];
    groupData[0]['data'].forEach(function (ele, key) {
      // eslint-disable-next-line eqeqeq
      if ($.inArray(ele.symbol, labelArray) == -1) {
        labelArray.push(ele.symbol);
      }
    });
    let chartHeight = 300;
    if (labelArray.length > 8) {
      chartHeight = 300 + (labelArray.length - 8) * 8;
    }
    that.width = 622;
    that.height = chartHeight;
    that.marginTop = 20;
    that.marginBottom = 50;
    that.marginLeft = 40;
    that.marginRight = 20;
    that.xaxisLength = that.width - that.marginBottom - that.marginLeft - that.marginRight;
    that.yaxisLength = chartHeight - 2 * that.marginTop - 2 * that.marginBottom;
    //   that.LegendArray = ['#E6F7FE', '#E6F7FE', '#00AEEF']
    that.previousXdomain = [];
    that.xScale = <any>d3.scaleLinear()
      .range([0, that.xaxisLength]);

    that.yScale = d3.scaleBand()
      .rangeRound([that.yaxisLength, 0])
      .padding(0.7);

    that.removeBarGraphChart();
    that.svg = d3.select('.group_column_chart')
      .append('svg')
      .attr('height', that.height)
      .attr('width', that.width)
      .attr('class', 'groupsvg');

    that.xaxis = d3.axisBottom(that.xScale)
        .tickSize(-(that.yaxisLength))
        .tickSizeOuter(0)
        .tickPadding(10)
        .tickFormat(function (d: number) {
          return d3.format('.2f')(d).toString() + '%';
        });

    that.yaxis = <any>d3.axisLeft(that.yScale)
      .scale(that.yScale)
      .tickSize(0)
      .tickPadding(6)
      .tickSizeInner((10))
      .tickSizeOuter(0)
      .tickPadding(10);
    groupData.forEach(function (val, key) {
      val['legendsShow'] = true;
    });
    that.renderChart(groupData); // render chart
    that.renderLegend(groupData); // plot legends
  }

  /* method used to remove svg and tooltip  * */
  public removeBarGraphChart() {
    if (d3.select('.groupsvg')) {
      d3.select('.groupsvg').remove();
    }
    d3.select('.tooltip_trade').remove();
  }

  /**
   * Sorts the chart model nodes alphabetically by symbol.
   * If cash nodes are found, they're moved to the top of the list.
   * -Note that "top" of the list actually means "end" of the list, since
   * the chart is rendering nodes last-to-first.
   * @param data
   */
  public sortModelNodes(data) {
    data[0].data = Utils.sortByDesc(data[0].data, 'symbol');
    data[1].data = Utils.sortByDesc(data[1].data, 'symbol');
    data[2].data = Utils.sortByDesc(data[2].data, 'symbol');

    data.forEach(d => {
      const cashNodes = d.data.filter(c => c.label.toLowerCase() === 'custodial cash'
                        || c.label.toLowerCase() === 'cash'
                        || c.label.toLowerCase() === '* cash *');
      if (!!cashNodes.length) {
        cashNodes.forEach(cashNode => {
          d.data.push(d.data.splice(d.data.indexOf(cashNode), 1)[0]);
        });
      }
    });
    return data;
  }

  /*methd used to  update/render the chart*/
  public renderChart(groupData) {
    const that = this;
    const data = this.sortModelNodes(groupData);

    that.targetValue = 0;
    that.xdomain = <any>that.CalculateXext(data);
    if (that.xdomain[0] > that.targetValue) {
      that.xdomain[0] = that.targetValue;
    }
    if (that.xdomain[1] < that.targetValue) {
      that.xdomain[1] = that.targetValue;
    }
    if (that.xdomain[0] > that.xdomain[1]) {
      that.xdomain = [that.xdomain[1], that.xdomain[0]];
    }
    d3.selectAll('.svgGElement').remove(); /*empty svg while updateing the chart when user click on legends*/

    if (that.xdomain && that.xdomain.length > 0) {
      that.svg.selectAll('.graph-title-portfolio-name').remove();
      that.svg.append('text')
        .attr('class', 'graph-title-portfolio-name fs-mask')
        .attr('transform', `translate(0,${that.marginTop})`)
        .text(function(d) { return data[0]['portfolioName']; });

      that.svgGroup = that.svg.append('g')
        .attr('class', 'svgGElement')
        .attr('transform', `translate(${that.marginLeft},${that.marginBottom})`);

      that.xScale.domain(that.xdomain);
      that.yScale.domain(data[0]['data'].map(function (d) { return d.symbol; }));

      that.xGroup = that.svgGroup.append('g')
        .attr('class', 'x-axis-bar-group')
        .attr('transform', `translate(${that.marginLeft},${that.yaxisLength})`)
        .call(that.xaxis);

      that.animateXaxis(); /*animate x axis*/

      /*append another y axis at left side of svg*/
      that.svgGroup.append('g')
        .attr('class', 'y-axis-bar-group-2')
        // eslint-disable-next-line prefer-template
        .attr('transform', 'translate(' + that.marginLeft + ',' + 0 + ')')
        .call(that.yaxis);

      d3.selectAll('.y-axis-bar-group text')
        .style('display', 'none');

      d3.selectAll('.y-axis-bar-group-2 line')
        .style('stroke', 'var(--table-text-secondary);')
        .style('stroke-width', '0.3px');

      d3.selectAll('.y-axis-bar-group-2 text')
        .style('font-size', '10px')
        .style('font-weight', 500);

      d3.selectAll('.y-axis-bar-group-2 text').each(function (d: string, i) {
        if (d.length > 8) {
          const remainingletter = d.slice(5);
          const lastlength = remainingletter.length;
          const displayName = d.slice(0, -(lastlength)) + '...';
          $(this).text(displayName);
        }
      })
        .on('mouseover', function (d, i, j) {
          that.gettingTextFromData(data[0].data, d);
          d3.select('.groupchart-axis-tooltip')
            .style('position', 'fixed')
            .style('text-align', 'center')
            .style('padding', '4px')
            .style('font', '12px')
            .style('font-weight', 'bold')
            .style('pointer-events', 'none')
            .style('background-color', 'var(--tooltip-background-base)')
            .style('color', 'var(--tooltip-text-primary)')
            .text(that.ticketName)
            .style('top', (d3.event['clientY'] - that.marginBottom + 10) + 'px')
            .style('left', (d3.event['clientX'] + (that.marginLeft / 2) - 5) + 'px')
            .style('opacity', '0')
            .transition()
            .duration(500)
            .style('opacity', '1');
        })
        .on('mouseout', function () {
          d3.select('.groupchart-axis-tooltip')
            .transition()
            .duration(500)
            .style('opacity', '0');
        });


      // condition for rotate x labels when lebels are more then 10
      d3.selectAll('.x-axis-bar-group text').each(function (d, i) {
        that.xLabelsArray.push(d);
      });

      if (that.xLabelsArray.length > 10) {
        d3.selectAll('.x-axis-bar-group text')
          .attr('transform', 'translate(-15,5) rotate(-45)');
      }
      that.plotrectangles(data);
    } else {
      // eslint-disable-next-line eqeqeq
      if (data[0]['data'].length == 0 && data[1]['data'].length == 0 && data[2]['data'].length == 0) {
        $('.group_error_message').show(); // show message when data is not available
      }

    }/*condition  for not to plot chart when all legends are clicked*/

  }

  /*method used to ploting the bars  to plot rectangles*/
  public plotrectangles(data) {
    for (let j = 0; j < data.length; j++) {
      const that = this;

      const FillColor = ['rgba(0, 170, 239, 0.1)', 'rgba(0, 170, 239, 0.1)', '#00AEEF'];
      const StrokeColor = ['#00AEEF', '#00AEEF', 'none'];

      const currentdata = data[j]['data'];
      const ClassName = data[j]['name'];
      const fillColor = FillColor[j];
      const strokeColor = StrokeColor[j];
      // let targetValue = data[j]['target']

      that.yScale.domain(data[j]['data'].map(function (d) { return d.label; }));

      if (data[j]['legendsShow']) {   /* not ploting bars if legend is checked*/

        const barGroup = that.svgGroup
          .append('g')
          .attr('class', 'barGroup' + j);

        const bars = barGroup.selectAll('.' + ClassName)
          .data(currentdata)
          .enter()
          .append('rect')
          .attr('class', ClassName)
          .attr('id', <any>function (d) {
            // eslint-disable-next-line prefer-template
            return ClassName + '_' + d['label'].replace(/[^A-Z0-9]/ig, '');
          })
          .attr('y', <any>function (d) {
            const offsety = 2;
            // eslint-disable-next-line eqeqeq
            if (d['series'] != 'Post_Trade') {
              return that.yScale(d.label) - offsety;
            } else {
              return that.yScale(d.label);
            }

          })
          .attr('height', function (d) {
            const offsetheight = 6;
            // eslint-disable-next-line eqeqeq
            if (d['series'] != 'Post_Trade') {
              return that.yScale.bandwidth() + offsetheight;
            } else {
              return that.yScale.bandwidth();
            }

          })
          .style('rx', 0)
          .style('ry', 0)
          .style('fill', function (d) {

            // eslint-disable-next-line eqeqeq
            if (d['series'] == 'Post_Trade') {

              // if (d['postTrade'] < d['lowerRange'] || d['postTrade'] > d['upperRange']) {
              // if ((d['postTrade'] < d['target'] - d['lower']) || (d['postTrade'] > d['target'] + d['upper'])) {
              if (d['outOfTolerance']) {
                return '#BF0B23';
              } else {
                return fillColor;
              }
            } else {
              return fillColor;
            }
          })
          .style('stroke', strokeColor)
          .style('stroke-width', 1)
          .on('mouseover', function (d, i) {
            that.mousemove(d, this);
          })
          .on('mouseout', function (d, i) {
            that.mouseout(d);
          })

          .attr('width', function (d) {
            return 0;
          })
          .attr('x', <any>function (d) {
            return that.xScale(that.targetValue) + that.marginLeft;
          })
          .transition()
          .duration(500)
          .attr('x', <any>function (d) {
            return that.marginLeft + that.xScale(Math.min(that.targetValue, d.value));
          })
          .attr('width', <any>function (d) {
            // Based on discussion with sairaj implemented the below logic
            /**
             * if lower = target and series = lower then we are not making the lower series bar to plot
             * if upper = target and series = upper then we are not making the upper series bar to plot
             * Finally we are making the values as target - lower and upper- target in data where we are
             * parsing the data into required format
             */

            // eslint-disable-next-line eqeqeq
            if (d['series'] == 'Lower') {
              // eslint-disable-next-line eqeqeq
              if (d['Lower'] == d['target']) {
                d['value'] = 0;
              }
            // eslint-disable-next-line eqeqeq
            } else if (d['series'] == 'Upper') {
              // eslint-disable-next-line eqeqeq
              if (d['Upper'] == d['target']) {
                d['value'] = 0;
              }
            }
            return Math.abs(that.xScale(d.value) - that.xScale(that.targetValue));
          });


        /* append ghost bars for show tooltip on svg*/
        // let ghostBars = barGroup.selectAll('.ghostBars' + ClassName)
        //     .data(currentdata)
        //     .enter()
        //     .append('rect')
        //     .attr('class', 'ghostBars' + ClassName)
        //     .attr('x', <any>function (d) {
        //         if (d.value <= that.targetValue) {
        //             return that.marginLeft
        //         } else {
        //             return that.marginLeft + that.xScale(Math.min(that.targetValue, d.value))
        //         }
        //     })
        //     .attr('y', <any>function (d) {
        //         return that.yScale(d.label)
        //     })
        //     .attr('height', that.yScale.rangeBand() + 5)
        //     .attr('rx', 0)
        //     .attr('ry', 0)
        //     .style('fill', 'transparent')
        //     .style('stroke', 'none')
        //     .attr('width', <any>function (d) {
        //         var rectwidth;
        //         if (d['value'] > that.targetValue) {
        //             rectwidth = that.xScale(that.xdomain[1]) - that.xScale(that.targetValue);
        //         } else {
        //             rectwidth = that.xScale(d['value']) + Math.abs(that.xScale(d.value) - that.xScale(that.targetValue));
        //             if (rectwidth == 0) {
        //                 rectwidth = that.xScale(that.xdomain[1])
        //             }
        //         }
        //         return Math.abs(rectwidth);
        //     })
        // .on('mouseover', function (d, i) {
        //     that.mousemove(d, this)
        // })
        // .on('mouseout', function (d, i) {
        //     that.mouseout(d);
        // })
      }
    }
  }

  /*method used to show tooltip on mousemove*/
  public mousemove(d, currentThis) {
    const that = this;
    const padding = 120;
    const paddinleft = 780;
    let left;
    let top;

    that.tooltip_trade = d3.select('.group_column_chart')
      .insert('div', ':first-child')
      .attr('class', 'tooltip_trade')
      .style('opacity', 0);

    left = that.xScale(d['value']) / 2 + that.marginRight + that.marginLeft;
    // eslint-disable-next-line eqeqeq
    if (d['series'] == 'Post_Trade') {
      if (d['outOfTolerance']) {
        // eslint-disable-next-line prefer-template
        d3.select('#' + d['series'] + '_' + d['label'].replace(/[^A-Z0-9]/ig, '')).style('fill', 'rgba(216,36,60,1)');
      } else {
        // eslint-disable-next-line prefer-template
        d3.select('#' + d['series'] + '_' + d['label'].replace(/[^A-Z0-9]/ig, '')).style('fill', '#19C7FF');
      }
      // eslint-disable-next-line prefer-template
      d3.select('#' + d['series'] + '_' + d['label'].replace(/[^A-Z0-9]/ig, '')).style('stroke', '#00AEEF');
    }

    top = d3.event['pageY'] - padding;
    let Value = d['postTrade'];
    Value = parseFloat(Value).toFixed(2);
    that.tooltip_trade
      .style('top', top + 'px')
      .style('left', left + 'px')
      .style('pointer-events', 'none')
      .style('position', 'absolute')
      .html(`<b>${d['symbol']}</b><br>Target: ${d['target'].toFixed(2)}%<br>Range: ${d['lowerRange'].toFixed(2)} - ${d['upperRange'].toFixed(2)}%<br>Post Trade: ${Value}%`)
      .style('opacity', 0)
      .transition(500)
      .style('opacity', 1);
  }

  /*method used  to hide tooltip on mouseout*/
  public mouseout(d) {
    const that = this;
    // eslint-disable-next-line eqeqeq
    if (d['series'] == 'Post_Trade') {
      if (d['outOfTolerance']) {
        // eslint-disable-next-line prefer-template
        d3.select('#' + d['series'] + '_' + d['label'].replace(/[^A-Z0-9]/ig, '')).style('fill', '#BF0B23');
      } else {
        // eslint-disable-next-line prefer-template
        d3.select('#' + d['series'] + '_' + d['label'].replace(/[^A-Z0-9]/ig, '')).style('fill', '#00AEEF');
      }
      // eslint-disable-next-line prefer-template
      d3.select('#' + d['series'] + '_' + d['label'].replace(/[^A-Z0-9]/ig, '')).style('stroke', 'none');
    }
    that.tooltip_trade.style('opacity', 0);
    that.tooltip_trade.remove();
  }

  /* method used to calculate xdomain value*/
  public CalculateXext(data) {
    let max, min;
    const maxArray = [];
    const minArray = [];
    data.forEach(val => {
      // eslint-disable-next-line eqeqeq
      if (val.legendsShow == true) {
        max = d3.max(val['data'], function (d) {
          return d['value'];
        });
        maxArray.push(max);
        min = d3.min(val['data'], function (d) {
          return d['value'];
        });
        minArray.push(min);
      }
    });
    const xextMin = d3.min(minArray);
    const xextMax = d3.max(maxArray);
    let ext;
    if (xextMin < -(xextMax)) {
      ext = [xextMin, Math.abs(xextMin)];
    } else {
      ext = [-(xextMax), xextMax];
    }
    // eslint-disable-next-line eqeqeq
    if (xextMin == undefined && xextMax == undefined) {
      return [];
    } else {
      return ext;
    }

  }// ext

  /*method used to animation of of x axis*/
  public animateXaxis() {
    const that = this;
    that.xGroup.transition().duration(100).tween('axis', <any>function (d, i) {
      // eslint-disable-next-line eqeqeq
      if (that.previousXdomain[0] == that.xdomain[0] && that.previousXdomain[1] == that.xdomain[1]) {
        that.previousXdomain = [that.xdomain[0] - 10, that.xdomain[1] + 10];
      }
      // eslint-disable-next-line no-var, prefer-const
      var i: any = d3.interpolateArray(that.previousXdomain, that.xdomain);
      return function (t) {
        that.xScale.domain(i(t));
        that.xGroup.call(that.xaxis);
        d3.selectAll('.x-axis-bar-group line')
          .style('stroke-width', 1)
          .style('stroke', '#c8c8c8');

        // eslint-disable-next-line no-shadow
        d3.selectAll('.x-axis-bar-group text').each(function (d, i) {
          // eslint-disable-next-line eqeqeq
          if (d == that.targetValue) {
            $(this).text('Target');
          }
        });
        d3.selectAll('.x-axis-bar-group text')
          .style('font-size', '11px')
          .style('font-weight', 500);
        // condition for rotate x labels when lebels are more then 10
        if (that.xLabelsArray.length > 10) {
          d3.selectAll('.x-axis-bar-group text')
            .attr('transform', 'translate(-15,5) rotate(-45)');
        }
      };
    });
  }// animateXaxis

  /* method used to function for plot legends*/
  public renderLegend(groupData) {
    const that = this;
    const Legendcolor = ['#E6F7FE', '#E6F7FE', '#00AEEF'];
    // append legends to chaart
    that.LegendsGroup = that.svg.selectAll('legendsGroup')
      .data(groupData)
      .enter()
      .append('g')
      .attr('class', 'legendsGroup legendsText');
    const length = groupData.length;
    that.LegendsGroup.append('rect')
      .attr('stroke', 'var(--table-text-primary)')
      .attr('class', 'legends')
      .attr('height', 15)
      .attr('width', 15)
      .attr('fill', <any>function (d, i, j) {
        return Legendcolor[i];
      })
      .attr('x', <any>function (d, i) {
        return (that.xaxisLength / length) + (i * 100);
      })
      .attr('y', that.yaxisLength + (2 * that.marginBottom));

    that.LegendsGroup.append('text')
      .attr('class', <any>function (d, i) {
        return d['name'];
      })
      .attr('x', <any>function (d, i) {
        return that.xaxisLength / length + i * 100 + that.marginRight;
      })
      .attr('y', that.yaxisLength + 2 * that.marginBottom + that.marginTop / 2)
      .style('font-weight', 'bold')
      .text(<any>function (d, i) {
        let displaytext;
        // eslint-disable-next-line eqeqeq
        if (d['name'] == 'Post_Trade') {
          displaytext = d['name'].replace('_', ' ');
        } else {
          displaytext = d['name'];
        }

        return displaytext;
      });

    that.tempData = JSON.parse(JSON.stringify(groupData)); // save data in a temparory Array
    // click on legend
    that.LegendsGroup.on('click', function (d, i) {
      that.clickOnLegends(this);
    });

  } // renderlegend

  /* method used  to toggles columns when user click on legends*/
  public clickOnLegends(currentEle) {
    const that = this;
    that.xLabelsArray = [];
    if ($(currentEle).hasClass('legendsText')) {
      $(currentEle).removeClass('legendsText');
      $(currentEle).addClass('legendClickText');
      $(currentEle).find('rect').addClass('legendClickrect');
      const classNameText = $(currentEle).find('text').attr('class');
      that.previousXdomain = <any>that.CalculateXext(that.tempData);
      that.tempData.forEach(el => {
        // eslint-disable-next-line eqeqeq
        if (el && (el.name == classNameText)) {
          el.legendsShow = false;
        }
      });
      that.renderChart(that.tempData);
    } else {
      $(currentEle).addClass('legendsText');
      $(currentEle).removeClass('legendClickText');
      $(currentEle).find('rect').removeClass('legendClickrect');
      const classNameText = $(currentEle).find('text').attr('class');
      that.previousXdomain = <any>that.CalculateXext(that.tempData);
      that.tempData.forEach(el => {
        // eslint-disable-next-line eqeqeq
        if (el && (el.name == classNameText)) {
          el.legendsShow = true;
        }
      });
      that.renderChart(that.tempData);
    }

  }
  gettingTextFromData(data, ticker) {
    data.forEach(element => {
      // eslint-disable-next-line eqeqeq
      if (element.symbol == ticker) {
        this.ticketName = element.label;
        return false;
      }
    });
  }

}
