import { Component, ViewChild } from '@angular/core';
import {
  IToolPanel,
} from '@ag-grid-community/core';
import { QuickTradeComponent } from '../../components/tradeorder/shared/quicktrade.component';

@Component({
  selector: 'eclipse-quick-trade-tool-panel',
  templateUrl: './quick-trade-tool-panel.component.html',
})
export class QuickTradeToolPanel implements IToolPanel {
  @ViewChild('quicktrade') quickTrade: QuickTradeComponent;

  currentTab: 'quickTrade' | 'fileImport' = 'quickTrade';
  params;

  agInit(params): void {
    this.params = params;
  }

  refresh() {
    // unused in this implementation, but required by IToolPanel.
  }
}
