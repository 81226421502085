<h4 class="text-info">
  Which method do you want to use for selecting accounts to trade</h4>
<div class="selection-content-container">
  <div class="row">
    <div class="col-sm-4 ">
      <div class="content-box-left">
        <div class="margin-top-20" *ngIf="showCalculateAtSleevePf && !runFromTactical">
          <p-checkbox [binary]="true" [trueValue]="1" [falseValue]="0" [(ngModel)]="isCalculateAtSleevePf" (onChange)="onChkChange($event)" name="" label="Calculate at Sleeve Portfolio (Include All Sleeves in Portfolio)" aria-label="Calculate at Sleeve Portfolio (Include All Sleeves in Portfolio)"></p-checkbox>
        </div>
        <div class="form-group" *ngIf="isRaiseCash || (isSpendCash && !isCalculateAtSleevePf)">
          <select class="form-control" [(ngModel)]="selectedSleeveAccountMethod" (change)="changeSleeveAccountMethod($event)">
            <option *ngFor="let sleeveAccountMethod of sleeveAccountMethods" [ngValue]="sleeveAccountMethod.value">{{sleeveAccountMethod.name}}</option>
          </select>
        </div>
        <div class="margin-top-10" [hidden]="!(isSpendCash && (isCalculateAtSleevePf || selectedSleeveAccountMethod === 'ContributionSleeve'))" id='isProtectAsideCash'>
          <input type="checkbox" [(ngModel)]="isProtectSetAsideInCont" name=""> Protect Set Aside Cash in Contribution Sleeve
        </div>

        <div class="form-group" *ngIf="isTaxHarvest">
          <p class="selection-Header"><strong>Select Harvest Type:</strong></p>
          <select [(ngModel)]="harvestType" class="form-control" name="tlhFiltersGainLoss">
            <option *ngFor="let ht of harvestTypeData" [ngValue]="ht.id">{{ht.name}}</option>
          </select>
        </div>

        <span *ngIf="!runFromTactical">
          <div class="col-sm-12  margin-top-20 marging-bottom-2x">
            <p class="selection-Header"><strong>Selection Method</strong></p>
          </div>
          <div class="form-group-radio" *ngIf="showPortfolio">
            <div class="radio " data-rel="portfolioContent" *ngIf="showPortfolio"> <label><input type="radio" name="optradio" [checked]="portfolio == 1" (click)="radioBtnChange(1, 'portfolio')"><span class="circle"></span><span class="check"></span>Portfolio</label></div>
          </div>
          <div class="form-group-radio" *ngIf="showSleevePortfolio">
            <div class="radio " data-rel="sleevePortfolioContent" *ngIf="showSleevePortfolio"> <label><input type="radio" name="optradio" [checked]="sleevePortfolio === 1" (click)="radioBtnChange(1, 'sleevePortfolio')"><span class="circle"></span><span class="check"></span>
              Sleeve Portfolio</label></div>
          </div>
          <div class="form-group-radio" *ngIf="showAccounts">
            <div class="radio" data-rel="accountContent" *ngIf="showAccounts"> <label><input type="radio" name="optradio" [checked]="account == 1" (click)="radioBtnChange(1, 'account')"><span class="circle"></span><span class="check"></span>Account</label></div>
          </div>
          <div class="form-group-radio" *ngIf="showModel">
            <div class="radio" data-rel="modelContent" *ngIf="showModel"> <label><input type="radio" name="optradio" [checked]="model == 1" (click)="radioBtnChange(1, 'model')"><span class="circle"></span><span class="check"></span>Model</label></div>
          </div>
          <div class="form-group-radio" *ngIf="showSleeves">
            <div class="radio" data-rel="sleeveContent" *ngIf="showSleeves"> <label><input type="radio" name="optradio" [checked]="sleeves == 1" (click)="radioBtnChange(1, 'sleeves')"><span class="circle"></span><span class="check"></span>Sleeves</label></div>
          </div>
          <div class="form-group-radio" *ngIf="!isLiquidate && (showtradeGroups || ((isRaiseCash || isSpendCash) && !isCalculateAtSleevePf))">
            <div class="radio" data-rel="tradeGroupsContent" *ngIf="showtradeGroups || ((isRaiseCash || isSpendCash) && !isCalculateAtSleevePf)">
              <label *ngIf="showAccounts"><input type="radio" name="optradio" [checked]="tradeGroupsForAccount == 1" (click)="radioBtnChange(1, 'tradeGroupsForAccount')"><span class="circle"></span>
                <span class="check"></span>Account Trade Group</label>
              <label *ngIf="showPortfolio"><input type="radio" name="optradio" [checked]="tradeGroupsForPortfolio == 1" (click)="radioBtnChange(1, 'tradeGroupsForPortfolio')"><span class="circle"></span>
                <span class="check"></span>Portfolio Trade Group</label>
            </div>
          </div>
          <div class="form-group-radio" *ngIf="showtradeGroups && tradeGroupTypeId === 0 && isLiquidate">
            <div class="radio" data-rel="tradeGroupsContent">
              <label>
                <input type="radio" name="optradio" (click)="radioBtnChange(1, 'tradeGroupsForAccount')">
                <span class="circle"></span>
                <span class="check"></span>
                Trade Group
              </label>
            </div>
          </div>
          <div class="form-group-radio" *ngIf="showExcelImport">
            <div class="radio" data-rel="excelImportContent" *ngIf="showExcelImport"> <label><input type="radio" name="optradio" [checked]="excelImport == 1" (click)="radioBtnChange(1, 'excelImport')"><span class="circle"></span><span class="check"></span>Excel Import</label></div>
          </div>
          <div class="form-group-radio" *ngIf="showExcelImportPortfolio">
            <div class="radio" data-rel="excelImportForPortfolioContent" *ngIf="showExcelImportPortfolio"> <label><input type="radio" name="optradio" [checked]="excelImportForPf == 1" (click)="radioBtnChange(1, 'excelImportForPf')"><span class="circle"></span>
              <span class="check"></span>Excel Import Portfolio</label></div>
          </div>
          <div class="form-group-radio" *ngIf="showExcelImportSleeves">
            <div class="radio" data-rel="excelImportForSleevesContent" *ngIf="showExcelImportSleeves"> <label><input type="radio" name="optradio" [checked]="excelImportForSlvs == 1" (click)="radioBtnChange(1, 'excelImportForSlvs')"><span class="circle"></span><span class="check"></span>
              Excel Import Sleeves</label></div>
          </div>
          <div class="form-group-radio" *ngIf="isRaiseCash">
            <div class="radio" data-rel="importBySecurity"> <label><input type="radio" name="optImportBySecurity" (click)="goToImportBySecurity()"><span class="circle"></span><span class="check"></span>Import by Security</label></div>
          </div>
        </span>
      </div>
    </div>
    <div class="col-sm-8">
      <div class="content-container">
        <div *ngIf="tradeFilterMethod == 'portfolio' ||
                                tradeFilterMethod == 'sleevePortfolio' ||
                                tradeFilterMethod == 'account' ||
                                tradeFilterMethod == 'model' ||
                                tradeFilterMethod == 'sleeves' ||
                                tradeFilterMethod == 'tradeGroupsForPortfolio' ||
                                tradeFilterMethod == 'tradeGroups' ||
                                tradeFilterMethod == 'tradeGroupsForAccount'">
          <div [ngClass]="showAmountInput ? 'trade-search1': 'trade-search'">
            <div class="row" *ngIf="!runFromTactical">
              <div class="col-sm-6">
                <div class="form-group is-empty">
                  <div class="icon-addon addon-md">
                    <div *ngIf="tradeFilterMethod == 'portfolio'">
                      <eclipse-portfolio-auto-complete (parentCallback)="getSelectedItem($event)"
                                                       [excludeDoNotTrade]="true" [List]="List"></eclipse-portfolio-auto-complete>
                    </div>
                    <div *ngIf="showPortfolioAutocomplete()">
                      <eclipse-sleeve-portfolio-auto-complete (parentCallback)="getSelectedItem($event)" [list]="List" [excludeDoNotTrade]="true"></eclipse-sleeve-portfolio-auto-complete>
                      <div > <a *ngIf="showContributionsButton()"  (click)="addSleeveContributions()" >Add All Portfolios With Value In Contribution Sleeve</a></div>
                    </div>
                    <div *ngIf="tradeFilterMethod == 'account'">
                      <eclipse-account-auto-complete (parentCallback)="getSelectedItem($event)" [isSpendCash]="isSpendCash || isRaiseCash"
                                                     [excludeDoNotTrade]="true" [List]="List"></eclipse-account-auto-complete>
                      <div><a *ngIf="isTaxHarvest" (click)="getAllTaxableAccounts()" >Add All Taxable Accounts</a></div>
                    </div>
                    <div *ngIf="tradeFilterMethod == 'model'">
                      <eclipse-model-auto-complete (parentCallback)="getSelectedItem($event)" [List]="List"></eclipse-model-auto-complete>
                    </div>
                    <div *ngIf="showSleeveAutocomplete()">
                      <eclipse-sleeves-auto-complete (parentCallback)="getSelectedItem($event)"></eclipse-sleeves-auto-complete>
                    </div>
                    <div *ngIf="tradeFilterMethod == 'tradeGroupsForPortfolio'">
                      <eclipse-trade-groups-auto-complete [tradeGroupTypeId]="tradeGroupTypeId" (parentCallback)="getSelectedItem($event)"></eclipse-trade-groups-auto-complete>
                      <br>
                      <em class="securityError danger" *ngIf="(isRaiseCash || isSpendCash) && !isCalculateAtSleevePf" >{{tradeGroupsErrorMsg}}</em>
                      <em class="securityError danger" *ngIf="!(isRaiseCash || isSpendCash)" >{{tradeGroupsErrorMsg}}</em>
                    </div>
                    <div *ngIf="tradeFilterMethod == 'tradeGroupsForAccount'">
                      <eclipse-trade-groups-auto-complete [tradeGroupTypeId]="tradeGroupTypeId" (parentCallback)="getSelectedItem($event)"></eclipse-trade-groups-auto-complete>
                      <br>
                      <em class="securityError danger" *ngIf="(isRaiseCash || isSpendCash) && !isCalculateAtSleevePf" >{{tradeGroupsErrorMsg}}</em>
                      <em class="securityError danger" *ngIf="!(isRaiseCash || isSpendCash)" >{{tradeGroupsErrorMsg}}</em>
                    </div>
                  </div>
                  <span class="material-input"></span></div>
              </div>
              <div class="col-sm-3" *ngIf="showAmountInput">
                <div class="form-group">
                  <input type="text" [(ngModel)]="enteredAmount" placeholder="Enter Amount" class="form-control" (keyup)="validateAmount($event)"
                         (keypress)="checkAmount($event, enteredAmount)">
                </div>
              </div>
              <div class="col-sm-1"> <button type="button" pButton [disabled]="disableAddBtn" (click)="addItem()" label="Add"></button></div>

            </div>
            <div class="row">
              <div class="col-sm-12 tradetool-analytics-status">
                <eclipse-trade-tool-analytic-analyzer (tradeToolCallBack)="updateAnalyticsStatus($event)"
                                                      (updateTLHOpportunityFlag)="setRowData()"
                                                      [isTaxHarvest]="isTaxHarvest"></eclipse-trade-tool-analytic-analyzer>
                <div *ngIf="showAnalyticsFailedWarning && isTaxHarvest" class="analytics-warning-message">Trades will be generated for only those where analytics is good</div>
              </div>
            </div>
          </div>

          <div class="agcontainer">
            <ag-grid-angular #agGrid style="width: 100%; height: 350px;" class="ag-theme-alpine"
                             [gridOptions]="tradesFilterGridOptions" rowSelection="single" [columnDefs]="tradesFilterColumnDefs"
                             [rowData]="List" suppressContextMenu="true" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
          </div>
          <p class="text-info" *ngIf="isRaiseCash || isSpendCash"><i class="far fa-lightbulb" aria-hidden="true"></i>
            <span> Each record should include the cash amount you want to {{isRaiseCash?'raise':'spend'}} </span>
          </p>
          <p class="text-info" *ngIf="showContributionAmountWarning"><i class="far fa-lightbulb" aria-hidden="true"></i>
            <span> Contribution sleeve has no excess cash to spent </span>
          </p>
        </div>

        <div class="tradetool-import-analytics-status excel-import-content" *ngIf="tradeFilterMethod == 'excelImport' ||
                                tradeFilterMethod == 'excelImportForPf' ||
                                tradeFilterMethod == 'excelImportForSlvs'"
             id="excelImportContent || excelImportForPortfolioContent || excelImportForSleevesContent">
          <h4>Which accounts should be included in this trade?</h4>
          <eclipse-trade-tool-analytic-analyzer (tradeToolCallBack)="updateAnalyticsStatus($event)"
                                                (updateTLHOpportunityFlag)="setRowData()"
                                                [isTaxHarvest]="isTaxHarvest"></eclipse-trade-tool-analytic-analyzer>
          <div *ngIf="showAnalyticsFailedWarning && isTaxHarvest" class="analytics-warning-message">Trades will be generated for only those where analytics is good</div>
          <div class="row">
            <div *ngIf="checkUploadFile">
              <div class="col-md-6">
                <div class="form-group" (dragover)="dragFile($event)" (drop)="dropFile($event)">
                  <div class="choose-file-small-box file-upload-input-style">
                    <span pButton class="p-button-sm"> Choose File
                      <input type="file" name="model" value="" id="model-file"
                             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv"
                             (click)="clickOnChooseFile($event)" (change)="selectedTemplate($event)">
                    </span>
                    <br> or drag and drop here
                    <div>
                      <p *ngIf="checkDragFile">{{dragFileName}}</p>
                      <p *ngIf="!checkDragFile">{{file.name}}</p>
                    </div>
                  </div>
                </div>
                <p class="text-info"><i class="far fa-lightbulb" aria-hidden="true"></i> A word about file formats...</p>
                <p *ngIf="!isCashNeed && (isRaiseCash || isSpendCash)">The imported file should include an “Account ID” or an “Account Number”{{isCalculateAtSleevePf ? ' or “Portfolio ID”':''}}, and “Amount” columns.</p>
                <p *ngIf="!isLiquidate && !isCashNeed && !(isRaiseCash || isSpendCash)">The imported file should include an “Account ID” or an “Account Number” column.</p>
                <p *ngIf="isCashNeed">The imported file should include an “Portfolio ID” column.</p>
                <p *ngIf="isLiquidate">The file should include “Account ID” or “Account Number” or “Portfolio ID” columns.</p>
                <p>The file’s extension can be xlsx or csv.</p>
                <p>Invalid accounts will be highlighted <span class="text-danger">red</span> and discarded during the {{isLiquidate ? 'trade creation' : 'calculation' }}.</p>
              </div>
              </div>
              <div class="col-md-6">
                <em class="securityError danger" *ngIf="showFileUploadError">{{fileUploadError}}</em>
                <span class="securityError danger" *ngIf="isNotValidFile">Please choose valid file</span>
                <div *ngIf="!isNotValidFile && uploadResults?.records?.length">
                  <ag-grid-angular #agImportGrid style="width: 100%; height: 350px;" class="ag-theme-alpine" [context]="fileImportContext"
                                   [gridOptions]="fileImportGridOptions" rowSelection="single" [columnDefs]="fileImportColumnDefs"
                                   (cellClicked)="onImportCellClicked($event)"
                                   [rowData]="uploadResults.records" suppressContextMenu="true" (gridReady)="onFileImportGridReady($event)">
                  </ag-grid-angular>
                </div>
              </div>
            </div>
            <div *ngIf="modelErrors && !checkUploadFile">
              <div class="choose-file-small-box file-upload-input-style import-log">
                <i class="fas fa-exclamation-triangle" aria-hidden="true"></i> There is something wrong please
                check log.
              </div>
              <p><strong>Import Log</strong></p>
              <div class="import-log-box">
                <p *ngFor="let error of errorLog">{{error.message}}</p>
              </div>
            </div>
            <div class="choose-file-small-box file-upload-input-style file-successfully-imported" *ngIf="!modelErrors && !checkUploadFile">
              <i class="fas fa-check-circle" aria-hidden="true"></i> File is successfully imported
            </div>
          </div>
          <ng-template pTemplate="footer">
            <button type="button" pButton icon="fas fa-check" (click)="modelImport()" *ngIf="modelErrors" label="Try Again"></button>
          </ng-template>
      </div>
    </div>
  </div>
  <p-dialog header="" [(visible)]="displayConfirm" modal="true" [closable]="false" [draggable]=false [resizable]=false class="ui-dialog-md">
    <div>All portfolios not sleeved will be removed from the list. Are you sure you want to continue?</div>
    <p-footer>
      <button type="button" pButton class="p-button-secondary"  icon="fas fa-times" (click)="confirmNo()" label="No"></button>
      <button type="button" pButton icon="fas fa-check" (click)="confirmYes()" label="Yes"></button>
    </p-footer>
  </p-dialog>
</div>
