import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import {OEHttpClient} from '../core';
import { ITradesCount, ITradeCountsPoll, ITradeCounter } from '../models/trade';
import { SessionHelper } from '../core';
import { PreferenceService } from './preference.service';
import { takeUntil } from 'rxjs/operators';
import {Utils} from '../core/functions';
import { SplitIoService } from '../core/feature-flag/splitio.service';

@Injectable({
  providedIn: 'root'
})
export class TradeService {
  private _tradeEndPoint = 'v1/tradeorder/trades';
  private _tradesCountEndpoint = 'v2/tradeorder/trades/count';
  private _tomDashboardEndPoint = 'v1/dashboard/tradeorder/summary';
  private _tomReports = 'v1/report/trades';
  private _tomBlocksReport = 'v1/report/blocks';
  private _tomAllocationReport = 'v1/report/allocations';

  private _sessionHelper: SessionHelper;
  private _stopTradesCountTimer$ = new Subject<void>();
  private _isTradeCountsPolled = true;
  private _isAwaitingTradeCounts = false;
  private _isTradeCountsFetchTooSoon = false;

  readonly tradesCountUpdated = new EventEmitter();
  public static TRADE_COUNT_DELTA_TIMEOUT = 3000;

  constructor(
    private _httpClient: OEHttpClient,
    private preferenceService: PreferenceService,
    private sessionHelper: SessionHelper = undefined
  ) {
    this._sessionHelper = sessionHelper || new SessionHelper();
    const user = this._sessionHelper.getUser();
  }

  getTradeListByPortfolio(portfolioId, tradeInstanceId) {
    let url = `${this._tradeEndPoint}/tradeListByPortfolio/${portfolioId}`;
    if (tradeInstanceId != null) {
      url = `${url}?tradeInstanceId=${tradeInstanceId}`;
    }
    return this._httpClient.getData(url);
  }

  getTradeOrderDashboardData() {
    return this._httpClient.getData(this._tomDashboardEndPoint);
  }
  /**get trade analysis report info */
  getTradeAnalysisReport(tradeIds, reportData) {
    return this._httpClient.postData(`${this._tomReports}/analysis?level1=${reportData.groupingLevels.level1}&level2=${reportData.groupingLevels.level2}
    &includeExcludedHolding=${reportData.includeExcludedHolding}&showMaskAccountNumber=${reportData.showMaskAccountNumber}`, { tradeIds });
  }

  /**get block summary report info */
  getTradeBlockSummaryReport(blockId) {
    return this._httpClient.getData(`${this._tomBlocksReport}/blockSummary?blockId=${blockId}`);
  }

  /**get trade by account report info */
  getTradeByAccountsReport(tradeByAccRunType, accountId, startDate, endDate): Observable<any> {
    if (tradeByAccRunType !== 'ALL') {
      return this._httpClient.getData(`${this._tomReports}/reportByAccounts?isAllAccount=false&accountIds=${accountId}&startDate=${startDate}&endDate=${endDate}`);
    } else {
      return this._httpClient.getData(`${this._tomReports}/reportByAccounts?isAllAccount=true&accountIds=''&startDate=${startDate}&endDate=${endDate}`);
    }
  }

  /**get trades by broker report info */
  getTradesByBrokerReport(startDate: string, endDate: string) {
    return this._httpClient.getData(`${this._tomBlocksReport}/blockBybroker?startDate=${startDate}&endDate=${endDate}`);
  }
  /**get allocation summary report info */
  getAllocationReport(blockIds, startDate: string, endDate: string) {
    if (blockIds !== '') {
      return this._httpClient.getData(`${this._tomAllocationReport}?blockIds=${blockIds}`);
    } else {
      return this._httpClient.getData(`${this._tomAllocationReport}?startDate=${startDate  }&endDate=${endDate}`);
    }
  }

  setTradeCountsVisible(visible: boolean) {
    const tradeCountsPoll = this.getTradeCountsPoll();
    tradeCountsPoll.isTradeCountsVisible = visible;
    this._sessionHelper.set('tradeCountsPoll', tradeCountsPoll);
    const isPollingTradeCounts = tradeCountsPoll.isTradeCountsVisible && this._isTradeCountsPolled;
    this.updateTradeCountsPoll(isPollingTradeCounts);
  }

  /** Fetch trade counts unless less than 2 seconds have passed since last fetch. This handles
   *  behavior observed in IMTE ticket 99923 where hovering over trade menu when sidebar was collapsed
   *  led to mouseover event being called many times per second, and mouseover of trades menu will
   *  call this tryFetchTradeCounts() method as of ticket 99923. */
  tryFetchTradeCounts() {
    if (!this._isTradeCountsFetchTooSoon && this._isTradeCountsPolled) {
      this._isTradeCountsFetchTooSoon = true;
      this.fetchTradeCounts();
      const TWO_SECONDS = 2000;
      setTimeout(() => this._isTradeCountsFetchTooSoon = false, TWO_SECONDS);
    }
  }

  private async fetchTradeCounts() {
    const showProgress = false;
    const tradeCounts = <ITradesCount>await this._httpClient.getData(`${this._tradesCountEndpoint}`, showProgress).toPromise();
    const tradeCountsPoll = this.getTradeCountsPoll();
    const lastTradeCounts = tradeCountsPoll.lastTradeCounts;
    tradeCountsPoll.lastTradeCounts = tradeCounts;
    this._sessionHelper.set('tradeCountsPoll', tradeCountsPoll);
    let isCountChanged = !lastTradeCounts;

    const tradeCounters = Object.keys(tradeCounts).reduce((counters, countName) => {
      const updatedTradeCounts = <ITradeCounter>{total: tradeCounts[countName]};
      if (lastTradeCounts && lastTradeCounts[countName] !== tradeCounts[countName]) {
        isCountChanged = true;
        const increment = updatedTradeCounts.total - lastTradeCounts[countName];
        updatedTradeCounts.delta = {
          isDown: increment < 0,
          count: increment
        };
        setTimeout(() => updatedTradeCounts.delta = null, TradeService.TRADE_COUNT_DELTA_TIMEOUT);
      }
      counters[countName] = updatedTradeCounts;
      return counters;
    }, {});

    if (isCountChanged) {
      this.tradesCountUpdated.emit(tradeCounters);
    }
  }

  private getTradeCountsPoll(): ITradeCountsPoll {
    return this._sessionHelper.get<ITradeCountsPoll>('tradeCountsPoll') ?? {isTradeCountsVisible: false, lastTradeCounts: null};
  }

  private updateTradeCountsPoll(isPollingTradeCounts: boolean) {
    this._stopTradesCountTimer$.next();
    if (isPollingTradeCounts) {
      this.pollTradeCounts();
    }
  }

  private pollTradeCounts() {
    const FIVE_SECONDS = 5000;
    timer(0, FIVE_SECONDS).pipe(takeUntil(this._stopTradesCountTimer$)).subscribe(async () => {
      if (!this.sessionHelper.getUser()) {
        this._stopTradesCountTimer$.next();
        this._isAwaitingTradeCounts = false;
      } else if (!this._isAwaitingTradeCounts) {
        this._isAwaitingTradeCounts = true;
        await this.fetchTradeCounts();
        this._isAwaitingTradeCounts = false;
      }
    });
  }
}
