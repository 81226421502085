import {TradeGroupsType} from '../../../libs/tom.enuns';
import {Messages} from '../../../libs/app.constants';
import {RaiseCashComponent} from '../newraisecash/raisecash.component';
import {SpendCashComponent} from '../spendcash/spendcash.component';
import {TradeFilterMethod} from '../../../libs/rebalancer.constants';
import {ITradeFilterCallbackEvent} from '../../../models/tradetools';

export class TradeToolHelper {
  static updateWithoutEmphasisTabData(params: any, self: RaiseCashComponent | SpendCashComponent): void {
    if (!self.selectedModelData.isCalculateAtSleevePf) {
      const nonSleeveAccountsWithSamePortfolio = self.withoutPreEmphasisedAccounts.filter(item => item.portfolioId === params.data.portfolioId && !item.isSleeve);
      if (nonSleeveAccountsWithSamePortfolio.length > 0) {
        const newValue = params.data[params.colDef.field];
        const subModelId = params.data.subModelId;
        nonSleeveAccountsWithSamePortfolio.forEach(account => {
          account[params.colDef.field] = newValue;
          account.subModelId = subModelId;
        });
      }
    }
  }

  static updateWithEmphasisTabData(params: any, self: RaiseCashComponent | SpendCashComponent): void {
    const updatedNode = self.updatedpreselectedRecords.filter(t => t.id === params.data.id && t.portfolioId === params.data.portfolioId)[0];
    const newValue = params.data[params.colDef.field];
    const subModelId = params.data.subModelId;
    if (updatedNode) {
      self.updatedpreselectedRecords.forEach(item => {
        if (item.portfolioId === params.data.portfolioId) {
          item[params.colDef.field] = newValue;
          item.subModelId = subModelId;
        }
      });
    } else if (!self.selectedModelData.isCalculateAtSleevePf) {
      self.withPreEmphasisedAccounts.forEach(account => {
        if (account.portfolioId === params.data.portfolioId && !account.isSleeve) {
          account[params.colDef.field] = newValue;
          account.subModelId = subModelId;
          self.updatedpreselectedRecords.push(account);
        }
      });
    }
  }

  static setWarningMsgToLiquidateSecurities(self: any, filter: any): void {
    if (!filter.liquidateEquivalents && !filter.liquidateGroupEquivalents) {
      self.hasLiquidateSecuritiesWarning = false;
    } else {
      self.hasLiquidateSecuritiesWarning = true;
      self.liquidateSecuritiesWarnMsg = Messages.liquidateSecuritiesWarningMessage;
    }
  }

  static validateSleeveTypeForFileImport(list: any): void {
    list.forEach(element => {
      if (element.includeSleeveTypeForRegistrationRebalance) {
        element.isValid = true;
        element.errorMessage = null;
      }
    });
  }

  static setTradeFilterData(event: ITradeFilterCallbackEvent, component: SpendCashComponent | RaiseCashComponent): void {
    const isCalculateAtSleevePortfolio = component.tradeOrderFilterComponent?.isCalculateAtSleevePf;
    if (event.haveValidSleeveAccount) {
      component.haveValidSleeveAccount = event.value;
    } else {
      component.disableFilterNextBtn = event.value;
    }
    // Fixed issue if all trades are invalid then disable next button.
    if (event.isAllInvalid) {
      component.isAllRecordsInvalid = event.value;
    }
    if (isCalculateAtSleevePortfolio) {
      component.isAllInvalidSleeveType = false;
    } else if (event.isAllInvalidSleeveType) {
      component.isAllInvalidSleeveType = event.value;
    }

    if (event.data === TradeFilterMethod.TRADE_GROUPS_FOR_ACCOUNT) {
      component.tradeGroupTypeId = TradeGroupsType.TradeGroupsForAccount;
    } else if (event.data === TradeFilterMethod.TRADE_GROUPS_FOR_PORTFOLIO) {
      component.tradeGroupTypeId = TradeGroupsType.TradeGroupsForPortfolio;
    }
  }
}
