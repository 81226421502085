<p-dialog header="Security Preferences" [resizable]="true" [(visible)]="displaySecPref"
          [style]="{'width': '1024px'}" [modal]="true">
  <div class="col-sm-12 security-preferences-modal-body">
    <div class="col-sm-6">
      <div class="form-group">
        Security Id:<span class="margin-left-05">{{securityPreference.id}}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        Security Name: <span class="margin-left-05">{{securityPreference.securityName}}</span>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        Security Type:<span class="margin-left-05">{{securityPreference.securityType}}</span>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        Security Symbol:<span class="margin-left-05">{{securityPreference.symbol}}</span>
      </div>
    </div>

    <div class="col-sm-6 pull-right">
      <label for="donottlh" class="primar-label m-t-0">
        <input type="checkbox" id="donottlh" name="donottlh" [checked]="securityPreference.doNotTLH"
               [(ngModel)]="securityPreference.doNotTLH"/> Do Not TLH
      </label>
    </div>

    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideEquivalentFromPopup">
      <eclipse-security-preference-equivalent></eclipse-security-preference-equivalent>
    </div>

    <div class="row"
         [hidden]="securityPreferencesHiddenMap.isHideRedemptionFee">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Redemption Fee</legend>

        <div class="col-sm-4">
          <div class="form-group">
            Redemption Fee Amount:
            <div class="form-inline">
              <select [(ngModel)]="type" (change)="onFeeTypeChange($event)"
                      class="form-control">
                <option value="$">$</option>
                <option value="%">%</option>
              </select>
              <input placeholder="Enter amount" *ngIf="type=='$'" type="number" min="0" value="0"
                     step="0.01" class="form-control" [(ngModel)]="securityPreference.redemptionFeeAmount"
                     (keypress)="setValid($event)"/>
              <input placeholder="Enter amount" *ngIf="type=='%'" type="number" min="0" value="0"
                     step="0.01" class="form-control" [(ngModel)]="securityPreference.redemptionFeeAmount"
                     (keyup)="validatePercent(securityPreference)" (keypress)="setValid($event)"/>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Redemption Fee days:
            <input placeholder="Enter days" type="number" min="0" value="0" step="1" class="form-control"
                   [(ngModel)]="securityPreference.redemptionFeeDays"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="row" [hidden]="!canShow">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Trade Min</legend>

        <div class="col-sm-3">
          <div class="form-group">
            Sell Min Amount:
            <input placeholder="Enter min amount" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.sellTradeMinAmtBySecurity"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Sell Min Percent:
            <input placeholder="Enter min Percent" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.sellTradeMinPctBySecurity"
                   (keyup)="validatePercent(securityPreference)" (keypress)="setValid($event)"/>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Buy Min Amount:
            <input placeholder="Enter min amount" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.buyTradeMinAmtBySecurity"
                   (keypress)="setValid($event)"/>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            Buy Min Percent:
            <input placeholder="Enter min percent" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.buyTradeMinPctBySecurity"
                   (keyup)="validatePercent(securityPreference)" (keypress)="setValid($event)"/>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row" [hidden]="!canShow">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Trade Max</legend>

        <div class="col-sm-3">
          <div class="form-group">
            Sell Max Amount:
            <input placeholder="Enter max amount" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.sellTradeMaxAmtBySecurity"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Sell Max Percent:
            <input placeholder="Enter max percent" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.sellTradeMaxPctBySecurity"
                   (keyup)="validatePercent(securityPreference)" (keypress)="setValid($event)"/>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Buy Max Amount:
            <input placeholder="Enter max amount" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.buyTradeMaxAmtBySecurity"
                   (keypress)="setValid($event)"/>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            Buy Max Percent:
            <input placeholder="Enter max percent" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.buyTradeMaxPctBySecurity"
                   (keyup)="validatePercent(securityPreference)" (keypress)="setValid($event)"/>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideTaxAlternates">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Tax Alternates</legend>

        <div class="security-preference-alternative" style="min-height:300px;">
          <table class="alernative-table">
            <caption class="hidden-element">Tax Alternates</caption>
            <thead class="sr-only">
            <tr>
              <th scope="col">Custodian</th>
              <th scope="col">Taxable</th>
              <th scope="col">Tax Deferred</th>
              <th scope="col">Tax Exempt</th>
              <th scope="col">Custodian</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tr class="form-group">
              <td>
                <p-autoComplete class="autocomplete-search" [(ngModel)]="selectedAlternativeCustodian" ngDefaultControl
                                [suggestions]="custodianAlternativeFilterList" appendTo="body"
                                placeholder="Search Custodian" name="custodianSearch"
                                (completeMethod)="autoCustodianSearch($event, 'Alternate')" field="name"
                                [minLength]="1" [disabled]="!!securityPreference.custodialCash">
                  <ng-template let-selectedCustodian pTemplate="item">
                    <ul class="ui-helper-clearfix show-hollding-dropdown">
                      <li>
                        <i class="lm lm-securities text-info" aria-hidden="true"></i>
                        <span>{{ selectedCustodian.name + " (" + selectedCustodian.id + ")"}}</span>
                      </li>
                    </ul>
                  </ng-template>
                </p-autoComplete>
              </td>
              <td>
                <eclipse-security-autocomplete [disableSecuritySearch]="!!securityPreference.custodialCash"
                  (onSearchSecuritySelect)="handleSelectedTax($event)" [isMoneyMarketSecurity]="false">
                </eclipse-security-autocomplete>
              </td>
              <td>
                <eclipse-security-autocomplete [disableSecuritySearch]="!!securityPreference.custodialCash"
                  (onSearchSecuritySelect)="handleSelectedDeferred($event)" [isMoneyMarketSecurity]="false">
                </eclipse-security-autocomplete>
              </td>
              <td>
                <eclipse-security-autocomplete [disableSecuritySearch]="!!securityPreference.custodialCash"
                  (onSearchSecuritySelect)="handleSelectedExempt($event)" [isMoneyMarketSecurity]="false">
                </eclipse-security-autocomplete>
                <div class=""></div>
              </td>
              <td>
                <button type="button" name="button" class="alternative-btn-add"
                        (click)="addToAlternativeGrid()" [disabled]="securityPreference.custodialCash"
                        [ngClass]="{'disabled': securityPreference.custodialCash}">
                  <i class="fas fa-plus-circle" aria-hidden="true"></i></button>
              </td>
            </tr>
            <tr *ngIf="showAlternativeErrMsg">
              <td colspan="5">
                <div class="alternative-error">
                  <p class="securityError danger alternative-error-security"
                     >{{errAlternativeMessage}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine"
                                 [gridOptions]="alternativeGridOptions" (gridReady)="onAlternativeGridReady($event)"
                                 rowSelection="single" [columnDefs]="columnAlternativeDefs"
                                 [rowData]="alternatives">
                </ag-grid-angular>
              </td>
            </tr>

          </table>
        </div>
      </fieldset>
    </div>

    <!--  DIVIDEND AND CAPITAL EDITABLE ONLY @ ACCOUNT LEVEL  -->

    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideDividendReinvest">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Dividend Reinvest</legend>
        <div class="col-sm-3">
          <div class="form-group">
            Taxable:
            <select class="form-control" [(ngModel)]="securityPreference.taxableDivReInvest"
                    (ngModelChange)="setDropdownSelectedOption($event,'taxableDivReInvest')">
              <!--<option value=null>Select</option>-->
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            Tax Deferred:
            <select class="form-control" [(ngModel)]="securityPreference.taxDefDivReinvest"
                    (ngModelChange)="setDropdownSelectedOption($event,'taxDefDivReinvest')">
              <!--<option value=null>Select</option>-->
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            Tax Exempt:
            <select class="form-control" [(ngModel)]="securityPreference.taxExemptDivReinvest"
                    (ngModelChange)="setDropdownSelectedOption($event,'taxExemptDivReinvest')">
              <!--<option value={{null}}>Select</option>-->
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideCapitalGainReinvest">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Capital Gain Reinvest</legend>


        <div class="col-sm-3">
          <div class="form-group">
            Taxable:
            <select class="form-control" [(ngModel)]="securityPreference.capGainReinvestTaxable"
                    (ngModelChange)="setDropdownSelectedOption($event,'capGainReinvestTaxable')">
              <!--<option value=null>Select</option>-->
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            Tax Deferred:
            <select class="form-control" [(ngModel)]="securityPreference.capGainsReinvestTaxDef"
                    (ngModelChange)="setDropdownSelectedOption($event,'capGainsReinvestTaxDef')">
              <!--<option value=null>Select</option>-->
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            Tax Exempt:
            <select class="form-control" [(ngModel)]="securityPreference.capGainsReinvestTaxExempt"
                    (ngModelChange)="setDropdownSelectedOption($event,'capGainsReinvestTaxExempt')">
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>
          </div>
        </div>
      </fieldset>
    </div>

    <!--  DIVIDEND AND CAPITAL EDITABLE ONLY @ ACCOUNT LEVEL  -->

    <div class="row"
         [hidden]="securityPreferencesHiddenMap.isHideCustodianSpecificRedemptionFee">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Custodian Specific Redemption Fee</legend>
        <div class='row'>
          <div class="col-sm-4">
            <div class="form-group">
              Amount:
              <div class="form-inline">
                <select [(ngModel)]="custodianFeeType"
                        (change)="onCustodianFeeTypeChange($event)" class="form-control">
                  <option value="$">$</option>
                  <option value="%">%</option>
                </select>
                <input placeholder="Enter amount" *ngIf="custodianFeeType=='$'" type="number" min="0"
                       value="0" step="0.01" class="form-control"
                       [(ngModel)]="securityPreference.custodianRedemptionFeeAmount"
                       (keypress)="setValid($event)"/>
                <input placeholder="Enter amount" *ngIf="custodianFeeType=='%'" type="number" min="0"
                       value="0" step="0.01" class="form-control"
                       [(ngModel)]="securityPreference.custodianRedemptionFeeAmount"
                       (keyup)="validatePercent(securityPreference)"
                       (keypress)="setValid($event)"/>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              Days:
              <input placeholder="Enter days" type="number" min="0" value="0" step="1"
                     class="form-control" [(ngModel)]="securityPreference.custodianRedemptionDays"
                     (keypress)="setValid($event)"/>

            </div>
          </div>
        </div>
        <div class='row' *ngIf="custodianFeeType=='%'">
          <div class='col-sm-4'>
            <div class="form-group">
              Minimum Redemption Fee ($):
              <input placeholder="Enter Amount" type="number" min="0" value="0" step="1"
                     class="form-control" [(ngModel)]="securityPreference.custodianRedemptionFeeMinAmount"
                     (keyup)="validateMinAmount(securityPreference)" (keypress)="setValid($event)"/>
            </div>
          </div>
          <div class='col-sm-4'>
            <div class="form-group">
              Maximum Redemption Fee ($):
              <input placeholder="Enter Amount" type="number" min="0" value="0" step="1"
                     class="form-control" [(ngModel)]="securityPreference.custodianRedemptionFeeMaxAmount"
                     (focusout)="validateMaxAmount(securityPreference)" (keypress)="setValid($event)"/>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="row"
         [hidden]="securityPreferencesHiddenMap.isHideTrading">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Trading</legend>
        <div class="col-sm-3">
          <div class="form-group">
            Exclude Holding:
            <select class="form-control" [(ngModel)]="securityPreference.excludeHolding"
                    (ngModelChange)="setDropdownSelectedOption($event,'excludeHolding')">
              <!--<option value=null>Select</option>-->
              <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                {{r.name}}
              </option>
            </select>

          </div>
        </div>
      </fieldset>
    </div>

    <div class="row"
         [hidden]="securityPreferencesHiddenMap.isHideTransactionFee">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Transaction Fee</legend>
        <div class="col-sm-3">
          <div class="form-group">
            Sell Transaction fee:
            <input placeholder="Enter fee" type="number" min="0" value="0" step="0.01" class="form-control"
                   [(ngModel)]="securityPreference.sellTransactionFee"
                   (keypress)="setValid($event)"/>

          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Buy Transaction Fee:
            <input placeholder="Enter fee" type="number" min="0" value="0" step="0.01" class="form-control"
                   [(ngModel)]="securityPreference.buyTransactionFee"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideTradeSettings">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Trade Settings</legend>
        <div class="col-sm-3">
          <div class="form-group">
            Sell Priority:
            <select class="form-control" [(ngModel)]="securityPreference.sellPriority"
                    (ngModelChange)="sellPriorityChange(securityPreference.sellPriority)"
                    [disabled]="securityPreference.isRestricted">
              <option value=null>Select</option>
              <option *ngFor="let r of sellPriorityList" [ngValue]="r.id">
                {{r.displayName}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            Buy Priority:
            <select class="form-control" [(ngModel)]="securityPreference.buyPriority"
                    (ngModelChange)="setDropdownSelectedOption($event,'buyPriority')"
                    [disabled]="securityPreference.sellPriority === ALWAYS_SELL_ALL || securityPreference.isRestricted">
              <option value=null>Select</option>
              <option *ngFor="let r of buyPriorityList" [ngValue]="r.id">
                {{r.displayName}}
              </option>
            </select>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideASTRORestrictions">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>ASTRO Restrictions</legend>
        <div class="row" [hidden]="this.preferenceLevel !== 'Account'">
          <div class="col-sm-12"><h5>Preference Settings</h5></div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">Type: {{astroRestrictionTypeDisplay(securityPreference.astroRestrictionType)}}
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group" [hidden]="!showAstroMinMaxFields">Minimum %:
              <input placeholder="Enter percent" id="astroRestrictionMin"
                     data-test-id="astroRestrictionMin"
                     type="number" min="0" value="0" step="1" class="form-control"
                     [(ngModel)]="securityPreference.astroRestrictionMin"
                     (change)="validateAstroMinMaxPercents(securityPreference, 'min')"
                     (keypress)="setValid($event)"/>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group" [hidden]="!showAstroMinMaxFields">Maximum %:
              <input placeholder="Enter percent" id="astroRestrictionMax"
                     data-test-id="astroRestrictionMax"
                     type="number" min="0" value="0" step="1" class="form-control"
                     [(ngModel)]="securityPreference.astroRestrictionMax"
                     (change)="validateAstroMinMaxPercents(securityPreference, 'max')"
                     (keypress)="setValid($event)"/>
            </div>
          </div>
        </div>
        <div [hidden]="this.preferenceLevel !== 'Account'">
          <div class="row">
            <div class="col-sm-12"><h5>Current ASTRO Values</h5></div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                Type: {{astroRestrictionTypeDisplay(securityPreference.astroRestrictionTypeMarsValue)}}</div>
            </div>
            <div class="col-sm-3">
              <div class="form-group" [hidden]="!showAstroMinMaxFields">
                Minimum %:
                <input class="form-control" [(ngModel)]="securityPreference.astroRestrictionMinMarsValue"
                       disabled="disabled"/>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group" [hidden]="!showAstroMinMaxFields">
                Maximum %:
                <input class="form-control" [(ngModel)]="securityPreference.astroRestrictionMaxMarsValue"
                       disabled="disabled"/>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideOptionsTrading">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Options Trading</legend>
        <div class="col-sm-3">
          <div class="form-group">Covered Call %:
            <input placeholder="Enter percent" id="coveredCallPercent"
                   data-test-id="coveredCallPercent"
                   type="number" value="0" step="1" class="form-control"
                   [(ngModel)]="securityPreference.coveredCallPercent" (keyup)="validatePercent(securityPreference)"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">Protective Put %:
            <input placeholder="Enter percent" id="protectivePutPercent"
                   data-test-id="protectivePutPercent"
                   type="number" value="0" step="1" class="form-control"
                   [(ngModel)]="securityPreference.protectivePutPercent" (keyup)="validatePercent(securityPreference)"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideTradeSettingsByAccountType">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Trade Settings By Account Type ( Do Not Buy/Sell )</legend>

        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <p class="trade-account-type">Taxable:</p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="securityPreference.tSellPriority">
                <option value=0>Select Sell Priority</option>
                <option *ngFor="let r of taxableSellPriorityList" [ngValue]="r.id">
                  {{r.displayName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="securityPreference.tBuyPriority">
                <option value=0>Select Buy Priority</option>
                <option *ngFor="let r of taxableBuyPriorityList" [ngValue]="r.id">
                  {{r.displayName}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <p class="trade-account-type">Tax Deferred:</p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="securityPreference.tdSellPriority">
                <option value=0>Select Sell Priority</option>
                <option *ngFor="let r of taxableSellPriorityList" [ngValue]="r.id">
                  {{r.displayName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="securityPreference.tdBuyPriority">
                <option value=0>Select Buy Priority</option>
                <option *ngFor="let r of taxableBuyPriorityList" [ngValue]="r.id">
                  {{r.displayName}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <p class="trade-account-type">Tax Exempt:</p>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="securityPreference.teSellPriority">
                <option value=0>Select Sell Priority</option>
                <option *ngFor="let r of taxableSellPriorityList" [ngValue]="r.id">
                  {{r.displayName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <select class="form-control" [(ngModel)]="securityPreference.teBuyPriority">
                <option value=0>Select Buy Priority</option>
                <option *ngFor="let r of taxableBuyPriorityList" [ngValue]="r.id">
                  {{r.displayName}}
                </option>
              </select>
            </div>
          </div>
        </div>

      </fieldset>
    </div>


    <div class="row" [hidden]="securityPreferencesHiddenMap.isHideTLHAlternate">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>TLH Alternate</legend>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group portfoliodetails-search-add">
              <p-autoComplete class="autocomplete-search inputpopup radius-none" [(ngModel)]="selectedCustodian" ngDefaultControl
                              [suggestions]="custodianFilterList" [disabled]="!!securityPreference.custodialCash"
                              placeholder="Search Custodian" name="custodianSearch"
                              (completeMethod)="autoCustodianSearch($event,'TLH')" field="name" [minLength]="1">
                <ng-template let-selectedCustodian pTemplate="item">
                  <ul class="ui-helper-clearfix show-hollding-dropdown">
                    <li>
                      <i class="lm lm-securities text-info" aria-hidden="true"></i>
                      <span>{{ selectedCustodian.name + " (" + selectedCustodian.id + ")"}}</span>
                    </li>
                  </ul>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group portfoliodetails-search-add">
              <p-autoComplete class="autocomplete-search inputpopup radius-none" [(ngModel)]="selectedTlhSecurity" ngDefaultControl
                              [suggestions]="filteredTlhSecurityResult" [disabled]="!!securityPreference.custodialCash"
                              placeholder="Search Security" name="tlhSearch"
                              (completeMethod)="autoTlhSecuritySearch($event)" field="name" [minLength]="1"
                              (onSelect)="handleTlhSelectedSecurity(selectedTlhSecurity)">
                <ng-template let-selectedTlhSecurity pTemplate="item">
                  <ul class="ui-helper-clearfix show-hollding-dropdown">
                    <li>
                      <i class="lm lm-securities text-info" aria-hidden="true"></i>
                      <span>{{ selectedTlhSecurity.name }}
                        (<strong>{{selectedTlhSecurity.symbol}}</strong>)</span>
                    </li>
                  </ul>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class="col-sm-4">
            <p class="securityError danger" *ngIf="showErrMsg">{{errMessage}}</p>
          </div>
          <div class="col-sm-2 pull-right text-right">
            <button type="button" pButton class="addbtnpopup" title="Add Security"
                    (click)="addTlh(selectedTlhSecurity)" label="Add" [disabled]="btnDisableSecurity"></button>
          </div>
        </div>

        <ag-grid-angular style="width: 100%; height: 200px; padding: 0;"
                         class="ag-theme-alpine col-md-12"
                         [gridOptions]="gridTlhOptions" (gridReady)="onTLHGridReady($event)"
                         rowSelection="single" [columnDefs]="tlhColumnDefs"
                         [rowData]="tlhList">
        </ag-grid-angular>
      </fieldset>
    </div>


    <!-- NEW PREFERENCE OF MINIMUM INITIAL BUY -->
    <div class="row"
         [hidden]="securityPreferencesHiddenMap.isHideMinimumInitialBuy">
      <fieldset class="col-md-12 security-preferences-fieldset">
        <legend>Minimum Initial Buy</legend>
        <div class="col-sm-3">
          <div class="form-group">
            Min Amount:
            <input placeholder="Enter Min Amount" type="number" min="0" value="0" step="0.01"
                   class="form-control" [(ngModel)]="securityPreference.minInitialBuyAmount"
                   (keypress)="setValid($event)"/>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-secondary" (click)="cancel()" icon="fas fa-times" label="Cancel"></button>
    <button type="button" pButton (click)="addToGrid()" icon="fas fa-check" label="Add"></button>
  </p-footer>
</p-dialog>
