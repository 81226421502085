import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SpinnerService} from './spinner.service';

@Component({
  selector: 'oe-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
  visible$: Observable<boolean>;
  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.visible$ = this.spinnerService.visible$;
  }
}
