import { Component, Input } from '@angular/core';
import {SecurityService} from '../../services/security.service';
import {ISubClass} from '../../models/subClass';
import { ICategory } from '../../models/category';
import { IClass } from '../../models/class';
import {ILocationOptimization, ILocationSettings} from '../../models/preferences/locationOptimization';
import {BaseComponent} from '../../core/base.component';
import { ILocationOptimizationCustom } from '../../models/preferences/locationOptimization';
import { PreferenceService } from '../../services/preference.service';

@Component({
  selector: 'eclipse-location-optimization',
  templateUrl: './locationoptimization.component.html',
  providers: [SecurityService]
})
export class LocationOptimizationComponent extends BaseComponent {

  @Input() displayPermission: boolean;
  @Input() LocationOptimizations: ILocationOptimizationCustom;
  subClassesData: ISubClass[] = [];
  selectedModel: ILocationOptimization;
  optimizations: any[] = [{ id: 1, value: '1' }, { id: 2, value: '2' }, { id: 3, value: '3' }, { id: 4, value: 'Never' }];
  errMesage: boolean = false;
  errorMessage: string = '';
  assetType: number;
  assetCategoryData: ICategory[] = [];
  assetClassData: IClass[] = [];
  assetTypeData: any[] = [];
  assetTypeName: string;
  assetTypeList: any[] = [];
  defaultAssetType: any;
  showPreferencePopup = false;
  minSubRank = 1;
  maxSubRank = 99;
  subRank: ILocationOptimization;
  disableAssetTypeData: boolean = false;

  constructor(private _securityService: SecurityService, private _preferenceservice: PreferenceService) {
    super();
    this.intilizeProperties();
    this.loadAssetsType();
}

  /**Intilize properties */
  intilizeProperties() {
    this.selectedModel = <ILocationOptimization>{};
    this.selectedModel.buySetting = <ILocationSettings>{};
    this.selectedModel.sellSetting = <ILocationSettings>{};
    this.selectedModel.id = 0;
    this.selectedModel.inheritedFrom = '';
    this.subRank = <ILocationOptimization>{};
    this.subRank.buySetting = <ILocationSettings>{};
    this.subRank.sellSetting = <ILocationSettings>{};
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadAssetList();
  }

  /**Load asset Types DropDown */
  loadAssetsType() {
    this.loadAssetCategory();
    this.loadAssetClass();
    this.loadAssetSubClass();
}

loadAssetCategory() {
    this._securityService.getCategoryData().subscribe(data => {
        // eslint-disable-next-line eqeqeq
        this.assetCategoryData = data.filter(a => a.isDeleted == 0);
        this.setAssetTypeData();
    },
    error => {
        throw error;
    });
}

loadAssetClass() {
    this._securityService.getClassData().subscribe(data => {
        // eslint-disable-next-line eqeqeq
        this.assetClassData = data.filter(a => a.isDeleted == 0);
        this.setAssetTypeData();
    },
    error => {
        throw error;
    });
}

loadAssetSubClass() {
    this._securityService.getSubClassData().subscribe(data => {
        // eslint-disable-next-line eqeqeq
        this.subClassesData = data.filter(a => a.isDeleted == 0);
        this.setAssetTypeData();
    },
    error => {
        throw error;
    });
}

loadAssetList() {
    this._preferenceservice.getPreferenceOption(this.LocationOptimizations.preferenceId).subscribe(data => {
        this.assetTypeList = data;
        // eslint-disable-next-line eqeqeq
        this.defaultAssetType = this.assetTypeList.find(a => a.optionName == 'SubClass');
        this.setDefaultAssetType();
        this.setAssetTypeData();
    },
    error => {
        throw error;
    });
}

  /**Add Optimizations into the datasource container */
  addOptimizations() {
    this.LocationOptimizations.resetToParent = false;
    // eslint-disable-next-line eqeqeq
    if (this.selectedModel.id != undefined && this.selectedModel.id != 0 && this.selectedModel.buySetting.T != undefined && this.selectedModel.buySetting.TD != undefined && this.selectedModel.buySetting.TE != undefined && this.selectedModel.sellSetting.T != undefined && this.selectedModel.sellSetting.TD != undefined && this.selectedModel.sellSetting.TE != undefined) {
      // eslint-disable-next-line eqeqeq
      const index = this.LocationOptimizations.assetTypes.findIndex(asset => asset.id == this.selectedModel.id);
      this.showPreferencePopup = false;
      this.addSubRankIntoRank(this.selectedModel, this.subRank);
      if (index > -1) {
        this.LocationOptimizations.assetTypes[index] = JSON.parse(JSON.stringify(this.selectedModel));
      } else {
        // eslint-disable-next-line eqeqeq
        this.selectedModel.name = this.assetTypeData.filter(a => a.id == this.selectedModel.id)[0].name;
        this.selectedModel.inheritedFrom = null;
        this.LocationOptimizations.assetTypes.unshift(this.selectedModel);
      }
      this.refreshControls();
    } else {
      this.errMesage = true;
    }
  }

  /**clear records after add and also filter records */
  refreshControls() {
    // eslint-disable-next-line eqeqeq
    if (this.LocationOptimizations.assetTypes != null) {
      this.LocationOptimizations.assetTypes.forEach(element => {
          // eslint-disable-next-line eqeqeq, radix
          this.assetTypeData = this.assetTypeData.filter(record => record.id != parseInt(element.id));
      });
    }
    this.intilizeProperties();
  }

  rebindControls(dataSourceObject) {
    this.LocationOptimizations.assetTypes = Object.values(dataSourceObject);
    const length = this.LocationOptimizations.assetTypes ? this.LocationOptimizations.assetTypes.length : 0;
    // eslint-disable-next-line eqeqeq
    if (this.LocationOptimizations.assetTypes != null) {
        this.LocationOptimizations.assetType = length ? this.LocationOptimizations.inheritedAssetType : this.defaultAssetType.id;
        this.LocationOptimizations.assetTypeName = length ? this.LocationOptimizations.inheritedAssetTypeName : this.defaultAssetType.optionName;
        this.setAssetTypeData();
    }
    this.intilizeProperties();
  }
  /**delete optimizations and loads the subclasses dropdown */
  deleteOptimizations(selectedItem) {
    this.LocationOptimizations.resetToParent = false;
    // eslint-disable-next-line eqeqeq
    this.LocationOptimizations.assetTypes.splice(this.LocationOptimizations.assetTypes.findIndex(x => x.id == selectedItem), 1);
    this.setAssetTypeData();
  }

  /**show/hide error message flag */
  toggleFlag(action: string, type: string) {
    this.errMesage = false;
    // OE-3441 reset subrank value if corresponding preference changes.
    // eslint-disable-next-line eqeqeq
    if (action == 'buy') {
        // eslint-disable-next-line eqeqeq
        if (type == 'T') {
            this.subRank.buySetting.T = undefined;
        // eslint-disable-next-line eqeqeq
        } else if (type == 'TD') {
            this.subRank.buySetting.TD = undefined;
        // eslint-disable-next-line eqeqeq
        } else if (type == 'TE') {
            this.subRank.buySetting.TE = undefined;
        }
    // eslint-disable-next-line eqeqeq
    } else if (action == 'sell') {
        // eslint-disable-next-line eqeqeq
        if (type == 'T') {
            this.subRank.sellSetting.T = undefined;
        // eslint-disable-next-line eqeqeq
        } else if (type == 'TD') {
            this.subRank.sellSetting.TD = undefined;
        // eslint-disable-next-line eqeqeq
        } else if (type == 'TE') {
            this.subRank.sellSetting.TE = undefined;
        }
    }
  }

  /** Get specifiv subclass vlaue of both Buy and Sell settings */
  getSelectValue(id: number) {
    // eslint-disable-next-line eqeqeq
    const filtlerdList = this.optimizations.find(x => x.id == id);
    // OE-3441 if id is not in optimzations.
    if (filtlerdList) {
        return filtlerdList.value;
    } else {
        return id.toFixed(2);
    }
  }

  setAssetTypeData() {
    this.assetType = this.LocationOptimizations.assetType;
    // eslint-disable-next-line eqeqeq
    const type = this.assetTypeList ? this.assetTypeList.find(t => t.id == Number(this.assetType)) : null;
    this.assetTypeName = type ? type.optionName : null;

    if (this.assetTypeData) {
        switch (this.assetTypeName) {
            case 'Category':
                this.assetTypeData = JSON.parse(JSON.stringify(this.assetCategoryData));
                break;
            case 'Class':
                this.assetTypeData = JSON.parse(JSON.stringify(this.assetClassData));
                break;
            case 'SubClass':
                this.assetTypeData = JSON.parse(JSON.stringify(this.subClassesData));
                break;
        }
        this.refreshControls();
    }
  }

  setDefaultAssetType() {
    if (!this.LocationOptimizations.assetType && this.assetTypeList) {
        // eslint-disable-next-line eqeqeq
        const type = this.assetTypeList.find(t => t.optionName == 'SubClass');
        this.LocationOptimizations.assetType = type ? type.id : null;
        this.LocationOptimizations.assetTypeName = type ? type.optionName : null;
    }
  }

  getLocationOptimization() {
    this.disableAssetTypeData = true;
    this.LocationOptimizations.assetTypes = [];
    this.setAssetTypeData();
    this.LocationOptimizations.resetToParent = false;
    this._preferenceservice.getLocationOptimizationPreferences(this.LocationOptimizations.level,
        this.LocationOptimizations.recordId,
        'locationOptimization',
        this.LocationOptimizations.preferenceId,
        this.LocationOptimizations.id,
        this.LocationOptimizations.inheritedFromValueId,
        this.LocationOptimizations.assetType
    ).subscribe(result => {
        this.LocationOptimizations.assetType = result.assetType;
        this.LocationOptimizations.assetTypes = result.assetTypes;
        this.LocationOptimizations.assetTypeName = result.assetTypeName;
        this.LocationOptimizations.inheritedAssetType = result.inheritedAssetType;
        this.LocationOptimizations.inheritedAssetTypes = result.inheritedAssetTypes;
        this.LocationOptimizations.inheritedAssetTypeName = result.inheritedAssetTypeName;
        this.setAssetTypeData();
        this.disableAssetTypeData = false;
    });
  }

    // OE-3441 open location preference popup and clear unsaved data if any
    showLocationPreferencePopup() {
        this.showPreferencePopup = true;
        this.errMesage = false;
        // eslint-disable-next-line eqeqeq
        this.selectedModel.name = this.assetTypeData.filter(a => a.id == this.selectedModel.id)[0].name;
        this.selectedModel.buySetting = <ILocationSettings>{};
        this.selectedModel.sellSetting = <ILocationSettings>{};
        this.subRank.buySetting = <ILocationSettings>{};
        this.subRank.sellSetting = <ILocationSettings>{};
    }

    hideLocationPreferencePopup() {
        this.showPreferencePopup = false;
        this.intilizeProperties();
    }

    // OE-3441 add subrank into preference value
    addSubRankIntoRank(selectedModel: ILocationOptimization, subRank: any) {
       const LOC_OPT_OPTION_NEVER = 4;
        const PONIT_SEPRATER = 100;
        const SUB_RANK_PRECISION = 2;
        // eslint-disable-next-line eqeqeq
        if (selectedModel.buySetting.T && selectedModel.buySetting.T != LOC_OPT_OPTION_NEVER && subRank.buySetting.T) {
            selectedModel.buySetting.T = +(selectedModel.buySetting.T + (subRank.buySetting.T / PONIT_SEPRATER)).toFixed(SUB_RANK_PRECISION);
        }
        // eslint-disable-next-line eqeqeq
        if (selectedModel.buySetting.TD && selectedModel.buySetting.TD != LOC_OPT_OPTION_NEVER && subRank.buySetting.TD) {
            selectedModel.buySetting.TD = +(selectedModel.buySetting.TD + (subRank.buySetting.TD / PONIT_SEPRATER)).toFixed(SUB_RANK_PRECISION);
        }
        // eslint-disable-next-line eqeqeq
        if (selectedModel.buySetting.TE && selectedModel.buySetting.TE != LOC_OPT_OPTION_NEVER && subRank.buySetting.TE) {
            selectedModel.buySetting.TE = +(selectedModel.buySetting.TE + (subRank.buySetting.TE / PONIT_SEPRATER)).toFixed(SUB_RANK_PRECISION);
        }
        // eslint-disable-next-line eqeqeq
        if (selectedModel.sellSetting.T && selectedModel.sellSetting.T != LOC_OPT_OPTION_NEVER && subRank.sellSetting.T) {
            selectedModel.sellSetting.T = +(selectedModel.sellSetting.T + (subRank.sellSetting.T / PONIT_SEPRATER)).toFixed(SUB_RANK_PRECISION);
        }
        // eslint-disable-next-line eqeqeq
        if (selectedModel.sellSetting.TD && selectedModel.sellSetting.TD != LOC_OPT_OPTION_NEVER && subRank.sellSetting.TD) {
            selectedModel.sellSetting.TD = +(selectedModel.sellSetting.TD + (subRank.sellSetting.TD / PONIT_SEPRATER)).toFixed(SUB_RANK_PRECISION);
        }
        // eslint-disable-next-line eqeqeq
        if (selectedModel.sellSetting.TE && selectedModel.sellSetting.TE != LOC_OPT_OPTION_NEVER && subRank.sellSetting.TE) {
            selectedModel.sellSetting.TE = +(selectedModel.sellSetting.TE + (subRank.sellSetting.TE / PONIT_SEPRATER)).toFixed(SUB_RANK_PRECISION);
        }
    }

    // OE-3441 handle condition if value is >99 or <1
    numberValidation(event) {
        if (event && event.target && event.target.value) {
            // eslint-disable-next-line radix
            if (parseInt(event.target.value) < 1) {
                event.target.value = 1;
            // eslint-disable-next-line radix
            } else if (parseInt(event.target.value) > this.maxSubRank) {
                event.target.value = this.maxSubRank;
            } else {
                event.target.value = parseFloat(event.target.value);
            }
        } else {
            event.target.value = undefined;
        }
    }

    editLocationPreferencePopup(id) {
      this.showPreferencePopup = true;
      this.errMesage = false;
      // eslint-disable-next-line eqeqeq
      const selectedModel = this.LocationOptimizations.assetTypes.find(asset => asset.id == id);
      if (selectedModel) {
        this.selectedModel = JSON.parse(JSON.stringify(selectedModel));
        this.getSubRankIntoRank(this.selectedModel, this.subRank);
      }
    }

    getSubRankIntoRank(selectedModel: ILocationOptimization, subRank: any) {
      const TO_FIX = 2;
      if (selectedModel.buySetting.T) {
        const T = (selectedModel.buySetting.T).toFixed(TO_FIX).split('.');
        selectedModel.buySetting.T = T && T[0] ? Number(T[0]) : null;
        subRank.buySetting.T = T && T[1] ? Number(T[1]) : null;
      }
      if (selectedModel.buySetting.TD) {
        const TD = (selectedModel.buySetting.TD).toFixed(TO_FIX).split('.');
        selectedModel.buySetting.TD = TD && TD[0] ? Number(TD[0]) : null;
        subRank.buySetting.TD = TD && TD[1] ? Number(TD[1]) : null;
      }
      if (selectedModel.buySetting.TE) {
        const TE = (selectedModel.buySetting.TE).toFixed(TO_FIX).split('.');
        selectedModel.buySetting.TE = TE && TE[0] ? Number(TE[0]) : null;
        subRank.buySetting.TE = TE && TE[1] ? Number(TE[1]) : null;
      }
      if (selectedModel.sellSetting.T) {
        const T = (selectedModel.sellSetting.T).toFixed(TO_FIX).split('.');
        selectedModel.sellSetting.T = T && T[0] ? Number(T[0]) : null;
        subRank.sellSetting.T = T && T[1] ? Number(T[1]) : null;
      }
      if (selectedModel.sellSetting.TD) {
        const TD = (selectedModel.sellSetting.TD).toFixed(TO_FIX).split('.');
        selectedModel.sellSetting.TD = TD && TD[0] ? Number(TD[0]) : null;
        subRank.sellSetting.TD = TD && TD[1] ? Number(TD[1]) : null;
      }
      if (selectedModel.sellSetting.TE) {
        const TE = (selectedModel.sellSetting.TE).toFixed(TO_FIX).split('.');
        selectedModel.sellSetting.TE = TE && TE[0] ? Number(TE[0]) : null;
        subRank.sellSetting.TE = TE && TE[1] ? Number(TE[1]) : null;
      }
  }
}
