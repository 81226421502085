<!-- display alerts here -->
<div class="db-message-bar" *ngIf="alerts != undefined && alerts.length > 0" (mouseenter)="clearTimer()" (mouseleave)="startTimer()">
  <ul>
    <li><a href="javascript:void(0)" (click)="closeAlert()" class="bar-close"><i class="fas fa-times" aria-hidden="true"></i></a></li>
  </ul>
  <div class="row">
    <div class="col-md-10">
      <ng-template ngFor [ngForOf]="alerts" let-alert="$implicit" let-ai="index">
        <div *ngIf="alert.type == 'S' || alert.typeId == 1" class="text-success" role="alert"> <i class="fas fa-check-circle" aria-hidden="true"></i> {{alert.message}} </div>
        <div *ngIf="alert.type == 'I' || alert.typeId == 2" class="text-info" role="alert"> <i class="fas fa-info-circle" aria-hidden="true"></i> {{alert.message}} </div>
        <div *ngIf="alert.type == 'W' || alert.typeId == 3" class="text-warning" role="alert"> <i class="fas fa-exclamation-circle" aria-hidden="true"></i> {{alert.message}} </div>
        <div *ngIf="alert.type == 'E' || alert.typeId == 4" class="text-danger" role="alert"> <i class="fas fa-times-circle" aria-hidden="true"></i> {{alert.message}} </div>
      </ng-template>
    </div>
  </div>
</div>
