import { Component, OnDestroy } from '@angular/core';
import { IStatusPanelAngularComp } from '@ag-grid-community/angular';
import { IMassEditStatusPanelParams } from '../../../../../models/tom';

@Component({
  selector: 'status-component',
  templateUrl: './mass-edit-status-bar.component.html'
})
export class MassEditStatusBarComponent implements IStatusPanelAngularComp, OnDestroy {
  selectedRows: number;
  totalRowsInBlockGrid: number;
  private params!: IMassEditStatusPanelParams;

  agInit(params: IMassEditStatusPanelParams): void {
    this.params = params;
    this.params.api.addEventListener('modelUpdated', this.onModelUpdated.bind(this));
  }

  onModelUpdated(): void {
    this.selectedRows = this.params.api.getModel().getRowCount();
  }

  ngOnDestroy(): void {
    this.params.api.removeEventListener('modelUpdated', this.onModelUpdated);
  }
}
