import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  private strategistEndPoint = 'community/strategists';
  private modelEndPoint = 'community/models';
  private modelManagersEndpoint = 'community/modelManagers';

  constructor(private _httpClient: OEHttpClient) { }

  /*** To get Community strategist  */
  getCommunityStrategist() {
    return this._httpClient.getData(this.strategistEndPoint);
  }

  /*** To get Community Models  */
  getCommunityModelByStrategistId(strategistId: number) {
    return this._httpClient.getData(`${this.modelEndPoint}?strategistId=${strategistId}`);
  }

  /** To import community model */
  importCommunityModel(communityModelId: number) {
    return this._httpClient.postData(`${this.modelEndPoint}/import/${communityModelId}`, {});
  }

  /*** To get approved community strategist  */
  getApprovedCommunityStrategist() {
    return this._httpClient.getData(`${this.strategistEndPoint}/approved`);
  }

  /*** To get approved community models  */
  getApprovedCommunityModelsByStrategistId(strategistId: number, communityModelManagerId?: number) {
    let endpointURL = `/approved?strategistId=${strategistId}`;
    if (communityModelManagerId) {
      endpointURL += `&communityModelManagerId=${communityModelManagerId}`;
    }
    return this._httpClient.getData(this.modelEndPoint + endpointURL);
  }

  getStrategistManagers(strategistId: number) {
    return this._httpClient.getData(`${this.modelManagersEndpoint}?communityStrategistId=${strategistId}`);
  }

  /*** To check whether community model min amount is greater or less than portfolio pending value */
  canSaveCommunityImport(selectedComPfIds) {
    return this._httpClient.postData(`${this.modelEndPoint}/import`, selectedComPfIds);
  }
}
