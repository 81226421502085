import {Component, HostListener} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {BaseComponent} from '../../core/base.component';
import {ErrorLogService} from '../../services/errorlog.service';
import {IErrorLog} from '../../models/errorlog';
import { GridOptions, ColDef, CsvExportParams, GridReadyEvent, GridApi } from '@ag-grid-community/core';
import { Utils as Util } from '../../core/functions';
import {BaseGridConfiguration} from '../../shared/gridextensions/basegrid';

@Component({
  selector: 'eclipse-errors',
  templateUrl: './errorlog.component.html',
  providers: [ErrorLogService]
})
export class ErrorLogComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  type: string;
  errorLogsData: IErrorLog[] = [];
  shouldShowConfirmResyncDialog: boolean;
  shouldShowResyncInitiatedDialog: boolean;
  resyncErrorMessage: any;
  shouldShowResyncStatusDialog: boolean;
  private readonly resyncStatus_Requesting: string = "Sending request to initiate sync...";
  private readonly resyncStatus_Success: string = "The resync was succesfully requested.";
  private readonly resyncStatus_Failure: string = "The resync request failed.";
  resyncStatus: string;
  shouldEnableResyncStatusOkButton: boolean;

  constructor(private _errorLogService: ErrorLogService, private activateRoute: ActivatedRoute, private _router: Router) {
    super();
    this.gridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null,
      statusBar: {
        ...BaseGridConfiguration.statusBarRowCounts
      }
    };
  }

  ngOnInit() {
    this.createColumnDefs();
    this.getErrorData();
    Util.windowSize();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  @HostListener('window:resize')
  onResize() {
    Util.windowSize();
  }

  /** Create column headers for agGrid */
   createColumnDefs() {
    this.columnDefs = [
      <ColDef>{ headerName: 'Portfolio ID', field: 'portfolioId', width: 100, cellClass: 'text-center', filter: 'agNumberColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Portfolio Name', field: 'portfolioName', width: 150, cellClass: 'text-center fs-mask', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Account ID', field: 'accountId', width: 100, cellClass: 'text-center', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Account Name', field: 'accountName', width: 150, cellClass: 'text-center fs-mask', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Position ID', field: 'positionId', width: 100, cellClass: 'text-center', filter: 'agNumberColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Symbol', field: 'symbol', width: 100, cellClass: 'text-center', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Error Level', field: 'errorLevel', width: 150, cellClass: 'text-center', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
      <ColDef>{ headerName: 'Error', field: 'errorMessage', width: 150, cellClass: 'text-center', filter: 'agTextColumnFilter', enableRowGroup: true, enablePivot: false },
    ];
  }

  getErrorData() {
      this._errorLogService.getErrorLogs(this.type)
      .subscribe((errorLogs: IErrorLog[]) => {
        this.errorLogsData = errorLogs;
        this.gridApi.setGridOption('rowData', this.errorLogsData);
        this.gridApi.sizeColumnsToFit();
      });
  }

  resyncAccounts() {
    this.shouldShowConfirmResyncDialog = false;
    this.shouldEnableResyncStatusOkButton = false;
    this.setResyncStatus("Sending request to initiate sync...");
    this.shouldShowResyncStatusDialog = true;
    this._errorLogService.initateErroredAccountResync()
      .subscribe({
        next: () => {
          this.setResyncStatus("The resync was succesfully requested.");
        },
        error: (e) => {
          if (e.status === 403) {
            this.resyncErrorMessage = e.error.Message;
            this.setResyncStatus("This feature is not available.");
            return;
          }
          if (e?.error.isResyncAllowed === false){
            this.resyncErrorMessage = e.error.resyncValidationMessage;
            this.setResyncStatus("Unable to resync accounts from this grid. Please sync from Orion Connect or run a Delta.");
            return;
          }

          this.resyncErrorMessage = e.message;
          this.setResyncStatus("The resync request failed.")
        },
      }).add(() => {
        this.shouldEnableResyncStatusOkButton = true;
      });
  }


  /** Export the grid data to csv file */
  exportToExcel() {
    const params = <CsvExportParams>{
      skipFooters: true,
      skipGroups: true,
      fileName: 'Errors Details.csv'
    };
    // eslint-disable-next-line no-shadow
    params.processCellCallback = function (params) {
      // eslint-disable-next-line eqeqeq
      if (params.column.getColId() == 'createdDate') {
        return Util.dateRenderer(params);
      } else if (params.value && params.value.toUpperCase) {
        return params.value.toUpperCase();
      } else {
        return params.value;
      }
    };

    this.gridApi.exportDataAsCsv(params);
  }

  private setResyncStatus(status: string){
    this.resyncStatus = status;
  }

}
