import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDashboardFilter, IDashboardFilterSave } from '../../models/dashboardfilter';
import { DashboardFilterService } from '../../services/dashboardfilter.service';
import { CustomSubService } from '../../core/customSubService';
import * as _ from 'lodash';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'eclipse-dashboard-filter',
  templateUrl: './dashboardfilter.component.html',
  styleUrls: ['./dashboardfilter.component.scss']
})
export class DashboardFilterComponent extends BaseComponent implements OnInit {

  showDashboardSetting: boolean;
  @Input() dashboardType: string;
  @Input() filterId: number;
  @Input() displayMessage: boolean;
  @Output() resetDashboardSetting = new EventEmitter();

  filters: IDashboardFilter[];
  mainDivClass: string;
  popUpWidth: number;

  constructor(private _dashboardFilterService: DashboardFilterService, private _subService: CustomSubService) {
    super();
  }

  ngOnInit() {
    this.mainDivClass = this.filterId ? 'col-sm-12' : 'col-sm-6';
    this.popUpWidth = this.filterId ? 800 : 1100;
    this.getDashboardFilterData();
  }

  getDashboardFilterData() {
    this._dashboardFilterService.getEditDashBoardData(this.dashboardType, this.filterId)
      .subscribe((filters: IDashboardFilter[]) => {
        this.filters = filters;
        this.showDashboardSetting = true;
      }, err => {
        this.closeDashboardSettingPopUp();
        this._subService.forkJoinError.next(err);
    });
  }

  saveDashboardSetting() {
    const dataForSave: IDashboardFilterSave = {
      filters: this.filters
    };
    this._dashboardFilterService.saveOrUpdateDashboardFilter(dataForSave)
      .subscribe((filters: IDashboardFilter[]) => {
        this.closeDashboardSettingPopUp();
      }, err => {
        this.closeDashboardSettingPopUp();
        this._subService.forkJoinError.next(err);
    });
  }

  closeDashboardSettingPopUp() {
    this.showDashboardSetting = false;
    this.filters = null;
    this.resetDashboardSetting.emit();
  }

  onOptionChange(filter: IDashboardFilter) {
    if (filter && filter.dashboardSubLevels && filter.dashboardSubLevels.length > 0) {
      const option = filter.dashboardSubLevels.find(s => +s.selectedOptionId === 3);
      if (option) {
        _.forEach(filter.dashboardSubLevels, function(subLevel) {
          // eslint-disable-next-line eqeqeq
          if (+subLevel.selectedOptionId != 3) {
            _.map(subLevel.options, function(opt) {
              if (opt.id === 3) {
                opt.isDisable = true;
              }
            });
          }
        });
      } else {
        _.forEach(filter.dashboardSubLevels, function(subLevel) {
          // eslint-disable-next-line eqeqeq
          if (+subLevel.selectedOptionId != 3) {
            _.map(subLevel.options, function(opt) {
              opt.isDisable = false;
            });
          }
        });
      }
    }
  }

  resetDashboardFilters() {
    // if(this.filters && this.filters.length > 0){
    //   _.forEach(this.filters, function(filter){
    //     if(filter.dashboardSubLevels && filter.dashboardName.length > 0){
    //       _.forEach(filter.dashboardSubLevels, function(subLevel){
    //         subLevel.selectedOptionId = subLevel.defaultOptionId;
    //       });
    //     }
    //   })
    // }
    this._dashboardFilterService.resetDashboardFilter(this.filterId)
    .subscribe((result: any) => {
      this.closeDashboardSettingPopUp();
      }, err => {
        this.closeDashboardSettingPopUp();
        this._subService.forkJoinError.next(err);
    });


  }

}
