<div [ngSwitch]="displayPermission">
  <div *ngSwitchCase="true">
    <div class="firm-security-setting-header">
      <div class="form-inline">
        <div class="form-group">
          <select class="form-control watermarked gray" [(ngModel)]="selectedSecurityTypeId" (change)="handleSelectedSecurityType($event.target.value)">
            <option  value="null" selected>Please Select</option>
            <option *ngFor="let st of securityTypes" value={{st.id}}>{{st.name}}</option>
          </select>
          <button type="button" pButton title="Set Preferences" (click)="setPrefPopup(selectedSecurityType)" class="m-l-10"
                  [disabled]="btnDisableSetPref" label="Add"></button>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="true" class="grid-control">
    <ag-grid-angular #agGrid style="width: 100%; height: 100px;" class="ag-theme-alpine" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" rowSelection="single" [columnDefs]="columnDefs"
                     [rowData]="PrefrowData" (rowClicked)="rowSelected($event)"  [getContextMenuItems]="getContextMenuItems">
    </ag-grid-angular>
  </div>
  <div *ngSwitchCase="false" class="grid-control">
    <ag-grid-angular class="ag-theme-alpine" #agGrid style="width: 100%; height: 200px;" [columnDefs]="columnDefs" [rowData]="PrefrowData" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [suppressContextMenu]="true">
    </ag-grid-angular>
  </div>

  <p-dialog header="Dividend Reinvest Preferences" [resizable]="true" [(visible)]="displayDividendReinvestPref" class="ui-dialog-xl" modal="true">
    <div class="col-sm-12 security-preferences-modal-body">
      <div class="col-sm-6">
        <div class="form-group">
          Security Type:<span class="margin-left-05">{{selectedSecurityType}}</span>
        </div>
      </div>
      <div class="row">
        <fieldset class="col-md-12 security-preferences-fieldset">
          <legend>Dividend Reinvest</legend>
          <div class="col-sm-3">
            <div class="form-group">
              Taxable:
              <select id="ddlType" class="form-control" [(ngModel)]="dividendReinvestPref.taxableDivReInvest" (ngModelChange)="setDropdownSelectedOption($event,'taxableDivReInvest')">
                <!--<option value=null>Select</option>-->
                <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value" >
                  {{r.name}}
                </option></select>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              Tax Deferred:
              <select id="ddlType" class="form-control" [(ngModel)]="dividendReinvestPref.taxDefDivReinvest" (ngModelChange)="setDropdownSelectedOption($event,'taxDefDivReinvest')">
                <!--<option value=null>Select</option>-->
                <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value" >
                  {{r.name}}
                </option></select>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              Tax Exempt:
              <select id="ddlType" class="form-control" [(ngModel)]="dividendReinvestPref.taxExemptDivReinvest" (ngModelChange)="setDropdownSelectedOption($event,'taxExemptDivReinvest')">
                <!--<option value={{null}}>Select</option>-->
                <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value">
                  {{r.name}}
                </option></select>
            </div>
          </div>
          <div *ngIf="displayPermission == 'false'" class="col-sm-3">
            <div class="form-group">
              Inherited from:
              <label>{{dividendReinvestPref.inheritedFrom}}</label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="cancel()" label="Cancel"></button>
      <button type="button" pButton icon="fas fa-check" (click)="addtoGrid()" label="Add"></button>
    </p-footer>
  </p-dialog>
</div>
