<p-dialog header="Portfolio Assignment" [(visible)]="showAssignPopup" modal="true" [resizable]=true
          (onHide)="onBeforeHide()" class="ui-dialog-xl modal-xl">
  <div class="col-md-6 col-sm-6">Accounts
    <div class="form-group row">
      <div class="col-md-6 margin-top-025">
        <select class="form-control" [(ngModel)]="accountFilterId" required (change)="onAccountTypeChange($event.target.value)">
          <option value="0">All Accounts</option>
          <option value="4">New Account</option>
          <option value="5">Accounts With No Portfolio</option>
        </select>
      </div>
      <div class="col-md-6" *ngIf="accountSearch">
        <div class="autocomplete-search">
          <p-autoComplete placeholder="Search Account Name" class="autocomplete-search fs-mask" (onSelect)="onAccountSelect($event)" [(ngModel)]="searchAccountString"
                          field="selectedItemFormat" [minLength]="1" [suggestions]="formattedAccountSuggestions" (completeMethod)="autoAccountSearch($event)" appendTo="body">
            <ng-template let-searchAccountString pTemplate="item">
              <ul class="ui-helper-clearfix show-hollding-dropdown">
                <li>
                  <i class="fas fa-briefcase text-info" aria-hidden="true"></i>
                  <span class="fs-mask">{{searchAccountString.displayFormat}}</span>
                </li>
              </ul>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>
    <div class="">
      <ag-grid-angular #agGrid style="width: 100%; height: 350px;" class="ag-theme-alpine" (gridReady)="onAccountGridReady($event)"
                       [gridOptions]="accountGridOptions" suppressRowClickSelection="true" rowSelection="multiple"
                       (rowSelected)="onRowSelect($event)" [columnDefs]="accountsColumnDefs" [rowData]="accounts"
                       suppressContextMenu="true">
      </ag-grid-angular>
    </div>
  </div>
  <div class="col-md-6 col-sm-6">Portfolio
    <div class="form-group row">
      <div class="col-md-6 margin-top-025">
        <select class="form-control" [disabled]="disableType" [(ngModel)]="portfolioFilterId" (change)="onPortfolioTypeChange($event.target.value)"
                required>
          <option value="0">All Portfolios</option>
          <option value="1">New Portfolio </option>
          <option value="2">Find by Orion HouseHold ID</option>
        </select>
      </div>
      <div class="col-md-6" *ngIf="portfolioSearch">
        <div class="autocomplete-search">
          <p-autoComplete placeholder="Search Portfolio Name" class="autocomplete-search fs-mask" (onSelect)="onPortfolioSelect($event)" [(ngModel)]="searchPorfolioString"
                          field="selectedItemFormat" [minLength]="1" [suggestions]="portfolioSuggestions" (completeMethod)="autoPortfolioSearch($event)" appendTo="body">
            <ng-template let-searchPorfolioString pTemplate="item">
              <ul class="ui-helper-clearfix show-hollding-dropdown">
                <li>
                  <i class="fas fa-cubes entity-icon-portfolio" aria-hidden="true"></i>
                  <span class="fs-mask">{{searchPorfolioString.displayFormat}}</span>
                </li>
              </ul>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="col-md-6" *ngIf="householdSearch">
        <div class="autocomplete-search">
          <p-autoComplete placeholder="Search HouseHold Id" [(ngModel)]="searchHouseHoldString" class="autocomplete-search" (onSelect)="onHouseHoldSelect($event)"
                          field="name" [minLength]="1" [suggestions]="householdSuggestions" (completeMethod)="autoHouseholdSearch($event)" appendTo="body">
          </p-autoComplete>
        </div>
      </div>
    </div>
    <div class="">
      <ag-grid-angular style="width:100%; height: 350px;" rowBuffer="100" class="ag-theme-alpine" (gridReady)="onPortfolioGridReady($event)"
                       (rowClicked)="onPortfolioRowClicked($event)" [gridOptions]="portfolioGridOptions" rowSelection="single" (rowSelected)="onRowSelect($event)"
                       [columnDefs]="portfolioColumnDefs" [rowData]="portfolios"
                       suppressContextMenu="true">
      </ag-grid-angular>
    </div>
  </div>
  <p-footer>
    <span *ngIf="hasDisabledAccounts" class="text-danger">One or more Disabled/Sleeve accounts are excluded from the list</span>
    <button type="button" pButton (click)="assignAccountsToPortfolio();" [disabled]="isDisabledAssignBtn" label="Assign"></button>
    <button type="button" pButton class="p-button-secondary" (click)="resetAssignPopup();" label="Cancel"></button>
  </p-footer>
</p-dialog>

<p-dialog header="" [(visible)]="displayConfirm" modal="true" class="ui-dialog-md">
  <div>This action will re-assign the Account(s) to new Portfolio. Do you wish to continue? </div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" (click)="displayConfirm=false" class="p-button-secondary" label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="assignConformation()" label="Yes"></button>
  </p-footer>
</p-dialog>
