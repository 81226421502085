import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {IAccountSimpleSearch} from '../../models/account';
import {SleeveService} from '../../services/sleeves.service';

@Component({
  selector: 'eclipse-sleeves-auto-complete',
  templateUrl: './sleeves.autocomplete.component.html'
})
export class SleevesAutoCompleteComponent extends BaseComponent {
  suggestions: IAccountSimpleSearch[] = [];
  selectedItem: string;
  searchText: string;
  @Input() isMacAssignment: boolean;
  @Output() parentCallback = new EventEmitter();

  constructor(private _sleeveService: SleeveService) {
    super();
  }

  /** Fires on item select */
  onSleeveSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({value: this.selectedItem});
  }

  /** To get accounts for search autoComplete  */
  loadSuggestions(event) {
    this.searchText = event.query.trim();
    this._sleeveService.getSleevedAccountSearch(event.query.toLowerCase())
      .subscribe((model: IAccountSimpleSearch[]) => {
        if (this.isMacAssignment) {
          // eslint-disable-next-line eqeqeq
          this.suggestions = model.filter(a => a.isDeleted == 0 && a.isSleeve && a.sleeveType == 'Normal');
        } else {
          // eslint-disable-next-line eqeqeq
          this.suggestions = model.filter(a => a.isDeleted == 0 && a.isSleeve);
        }

        // this.suggessions = model.filter(a => a.isDeleted == 0 && a.isSleeve);
        this.formatAccountSuggestions(this.suggestions, event);
      });
  }
}
