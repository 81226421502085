<div class="text-right analytic-analyzer-status">
  <p-chip *ngIf="needAnalytics == 0" label="Analytics is good" icon="fas fa-check fa-sm"
          styleClass="successAlt p-chip-analytics"></p-chip>
  <div *ngIf="needAnalytics == 1">
    <p-chip label="Needs Analytics" icon="fas fa-exclamation fa-sm" styleClass="warningAlt p-chip-analytics"></p-chip>
    <button *ngIf="!isMultiple" (click)="runPortfolioAnalysis([portfolioId])" type="button"
            pButton class="p-button-sm run-now-button" [disabled]="isDisableButton" label="Run Now">
    </button>
    <button *ngIf="isMultiple" (click)="runAnalysis()" type="button"
            pButton class="p-button-sm run-now-button" [disabled]="isDisableButton" label="Run Now">
    </button>
  </div>
  <div *ngIf="needAnalytics == 2">
    <p-chip label="Analytics in Progress" icon="fas fa-spinner fa-pulse fa-sm"
            styleClass="primaryAlt p-chip-analytics"></p-chip>
    <p-chip label="Duration: {{duration}}" icon="far fa-clock fa-sm" styleClass="p-chip-analytics"></p-chip>
  </div>
  <div *ngIf="needAnalytics == 3">
    <p-chip label="Analytics Failed" icon="fas fa-exclamation-triangle fa-sm"
            styleClass="errorAlt p-chip-analytics cursor"
            (click)="isMultiple ? overlayAnalyticsErrorMulti.toggle($event) : overlayAnalyticsErrorSingle.toggle($event)">
    </p-chip>
    <p-overlayPanel #overlayAnalyticsErrorSingle [showCloseIcon]="false" [style]="{width: '450px'}">
      <ng-template pTemplate>
        <span>Error: <small>{{status}}</small></span>
        <div class="text-right">
          <button (click)="runPortfolioAnalysis([portfolioId])" type="button"
                  name="button" class="btn model-analytic-btn run-now-button" [disabled]="isDisableButton">Run Now
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>
    <p-overlayPanel #overlayAnalyticsErrorMulti [showCloseIcon]="false" [style]="{width: '450px'}">
      <ng-template pTemplate>
        <div style="max-height:400px; overflow-y: auto;">
          <table class="table">
            <caption class="hidden-element">Analytics Failed</caption>
            <thead>
            <tr>
              <th scope="col">Portfolio Id</th>
              <th scope="col">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let flag of portfolioFlags">
              <td><span>{{flag.portfolioId}}</span></td>
              <td>
                <span *ngIf="flag.needAnalytics == 0"><i class="fas fa-check-circle text-success"
                                                         aria-hidden="true"></i></span>
                <span *ngIf="flag.needAnalytics == 3"><i class="fas fa-exclamation-triangle text-danger"
                                                         title="{{flag.failedReason}}" aria-hidden="true"></i></span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-right">
          <button (click)="runAnalysis()" type="button" name="button" class="btn model-analytic-btn run-now-button"
                  [disabled]="isDisableButton">Run Now
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>
</div>
