<ng-container *ngIf="!!displayItems?.length && !!totalValue; else noDataTemplate">
  <div class="stacked-progress-bar-container">
    <ng-container *ngFor="let item of displayItems">
      <div [ngStyle]="{
        'background-color': getBgColor(item),
        'width': getPercent(item.value) + '%'
      }" class="stacked-progress-bar-item {{item.class}}" pTooltip="{{ getLabelValue(item) }}" [tooltipPosition]="toolTipPosition">
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #noDataTemplate>
  <div class="stacked-progress-bar-container">
    <div class="stacked-progress-bar-item empty"></div>
  </div>
</ng-template>
