import { Injectable } from '@angular/core';
import { PreferenceService } from '../services/preference.service';
import { SecurityService } from '../services/security.service';
import {
  ILevelPreference,
  ILevelPreferenceSave,
  IPreferences,
  IBatchJobPreferenceParameter
} from '../models/preferences/preference';
import { IPreferenceCommunity, ICustomCommunityPreferenceSave } from '../models/preferences/preferenceCommunity';
import { ICustomSecurityPreferenceSave, ISecurityPreferenceSave } from '../models/preferences/securityPreference';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IPreferenceDataVM, IPreferenceDataVMCompare } from '../viewModels/preference.data';
import { PreferenceEnums, PREFERENCE_NAME } from '../libs/preference.enums';
import { ILocationOptimizationSave } from '../models/preferences/locationOptimization';
import { ICustomRedemptionFeePreferenceSave } from '../models/preferences/redemptionfeepreference';
import { ICustomCustodianRedemptionFeePreferenceSave } from '../models/preferences/custodianredemptionfeepreference';
import { ICustomTransactionFeePreferenceSave } from '../models/preferences/transactionfeepreference';
import { ICustomTradeMinPreferenceSave } from '../models/preferences/trademinpreference';
import { ICustomTradeMaxPreferenceSave } from '../models/preferences/trademaxpreference';
import { ICustomDividendReinvestPreferenceSave } from '../models/preferences/dividendreinvestpreference';
import { ICustomCapitalGainReinvestPreferenceSave } from '../models/preferences/capitalgainreinvestpreference';
import {
  ICustomTradeSettingsPreferenceSave
} from '../models/preferences/tradesettingspreference'; /**23-6-17:: Trade Settings Preference */
import {
  ICustomDefaultPrimaryTeamPreferenceSave
} from '../models/preferences/defaultprimaryteampreference'; /** 7-8-17:: Default Primary Team preference */
import { Utils as Util } from '../core/functions';
import * as _ from 'lodash';
import { SessionHelper } from '../core';
import { ICustomSystematicMultiplierPreferenceSave } from '../models/preferences/systematic';
import { ICustomBillingCashMultiplierPreferenceSave } from '../models/preferences/billingcash';
import { PreferenceHelper } from '../shared/preference/preference.helper';
import { PREFERENCE_LEVEL, RELATED_TYPE_ID, UserType } from '../libs/app.constants';
import {
  IMoneyMarketPreferenceSaveMessage,
  IMoneyMarketAllocationPreference,
  IMoneyMarketFundPreference,
  IMoneyMarketAllocationPreferenceSave,
  IMoneyMarketFundPreferenceSave,
  IMoneyMarketPreferenceSave
} from '../models/preferences/moneyMarketPreference';
import { IAstroInvestorPreferences, IPreferenceOptions, ISaveInvestorPreferencesRequest, ISaveInvestorPreferencesResponse } from '../models/astro';
import { AstroAccountPreferenceValue, AstroInvestorPreferences } from '../libs/astro.constants';
import { SplitIoService } from '../core/feature-flag/splitio.service';

@Injectable()
export class PreferencesBusinessObjects {
  preferenceSaveData: ILevelPreferenceSave;
  securityPreferenceSaveData: ISecurityPreferenceSave;
  astroInvestorPreferencesSave: IAstroInvestorPreferences;
  investorPreferencesSaveRequest: ISaveInvestorPreferencesRequest;
  preferenceOptions: IPreferenceOptions[];
  prefEnums: any;
  nonEditedSecurities: any[];
  editedInvestorPreferences: IPreferenceDataVM;
  astroInvestorPreferencesFeatureFlag: boolean;

  constructor(private readonly preferenceservice: PreferenceService, private readonly securityService: SecurityService, private readonly sessionHelper: SessionHelper,
    private readonly _preferenceHelper: PreferenceHelper, private readonly _splitService: SplitIoService) {
    this.prefEnums = Util.convertEnumValuesToString(PreferenceEnums);
    this.getInvestorPreferenceFeatureFlag();
  }

  /**--------------------------------------------------GET START -------------------------------------------- */

  /* Get Preference levels and Categories */
  getPreferencesData(levelName: string, id: number): Observable<any[]> {
    const user = this.sessionHelper.getUser();
    const isOrionAdmin = user.role.roleTypeId === +UserType.OrionAdmin;

    /** Get Categories and Level preferences */
    if (id !== null) {
      return forkJoin([
        this.preferenceservice.getPrefLevelCategories(levelName),
        this.preferenceservice.getpreferencesForLevel(levelName, id)
          .pipe(map((response: ILevelPreference) => {
            response.preferences = response.preferences.filter(p => !p.requireSuperAdmin || isOrionAdmin);
            this.applySuperAdminHelpText(response.preferences);
            this._preferenceHelper.preserveSpace(response.preferences);
            // SF-154198
            const preference = response.preferences.find(pref => pref.name === 'lowestEstimatedTax');
            if (preference) {
              const taxLotDepletion = response.preferences.find(pref => pref.componentName === 'TaxLotDepletionMethodSortedlist');
              if (preference.value === 'true') {
                taxLotDepletion.disabled = true;
              } else {
                taxLotDepletion.disabled = false;
              }
            }
            this.setWashSalePreference(response.preferences);
            return response;
          })),

        this.preferenceservice.getPrefLevelSubCategories(levelName),
        this.securityService.getSecurityType()
      ]);
    } else { /** Get categories and Master Preferences if no id exists */
      return forkJoin([this.preferenceservice.getPrefLevelCategories(levelName),
        this.preferenceservice.getMasterPreferences(this.prefEnums.master, levelName)
          .pipe(tap((response: ILevelPreference) => {
              response.preferences = response.preferences.filter(p => !p.requireSuperAdmin || isOrionAdmin);
            }),
            tap((response: ILevelPreference) => this.applySuperAdminHelpText(response.preferences))),
        this.preferenceservice.getPrefLevelSubCategories(levelName),
        this.securityService.getSecurityType()
      ]);
    }
  }

  applySuperAdminHelpText(preferences: IPreferences[]): void {
    preferences.filter(pref => pref.requireSuperAdmin).forEach(pref => {
      pref.helpText = `ORION ADMIN PREFERENCE

${pref.helpText}`;
    });
  }

  /* Get custom components data*/
  getCustomComponents(observableData: any, preferencesvm: IPreferenceDataVM) {
    const preferenceCustomDataData = preferencesvm.levelPreferences.preferences.filter(x => x.componentType === this.prefEnums.Custom.toLowerCase());
    let cnt = 0;
    /** Get the Api's data accordingly from apiArray */
    preferenceCustomDataData.forEach(element => {
      switch (element.componentName) {
        /** Community Strategist Enabled List Cascading Control */
        case this.prefEnums.CommunityStrategistEnabledListCascadingControl:
          const communityStrategist = observableData.filter(x => x.preferenceId === element.preferenceId);
          if (communityStrategist.length > 0) {
            preferencesvm.preferenceCommunities = communityStrategist[0];
            preferencesvm.preferenceCommunities.isSetFromParent = false;
            preferencesvm.preferenceCommunities.resetToParent = false;
            if (preferencesvm.preferenceCommunities.strategists.strategistIds === null) {
              preferencesvm.preferenceCommunities.isSetFromParent = true;
              // preferencesvm.preferenceCommunities.strategists = Util.deepClone(preferencesvm.preferenceCommunities.inheritedStrategists);
            }
          }
          break;
        /** Location Optimization DataGrid */
        case this.prefEnums.LocationOptimizationDataGrid:
          const locationOptimization = observableData.filter(x => x.componentName === PreferenceEnums.LocationOptimizationDataGrid);
          if (locationOptimization.length > 0) {
            preferencesvm.preferenceLocationOptimization = locationOptimization[0];
            preferencesvm.preferenceLocationOptimization.isSetFromParent = false;
            preferencesvm.preferenceLocationOptimization.resetToParent = false;
            if (preferencesvm.preferenceLocationOptimization.assetTypes.length === 0) {
              preferencesvm.preferenceLocationOptimization.isSetFromParent = true;
              preferencesvm.preferenceLocationOptimization.assetTypes = Util.deepClone(preferencesvm.preferenceLocationOptimization.inheritedAssetTypes);
            }
          }
          break;

        /** Redemption Fee preferences DataGrid */
        case this.prefEnums.RedemptionFeeDataGrid:
          const redemptionFeePref = observableData.filter(x => x.componentName === PreferenceEnums.RedemptionFeeDataGrid);
          if (redemptionFeePref.length > 0) {
            preferencesvm.preferenceRedemptionFeeData = redemptionFeePref[0];
            preferencesvm.preferenceRedemptionFeeData.isSetFromParent = false;
            preferencesvm.preferenceRedemptionFeeData.resetToParent = false;
            if (preferencesvm.preferenceRedemptionFeeData.redemptionFeePreferences.length === 0) {
              preferencesvm.preferenceRedemptionFeeData.isSetFromParent = true;
              preferencesvm.preferenceRedemptionFeeData.redemptionFeePreferences = Util.deepClone(preferencesvm.preferenceRedemptionFeeData.inheritedRedemptionFeePreferences);
            }
          }
          break;

        /** Custodian Redemption Fee preferences DataGrid */
        case this.prefEnums.CustodianRedemptionFeeDataGrid:
          const custodianRedemptionFeePref = observableData.filter(x => x.componentName === PreferenceEnums.CustodianRedemptionFeeDataGrid);
          if (custodianRedemptionFeePref.length > 0) {
            preferencesvm.preferenceCustodianRedemptionFeeData = custodianRedemptionFeePref[0];
            preferencesvm.preferenceCustodianRedemptionFeeData.isSetFromParent = false;
            preferencesvm.preferenceCustodianRedemptionFeeData.resetToParent = false;
            /** 12-4-17 :: Bind inherited when empty while load time */
            if (preferencesvm.preferenceCustodianRedemptionFeeData.custodianRedemptionFeePreferences.length === 0) {
              preferencesvm.preferenceCustodianRedemptionFeeData.isSetFromParent = true;
              // preferencesvm.preferenceCustodianRedemptionFeeData.custodianRedemptionFeePreferences = Util.deepClone(preferencesvm.preferenceCustodianRedemptionFeeData.inheritedCustodianRedemptionFeePreferences);
            }
          }
          break;

        /** Transaction Fee preferences DataGrid */
        case this.prefEnums.TransactionFeeDataGrid:
          const transactionFeePref = observableData.filter(x => x.componentName === PreferenceEnums.TransactionFeeDataGrid);
          if (transactionFeePref.length > 0) {
            preferencesvm.preferenceTransactionFeeData = transactionFeePref[0];
            /** 12-4-17 :: Bind inherited when empty while load time */
            preferencesvm.preferenceTransactionFeeData.isSetFromParent = false;
            preferencesvm.preferenceTransactionFeeData.resetToParent = false;
            if (preferencesvm.preferenceTransactionFeeData.transactionFeePreferences.length === 0) {
              preferencesvm.preferenceTransactionFeeData.isSetFromParent = true;
              preferencesvm.preferenceTransactionFeeData.transactionFeePreferences = Util.deepClone(preferencesvm.preferenceTransactionFeeData.inheritedTransactionFeePreferences);
            }
          }
          break;
        /** Trade Min preferences DataGrid */
        case this.prefEnums.TradeMinDataGrid:
          const tradeMinPref = observableData.filter(x => x.componentName === PreferenceEnums.TradeMinDataGrid);
          if (tradeMinPref.length > 0) {
            preferencesvm.preferenceTradeMinData = tradeMinPref[0];
            preferencesvm.preferenceTradeMinData.isSetFromParent = false;
            preferencesvm.preferenceTradeMinData.resetToParent = false;
            if (preferencesvm.preferenceTradeMinData.tradeMinPreferences.length === 0) {
              preferencesvm.preferenceTradeMinData.isSetFromParent = true;
              preferencesvm.preferenceTradeMinData.tradeMinPreferences = Util.deepClone(preferencesvm.preferenceTradeMinData.inheritedTradeMinPreferences);
            }
          }
          break;
        /** Trade Max preferences DataGrid */
        case this.prefEnums.TradeMaxDataGrid:
          const tradeMaxPref = observableData.filter(x => x.componentName === PreferenceEnums.TradeMaxDataGrid);
          if (tradeMaxPref.length > 0) {
            preferencesvm.preferenceTradeMaxData = tradeMaxPref[0];
            preferencesvm.preferenceTradeMaxData.isSetFromParent = false;
            preferencesvm.preferenceTradeMaxData.resetToParent = false;
            if (preferencesvm.preferenceTradeMaxData.tradeMaxPreferences.length === 0) {
              preferencesvm.preferenceTradeMaxData.isSetFromParent = true;
              preferencesvm.preferenceTradeMaxData.tradeMaxPreferences = Util.deepClone(preferencesvm.preferenceTradeMaxData.inheritedTradeMaxPreferences);
            }
          }
          break;
        /** Dividend Reinvest preferences DataGrid */
        case this.prefEnums.DividendReinvestDataGrid:
          const dividendReinvestPref = observableData.filter(x => x.componentName === PreferenceEnums.DividendReinvestDataGrid);
          if (dividendReinvestPref.length > 0) {
            preferencesvm.preferenceDividendReinvestData = dividendReinvestPref[0];
            preferencesvm.preferenceDividendReinvestData.isSetFromParent = false;
            preferencesvm.preferenceDividendReinvestData.resetToParent = false;
            if (preferencesvm.preferenceDividendReinvestData.dividendReinvestPreferences.length === 0) {
              preferencesvm.preferenceDividendReinvestData.isSetFromParent = true;
              preferencesvm.preferenceDividendReinvestData.dividendReinvestPreferences = Util.deepClone(preferencesvm.preferenceDividendReinvestData.inheritedDividendReinvestPreferences);
            }
          }
          break;
        /** Capital Gain Reinvest preferences DataGrid */
        case this.prefEnums.CapitalGainReinvestDataGrid:
          const capitalGainReinvestPref = observableData.filter(x => x.componentName === PreferenceEnums.CapitalGainReinvestDataGrid);
          if (capitalGainReinvestPref.length > 0) {
            preferencesvm.preferenceCapitalGainReinvestData = capitalGainReinvestPref[0];
            preferencesvm.preferenceCapitalGainReinvestData.isSetFromParent = false;
            preferencesvm.preferenceCapitalGainReinvestData.resetToParent = false;
            if (preferencesvm.preferenceCapitalGainReinvestData.capitalGainReinvestPreferences.length === 0) {
              preferencesvm.preferenceCapitalGainReinvestData.isSetFromParent = true;
              preferencesvm.preferenceCapitalGainReinvestData.capitalGainReinvestPreferences = Util.deepClone(preferencesvm.preferenceCapitalGainReinvestData.inheritedCapitalGainReinvestPreferences);
            }
          }
          break;

        case this.prefEnums.SystematicMultiplierLink:
          const systematicMultiplierPref = observableData.filter(x => x.componentName === PreferenceEnums.SystematicMultiplierLink);
          if (systematicMultiplierPref.length > 0) {
            preferencesvm.preferenceSystematicMultiplierData = systematicMultiplierPref[0];
            preferencesvm.preferenceSystematicMultiplierData.isSetFromParent = false;
            preferencesvm.preferenceSystematicMultiplierData.resetToParent = false;
            if (preferencesvm.preferenceSystematicMultiplierData.systematicMultiplierPreferences.length === 0) {
              preferencesvm.preferenceSystematicMultiplierData.isSetFromParent = true;
              preferencesvm.preferenceSystematicMultiplierData.systematicMultiplierPreferences = Util.deepClone(preferencesvm.preferenceSystematicMultiplierData.inheritedSystematicMultiplierPreferences);
            }
          }
          break;

        case this.prefEnums.BillingCashMultiplierLink:
          const billingCashMultiplierPref = observableData.filter(x => x.componentName === PreferenceEnums.BillingCashMultiplierLink);
          if (billingCashMultiplierPref.length > 0) {
            preferencesvm.preferenceBillingCashMultiplierData = billingCashMultiplierPref[0];
            preferencesvm.preferenceBillingCashMultiplierData.isSetFromParent = false;
            preferencesvm.preferenceBillingCashMultiplierData.resetToParent = false;
            if (preferencesvm.preferenceBillingCashMultiplierData.billingCashMultiplierPreferences.length === 0) {
              preferencesvm.preferenceBillingCashMultiplierData.isSetFromParent = true;
              preferencesvm.preferenceBillingCashMultiplierData.billingCashMultiplierPreferences = Util.deepClone(preferencesvm.preferenceBillingCashMultiplierData.inheritedBillingCashMultiplierPreferences);
            }
          }
          break;

        /** Trade Settings preferences DataGrid */
        case this.prefEnums.TradeSettingsDataGrid:
          const tradeSettingsPref = observableData.filter(x => x.componentName === PreferenceEnums.TradeSettingsDataGrid);
          if (tradeSettingsPref !== null) {
            preferencesvm.preferenceTradeSettingsData = tradeSettingsPref[0];
            preferencesvm.preferenceTradeSettingsData.isSetFromParent = false;
            preferencesvm.preferenceTradeSettingsData.resetToParent = false;
            if (preferencesvm.preferenceTradeSettingsData.tradeSettingsPreferences.length === 0) {
              preferencesvm.preferenceTradeSettingsData.isSetFromParent = true;
              preferencesvm.preferenceTradeSettingsData.tradeSettingsPreferences = Util.deepClone(preferencesvm.preferenceTradeSettingsData.inheritedTradeSettingsPreferences);
            }
          }
          break;

        /** Default Primary Team preferences Component */
        case this.prefEnums.DefaultPrimaryTeamDropdownSearch:
          const defaultPrimaryTeamPref = observableData.filter(x => x.componentName === PreferenceEnums.DefaultPrimaryTeamDropdownSearch);
          if (defaultPrimaryTeamPref !== null) {
            preferencesvm.preferenceDefaultPrimaryTeamData = defaultPrimaryTeamPref[0];
            preferencesvm.preferenceDefaultPrimaryTeamData.isSetFromParent = false;
            preferencesvm.preferenceDefaultPrimaryTeamData.resetToParent = false;
            if (preferencesvm.preferenceDefaultPrimaryTeamData.defaultPrimaryTeamPreferences.length === 0) {
              preferencesvm.preferenceDefaultPrimaryTeamData.isSetFromParent = true;
              // preferencesvm.preferenceDefaultPrimaryTeamData.defaultPrimaryTeamPreferences = Util.deepClone(preferencesvm.preferenceDefaultPrimaryTeamData.inheritedDefaultPrimaryTeamPreferences);
            }
          }
          break;

        case this.prefEnums.MoneyMarketAllocationGrid:
          const mmAllocationPreference = observableData.filter(x => x.componentName === PreferenceEnums.MoneyMarketAllocationGrid);
          if (mmAllocationPreference) {
            preferencesvm.moneyMarketAllocationPreferenceData = mmAllocationPreference[0];
            preferencesvm.moneyMarketAllocationPreferenceData.isSetFromParent = false;
            preferencesvm.moneyMarketAllocationPreferenceData.resetToParent = false;
            if (preferencesvm.moneyMarketAllocationPreferenceData.moneyMarketAllocations?.length === 0) {
              preferencesvm.moneyMarketAllocationPreferenceData.isSetFromParent = true;
              preferencesvm.moneyMarketAllocationPreferenceData.moneyMarketAllocations = Util.deepClone(preferencesvm.moneyMarketAllocationPreferenceData.inheritedMoneyMarketAllocations);
            }
          }
          break;

        case this.prefEnums.MoneyMarketFundsGrid:
          const mmFundPreference = observableData.filter(x => x.componentName === PreferenceEnums.MoneyMarketFundsGrid);
          if (mmFundPreference) {
            preferencesvm.moneyMarketFundPreferenceData = mmFundPreference[0];
            preferencesvm.moneyMarketFundPreferenceData.isSetFromParent = false;
            preferencesvm.moneyMarketFundPreferenceData.resetToParent = false;
            if (preferencesvm.moneyMarketFundPreferenceData.moneyMarketFunds?.length === 0) {
              preferencesvm.moneyMarketFundPreferenceData.isSetFromParent = true;
              preferencesvm.moneyMarketFundPreferenceData.moneyMarketFunds = Util.deepClone(preferencesvm.moneyMarketFundPreferenceData.inheritedMoneyMarketFunds);
            }
          }
          break;
      }
      cnt++;
    });
  }

  /**------------------------------------------------- GET END -------------------------------------------------*/

  /** ----------------------------------------------- BUILD CUSTOM COMPONENT API START--------------------------*/

  /** Prepare an array of ApiServiceCalls for Custom Components */
  buildApiServiceCallsForCustomComponents(levelName: string,
                                          recordId: number,
                                          preferenceData: any) {
    const apiArray = [];
    /** Fill the custom component apis within Array */
    preferenceData.forEach(element => {
      switch (element.componentName) {
        /** Community Strategist Enabled List Cascading Control */
        case this.prefEnums.CommunityStrategistEnabledListCascadingControl:
          apiArray.push(this.preferenceservice.getPreferenceCommunities(levelName,
            recordId,
            this.prefEnums.communityStrategist,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId
          ));
          break;
        /** Location Optimization DataGrid */
        case this.prefEnums.LocationOptimizationDataGrid:
          apiArray.push(this.preferenceservice.getLocationOptimizationPreferences(levelName,
            recordId,
            this.prefEnums.locationOptimization,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId,
            element.value));
          break;
        /** Redemption Fee preferences Data Grid */
        case this.prefEnums.RedemptionFeeDataGrid:
          apiArray.push(this.preferenceservice.getRedemptionFeePreferences(levelName,
            recordId,
            this.prefEnums.redemptionFeePreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Custodian Redemption Fee preferences Data Grid */
        case this.prefEnums.CustodianRedemptionFeeDataGrid:
          apiArray.push(this.preferenceservice.getCustodianRedemptionFeePreferences(levelName,
            recordId,
            this.prefEnums.custodianRedemptionFeePreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Redemption Fee preferences Data Grid */
        case this.prefEnums.TransactionFeeDataGrid:
          apiArray.push(this.preferenceservice.getTransactionFeePreferences(levelName,
            recordId,
            this.prefEnums.transactionFeePreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Trade Min preferences Data Grid */
        case this.prefEnums.TradeMinDataGrid:
          apiArray.push(this.preferenceservice.getTradeMinPreferences(levelName,
            recordId,
            this.prefEnums.tradeMinPreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Trade Max preferences Data Grid */
        case this.prefEnums.TradeMaxDataGrid:
          apiArray.push(this.preferenceservice.getTradeMaxPreferences(levelName,
            recordId,
            this.prefEnums.tradeMaxPreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Dividend Reinvest preferences Data Grid */
        case this.prefEnums.DividendReinvestDataGrid:
          apiArray.push(this.preferenceservice.getDividendReinvestPreferences(levelName,
            recordId,
            this.prefEnums.dividendReinvestPreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Capital Gain Reinvest preferences Data Grid */
        case this.prefEnums.CapitalGainReinvestDataGrid:
          apiArray.push(this.preferenceservice.getCapitalGainReinvestPreferences(levelName,
            recordId,
            this.prefEnums.capitalGainReinvestPreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Trade Settings preferences Data Grid */
        case this.prefEnums.TradeSettingsDataGrid:
          apiArray.push(this.preferenceservice.getTradeSettingsPreferences(levelName,
            recordId,
            this.prefEnums.tradeSettingsPreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        /** Default Primary Team preferences Custom component */
        case this.prefEnums.DefaultPrimaryTeamDropdownSearch:
          apiArray.push(this.preferenceservice.getDefaultPrimaryTeamPreferences(levelName,
            recordId,
            this.prefEnums.defaultPrimaryTeamPreference,
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        case this.prefEnums.SystematicMultiplierLink:
          apiArray.push(this.preferenceservice.getSystematicMultiplierPreferences(levelName,
            recordId,
            'systematicMultiplierPreference',
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        case this.prefEnums.BillingCashMultiplierLink:
          apiArray.push(this.preferenceservice.getBillingCashMultiplierPreferences(levelName,
            recordId,
            'billingCashMultiplierPreference',
            element.preferenceId,
            element.id,
            element.inheritedFromValueId));
          break;
        case this.prefEnums.MoneyMarketAllocationGrid:
          const allocationRelatedType = RELATED_TYPE_ID[levelName.toUpperCase()];
          apiArray.push(this.preferenceservice.getMoneyMarketAllocationPreference(allocationRelatedType, recordId));
          break;
        case this.prefEnums.MoneyMarketFundsGrid:
          const fundRelatedType = RELATED_TYPE_ID[levelName.toUpperCase()];
          apiArray.push(this.preferenceservice.getMoneyMarketFundPreference(fundRelatedType, recordId));
          break;
      }
    });
    return apiArray;
  }

  getAstroInvestorPreferences(accountId: number): Observable<IAstroInvestorPreferences> {
    return this.preferenceservice.getInvestorPreferencesForAccount(accountId);
  }

  /** ----------------------------------------------- BUILD CUSTOM COMPONENT API END ----------------------------*/

  /**--------------------------------------------- FETCH Edited values - START ----------------------------------*/
  /** Fetch the altered drop down values from form */
  fetchEditedDropdownSelectedOptions(element: any, apiPreferences: any, preferencesToSave: any) {
    const filteredPreference = apiPreferences.filter(x => x.componentType === this.prefEnums.Default.toLowerCase()
      && x.preferenceId === element.preferenceId);
    let isSaveToDB: boolean = false;

    /** Here element object means either Changed or inherited set while Load time
     *  Filtered object means Load time values
     */
    if (element.isSetFromParent) {
      if (element.selectedOptions.length === 0) {
        // return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        isSaveToDB = false;
      } else if (element.resetToParent) {
        isSaveToDB = false;
      } else {
        isSaveToDB = true;
        filteredPreference[0].selectedOptions = element.selectedOptions;
      }
    } else if (!element.isSetFromParent) { /** While Data is present on Load */
      if (element.selectedOptions.length === 0) {
        isSaveToDB = true;
        filteredPreference[0].selectedOptions = [];
        filteredPreference[0].value = null;
      } else {
        if (!element.resetToParent && filteredPreference[0].selectedOptions[0].id !== element.selectedOptions[0].id) {
          isSaveToDB = true;
          filteredPreference[0].selectedOptions = [];
          filteredPreference[0].selectedOptions = element.selectedOptions;
        }
        // Only for MAC
        if (!element.resetToParent && filteredPreference[0].selectedOptions[0].id === element.selectedOptions[0].id && (element.componentType === 'MACBUY' || element.componentType === 'MACSELL')) {
          isSaveToDB = true;
          filteredPreference[0].selectedOptions = [];
          filteredPreference[0].selectedOptions = element.selectedOptions;
        } else if (element.resetToParent) {
          filteredPreference[0].selectedOptions = [];
          filteredPreference[0].value = null;
          isSaveToDB = true;
        }
      }
    }

    if (isSaveToDB) {
      preferencesToSave.push({
        id: filteredPreference[0].id,
        preferenceId: filteredPreference[0].preferenceId,
        name: filteredPreference[0].name,
        valueType: filteredPreference[0].valueType,
        componentType: filteredPreference[0].componentType,
        componentName: filteredPreference[0].componentName,
        selectedOptions: filteredPreference[0].selectedOptions,
        value: filteredPreference[0].value,
        resetToParent: element.resetToParent
      });
    }
    return preferencesToSave;
  }

  /** Fetch the edited checkbox and text box values from form */
  fetchEditedCheckboxnTextValues(element: any, apiPreferences: any, preferencesToSave: any) {
    let filteredPreference: any;
    let isSaveToDB: boolean = false;
    if ((element.componentType === this.prefEnums.Default.toLowerCase()) || (element.componentType === 'MACBUY') || (element.componentType === 'MACSELL')) {
      filteredPreference = apiPreferences.filter(x => x.componentType === this.prefEnums.Default.toLowerCase()
        && x.preferenceId === element.preferenceId);

      /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRIDE/RESET/OVERRIDE*/
      if (element.isSetFromParent) {
        if (element.value === null) {
          isSaveToDB = false;
        } else if (element.resetToParent) {
          isSaveToDB = false;
        } else {
          isSaveToDB = true;
        }
      } else if (!element.isSetFromParent) { /** While Data is present on Load */
        if (element.value === null) {
          isSaveToDB = true;
        } else {
          /*Reverted OE-3100 for OE-3372 and OE-3377 Preference are not saving when preference reset is done and clicked on save.
          And added the OR condition for handling reserve cash preference not saving.
          Fix for OE-3100 filteredPreference[0].value != element.value  changed to filteredPreference[0].value != null
          Fix for DEVOPS-25200 due to data type difference between values example (false == "false") treated as false,
          Preference are not saving when preference reset is done and clicked on save */
          if (!element.resetToParent && (filteredPreference[0].value !== element.value || filteredPreference[0].indicatorValue !== element.indicatorValue)) {
            isSaveToDB = true;
          } else if (element.resetToParent && Util.convertIntoBooleanValue(element.value) === Util.convertIntoBooleanValue(element.inheritedValue)) {
            element.value = null;
            isSaveToDB = true;
          }
        }
      }

      if (isSaveToDB) {
        const preferenceData = apiPreferences.filter(x => x.componentType === this.prefEnums.Default.toLowerCase()
          && x.preferenceId === element.preferenceId);
        preferenceData[0].value = element.value;
        preferencesToSave.push({
          id: preferenceData[0].id,
          preferenceId: preferenceData[0].preferenceId,
          name: preferenceData[0].name,
          valueType: preferenceData[0].valueType,
          componentType: preferenceData[0].componentType,
          componentName: preferenceData[0].componentName,
          selectedOptions: preferenceData[0].selectedOptions,
          value: element.value,
          selectedIndicatorValue: element.indicatorValue,
          resetToParent: element.resetToParent
        });
      }
    }
    return preferencesToSave;
  }

  /** Fetch the edited Sorted list values from form */
  fetchEditedSortedListSelectedOptions(vmPreferenceItem, getApiSortedOptions, preferencesToSave) {
    const getDataObjects = (Object.keys(getApiSortedOptions).length > 0) ?
      getApiSortedOptions : null;

    const postDataObjects = (Object.keys(vmPreferenceItem.selectedOptions).length > 0) ?
      vmPreferenceItem.selectedOptions : vmPreferenceItem.options;

    let isSaveToDB = false;
    const sortedOptions = [];
    let cnt = 1;
    const isReordered = JSON.stringify(getDataObjects) === JSON.stringify(postDataObjects);

    if (vmPreferenceItem.isSetFromParent) {
      if (vmPreferenceItem.resetToParent) {
        isSaveToDB = false;
      } else if (!vmPreferenceItem.resetToParent && !isReordered) {
        isSaveToDB = true;
        postDataObjects.forEach(element => {
          sortedOptions.push({id: element.id, name: element.name, order: cnt});
          cnt++;
        });
      }
    } else if (!vmPreferenceItem.isSetFromParent) { /** While Data is present on Load */
      if (vmPreferenceItem.resetToParent) { // empty
        isSaveToDB = true;
      } else if (!vmPreferenceItem.resetToParent && !isReordered) {
        isSaveToDB = true;
        postDataObjects.forEach(element => {
          sortedOptions.push({id: element.id, name: element.name, order: cnt});
          cnt++;
        });
      }
    }

    if (isSaveToDB) {
      preferencesToSave.push({
        id: vmPreferenceItem.id,
        preferenceId: vmPreferenceItem.preferenceId,
        name: vmPreferenceItem.name,
        valueType: vmPreferenceItem.valueType,
        componentType: vmPreferenceItem.componentType,
        componentName: vmPreferenceItem.componentName,
        selectedOptions: sortedOptions,
        value: vmPreferenceItem.value,
        resetToParent: vmPreferenceItem.resetToParent
      });
    }
    return preferencesToSave;
  }

  /** Fetch the edited LocationOptimization values */
  fetchEditedLocationOptimizationOptions(locationOptimization: any, apiPreferences: any) {
    return <ILocationOptimizationSave>{
      id: locationOptimization.id,
      preferenceId: locationOptimization.preferenceId,
      assetType: locationOptimization.assetType,
      assetTypes: (Object.keys(locationOptimization.assetTypes).length > 0) ? locationOptimization.assetTypes : []
    };
  }

  /** Fetch the edited communityStrategistPreference values */
  fetchEditedcommunityStrategistPreference(communityStrategistPreference: any, apiPreferences: any) {

    return <ICustomCommunityPreferenceSave>{
      id: communityStrategistPreference.id,
      preferenceId: communityStrategistPreference.preferenceId,
      strategists:
        <IPreferenceCommunity>{
          strategistIds: communityStrategistPreference.strategists.strategistIds,
          modelAccessLevel: communityStrategistPreference.strategists.modelAccessLevel,
          communityModels: communityStrategistPreference.strategists.communityModels
        }
    };
  }

  /** Fetch the edited Security Preference values */
  fetchEditedsecurityPreferrencePreferenceNew(securityPreference: any) {
    /** fetch non edited securities from the collection :: which are not edited/added/deleted */
    this.nonEditedSecurities = (Object.keys(securityPreference.securityPreferences).length > 0) ? securityPreference.securityPreferences.filter(x => !x.isEdit && !x.isAdd) : [];
    return <ICustomSecurityPreferenceSave>{
      id: securityPreference.id,
      preferenceId: securityPreference.preferenceId,
      addSecurities: Util.deepClone(securityPreference.securityPreferences).filter(x => x.isAdd && !x.isEdit), // SECURITY PREFERENCES BUCKETING ARRAYS
      editSecurities: Util.deepClone(securityPreference.securityPreferences).filter(x => x.isEdit && !x.isAdd),
      deleteSecurities: (Util.deepClone(securityPreference.deletedIds) === null) ? [] : Util.deepClone(securityPreference.deletedIds),
      // securities: (Object.keys(securityPreference.securityPreferences).length > 0) ? securityPreference.securityPreferences.filter(x => !x.isEdit && !x.isAdd) : [],
      resetToParent: securityPreference.resetToParent,
      isSetFromParent: securityPreference.isSetFromParent
    };
  }

  /** Fetch the edited Redemption Fee Preference values */
  fetchEditedRedemptionFeePreference(redemptionFeePreference: any, apiPreferences: any) {
    return <ICustomRedemptionFeePreferenceSave>{
      id: redemptionFeePreference.id,
      preferenceId: redemptionFeePreference.preferenceId,
      redemptionFees: (Object.keys(redemptionFeePreference.redemptionFeePreferences).length > 0) ? redemptionFeePreference.redemptionFeePreferences : null
    };
  }

  /** Fetch the edited Custodian Redemption Fee Preference values */
  fetchEditedCustodianRedemptionFeePreference(custodianRedemptionFeePreference: any, apiPreferences: any) {
    return <ICustomCustodianRedemptionFeePreferenceSave>{
      id: custodianRedemptionFeePreference.id,
      preferenceId: custodianRedemptionFeePreference.preferenceId,
      custodianRedemptionFees: (Object.keys(custodianRedemptionFeePreference.custodianRedemptionFeePreferences).length > 0) ? custodianRedemptionFeePreference.custodianRedemptionFeePreferences : null
    };
  }

  /** Fetch the edited Transaction Fee Preference values */
  fetchEditedTransactionFeePreference(transactionFeePreference: any, apiPreferences: any) {
    return <ICustomTransactionFeePreferenceSave>{
      id: transactionFeePreference.id,
      preferenceId: transactionFeePreference.preferenceId,
      transactionFees: (Object.keys(transactionFeePreference.transactionFeePreferences).length > 0) ? transactionFeePreference.transactionFeePreferences : null
    };
  }

  /** Fetch the edited Trade Min Preference values */
  fetchEditedTradeMinPreference(tradeMinPreference: any, apiPreferences: any) {

    return <ICustomTradeMinPreferenceSave>{
      id: tradeMinPreference.id,
      preferenceId: tradeMinPreference.preferenceId,
      tradeMin: (Object.keys(tradeMinPreference.tradeMinPreferences).length > 0) ? tradeMinPreference.tradeMinPreferences : null
    };
  }

  /** Fetch the edited Trade Max Preference values */
  fetchEditedTradeMaxPreference(tradeMaxPreference: any, apiPreferences: any) {
    return <ICustomTradeMaxPreferenceSave>{
      id: tradeMaxPreference.id,
      preferenceId: tradeMaxPreference.preferenceId,
      tradeMax: (Object.keys(tradeMaxPreference.tradeMaxPreferences).length > 0) ? tradeMaxPreference.tradeMaxPreferences : null
    };
  }

  /** Fetch the edited Dividend Reinvest Preference values */
  fetchEditedDividendReinvestPreference(dividendReinvestPreference: any, apiPreferences: any) {
    return <ICustomDividendReinvestPreferenceSave>{
      id: dividendReinvestPreference.id,
      preferenceId: dividendReinvestPreference.preferenceId,
      dividendReinvests: (Object.keys(dividendReinvestPreference.dividendReinvestPreferences).length > 0) ? dividendReinvestPreference.dividendReinvestPreferences : null
    };
  }

  /** Fetch the edited Capital Gain Reinvest Preference values */
  fetchEditedCapitalGainReinvestPreference(capitalGainReinvestPreference: any, apiPreferences: any) {
    return <ICustomCapitalGainReinvestPreferenceSave>{
      id: capitalGainReinvestPreference.id,
      preferenceId: capitalGainReinvestPreference.preferenceId,
      capitalGainReinvests: (Object.keys(capitalGainReinvestPreference.capitalGainReinvestPreferences).length > 0) ? capitalGainReinvestPreference.capitalGainReinvestPreferences : null
    };
  }

  /** Fetch the edited Trade Settings Preference values */
  fetchEditedTradeSettingsPreference(tradeSettingsPreference: any, apiPreferences: any) {
    return <ICustomTradeSettingsPreferenceSave>{
      id: tradeSettingsPreference.id,
      preferenceId: tradeSettingsPreference.preferenceId,
      tradeSettings: (Object.keys(tradeSettingsPreference.tradeSettingsPreferences).length > 0) ? tradeSettingsPreference.tradeSettingsPreferences : null
    };
  }

  /** Fetch the edited default primary; Preference values */
  fetchEditedDefaultPrimaryTeamPreference(defaultPrimaryTeamPreference: any, apiPreferences: any) {
    return <ICustomDefaultPrimaryTeamPreferenceSave>{
      id: defaultPrimaryTeamPreference.id,
      preferenceId: defaultPrimaryTeamPreference.preferenceId,
      defaultPrimaryTeams: (Object.keys(defaultPrimaryTeamPreference.defaultPrimaryTeamPreferences).length > 0) ? defaultPrimaryTeamPreference.defaultPrimaryTeamPreferences : []
    };
  }

  fetchEditedSystematicMultiplierPreference(systematicMultiplierPreference: any) {
    return <ICustomSystematicMultiplierPreferenceSave>{
      id: systematicMultiplierPreference.id,
      preferenceId: systematicMultiplierPreference.preferenceId,
      systematicMultipliers: (Object.keys(systematicMultiplierPreference.systematicMultiplierPreferences).length > 0) ? systematicMultiplierPreference.systematicMultiplierPreferences : null
    };
  }

  fetchEditedBillingCashMultiplierPreference(billingCashMultiplierPreference: any) {
    return <ICustomBillingCashMultiplierPreferenceSave>{
      id: billingCashMultiplierPreference.id,
      preferenceId: billingCashMultiplierPreference.preferenceId,
      billingCashMultipliers: (Object.keys(billingCashMultiplierPreference.billingCashMultiplierPreferences).length > 0) ? billingCashMultiplierPreference.billingCashMultiplierPreferences : null
    };
  }

  /**----------------------------------------------FETCH Edited values - END------------------------------------*/

  /**------------------------------------------- SET UI Values START -------------------------------------------*/
  /** Set the drop down selected option to Value field*/
  setDropdownValue(preferenceViewModel: any, globalReset: boolean) {
    preferenceViewModel.levelPreferences.preferences.forEach(preference => {
      preference.isSetFromParent = false;
      preference.resetToParent = globalReset;
      preference.isNotReSetToParent = true;
      if (preference.componentName !== null) {
        if (preference.valueType.toLowerCase() === 'sortedlist') {
          if (preference.selectedOptions.length === 0) {
            preference.selectedOptions = Util.deepClone(preference.inheritedSelectedOptions);
            preference.isSetFromParent = true;
            preference.isNotReSetToParent = false;
          }
          preference.selectedOptions = Util.sortBy(Util.deepClone(preference.selectedOptions), 'order');
        } else if (preference.componentName.toLowerCase() === this.prefEnums.Dropdown.toLowerCase()) {
          if (preference.selectedOptions.length > 0) {
            preference.value = preference.selectedOptions[0].id;
            preference.isSetFromParent = false;
          } else {
            preference.isSetFromParent = true;
          }
        } else if (preference.componentName.toLowerCase() === 'textbox') {
          if (preference.valueType.toLowerCase() === 'decimal') {
            /** Set validation for number type - Decimal */
            preference.valueType = 'Number';
            // preference.pattern = "[0-9]?[0-9]?(\.[0-9][0-9]?)?{0,2}";
            preference.pattern = /^([0-9])?(\.[0-9]{1,2})?$/;
          } else {
            preference.pattern = '[0-9]*';
          }
          // Based on Indicator Value Set the Max & MIN Values
          if (preference.indicatorValue !== null && preference.indicatorOptions.length > 0) {
            preference.maxValue = preference.indicatorOptions.find(x => x.name === preference.indicatorValue).maxValue;
            preference.minValue = preference.indicatorOptions.find(x => x.name === preference.indicatorValue).minValue;
          } else {
            preference.minValue = (preference.minValue !== null) ? preference.minValue : 0;
            preference.maxValue = (preference.maxValue !== null) ? preference.maxValue : Number.MAX_VALUE;
          }
          preference.watermarkText = (preference.watermarkText !== null) ? preference.watermarkText : 'Please enter value';
          if (preference.value !== null && preference.value !== '') {
            /** NEW LOGIC 7-4-17 :: MANUAL OVERRIDE ONLY :: CURRENT LEVEL */
            preference.isSetFromParent = false;
          } else {
            preference.isSetFromParent = true;
          }
        } else if (preference.componentName.toLowerCase() === 'checkbox') {
          if (preference.value !== null) {
            /** NEW LOGIC 7-4-17 :: MANUAL OVERRIDE ONLY :: CURRENT LEVEL */
            preference.isSetFromParent = false;
          } else {
            preference.isSetFromParent = true;
          }
        }
        preference.isValid = true;
      }

    });
  }

  /**------------------------------------------- SET UI Values END ---------------------------------------------*/

  /**--------------------------------------- NEW SAVE/UPDATE START ----------------------------------------------- */
  /** get ReadyToSave Preferences DATA for saving*/
  getReadyToSavePreferences(prefrenceVM: IPreferenceDataVM, apiPreferences: any) {
    /** LOCAL Method variables */
    const prefEnums = Util.convertEnumValuesToString(PreferenceEnums);
    const preferencesToSave: ILevelPreferenceSave = <ILevelPreferenceSave>{
      level: prefrenceVM.levelPreferences.level,
      id: prefrenceVM.levelPreferences.id,
      defaultPreferences: [],
      locationOptimizationPreference: {},
      communityStrategistPreference: {},
      custodianRedemptionFeePreferences: {},
      redemptionFeePreferences: {},
      transactionFeePreferences: {},
      tradeMinPreferences: {},
      tradeMaxPreferences: {},
      dividendReinvestPreferences: {},
      capitalGainReinvestPreferences: {},
      tradeSettingsPreferences: {},
      defaultPrimaryTeamPreferences: {},
      systematicMultiplierPreferences: {},
      billingCashMultiplierPreferences: {},
      moneyMarketAllocationPreference: this.fetchEditedMoneyMarketAllocationPreference(prefrenceVM),
      moneyMarketFundPreference: this.fetchEditedMoneyMarketFundPreference(prefrenceVM),
      astroInvestorPreferencesSave: this.fetchEditedInvestorPreferences(prefrenceVM, apiPreferences)
    };
    prefrenceVM.levelPreferences.preferences = prefrenceVM.levelPreferences.preferences
    .filter(pref => pref.categoryType !== AstroInvestorPreferences.AstroPreferences);

    prefrenceVM.levelPreferences.preferences.forEach(element => {
      switch (element.componentType) {
        /** For Default preferences fetch the edited drop down selected options */
        case prefEnums.Default.toLowerCase():
        case 'MACBUY':
        case 'MACSELL':
          switch (element.componentName.toLowerCase()) {
            case prefEnums.Dropdown.toLowerCase():
              preferencesToSave.defaultPreferences = this.fetchEditedDropdownSelectedOptions(element,
                apiPreferences.preferences,
                preferencesToSave.defaultPreferences);
              break;
            default:
              preferencesToSave.defaultPreferences = this.fetchEditedCheckboxnTextValues(element,
                apiPreferences.preferences,
                preferencesToSave.defaultPreferences);
              break;
          }
          break;
        /** For custom preferences fetch the edited/re-ordered sorted options */
        case prefEnums.Custom.toLowerCase():
          switch (element.componentName.toLowerCase()) {
            case prefEnums.PriorityRankingSortedlist.toLowerCase():
              const priorityrankingsortedlist = apiPreferences.preferences.filter(x => (x.componentName !== null) ? x.componentName.toLowerCase() === prefEnums.PriorityRankingSortedlist.toLowerCase() : null);
              preferencesToSave.defaultPreferences = this.fetchEditedSortedListSelectedOptions(element,
                priorityrankingsortedlist[0].selectedOptions,
                preferencesToSave.defaultPreferences);
              break;
            case prefEnums.TaxLotDepletionMethodSortedlist.toLowerCase():
              const taxlotdepletionmethodSortedlist = apiPreferences.preferences.filter(x => (x.componentName !== null) ? x.componentName.toLowerCase() === prefEnums.TaxLotDepletionMethodSortedlist.toLowerCase() : null);
              preferencesToSave.defaultPreferences = this.fetchEditedSortedListSelectedOptions(element,
                taxlotdepletionmethodSortedlist[0].selectedOptions,
                preferencesToSave.defaultPreferences);
              break;
            case prefEnums.LocationOptimizationDataGrid.toLowerCase():
              preferencesToSave.locationOptimizationPreference = this.fetchEditedLocationOptimizationOptions(prefrenceVM.preferenceLocationOptimization,
                apiPreferences.preferenceLocationOptimization);
              break;

            case prefEnums.CommunityStrategistEnabledListCascadingControl.toLowerCase():
              preferencesToSave.communityStrategistPreference = this.fetchEditedcommunityStrategistPreference(prefrenceVM.preferenceCommunities,
                apiPreferences.preferenceCommunities);
              break;

            case prefEnums.SecurityDataGrid.toLowerCase():
              // preferencesToSave.securityPreferences = this.fetchEditedsecurityPreferrencePreference(prefrenceVM.preferenceSecurityData,
              //     apiPreferences.preferenceSecurityData);
              break;
            case prefEnums.RedemptionFeeDataGrid.toLowerCase():
              preferencesToSave.redemptionFeePreferences = this.fetchEditedRedemptionFeePreference(prefrenceVM.preferenceRedemptionFeeData,
                apiPreferences.preferenceRedemptionFeeData);
              break;
            case prefEnums.CustodianRedemptionFeeDataGrid.toLowerCase():
              preferencesToSave.custodianRedemptionFeePreferences = this.fetchEditedCustodianRedemptionFeePreference(prefrenceVM.preferenceCustodianRedemptionFeeData,
                apiPreferences.preferenceCustodianRedemptionFeeData);
              break;
            case prefEnums.TransactionFeeDataGrid.toLowerCase():
              preferencesToSave.transactionFeePreferences = this.fetchEditedTransactionFeePreference(prefrenceVM.preferenceTransactionFeeData,
                apiPreferences.preferenceTransactionFeeData);
              break;
            case prefEnums.TradeMinDataGrid.toLowerCase():
              preferencesToSave.tradeMinPreferences = this.fetchEditedTradeMinPreference(prefrenceVM.preferenceTradeMinData,
                apiPreferences.preferenceTradeMinData);
              break;
            case prefEnums.TradeMaxDataGrid.toLowerCase():
              preferencesToSave.tradeMaxPreferences = this.fetchEditedTradeMaxPreference(prefrenceVM.preferenceTradeMaxData,
                apiPreferences.preferenceTradeMaxData);
              break;
            case prefEnums.DividendReinvestDataGrid.toLowerCase():
              preferencesToSave.dividendReinvestPreferences = this.fetchEditedDividendReinvestPreference(prefrenceVM.preferenceDividendReinvestData,
                apiPreferences.preferenceDividendReinvestData);
              break;
            case prefEnums.CapitalGainReinvestDataGrid.toLowerCase():
              preferencesToSave.capitalGainReinvestPreferences = this.fetchEditedCapitalGainReinvestPreference(prefrenceVM.preferenceCapitalGainReinvestData,
                apiPreferences.preferenceCapitalGainReinvestData);
              break;
            case prefEnums.TradeSettingsDataGrid.toLowerCase():
              preferencesToSave.tradeSettingsPreferences = this.fetchEditedTradeSettingsPreference(prefrenceVM.preferenceTradeSettingsData,
                apiPreferences.preferenceTradeSettingsData);
              break;
            case prefEnums.DefaultPrimaryTeamDropdownSearch.toLowerCase():
              preferencesToSave.defaultPrimaryTeamPreferences = this.fetchEditedDefaultPrimaryTeamPreference(prefrenceVM.preferenceDefaultPrimaryTeamData,
                apiPreferences.preferenceDefaultPrimaryTeam);
              break;
            case prefEnums.SystematicMultiplierLink.toLowerCase():
              preferencesToSave.systematicMultiplierPreferences = this.fetchEditedSystematicMultiplierPreference(prefrenceVM.preferenceSystematicMultiplierData);
              break;
            case prefEnums.BillingCashMultiplierLink.toLowerCase():
              preferencesToSave.billingCashMultiplierPreferences = this.fetchEditedBillingCashMultiplierPreference(prefrenceVM.preferenceBillingCashMultiplierData);
              break;
          }
      }
    });
    return preferencesToSave;
  }

  /** Save preferences for Normal and MASS Update */
  saveAllPreferences(prefrenceVM: any, Preferncelst: any, selectedLevelIds: number[], apiPreference: any) {
    this.preferenceSaveData = <ILevelPreferenceSave>{
      defaultPreferences: [],
      locationOptimizationPreference: {},
      communityStrategistPreference: {}
    };

    /** Convert the strings to integer/s if any within Number array */
    const convertedSelectedIds = (selectedLevelIds !== null) ?
      Util.toPrimitiveInt(selectedLevelIds) : null;

    if (selectedLevelIds !== null && selectedLevelIds.length > 1) {            /** MASS UPDATE */

      this.preferenceSaveData = {
        level: prefrenceVM.levelPreferences.level,
        id: null,
        ids: convertedSelectedIds,
        defaultPreferences: Preferncelst.defaultPreferences,
        locationOptimizationPreference: (Preferncelst.locationOptimizationPreference && Object.keys(Preferncelst.locationOptimizationPreference).length &&
          Preferncelst.locationOptimizationPreference.assetTypes &&
          (Preferncelst.locationOptimizationPreference.assetTypes.length > 0 ||  (prefrenceVM.preferenceLocationOptimization && prefrenceVM.preferenceLocationOptimization.resetToParent))) ? Preferncelst.locationOptimizationPreference : null,
        communityStrategistPreference: (Preferncelst.communityStrategistPreference && Object.keys(Preferncelst.communityStrategistPreference).length &&
          Preferncelst.communityStrategistPreference.strategistIds) ? Preferncelst.communityStrategistPreference : null,
        redemptionFeePreferences: (Preferncelst.redemptionFeePreferences && Object.keys(Preferncelst.redemptionFeePreferences).length &&
          Preferncelst.redemptionFeePreferences.redemptionFees) ? Preferncelst.redemptionFeePreferences : null,
        custodianRedemptionFeePreferences: (Preferncelst.custodianRedemptionFeePreferences && Object.keys(Preferncelst.custodianRedemptionFeePreferences).length &&
          Preferncelst.custodianRedemptionFeePreferences.custodianRedemptionFees) ? Preferncelst.custodianRedemptionFeePreferences : null,
        transactionFeePreferences: (Preferncelst.transactionFeePreferences && Object.keys(Preferncelst.transactionFeePreferences).length &&
          Preferncelst.transactionFeePreferences.transactionFees) ? Preferncelst.transactionFeePreferences : null,
        tradeMinPreferences: (Preferncelst.tradeMinPreferences && Preferncelst.tradeMinPreferences.tradeMin) ?
          Preferncelst.tradeMinPreferences : null,
        tradeMaxPreferences: (Preferncelst.tradeMaxPreferences && Preferncelst.tradeMaxPreferences.tradeMax) ?
          Preferncelst.tradeMaxPreferences : null,
        dividendReinvestPreferences: (Preferncelst.dividendReinvestPreferences && Preferncelst.dividendReinvestPreferences.dividendReinvests) ?
          Preferncelst.dividendReinvestPreferences : null,
        capitalGainReinvestPreferences: (Preferncelst.capitalGainReinvestPreferences && Preferncelst.capitalGainReinvestPreferences.capitalGainReinvests) ?
          Preferncelst.capitalGainReinvestPreferences : null,
        tradeSettingsPreferences: (Preferncelst.tradeSettingsPreferences && Preferncelst.tradeSettingsPreferences.tradeSettings) ?
          Preferncelst.tradeSettingsPreferences : null,
        defaultPrimaryTeamPreferences: null, /** Not necessary for bulk update */
        systematicMultiplierPreferences: Preferncelst.systematicMultiplierPreferences
          && Object.keys(Preferncelst.systematicMultiplierPreferences).length > 0 ? Preferncelst.systematicMultiplierPreferences : null,
        billingCashMultiplierPreferences: Preferncelst.billingCashMultiplierPreferences
          && Object.keys(Preferncelst.billingCashMultiplierPreferences).length > 0 ? Preferncelst.billingCashMultiplierPreferences : null,
      };

      if(prefrenceVM.preferenceLocationOptimization && prefrenceVM.preferenceLocationOptimization.resetToParent && this.preferenceSaveData.locationOptimizationPreference != null) {
        this.preferenceSaveData.locationOptimizationPreference.assetType = null;
      }

      // this._logger.log("Preference Save Data: ", JSON.stringify(this.preferenceSaveData));
      return this.preferenceservice.updateMassPreferences(this.preferenceSaveData)
        ;
    } else { /** UPDATE */

      this.preferenceSaveData = {
        level: prefrenceVM.levelPreferences.level,
        id: prefrenceVM.levelPreferences.id,
        ids: null,
        defaultPreferences: Preferncelst.defaultPreferences,
        locationOptimizationPreference: (Object.keys(Preferncelst.locationOptimizationPreference).length > 0) ?
          this.comparelocationOptimizationPreferenceToSave(Preferncelst.locationOptimizationPreference, apiPreference, prefrenceVM) : null,
        communityStrategistPreference: (Object.keys(Preferncelst.communityStrategistPreference).length > 0) ?
          this.comparecommunityStrategistPreferenceToSave(Preferncelst.communityStrategistPreference, apiPreference, prefrenceVM) : null,
        redemptionFeePreferences: (Preferncelst.redemptionFeePreferences !== undefined) ?
          ((Object.keys(Preferncelst.redemptionFeePreferences).length > 0) ?
            this.compareRedemptionFeePreferencesToSave(Preferncelst.redemptionFeePreferences, apiPreference, prefrenceVM) : null) : null,
        custodianRedemptionFeePreferences: (Preferncelst.custodianRedemptionFeePreferences !== undefined) ?
          ((Object.keys(Preferncelst.custodianRedemptionFeePreferences).length > 0) ?
            this.compareCustodianRedemptionFeePreferencesToSave(Preferncelst.custodianRedemptionFeePreferences, apiPreference, prefrenceVM) : null) : null,
        transactionFeePreferences: (Preferncelst.transactionFeePreferences !== undefined) ?
          ((Object.keys(Preferncelst.transactionFeePreferences).length > 0) ?
            this.compareTransactionFeePreferencesToSave(Preferncelst.transactionFeePreferences, apiPreference, prefrenceVM) : null) : null,
        tradeMinPreferences: (Preferncelst.tradeMinPreferences !== undefined) ?
          ((Object.keys(Preferncelst.tradeMinPreferences).length > 0) ?
            this.compareTradeMinPreferencesToSave(Preferncelst.tradeMinPreferences, apiPreference, prefrenceVM) : null) : null,
        tradeMaxPreferences: (Preferncelst.tradeMaxPreferences !== undefined) ?
          ((Object.keys(Preferncelst.tradeMaxPreferences).length > 0) ?
            this.compareTradeMaxPreferencesToSave(Preferncelst.tradeMaxPreferences, apiPreference, prefrenceVM) : null) : null,
        dividendReinvestPreferences: (Preferncelst.dividendReinvestPreferences !== undefined) ?
          ((Object.keys(Preferncelst.dividendReinvestPreferences).length > 0) ?
            this.compareDividendReinvestPreferencesToSave(Preferncelst.dividendReinvestPreferences, apiPreference, prefrenceVM) : null) : null,
        capitalGainReinvestPreferences: (Preferncelst.capitalGainReinvestPreferences !== undefined) ?
          ((Object.keys(Preferncelst.capitalGainReinvestPreferences).length > 0) ?
            this.compareCapitalGainReinvestPreferencesToSave(Preferncelst.capitalGainReinvestPreferences, apiPreference, prefrenceVM) : null) : null,
        tradeSettingsPreferences: (Preferncelst.tradeSettingsPreferences !== undefined) ?
          ((Object.keys(Preferncelst.tradeSettingsPreferences).length > 0) ?
            this.compareTradeSettingsPreferencesToSave(Preferncelst.tradeSettingsPreferences, apiPreference, prefrenceVM) : null) : null,
        defaultPrimaryTeamPreferences: (Preferncelst.defaultPrimaryTeamPreferences !== undefined) ?
          ((Object.keys(Preferncelst.defaultPrimaryTeamPreferences).length > 0) ?
            this.compareDefaultPrimaryTeamPreferencesToSave(Preferncelst.defaultPrimaryTeamPreferences, apiPreference, prefrenceVM) : null) : null,
        systematicMultiplierPreferences: (Preferncelst.systematicMultiplierPreferences !== undefined) ?
          ((Object.keys(Preferncelst.systematicMultiplierPreferences).length > 0) ?
            this.compareSystematicMultiplierPreferencesToSave(Preferncelst.systematicMultiplierPreferences, apiPreference, prefrenceVM) : null) : null,
        billingCashMultiplierPreferences: (Preferncelst.billingCashMultiplierPreferences !== undefined) ?
          ((Object.keys(Preferncelst.billingCashMultiplierPreferences).length > 0) ?
            this.compareBillingCashMultiplierPreferencesToSave(Preferncelst.billingCashMultiplierPreferences, apiPreference, prefrenceVM) : null) : null
      };
      // this._logger.log("Preference Save Data: ", JSON.stringify(this.preferenceSaveData));
      return this.preferenceservice.updatePreference(this.preferenceSaveData)
        ;
    }
  }


  /**--------------------------------------- NEW SAVE/UPDATE END ------------------------------------------------- */

  /** */
  setInheritedValuesIfNotSetInitially(preferencesLst) {
    preferencesLst.forEach(element => {
      if (element.isInherited) {
        switch (element.componentType) {
          case this.prefEnums.Default.toLowerCase():
            this.setInheritedValuesForDefaultControls(element);
            break;
          case this.prefEnums.Custom.toLowerCase():
            this.setInheritedValuesForCustomControls(element);
            break;
        }
      }
    });
  }

  /** sets Inherited Values for Default Controls*/
  setInheritedValuesForDefaultControls(prefElement: any) {
    switch (prefElement.componentName.toLowerCase()) {
      case this.prefEnums.Textbox.toLowerCase(): // textbox
      case this.prefEnums.Checkbox.toLowerCase(): // checkbox
        // console.log(prefElement.componentName, "Control value change", prefElement.inheritedValue)
        prefElement.value = (prefElement.value === null) ? prefElement.inheritedValue : prefElement.value;
        break;
      case this.prefEnums.Dropdown.toLowerCase():
        // console.log(prefElement.componentName, "Control value change", prefElement.inheritedSelectedOptions)
        prefElement.selectedOptions = (Object.keys(prefElement.selectedOptions).length === 0) ? prefElement.inheritedSelectedOptions : prefElement.selectedOptions;
        break;
    }
  }

  /**sets Inherited Values for custom Controls */
  setInheritedValuesForCustomControls(prefElement: any) {
    switch (prefElement.componentName.toLowerCase()) {
      // SortedLsit
      case this.prefEnums.PriorityRankingSortedlist.toLowerCase():
      case this.prefEnums.TaxLotDepletionMethodSortedlist.toLowerCase():

        prefElement.selectedOptions = (Object.keys(prefElement.selectedOptions).length === 0) ? prefElement.inheritedSelectedOptions : prefElement.selectedOptions;

        break;

    }
  }

  /** COMPARE  communityStrategistPreference to Save COMPARE */
  comparecommunityStrategistPreferenceToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {

    const postDataObjects = (postMainObject.strategists.strategistIds !== null) ? (postMainObject.strategists.strategistIds) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/


    /** Bind inherited preferences to the actual when the preference is reset */ // OE- 1775- commented  seems this line not require as rest functionality is working independent
    // postMainObject.strategists = (preferenceVM.preferenceCommunities.resetToParent) ? Util.deepClone(preferenceVM.preferenceCommunities.inheritedStrategists) : postMainObject.strategists;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceCommunities.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceCommunities.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceCommunities.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if ((preferenceVM.preferenceCommunities.resetToParent)) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.strategists.strategistIds = [];
          /** While Load on data is there, but deleted the records :: resetToParent=false */
          postMainObject.strategists.modelAccessLevel = null;
          postMainObject.strategists.communityModels = [];
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceCommunities.resetToParent)
          && (_.isEqual(preferenceVM.preferenceCommunities.inheritedStrategists, postMainObject.strategists))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceCommunities.strategists, postMainObject.strategists)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** locationOptimizationPreference to Save COMPARE */
  comparelocationOptimizationPreferenceToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {

    const postDataObjects = (postMainObject.assetTypes !== null) ? (postMainObject.assetTypes) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.assetTypes = (preferenceVM.preferenceLocationOptimization.resetToParent) ? [] : postMainObject.assetTypes;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceLocationOptimization.isSetFromParent) {
      if (preferenceVM.preferenceLocationOptimization.resetToParent) {
        postMainObject.resetToParent = true;
        postMainObject.assetType = null;
        return postMainObject;
      } else if (_.isEqual(getMainObject.preferenceLocationOptimization.assetTypes, postMainObject.assetTypes)
        && getMainObject.preferenceLocationOptimization.assetType === postMainObject.assetType) {
        return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceLocationOptimization.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceLocationOptimization.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          postMainObject.assetType = null;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.assetTypes = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceLocationOptimization.resetToParent)
          && (_.isEqual(preferenceVM.preferenceLocationOptimization.inheritedAssetTypes, postMainObject.assetTypes))) {
          postMainObject.resetToParent = true;
          postMainObject.assetType = null;
          return postMainObject;
        } else if ((preferenceVM.preferenceLocationOptimization.resetToParent) /**When data is there on Load, but reset to parent, alters the record != parent :: Save :: resetToParent=false  */
          && !(_.isEqual(preferenceVM.preferenceLocationOptimization.inheritedAssetTypes, postMainObject.assetTypes))) {
          postMainObject.resetToParent = false;
          postMainObject.assetType = null;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceLocationOptimization.assetTypes, postMainObject.assetTypes)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE RedemptionFeePreferences to Save COMPARE */
  compareRedemptionFeePreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceRedemptionFeeData.redemptionFeePreferences !== null) ? (getMainObject.preferenceRedemptionFeeData.redemptionFeePreferences) : null;
    const postDataObjects = (postMainObject.redemptionFees !== null) ? (postMainObject.redemptionFees) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.redemptionFees = (preferenceVM.preferenceRedemptionFeeData.resetToParent &&
      _.isEqual(getDataObjects, postDataObjects)) ? Util.deepClone(preferenceVM.preferenceRedemptionFeeData.inheritedRedemptionFeePreferences) : postMainObject.redemptionFees;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceRedemptionFeeData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceRedemptionFeeData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceRedemptionFeeData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceRedemptionFeeData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.redemptionFees = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceRedemptionFeeData.resetToParent)
          && _.isEqual(preferenceVM.preferenceRedemptionFeeData.inheritedRedemptionFeePreferences, postMainObject.redemptionFees)) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceRedemptionFeeData.redemptionFeePreferences, postMainObject.redemptionFees)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE Custodian RedemptionFeePreferences to Save COMPARE */
  compareCustodianRedemptionFeePreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceCustodianRedemptionFeeData.custodianRedemptionFeePreferences !== null) ? (getMainObject.preferenceCustodianRedemptionFeeData.custodianRedemptionFeePreferences) : null;
    const postDataObjects = (postMainObject.custodianRedemptionFees !== null) ? (postMainObject.custodianRedemptionFees) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.custodianRedemptionFees = (preferenceVM.preferenceCustodianRedemptionFeeData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceCustodianRedemptionFeeData.inheritedCustodianRedemptionFeePreferences) : postMainObject.custodianRedemptionFees;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceCustodianRedemptionFeeData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceCustodianRedemptionFeeData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceCustodianRedemptionFeeData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceCustodianRedemptionFeeData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.custodianRedemptionFees = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceCustodianRedemptionFeeData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceCustodianRedemptionFeeData.inheritedCustodianRedemptionFeePreferences, postMainObject.custodianRedemptionFees))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceCustodianRedemptionFeeData.custodianRedemptionFeePreferences, postMainObject.custodianRedemptionFees)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE  TransactionFeePreferences to Save COMPARE **/
  compareTransactionFeePreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceTransactionFeeData.transactionFeePreferences !== null) ? (getMainObject.preferenceTransactionFeeData.transactionFeePreferences) : null;
    const postDataObjects = (postMainObject.transactionFees !== null) ? (postMainObject.transactionFees) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.transactionFees = (preferenceVM.preferenceTransactionFeeData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceTransactionFeeData.inheritedTransactionFeePreferences) : postMainObject.transactionFees;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceTransactionFeeData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceTransactionFeeData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceTransactionFeeData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceTransactionFeeData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.transactionFees = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceTransactionFeeData.resetToParent)
          && _.isEqual(preferenceVM.preferenceTransactionFeeData.inheritedTransactionFeePreferences, postMainObject.transactionFees)) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceTransactionFeeData.transactionFeePreferences, postMainObject.transactionFees)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE TradeMinPreferences to Save COMPARE */
  compareTradeMinPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceTradeMinData.tradeMinPreferences !== null) ? (getMainObject.preferenceTradeMinData.tradeMinPreferences) : null;
    const postDataObjects = (postMainObject.tradeMin !== null) ? (postMainObject.tradeMin) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.tradeMin = (preferenceVM.preferenceTradeMinData.resetToParent &&
      _.isEqual(getDataObjects, postDataObjects)) ? Util.deepClone(preferenceVM.preferenceTradeMinData.inheritedTradeMinPreferences) : postMainObject.tradeMin;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceTradeMinData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceTradeMinData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceTradeMinData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceTradeMinData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.tradeMin = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceTradeMinData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceTradeMinData.inheritedTradeMinPreferences, postMainObject.tradeMin))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceTradeMinData.tradeMinPreferences, postMainObject.tradeMin)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE TradeMaxPreferences to Save COMPARE */
  compareTradeMaxPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceTradeMaxData.tradeMaxPreferences !== null) ? (getMainObject.preferenceTradeMaxData.tradeMaxPreferences) : null;
    const postDataObjects = (postMainObject.tradeMax !== null) ? (postMainObject.tradeMax) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.tradeMax = (preferenceVM.preferenceTradeMaxData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceTradeMaxData.inheritedTradeMaxPreferences) : postMainObject.tradeMax;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceTradeMaxData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceTradeMaxData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceTradeMaxData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceTradeMaxData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.tradeMax = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceTradeMaxData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceTradeMaxData.inheritedTradeMaxPreferences, postMainObject.tradeMax))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceTradeMaxData.tradeMaxPreferences, postMainObject.tradeMax)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE dividendReinvestPreferences to Save COMPARE */
  compareDividendReinvestPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceDividendReinvestData.dividendReinvestPreferences !== null) ? (getMainObject.preferenceDividendReinvestData.dividendReinvestPreferences) : null;
    const postDataObjects = (postMainObject.dividendReinvests !== null) ? (postMainObject.dividendReinvests) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.dividendReinvests = (preferenceVM.preferenceDividendReinvestData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceDividendReinvestData.inheritedDividendReinvestPreferences) : postMainObject.dividendReinvests;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceDividendReinvestData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceDividendReinvestData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceDividendReinvestData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceDividendReinvestData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.dividendReinvests = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceDividendReinvestData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceDividendReinvestData.inheritedDividendReinvestPreferences, postMainObject.dividendReinvests))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceDividendReinvestData.dividendReinvestPreferences, postMainObject.dividendReinvests)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  compareSystematicMultiplierPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceSystematicMultiplierData.systematicMultiplierPreferences !== null) ? (getMainObject.preferenceSystematicMultiplierData.systematicMultiplierPreferences) : null;
    const postDataObjects = (postMainObject.systematicMultipliers !== null) ? (postMainObject.systematicMultipliers) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.systematicMultipliers = (preferenceVM.preferenceSystematicMultiplierData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceSystematicMultiplierData.inheritedSystematicMultiplierPreferences) : postMainObject.systematicMultipliers;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceSystematicMultiplierData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceSystematicMultiplierData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;  /** Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null  */
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceSystematicMultiplierData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceSystematicMultiplierData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.systematicMultipliers = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else {
        if ((preferenceVM.preferenceSystematicMultiplierData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceSystematicMultiplierData.inheritedSystematicMultiplierPreferences, postMainObject.systematicMultipliers))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceSystematicMultiplierData.systematicMultiplierPreferences, postMainObject.systematicMultipliers)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  compareBillingCashMultiplierPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceBillingCashMultiplierData.billingCashMultiplierPreferences !== null) ? (getMainObject.preferenceBillingCashMultiplierData.billingCashMultiplierPreferences) : null;
    const postDataObjects = (postMainObject.billingCashMultipliers !== null) ? (postMainObject.billingCashMultipliers) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.billingCashMultipliers = (preferenceVM.preferenceBillingCashMultiplierData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceBillingCashMultiplierData.inheritedBillingCashMultiplierPreferences) : postMainObject.billingCashMultipliers;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceBillingCashMultiplierData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceBillingCashMultiplierData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;  /** Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null  */
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceBillingCashMultiplierData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceBillingCashMultiplierData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.billingCashMultipliers = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else {
        if ((preferenceVM.preferenceBillingCashMultiplierData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceBillingCashMultiplierData.inheritedBillingCashMultiplierPreferences, postMainObject.billingCashMultipliers))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceBillingCashMultiplierData.billingCashMultiplierPreferences, postMainObject.billingCashMultipliers)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE capitalGainReinvestPreferences to Save COMPARE */
  compareCapitalGainReinvestPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceCapitalGainReinvestData.capitalGainReinvestPreferences !== null) ? (getMainObject.preferenceCapitalGainReinvestData.capitalGainReinvestPreferences) : null;
    const postDataObjects = (postMainObject.capitalGainReinvests !== null) ? (postMainObject.capitalGainReinvests) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.capitalGainReinvests = (preferenceVM.preferenceCapitalGainReinvestData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceCapitalGainReinvestData.inheritedCapitalGainReinvestPreferences) : postMainObject.capitalGainReinvests;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceCapitalGainReinvestData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceCapitalGainReinvestData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;  /** Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null  */
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceCapitalGainReinvestData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceCapitalGainReinvestData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.capitalGainReinvests = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceCapitalGainReinvestData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceCapitalGainReinvestData.inheritedCapitalGainReinvestPreferences, postMainObject.capitalGainReinvests))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceCapitalGainReinvestData.capitalGainReinvestPreferences, postMainObject.capitalGainReinvests)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** COMPARE SecurityPreferences to Save COMPARE */
  compareBucketSecurityPreferencesToSave(postMainObject: any, getMainObject: any) {
    /** Reset to parent to true when ever following condition fails*/
    postMainObject.resetToParent = (!((postMainObject.addSecurities !== null && postMainObject.addSecurities.length > 0) ||
      (postMainObject.editSecurities !== null && postMainObject.editSecurities.length > 0) ||
      (postMainObject.deleteSecurities !== null && postMainObject.deleteSecurities.length > 0) ||
      (_.isEqual(getMainObject.securityPreferences, this.nonEditedSecurities))));

    // this._logger.log("Posted security changes", postMainObject);
    /** Prevent null object for delete securities */
    postMainObject.deleteSecurities = (postMainObject.deleteSecurities === null) ? [] : postMainObject.deleteSecurities;
    return postMainObject;
  }

  /** COMPARE tradeSettingsPreferences to Save COMPARE */
  compareTradeSettingsPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceTradeSettingsData.tradeSettingsPreferences !== null) ? (getMainObject.preferenceTradeSettingsData.tradeSettingsPreferences) : null;
    const postDataObjects = (postMainObject.tradeSettings !== null) ? (postMainObject.tradeSettings) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.tradeSettings = (preferenceVM.preferenceTradeSettingsData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceTradeSettingsData.inheritedTradeSettingsPreferences) : postMainObject.tradeSettings;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceTradeSettingsData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceTradeSettingsData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else if (_.isEqual(getDataObjects, postDataObjects)) {
        return null;  /** Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null  */
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceTradeSettingsData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceTradeSettingsData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          postMainObject.tradeSettings = []; /** While Load on data is there, but deleted the records :: resetToParent=false */
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceTradeSettingsData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceTradeSettingsData.inheritedTradeSettingsPreferences, postMainObject.tradeSettings))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceTradeSettingsData.tradeSettingsPreferences, postMainObject.tradeSettings)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }


  /** COMPARE defaultTeamPreferences to Save COMPARE */
  compareDefaultPrimaryTeamPreferencesToSave(postMainObject: any, getMainObject: any, preferenceVM: IPreferenceDataVM) {
    const getDataObjects = (getMainObject.preferenceDefaultPrimaryTeamData.defaultPrimaryTeamPreferences !== null) ? (getMainObject.preferenceDefaultPrimaryTeamData.defaultPrimaryTeamPreferences) : null;
    const postDataObjects = (postMainObject.defaultPrimaryTeams !== null) ? (postMainObject.defaultPrimaryTeams) : null;

    postMainObject.resetToParent = false; /** Initilaize the boolean while loding the save to false before actual comparison starts*/

    /** Bind inherited preferences to the actual when the preference is reset */
    postMainObject.defaultPrimaryTeams = (preferenceVM.preferenceDefaultPrimaryTeamData.resetToParent &&
      (_.isEqual(getDataObjects, postDataObjects))) ? Util.deepClone(preferenceVM.preferenceDefaultPrimaryTeamData.inheritedDefaultPrimaryTeamPreferences) : postMainObject.defaultPrimaryTeams;

    /*** NEW CODE FOR HANDLING ALL SCENARIOS OF MANUAL OVERRRIDE/RESET/OVERRIDE*/
    if (preferenceVM.preferenceDefaultPrimaryTeamData.isSetFromParent) {
      if (postDataObjects === null) {
        return null; /** While Data is not there on Load, user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
      } else if (preferenceVM.preferenceDefaultPrimaryTeamData.resetToParent) {
        postMainObject.resetToParent = true;
        return postMainObject;
      } else {
        postMainObject.resetToParent = false;
        return postMainObject; /** Updating the record when data is not there on Load :: resetToParent=false */
      }
    } else if (!preferenceVM.preferenceDefaultPrimaryTeamData.isSetFromParent) { /** While Data is present on Load */
      if (postDataObjects === null) {
        if (preferenceVM.preferenceDefaultPrimaryTeamData.resetToParent) {
          /** While Data is present on Load :: But reset to empty inherited preference*/
          postMainObject.resetToParent = true;
          return postMainObject;
        } else {
          postMainObject.resetToParent = false;
          return postMainObject;
        }
      } else if (postDataObjects !== null) {
        if ((preferenceVM.preferenceDefaultPrimaryTeamData.resetToParent)
          && (_.isEqual(preferenceVM.preferenceDefaultPrimaryTeamData.inheritedDefaultPrimaryTeamPreferences, postMainObject.defaultPrimaryTeams))) {
          postMainObject.resetToParent = true;
          return postMainObject;
        } else if (_.isEqual(getMainObject.preferenceDefaultPrimaryTeamData.defaultPrimaryTeamPreferences, postMainObject.defaultPrimaryTeams)) {
          return null; /** While Load data is there, but user neither adds nor updates :: no action :: SAVE :: set the parent object null :: resetToParent=false*/
        } else {
          postMainObject.resetToParent = false;
          return postMainObject; /** Updating the record when data is there on Load :: resetToParent=false */
        }
      }
    }
  }

  /** compare SortedOptions in SortedList */
  compareSortedOptionsInSortedList(preferenceViewModelCompare: any, preferencesvm: any) {
    const sortedLists = preferenceViewModelCompare.preferences.filter(x => x.valueType.toLowerCase() === this.prefEnums.sortedlist);
    if (Object.keys(sortedLists).length > 0) {
      sortedLists.forEach(element => {
        /** Sort the loaded sorted lists ( TLDE && PR ) */
        if ((Object.keys(element.selectedOptions).length !== Object.keys(element.options).length)) {
          element.selectedOptions = Util.sortBy(<any>Object.values(element.options), 'order');
        } else if ((Object.keys(element.selectedOptions).length > 0)) {
          element.selectedOptions = Util.sortBy(<any>Object.values(element.selectedOptions), 'order');
        } else {
          element.selectedOptions = Util.sortBy(<any>Object.values(element.inheritedSelectedOptions), 'order');
        }
      });
    }

    // PreferenceVM Sortedlist selectionOption compare
    const vmsortedLists = preferencesvm.levelPreferences.preferences.filter(x => x.valueType.toLowerCase() === this.prefEnums.sortedlist);
    if (Object.keys(vmsortedLists).length > 0) {
      vmsortedLists.forEach(element => {

        const getOptions = Object.values(element.options);
        const getSelectedoptions = Object.values(element.selectedOptions);
        getSelectedoptions.forEach(elementSelectedOptions => {
          getOptions.push(elementSelectedOptions);
        });
        // compare length if both length not equal ( options & SelectedOptions)
        if (Object.keys(getSelectedoptions).length !== Object.keys(getOptions).length) {
          element.selectedOptions = Util.removeDuplicates(getOptions, 'id');
        }
      });
    }
  }

  /** BIND the inherited values of immediate upper level to the current level if any */
  bindInheritedValuesFromImmediateLevelToCurrentLevel(prefrenceVM: IPreferenceDataVM) {
    const prefEnums = Util.convertEnumValuesToString(PreferenceEnums);
    prefrenceVM.levelPreferences.preferences.forEach(elementpref => {
      // filteredPreference = [];
      elementpref.isNotReSetToParent = false;
      switch (elementpref.componentType) {
        /** For Default preferences fetch the edited dropdown selected options */
        /** DEFAULT: Assign inherited values to Actual value
         * Textbox
         * Dropdown
         * Checkbox
         */
        case prefEnums.Default.toLowerCase():
          switch (elementpref.componentName.toLowerCase()) {
            case prefEnums.Dropdown.toLowerCase():
              if (elementpref.inheritedSelectedOptions.length > 0) {
                const InheritValue = elementpref.inheritedSelectedOptions[0]['id'];
                const inheritName = elementpref.inheritedSelectedOptions[0]['name'];
                elementpref.selectedOptions = [{id: InheritValue, order: 0, name: inheritName}];
                elementpref.value = InheritValue;
              } else {
                elementpref.value = '';
                elementpref.selectedOptions = [{id: '', order: 0}];
              }
              break;
            default:
              if (elementpref.inheritedValue) {
                elementpref.value = elementpref.inheritedValue;
                elementpref.indicatorValue = elementpref.inheritedIndicatorValue;
              } else {
                elementpref.value = '';
              }
              break;
          }
          break;
        /** For custom preferences fetch the edited/re-ordered sorted options */
        case prefEnums.Custom.toLowerCase():
          /** CUSTOM: Assign inherited values to Actual value
           * Community strategists
           * Location Optimizations
           * Securities
           * Sorted List-PriorityRankingSortedlist, TaxLotDepletionMethodSortedlist
           */
          switch (elementpref.componentName.toLowerCase()) {
            case prefEnums.PriorityRankingSortedlist.toLowerCase():
            case prefEnums.TaxLotDepletionMethodSortedlist.toLowerCase():
              if (elementpref.inheritedSelectedOptions.length > 0) {
                elementpref.selectedOptions = [];
                elementpref.selectedOptions = Util.deepClone(elementpref.inheritedSelectedOptions);
              } else {
                elementpref.selectedOptions = Util.deepClone(elementpref.options);
              }
              break;

            case prefEnums.LocationOptimizationDataGrid.toLowerCase():
              prefrenceVM.preferenceLocationOptimization.resetToParent = true;
              if (elementpref.isInherited) {
                prefrenceVM.preferenceLocationOptimization.assetTypes = (Object.keys(prefrenceVM.preferenceLocationOptimization.inheritedAssetTypes).length > 0) ?
                  Util.deepClone(prefrenceVM.preferenceLocationOptimization.inheritedAssetTypes) : [];
              } else {
                prefrenceVM.preferenceLocationOptimization.assetTypes = [];
              }
              break;

            case prefEnums.CommunityStrategistEnabledListCascadingControl.toLowerCase():
              prefrenceVM.preferenceCommunities.resetToParent = true;
              if (elementpref.isInherited) {
                if (Object.keys(prefrenceVM.preferenceCommunities.inheritedStrategists).length > 0) {
                  prefrenceVM.preferenceCommunities.strategists = Util.deepClone(prefrenceVM.preferenceCommunities.inheritedStrategists);
                } else {
                  prefrenceVM.preferenceCommunities.strategists.strategistIds = null;
                  prefrenceVM.preferenceCommunities.strategists.modelAccessLevel = null;
                  prefrenceVM.preferenceCommunities.strategists.communityModels = null;
                }
              } else {
                prefrenceVM.preferenceCommunities.strategists.strategistIds = null;
                prefrenceVM.preferenceCommunities.strategists.modelAccessLevel = null;
                prefrenceVM.preferenceCommunities.strategists.communityModels = null;
              }
              break;
            case prefEnums.SecurityDataGrid.toLowerCase():
              if (prefrenceVM.preferenceSecurityData !== undefined) {
                prefrenceVM.preferenceSecurityData.resetToParent = true;
                prefrenceVM.preferenceSecurityData.securityPreferences = (elementpref.isInherited) ?
                  Util.deepClone(prefrenceVM.preferenceSecurityData.inheritedSecurityPreferences) : [];
              }
              break;
            case prefEnums.RedemptionFeeDataGrid.toLowerCase():
              prefrenceVM.preferenceRedemptionFeeData.resetToParent = true;
              prefrenceVM.preferenceRedemptionFeeData.redemptionFeePreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceRedemptionFeeData.inheritedRedemptionFeePreferences) : [];
              break;
            case prefEnums.TransactionFeeDataGrid.toLowerCase():
              if (prefrenceVM.preferenceTransactionFeeData !== undefined) {
                prefrenceVM.preferenceTransactionFeeData.resetToParent = true;
                prefrenceVM.preferenceTransactionFeeData.transactionFeePreferences = (elementpref.isInherited) ?
                  Util.deepClone(prefrenceVM.preferenceTransactionFeeData.inheritedTransactionFeePreferences) : [];
              }
              break;
            case prefEnums.TradeMinDataGrid.toLowerCase():
              prefrenceVM.preferenceTradeMinData.resetToParent = true;
              prefrenceVM.preferenceTradeMinData.tradeMinPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceTradeMinData.inheritedTradeMinPreferences) : [];
              break;
            case prefEnums.TradeMaxDataGrid.toLowerCase():
              prefrenceVM.preferenceTradeMaxData.resetToParent = true;
              prefrenceVM.preferenceTradeMaxData.tradeMaxPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceTradeMaxData.inheritedTradeMaxPreferences) : [];
              break;
            case prefEnums.DividendReinvestDataGrid.toLowerCase():
              prefrenceVM.preferenceDividendReinvestData.resetToParent = true;
              prefrenceVM.preferenceDividendReinvestData.dividendReinvestPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceDividendReinvestData.inheritedDividendReinvestPreferences) : [];
              break;
            case prefEnums.CapitalGainReinvestDataGrid.toLowerCase():
              prefrenceVM.preferenceCapitalGainReinvestData.resetToParent = true;
              prefrenceVM.preferenceCapitalGainReinvestData.capitalGainReinvestPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceCapitalGainReinvestData.inheritedCapitalGainReinvestPreferences) : [];
              break;
            case prefEnums.SystematicMultiplierLink.toLowerCase():
              prefrenceVM.preferenceSystematicMultiplierData.resetToParent = true;
              prefrenceVM.preferenceSystematicMultiplierData.systematicMultiplierPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceSystematicMultiplierData.inheritedSystematicMultiplierPreferences) : [];
              break;
            case prefEnums.BillingCashMultiplierLink.toLowerCase():
              prefrenceVM.preferenceBillingCashMultiplierData.resetToParent = true;
              prefrenceVM.preferenceBillingCashMultiplierData.billingCashMultiplierPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceBillingCashMultiplierData.inheritedBillingCashMultiplierPreferences) : [];
              break;
            case prefEnums.TradeSettingsDataGrid.toLowerCase():
              prefrenceVM.preferenceTradeSettingsData.resetToParent = true;
              prefrenceVM.preferenceTradeSettingsData.tradeSettingsPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceTradeSettingsData.inheritedTradeSettingsPreferences) : [];
              break;
            case prefEnums.DefaultPrimaryTeamDropdownSearch.toLowerCase():
              prefrenceVM.preferenceDefaultPrimaryTeamData.resetToParent = true;
              prefrenceVM.preferenceDefaultPrimaryTeamData.defaultPrimaryTeamPreferences = (elementpref.isInherited) ?
                Util.deepClone(prefrenceVM.preferenceDefaultPrimaryTeamData.inheritedDefaultPrimaryTeamPreferences) : [];
              break;
            case prefEnums.MoneyMarketAllocationGrid.toLowerCase():
              prefrenceVM.moneyMarketAllocationPreferenceData.resetToParent = true;
              prefrenceVM.moneyMarketAllocationPreferenceData.moneyMarketAllocations = elementpref.isInherited ?
                Util.deepClone(prefrenceVM.moneyMarketAllocationPreferenceData.inheritedMoneyMarketAllocations) : [];
              break;
            case prefEnums.MoneyMarketFundsGrid.toLowerCase():
              prefrenceVM.moneyMarketFundPreferenceData.resetToParent = true;
              prefrenceVM.moneyMarketFundPreferenceData.moneyMarketFunds = elementpref.isInherited ?
                Util.deepClone(prefrenceVM.moneyMarketFundPreferenceData.inheritedMoneyMarketFunds) : [];
              break;
          }
      }
    });

    return prefrenceVM;
  }

  /** Save Security Preferences separately */
  saveSecurityPreferences(levelName: string,
    recordId: number,
    selectedLevelIds: number[],
    securityPreferences: ICustomSecurityPreferenceSave,
    apiSecurityPreference: any): Observable<any>[] {

    const secPreferences = (Object.keys(securityPreferences).length > 0)
      ? this.compareBucketSecurityPreferencesToSave(securityPreferences, apiSecurityPreference)
      : null;

    const batchJobParams = { runFor: null, runForIds: null, primaryTeamId: this.sessionHelper.getUser().primaryTeam } as IBatchJobPreferenceParameter;
    const apiArray = [];

    /** Convert the strings to integer/s if any within Number array */
    /** MASS SECURITY PREFS UPDATE  */
    if (selectedLevelIds && selectedLevelIds.length > 1) {
      this.securityPreferenceSaveData = {
        level: levelName,
        id: null,
        ids: selectedLevelIds,
        securityPreferences: secPreferences
      };
      apiArray.push(this.preferenceservice.updateMassSecurityPreferences(this.securityPreferenceSaveData));
    } else {
      this.securityPreferenceSaveData = {
        level: levelName,
        id: recordId,
        ids: null,
        securityPreferences: secPreferences
      };
      apiArray.push(this.preferenceservice.updateSecurityPreferences(this.securityPreferenceSaveData));
    }

    // Write to batch table - add to apiArray for both edits and adds
    batchJobParams.runFor = this.securityPreferenceSaveData.level;

    if (levelName.toUpperCase() !== PREFERENCE_LEVEL.FIRM.toUpperCase() && (this.securityPreferenceSaveData.securityPreferences.addSecurities.length > 0
      || this.securityPreferenceSaveData.securityPreferences.editSecurities.length > 0
      || this.securityPreferenceSaveData.securityPreferences.deleteSecurities.length > 0)) {

      batchJobParams.runForIds = selectedLevelIds && selectedLevelIds.length > 1 ? this.securityPreferenceSaveData.ids : [this.securityPreferenceSaveData.id];
      apiArray.push(this.preferenceservice.updateBatchJobForSecurityPreferenceChange(batchJobParams));
    }

    return apiArray;
  }

  getESGEnabled(firmId: number): Observable<boolean> {
    const user = this.sessionHelper.getUser();
    const isOrionAdmin = user.role.roleTypeId === +UserType.OrionAdmin;
    return this.preferenceservice.getpreferencesForLevel('Firm', firmId)
      .pipe(map(result => {
        if(result.preferences.filter(pref => pref.name === 'esgEnabled').length > 0
         && result.preferences.filter(pref => pref.name === 'esgEnabled')[0].selectedOptions.length > 0)
        {
          const esgSelectedValue = result.preferences.filter(pref => pref.name === 'esgEnabled')[0].selectedOptions[0].name;
          if(esgSelectedValue === 'User Manual' || (esgSelectedValue === 'Orion Manual' && isOrionAdmin)) {
            return true;
          }
        }

        return false;
      }));
  }

  setWashSalePreference(preferences: IPreferences[]): void {
    const washSalesPref = preferences.find(pref => pref.name === PREFERENCE_NAME.ALLOW_WASH_SALES);
    if (washSalesPref) {
      let value = Util.getBooleanValue(washSalesPref.value);
      if (value === null) {
        value = Util.getBooleanValue(washSalesPref.inheritedValue);
      }
      this._preferenceHelper.updateWashSalesPreference(value, preferences);
    }
  }

  saveMoneyMarketPreference(moneyMarketPreferenceSave: IMoneyMarketPreferenceSave, selectedLevelIds: number[],
    apiPreference: IPreferenceDataVMCompare): Observable<IMoneyMarketPreferenceSaveMessage>[] {
    return [
      this.updateMoneyMarketAllocationPreference(moneyMarketPreferenceSave.moneyMarketAllocationPreference,
        selectedLevelIds, apiPreference),
      this.updateMoneyMarketFundPreference(moneyMarketPreferenceSave.moneyMarketFundPreference,
        selectedLevelIds, apiPreference)
    ];
  }

  saveAstroInvestorPreferences(editedPreferences: IAstroInvestorPreferences,
    updateCashFields: boolean, originalAstroInvestorPreferences: IPreferenceDataVMCompare, accountId: number)
    : Observable<ISaveInvestorPreferencesResponse> {
    this.investorPreferencesSaveRequest = {
      isEdited: this._preferenceHelper.hasInvestorPreferences
        ? !_.isEqual(editedPreferences, originalAstroInvestorPreferences.originalAstroInvestorPreferences)
        : false,
      editedInvestorPreferences: editedPreferences
    };
    if (!!editedPreferences) {
      this.investorPreferencesSaveRequest.editedInvestorPreferences.updateCashFields = updateCashFields;
    }
    return this.preferenceservice.setAstroInvestorPreferences(accountId, this.investorPreferencesSaveRequest);
  }

  updateMoneyMarketAllocationPreference(moneyMarketAllocationPreferenceSave: IMoneyMarketAllocationPreferenceSave,
    selectedLevelIds: number[], apiPreference: IPreferenceDataVMCompare): Observable<IMoneyMarketPreferenceSaveMessage> {
    if (_.isEqual(moneyMarketAllocationPreferenceSave.moneyMarketAllocations,
      apiPreference.moneyMarketAllocationPreferences.inheritedMoneyMarketAllocations)) {
      moneyMarketAllocationPreferenceSave.moneyMarketAllocations = [];
    }
    if (selectedLevelIds?.length > 1) {
      moneyMarketAllocationPreferenceSave.relatedTypeIds = selectedLevelIds;
    }

    return this.preferenceservice.updateMoneyMarketAllocationPreference(moneyMarketAllocationPreferenceSave);
  }

  updateMoneyMarketFundPreference(moneyMarketFundPreferenceSave: IMoneyMarketFundPreferenceSave, selectedLevelIds: number[],
    apiPreference: IPreferenceDataVMCompare): Observable<IMoneyMarketPreferenceSaveMessage> {
    if (apiPreference.moneyMarketFundPreference && _.isEqual(moneyMarketFundPreferenceSave.moneyMarketFunds,
      apiPreference.moneyMarketFundPreference.inheritedMoneyMarketFunds)) {
      moneyMarketFundPreferenceSave.moneyMarketFunds = [];
    }
    if (selectedLevelIds?.length > 1) {
      moneyMarketFundPreferenceSave.relatedTypeIds = selectedLevelIds;
    }

    return this.preferenceservice.updateMoneyMarketFundPreference(moneyMarketFundPreferenceSave);
  }

  private fetchEditedMoneyMarketAllocationPreference(preferenceVM: IPreferenceDataVM): IMoneyMarketAllocationPreferenceSave {
    const hasMoneyMarketPreference = this._preferenceHelper.hasMoneyMarketPreference(preferenceVM.levelPreferences.preferences);
    let moneyMarketAllocationPreferenceSave = <IMoneyMarketAllocationPreferenceSave>{};
    if (hasMoneyMarketPreference) {
      moneyMarketAllocationPreferenceSave = this.createMoneyMarketAllocationPreferenceSaveRequest(
        preferenceVM.moneyMarketAllocationPreferenceData)
    }
    return moneyMarketAllocationPreferenceSave;
  }

  private fetchEditedMoneyMarketFundPreference(preferenceVM: IPreferenceDataVM): IMoneyMarketFundPreferenceSave {
    const hasMoneyMarketPreference = this._preferenceHelper.hasMoneyMarketPreference(preferenceVM.levelPreferences.preferences);
    const level = preferenceVM.levelPreferences.level.toUpperCase();

    if (level === PREFERENCE_LEVEL.TEAM.toUpperCase()) {
      return null;
    }

    let moneyMarketFundPreferenceSave = <IMoneyMarketFundPreferenceSave>{};
    if (hasMoneyMarketPreference) {
      moneyMarketFundPreferenceSave = this.createMoneyMarketFundPreferenceSaveRequest(preferenceVM.moneyMarketFundPreferenceData)
    }
    return moneyMarketFundPreferenceSave;
  }

  private createMoneyMarketAllocationPreferenceSaveRequest(MMAllocationPreference: IMoneyMarketAllocationPreference): IMoneyMarketAllocationPreferenceSave {
    return {
      preferenceValueId: MMAllocationPreference.id,
      relatedTypeId: MMAllocationPreference.recordId,
      relatedTypeIds: null,
      relatedType: MMAllocationPreference.relatedType,
      preferenceId: MMAllocationPreference.preferenceId,
      moneyMarketAllocations: MMAllocationPreference.moneyMarketAllocations?.length ? MMAllocationPreference.moneyMarketAllocations : [],
      resetToParent: MMAllocationPreference.resetToParent
    };
  }

  private createMoneyMarketFundPreferenceSaveRequest(MMFundPreference: IMoneyMarketFundPreference): IMoneyMarketFundPreferenceSave {
    return {
      preferenceValueId: MMFundPreference.id,
      relatedTypeId: MMFundPreference.recordId,
      relatedTypeIds: null,
      relatedType: MMFundPreference.relatedType,
      preferenceId: MMFundPreference.preferenceId,
      moneyMarketFunds: MMFundPreference.moneyMarketFunds?.length ? MMFundPreference.moneyMarketFunds : [],
      resetToParent: MMFundPreference.resetToParent
    };
  }

  getInvestorPreferenceFeatureFlag(): void {
    this._splitService.flagEnabled$('AstroAccounts_ShowInvestorPreferences_2088').subscribe(
      (enabled: boolean) => {
      this.astroInvestorPreferencesFeatureFlag = enabled;
    });
  }

  private fetchEditedInvestorPreferences(preferenceVM: IPreferenceDataVM, apiPreferences: any): IAstroInvestorPreferences {
    const originalPreferences = _.cloneDeep(apiPreferences.originalAstroInvestorPreferences);
    const editedPreferences = preferenceVM.levelPreferences.preferences
      .filter(pref => pref.categoryType === AstroInvestorPreferences.AstroPreferences && pref.name !== AstroInvestorPreferences.ExposureModel);

    this.astroInvestorPreferencesSave = originalPreferences;

    for (const preference of editedPreferences) {
      if (preference.value !== originalPreferences[preference.name]) {
        this.astroInvestorPreferencesSave[preference.name] = preference.value;
      }
    }
    if (this.astroInvestorPreferencesFeatureFlag) {
      this.setDropDownPreferences(preferenceVM);
    }
    return this.astroInvestorPreferencesSave;
  }

  setDropDownPreferences(preferenceVM: IPreferenceDataVM): void {
    const astroPreferences = preferenceVM.levelPreferences.preferences
      .filter(preference => preference.categoryType === AstroInvestorPreferences.AstroPreferences
        || preference.name === AstroAccountPreferenceValue.AllowShortTermGain);

    for (const preference of astroPreferences) {
      this.preferenceOptions = preference?.options;
      if ([
        AstroAccountPreferenceValue.AllowShortTermGain,
        AstroInvestorPreferences.AstroWithHoldCashForTaxes
      ].includes(preference.name)) {
        this.setDropDownPreferenceValues(preference, this.preferenceOptions);
      }
    }
  }

  setDropDownPreferenceValues(preference: IPreferences, preferenceOptions: IPreferenceOptions[]): void {
    if (this.astroInvestorPreferencesSave && preference.selectedOptions.length) {
      this.astroInvestorPreferencesSave[preference.name] = preferenceOptions
        ?.find(option => option.id === preference.selectedOptions[0].id)?.name;
    } else if (this.astroInvestorPreferencesSave) {
      this.astroInvestorPreferencesSave[preference.name] = preferenceOptions
        ?.find(option => option.id === preference.inheritedSelectedOptions[0]?.id)?.name;
    }
  }
}
