import { Injectable } from '@angular/core';
import { OEHttpClient } from '../core';
import { Observable } from 'rxjs';
import { IAnalyticsBannerSpinnerStatus, IRunAnalyticsConfig } from '../models/analytics';
import { IPortfolioAnalytics } from '../models/portfolio';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private _analyticsBannerSpinnerStatus = 'v2/Analytics/BannerSpinner/Status';
  private _resetAnalyticsRunHistory = 'v2/Analytics/ResetAnalyticsRunHistory';
  private _getPortfolioAnalyticsStatus = 'v2/Analytics/GetPortfoliosAnalyticsStatus';
  private _runAnalyticsConfig = 'v2/Analytics/RunAnalyticsConfig';

  constructor(private readonly _httpClient: OEHttpClient) {
  }

  getAnalyticsBannerSpinnerStatus(): Observable<IAnalyticsBannerSpinnerStatus> {
    return this._httpClient.getData(this._analyticsBannerSpinnerStatus);
  }

  resetAnalyticsRunHistory(): Observable<string> {
    return this._httpClient.getData(this._resetAnalyticsRunHistory);
  }

  getPortfoliosAnalyticsStatus(portfolioIds: number[]): Observable<IPortfolioAnalytics[]> {
    return this._httpClient.postData(this._getPortfolioAnalyticsStatus, portfolioIds);
  }

  getRunAnalyticsConfigs(): Observable<IRunAnalyticsConfig[]> {
    return this._httpClient.getData(this._runAnalyticsConfig);
  }

  updateRunAnalyticsConfig(id: number, runAnalyticsConfig : IRunAnalyticsConfig) : Observable<IRunAnalyticsConfig> {
    return this._httpClient.updateData(`${this._runAnalyticsConfig}/${runAnalyticsConfig.id}`, runAnalyticsConfig);
  }
}
