import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NoteEditorComponent, NotesListComponent } from './';

@NgModule({
  imports: [SharedModule],
  declarations: [NotesListComponent, NoteEditorComponent],
  exports: [NotesListComponent],
})
export class NotesModule {
}
