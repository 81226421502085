<div class="row">
  <div class="col-lg-12">
    <label class="control-label col-md-4 col-xs-8">
      <span [innerText]="toleranceSettingsSell[0].displayName"></span>
      <a href="javascript:void(0);" data-toggle="tooltip" [title]="toleranceSettingsSell[0].helpText" class="btn-help">
        <i class="fas fa-question-circle" aria-hidden="true"></i>
      </a>
    </label>
    <div class="col-md-1 col-xs-2"></div>
    <div class="col-md-7 col-xs-12">
      <div class="field-control">
        <select [id]="toleranceSettingsSell[0].name" class="form-control" [(ngModel)]="toleranceSeleted" (change)="dropdownSelectedOption($event)"
                title="Default">
          <option *ngFor="let opt of toleranceSettingSellOptions" [id]="opt.name" [value]="opt.name">{{opt.name}}</option>
        </select>
      </div>
      <a href="javascript:void(0)" class="btn-reset">
        <i class="fas fa-undo" (click)="setResetControl($event,toleranceSettingsSell[0])" [id]="toleranceSettingsSell[0].name" aria-hidden="true"></i>
      </a>
    </div>

  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div *ngIf="toleranceSeleted =='Out of Tolerance'">
      <div *ngFor="let pref of objToleranceSettingSell" class="row">
        <label class="control-label col-md-4 col-xs-12">
          <span [innerText]="pref.displayName"></span>
          <a href="javascript:void(0);" data-toggle="tooltip" [title]="pref.helpText" class="btn-help">
            <i class="fas fa-question-circle" aria-hidden="true"></i>
          </a>
        </label>

        <div class="col-md-1 col-xs-12"></div>
        <div class="col-md-7 col-xs-12">
          <div class="field-control">
            <label [title]="">
              <input type="Checkbox" [id]="pref.name" [name]="pref.name" [value]="pref.value" [checked]="(pref.value == 'true')? true : false"
                     (change)="setCheckBoxValue($event)" />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
