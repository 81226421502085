import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { PortfolioService } from '../../services/portfolio.service';
import { IPortfolioSimpleSearch } from '../../models/portfolio';
import { ITradeGridItem } from '../../models/spendcash';

@Component({
  selector: 'eclipse-sleeve-portfolio-auto-complete',
  templateUrl: './sleeveportfolio.autocomplete.component.html'
})
export class SleevePfAutoCompleteComponent extends BaseComponent {

  suggestions: IPortfolioSimpleSearch[] = [];
  selectedItem: string;
  @Input() excludeDoNotTrade: boolean = false;
  @Input() list: Array<ITradeGridItem> = [];
  @Output() parentCallback = new EventEmitter();

  constructor(private _portfolioService: PortfolioService) {
    super();
  }

  /** To get portfolios for search autoComplete  */
  loadSuggestions(event) {
    this._portfolioService.searchSleevePortfolioSimple(event.query.toLowerCase(), this.excludeDoNotTrade)
      .subscribe((model: IPortfolioSimpleSearch[]) => {
        const addedPortfolioIds = this.list.map(l => l.id);
        this.suggestions = model.filter(portfolio =>
          ((!addedPortfolioIds.includes(portfolio.id)) && (portfolio.isDeleted === 0) && (portfolio.sleevePortfolio === 1)));
        this.formatPortfolioSuggestions(this.suggestions, event);
      });
  }

  /** Fires on item select */
  onPortfolioSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({ value: this.selectedItem });
  }

}
