import { Dictionary } from 'lodash';
import { ITacticalTrade } from './tactical';
import { ITradeGridItem } from './spendcash';

/** Defines the TradeToolsFilter entity */
export interface ITradeToolsFilter {
  id: number;
  name: string;
  tag: string;
}

/* Defines the Sell security entity */
export interface ISellSecurity {
  id: number;
  name: string;
}

/* Defines the Trade security entity */
export interface ITradeSecurities {
  sellSecurityId: number;
  sellSecurityName: string;
  buySecurityId: number;
  buySecurityName: string;
}

/* Defines the Trade groups portfolio entity */
export interface ITradeGroupsPortfolio {
  id: number;
  name: string;
}

/* Defines the Trade groups portfolio entity */
export interface ITradeGroupsAccount {
  id: number;
  name: string;
}

/* Defines the entity for excel import */
export interface IExcelImport {
  name: string;
  document: any;
}

/* Defines the entity for generate trades */
export interface IGenerateTrades {
  accountIds: number[];
  portfolioIds: number[];
  modelIds: number[];
  tradeGroupIds: number[];
  portfolioTradeGroupIds: number[];
  tradeGroupForAccountIds: number[];
  tradeGroupForPortfolioIds: number[];
  isViewOnly: boolean;
  isExcelImport: boolean;
  security: ITradeSecurity[];
  notes: string;
  reason: string;
  swapOptions: {
    tickerBatch: number;
    profitLoss: number;
    value: number;
    valueType: number;
    includeTaxDeferredOrExemptAccounts: boolean;
    instanceNote: string;
  };
  tradeQueueId: number;
  filter: ICashNeedDefaultPreference;
  cashNeedFromTactical: boolean;
  maxGainAmount: number;
  maxGainAmountType: string;
  maximumCapitalGainsAllowed: string;
  includeYTDGainLoss: boolean;
  tradeToolSelection: TradeToolSelectionMethod;
  tradeInstanceType: TradeInstanceType;
  tradeInstanceSubType: TradeInstanceSubType;
  accessS3ForData: boolean;
  uploadJsonToS3: boolean;
  generateLogFile: boolean;
  globalTradeTaxOptions: IGlobalTradeTaxConsideration;
}

/** Defines the entity trade security */
export interface ITradeSecurity {
  sellSecurityId: number;
  buySecurityId: number;
  percent: number;
}

/**Defines the entity file upload results  */
export interface IFileUploadResult {
  recordType: string;
  records: IFileUploadRecords[];
  importFileHaveAccounts: boolean;
}

/**Defines the entity file upload records  */
export interface IFileUploadRecords {
  id: number;
  name: string;
  accountId: number;
  accountNumber: number;
  isValid: boolean;
  portfolioId: number;
  needAnalytics: number;
  isSleeve?: boolean;
  sleevePortfolio?: boolean;
}

export interface IBatchJobDetail {
  id: number;
  batchJobId: number;
  record: string;
  recordId: string;
  status: string;
  message: string;
}

export interface IBatchJob {
  id: number;
  type: string;
  description: string;
  startedDate: Date;
  completedDate: Date;
  status: string;
  errorCount: number;
  successCount: number;
  totalCount: number;
  percentComplete: number;
  createdBy: string;
  details: IBatchJobDetail[];
}

/* Defines the global trades parent model entity  */
export interface IGlobalTradesParentModel {
  selectedFile: any;
  isOnLoad: boolean; // Flag to jump to second tab incase of querystring values from portfolio/model/account.
  selectedVal: string;
  selectedAccountsList: any;
  portfolioId: any;
  excelRecords: any[];
  uploadedRecordType: string;
  isGlobalTrade: boolean;
  displayFilterOptions: {
    showPortfolio: boolean;
    showAccounts: boolean;
    showModel: boolean;
    showtradeGroupsForPortfolio: boolean;
    showtradeGroupsForAccounts: boolean;
    showtradeGroups: boolean;
    showExcelImport: boolean;
    showSleeves: boolean;
    showExcelImportPortfolio: boolean;
    showExcelImportSleeves: boolean;
  };
}

export interface ILiquidateParentModel {
  selectedFile: IExcelImport;
  isOnLoad: boolean;
  selectedVal: string;
  selectedAccountsList: ITradeGridItem[];
  portfolioId: string;
  excelRecords: IFileUploadRecords[];
  uploadedRecordType: string;
  displayFilterOptions: IDisplayFilterOptions;
  isLiquidate: boolean;
}

export interface IDisplayFilterOptions {
  showPortfolio: boolean;
  showAccounts: boolean;
  showModel: boolean;
  showtradeGroups: boolean;
  showExcelImport: boolean;
}

export interface ILiquidateRequestModel {
  portfolioIds?: number[];
  accountIds?: number[];
  tradeGroupIds?: number[];
  tradeToolSelection: number;
  reason: string;
  isExcelImport: boolean;
  isViewOnly: boolean;
  sleeveAccountMethod: string;
}

export interface ILiquidateResult {
  instanceId: number;
  message: string;
  trades?: ITacticalTrade[];
}

export interface ILiquidateSessionData {
  ids: string;
  type: string;
}

/** Defines the entity for Trade to Target */
export interface ITradeTraget {
  accountIds: number[];
  modelIds: number[];
  tradeGroupIds: number[];
  isViewOnly: boolean;
  isExcelImport: boolean;
  security: ISecurity;
  preferences: IPreferencesRebalance;
  tradeToolSelection: TradeToolSelectionMethod;
  tradeInstanceType: TradeInstanceType;
  tradeInstanceSubType: TradeInstanceSubType;
}

export interface ISecurity {
  securityId: number;
  targetPercent: number;
  side: number;
  isFullTrade: boolean;
  modelTypeId: number;
  reason: string;
}

export interface IPreferencesRebalance {
  minimumTradePercent: number;
  minimumTradeDollar: number;
  allowWashSalesId: number;
  allowShortTermGainsId: number;
  cashProtection: string;
}

export interface IPreferencesOptionTrading {
  strikePricePercent?: number;
  expirationDateEnd?: number;
  expirationDateStart?: number;
}

/**Define Trade Side */
export interface ITradeSide {
  id: number;
  name: string;
}

/** Define Trade AllowShort Term Gains */
export interface IShortTermGain {
  id: number;
  name: string;
}

/**Define list for allow Wash Sales  */
export interface IWashSales {
  id: number;
  name: string;
}

/**Define  model types on the basis of
 models or portfolios, or accounts */
export interface IModelTypes {
  id: number;
  name: string;
  modelType: string;
  modelTypeId: number;
  isFavorite: boolean;
  nameSpace: any;
  securityAsset: IModelsecurityAsset;
  hasSubstitute?: boolean;
}

export interface IModelsecurityAsset {
  id: number;
  name: string;
  color: string;
}
export interface ISwapOptions {
  tickerBatch: number;
  profitLoss: number;
  value: number;
  valueType: number;
  includeTaxDeferredOrExemptAccounts: boolean;
  instanceNote: string;
}

export interface ITLHFilters {
  taxableAccountsOnly: boolean;
  gainLoss: number;
  term: number;
  sign: number;
  amount: number;
  note: string;
  description: string;
  tlh: boolean;
  isViewOnly: boolean;
  harvestType: number;
  ids: number[];
  type: string;
  isFullPositionHarvest: boolean;
  specificAmountType?: string;
  specificAmount: number;
  securityFilterType?: string;
  minimumGainLoss: number;
  minimumGainLossAmount?: number;
  minimumGainLossPercent?: number;
  ignoreDoNotTLH?: boolean;
  enableVSP: boolean;
}

export interface ITradeTLH {
  accountName: string;
  accountNumber: number;
  custodianId: number;
  accountTypeId: number;
  accountType: number;
  symbol: string;
  sell_price: number;
  portfolioRealizedYTDGL$: number;
  totalGLAmount: number;
  GLPercent: number;
  STGLAmount: number;
  LTGLAmount: number;
  tradeSTGLAmount: number;
  tradeLTGLAmount: number;
  accountValue: number;
  cashValue: number;
  securityName: string;
  currentShares: string;
  currentValue$: number;
  currentPercent: number;
  custodian: string;
  managementStyle: string;
  accountId: number;
  portfolioId: number;
  tlhSecurityId: number;
  tlhSecurityName: string;
  tlhSecuritySymbol: string;
  securityId: number;
  tlhSellingPercent: number;
  modelId: number;
  modelName: string;
  nodeId: number;
  costPrice: number;
  modelElementId: number;
  securitySetId: number;
  priority: number;
  tlhSecurityData: tlhSecurityData[];
  isCheck: boolean;
  shares: number;
  tradeAmount: number;
  isValidTrade: boolean;
  errorMessage: string;
  equivalenceSecurityId: number;
  isEquivalentTicker: boolean;
  needAnalytics: number;
  failedReason: string;
  isDoNotTLHSetOnSecuritySet: boolean;
  relatedType: number;
  defaultTLHSecurityId: number;
  buyTradeAmount: number;
  buyTradeShares: number;
  cashValuePostTrade: number;
}

export interface tlhSecurityData {
  tlhSecurityId: number;
  tlhSecurityName: string;
  tlhSecuritySymbol: string;
  priority: number;
}

export interface ITLH {
  trades: ITradeTLH[];
  haveDoNotTLHSecurity: boolean;
  batchId: string;
  tradeFetchErrors: {
    hasError: boolean;
    ids: number[];
  };
}

export interface IValidateTradeResponse {
  trades: IValidateTrade[];
  cashValuePostTrade: INumberDictionary;
}

export interface IValidateTrade {
  accountId: number;
  buyTickerId: number;
  buyTickerName: string;
  isValidTrade: boolean;
  percent: number;
  sellTickerId: number;
  sellTickerName: string;
  errorMessage: string;
  portfolioId: number;
  possibleBuyTickerName: string;
  possibleBuyTickerSymbol: string;
  possibleBuyTickerId: number;
  warningMessage?: string;
  buyTradeAmount: number;
  buyTradeShares: number;
  cashValuePostTrade: number;
  sellTradeShares: number;
  sellTradeAmount: number;
  sellTradePercent: number;
}

/**GainOrLoss Entry */
export interface IGainOrLoss {
  id: number;
  name: string;
}

export interface ITradeGroup {
  id: number;
  name: string;
  substitutedModelId: any;
  status: string;
  accountCount: number;
}

/**Define for Rounding dropdown in Rebalancer */
//   export interface IRounding{
//       id:  number,
//       name:  string
//   }

/** Define Priority Ranking */
export interface IPriorityRanking {
  id: number;
  name: string;
  order: number;
}

export interface IMinimumTrade {
  type: string;
  amount: number;
}

export interface IRebalancerWashSales {
  id: number;
  name: string; // "Yes",
  value: boolean;
}

export interface IRebalancerWashGains {
  id: number;
  name: string;
  value: string;
}

/**Generate Rebalancer Trade */
export interface IGenerateRebalance {
  portfolioIds: number[];
  modelIds: number[];
  portfolioTradeGroupIds: number[];
  sleeveIds: number[];
  calculateAtRegistrationLevel: boolean;
  minimumTradeAmount: IMinimumTrade;
  allowWashSale: boolean;
  allowShortTermGain: string;
  liquidateEquivalents: boolean;
  liquidateGroupEquivalents: boolean;
  exactTrade: boolean;
  isExcelImport: boolean;
  includeCustodialCash: boolean;
  priorityRanking: IPriorityRanking[];
  rounding: boolean;
  reason: string;
  useDefaultPreference: boolean;
  maxGainAmount: number;
  maxGainAmountType: string;
  maxShortTermGainAmountType: string;
  maxShortTermGainAmount: number;
  rebalanceType: string;
  maximumCapitalGainsAllowed: string;
  includeYTDGainLoss: boolean;
  portfolioAccountMap: Object;
  tacticalRebalanceCashProtection: string;
  tacticalRebalanceDoNotSellUnAssignedSecurities: boolean;
  tacticalRebalanceDoNotRebalanceCash: boolean;
  tacticalRebalanceSecurities: any[];
  modelTypeIds: number[];
  sleeveStrategyIds?: any[];
  isViewOnly?: boolean;
  sleeveModelIds?: number[];
  tradeTolerancePercent: number;
  rebalanceTarget: string;
  sleevePortfolioRebalanceTarget: string;
  sleeveTradeTolerancePercent: number;
  individualSleeveRebalanceMethod: string;
  sleevePortfolioRebalanceLevel: string;
  sleevePortfolioMinCashThreshold: number;
  tradeToolSelection: TradeToolSelectionMethod;
  tradeInstanceSubType: TradeInstanceSubType;
  tradeInstanceType: TradeInstanceType;
  liquidateUnassignedSecurities: boolean;
  rebalanceLevelSetting: string;
  accessS3ForData: boolean;
  uploadJsonToS3: boolean;
  generateLogFile: boolean;
}

/** Define for Minimum Trade Amount For Rebalancer */
export interface ITradeAmount {
  cashAmount: number;
}

/** Define for Minimum Trade Symbols for Rebalancer  */
export interface IMinTradeCashType {
  id: number;
  name: string;
}

export interface ITLHTrades {
  accountId: number;
  sellTickerId: number;
  sellTickerName: string;
  percent: number;
  buyTickerId: number;
  buyTickerName: string;
  isEquivalentTicker: boolean;
  totalGLAmount: number;
  portfolioId: number;
  washSaleGroup?: string;
  isTargeted?: boolean;
  equivalentSecurityId?: number;
}

export interface ITLHGenerateTrade {
  trades: ITLHTrades[];
  filter: ITLHFilters;
  isTaxHarvesting: boolean;
  isEquivalentTicker: boolean;
  allowWashSaleOnSell: boolean;
  instanceNotes: string;
  taxHarvestingFilterBatchId: string;
}

export interface ITradeMessage {
  id: number;
  severity: string;
  shortCode: string;
  arguments: string;
  message: string;
}

/**Define wash sale trade  entities  */
export interface IwashSaleTrades {
  portfolioId: number;
  accountId: number;
  accountNumber: string;
  securityId: number;
  securityName: string;
  amount: number;
}

/**Wash Sale GenerateTrade entities  */
export interface IWashSaleGenerateTrades {
  trades: IwashSaleTrades[];
}

/** Define global trade response entity */
export interface IGlobalTradeResponse {
  instanceId: number;
  tradeAmount: number;
  tradeCost: number;
  orderQty: number;
  trades: ITrade[];
  issues: any[];
  tradeQueueId: number;
}

/** Define global trades trade entity */
export interface ITrade {
  id: number;
  tradeAmount: number;
  tradeCost: number;
  orderQty: number;
  actionId: number;
  accountId: number;
  accountName: string;
  securityId: number;
  securityName: string;
  cashValuePostTrade: number;
  isEnabled: boolean;
  holdUntil: Date;
  orderPercent: number;
  redemptionFee: number;
  transactionFee: number;
  securitySetIds: number[];
  isAssigned: boolean;
  messages: ITradeMessage[];
  taxLots: any[];
}

/** Define global trades message entity */
export interface ITradeMessage {
  id: number;
  severity: string;
  shortCode: string;
  message: string;
}

export interface ITradeRebalanceResponses {
  success: IRebalancerResponse[];
  issues: IRebalancerResponse[];
  instanceId: number;
  tradeQueueId: number;
  trades?: ITacticalTrade[];
}

export interface IRebalancerResponse {
  portfolioId: number;
  accountId: any;
  modelDetailId: any;
  instanceId: number;
  numberOfTradesGenerated: any;
  rebalancerLogFilePath: string;
  message: string;
}

/** Define TH common drop down  entity */
export interface ITHDropdown {
  id: number;
  name: string;
}

export interface ITHMasterFilter {
  methods: ITHDropdown[];
}

export interface ICashNeedDefaultPreference {
  allowWashSales: boolean;
  allowShortTermGains: string;
  liquidateEquivalents: boolean;
  liquidateGroupEquivalents: boolean;
  abilityToStopSalesWithRedemptionFeePenalties: boolean;
  overrideStopTradesWithinXNumberOfDaysOfGoingFromSTGToLTG: boolean;
  stopTradesWithinXNumberOfDaysFromStgToLtg: number;
  overrideTransactionCostLimit: boolean;
  transactionCostLimit: number;
  overrideBuyMinimumTransactionAmount: boolean;
  buyMinimumTransactionAmount: number;
  buyMinimumTransactionAmountType: string;
  overrideSellMinimumTransactionAmount: boolean;
  sellMinimumTransactionAmount: number;
  sellMinimumTransactionAmountType: string;
  overrideBuyMaximumTransactionAmount: boolean;
  buyMaximumTransactionAmount: number;
  buyMaximumTransactionAmountType: string;
  overrideSellMaximumTransactionAmount: boolean;
  sellMaximumTransactionAmount: number;
  sellMaximumTransactionAmountType: string;
  overrideMinimumInitialBuyTransactionAmount: boolean;
  minimumInitialBuyTransactionAmount: number;
  overrideTradeTolerancePercent: boolean;
  tradeTolerancePercent: number;
  rebalanceTarget: string;
  maxGainAmount: number;
  maxGainAmountType: string;
  maxShortTermGainAmountType: string;
  maxShortTermGainAmount: number;
  maximumCapitalGainsAllowed: string;
  includeYTDGainLoss: boolean;
  liquidateUnassignedSecurities: boolean;
}

export interface IContributionSleeveTrade {
  accountNumber: string;
  portfolioName: string;
  fromAccount: string;
  fromId: number;
  funds: number;
  toAccount: string;
  toId: number;
  sleeveContributionPercent: number;
  tradeAmount: number;
  portfolioId: number;
  needAnalytics: number;
}

export enum TradeSeverity {
  Info = 1,
  Warning = 2,
  Error = 3
}

export enum OptionFilterType {
  Account = 'account',
  Portfolio = 'portfolio',
  Model = 'model',
  TradeGroup = 'tradeGroups',
  Import = 'excelImport',
  SleevePortfolio = 'sleevePortfolio',
  IndividualSleeve = 'individualSleeve'
}

export enum TradeToolSelectionMethod {
  Portfolio = 1,
  Account = 2,
  Model = 3,
  AccountTradeGroup = 4,
  SleevePortfolio = 5,
  ExcelImport = 6,
  PortfolioTradeGroup = 7,
  SecurityImport = 8,
  IndividualSleeve = 9,
  TacticalTool = 10
}

export enum TradeInstanceType {
  Astro = 1,
  CashMovements = 2,
  GlobalTrades = 3,
  OptionTrading = 4,
  QuickTrade = 5,
  Rebalance = 6,
  TacticalTool = 7,
  TaxHarvesting = 8,
  TradeToTarget = 10,
  Liquidate = 11
}

export enum TradeInstanceSubType {
  AutomatedLosses = 1,
  Astro = 2,
  CashNeeds = 3,
  FocusedRebalance = 4,
  FullLocationRebalance = 5,
  GlobalTrades = 6,
  ManualGains = 7,
  ManualLosses = 8,
  OptionTrading = 9,
  PartialLocationRebalance = 10,
  QuickTrade = 11,
  RaiseCash = 12,
  SpendCash = 13,
  StandardRebalance = 14,
  TacticalTool = 15,
  TradeImport = 17,
  TradeToTarget = 18,
  JournalOnlyCash = 19,
  JournalCashAndHoldings = 20,
  MoneyMarketRebalance = 21,
  Liquidate = 22
}

export interface IOptionTradesParentModel {
  selectedAccountsList: any;
  excelRecords: any[];
  uploadedRecordType: string;
  displayFilterOptions: {
    showPortfolio: boolean;
    showAccounts: boolean;
    showModel: boolean;
    showtradeGroupsForAccounts: boolean;
    showtradeGroups: boolean;
    showExcelImport: boolean;
    showSleeves: boolean;
    showExcelImportPortfolio: boolean;
    showExcelImportSleeves: boolean;
  };
}

export interface IOptionTrading {
  parentModelData: IOptionTradesParentModel;
}

export interface ITaxHarvestingParentModel {
  selectedFile: any;
  isOnLoad: boolean;
  selectedVal: string;
  selectedAccountsList: any;
  portfolioId: any;
  excelRecords: any[];
  uploadedRecordType: string;
  harvestType: number;
  displayFilterOptions: {
    showPortfolio: boolean;
    showAccounts: boolean;
    showModel: boolean;
    showtradeGroupsForPortfolio: boolean;
    showtradeGroupsForAccounts: boolean;
    showtradeGroups: boolean;
    showExcelImport: boolean;
    showSleeves: boolean;
    showExcelImportPortfolio: boolean;
    showExcelImportSleeves: boolean;
  };
}

export interface ITaxHarvestingSecurity {
  id: number;
  name: string;
  symbol: string;
  securityTypeId?: number;
  label?: string;
}

export interface IGenerateTradeFilters {
  trades: ITLHTrades[];
  filter: ITaxHarvestingFilters;
  isTaxHarvesting: boolean;
  allowWashSaleOnSell: boolean;
  instanceNotes: string;
  taxHarvestingFilterBatchId: string;
  tradeToolSelection: TradeToolSelectionMethod;
  tradeInstanceType: TradeInstanceType;
  tradeInstanceSubType: TradeInstanceSubType;
  overridePreference: ITaxHarvestingOverridePreferences;
}

export interface ITaxHarvestingOverridePreferences {
  enableVSP: boolean;
}

export interface ITaxHarvestingFilters {
  isFullPositionHarvest: boolean;
  isGainHarvesting: boolean;
  amount: number;
  term: number;
  securityTypeIds: number[];
  securityIds: number[];
  minimumGainLoss: number;
  minimumGainLossAmount?: number;
  minimumGainLossPercent?: number;
  type: string;
  ids: string | number[];
  tlh?: boolean;
  ignoreDoNotTLH?: boolean;
  isViewOnly?: boolean;
  bucketArray?: number[][];
  batchId?: string;
}

export interface IBatchProgress {
  completedBatchCount: number;
  totalBatchCount: number;
}

export interface ITradeFilterCallbackEvent {
  data: string;
  haveValidSleeveAccount: boolean;
  isAllInvalid: boolean;
  isAllInvalidSleeveType: boolean;
  value: boolean;
}

export interface ISearchTLHSecurity {
  accountId: number;
  custodianId?: number;
  isEquivalent?: boolean;
  portfolioId?: number;
  securityId: number;
  modelId?: number;
}

export interface IAlternateSecurity {
  effectiveTargetPercent: number;
  isEquivalent: number;
  name: string;
  priority: number;
  securityId: number;
  symbol: string;
}

export interface IAlternateBuySecurityResponse {
  securities: IAlternateSecurity[];
}

export interface INumberDictionary {
  [index: number]: number;
}

export interface ICashProtection {
  id: number;
  name: string;
  value: string;
}

export interface IGlobalTradeTaxOption {
  value: string;
  label: string;
}

export interface IGlobalTradeTaxConsideration {
  gainLossMethod: string;
  gainLossAmount: number;
  gainLossAmountType: string;
  includeTaxDeferredOrExemptAccounts: boolean;
}
