<div  class="authenticate">
  <p-card *ngIf="pageStatus !== statuses.Error">
    <ng-template pTemplate="header">
      <div [ngSwitch]="pageStatus" class="p-card-title">
        <p *ngSwitchCase="statuses.Authenticating">Please wait while you are being authenticated…</p>
        <p *ngSwitchCase="statuses.GettingUserDetails">Getting your user account…</p>
        <p *ngSwitchCase="statuses.Success">Success!  Please wait while you are redirected…</p>
        <p *ngSwitchDefault>Warming up the application…</p>
      </div>
    </ng-template>
    <div class="flex" style="justify-content: center;">
      <span *ngIf="pageStatus === statuses.Success"><i class="fas fa-check-circle fa-3x" style="color: var(--badge-background-success)"></i></span>
      <p-progressSpinner *ngIf="pageStatus !== statuses.Success"></p-progressSpinner>
    </div>
  </p-card>

  <div *ngIf="pageStatus == statuses.Error">
    <p-message severity="error" text="{{errorMessage}}"></p-message>
    <div class="m-t-10 text-center">
      <a href="/">Return to Login</a>
    </div>
  </div>
</div>
