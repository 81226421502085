import { Injectable } from '@angular/core';
import { PortfolioService } from '../../../services/portfolio.service';
import { PreferenceService } from '../../../services/preference.service';
import { IBatchJobPreferenceParameter } from '../../../models/preferences/preference';
import * as Constants from '../../../libs/app.constants';
import { SessionHelper } from '../../../core';
import { IEsgAssignmentImportResult } from '../../../models/preferences/securityPreference';

@Injectable({
  providedIn: 'root'
})

export class PreferenceHubService {
  constructor(private readonly _preferenceService: PreferenceService,
    private readonly _portfolioService: PortfolioService,
    private readonly _sessionHelper: SessionHelper) {
  }

  updateBatchJobForSecurityPreferenceChange(batchJobParams: IBatchJobPreferenceParameter): void {
    this._preferenceService.updateBatchJobForSecurityPreferenceChange(batchJobParams).subscribe(() => {
    });
  }

  updateBatchJobAndAnalyticsStatus(result: IEsgAssignmentImportResult): void {
    if (result.portfolioIds.length) {
      const batchJobParams = {
        runFor: Constants.PREFERENCE_LEVEL.FIRM,
        runForIds: [this._sessionHelper?.getUser()?.firmId],
        primaryTeamId: this._sessionHelper?.getUser()?.primaryTeam
      } as IBatchJobPreferenceParameter;
      this._preferenceService.updateBatchJobForSecurityPreferenceChange(batchJobParams).subscribe(() => {
        this.updateAnalyticsStatus(result.portfolioIds, Constants.ANALYTICS_TRIGGER.PREFERENCE_EDITS_ALL_OTHER_LEVELS, Constants.PREFERENCE_LEVEL.PORTFOLIOS);
      });
    }
  }

  updateAnalyticsStatus(ids: number[], trigger: string, preferenceLevel: string): void {
    if (ids.length) {
      this._portfolioService.runPortfolioAnalysisByTrigger(ids, trigger, preferenceLevel).subscribe(() => {
      });
    }
  }
}
