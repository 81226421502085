import { Component, Input, HostListener } from '@angular/core';
import { GridOptions, ColDef, GridReadyEvent, GridApi } from '@ag-grid-community/core';
import {BaseComponent} from '../../core/base.component';
import {ITradeMinPreference, ITradeMinPreferencesGet} from '../../models/preferences/trademinpreference';
import {SecurityService} from '../../services/security.service';
import { Utils as Util } from '../../core/functions';

@Component({
  selector: 'eclipse-trade-min',
  templateUrl: './trademin.component.html'
})
export class TradeMinComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  PrefrowData: ITradeMinPreference[] = [];
  selectedSecurityType: any = null;
  selectedSecurityTypeId: number = null;
  trademinPref: ITradeMinPreference = <ITradeMinPreference>{};
  securityTypeId: number;
  securitySymbol: string;
  type: string = '$';
  securityTypes: any[];
  securityTypesHidden: any[];
  securityTypesInherited: any[];
  canShow: boolean = true;
  canRead: boolean = false;
  btnDisableSetPref: boolean = true;
  displayTradeMinPref: boolean = false;
  isInherited: boolean = false;
  isPreferenceEdited: boolean = false;

  @Input() displayPermission: string;
  @Input() fromParent: ITradeMinPreferencesGet;
  @Input() st: any[];
  fromParentPrefLevel: string;
  clonedSecurityTypes: any[]; /** Security Types :: New approach  */

  constructor(private _securityService: SecurityService) {
    super();
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
  }

  ngOnInit() {
    this.PrefrowData = this.fromParent.tradeMinPreferences;
    this.fromParentPrefLevel = this.fromParent.levelName.toLowerCase();
    this.resetSecurityTypes();
  }

  /** Reset all security types that are customized */
  resetSecurityTypes() {
    if (!this.isInherited) {
      this.securityTypesHidden = Util.deepClone(this.st);
      this.securityTypes = Util.deepClone(this.st);
      this.clonedSecurityTypes = Util.deepClone(this.st);
      /** Splice the existing security Types */
      this.spliceExistingSecTypes();
      this.createColumnDefs();
    } else {
      this.handleSecTypesAfterInherit(this.st);
      this.createColumnDefs();
    }
  }

  /** Splice already selected Security type/s from existing Security Types  */
  spliceExistingSecTypes() {
    if (this.PrefrowData) {
      const secTypes = this.PrefrowData.map(m => m.securityTypeId);
      secTypes.forEach(m => {
        // eslint-disable-next-line eqeqeq
        this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.id == m), 1);
        Util.sortBy(this.securityTypes, 'name');
      });
    }
  }

  /** Security Type on Dropdown selection changed event */
  handleSelectedSecurityType(securityTypeId) {
    if (+securityTypeId) {
      this.btnDisableSetPref = false;
      this.securityTypeId = securityTypeId;
      this.selectedSecurityTypeId = +securityTypeId;
      // eslint-disable-next-line eqeqeq
      const secType = this.securityTypes.filter(x => x.id == +securityTypeId);
      // eslint-disable-next-line eqeqeq
      this.selectedSecurityType = (secType.length > 0 && secType != null) ? secType[0].name : '';
    } else {
      this.btnDisableSetPref = true;
    }
  }

  /** to open preferences popup*/
  setPrefPopup(selectedSecurityType) {
    this.selectedSecurityTypeId = null;
    this.displayTradeMinPref = true;
    this.resetForm();
    this.bindTradeMinEmptyData(selectedSecurityType);
  }

  /** Find index of expanded row  */
  findExpandedRowIndex(array, key, val) {
    for (let i = 0; i < array.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (array[i][key] == val) {
        return i;
      }
    }
    return null;
  }

  /** To Reset Form */
  resetForm() {
    this.trademinPref = <ITradeMinPreference>{};
    this.btnDisableSetPref = true;
    this.isPreferenceEdited = false;
  }

  /**Bind Redemption fee pref empty data */
  bindTradeMinEmptyData(selectedSecurityType) {
    this.trademinPref = <ITradeMinPreference>{
      id: +selectedSecurityType,
      securityTypeId: +selectedSecurityType,
      sellMinAmount: null,
      sellMinPercent: null,
      buyMinAmount: null,
      buyMinPercent: null,
      inheritedFrom: null
    };
  }

  /**  create column headers for agGrid */
  private createColumnDefs() {
    // console.log("coldef", this.fromParentPrefLevel)
    this.PrepareColdef();
  }

  /** Prepare colDefs based on Preference level **/
  PrepareColdef() {
    const self = this;
    if (this.displayPermission) {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Sell Min Amount', field: 'sellMinAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Sell Min Percent', field: 'sellMinPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Min Amount', field: 'buyMinAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Min Percent', field: 'buyMinPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
      ];
    } else {
      this.columnDefs = [
        <ColDef>{
          headerName: 'Security Type', field: 'securityType', width: 150, cellRenderer:
            function (params) {
              return self.securityTypeCellRenderer(params, self);
            },
          cellClass: 'text', filter: 'agTextColumnFilter'
        },
        <ColDef>{ headerName: 'Sell Min Amount', field: 'sellMinAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Sell Min Percent', field: 'sellMinPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Min Amount', field: 'buyMinAmount', width: 110, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{ headerName: 'Buy Min Percent', field: 'buyMinPercent', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
        <ColDef>{
          headerName: 'Inherited from', field: 'inheritedFrom', width: 180, cellClass: 'text-center', filter: 'agNumberColumnFilter'
        }
      ];
    }
    this.columnDefs.forEach(c => c.menuTabs = []);
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  /** Fires when row is selected */
  rowSelected(event) {
    if (!this.displayPermission) {
      return;
    }
    let result = '';
    // eslint-disable-next-line eqeqeq
    result = this.clonedSecurityTypes.find(m => m.id == +event.data.securityTypeId).name;
    this.selectedSecurityType = result;
  }

  /** security Type based on its id */
  securityTypeCellRenderer(params, self) {
    let result = '';
    const currentSecurityType = +params.node.data.securityTypeId;
    const checkSecurityType = this.clonedSecurityTypes.filter(m => m.id === +params.node.data.securityTypeId);
    /** NEW LOGIC FOR SECURITY TYPES RENDERING */
    result = (checkSecurityType.length > 0) ? checkSecurityType[0].name : result;
    return result;
  }

  /** method to display context menu on accounts agGrid*/
  getContextMenuItems(params) {
    const contextResult = [];
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Edit Preferences">Edit Preferences</hidden>' });
    // eslint-disable-next-line prefer-template
    contextResult.push({ name: '<hidden id=' + params.node.data.securityTypeId + ' title="Delete Preferences">Delete Preferences</hidden>' });
    return contextResult;
  }

  /** Hostlistner  */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement.title === 'Edit Preferences') {
      this.isPreferenceEdited = true;
      this.displayTradeMinPref = true;
      this.trademinPref = Object.assign({}, this.PrefrowData.find(x => x.securityTypeId === +targetElement.id));
      this.securityTypeId = +this.trademinPref.securityTypeId;
    }

    if (targetElement.title === 'Delete Preferences') {
      const selectedPrefToDelete = Number(targetElement.id);
      const rowData = this.gridApi.getGridOption('rowData');
      rowData.splice(rowData.findIndex(x => x.securityTypeId === selectedPrefToDelete), 1);
      this.gridApi.setGridOption('rowData', rowData);
      const deletedObject = this.securityTypesHidden.find(x => x.id === +selectedPrefToDelete);
      this.securityTypes.push(deletedObject);
      Util.sortBy(this.securityTypes, 'name');
    }
  }

  /** cancels the operation and closes the pop-up */
  cancel() {
    this.displayTradeMinPref = false;
    this.bindTradeMinEmptyData(this.trademinPref);
  }

  /** Adding to grid */
  addtoGrid() {
    this.displayTradeMinPref = false;
    // eslint-disable-next-line eqeqeq
    this.trademinPref.id = (this.trademinPref.id != undefined) ? +this.trademinPref.id : null;
    this.trademinPref.securityTypeId = +this.securityTypeId;
    this.trademinPref.securityType = null;
    // eslint-disable-next-line eqeqeq
    this.trademinPref.sellMinAmount = (this.trademinPref.sellMinAmount != undefined) ? this.trademinPref.sellMinAmount : null;
    // eslint-disable-next-line eqeqeq
    this.trademinPref.sellMinPercent = (this.trademinPref.sellMinPercent != undefined) ? this.trademinPref.sellMinPercent : null;
    // eslint-disable-next-line eqeqeq
    this.trademinPref.buyMinAmount = (this.trademinPref.buyMinAmount != undefined) ? this.trademinPref.buyMinAmount : null;
    // eslint-disable-next-line eqeqeq
    this.trademinPref.buyMinPercent = (this.trademinPref.buyMinPercent != undefined) ? this.trademinPref.buyMinPercent : null;
    // eslint-disable-next-line eqeqeq
    const match = this.PrefrowData.filter(x => x.securityTypeId == this.trademinPref.securityTypeId);
    if (match.length > 0) {
      match[0] = this.trademinPref;
      const indexVal = this.findExpandedRowIndex(this.PrefrowData, 'securityTypeId', match[0].securityTypeId);
      this.PrefrowData[indexVal] = match[0];
    } else {
      this.PrefrowData.push(this.trademinPref);
    }
    this.gridApi.setGridOption('rowData', this.PrefrowData);
    if (!this.isPreferenceEdited) {
      // eslint-disable-next-line eqeqeq
      this.securityTypes.splice(this.securityTypes.findIndex(sc => sc.name == this.selectedSecurityType), 1);
      Util.sortBy(this.securityTypes, 'name');
    }
    this.resetForm();
  }

  /*  binding InheritedSecurity Preference to gridOptions */
  bindTradeMinData(securityResults) {
    this.isInherited = true;
    this.resetForm();
    this.PrefrowData = this.fromParent.tradeMinPreferences;
    this.createColumnDefs();
    this.resetSecurityTypes();
    this.trademinPref = <ITradeMinPreference>{
      id: null,
      securityTypeId: null,
      sellMinAmount: null,
      sellMinPercent: null,
      buyMinAmount: null,
      buyMinPercent: null,
      inheritedFrom: null
    };
  }

  /** validate percent */
  validatePercent(value) {
    if (value.sellMinPercent > 100) {
      this.trademinPref.sellMinPercent = 100;
    }
    if (value.buyMinPercent > 100) {
      this.trademinPref.buyMinPercent = 100;
    }
  }

  /** Handle the security types after inherit */
  handleSecTypesAfterInherit(securitieTypesResult: any) {
    this.securityTypesInherited = securitieTypesResult;
    const inheriteds = this.fromParent.inheritedTradeMinPreferences;
    const arrInheriteds = inheriteds.map(m => m.securityTypeId);
    if (inheriteds.length > 0) {
      arrInheriteds.forEach(secId => {
        // eslint-disable-next-line eqeqeq
        this.securityTypesInherited.splice(this.securityTypesInherited.findIndex(sc => sc.id == secId), 1);
      });
      this.securityTypes = this.securityTypesInherited;
      Util.sortBy(this.securityTypes, 'name');
    } else {
      this.securityTypes = securitieTypesResult;
      Util.sortBy(this.securityTypes, 'name');
    }
    this.isInherited = false;
  }
  /** Handle the special characters */
  setValid(event) {
    // eslint-disable-next-line eqeqeq
    if (event.key.toLowerCase() == 'e' || event.key == '-' || event.key == '+' || event.key == ',' || event.key == '/'
      // eslint-disable-next-line eqeqeq
      || event.key == '?' || event.key == '*') {
      return false;
    }
    return true;
  }


}
