import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';

@Injectable({
  providedIn: 'root'
})
export class SleeveService {
  private _portfoilioSleeveEndPoint = 'portfolio/sleeves';
  private _sleeveSimpleSearchEndPoint = 'account/accounts/simple';

  constructor(private _httpClient: OEHttpClient) {  }

  /** get Sleeved accounts for autocomplete with accountNumber/portfolioName/accountNumber */
  searchportfolioSleeveAccounts(searchString: string) {
    return this._httpClient.getData(`${this._portfoilioSleeveEndPoint}?search=${searchString}`);
  }

  /** Gets Allocations for selected Sleeved account associated with a model */
  getSleevedAccountAllocation(sleevedAccountId: number) {
    return this._httpClient.getData(`${this._portfoilioSleeveEndPoint}/${sleevedAccountId}/allocations`);
  }

  /** Sleeve accounts search */
  getSleevedAccountSearch(searchString: string) {
    return this._httpClient.getData(
      `${this._sleeveSimpleSearchEndPoint
      }?excludeNeedAnalytics=true&excludeSleeve=true&excludeSMA=true&inSleeve=true&includevalue=true&search=${searchString}`);
  }
}
