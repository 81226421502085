import {Component, Input, ViewChild} from '@angular/core';
import {ISecurityPreferenceTab} from '../../models/preferences/securityPreference';
import {IPreferenceDataVM} from '../../viewModels/preference.data';
import {Observable} from 'rxjs';
import {PREFERENCE_LEVEL} from '../../libs/app.constants';
import {BaseComponent} from '../../core/base.component';
import {SecurityPreferenceComponent} from './security.preference.component';
import {TargetedSecurityComponent} from './targeted-security/targeted-security.component';
import {HoldingSecurityComponent} from './holding-security/holding-security.component';
import {ESGRestrictedSecuritiesComponent} from './esg-restricted-securities/esg-restricted-securities.component';
import {SecurityPreferenceHubService} from './shared/security-preference-hub.service';
import {SecurityPreferenceHelper} from "./shared/security-preference-helper";

@Component({
  selector: 'eclipse-security-preference-tab',
  templateUrl: './security-preference-tab.component.html',
  styleUrls: ['./security-preference-tab.component.scss']
})
export class SecurityPreferenceTabComponent extends BaseComponent {
  @Input() displayPermission: boolean;
  @Input() preferenceLevel: string;
  preferenceLevelId: number;
  width: string;
  selectedTabNumber: number;
  selectedTabName: string;
  securityPreferenceTabMap: ISecurityPreferenceTab[];
  selectedLevelIds: number[];

  @ViewChild(SecurityPreferenceComponent) securityPreferenceComponent: SecurityPreferenceComponent;
  @ViewChild(TargetedSecurityComponent) targetedSecurityComponent: TargetedSecurityComponent;
  @ViewChild(HoldingSecurityComponent) holdingSecurityComponent: HoldingSecurityComponent;
  @ViewChild(ESGRestrictedSecuritiesComponent) esgRestrictedSecuritiesComponent: ESGRestrictedSecuritiesComponent;

  constructor(private readonly _securityPreferenceHubService: SecurityPreferenceHubService) {
    super();
  }

  ngOnInit(): void {
    this.createShowSecurityPreferenceTabMap();
  }

  loadSecurityData(preferenceVM: IPreferenceDataVM): void {
    this.securityPreferenceComponent.selectedLevelIds = this.selectedLevelIds;
    this.securityPreferenceComponent.loadSecurityData(preferenceVM);
    if (preferenceVM?.levelPreferences?.id) {
      this.resetGridData();
      this.preferenceLevelId = preferenceVM.levelPreferences.id;
      SecurityPreferenceHelper.isSleevePortfolio = preferenceVM.levelPreferences.isSleevedPortfolio;
      this._securityPreferenceHubService.getPortfolioAccountPreferenceSecurities(preferenceVM?.levelPreferences.level, preferenceVM?.levelPreferences.id);
      this.createShowSecurityPreferenceTabMap();
    }
  }

  resetGridData(): void {
    this.securityPreferenceComponent?.resetData();
    this.targetedSecurityComponent?.resetData();
    this.holdingSecurityComponent?.resetData();
    this.esgRestrictedSecuritiesComponent?.resetData();
  }

  saveSecurities(): Observable<any>[] {
    return this.securityPreferenceComponent.saveSecurities();
  }

  createShowSecurityPreferenceTabMap(): void {
    let count = 1;
    this.selectedTabName = 'pills-all-tab';
    this.securityPreferenceTabMap = [
      {
        id: 'pills-all-tab',
        tabName: 'SECURITIES WITH PREFERENCES',
        href: '#all-securities',
        isActive: true,
        tabNumber: count,
        component: this.securityPreferenceComponent,
      }
    ];

    if ((this.preferenceLevel === PREFERENCE_LEVEL.PORTFOLIO || this.preferenceLevel === PREFERENCE_LEVEL.ACCOUNT) && !SecurityPreferenceHelper.isSleevePortfolio) {
      count = count + 1;
      this.securityPreferenceTabMap.push({
        id: 'pills-target-tab',
        tabName: 'TARGETED',
        href: '#targeted-securities',
        tabNumber: count,
        component: this.targetedSecurityComponent,
      });
    }

    if (this.preferenceLevel === PREFERENCE_LEVEL.PORTFOLIO || this.preferenceLevel === PREFERENCE_LEVEL.ACCOUNT) {
      count = count + 1;
      this.securityPreferenceTabMap.push({
        id: 'pills-holding-tab',
        tabName: 'CURRENT HOLDINGS',
        href: '#holding-securities',
        tabNumber: count,
        component: this.holdingSecurityComponent,
      });
    }

    if (this.preferenceLevel === PREFERENCE_LEVEL.PORTFOLIO) {
      count = count + 1;
      this.securityPreferenceTabMap.push({
        id: 'pills-esg-tab',
        tabName: 'ESG RESTRICTED',
        href: '#esg-restricted',
        tabNumber: count,
        component: this.esgRestrictedSecuritiesComponent,
      });
    }
    this.securityPreferenceTabMap[count - 1].isLastElement = true;
    this.width = count === 2 ? '412' : count === 3 ? '574' : '736';
  }

  onTabChange(tab: ISecurityPreferenceTab): void {
    this.selectedTabNumber = tab.tabNumber;
    this.selectedTabName = tab.id;
    this.securityPreferenceTabMap.forEach(t => t.isActive = t.id === tab.id);
  }

  getExcelExportDataSheets(): string[] {
    return this.securityPreferenceTabMap.map(t => t.component.exportToExcelDataSheet());
  }
}
