import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { SessionHelper } from '../session.helper';

/**
 * Performs route guarding for lists components to allow the user to search for a single entity
 * instead of loading the entire list.
 *
 * The user must have the `requireSearch` property set on the user record in session.
 *
 * The search component must be passed to the guard via the `data` property on the route.  This component is
 * instantiated by the dialog service and allows the user to search for an entity.  Once an entity is selected,
 * the Id is returned and the guard creates a new urlTree to redirect to, using the `resultPath` property from `data`
 * as the template for the url.
 *
 * Caveats:
 *  -If the route being navigated to has `filter` in the url, the router will continue navigation.
 *    This is because filtered lists are deemed small enough to not affect performance, whereas a full list
 *    can take up too much time and resources.
 */
export const requireSearchGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const sessionInfo: SessionHelper = inject(SessionHelper);
    const router: Router = inject(Router);
    const dialogService: DialogService = inject(DialogService);
    const requiresSearch = sessionInfo.getUser().requireSearch;
    // if the user doesn't require a search, or the url contains `filter`, then allow navigation to occur.
    if (!requiresSearch || state.url.indexOf('filter') >= 0) {
      return true;
    }
    // return an observable that will resolve once the user has chosen an entity, chosen to load all, or closes the search dialog.
    return new Observable<boolean | UrlTree>((sender: Observer<boolean | UrlTree>) => {
      // create the search component in a dialog and display it
      const ref = dialogService.open(route.data.searchComponent,
        {
          header: 'Please select',
          width: '500'
        });
      // once the search dialog closes, resolve the observable
      ref.onClose.subscribe(data => {
        if (data) {
          // if an entity has been selected, create a new url based on the `resultPath` property that will display
          // just that entity on the list component.
          if (data.id && data.id > 0) {
            sender.next(router.parseUrl(parseUrl(state.url, route.data.resultPath.replace(':id', data.id))));
          } else { // if the user chooses to load the whole list anyway, resolve to true which will commence navigating
            sender.next(true);
          }
        } else { // if the dialog was closed without a selection, don't continue navigating
          sender.next(false);
        }
        // complete the observable to cleanup
        sender.complete();
      });
    });

  /**
   * Parses a `url` and adds the `redirectTo` in the appropriate position.
   * Examples:
   * parseUrl('/foo/bar/baz', './qux');
   * => /foo/bar/baz/qux
   * parseUrl('/foo/bar/baz', '../qux');
   * => /foo/bar/qux
   * parseUrl('/foo/bar/baz', '../../qux');
   * => /foo/qux
   * parseUrl('/foo/bar/baz', '..');
   * => /foo/bar
   * @param url
   * @param redirectTo
   */
  function parseUrl(url: string, redirectTo: string) {
    const urlTokens = url.split('/');
    const redirectToTokens = redirectTo.split('/');
    let token = redirectToTokens.shift();

    while (token) {
      if (token !== '.' && token !== '..') {
        redirectToTokens.unshift(token);
        break;
      }
      if (token === '..') {
        urlTokens.pop();
      }
      token = redirectToTokens.shift();
    }
    urlTokens.push(...redirectToTokens);
    return urlTokens.join('/');
  }
};
