import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import { IDashboardFilterSave } from '../models/dashboardfilter';

@Injectable({
  providedIn: 'root'
})
export class DashboardFilterService {

  private _dashboardFilterEndPoint = 'v1/dashboardfilter/';

  constructor(private _httpClient: OEHttpClient) { }

  getEditDashBoardData(dashboardType: string, filterId: number) {
    return this._httpClient.getData(`${this._dashboardFilterEndPoint}?type=${dashboardType}&filterId=${filterId}`);
  }

  saveOrUpdateDashboardFilter(filters: IDashboardFilterSave) {
    return this._httpClient.updateData(this._dashboardFilterEndPoint, filters);
  }

  resetDashboardFilter(filterId: number) {
    return this._httpClient.updateData(`${this._dashboardFilterEndPoint}reset`, {filterId: filterId});
  }
}
