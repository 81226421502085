import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import { BaseComponent } from '../../../../core/base.component';
import { AutocompleteHubService } from '../../../search/shared/autocomplete-hub.service';
import { ISecurity } from '../../../../models/security';
import { IEquivalent, ISecurityPreference } from '../../../../models/preferences/securityPreference';
import { SecurityPreferenceHelper } from '../security-preference-helper';
import * as constants from '../../../../libs/app.constants';
import { ViewTypeEnum } from '../../../../libs/app.constants';
import * as _ from 'lodash';
import { SecurityPreferenceHubService } from '../security-preference-hub.service';
import { BaseGridConfiguration } from '../../../gridextensions/basegrid';
import { IExitWarning, ISavedView } from '../../../../viewModels/savedview';

@Component({
  selector: 'eclipse-security-preference-equivalent',
  templateUrl: './security-preference-equivalent.component.html',
  styleUrls: ['./security-preference-equivalent.component.scss']
})
export class SecurityPreferenceEquivalentComponent extends BaseComponent {
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  savedView: ISavedView = <ISavedView>{};
  gridContext = {
    isGridModified: false,
    router: undefined,
    isNone: false,
    self: this,
    isFilterChanged: false
  };
  equivalents: IEquivalent[];
  security: ISecurity;
  securityPreference: ISecurityPreference;
  filterOutSecurityIds: number[];
  setFilter: boolean;
  @Output() onEquivalentUpdate = new EventEmitter<ISecurityPreference>();

  constructor(private readonly _autocompleteHubService: AutocompleteHubService,
              private readonly _securityPreferenceHubService: SecurityPreferenceHubService) {
    super();
    const viewType = ViewTypeEnum.SecurityPreferenceEquivalent;
    this.gridOptions = BaseGridConfiguration.generateExtendedFilterGridOptions(this.defaultFilterableGridOptions, this, viewType, this.refreshEquivalentData.bind(this),
      null, this.savedView);
    (<SideBarDef>this.gridOptions.sideBar).defaultToolPanel = '';
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.savedView.parentGridApi = params.api;
  }

  private static sellBuyPriorityChangeEvent(params, eSelect, eLabel, eCell) {
    let newValue = eSelect.value ? eSelect.selectedOptions[0].textContent : '';
    if (params.colDef.field === 'buyPriority' && Number(params.data['sellPriority']?.id) === constants.sellPriority.ALWAYS_SELL_ALL) {
      eSelect.value = '1';
      newValue = 'Do Not Buy';
    }
    if (params.colDef.field === 'sellPriority' && Number(eSelect.value) === constants.sellPriority.ALWAYS_SELL_ALL) {
      params.data['buyPriority'] = {id: 1, displayName: 'Do Not Buy'};
    } else if (params.colDef.field === 'sellPriority' && Number(params.data[params.colDef.field]?.id) === constants.sellPriority.ALWAYS_SELL_ALL) {
      params.data['buyPriority'] = {id: 0, displayName: 'Default'};
    }

    params.data[params.colDef.field] = {id: eSelect.value, displayName: newValue};
    eLabel.nodeValue = newValue;
    eCell.removeChild(eSelect);
    eCell.appendChild(eLabel);
  }

  private static deleteCellRenderer(params: any): string {
    return `<span><i id =${params.data.id} title="Delete Item" class="fas fa-times red" aria-hidden="true"></i></span>`;
  }

  ngOnInit(): void {
    this.createColDefs();
    this.savedView = <ISavedView>{
      parentColumnDefs: this.columnDefs,
      parentGridOptions: this.gridOptions,
      exitWarning: <IExitWarning>{}
    };
  }

  showSecurityPreferenceEquivalent(securityPreference: ISecurityPreference): void {
    this.securityPreference = securityPreference;
    this.equivalents = securityPreference.equivalents ? _.cloneDeep(securityPreference.equivalents) : [];
    this.equivalents.forEach(equivalent => SecurityPreferenceHelper.updateSellAndBuyPriority(equivalent, true));
    this.refreshEquivalentData();
    this.updateFilterOutSecurities();
    SecurityPreferenceHelper.loadSavedViews(this.gridApi);
  }

  onSearchSecuritySelect(security: ISecurity): void {
    this.security = security;
    if (security?.id) {
      this.addToGrid();
    }
  }

  addEquivalent(): void {
    this.securityPreference.equivalents = this.equivalents;
    this.onEquivalentUpdate.emit(this.securityPreference);
    this._securityPreferenceHubService.addSecurityPreferenceForEquivalent.next(this.equivalents);
    this.resetData();
  }

  cancel(): void {
    this.resetData();
  }

  @HostListener('click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (!targetElement.id) {
      return;
    }
    const id = Number(targetElement.id);
    if (targetElement.title === 'Delete Item' && id) {
      this.equivalents = this.equivalents.filter(e => e.id !== id);
      this.filterOutSecurityIds = this.filterOutSecurityIds.filter(secId => secId !== id);
      this.refreshEquivalentData();
    }
  }

  refreshEquivalentData(): void {
    if (this.equivalents?.length) {
      this.gridApi.setGridOption('rowData', this.equivalents);
    }
    this.setFilter = true;
  }

  private updateFilterOutSecurities(): void {
    this.filterOutSecurityIds = this.equivalents.map(e => e.id);
    if (!SecurityPreferenceHelper.isFirmOrTeamLevel()) {
      this.filterOutSecurityIds = [...this.filterOutSecurityIds, ...SecurityPreferenceHelper.securityIdsInModel];
    }
    this.filterOutSecurityIds.push(this.securityPreference.id);
  }

  private addToGrid(): void {
    const equivalent: IEquivalent = {
      securityId: this.securityPreference.id,
      id: this.security.id,
      symbol: this.security.symbol,
      name: this.security.name,
      securityType: this.security.securityType,
      securityTypeId: this.security.securityTypeId,
      buyPriority: null,
      sellPriority: null
    };
    SecurityPreferenceHelper.updateSellAndBuyPriority(equivalent, true);
    this.equivalents.push(equivalent);
    this.refreshEquivalentData();
    this.updateFilterOutSecurities();
    this._autocompleteHubService.requestToClearSearchData();
  }

  private resetData(): void {
    this.equivalents = [];
    this.securityPreference = null;
    this._autocompleteHubService.requestToClearSearchData();
    this.filterOutSecurityIds = [];
  }

  private createColDefs(): void {
    this.columnDefs = [
      <ColDef>{
        headerName: 'Security ID',
        cellClass: 'text-center ',
        width: 100,
        headerTooltip: 'Security ID',
        field: 'id',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        headerName: 'Symbol',
        cellClass: 'text-center ',
        width: 100,
        headerTooltip: 'Symbol',
        sortable: false,
        field: 'symbol',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Security Name',
        cellClass: 'text-center ',
        width: 250,
        headerTooltip: 'Security Name',
        sortable: false,
        field: 'name',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Type',
        cellClass: 'text-center ',
        width: 100,
        headerTooltip: 'Type',
        sortable: false,
        field: 'securityType',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Buy Priority',
        width: 150,
        headerTooltip: 'Buy Priority',
        sortable: false,
        field: 'buyPriority',
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return this.sellBuyPriorityEditor(params, this);
        }
      },
      <ColDef>{
        headerName: 'Sell Priority',
        width: 150,
        headerTooltip: 'Sell Priority',
        sortable: false,
        field: 'sellPriority',
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return this.sellBuyPriorityEditor(params, this);
        }
      },
      <ColDef>{
        headerName: 'Delete',
        cellClass: 'text-center ',
        width: 90,
        field: 'delete',
        cellRenderer: SecurityPreferenceEquivalentComponent.deleteCellRenderer
      }
    ];
  }

  private sellBuyPriorityEditor(params, self): HTMLDivElement {
    let editing = false;
    const eCell = document.createElement('div');
    eCell.style.cssText = 'height:20px;';
    const eLabel = document.createTextNode(!params.value ? 'Default' : params.value.displayName || 'Default');
    eCell.appendChild(eLabel);

    const eSelect = document.createElement('select');
    self.updateSelectPriorityOptions(params, eSelect);

    if (params.data[params.colDef.field]) {
      eSelect.value = (params.data[params.colDef.field].id == null) ? '0' : params.data[params.colDef.field].id;
    } else {
      eSelect.value = '0';
    }

    const isRestricted = SecurityPreferenceHelper.esgSecurityIds.indexOf(params.data.id) !== -1;
    eCell.addEventListener('click', () => {
      if (!editing && !isRestricted) {
        eCell.removeChild(eLabel);
        eCell.appendChild(eSelect);
        eSelect.focus();
        editing = true;
      }
    });

    eSelect.addEventListener('blur', () => {
      if (editing) {
        editing = false;
        eCell.removeChild(eSelect);
        eCell.appendChild(eLabel);
      }
    });

    eSelect.addEventListener('change', () => {
      if (editing) {
        editing = false;
        SecurityPreferenceEquivalentComponent.sellBuyPriorityChangeEvent(params, eSelect, eLabel, eCell);
        const updatedNodes = [];
        updatedNodes.push(params.node);
        self.gridApi.refreshCells({rowNodes: updatedNodes});
      }
    });

    const anchorElement = document.createElement('a');
    anchorElement.setAttribute('style', 'cursor: pointer');
    anchorElement.id = params.data.id;
    const iconElement = document.createElement('i');
    iconElement.setAttribute('class', 'far fa-lightbulb');
    iconElement.id = params.data.id;
    iconElement.setAttribute('title', (params.colDef.field === 'buyPriority') ? 'Buy Priority' : 'Sell Priority');
    iconElement.setAttribute('aria-hidden', 'true');
    iconElement.setAttribute('style', 'position: absolute;top: 4px;right: 3px;');
    iconElement.setAttribute('symbol', params.data.symbol);
    anchorElement.appendChild(iconElement);
    const ePriorityCell = document.createElement('div');
    ePriorityCell.style.cssText = 'height:20px;';
    ePriorityCell.appendChild(eCell);
    if (!eSelect.value) {
      ePriorityCell.appendChild(anchorElement);
    }
    return ePriorityCell;
  }

  updateSelectPriorityOptions(params: any, eSelect: HTMLSelectElement): void {
    let list: any[];
    if (params.colDef.field === 'buyPriority') {
      list = SecurityPreferenceHelper.buyPriorityList;
    } else if (params.colDef.field === 'sellPriority') {
      list = SecurityPreferenceHelper.sellPriorityList;
    }

    if (list?.length) {
      const option = document.createElement('option');
      option.setAttribute('value', null);
      option.innerHTML = 'Default';
      eSelect.appendChild(option);
      list.forEach(function (item) {
        const eOption = document.createElement('option');
        eOption.setAttribute('value', item.id);
        eOption.innerHTML = item.displayName;
        eSelect.appendChild(eOption);
      });
    }
  }

  onModelUpdated(): void {
    if (this.setFilter) {
      this.setFilter = false;
      SecurityPreferenceHelper.onGridModelUpdates(this.gridApi, this.gridContext);
    }
  }
}
