// The list of file replacements can be found in `angular.json`.
// Base settings, assumes application is being built and deployed on a server.
// Can be overwritten when ng serving locally by replacing this file w/ api.local.ts
export const api = {
  local: false,
  apiEndpoint: {
    v1: '/v1/',
    v2: '/api/v2/'
  },
  notificationEndpoint: '',
};
