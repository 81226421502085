import { Component, OnInit } from '@angular/core';
import { Utils as Util } from '../../../core/functions';
import { ITabGrid } from '../../../viewModels/tom.rightpanel';
import { TomService } from '../../../services/tom.service';
import { GridOptions, ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { IIdName, IModelTolerance, IModelSummary, IModelAnalyzer } from '../../../models/tom';
import { NotificationService } from '../../../core/customSubService';
import * as _ from 'lodash';
import {BaseComponent} from '../../../core/base.component';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';
import { IPortfolioAnalytics } from '../../../models/portfolio';

@Component({
  selector: 'eclipse-tradeorder-model-analysis',
  templateUrl: './modelanalysis.component.html'
})
export class ModelAnalysisComponent extends BaseComponent implements OnInit {
  isCostBasis = true;
  isTradeBlock = true;
  isExcludeAsset = false;
  selectedModelId: number;
  filteredModels: IIdName[];
  modelLevels: IIdName[];
  originalModelLevels: IIdName[];
  tabGrid: ITabGrid;
  summary: IModelSummary;
  private gridApiTab: GridApi;
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  gridRowData: any[];
  tradeIds: number[];
  strTradeIds: string;
  selectedTab;
  tabs: string[] = ['']; // , "category", "class", "subclass", "securityset"];
  selectedCellValueType = 1;
  selectedPortfolioTab = 1;
  selectedModelPortfoliosList: number[];
  portfolioManagedValue: {[k: number]: number | undefined } = {};
  selectedAsset: IModelTolerance;
  refreshSubscription: any;
  showCalculateBox = false;
  targetTolerance = 0;
  gridContext: any = this;
  custodialCash: any = [];
  isRowSelected = false;
  portfolioAssetSubscription: Subscription;
  portfolioAnalytics: IPortfolioAnalytics[];

  constructor(private _notifier: NotificationService, private _tomService: TomService, private readonly _splitService: SplitIoService) {
    super();
    this.portfolioAnalytics = [];
    this.filteredModels = <IIdName[]>[];
    // Initialize grid options to avoid issue in model reload
    this.gridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };
    this.disposeAll();
  }

  ngOnInit() {
    this.selectedModelPortfoliosList = [];
    // models can be loaded before trades loading
    this.loadModelsByTrades();
  }

  /**
   * Refresh the list of models.  If a list of models are passed in the parameters, they will be loaded.
   * Otherwise, the models associated with open orders will be listed.
   * @param tradeData
   * @param models
   */
  public refreshModels(tradeData?: {tradeIds: number[]; action: string}, models?: IIdName[]): void {
    let tradesChanged = false;
    if (!tradeData || typeof tradeData.tradeIds === 'undefined' || !tradeData.tradeIds.length) {
      tradesChanged = !!this.tradeIds?.length;
      this.strTradeIds = '';
      this.tradeIds = undefined;
    } else if (tradeData?.tradeIds.length) {
      this.tradeIds = tradeData.tradeIds;
      const dataTrades = tradeData.tradeIds.join(',');
      tradesChanged = (dataTrades !== this.strTradeIds);
      this.strTradeIds = dataTrades;
    }
    if (models) {
      this.filteredModels = models;
      if (this.filteredModels.length === 1) {
        const modelId = this.filteredModels[0].id;
        if (this.selectedModelId !== modelId) {
          this.selectedModelId = modelId;
          setTimeout(() => {
            this.onModelSelect(modelId);
          }, 0);
        }
      } else if (!this.filteredModels.length) {
        this.selectedModelId = null;
      }
    } else if (!tradeData || tradeData.action === 'Refresh' || tradeData.action === 'Delete' || tradesChanged) {
      this.loadModelsByTrades();
    }
    if (!this.selectedModelId) {
      this.disposeAll();
    }
  }

  refreshClick() {
    if (typeof this.selectedModelId !== 'undefined') {
      this.summary = undefined;
      this.disposeAll();
      this.onModelSelect(this.selectedModelId);
    }
  }

  onModelSelect(modelId: number) {
    this.disposeAll();
    this.tabs = [];
    if (!modelId) {
      this.selectedModelId = undefined;
      return;
    }
    this.selectedModelId = modelId;
    this.selectedModelPortfoliosList = [];
    this.loadModelSummary(modelId);
    this.loadModelLevels(modelId)
      .subscribe(() => {
        // changing selected tab to lower case as api accepts lower but levels coming as upper
        this.loadLevelDataByModelId(this.selectedTab?.toLowerCase() || 'SECURITYSET', modelId);
      });
  }

  // Load the list of models for active trades.
  loadModelsByTrades() {
    this._tomService.getModelsByTrades()
      .subscribe((models: IIdName[]) => {
        this.filteredModels = models;
      });
  }

  loadModelLevels(modelId: number) {
    return this._tomService.getModelLevels(modelId)
      .pipe(tap((levels: IIdName[]) => {
        this.originalModelLevels = levels;
        // changing selected tab to lower case as api accepts lower but levels coming as upper  fix for OE-3121
        this.originalModelLevels.forEach(item => {
            this.tabs.push(item.name.toLowerCase());
        });

        this.modelLevels = Util.sortBy(levels, 'id');
        if (this.modelLevels && this.modelLevels?.length !== 0) {
          const ids = this.modelLevels.map(x => x.id);
          const maxId = Math.max(...ids);
          const maxModelLevel = this.modelLevels.find(x => +x.id === maxId);
          this.selectedTab = maxModelLevel?.name;
        }
      }));
  }

  loadModelSummary(modelId: number) {
    this._tomService.getModelSummary(modelId, this.isCostBasis, this.isTradeBlock, this.isExcludeAsset)
      .subscribe((model: IModelSummary) => {
        this.summary = model;
      });
  }

  recalculateTolerance(hide: boolean = false) {
    if (hide === true) {
      this.targetTolerance = 0;
      this.showCalculateBox = false;
    }
    this.gridApiTab.redrawRows();
  }

  onTabChange(tabName) {
    this.selectedTab = tabName.toUpperCase();
    let gridRowData = [];
    this.isRowSelected = false;
    switch (this.selectedTab) {
      case 'SUBCLASS':
        gridRowData = Util.deepClone(this.tabGrid.orderSubClasses);
        break;
      case 'CLASS':
        gridRowData = Util.deepClone(this.tabGrid.orderClasses);
        break;
      case 'CATEGORY':
        gridRowData = Util.deepClone(this.tabGrid.orderCategories);
        break;
      case 'SECURITYSET':
        gridRowData = Util.deepClone(this.tabGrid.orderSecuritySet);
        break;
      default:
        gridRowData = Util.deepClone(this.tabGrid.orderSecurities);
    }
    if (gridRowData && gridRowData.length) {
      this.tabGrid.gridRowData = gridRowData;
      this.setFloatingRowsValue();
      this.gridApiTab.setGridOption('rowData', this.tabGrid.gridRowData);
    } else {
      this.loadLevelDataByModelId(this.selectedTab.toLowerCase(), this.selectedModelId);
    }
  }

  loadLevelDataByModelId(levelName: string, modelId: number) {
    this.portfolioAnalytics = [];
    this._tomService.getLevelDataByModelId(levelName, modelId, this.isCostBasis, this.isTradeBlock, this.isExcludeAsset)
      .subscribe({
        next: (model: IModelAnalyzer) => {
          if (levelName === 'security') {
            model.levelsData.forEach(item => {
              item.assetName = item.isModelSecurity ? item.assetName : `${item.assetName}*`;
            });
          }
          this.tabGrid.gridRowData = model.levelsData;

          if (model.portfolioFlag?.length) {
            for (const portfolioFlagElement of model.portfolioFlag) {
              this.selectedModelPortfoliosList.push(portfolioFlagElement.portfolioId);
              this.portfolioManagedValue[portfolioFlagElement.portfolioId] = portfolioFlagElement.managedValue;
              const portfolioAnalytic = {} as IPortfolioAnalytics;
              portfolioAnalytic.failedReason = portfolioFlagElement.failedReason;
              portfolioAnalytic.needAnalytics = portfolioFlagElement.needAnalytics;
              portfolioAnalytic.portfolioId = portfolioFlagElement.portfolioId;
              portfolioAnalytic.editedDate = portfolioFlagElement.analyticsEditedDate;
              this.portfolioAnalytics.push(portfolioAnalytic);
            }
          }

          this.SaveAssetData(model.levelsData);

          this.setFloatingRowsValue();
        },
        error: () => {
          this.SaveAssetData([]);
        }
      });
  }

  SaveAssetData(models) {
    // changes tabid's to level names as we will have different levels for each time and id's --
    switch (this.selectedTab.toUpperCase()) {
      case 'SUBCLASS':
        this.tabGrid.orderSubClasses = Util.deepClone(models);
        break;
      case 'CLASS':
        this.tabGrid.orderClasses = Util.deepClone(models);
        break;
      case 'CATEGORY':
        this.tabGrid.orderCategories = Util.deepClone(models);
        break;
      case 'SECURITYSET':
        this.tabGrid.orderSecuritySet = Util.deepClone(models);
        break;
      default:
        this.tabGrid.orderSecurities = Util.deepClone(models);
    }
  }

  /** Create column headers for agGrid */
  createColumnDefs() {
    this.tabGrid.columnDefs = [
      <ColDef>{
        headerName: 'Name',
        field: 'assetName',
        filter: 'agTextColumnFilter',
        width: 185
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 95,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 95,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Need',
        field: 'differenceInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 100,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Post Trade',
        field: 'postTradeInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 109,
        cellClass: 'text-right'
      }
    ];
  }

  createColumnDefsPortfolio() {
    this.columnDefs = [
      <ColDef>{
        colId: 'portfolioId',
        headerName: 'ID',
        field: 'portfolioId',
        width: 50,
        cellClass: 'text-right'
      },
      <ColDef>{
        colId: 'portfolioName',
        headerName: 'Portfolio Name',
        field: 'portfolioName',
        cellClass: 'fs-mask'
      },
      <ColDef>{
        headerName: 'ID',
        field: 'accountId',
        hide: true,
        width: 50,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Account Name',
        field: 'accountName',
        hide: true,
        cellClass: 'fs-mask'
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 85,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 75,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 100,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Post Trade',
        field: 'postTradeInPercentage',
        cellRenderer: (params) => Util.percentageCellRenderer(params, 2),
        width: 100,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Post Trade',
        field: 'postTradeInDollar',
        cellRenderer: (params) => Util.currencyCellRenderer(params, 2),
        hide: true,
        width: 125,
        cellClass: 'text-right',
        tooltipValueGetter: Util.currencyValueForTooltip,
      },
      <ColDef>{
        headerName: 'Current',
        field: 'currentInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 75,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Target',
        field: 'targetInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 75,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Difference',
        field: 'differenceInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 100,
        cellClass: 'text-right'
      },
      <ColDef>{
        headerName: 'Post Trade',
        field: 'postTradeInShares',
        cellRenderer: (params) => Util.sharesCellRenderer(params, 2),
        hide: true,
        width: 100,
        cellClass: 'text-right'
      }
    ];
  }

  onCellClicked(event) {
    if (event.data.assetName !== 'Cash' && event.data.assetName !== 'Custodial Cash') {
      // remove ag-row-selected from the floating node if it exists.
      this.deselectFloatingRow(event);
      this.isRowSelected = true;
      return;
    } else {
      this.gridApiTab.deselectAll();
      if (typeof event.event.target.offsetParent !== 'undefined') {
        event.event.target.offsetParent.classList.add('ag-row-selected');
      }
      const model = event.node.data;
      this.selectedAsset = model;
      this.loadPortfoliosByAssetId(this.selectedAsset, this.selectedPortfolioTab);
    }
  }

  private deselectFloatingRow(event) {
    const baseElem = this.findParentElement(event.event.target.offsetParent, 'ag-root', 0, 5);
    const floatingTop = this.findChildElement(baseElem, 'ag-floating-top');
    if (typeof floatingTop !== 'undefined') {
      const viewPort = this.findChildElement(floatingTop, 'ag-floating-top-viewport');
      if (typeof viewPort !== 'undefined') {
        const container = this.findChildElement(viewPort, 'ag-floating-top-container');
        if (typeof container !== 'undefined' && container.childNodes && container.childNodes?.length && container.childNodes[0]) {
          container.childNodes[0].classList.remove('ag-row-selected');
        }
      }
    }
  }

  private findChildElement(baseElem: any, className: string) {
    const child = _.find(baseElem?.childNodes, function (item: any) {
      if (typeof item.classList !== 'undefined') {
        return item.classList.contains(className);
      }
      return false;
    });
    return child;
  }

  private findParentElement(baseElem: any, className: string, counter: number, limit: number) {
    counter++;
    const parent = baseElem.offsetParent;
    if (parent.classList.contains(className)) {
      return parent;
    } else if (counter === limit) {
      return null;
    } else {
      return this.findParentElement(parent, className, counter, limit);
    }
  }

  /** MultiRow selected in Accounts for Edit preferences */
  onRowSelected(event) {
    const model = event.node.data;
    this.selectedAsset = model;
    this.isRowSelected = true;
    this.loadPortfoliosByAssetId(this.selectedAsset, this.selectedPortfolioTab);
  }

  getRowClass(params) {
    const model = <IModelTolerance>params.data;
    if (params.context.showCalculateBox) {
      if (params.context.targetTolerance > 0 && params.context.targetTolerance < model.targetInPercentage) {
        return 'out-of-tolerance-row';
      }
    } else {
      if (model.postTradeInPercentage < (model.targetInPercentage - model.lowerModelTolerancePercentage) ||
        model.postTradeInPercentage > (model.targetInPercentage + model.upperModelTolerancePercentage)) {
        return 'out-of-tolerance-row';
      }
    }
  }

  loadPortfoliosByAssetId(asset: IModelTolerance, tabId) {
    const modelId = this.selectedModelId;
    this.gridRowData = [];
    this.portfolioAssetSubscription?.unsubscribe();
    this.portfolioAssetSubscription = this._tomService.getPortfoliosByAssetId(this.selectedTab.toLowerCase(), modelId, asset.assetId, asset.modelElementId)
      .subscribe({
        next: (models: IModelTolerance) => {
          // Segregating two result sets for sleeve/normal/
          if (models[0].length && tabId === 1) {
            models[0].forEach(item => {
              // 142697 - Correcting targets for portfolios
              const managedValue = this.portfolioManagedValue[item.portfolioId];

              if (managedValue) {
                const target = managedValue * (asset.targetInPercentage * .01);
                item.targetInDollar = target; // managedValue * asset.targetInPercentage;
                item.targetInShares = asset.currentPrice ?
                  target / asset.currentPrice :
                  0;
              } else {
                // values in `asset` were calculated against total managed value
                //   as opposed to the individual portfolio's managed value
                //   see: `getModelAnalysisTabSummayAndModelDeatils`
                item.targetInDollar = asset.targetInDollar;
                item.targetInShares = asset.targetInPercentage;
              }

              item.targetInPercentage = asset.targetInPercentage;
              item.differenceInPercentage = item.currentInPercentage - asset.targetInPercentage;
              item.differenceInDollar = item.currentInDollar - asset.targetInDollar;
              item.differenceInShares = item.currentInShares - asset.targetInShares;
            });
            this.gridRowData = models[0];
            this.showColumnsVisible(this.selectedCellValueType);
          }

          if (models[1].length && tabId === 2) {
            models[1].forEach(item => {
              item.targetInPercentage = asset.targetInPercentage;
              item.differenceInPercentage = item.currentInPercentage - asset.targetInPercentage;
              item.differenceInDollar = item.currentInDollar - asset.targetInDollar;
              item.differenceInShares = item.currentInShares - asset.targetInShares;
              item.targetInDollar = asset.targetInDollar;
              item.targetInShares = asset.targetInPercentage;
            });

            this.gridRowData = models[1];
            this.showColumnsVisible(this.selectedCellValueType);
          }
        },
        error: () => {
          this.gridRowData = [];
        }
      });
  }

  onPortfolioTabChange(tabId: number) {
    this.selectedPortfolioTab = tabId;
    if (this.isRowSelected) {
      this.loadPortfoliosByAssetId(this.selectedAsset, this.selectedPortfolioTab);
    } else {
      this.showColumnsVisible(this.selectedCellValueType);
    }
  }

  private selectCellValueType(type: number) {
    this.selectedCellValueType = type;
    this.showColumnsVisible(type);
  }

  private showColumnsVisible(type: number) {
    const portfolioColumns = ['portfolioId', 'portfolioName'];
    const accountColumns = ['accountId', 'accountName'];
    const percentageColumns = ['currentInPercentage', 'targetInPercentage', 'differenceInPercentage', 'postTradeInPercentage'];
    const dollarColumns = ['currentInDollar', 'targetInDollar', 'differenceInDollar', 'postTradeInDollar'];
    const sharesColumns = ['currentInShares', 'targetInShares', 'differenceInShares', 'postTradeInShares'];
    let columnsToHide = portfolioColumns.concat(accountColumns, percentageColumns, dollarColumns, sharesColumns);
    this.gridApi?.setColumnsVisible(columnsToHide, false);
    columnsToHide = (type === 1) ? percentageColumns : ((type === 2) ? dollarColumns : sharesColumns);
    columnsToHide = columnsToHide.concat((this.selectedPortfolioTab === 1) ? portfolioColumns : accountColumns);
    this.gridApi?.setColumnsVisible(columnsToHide, true);
  }

  disposeAll() {
    this.summary = undefined;
    this.portfolioAnalytics = [];
    this.disposeTabGrid();
    this.disposePortfolio();
  }

  private disposeTabGrid() {
    this.tabGrid = <ITabGrid>{
      gridOptions: this.tabGrid?.gridOptions ?? this.defaultGridOptions,
      gridRowData: <IModelTolerance[]>[],
      orderSecurities: <IModelTolerance[]>[],
      orderSubClasses: <IModelTolerance[]>[],
      orderClasses: <IModelTolerance[]>[],
      orderCategories: <IModelTolerance[]>[]
    };
    this.createColumnDefs();
  }

  private disposePortfolio() {
    this.gridRowData = <IModelTolerance[]>[];
    this.createColumnDefsPortfolio();
  }

  // to validate target tolarance should not accept more than 100
  private validateTolerance(value) {
    if (value === null || typeof value === 'undefined') {
      return false;
    }
    value = value.toString();
    const pattern = /^\d+(\.\d{1,2})?$/;
    if (!value.match(pattern)) {
      return false;
    }
    /** Value should be greater than '0' and less than or equal to 100 */
    if (+value > 100) {
      this.targetTolerance = 100;
    }
    this.recalculateTolerance(false);
  }

  private setFloatingRowsValue() {
    this.custodialCash = [];
    this.custodialCash = this.tabGrid.gridRowData.filter((x, i) => {
      if (x.assetName.toLowerCase() === 'custodial cash' || x.assetName.toLowerCase() === 'cash') {
        this.tabGrid.gridRowData.splice(i, 1);
        return x;
      }
    });
    this.gridApiTab.setGridOption('pinnedTopRowData', this.custodialCash);
  }

  onTabGridReady(params: GridReadyEvent) {
    this.gridApiTab = params.api;
    this.gridApiTab.setGridOption('pinnedTopRowData', this.custodialCash);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  refreshData(event) {
    let canRefresh = false;
    // If analytics running on any one of the portfolio of the selected model, we can refresh data.
    if (event && event.portfolioFlag && event.portfolioFlag.length &&
      this.selectedModelPortfoliosList && this.selectedModelPortfoliosList.length
    ) {
      event.portfolioFlag.forEach(element => {
        const portfolio = this.selectedModelPortfoliosList.find(p => p === element.portfolioId);
        if (portfolio) {
          canRefresh = true;
          return;
        }
      });
      if (canRefresh) {
        this.refreshClick();
      }
    }
  }
}
