<div [formGroup]="form">
  <div [attr.for]="dynamicControlData.name">
    <div [ngSwitch]="dynamicControlData.componentType">

      <div *ngSwitchCase="'default'">

        <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}">{{dynamicControlData.displayName}}
          <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
        </label>
        <div class="col-md-1 col-xs-4"><span *ngIf="dynamicControlData.symbol !=null" class="input-group-addon-symbol">{{dynamicControlData.symbol }}</span>
          <span *ngIf="dynamicControlData.indicatorOptions != undefined && dynamicControlData.indicatorOptions.length > 0">
                            {{dynamicControlData.indicatorValue}}
                        </span>
        </div>
        <div [ngSwitch]="dynamicControlData.componentName" class="col-md-5 col-xs-12">
          <div *ngSwitchCase="'Textbox'">
            <label [innerText]="dynamicControlData.value"></label>
          </div>
          <div *ngSwitchCase="'Dropdown'">
                        <span *ngIf="dynamicControlData.selectedOptions.length > 0">
                            <label [innerText]="dynamicControlData.selectedOptions[0].name"></label>
                        </span>
          </div>
          <div *ngSwitchCase="'Checkbox'" [id]="dynamicControlData.name">
            <div [ngSwitch]="dynamicControlData.valueType">
              <div *ngSwitchCase="'Boolean'">
                <input type="checkbox" [id]="dynamicControlData.name" [name]="dynamicControlData.name" [formControlName]="dynamicControlData.name"
                       [value]="dynamicControlData.value" (change)="setCheckBoValue($event)" [ngModel]="dynamicControlData.value==='true'"
                       [title]="showTooltip(dynamicControlData)" disabled>
              </div>

              <div *ngSwitchCase="'List'">
                <div *ngFor="let opt of dynamicControlData.options">
                  <input type="checkbox" [id]="dynamicControlData.name" [name]="dynamicControlData.name" [value]="opt.id" [formControlName]="dynamicControlData.name"
                         [ngModel]="dynamicControlData.selectedOptions.indexOf(opt.id) > -1" disabled> {{opt.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Custom control display / rendering-->
      <div *ngSwitchCase="'custom'">
        <div [ngSwitch]="dynamicControlData.componentName">

          <div *ngSwitchCase="'TaxLotDepletionMethodSortedlist'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-5 col-xs-12">
              <eclipse-sort-objects [displayPermission]="false" [(model)]="(dynamicControlData.selectedOptions.length >0) ? dynamicControlData.selectedOptions : dynamicControlData.options"></eclipse-sort-objects>
            </div>
          </div>

          <div *ngSwitchCase="'PriorityRankingSortedlist'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-5 col-xs-12">
              <eclipse-sort-objects [displayPermission]="false" [(model)]="(dynamicControlData.selectedOptions.length > 0) ? dynamicControlData.selectedOptions : dynamicControlData.options"></eclipse-sort-objects>
            </div>
          </div>

          <div *ngSwitchCase="'CommunityStrategistEnabledListCascadingControl'" class="form-group">
            <eclipse-community-strategist [displayPermission]="false" [(CommunityStrategies)]="communityStrategies"></eclipse-community-strategist>
          </div>

          <div *ngSwitchCase="'LocationOptimizationDataGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-5 col-xs-12">
              <eclipse-location-optimization [displayPermission]="false" [(LocationOptimizations)]="LocationOptimizations"></eclipse-location-optimization>
            </div>
          </div>
          <div *ngSwitchCase="'RedemptionFeeDataGrid'">
            <eclipse-redemption-fee [displayPermission]="false" [(fromParent)]="RedemptionFeePrefResult" [(st)] ="secTypes"></eclipse-redemption-fee>
          </div>
          <div *ngSwitchCase="'CustodianRedemptionFeeDataGrid'">
            <eclipse-custodian-redemption-fee [displayPermission]="false" [(fromParent)]="CustodianRedemptionFeePrefResult" [(st)] ="secTypes"></eclipse-custodian-redemption-fee>
          </div>
          <div *ngSwitchCase="'TransactionFeeDataGrid'">
            <eclipse-transaction-fee [displayPermission]="false" [(fromParent)]="TransactionFeePrefResult" [(st)] ="secTypes"></eclipse-transaction-fee>
          </div>
          <div *ngSwitchCase="'TradeMinDataGrid'">
            <eclipse-trade-min [displayPermission]="false" [(fromParent)]="TradeMinPrefResult" [(st)] ="secTypes"></eclipse-trade-min>
          </div>
          <div *ngSwitchCase="'TradeMaxDataGrid'">
            <eclipse-trade-max [displayPermission]="false" [(fromParent)]="TradeMaxPrefResult" [(st)] ="secTypes"></eclipse-trade-max>
          </div>
          <div *ngSwitchCase="'DividendReinvestDataGrid'">
            <eclipse-dividend-reinvest [displayPermission]="false" [(fromParent)]="DividendReinvestPrefResult" [(st)] ="secTypes"></eclipse-dividend-reinvest>
          </div>
          <div *ngSwitchCase="'CapitalGainReinvestDataGrid'">
            <eclipse-capitalgain-reinvest [displayPermission]="false" [(fromParent)]="CapitalGainReinvestPrefResult" [(st)] ="secTypes"></eclipse-capitalgain-reinvest>
          </div>
          <div *ngSwitchCase="'TradeSettingsDataGrid'">
            <eclipse-trade-settings [displayPermission]="false" [(fromParent)]="TradeSettingsPrefResult" [(st)] ="secTypes"></eclipse-trade-settings>
          </div>
          <div *ngSwitchCase="'DefaultPrimaryTeamDropdownSearch'">
            <eclipse-default-primary-team [displayPermission]="false" [(fromParent)]="DefaultPrimaryTeamsPrefResult"></eclipse-default-primary-team>
          </div>
          <div *ngSwitchCase="'SystematicMultiplierLink'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <a class="field-control p-t-10" href="javascript:void(0);" (click)="displaySystematicMultiplier = true">Click here to view</a>
              <p-dialog header="Systematic Set Aside Cash Multiplier" [(visible)]="displaySystematicMultiplier" modal="true" [draggable]="false"
                        [resizable]="false" class="ui-dialog-xl"
                        [closable]="true" [closeOnEscape]="true">
                <div class="col-sm-12 breakWord p-content p-0 table-responsive">
                  <div class="col-sm-12 p-0">
                    <div class="form-group is-empty">
                      <eclipse-systematic-multiplier-grid [canEdit]="false" [preferenceData]="SystematicMultiplierPrefResult"></eclipse-systematic-multiplier-grid>
                    </div>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="displaySystematicMultiplier = false" label="Close"></button>
                </p-footer>
              </p-dialog>
            </div>
          </div>
          <div *ngSwitchCase="'BillingCashMultiplierLink'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <a class="field-control p-t-10" href="javascript:void(0);" (click)="displayBillingCashMultiplier = true">Click here to view</a>
              <p-dialog header="Billing Set Aside Cash Multiplier" [(visible)]="displayBillingCashMultiplier" modal="true" [draggable]="false"
                        [resizable]="false" class="ui-dialog-xl"
                        [closable]="true" [closeOnEscape]="true">
                <div class="col-sm-12 breakWord p-content p-0 table-responsive">
                  <div class="col-sm-12 p-0">
                    <div class="form-group is-empty">
                      <eclipse-billing-cash-multiplier-grid [canEdit]="false" [preferenceData]="BillingCashMultiplierPrefResult"></eclipse-billing-cash-multiplier-grid>
                    </div>
                  </div>
                </div>
                <p-footer>
                  <button pButton (click)="displayBillingCashMultiplier = false" label="Close"></button>
                </p-footer>
              </p-dialog>
            </div>
          </div>

          <div *ngSwitchCase="'MoneyMarketAllocationGrid'" class="form-group">
            <label class="control-label col-md-4 col-xs-8" [ngClass]="{'super-admin-preference': dynamicControlData.requireSuperAdmin}"> {{dynamicControlData.displayName}}
              <a href="javascript:void(0);" data-toggle="tooltip" [title]="dynamicControlData.helpText" class="btn-help"><i class="fas fa-question-circle" aria-hidden="true"></i></a>
            </label>
            <div class="col-md-1 col-xs-4"></div>
            <div class="col-md-7 col-xs-12">
              <eclipse-money-market-preference
                [canEdit]="false"
                [moneyMarketAllocationPreference]="MoneyMarketAllocationPreference">
              </eclipse-money-market-preference>
            </div>
          </div>

        </div>
      </div>
      <span class="cat-pref-error" [innerText]="errorMessage"></span>
    </div>
  </div>
</div>
