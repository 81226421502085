<div class="p-x-5">
  <p-tabView styleClass="tabs-only">
    <p-tabPanel *ngIf="!!params?.showQuickTrade">
      <ng-template pTemplate="header">
        <a class="tab-icon" (click)="currentTab = 'quickTrade'" pTooltip="Quick Trade"><i class="fas fa-bolt" aria-hidden="true"></i></a>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel *ngIf="!!params?.showFileImport && !!params.fileImport">
      <ng-template pTemplate="header">
        <a class="tab-icon" (click)="currentTab = 'fileImport'" pTooltip="File Import"><i class="fas fa-download" aria-hidden="true"></i></a>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
  <section class="p-y-10" [ngStyle]="{display: currentTab === 'quickTrade' ? 'block' : 'none'}">
    <eclipse-tradeorder-quicktrade #quicktrade></eclipse-tradeorder-quicktrade>
  </section>
  <section class="p-y-10" [ngStyle]="{display: currentTab === 'fileImport' ? 'block' : 'none'}">
    <eclipse-trade-file-import-panel #fileImport (uploadFileNotify)="params.fileImport.onFileUpload?.($event)" (onSubmitNotify)="params.fileImport.onSubmitNotify?.($event)"
                               [importType]="params.fileImport.importType" [isFixedIncome]="params.fileImport.isFixedIncome">
    </eclipse-trade-file-import-panel>
  </section>
</div>
