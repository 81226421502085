<div class="row">
  <div class="col-lg-4" *ngIf="!analyseSleeve"><strong>Portfolio:</strong>
    <span class="text-info fs-mask" [title]="portfolioName">{{portfolioName}} ({{portfolioId}})</span>
  </div>
  <div class="col-lg-4" *ngIf="analyseSleeve"><strong>Sleeve:</strong>
    <span class="text-info" [title]='sleeveName'>{{sleeveName}} ({{sleeveAccountId}})</span>
  </div>
  <div class="col-lg-4"><strong>Model:</strong> <span class="text-info" [title]="modelName">{{modelName}} ({{modelId}})</span></div>
  <div class="col-lg-3 text-right"><strong>AUM:</strong> <span class="text-info">{{!!totalAum ? formatCurrency(totalAum) : "$0.00"}}</span></div>
</div>
<div class="row">
  <!-- OE-1701 set aside cash -->
  <div class="col-lg-2"><strong>Set Aside Cash:</strong> <span class="text-info">{{!!setAsideCash ? formatCurrency(setAsideCash) : "$0.00"}}</span></div>
  <!-- OE-1701 reserve cash -->
  <div class="col-lg-2"><strong>Reserve Cash:</strong> <span class="text-info">{{!!reserveCash ? formatCurrency(reserveCash) : "$0.00"}}</span></div>

  <div class="col-lg-3"><strong>Set Aside Cash %:</strong> <span class="text-info">{{getPercentage(setasideCashPercent, false)}}</span></div>
  <div class="col-lg-3"><strong>Reserve Cash %:</strong> <span class="text-info">{{getPercentage(reserveCashPercent, false)}}</span></div>

</div>
<eclipse-analytics-duration *ngIf="portfolioAnalytics?.length"
                            [portfolioAnalytics]="portfolioAnalytics"
                            (updateAnalyticsData)="refreshData($event)"></eclipse-analytics-duration>
<div class="row">
  <p-tabView [activeTabName]="selectedTab" styleClass="tabs-only">
    <p-tabPanel tabName="Security">
      <ng-template pTemplate="header">
        <a id="securityTab" (click)="resizeGridOnTabChange('Security')">Security</a>
      </ng-template>
      <div class="sm_tabs" id="anapSecurity">
        <div class="agcontainer" *ngIf="!analyseSleeve">
          <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 270px;" [pinnedBottomRowData]="modelSecurityAllocationGridOptions.pinnedBottomRowData"
                           [gridOptions]="modelSecurityAllocationGridOptions" rowSelection="multiple"
                           [columnDefs]="modelSecurityAllocationColumnDefs" [rowData]="modelSecurityPortfolioAllocations"
                           [context]="gridContext" [suppressContextMenu]="true"
                           [getRowStyle]="getRowStyle" (gridReady)="onSecurityGridReady($event)">
          </ag-grid-angular>
        </div>
        <div class="agcontainer" *ngIf="analyseSleeve">
          <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 270px;" [pinnedBottomRowData]="modelSecuritySleeveAllocationGridOptions.pinnedBottomRowData"
                           [gridOptions]="modelSecuritySleeveAllocationGridOptions" rowSelection="multiple"
                           [columnDefs]="modelSecuritySleeveAllocationColumnDefs" [rowData]="sleeveAllocations"
                           [context]="gridContext" [suppressContextMenu]="true" [getRowStyle]="getRowStyle" (gridReady)="onSleeveGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="hasSecuritySetLevel" tabName="SecuritySet">
      <ng-template pTemplate="header">
        <a id="securitySetTab" (click)="resizeGridOnTabChange('SecuritySet')">Security Set</a>
      </ng-template>
      <div class="sm_tabs" id="anapSecuritySet">
        <div class="agcontainer">
          <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 270px;" [pinnedBottomRowData]="modelSecuritySetAllocationGridOptions.pinnedBottomRowData"
                           [gridOptions]="modelSecuritySetAllocationGridOptions" rowSelection="multiple"
                           [columnDefs]="modelSecuritySetAllocationColumnDefs" [rowData]="modelSecuritySetPortfolioAllocations"
                           [context]="gridContext" [suppressContextMenu]="true" [getRowStyle]="getRowStyle" (gridReady)="onSecuritySetGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="hasSubClassLevel" tabName="SubClass">
      <ng-template pTemplate="header">
        <a id="subclassTab" (click)="resizeGridOnTabChange('SubClass')">Sub-Class</a>
      </ng-template>
      <div class="sm_tabs" id="anapSubClass">
        <div class="agcontainer">
          <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 270px;" [pinnedBottomRowData]="modelSubClassAllocationGridOptions.pinnedBottomRowData"
                           [gridOptions]="modelSubClassAllocationGridOptions" rowSelection="multiple"
                           [columnDefs]="modelSubClassAllocationColumnDefs" [rowData]="modelSubClassPortfolioAllocations"
                           [context]="gridContext" [suppressContextMenu]="true" [getRowStyle]="getRowStyle" (gridReady)="onSubClassGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="hasClassLevel" tabName="Class">
      <ng-template pTemplate="header">
        <a id="class" (click)="resizeGridOnTabChange('Class')">Class</a>
      </ng-template>
      <div class="sm_tabs" id="anapClass">
        <div class="agcontainer">
          <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 270px;" [pinnedBottomRowData]="modelClassAllocationGridOptions.pinnedBottomRowData"
                           [gridOptions]="modelClassAllocationGridOptions" rowSelection="multiple"
                           [columnDefs]="modelClassAllocationColumnDefs" [rowData]="modelClassPortfolioAllocations"
                           [context]="gridContext" [suppressContextMenu]="true" [getRowStyle]="getRowStyle" (gridReady)="onClassGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="hasCategoryLevel" tabName="Category">
      <ng-template pTemplate="header">
        <a id="categoryTab" (click)="resizeGridOnTabChange('Category')">Category</a>
      </ng-template>
      <div class="sm_tabs" id="anapCategory">
        <div class="agcontainer">
          <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 270px;" [pinnedBottomRowData]="modelCategoryAllocationGridOptions.pinnedBottomRowData"
                           [gridOptions]="modelCategoryAllocationGridOptions" rowSelection="multiple"
                           [columnDefs]="modelCategoryAllocationColumnDefs" [rowData]="modelCategoryPortfolioAllocations"
                           [context]="gridContext" [suppressContextMenu]="true" [getRowStyle]="getRowStyle" (gridReady)="onCategoryGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

</div>
