export enum BillingCashAutoRebalanceFrequency {
  None = 1,
  Monthly = 2,
  Quarterly = 3,
  SemiAnnual = 4,
  Annual = 5
}

export interface IBillingCashMultiplierRebalance {
  autoRebalanceTypeId: BillingCashAutoRebalanceFrequency;
  minimum?: number;
  maximum?: number;
  target?: number;
  expire?: boolean;
  toleranceBand?: number;
}

export interface IBillingCashMultiplierPreference {
  levelName: string;
  recordId: number;
  id: number;
  preferenceId: number;
  name: string;
  displayName: string;
  categoryType: string;
  componentType: string;
  componentName: string;
  billingCashMultiplierPreferences: IBillingCashMultiplierRebalance[];
  inheritedBillingCashMultiplierPreferences: IBillingCashMultiplierRebalance[];
  isSetFromParent: boolean;
  resetToParent: boolean;
}

export interface IBillingCashMultiplierPreference {
  levelName: string;
  recordId: number;
  id: number;
  preferenceId: number;
  name: string;
  displayName: string;
  categoryType: string;
  componentType: string;
  componentName: string;
  billingCashMultiplierPreferences: IBillingCashMultiplierRebalance[];
  inheritedBillingCashMultiplierPreferences: IBillingCashMultiplierRebalance[];
  isSetFromParent: boolean;
  resetToParent: boolean;
}

export interface ICustomBillingCashMultiplierPreferenceSave {
  id: number;
  preferenceId: number;
  billingCashMultipliers: IBillingCashMultiplierPreference;
  resetToParent: boolean;
}
