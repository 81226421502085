import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import {RowSelectedEvent} from '@ag-grid-community/core';
import {BaseComponent} from '../../core/base.component';
import * as _ from 'lodash';
import {IRebalanceSecurity} from '../../models/rebalance';
import {SECURITY_SUB_MODEL_FIELD_NAME} from '../../libs/rebalancer.constants';
import {Utils as Util} from '../../core/functions';
import {IModelTypes} from '../../models/tradetools';

@Component({
  selector: 'eclipse-focus-rebalance-securities',
  templateUrl: './focus-rebalance-securities.component.html',
  styleUrls: ['./focus-rebalance-securities.component.scss']
})
export class FocusRebalanceSecuritiesComponent extends BaseComponent {
  securityList: IRebalanceSecurity[];
  securities: IRebalanceSecurity[];
  @Input() isModelScreen: boolean;
  @Output() updateSelectedSecurities = new EventEmitter();
  securityListGridOptions: GridOptions;
  securityListColumnDefs: ColDef[];
  isBalanceIndividualSecurities: boolean;
  isIncludeSubstituteNodes: boolean;
  selectedSubModelType: string;
  timeoutToken: NodeJS.Timeout;
  gridApi: GridApi;

  constructor() {
    super();
    this.securityListGridOptions = this.defaultFilterableGridOptions;
  }

  ngOnInit(): void {
    this.createColDefs();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  createColDefs() {
    this.securityListColumnDefs = [
      <ColDef>{
        headerName: 'Id',
        width: 80,
        headerTooltip: 'security id',
        field: 'securityId',
        filter: 'agNumberColumnFilter',
        headerCheckboxSelection: this.isBalanceIndividualSecurities,
        checkboxSelection: this.isBalanceIndividualSecurities,
        headerCheckboxSelectionFilteredOnly: true
      },
      <ColDef>{
        headerName: 'Symbol',
        width: 100,
        headerTooltip: 'security symbol',
        field: 'securitySymbol',
        filter: 'agTextColumnFilter',
        sort: 'asc'
      },
      <ColDef>{
        headerName: 'Name',
        width: 150,
        headerTooltip: 'security name',
        field: 'securityName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Type',
        width: 100,
        headerTooltip: 'securitySet type',
        field: 'securityType',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Target Percent',
        width: 110,
        headerTooltip: 'Target Percent',
        field: 'targetPercent',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        headerName: 'Asset Category Name',
        width: 150,
        hide: true,
        headerTooltip: 'category type',
        field: 'assetCategoryName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Asset Class Name',
        width: 150,
        hide: true,
        headerTooltip: 'class type',
        field: 'assetClassName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Asset Sub-Class Name',
        width: 150,
        hide: true,
        headerTooltip: 'sub-class type',
        field: 'assetSubClassName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Model Name',
        width: 150,
        headerTooltip: 'model name',
        field: 'modelName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Category Name',
        width: 150,
        headerTooltip: 'model category',
        field: 'categoryName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Class Name',
        width: 150,
        headerTooltip: 'model class',
        field: 'className',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Sub-Class Name',
        width: 150,
        headerTooltip: 'model sub-class',
        field: 'subClassName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Security Set Name',
        width: 150,
        headerTooltip: 'model security set',
        field: 'securitySetName',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Substitute',
        width: 100,
        headerTooltip: 'Substitute',
        field: 'isSubstituted',
        valueGetter: params => Util.getYesNoForBoolean(params.data.isSubstituted),
        filter: 'agTextColumnFilter'
      }
    ];
  }

  onBalanceIndividualCheckBoxChange(): void {
    this.createColDefs();
    this.selectAndUnselectSecuritiesRows();
  }

  onIncludeSubstituteNodesCheckBoxChange(): void {
    if (this.isIncludeSubstituteNodes) {
      this.securityList = this.securities;
    } else {
      this.securityList = this.securities.filter(s => !s.isSubstituted);
    }
    this.selectAndUnselectSecuritiesRows();
  }

  updateSecurityList(securities: IRebalanceSecurity[], selectedNodeIds: number[], selectedSubModelType: string): void {
    this.securities = securities;
    this.selectedSubModelType = selectedSubModelType;
    this.securityList = this.securities.filter(s => !s.isSubstituted);
  }

  selectAndUnselectSecuritiesRows(): void {
    setTimeout(() => {
      if (!this.isBalanceIndividualSecurities) {
        this.gridApi.selectAll();
        const selectedSubModelDetails = this.getSubModelDetails();
        this.updateSelectedSecurities.emit({
          selectedSecurities: [],
          isButtonDisabled: this.securityList.length === 0,
          selectedSubModelDetails: selectedSubModelDetails
        });
      } else {
        this.gridApi.deselectAll();
      }
      this.gridApi.refreshCells();
      if (!this.isModelScreen) {
        this.gridApi.sizeColumnsToFit();
      }
    });
  }

  onRowSelected(event: RowSelectedEvent): void {
      if (this.timeoutToken) {
        clearTimeout(this.timeoutToken);
      }

      this.timeoutToken = setTimeout(function () {
        this.setSecuritySubModelDetails(event);
      }.bind(this), 250);
  }

  setSecuritySubModelDetails(event: RowSelectedEvent) : void{
    if (this.isBalanceIndividualSecurities) {
      this.gridApi.forEachNode(node => {
        if (node.data.securityId === event.data.securityId && node.data.modelId === event.data.modelId) {
          node.setSelected(event.node.isSelected());
        }
      });

      this.gridApi.refreshCells();
      const selectedRows = this.gridApi.getSelectedRows();
      let securitiesInfo = [];
      selectedRows.forEach(item => {
        const modelIds = item.modelId.toString().split(',');
        modelIds.forEach(modelId => {
          securitiesInfo.push({
            symbol: item.securitySymbol,
            modelId: Number(modelId)
          });
        });
      });
      securitiesInfo = _.uniqBy(securitiesInfo, item => [item.symbol, item.modelId].join());
      const selectedSubModelDetails = this.getSubModelDetails();
      this.updateSelectedSecurities.emit({
        selectedSecurities: securitiesInfo,
        isButtonDisabled: securitiesInfo.length === 0,
        selectedSubModelDetails: selectedSubModelDetails
      })
    }
  }

  getSubModelDetails(): any {
    const SUB_MODEL_NAME = this.selectedSubModelType.toUpperCase();
    const MODEL_ELEMENT_FIELD_NAME = SECURITY_SUB_MODEL_FIELD_NAME.MODEL_ELEMENT_ID[SUB_MODEL_NAME];
    const MODEL_DETAIL_FIELD_NAME = SECURITY_SUB_MODEL_FIELD_NAME.MODEL_DETAIL_ID[SUB_MODEL_NAME];
    const selectedSecurities = this.gridApi.getSelectedRows();
    const modelElementIds = selectedSecurities.map(s => s[MODEL_ELEMENT_FIELD_NAME]).filter(s => s);
    const modelDetailIds = _.flattenDeep(selectedSecurities.map(s => s[MODEL_DETAIL_FIELD_NAME]?.split(','))).filter(s => s);
    return {
      modelElementIds: _.uniq(modelElementIds),
      modelDetailIds: _.uniq(modelDetailIds.map(s => Number(s)))
    };
  }

  clearData(): void {
    this.securities = [];
    this.securityList = [];
    this.isBalanceIndividualSecurities = false;
    this.isIncludeSubstituteNodes = false;
  }

  updateFocusedRebalanceSecurities(securities: IRebalanceSecurity[], filter: any, selectedSubModels: IModelTypes[]): void {
    this.updateSecurityList(securities, filter.subModelIds, filter.subModelType);
    this.selectAndUnselectSecuritiesRows();
    if (selectedSubModels.some(s => s.hasSubstitute)) {
      this.isIncludeSubstituteNodes = true;
      this.onIncludeSubstituteNodesCheckBoxChange();
    }
  }
}
