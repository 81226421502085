import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private subject = new BehaviorSubject<boolean>(false);
  visible$: Observable<boolean> = this.subject.asObservable();

  changeVisible(visible: boolean) {
    this.subject.next(visible);
  }
}
