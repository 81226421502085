<div class="row">
  <div class="col-xs-3">
    <div class="form-group is-empty">
      <input type="text" class="placeholder-input" [(ngModel)]="searchText"
             placeholder="Filter Preferences" pInputText/>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <div class="panel panel-default">
      <div *ngFor="let category of categories;let i = index" [hidden]="!matchCategoryName(category)">
        <div class="panel-heading" role="tab" id="heading{{category.name}}">
          <h4 class="panel-title">
            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#{{category.id}}"
               aria-expanded="false" aria-controls="Rebalance">
              {{category.name}}
            </a>
          </h4>
        </div>
        <div id="{{category.id}}" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="Rebalance">
          <div class="category-fieldset category-form">
            <form [formGroup]="form" class="form-horizontal" novalidate>
              <div class="preferences-category"
                   *ngFor="let preference of preferences | itemfilter : 'categoryType' : category.name : subCategories"
                   [hidden]="!matchSubCategoryName(preference)">
                <h4 *ngIf="preference.key !=='null'" [innerText]="preference.key"></h4>
                <div class="row" *ngFor="let pref of preference.value">
                  <div class="col-lg-12" [ngSwitch]=" perfpermissionMode ">
                    <div *ngSwitchCase="'ADDUPDATE'" class="form-group" [hidden]="!matchPreferenceName(pref) || pref.isHidden">
                      <eclipse-dynamic-control [dynamicControlData]="pref"
                                               [toleranceSettingsSell]="toleranceSettingsSell"
                                               [toleranceSettingsBuy]="toleranceSettingsBuy"
                                               [(communityStrategies)]="preferenceCommunities" [form]="form"
                                               [LocationOptimizations]="locationOptimizations"
                                               [(RedemptionFeePrefResult)]="RedemptionFeePrefResultWrapper"
                                               [(CustodianRedemptionFeePrefResult)]="CustodianRedemptionFeePrefResultWrapper"
                                               [(TransactionFeePrefResult)]="TransactionFeePrefResultWrapper"
                                               [(TradeMinPrefResult)]="TradeMinPrefResultWrapper"
                                               [(TradeMaxPrefResult)]="TradeMaxPrefResultWrapper"
                                               [(DividendReinvestPrefResult)]="DividendReinvestPrefResultWrapper"
                                               [(CapitalGainReinvestPrefResult)]="CapitalGainReinvestPrefResultWrapper"
                                               [(TradeSettingsPrefResult)]="TradeSettingsPrefResultWrapper"
                                               [(DefaultPrimaryTeamsPrefResult)]="DefaultPrimaryTeamsPrefResultWrapper"
                                               [SystematicMultiplierPrefResult]="SystematicMultiplierPrefResultWrapper"
                                               [BillingCashMultiplierPrefResult]="BillingCashMultiplierPrefResultWrapper"
                                               [levelName]="levelName" [preferenceResults]="preferences"
                                               [levelId]="levelId" [selectedLeveIds]="selectedIds"
                                               [secTypes]="SecurityTypes"
                                               [MoneyMarketAllocationPreference]="MoneyMarketAllocationPreferenceWrapper"
                                               [MoneyMarketFundPreference]="MoneyMarketFundPreferenceWrapper"
                                               (updatePreference)="updatePreferences($event)">
                      </eclipse-dynamic-control>

                    </div>
                    <div *ngSwitchCase="'VIEW'" class="form-group" [hidden]="!matchPreferenceName(pref)">
                      <eclipse-dynamic-control-ready-only [dynamicControlData]="pref"
                                                          [(communityStrategies)]="preferenceCommunities"
                                                          [form]="form"
                                                          [LocationOptimizations]="locationOptimizations"
                                                          [(RedemptionFeePrefResult)]="RedemptionFeePrefResultWrapper"
                                                          [(CustodianRedemptionFeePrefResult)]="CustodianRedemptionFeePrefResultWrapper"
                                                          [(TransactionFeePrefResult)]="TransactionFeePrefResultWrapper "
                                                          [(TradeMinPrefResult)]="TradeMinPrefResultWrapper"
                                                          [(TradeMaxPrefResult)]="TradeMaxPrefResultWrapper"
                                                          [(DividendReinvestPrefResult)]="DividendReinvestPrefResultWrapper"
                                                          [(CapitalGainReinvestPrefResult)]="CapitalGainReinvestPrefResultWrapper"
                                                          [(TradeSettingsPrefResult)]="TradeSettingsPrefResultWrapper"
                                                          [(DefaultPrimaryTeamsPrefResult)]="DefaultPrimaryTeamsPrefResultWrapper"
                                                          [SystematicMultiplierPrefResult]="SystematicMultiplierPrefResultWrapper"
                                                          [BillingCashMultiplierPrefResult]="BillingCashMultiplierPrefResultWrapper"
                                                          [MoneyMarketAllocationPreference]="MoneyMarketAllocationPreferenceWrapper"
                                                          [MoneyMarketFundPreference]="MoneyMarketFundPreferenceWrapper"
                                                          [levelName]="levelName" [levelId]="levelId">
                      </eclipse-dynamic-control-ready-only>

                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
