<!--Notifications-->
<div>
  <div class="notification-right-tab-box" *ngIf="(!showPreferencesDiv)">
    <div class="noti-box-header">
      <h1 class="pull-left">Notifications</h1>
      <div class="notibar-menu">
        <ul>
          <li class="form-inline" *ngIf="!showTodo">
            <span>Group by :</span>
            <select *ngIf="!showTodo" class="form-control radius-none" [(ngModel)]="selectedDisplayCategory" (change)="groupNotifications($event.target.value)">
              <option value="Date">Date</option>
              <option value="Category">Category</option>
            </select>
          </li>
          <li><a href="javascript:void(0);" class="user-info" (click)="showPreferences(true)" title="User Preferences"><i class="fas fa-cog" aria-hidden="true"></i> </a></li>
        </ul>
      </div>
    </div>

    <div class="noti-box-content" *ngIf="showNotificationDiv">
      <div class="form-group is-empty">
        <div class="icon-addon addon-lg">
          <input type="text" id="searchNotifications" placeholder="Search" [(ngModel)]="filterNotificationsText" class="form-control custom-search-bar"
                 (ngModelChange)="filterNotifications($event)">
          <label for="searchNotifications" class="fas fa-search custom-icon-addon" title="Search" aria-label="Search"></label>
          <span for="searchNotifications" class="fas fa-times custom-icon-close" title="Clear search" aria-label="Clear Search"
                 (click)="clearSearch()"></span>
        </div>
        <span class="material-input"></span></div>

      <div class="custom-tab-container fullList-scroll">
        <p-tabView styleClass="tabs-only">
          <p-tabPanel [selected]="selectedTab === 'All'">
            <ng-template pTemplate="header">
              <a (click)="getFilterList('All')">
                <i class="far fa-fw fa-file-alt" aria-hidden="true"></i> All
              </a>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [selected]="selectedTab === 'Unread'">
            <ng-template pTemplate="header">
              <a (click)="getFilterList('Unread')">
                <i class="far fa-fw fa-newspaper" aria-hidden="true"></i> Unread
              </a>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [selected]="selectedTab === 'Read'">
            <ng-template pTemplate="header">
              <a (click)="getFilterList('Read')">
                <i class="far fa-newspaper" aria-hidden="true"></i> Read
              </a>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [selected]="selectedTab === 'ToDo'">
            <ng-template pTemplate="header">
              <a (click)="getActivities(4)"><i class="far fa-calendar-check" aria-hidden="true"></i>
                To Do </a>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
        <div class="tab-content">
          <!--Start Notification and categories-->
          <div class="tab-pane fade in active">
            <div class="notifications-wrapper flex-column" *ngIf="!showTodo">
              <div class="notification-list-actions">
                <button pButton class="p-button-secondary p-button-sm" (click)="getFilterList('DeleteAll')" icon="fas fa-trash text-danger" label="Delete All"></button>
                <button pButton class="p-button-secondary p-button-sm" (click)="markAsRead()" icon="fas fa-envelope-circle-check icon-primary" label="Mark All as Read"></button>
              </div>
              <section class="m-t-10" *ngIf="!loadingNotifications; else loading">
                <p-accordion [(activeIndex)]="activeNotificationGroupIndex" id="notifications-accordion">
                  <p-accordionTab *ngFor="let group of displayGroups; trackBy: trackByNotificationGroup" [header]="group" id="notification-accordion-tab-{{group}}">
                    <p-virtualScroller [value]="finalList | notificationGroupFilter:group" scrollHeight="400px" [itemSize]="66">
                      <ng-template pTemplate="item" let-notificationItem>
                        <notification-item [notice]="notificationItem" (viewNotification)="viewNotificationResult($event)" (setReadStatus)="updateNotificationStatus($event)"></notification-item>
                      </ng-template>
                    </p-virtualScroller>
                  </p-accordionTab>
                </p-accordion>
              </section>
              <span *ngIf="!loadingNotifications && !displayGroups?.length"
                    class="flex-column" style="align-items: center; justify-content: center;">
                No notifications
              </span>

              <ng-template #loading>
                <div class="flex-column" style="align-items: center; justify-content: center;">
                  <p-progressSpinner></p-progressSpinner>
                  <span class="opl-progress-spinner-text-sm">Loading notifications...</span>
                </div>
              </ng-template>
            </div>
            <div class="notification-footer" *ngIf="showAllNotificaitons && showTodo==false">
              <a href="javascript:void(0)" class="menu-title" (click)="viewAllNotifications()">More<i class="fas fa-arrow-alt-circle-right" aria-hidden="true"></i></a>
            </div>
            <!--End  Notification and categories-->

            <!--ToDo Tab-->
            <div *ngIf="showTodo">
              <div class="notifications-wrapper">
                <div class="notification-list-actions">
                  <button pButton class="p-button-secondary p-button-sm" (click)="onShowNewTask()" icon="fas fa-plus-square text-success" label="Add New Task"></button>
                  <button pButton class="p-button-secondary p-button-sm" (click)="completeTasks()" icon="fas fa-check-square text-success" label="Completed Tasks"></button>
                </div>
                <div *ngFor="let group of displayActivityGroups">
                  <div class="chat-box-single-line">
                    <abbr class="items-date-stamp">{{group}}</abbr>
                  </div>
                  <div *ngFor="let activity of finalActivitiesList ; let i=index">
                    <div *ngIf="activity.displayGroup==group &&  (i<topActivities || showAllActivities)">
                      <a href="javascript:void(0)" class="notification-item-list">
                        <div class="notification-item" (click)="getActivityDetails(activity.id)">
                          <h4 class="item-title">
                            <i class="far fa-calendar-check text-info m-r-10" aria-hidden="true"></i>{{activity.name}}
                          </h4>
                          <span class="item-time-stamp">{{activity.createdOn | dateFormat: 'hh:mm A'}}</span>
                        </div>
                        <div class="item-slide-menu">
                          <button class="btn-read" title="Assign User"><i class="fas fa-user-plus text-warning" (click)="showUsers(activity.id)" alt="Assign User" aria-hidden="true"></i></button>
                          <button class="btn-trash" title="Mark as Complete"><i class="far fa-check-square text-white" (click)="markComplete(activity.id)" aria-hidden="true"></i></button>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="notification-footer" *ngIf="!showAllActivities">
                <a href="javascript:void(0)" class="menu-title" (click)="viewAllActivities()">More<i class="fas fa-arrow-alt-circle-right" aria-hidden="true"></i></a>
              </div>
            </div>
            <!--END ToDo Tab-->
          </div>
        </div>
      </div>
    </div>

    <!--Notification Story-->
    <div *ngIf="showNotificationStoryDiv">
      <div class="noti-box-content">
        <div class="custom-tab-container fullList-scroll">
          <div class="tab-content">
            <div class="notification-item-full" *ngFor="let notification of notificationInformation">
              <p [ngSwitch]="notification.notificationCategory.name.toLowerCase()">
                  <i *ngSwitchCase="'trading'" class="fas fa-heart-rate green" aria-hidden="true"></i>
                  <i *ngSwitchCase="'approval'" class="fas fa-file-check red" aria-hidden="true"></i>
                  <i *ngSwitchCase="'system status'" class="fas fa-server blue" aria-hidden="true"></i>
                  <i *ngSwitchCase="'analytics status'" class="fal fa-analytics blue" aria-hidden="true"></i>
                <span class="notification-cat">{{notification.notificationCategory.name}}</span>
              </p>
              <div class="item-time-stamp"><span>{{(notification.createdOn | dateFormat: 'MM/DD/YYYY hh:mm A')}}</span>
                <span
                  [ngClass]="{'Error':'fas fa-exclamation-circle text-danger','Information':'fas fa-info-circle text-info', 'Warning':'fas fa-exclamation text-warning', 'TodoInformation':'far fa-calendar-check text-primary'}[notification.severityType]"></span>
              </div>
              <p>{{notification.subject}}</p>
              <p>{{notification.body}}</p>
              <a *ngIf="notification.notificationCategory.name=='Trading' || notification.notificationCategory.name=='Approval' || notification.notificationCategory.name=='System Status'"
                 href="javascript:void(0)" class="menu-title" (click)="routeLink()">{{clickHere}} <i class="fas fa-arrow-alt-circle-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
        <div class="notification-footer text-right clearfix">
          <a class="menu-title pull-right" href="javascript:void(0)" (click)="showNotifications()"><i class="fas fa-chevron-circle-left" aria-hidden="true"></i> Back</a>
        </div>
      </div>
    </div>
    <!--End Notification Story-->

    <!--Activity Story-->
    <div *ngIf="showActivityStoryDiv">
      <div class="noti-box-content">
        <div class="custom-tab-container fullList-scroll">
          <div class="tab-content">
            <div class="notification-item-full notification-item-list" *ngFor="let activity of activityInformation">
              <span class="notification-cat">
                <i class="far fa-calendar-check text-info" aria-hidden="true"></i>
          <span>{{activity.name}}</span>
              <p>{{activity.description}}</p>
              </span>
              <div class="item-slide-menu">
                <button class="btn-read" title="Assign User"><i class="fas fa-user-plus text-warning" (click)="showUsers(activity.id)" alt="Assign User" aria-hidden="true"></i></button>
                <button class="btn-trash" title="Mark as Complete"><i class="far fa-check-square text-white" (click)="markComplete(activity.id)" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="notification-footer text-right clearfix">
        <a class="menu-title pull-right" href="javascript:void(0)" (click)="showNotifications()"><i class="fas fa-chevron-circle-left" aria-hidden="true"></i> Back</a>
      </div>
    </div>
  </div>
  <!--End Activity Story-->

  <!--Notification Preferences-->
  <div class="notification-right-tab-box" *ngIf="(showPreferencesDiv)">
    <div class="noti-box-header">
      <h1 class="pull-left"><i class="fas fa-cog" aria-hidden="true"></i> Notification Preferences</h1>
    </div>
    <div class="noti-box-content">
      <div class="custom-tab-container fullList-scroll">
        <div class="tab-content">

          <div class="notification-preferences">
            <div class="row">
              <p class="col-md-6">Personal email:</p>
            </div>
            <div class="row">
              <p class="col-md-10">{{userEmail}}
                <span class="float-md-right">
                  <a href="javascript:void(0)" (click)="updateUserEmail()"><small>Change</small></a>
                </span>
              </p>
            </div>
            <div class="row margin-top-10">
              <div class="col-md-5 col-sm-6 text-center pull-right">
                <p class="col-md-6 col-sm-6">Enabled</p>
                <p class="col-md-6 col-sm-6">Email</p>
              </div>
            </div>
            <div class="noti-per-row row" *ngFor="let category of notificationCategoryList">
              <div class="col-md-7">
                <h4 class="item-title" [ngSwitch]="category.notificationCategoryName.toLowerCase()">
                    <i *ngSwitchCase="'trading'" class="fas fa-heart-rate green" aria-hidden="true"></i>
                    <i *ngSwitchCase="'approval'" class="fas fa-file-check red" aria-hidden="true"></i>
                    <i *ngSwitchCase="'system status'" class="fas fa-server blue" aria-hidden="true"></i>
                    <i *ngSwitchCase="'analytics status'" class="fal fa-analytics blue" aria-hidden="true"></i>
                    {{category.notificationCategoryName}} </h4>
                </div>
                <div *ngFor="let topic of notificationTopics">
                  <div *ngIf="topic.notificationCategoryId==category.notificationCategoryId" class="clearfix">
                    <div class="col-md-7 col-sm-6">
                      <p class="noti-per-content">{{topic.subject}}
                        <a href="javascript:void(0);" title={{topic.description}}><i class="far fa-question-circle text-info" aria-hidden="true"></i></a>

                    </p>

                  </div>
                  <div class="col-md-5 col-sm-6 text-center">
                    <div class="col-md-6 col-sm-6">
                      <p *ngIf="topic.code !== 'TradeOrderEndDaySummary'" ><input type="checkbox" title="Enable Topic"
                        id="{{'chkEnableTopic_' + topic.id}}" (change)="updateEnable(topic)" [(ngModel)]="topic.enabled"/></p>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <p *ngIf="topic.code !== 'TRADEGEN'"><input type="checkbox" title="Send Email" id="{{'chkSendEmail_' + topic.id}}"
                        [(ngModel)]="topic.sendEmail" [disabled]="topic.code !== 'TradeOrderEndDaySummary' && !topic.enabled"/></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p-footer>
        <div class="pull-right p-a-10">
          <button type="button" pButton icon="fas fa-check" (click)="onSave()" label="Save"></button>
          <button type="button" pButton class="p-button-secondary" (click)="showPreferences(false)" label="Close"></button>
        </div>
      </p-footer>
    </div>
  </div>
  <!--End Notification Preferences-->
</div>

<!--Create Activity Popup-->
<p-dialog header="Add New Activity" [(visible)]="displayAddActivity" modal="true" [draggable]=false
          [resizable]=false  [style]="{'width':'550px'}">
  <div class="form-horizontal col-md-10">
    <div class="form-group">
      <label class="control-label col-sm-3 label-fontsize">Name</label>
      <div class="col-sm-9">
        <input type="text" [(ngModel)]="activity.name" maxlength="50" class="form-control"/>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-sm-3 label-fontsize">Description</label>
      <div class="col-sm-9">
        <input type="text" maxlength="50" [(ngModel)]="activity.description" placeholder="up to 50 characters only" class="form-control"
        />
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="closePopup()" label="Cancel"></button>
    <button type="button" pButton icon="fas fa-check" (click)="createActivity()" label="Save"></button>
  </p-footer>
</p-dialog>
<!--End Create Activity Popup-->

<!--Assign User Popup-->
<p-dialog header="Assign User" [(visible)]="displayUsers" modal="true" [draggable]=false
          [resizable]=false  [style]="{'width':'1000px','height':'475px'}">
  <div>
    <div class="col-md-6">
      All Users
      <div>
        <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 280px;"
           [rowData]="usersData" [gridOptions]="gridOptions" [columnDefs]="columnDefs"
           rowSelection="multiple" (gridReady)="onUsersGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="col-md-1 arrow-box">
      <button type="button" pButton icon="fas fa-fw fa-angle-right" (click)="assignUser()"></button>
      <br>
      <button type="button" pButton icon="fas fa-fw fa-angle-left" (click)="removeUser()"></button>
      <br>
      <button type="button" pButton icon="fas fa-fw fa-angles-right" (click)="assignAllUsers()"></button>
      <br>
      <button type="button" pButton icon="fas fa-fw fa-angles-left" (click)="removeAllUsers()"></button>
    </div>
    <div class="col-md-5">
      Assigned Users
      <div>
        <ag-grid-angular #agGrid class="ag-theme-alpine" style="width: 100%; height: 280px;" [columnDefs]="columnDefs" [rowData]="selectedUsers"
                         rowSelection="multiple" scrollbar [gridOptions]="selectedUsersGridOptions" (gridReady)="onSelectedUsersGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="closeUsersPopup()" label="CANCEL"></button>
    <button type="button" pButton icon="fas fa-check" (click)="AssignUsersToActivity()" label="SAVE"></button>
    <em class="securityError danger" *ngIf="assignUserErrorMsg">{{assignUserErrorMsg}}</em>
  </p-footer>
</p-dialog>
<!--End Assign User Popup-->

<!--Completed Tasks-->
<p-dialog header="Completed Activities" [(visible)]="displayCompletedActivity" modal="true"
          [draggable]="false" [resizable]="false" [style]="{'width': '650px'}">
  <div>
    <ag-grid-angular (modelUpdated)="onModelUpdated()" #agGrid1 style="width: 100%; height: 350px;" class="ag-theme-alpine"
                     [gridOptions]="doneTaskGridOptions" [columnDefs]="doneTaskColumnDefs"
                     [rowData]="completedActivityList"  suppressContextMenu="false" (gridReady)="onDoneTasksGridReady($event)">
    </ag-grid-angular>
  </div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" (click)="displayCompletedActivity=false" label="CANCEL"></button>
  </p-footer>
</p-dialog>
<!--End Completed Tasks-->
