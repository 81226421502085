<div class="row" style="height:90%; overflow-y: auto;">
  <div *ngIf="!(isFirmAdmin || isOrionAdmin)" class="pull-right ">
    <span *ngIf="displayMessage">User has overridden the firm dashboard defaults </span>
    <a href="javascript:void(0)" class="btn-reset fl-none" data-toggle="tooltip" title="Reset settings to firm default">
      <i class="fas fa-undo" (click)="resetDashboardFilters()" aria-hidden="true"></i>
    </a>
  </div>
  <div class="col-sm-12">
    <div class="dashboard-setting">
      <ng-template #loading>
        <h4>Loading filters…</h4>
      </ng-template>
      <ng-template *ngIf="filters && filters.length > 0; else loading" ngFor [ngForOf]="filters" let-filter="$implicit" let-index="index">
        <label class="title">{{filter.name}}</label>
        <label class="sub-title">Select what to include in the actions needed count.</label>
        <ng-template *ngIf="filter.dashboardSubLevels && filter.dashboardSubLevels.length > 0" ngFor
                     [ngForOf]="filter.dashboardSubLevels" let-subLevel="$implicit">
          <div class="form-group is-empty">
            <label class="primar-label" for="optionFilter-{{subLevel.id}}">{{subLevel.name}}</label>
            <select id="optionFilter-{{subLevel.id}}" class="form-control" [(ngModel)]="subLevel.selectedOptionId"
                    (change)="onOptionChange(filter)">
              <option *ngFor="let option of subLevel.options" value="{{option.id}}">{{option.name}}</option>
            </select>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
<p-footer class="text-right" style="position: absolute; right: 0; bottom: 0;">
  <div class="col-sm-12">
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal"
            (click)="closeDashboardSettingPopUp()" label="Cancel">
    </button>
    <button [disabled]="filters && filters.length == 0" type="button" pButton
            data-dismiss="modal" (click)="saveDashboardSetting()" label="Save">
    </button>
  </div>
</p-footer>
