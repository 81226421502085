import { Component, EventEmitter, Output, Pipe, PipeTransform } from '@angular/core';
import {Router} from '@angular/router';
import {SessionHelper, AlertService} from '../../core';
import {NotificationService} from '../../services/notification.service';
import {
  ActivityUser,
  IActivities,
  INotificationModel,
  INotificationsList,
  INotificationTopics,
  INotificationVM,
  ISelectedNotifications,
  ISubscribeNotifications,
  ISubscribeNotificationTopics,
  NotificationCountAPIMap
} from '../../models/notification';
import {BaseComponent} from '../../core/base.component';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from '@ag-grid-community/core';
import {IUser} from '../../models/user';
import {IRole} from '../../models/role';
import {UserService} from '../../services/user.service';
import {ConfigService} from '../../config/config.service';
import * as Consts from '../../libs/app.constants';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {finalize, tap} from 'rxjs/operators';

@Pipe({name: 'notificationGroupFilter'})
export class NotificationGroupFilterPipe implements PipeTransform {
  transform(items: any[], group: string): any[] {
    if(!items){
      return [];
    }
    return items.filter(item => item.displayGroup === group);
  }
}

@Component({
  selector: 'eclipse-notification-layout',
  templateUrl: './notification.component.html'
})
export class NotificationComponent extends BaseComponent {
  private noti: any;
  private notificationSubscription: Subscription;
  private totalNotificationsCountSubscription: Subscription;
  private notificationsSubscription: Subscription;
  private notificationsCounterSubscription: Subscription;
  socket: any;
  // Notifications Options
  messages: any = [];
  ids: any = [];
  connection;
  message: string;
  results: any;
  msgCount = 0;
  showMe: boolean = true;
  showPreferencesDiv: boolean = false;
  showNotificationStoryDiv: boolean = false;
  displayAddActivity: boolean = false;
  displayCompletedActivity: boolean = false;
  displayUsers: boolean = false;
  PreferencesDiv: boolean = false;
  showNotificationDiv: boolean = true;
  notificationTopics: ISubscribeNotificationTopics[] = [];
  notificationTopic: INotificationTopics[] = [];
  notificationCategoryList: INotificationTopics[] = [];
  subscribeNotifications = <ISubscribeNotifications>{};
  notificationCountAPIMap: NotificationCountAPIMap[] = [];
  @Output() msgCounter = new EventEmitter();
  @Output() analyticNeedCounter = new EventEmitter();
  @Output() onNotificationComplete = new EventEmitter();
  selectedDisplayCategory: string = 'Date';
  displayGroups: string[] = [];
  activeNotificationGroupIndex: number = 0;
  loadingNotifications: boolean = false;
  displayActivityGroups: string[] = [];
  notificationsList: INotificationsList[] = [];   // This variable will be used to hold the api returned result
  finalList: INotificationsList[] = [];           // this variable will be used to hold UI display records with filters and conditions
  notificationInformation: INotificationsList[];  // THis variable is used to display the Notification information when click on Notification
  activityInformation: IActivities[];             // THis variable is used to display the activity information when click on activity
  selectedNotificationFilter: string = 'Unread';     // this variable will be used to hold selected filter
  activityList: IActivities[] = [];                // This variable will be used to hold the api returned result of activities
  activity: IActivities = <IActivities>{};
  completedActivityList: IActivities[] = [];       // This variable will be used to hold the api returned result of completed activities
  copyOfCompletedActivityList: IActivities[] = []; // This variable will be used to hold the api returned result of completed activities Copy
  finalActivitiesList: IActivities[] = [];
  userEmail: string;
  selectedTab: string = 'Unread';
  topFour: number = 4;                    // This variable is to display top 4 Notifications
  showAllNotificaitons: boolean = false;  // This variable is used to display more for Notifications
  topActivities = 4;                   // This variable is to display top 5 Activities
  showAllActivities: boolean = false;     // This variable is used to display more for Activities
  columnDefs: ColDef[];
  errorMessage: string;
  private gridApiUsers: GridApi;
  private gridApiSelectedUsers: GridApi;
  private gridApiDoneTasks: GridApi;
  gridOptions: GridOptions = {
    ...this.defaultGridOptions,
    sideBar: null,
  };
  selectedUsersGridOptions: GridOptions = {
    ...this.defaultGridOptions,
    sideBar: null,
  };
  masterUsersData: any[] = [];
  usersData: any[] = [];
  selectedUsers: any[] = [];
  assignUserErrorMsg: string = '';
  notification = <INotificationModel>{};
  selectedActivityId: number;
  iUser: IUser;
  isNormalUser: boolean = false;
  showTodo: boolean = false;
  filterNotificationsText: any;
  notificationVM = <INotificationVM>{};
  severity: any[] = [];
  showActivityStoryDiv: boolean = false;
  navigateURL: string;
  queryParams: any;
  clickHere: string;
  totalCount: any;
  readCount: any;
  moreEnabled: boolean = false;

  /******* PROGRESS BAR VARIABLES **/
  @Output() ShowProfilePopup = new EventEmitter();
  doneTaskColumnDefs: ColDef[];
  doneTaskGridOptions: GridOptions = {
    ...this.defaultGridOptions,
    sideBar: null,
  };

  constructor(private _router: Router, private _toastr: ToastrService, private notificationService: NotificationService, private _userService: UserService, private _configService: ConfigService, private _alertService: AlertService) {
    super();

    this.getSubscribeNotificationTopic();
    this.createColumnDefs();
    this.createTaskColumnDefs();
  }

  ngOnInit() {
    this.notificationsCounter(); // get unread counter to show in bell icon using emit
    this.totalNotificationsCount(); // get total notification count to show More link
    this.loadNotifications(); // notification Subscribtion
    this.loadNotificationList(this.topFour); // loading top 4 notifications
    const role = this._sessionHelper.getUser().role;
    this.getSeverityIcons();
    if (role.roleTypeId === Consts.UserType.User) {
      this.isNormalUser = true;
    }
  }

  onUsersGridReady(event: GridReadyEvent) {
    this.gridApiUsers = event.api;
  }

  onSelectedUsersGridReady(event: GridReadyEvent) {
    this.gridApiSelectedUsers = event.api;
  }

  onDoneTasksGridReady(event: GridReadyEvent) {
    this.gridApiDoneTasks = event.api;
  }

  // This method is used to load Notifications
  loadNotifications() {
    this.connection = this.notificationService.getNotifications().subscribe(notification => {
      this.notificationVM = JSON.parse(<string>notification);
      const dateTime = new Date();
      switch (+this.notificationVM.typeId) {
        case Consts.EmitNotificationType.NotificationWindow: /** FOR NOTIFICATION WINDOW MESSAGE/S */
          if (!!this.notificationVM.userNotification) {
            const severity = this.severity.find(s => s.severityType === this.notificationVM.severityType); // This "severity" variable is used to fetch the severity type and get that respective class from environment
            if (!!severity) {
              this.msgCount++;
              // set default
              this.showAllNotificaitons = false;
              this.moreEnabled = false;
              this.topFour = 4;
              this.checkAndGetTotalNotificationCount(this.notificationVM.userNotification.instanceId, 'user'); // Used to get the Total Notifications Count
              // Getting Read notifications under To Do tab when we stay on To Do tab for few seconds
              this.selectedNotificationFilter = this.selectedTab = 'Unread'; // Setting default
            }
          }
          break;
      }
    });
  }

  onShowToaster() {
    this.viewNotificationResult(this.notificationVM.userNotification.id);
    this.showNotificationDiv = true;
    this.showNotificationStoryDiv = false;
  }

  onShowNewTask() {
    this.displayAddActivity = true;
  }

  updateEnable(topics) {
    if (topics.enabled) {
      topics.sendEmail = false;
    }
  }

  // this method will be called in page load to get total notifications list from API
  // param:noOfNotifications: number of notifications to get initially, if 0 then get all notifications
  loadNotificationList(noOfNotifications: number) {
    this.loadingNotifications = true;
    // eslint-disable-next-line eqeqeq
    if (noOfNotifications == 0) {
      this.notificationService.GetNotificationList()
        .subscribe((model: INotificationsList[]) => {
          this.notificationsList = model;
          this.FilterList(this.selectedNotificationFilter);
          const sessionHelper = new SessionHelper();
          this.userEmail = sessionHelper.getUser().email;
        });
    } else {
      this.notificationService.getTopUnReadNotificationList(noOfNotifications)
        .subscribe((model: INotificationsList[]) => {
          this.notificationsList = model;
          this.FilterList(this.selectedNotificationFilter);
          const sessionHelper = new SessionHelper();
          this.userEmail = sessionHelper.getUser().email;
        });
    }
  }

  // This is used to get the count of all Notifications
  totalNotificationsCount() {
    this.totalNotificationsCountSubscription?.unsubscribe();
    this.totalNotificationsCountSubscription = this.notificationService.getAllNotificationsCount()
      .subscribe((notiCount: INotificationVM) => {
        this.totalCount = notiCount[0].TotalNotificationCount;
        this.readCount = notiCount[0].ReadNotificationCount;
        if (this.totalCount > 4 && !this.showAllNotificaitons) {
          this.showAllNotificaitons = false;
        }
        // For Activities in TO DO notification panel
        if (notiCount[0].UserActivityCount > 4 && !this.showAllActivities) {
          this.showAllActivities = false;
        }
      });
  }

  // Method to avoid multiple calls to count API for same instanceId --OE-2783
  checkAndGetTotalNotificationCount(processId, type) {
    const sessionData = this._sessionHelper.get('notificationCountKey');
    this.notificationCountAPIMap = sessionData ? <NotificationCountAPIMap[]>(sessionData) : [];
    // eslint-disable-next-line eqeqeq
    if (this.notificationCountAPIMap.length == 0) {
      this.notificationCountAPIMap.push({
        instanceId: processId,
        notificationCallType: {
          isMenuNotification: false,
          isUserNotification: false
        }
      });
      this._sessionHelper.set('notificationCountKey', this.notificationCountAPIMap);
    }
    if (this.notificationCountAPIMap && this.notificationCountAPIMap.length > 0) {
      // eslint-disable-next-line eqeqeq
      const map = this.notificationCountAPIMap.filter(item => item.instanceId == processId);
      if (map && map.length > 0) {
        // eslint-disable-next-line eqeqeq
        if (type == 'user' && !(map[0].notificationCallType.isUserNotification)) {
          map[0].notificationCallType.isUserNotification = true;
          this.loadNotificationList(this.topFour);
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'menu' && !(map[0].notificationCallType.isMenuNotification)) {
          map[0].notificationCallType.isMenuNotification = true;
          this.totalNotificationsCount(); // only call this with menu notifications
        }
        this._sessionHelper.set('notificationCountKey', this.notificationCountAPIMap);
      } else {
        this.notificationCountAPIMap = this.notificationCountAPIMap ? this.notificationCountAPIMap : [];
        this.notificationCountAPIMap.push({
          instanceId: processId,
          notificationCallType: {
            // eslint-disable-next-line eqeqeq
            isMenuNotification: type == 'menu',
            // eslint-disable-next-line eqeqeq
            isUserNotification: type == 'user'
          }
        });
        this._sessionHelper.set('notificationCountKey', this.notificationCountAPIMap);
        // As per discussion count calls not required for menunotification.
        // Need to check impact of OEF-4640/OEF-4678
        // eslint-disable-next-line eqeqeq
        if (type == 'user') {
          this.loadNotificationList(this.topFour);
        } // this should be called for user notification only.
        // eslint-disable-next-line eqeqeq
        if (type == 'menu') {
          this.totalNotificationsCount();
        }
      }
    }
  }

  getFilterList(filterBy: string) {
    // Don't do anything if the selected tab has already been loaded
    if (filterBy === this.selectedTab) {
      return;
    }
    this.selectedTab = filterBy;
    this.filterNotificationsText = '';
    this.finalActivitiesList = <IActivities[]>[];
    this.FilterList(filterBy);
  }

  // method to filter the notifcations by readStatus
  FilterList(filterBy: string) {
    this.loadingNotifications = true;
    this.showTodo = false;
    this.selectedNotificationFilter = filterBy;
    let readStatus = false;
    this.showAllNotificaitons = false;
    this.finalList = this.notificationsList = null;
    if (this.moreEnabled) {
      this.topFour = 0;
    }
    this.notificationsSubscription?.unsubscribe();
    switch (filterBy) {
      case 'Read':
        readStatus = true;
        this.notificationsSubscription = this.notificationService.getTopReadNotificationList(this.topFour)
          .pipe(finalize(() => {this.loadingNotifications = false}))
          .subscribe((model: INotificationsList[]) => {
            this.notificationsList = model;
            // eslint-disable-next-line eqeqeq
            this.finalList = this.notificationsList.filter(notification => !!notification.readStatus === readStatus)
              .filter(x => x.subject.toLowerCase().indexOf((this.filterNotificationsText || '').toLowerCase()) >= 0);
            this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
          });
        if (this.readCount > 4 && !this.moreEnabled) {
          this.showAllNotificaitons = true;
        }
        break;
      case 'Unread':
        if (this.moreEnabled) {
          this.topFour = 0;
        }
        this.notificationsSubscription = this.notificationService.getTopUnReadNotificationList(this.topFour)
          .pipe(
            tap((model: INotificationsList[]) => {
              this.notificationsList = model;
              // eslint-disable-next-line eqeqeq
              this.finalList = this.notificationsList
                .filter(notification =>  !!notification.readStatus === readStatus)
                .filter(x => x.subject && x.subject.toLowerCase().indexOf((this.filterNotificationsText || '').toLowerCase()) >= 0);
              this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
            }),
            finalize(() => {this.loadingNotifications = false}))
          .subscribe();
        break;
      case 'All':
        // eslint-disable-next-line eqeqeq
        if (this.topFour != 0) {
          this.notificationsSubscription = this.notificationService.GetTopNotificationList(this.topFour)
            .pipe(
              tap((model: INotificationsList[]) => {
                this.notificationsList = model;
                this.finalList = this.notificationsList
                  .filter(x => x.subject.toLowerCase().indexOf((this.filterNotificationsText || '').toLowerCase()) >= 0);
                this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
              }),
              finalize(() => {this.loadingNotifications = false}))
            .subscribe();
        } else {
          this.notificationsSubscription = this.notificationService.GetNotificationList()
            .pipe(
              tap((model: INotificationsList[]) => {
                this.notificationsList = model;
                this.finalList = this.notificationsList
                  .filter(x => x.subject.toLowerCase().indexOf((this.filterNotificationsText || '').toLowerCase()) >= 0);
                this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
              }),
              finalize(() => {this.loadingNotifications = false}))
            .subscribe();
        }
        if (this.totalCount > 0 && !this.moreEnabled) {
          this.showAllNotificaitons = true;
        }
        break;
      case 'ToDo':
        this.loadingNotifications = false;
        this.showTodo = true;
        break;
      case 'DeleteAll':
        this.loadingNotifications = false;
        this.selectedNotificationFilter = this.selectedTab = filterBy = 'Unread'; // setting default.
        this.deleteAllNotifications();
        break;
      default:
        this.finalList = this.notificationsList.filter(x => x.subject.toLowerCase().indexOf((this.filterNotificationsText || '').toLowerCase()) >= 0);
        this.loadingNotifications = false;
        break;
    }
  }

  FilterSearchNotificationsList(filterBy: string) {
    this.showTodo = false;
    this.selectedNotificationFilter = filterBy;
    let readStatus = false;
    this.showAllNotificaitons = false;
    if (this.moreEnabled) {
      this.topFour = 0;
    }
    switch (filterBy) {
      case 'Read':
        readStatus = true;
        // eslint-disable-next-line eqeqeq
        this.finalList = this.finalList.filter(notification => !!notification.readStatus === readStatus);
        this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
        if (this.readCount > 4 && !this.moreEnabled) {
          this.showAllNotificaitons = true;
        }
        break;
      case 'Unread':
        if (this.moreEnabled) {
          this.topFour = 0;
        }
        // eslint-disable-next-line eqeqeq
        this.finalList = this.finalList.filter(notification => !!notification.readStatus === readStatus);
        this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
        break;
      case 'All':
        // eslint-disable-next-line eqeqeq
        if (this.topFour != 0) {
          this.finalList = this.finalList.slice(0, this.topFour);
          this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
        } else {

          this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
        }
        if (this.totalCount > 0 && !this.moreEnabled) {
          this.showAllNotificaitons = true;
        }
        break;
      case 'ToDo':
        this.showTodo = true;
        break;
      case 'DeleteAll':
        this.selectedNotificationFilter = this.selectedTab = filterBy = 'Unread'; // setting default.
        this.deleteAllNotifications();
        break;
      default:
        this.finalList = this.notificationsList;
        break;
    }
  }

  // this method will be called while changing the group by elemennt in UI
  groupNotifications(param) {
    this.selectedDisplayCategory = param;
    this.ProcessNotificationDisplay(this.selectedDisplayCategory, this.finalList);
  }

  // This method is used to update the status of the notification
  updateNotificationStatus($event) {
    const notificationId = $event[0];
    const operationName: string = $event[1];
    this.updateStatus(notificationId, operationName);
  }

  updateStatus(notificationId: number, operationName: string) {
    switch (operationName) {
      case 'Read': // Read the Notification
      case 'UnRead': // UnRead the Notification
        // eslint-disable-next-line eqeqeq
        const newStatus = ((operationName != 'Read'));
        this.notificationService.ReadNotification({ids: [notificationId], readStatus: newStatus})
          .subscribe(model => {
            // eslint-disable-next-line eqeqeq
            const notlst = this.finalList.filter(rec => rec.id == notificationId);
            // eslint-disable-next-line eqeqeq
            notlst[0].readStatus = ((operationName != 'Read'));
            this.FilterList(this.selectedNotificationFilter);
            this.totalNotificationsCount(); // To show more link
          });
        break;
      case 'Delete': // Delete the Notification
        this.notificationService.DeleteNotification(notificationId)
          .subscribe(deleteNotification => {
            // eslint-disable-next-line eqeqeq
            this.notificationsList.splice(this.notificationsList.findIndex(x => x.id == notificationId), 1);
            this.finalList = [];
            this.FilterList(this.selectedNotificationFilter);
            this.totalNotificationsCount(); // To show more link
          });
        break;
    }

  }

  viewNotificationResult(notificationId) {
    // eslint-disable-next-line eqeqeq
    this.notificationInformation = this.finalList.filter(e => e.id == notificationId);
    this.setNavigationLink(this.notificationInformation[0].subCode, null);
    this.updateStatus(notificationId, 'UnRead');
    this.showNotificationDiv = false;
    this.showNotificationStoryDiv = true;
    this.showActivityStoryDiv = false;
  }

  // This method is used to display the notification in UI
  private ProcessNotificationDisplay(groupBy: string, notificationsList: INotificationsList[]) {
    const groupNames: string[] = [];
    if (notificationsList) {
      notificationsList.forEach(notification => {
        // eslint-disable-next-line eqeqeq
        if (groupBy == 'Date') {
          notification.displayGroup = this.getDayNotation(notification.createdOn);
        } else {
          notification.displayGroup = notification.notificationCategory.name;
        }
        // eslint-disable-next-line eqeqeq
        const catExists = groupNames.find(c => c == notification.displayGroup);
        // eslint-disable-next-line eqeqeq
        if (catExists == undefined) {
          groupNames.push(notification.displayGroup);
        }
      });
    }
    this.displayGroups = groupNames;
    this.notificationsCounter();
  }

  // This method is used to Display the Notifications with date filter
  // Using UTCFormat Dates BugFix: OEF-4974
  getDayNotation(date: Date) {
    let filter: string;
    date = new Date(date);
    this.resetTimeToZero(date);
    const thisDay = this.getDate(0).getTime();
    const shortDate = date.getTime();
    const yesterday = this.getDate(-1).getTime();
    const lastWeekStart = this.getDate(-7).getTime();
    if (shortDate === thisDay) {
      filter = Consts.DayNotationFilterType.Today;
    } else if (shortDate === yesterday) {
      filter = Consts.DayNotationFilterType.Yesterday;
    } else if (shortDate < yesterday && shortDate >= lastWeekStart) {
      filter = Consts.DayNotationFilterType.PreviousSevenDays;
    } else {
      filter = Consts.DayNotationFilterType.Older;
    }

    return filter;
  }

  // This method is used to show the preferences div
  showPreferences(param) {
    this.showPreferencesDiv = param;
    this.showNotificationStoryDiv = false;
    this.showNotificationDiv = true;
    // eslint-disable-next-line eqeqeq
    if (param == true) {
      this.notificationService.getAllSubscribeNotificationTopic()
        .subscribe((notificationCategoryModel: ISubscribeNotificationTopics[]) => {
          this.notificationTopics = notificationCategoryModel;
          this.excludeFirmAdminNotifications();
        });
    }
  }

  // It filters out the 'tradeOrderEndDaySummary' notification topic for non-firm admin users.
  excludeFirmAdminNotifications(): void {
    const user = this._sessionHelper.getUser();
    const isFirmAdminUser = user.role.roleTypeId === Consts.UserType.FirmAdmin;

    if (!isFirmAdminUser) {
      this.notificationTopics = this.notificationTopics.filter(notificationTopic => {
        return notificationTopic.code !== Consts.NotificationCode.tradeOrderEndDaySummary;
      });
    }
  }

  // This method is used to Save the preferences
  onSave() {
    this.userEmail = this._sessionHelper.getUser().email;
    this.subscribeNotifications = <ISubscribeNotifications>{email: this.userEmail, notificationTopics: []};
    this.notificationTopics.forEach(t => {
      this.subscribeNotifications.notificationTopics.push(<ISelectedNotifications>{
        id: t.id,
        isEmail: t.sendEmail,
        isEnable: t.enabled
      });
    });
    this.notificationService.subscribeNotifications(this.subscribeNotifications)
      .subscribe((model: ISubscribeNotifications) => {
          this._alertService.alert.emit([{typeId: 1, message: 'Notifications have been saved'}]);
        },
        error => {
          this._alertService.alert.emit([{typeId: 4, message: error.message}]);
        });
  }

  getSubscribeNotificationTopic() {
    this.notificationService.getAllNotificationCategories()
      .subscribe((notificationCategoryTypeModel: INotificationTopics[]) => {
        this.notificationTopic = notificationCategoryTypeModel;
        this.notificationTopic.forEach(cat => {
          const catExists = this.notificationCategoryList.find(c => c.notificationCategoryId === cat.notificationCategoryId);
          if (!catExists) {
            this.notificationCategoryList.push(<INotificationTopics>{
              notificationCategoryId: cat.notificationCategoryId,
              notificationCategoryName: cat.notificationCategoryName,
              code: cat.code,
              iconUrl: cat.iconUrl,
              subjects: [cat.subject]
            });
          } else {
            catExists.subjects.push(cat.subject);
          }
        });
      });
    this.notificationService.getAllSubscribeNotificationTopic()
      .subscribe((notificationCategoryModel: ISubscribeNotificationTopics[]) => {
        this.notificationTopics = notificationCategoryModel;
        this.excludeFirmAdminNotifications();
      });
  }

  filterNotifications(event, isFromMoreEvent = false) {
    const inputKey = event.key ? event.key : event;
    const filterText = this.filterNotificationsText;

    // Filter data here
    if (!!this.showTodo) {
      this.notificationService.getActivitiesBySearch(filterText)
        .subscribe((result: IActivities[]) => {
          this.finalActivitiesList = result;
          this.ProcessActivitiesDisplay('Date', this.finalActivitiesList);
        });
    } else {
      if (this.notificationSubscription && !this.notificationSubscription.closed) {
        this.notificationSubscription.unsubscribe();
      }
      this.notificationSubscription = this.notificationService.getNotificationsBySearch(filterText)
        .subscribe((result: INotificationsList[]) => {
          this.finalList = result;
          this.FilterSearchNotificationsList(this.selectedNotificationFilter);
        });
    }
  }

  // End search Notification

  // ToDo-Activities
  // Get Activities from API
  getActivities(activityCount: number) {
    this.showTodo = true;
    this.selectedTab = 'ToDo';
    this.filterNotificationsText = '';
    if (activityCount > 0) {
      this.showAllActivities = false;
    }
    this.notificationService.getActivities(activityCount)
      .subscribe((activities: IActivities[]) => {
        // activities = Util.sortBy(activities);
        this.copyOfCompletedActivityList = activities;
        // eslint-disable-next-line eqeqeq
        activities = activities.filter(act => act.isCompleted != true);
        // this.activityList = Util.sortBy(activities);
        this.activityList = activities;
        this.ProcessActivitiesDisplay('Date', this.activityList);
      });
  }

  // This method is used to display To Do activities in grouped format.
  ProcessActivitiesDisplay(groupBy: string, activitiesList: IActivities[]) {
    const groupNames: string[] = [];
    // Loop each activity
    activitiesList.forEach(activity => {
      if (groupBy === 'Date') {
        activity.displayGroup = this.getDayNotation(activity.createdOn);
      }
      const catExists = groupNames.find(c => c === activity.displayGroup);
      if (!catExists) {
        groupNames.push(activity.displayGroup);
      }
    });
    this.displayActivityGroups = groupNames;
    this.finalActivitiesList = activitiesList;
  }

  // Activity Creation
  createActivity() {
    if (this.activity.name === '' || !this.activity.name || this.activity.description === '' || !this.activity.description) {
      this._alertService.alert.emit([{typeId: 4, message: 'Please fill all the fields'}]);
      this.displayAddActivity = true;
      return;
    }
    this.notificationService.createActivity(this.activity)
      .subscribe((result: IActivities) => {
        this.closePopup();
        this.getActivities(this.topActivities);
      });
  }

  // Close popup
  closePopup() {
    this.activity.name = '';
    this.activity.description = '';
    this.displayAddActivity = false;
  }

  /** Create column headers for agGrid */
  private createColumnDefs() {
    this.columnDefs = [
      <ColDef>{ headerName: '', field: 'chck', width: 30, checkboxSelection: true, filter: 'agNumberColumnFilter' },
      <ColDef>{ headerName: 'User ID', field: 'id', width: 75, cellClass: 'text-center', filter: 'agNumberColumnFilter' },
      <ColDef>{ headerName: 'User Name', field: 'name', width: 125, filter: 'agTextColumnFilter' },
      <ColDef>{ headerName: 'Role', field: 'role.name', width: 110, cellClass: 'text-center', filter: 'agTextColumnFilter' },
      <ColDef>{ headerName: 'Status', field: 'status', width: 110, cellClass: 'text-center', cellRenderer: this.statusRenderer, filterParams: { cellRenderer: this.statusFilterRenderer }, suppressHeaderMenuButton: true }
    ];
  }

  /** renders status for row data */
  private statusRenderer(params) {
    return `<i class="fa fa-circle ${params.value === 1 ? 'text-success' : 'text-gray'}" aria-hidden="true"></i>`;
  }

  /** Renders based on status filter  */
  statusFilterRenderer(params) {
    const filterParam = true;
    let result = '<span>';
    if (params.value === 0) {
      result += '<i class="fa fa-circle text-gray" aria-hidden="true"></i>';
    } else if (params.value === 1) {
      result += '<i class="fa fa-circle text-green" aria-hidden="true"></i>'
    } else {
      return null;
    }
    if (filterParam && params.value === 1) {
      result += '(no records)';
    }
    return `${result}</span>`;
  }

  /** Method to get all the users list*/
  getAllUsers() {
    this.errorMessage = '';
    this._userService.getUsers()
      .subscribe((users: IUser[]) => {
        this.masterUsersData = users;
        this.usersData = users;
        this.gridApiUsers.sizeColumnsToFit();
      });
  }

  /** * method to display module updates on console*/
  // This method will update the status of the activity
  markComplete(id: number) {
    const activity = this.activityList.filter(record => record.id === id)[0];
    if (!!activity) {
      activity.isCompleted = true;
      return this.notificationService.updateActivity(id, activity)
        .subscribe(msg => {
            this.activityList.find(a => a.id === id).isCompleted = true;
            this.finalActivitiesList = this.activityList.filter(act => !act.isCompleted);
            this.ProcessActivitiesDisplay('Date', this.finalActivitiesList);
          },
          error => (console.log(`Error: ${error}`)));
    }
  }

  showUsers(id: number) {
    this.usersData = [];
    // Fix for OEF-3383: Previously we used to get this list once in 'onInit', which throwing error when user loginAs another user.
    this.getAllUsers(); // Get all users list
    this.selectedActivityId = id;
    this.displayUsers = true;

    // Setting users data from master Dataset
    this.usersData = this.masterUsersData;
    this.notificationService.getUsersAssignedToActivity(this.selectedActivityId)
      .subscribe((users: ActivityUser[]) => {
        users.forEach(record => {
          this.iUser = <IUser>{};
          this.iUser.role = <IRole>{};
          this.iUser.name = record.userName;
          this.iUser.id = record.userId;
          this.iUser.role.name = record.role;
          this.iUser.status = record.status;
          this.selectedUsers.push(this.iUser);
        });
        this.gridApiSelectedUsers.setGridOption('rowData', this.selectedUsers);
        this.selectedUsers.forEach(item =>
          // eslint-disable-next-line eqeqeq
          this.usersData = this.usersData.filter(record => record.id != item.id)
        );
        this.gridApiUsers.setGridOption('rowData', this.usersData);
      });
  }

  assignUser() {
    const selUsers = this.gridApiUsers.getSelectedRows();
    if (!selUsers?.length) {
      this.assignUserErrorMsg = 'Please select at least one record to assign.';
    } else {
      this.assignUserErrorMsg = '';
      selUsers.forEach(item => {
        // Remove selected values from 'All Users'.
        this.usersData = this.usersData.filter(record => record.id !== item.id);
        // Push selected values to 'Assigned Users'
        this.selectedUsers.push(item);
      });
      this.gridApiSelectedUsers.setGridOption('rowData', this.selectedUsers);
      this.gridApiUsers.setGridOption('rowData', this.usersData);
    }
  }

  removeUser() {
    const users = this.gridApiSelectedUsers.getSelectedRows();
    if (!users?.length) {
      this.assignUserErrorMsg = 'Please select at least one record to remove.';
    } else {
      this.assignUserErrorMsg = '';
      users.forEach(item => {
        this.selectedUsers = this.selectedUsers.filter(record => record.id !== item.id);
        this.usersData.push(item);
        this.gridApiUsers.setGridOption('rowData', this.usersData);
        this.gridApiSelectedUsers.setGridOption('rowData', this.selectedUsers);
      });
    }
  }

  assignAllUsers() {
    this.selectedUsers = this.selectedUsers.concat(this.usersData);
    this.usersData = [];
    this.gridApiSelectedUsers.setGridOption('rowData', this.selectedUsers);
    this.gridApiUsers.setGridOption('rowData', this.usersData);
  }

  removeAllUsers() {
    !this.usersData.length ?
      this.usersData = this.selectedUsers.concat(this.usersData) : this.usersData = this.usersData.concat(this.selectedUsers);
    this.selectedUsers = [];
    this.gridApiSelectedUsers.setGridOption('rowData', this.selectedUsers);
    this.gridApiUsers.setGridOption('rowData', this.usersData);
  }

  AssignUsersToActivity() {
    const userIds = [];
    this.selectedUsers.forEach(record => {
      userIds.push(record.id);
    });
    if (userIds.length) {
      return this.notificationService.AssignUsersToActivity(this.selectedActivityId, userIds)
        .subscribe(msg => {
            this.closeUsersPopup();
          },
          error => (console.log(`Error: ${error}`))
        );
    }
  }

  closeUsersPopup() {
    this.assignUserErrorMsg = '';
    this.selectedActivityId = 0;
    this.usersData = [];
    this.gridApiUsers.setGridOption('rowData', this.usersData);
    this.selectedUsers = [];
    this.displayUsers = false;
  }

  // End Activity

  showNotifications() {
    this.showNotificationDiv = true;
    this.showNotificationStoryDiv = false;
    this.showActivityStoryDiv = false;
  }

  notificationsCounter() {
    this.notificationsCounterSubscription?.unsubscribe();
    this.notificationsCounterSubscription = this.notificationService.getAllNotificationsCount()
      .pipe(
        tap((msg: INotificationVM) => {
          this.msgCount = msg[0].TotalNotificationCount - msg[0].ReadNotificationCount;
          this.msgCounter.emit(this.msgCount);
          // This is used to set more when msgCount>0
          if (this.selectedTab === 'Unread') {
            if (this.msgCount > 4 && !this.moreEnabled) {
              this.showAllNotificaitons = true;
            }
          }
        })
      ).subscribe();
  }

  clearSearch() {
    this.filterNotificationsText = '';
    this.filterNotifications('');
  }

  updateUserEmail() {
    this.ShowProfilePopup.emit();
  }

  // This method is to display top 4 Notifications
  viewAllNotifications() {
    if (this.filterNotificationsText && this.filterNotificationsText !== '') {
      this.filterNotifications(this.filterNotificationsText, true);
    } else {
      this.loadNotificationList(0);
    }
    this.moreEnabled = true;
  }

  // This method is to display top 5 Activities
  viewAllActivities() {
    this.showAllActivities = true;
    if (this.filterNotificationsText && this.filterNotificationsText !== '') {
      this.filterNotifications({}, true);
    } else {
      this.getActivities(0);
    }
  }

  routeLink() {
    if (this.queryParams) {
      this._router.navigate([this.navigateURL], {queryParams: this.queryParams});
    } else {
      this._router.navigate([this.navigateURL]);
    }
  }

  notifyImport() {
    if (this.notificationVM.userNotification.subCode === Consts.NotificationSubType.ImportComplete) {
      if (this.notificationVM.progressNotification.progress === 100) {
        this.notificationService.onNotificationComplete();
      }
    }
  }

  deleteAllNotifications() {
    this.notificationService.deleteAllNotifications()
      .subscribe(model => {
          this._alertService.alert.emit([{typeId: 1, message: model.message}]);
          this.notificationsList = [];
          // THis is used to call the default notifcations list
          this.loadNotificationList(this.topFour);
          this.totalNotificationsCount(); // Used to get the total Notifications(Read/Unread)
        },
        error => {
          this._alertService.alert.emit([{typeId: 4, message: error.message}]);
        });
  }

  markAsRead() {
    this.notificationService.readAllNotifications()
      .subscribe(model => {
          this.loadNotificationList(this.topFour);
          this._alertService.alert.emit([{typeId: 1, message: 'All user notifications read successfully'}]);
          this.totalNotificationsCount();
        },
        error => {
          this._alertService.alert.emit([{typeId: 4, message: error.message}]);
        });
  }

  ngOnDestroy() {
    this.connection?.unsubscribe();
    this.notificationSubscription?.unsubscribe();
    this.notificationsSubscription?.unsubscribe();
    this.notificationsCounterSubscription?.unsubscribe();
    this.totalNotificationsCountSubscription?.unsubscribe();
    this._sessionHelper.removeItem('notificationCountKey');
  }

  getSeverityIcons() {
    this.severity =
      [
        {
          severityType: 'Information',
          icon: 'fa-info-circle text-info'
        },
        {
          severityType: 'Error',
          icon: 'fa-exclamation-circle text-danger'
        },
        {
          severityType: 'Warning',
          icon: 'fa-warning text-warning'
        },
        {
          severityType: 'TodoInformation',
          icon: 'fa-calendar-check text-primary'
        }
      ];
  }

  getActivityDetails(activityId) {
    this.activityInformation = this.activityList.filter(e => e.id === activityId);
    this.showNotificationDiv = false;
    this.showNotificationStoryDiv = false;
    this.showActivityStoryDiv = true;
  }

  /* initialize Completed Tasks Grid options */
  private createTaskColumnDefs() {
    this.doneTaskColumnDefs = [
      <ColDef>{headerName: 'Name', field: 'name', width: 200, filter: 'agTextColumnFilter'},
      <ColDef>{
        headerName: 'Description',
        field: 'description',
        width: 315,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      }
    ];
  }

  completeTasks() {
    this.completedActivityList = this.copyOfCompletedActivityList.filter(act => act.isCompleted);
    this.gridApiDoneTasks.setGridOption('rowData', this.completedActivityList);
    this.displayCompletedActivity = true;
  }

  /** * method to display module updates on console*/
  onModelUpdated() {
    this.gridApiDoneTasks?.autoSizeColumns(['description']);
    this.gridApiDoneTasks?.autoSizeColumns(['name']);
  }

  /**Setting descriptive link - OEMP-215 */
  setNavigationLink(subCode, recordId) {
    this.queryParams = null;
    switch (subCode) {
      case Consts.NotificationSubType.TradeInstanceCompleted:
        this.clickHere = Consts.navigationLink.Vieworders;
        this.navigateURL = '/eclipse/tradeorder/list';
        break;
      case Consts.NotificationSubType.TradeInstanceError:
        this.clickHere = Consts.navigationLink.Vieworders;
        this.navigateURL = '/eclipse/tradeorder/list';
        break;
      case Consts.NotificationSubType.PendingOrders:
        this.clickHere = Consts.navigationLink.Viewpendings;
        this.navigateURL = '/eclipse/tradeorder/pending';
        break;
      case Consts.NotificationSubType.FixBlockOrder:
        this.clickHere = Consts.navigationLink.Viewclosedorders;
        this.navigateURL = '/eclipse/tradeorder/closed';
        break;
      case Consts.NotificationSubType.DataImportComplete:
        this.clickHere = Consts.navigationLink.Errors;
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount !== undefined && this.notificationVM.userNotification.errorCount > 0) {
            this.clickHere += `: ${this.notificationVM.userNotification.errorCount}`;
          }
        }

        this.navigateURL = '/eclipse/errorlogs';
        break;
      case Consts.NotificationSubType.ModelImportComplete:
        this.clickHere = Consts.navigationLink.Errors;
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount > 0 && this.notificationVM.userNotification.errorCount !== null) {
            this.clickHere += `: ${this.notificationVM.userNotification.errorCount}`;
          } else {
            this.clickHere = Consts.navigationLink.ViewImports;
          }
        }
        if (this.notificationVM.userNotification) {
          this.navigateURL = `/eclipse/admin/importlogs/detail/${this.notificationVM.userNotification.instanceId}`;
        }
        break;
      case Consts.NotificationSubType.FullAnalysisComplete:
        this.clickHere = Consts.navigationLink.Errors;
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount !== undefined && this.notificationVM.userNotification.errorCount > 0) {
            this.clickHere += `: ${this.notificationVM.userNotification.errorCount}`;
          }
        }
        this.navigateURL = '/eclipse/analyticserrorlogs';
        break;
      case Consts.NotificationSubType.TradesNeedApproval:
        this.clickHere = Consts.navigationLink.Vieworders;
        this.navigateURL = '/eclipse/tradeorder/list/2';
        break;
      case Consts.NotificationSubType.ModelAssignmentsNeedApproval:
        this.clickHere = Consts.navigationLink.Viewportfolios;
        this.navigateURL = '/eclipse/portfolio/list';
        break;
      case Consts.NotificationSubType.ModelNeedsApproval:
        this.clickHere = Consts.navigationLink.Viewmodel;
        this.navigateURL = '/eclipse/model/list';
        this.queryParams = {
          filter: 2
        };
        break;
      case Consts.NotificationSubType.CommunityModelUpdated:
        this.clickHere = Consts.navigationLink.Viewmodel;
        this.navigateURL = '/eclipse/model/list';
        break;
      case Consts.NotificationSubType.ImportComplete:
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount !== undefined && this.notificationVM.userNotification.errorCount !== 0) {
            this.clickHere = `${Consts.navigationLink.Errors}: ${this.notificationVM.userNotification.errorCount}`;
          } else {
            this.clickHere = Consts.navigationLink.ViewImports;
          }
        }
        this.navigateURL = `/eclipse/admin/importlogs/detail/${recordId}`;
        break;
      case Consts.NotificationSubType.TLHBuyBack:
        this.clickHere = Consts.navigationLink.ClickHere;
        const instanceId = this.notificationVM && this.notificationVM.userNotification ? this.notificationVM.userNotification.instanceId : this.notificationInformation[0].instanceId;
        if (!instanceId) {
          this.navigateURL = '/eclipse/tradeorder/instancesfilter';
        } else {
          this.navigateURL = `/eclipse/tradeorder/instancesfilter/${instanceId}`;
        }
        break;
      default:
        break;
    }
  }

  public trackByNotificationGroup(index: number, group: string): string {
    return group;
  }
}
