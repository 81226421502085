export const SECURITY_STATUS = {
    OPEN: 'OPEN'
};

export const SECURITY_TYPE_ID = {
    BOND: 6,
    OPTION: 7
};

export const SECURITY_RELATED_TYPE = {
    EQUIVALENT: 'Equivalent',
    ALTERNATE: 'Alternate'
};
