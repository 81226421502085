import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import * as constants from '../../libs/app.constants';
import { OptionFilterType } from '../../models/tradetools';
import * as _ from 'lodash';
import { TradeToolsService } from '../../services/tradetools.service';
import { IPortfolioAnalytics } from '../../models/portfolio';
import { IAnalyticsTradeToolList } from '../../models/analytics';
import { AnalyticsHelper } from '../analytics-duration/analytics-helper';
import { IAnalyticsNotification } from '../../models/notification';

@Component({
  selector: 'eclipse-trade-tool-analytic-analyzer',
  templateUrl: './tradetool.analytics.component.html'
})

export class TradeToolAnalyticsAnalyzerComponent extends BaseComponent {

  @Input() isTaxHarvest: boolean;
  @Output() tradeToolCallBack = new EventEmitter();
  @Output() updateTLHOpportunityFlag = new EventEmitter();
  isMultiple: boolean;
  portfolioAnalytics: IPortfolioAnalytics[];
  List: any[];
  tradeFilterMethod: string;
  constructor(private readonly _tradeToolsService: TradeToolsService) {
    super(constants.PRIV_MODELS);
    this.portfolioAnalytics = [];
  }

  setPortfolioAnalyticsDetails(list: IAnalyticsTradeToolList[] | any[], tradeFilterMethod: string): void {
    this.List = list;
    this.tradeFilterMethod = tradeFilterMethod;
    this.portfolioAnalytics = AnalyticsHelper.getPortfolioAnalyticsDetailsFromTradeToolList(list, tradeFilterMethod);
  }

  resetAnalytics() {
    this.portfolioAnalytics = [];
  }

  updateNextButtonStatus(isDisableNextButton: boolean): void {
    this.tradeToolCallBack.emit({ value: isDisableNextButton });
  }

  async updateTradeToolList(analyticsNotification: IAnalyticsNotification): Promise<void> {
    if (this.List) {
      await this.getAndUpdateTLHOpportunity(analyticsNotification);
      if (analyticsNotification.portfolioFlags) {
        if (this.tradeFilterMethod === OptionFilterType.Model || this.tradeFilterMethod === OptionFilterType.TradeGroup) {
          this.List.forEach(data => {
            if (data.portfolioFlags) {
              this.updateListFields(data.portfolioFlags, analyticsNotification.portfolioFlags, 'portfolioId');
            }
          });
        } else if (this.tradeFilterMethod === OptionFilterType.Portfolio) {
          this.updateListFields(this.List, analyticsNotification.portfolioFlags, 'id');
        } else if (this.tradeFilterMethod === OptionFilterType.Account || this.tradeFilterMethod === OptionFilterType.SleevePortfolio) {
          this.updateListFields(this.List, analyticsNotification.portfolioFlags, 'portfolioId');
        }
      }
      AnalyticsHelper.updatePortfolioDetails(this.portfolioAnalytics, analyticsNotification.portfolioFlags);
    }
  }

  private updateListFields(list: IAnalyticsTradeToolList[], portfolioFlags: IPortfolioAnalytics[], columnName: string): void {
    const portfolioIdMap = new Map(portfolioFlags.map(flag => [flag.portfolioId, flag]));

    list.forEach(oldFlag => {
      const newFlag = portfolioIdMap.get(oldFlag[columnName]);
      if (newFlag) {
        oldFlag.needAnalytics = newFlag.needAnalytics;
        oldFlag.failedReason = newFlag.failedReason;
        oldFlag.analyticsEditedDate = newFlag.editedDate;
      }
    });
  }

  async getAndUpdateTLHOpportunity(analyticsNotification: IAnalyticsNotification): Promise<void> {
    if (this.isTaxHarvest && analyticsNotification.isAnalyticsCompleted) {
      const tlhObject = this.getTLHOpportunityFlagObject(analyticsNotification);
      if (tlhObject.isUpdateTLHFlag) {
        const newList = await this._tradeToolsService.getTLHOpportunity(tlhObject.ids, tlhObject.type).toPromise();
        this.updateTLHOpportunity(newList);
        this.updateTLHOpportunityFlag.emit();
      }
    }
  }

  getTLHOpportunityFlagObject(event: IAnalyticsNotification): any {
    const portfolioIds = event.portfolioFlags.map(p => p.portfolioId);
    const tlhObject = {
      isUpdateTLHFlag: false,
      ids: this.List.filter(item => item.id).map(l => l.id),
      type: null
    };
    if (this.tradeFilterMethod === OptionFilterType.Portfolio) {
      tlhObject.isUpdateTLHFlag = this.List.some(item => portfolioIds.includes(item.id));
      tlhObject.type = OptionFilterType.Portfolio;
    } else if (this.tradeFilterMethod === OptionFilterType.Account || this.tradeFilterMethod === OptionFilterType.Import) {
      tlhObject.isUpdateTLHFlag = this.List.some(item => portfolioIds.includes(item.portfolioId));
      tlhObject.type = OptionFilterType.Account;
    } else if (this.tradeFilterMethod === OptionFilterType.Model) {
      const ids = _.flattenDeep(this.List.map(l => l.portfolioFlags)).map(p => p.portfolioId);
      tlhObject.isUpdateTLHFlag = ids.some(item => portfolioIds.includes(item));
      tlhObject.type = OptionFilterType.Model;
    } else {
      tlhObject.ids = _.flattenDeep(this.List.map(l => l.portfolioFlags)).map(p => p.portfolioId);
      tlhObject.isUpdateTLHFlag = tlhObject.ids.some(item => portfolioIds.includes(item));
      tlhObject.type = OptionFilterType.Portfolio;
    }
    return tlhObject;
  }

  updateTLHOpportunity(newList: any[]): void {
    if (this.List?.length) {
      this.List.forEach(list => {
        if (this.tradeFilterMethod === OptionFilterType.TradeGroup && list.portfolioFlags.length) {
          list.portfolioFlags.forEach(p => p.hasTaxLossHarvest = !!newList.find(e => e.id === p.portfolioId).hasTaxLossHarvest);
        } else {
          newList.forEach(newFlag => {
            if (newFlag.id === list.id) {
              if (this.tradeFilterMethod === OptionFilterType.Model && list.portfolioFlags.length) {
                list.portfolioFlags[0].hasTaxLossHarvest = newFlag.hasTaxLossHarvest;
              } else {
                list.hasTaxLossHarvest = newFlag.hasTaxLossHarvest;
              }
            }
          });
        }
      });
    }
  }
}
