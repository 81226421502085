<div class="grid-control">
    <div class="table-responsive">
        <div [ngSwitch]="displayPermission">
            <table class="table table-bordered tabel-bg-white location-table">
              <caption class="hidden-element">Permissions</caption>
                <thead>
                    <tr>
                        <th colspan="3" style="text-align: center;" scope="col">
                            <select *ngIf = "displayPermission" class="form-control" [(ngModel)]="LocationOptimizations.assetType" (ngModelChange)="getLocationOptimization()">
                                <option *ngFor ="let type of assetTypeList" [ngValue]=type.id>{{type.optionName}}</option>
                            </select>
                            <label *ngIf = "!displayPermission">{{LocationOptimizations.inheritedAssetTypeName}}</label>
                        </th>
                        <th colspan="3" style="text-align: center;" scope="col">Buy</th>
                        <th colspan="3" style="text-align: center;" scope="col">Sell</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngSwitchCase="true">
                        <td colspan="3" style="text-align: center;width: 33%">
                            <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.id" (change)="toggleFlag('','')" [disabled]="disableAssetTypeData">
                                <option *ngIf="assetTypeName == 'Category'" [ngValue]=0>Select Category</option>
                                <option *ngIf="assetTypeName == 'Class'" [ngValue]=0>Select Class</option>
                                <option *ngIf="assetTypeName == 'SubClass'" [ngValue]=0>Select Sub Class</option>
                                <option *ngFor="let class of assetTypeData" [ngValue]="class.id">
                                    {{class.name}}
                                </option>
                            </select>
                            <input type="hidden" [(ngModel)]="selectedModel.name" value="" />
                        </td>
                        <td colspan="7" style="text-align: center;">
                            <a href="javascript:void(0)"  [class.disabled]="!selectedModel.id" class="submitButton" (click)="showLocationPreferencePopup()">
                              <i class="fas fa-plus-circle" aria-hidden="true"></i>
                            </a>
                            <span *ngIf="LocationOptimizations.inheritedFrom" class="location-level-label">Level: {{LocationOptimizations.inheritedFrom}}</span>
                          </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: center;"></td>
                        <td style="text-align: center;"><strong>T </strong></td>
                        <td style="text-align: center;"><strong>TD</strong></td>
                        <td style="text-align: center;"><strong>TE</strong></td>
                        <td style="text-align: center;"><strong>T</strong></td>
                        <td style="text-align: center;"><strong>TD</strong></td>
                        <td style="text-align: center;"><strong>TE</strong></td>
                        <td><strong>Inherited From</strong></td>
                    </tr>
                    <tr *ngFor="let item of LocationOptimizations.assetTypes">
                        <td colspan="3" style="text-align: center;"><a href="javascript:void(0)" (click)="editLocationPreferencePopup(item.id)">{{item.name}}</a></td>
                        <td>{{getSelectValue(item.buySetting.T)}}</td>
                        <td>{{getSelectValue(item.buySetting.TD)}}</td>
                        <td>{{getSelectValue(item.buySetting.TE)}}</td>
                        <td>{{getSelectValue(item.sellSetting.T)}}</td>
                        <td>{{getSelectValue(item.sellSetting.TD)}}</td>
                        <td>{{getSelectValue(item.sellSetting.TE)}}</td>
                         <td>{{item.inheritedFrom}}</td>
                        <td *ngSwitchCase="true"><i class="fas fa-times red" aria-hidden="true" title="DeleteOptimization" (click)="deleteOptimizations(item.id)"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>

  <p-dialog header="Location Optimization" [(visible)]="showPreferencePopup" modal="true" [draggable]=true
    [resizable]=false class="ui-dialog-lg" [closeOnEscape]="false">
    <div class="grid-control table-width">
        <div class="table-responsive">
            <div [ngSwitch]="displayPermission">
                <table class="table table-bordered tabel-bg-white location-table">
                  <caption class="hidden-element">Location Optimizations</caption>
                    <thead>
                        <tr>
                            <th colspan="3" style="text-align: center;" scope="col"><strong> {{LocationOptimizations.assetTypeName}} </strong></th>
                            <th colspan="3" style="text-align: center;" scope="col">Buy</th>
                            <th colspan="3" style="text-align: center;" scope="col">Sell</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="3" style="text-align: center;">
                                <label>
                                    {{selectedModel.name}}
                                </label>
                            </td>
                            <td style="text-align: center;"><strong>T </strong></td>
                            <td style="text-align: center;"><strong>TD</strong></td>
                            <td style="text-align: center;"><strong>TE</strong></td>
                            <td style="text-align: center;"><strong>T</strong></td>
                            <td style="text-align: center;"><strong>TD</strong></td>
                            <td style="text-align: center;"><strong>TE</strong></td>
                        </tr>
                        <tr *ngSwitchCase="true">
                            <td colspan="3" style="text-align: center; width: 33%"></td>
                            <td>
                                <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.buySetting.T" (change)="toggleFlag('buy','T')">
                                    <option *ngFor="let opt of optimizations" [ngValue]="opt.id" >
                                        {{opt.value}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.buySetting.TD" (change)="toggleFlag('buy','TD')">
                                    <option *ngFor="let opt of optimizations" [ngValue]="opt.id" >
                                        {{opt.value}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.buySetting.TE" (change)="toggleFlag('buy','TE')">
                                    <option *ngFor="let opt of optimizations" [ngValue]="opt.id" >
                                        {{opt.value}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.sellSetting.T" (change)="toggleFlag('sell','T')">
                                    <option *ngFor="let opt of optimizations" [ngValue]="opt.id" >
                                    {{opt.value}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.sellSetting.TD" (change)="toggleFlag('sell','TD')">
                                    <option *ngFor="let opt of optimizations" [ngValue]="opt.id" >
                                    {{opt.value}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="selectedModel.sellSetting.TE" (change)="toggleFlag('sell','TE')">
                                    <option *ngFor="let opt of optimizations" [ngValue]="opt.id" >
                                    {{opt.value}}
                                    </option>
                                </select>
                            </td>

                            <td [hidden]="displayPermission == true">
                                    <label class="col-md-4"> {{selectedModel.inheritedFrom}}
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" style="text-align: center;"> SubRank </td>
                            <td><input class="form-control ng-pristine ng-valid ng-touched" type="number" [(ngModel)]="subRank.buySetting.T" name="T" [min]="minSubRank" [max]="maxSubRank" (keyup)="numberValidation($event)" [disabled]="!selectedModel.buySetting.T || selectedModel.buySetting.T == 4"/></td>
                            <td><input class="form-control ng-pristine ng-valid ng-touched" type="number" [(ngModel)]="subRank.buySetting.TD" [min]="minSubRank" [max]="maxSubRank" (keyup)="numberValidation($event)" [disabled]="!selectedModel.buySetting.TD || selectedModel.buySetting.TD == 4"/></td>
                            <td><input class="form-control ng-pristine ng-valid ng-touched" type="number" [(ngModel)]="subRank.buySetting.TE" [min]="minSubRank" [max]="maxSubRank" (keyup)="numberValidation($event)" [disabled]="!selectedModel.buySetting.TE || selectedModel.buySetting.TE == 4"/></td>
                            <td><input class="form-control ng-pristine ng-valid ng-touched" type="number" [(ngModel)]="subRank.sellSetting.T" [min]="minSubRank" [max]="maxSubRank" (keyup)="numberValidation($event)" [disabled]="!selectedModel.sellSetting.T || selectedModel.sellSetting.T == 4"/></td>
                            <td><input class="form-control ng-pristine ng-valid ng-touched" type="number" [(ngModel)]="subRank.sellSetting.TD" [min]="minSubRank" [max]="maxSubRank" (keyup)="numberValidation($event)" [disabled]="!selectedModel.sellSetting.TD || selectedModel.sellSetting.TD == 4"/></td>
                            <td><input class="form-control ng-pristine ng-valid ng-touched" type="number" [(ngModel)]="subRank.sellSetting.TE" [min]="minSubRank" [max]="maxSubRank" (keyup)="numberValidation($event)" [disabled]="!selectedModel.sellSetting.TE || selectedModel.sellSetting.TE == 4"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span *ngIf="errMesage" style="color:red; margin-left: 1%;"> Please select all fields.</span>
        </div>
    </div>
    <p-footer>
      <button type="button" pButton (click)="addOptimizations()" label="OK"></button>
      <button type="button" pButton class="p-button-secondary" (click)="hideLocationPreferencePopup()" label="Cancel"></button>
    </p-footer>
  </p-dialog>
