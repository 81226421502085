// Test/Review environment
export const environment = {
  name: 'review',
  production: false,
  versionCheckURL : 'assets/version.json',
  splitIoKey: 'cctir7k8818891cerft8653jc09p80b03b0i',
  orionConnect: {
    loginRedirectUrl: 'https://testlogin.orionadvisor.com/login.html?r='
  },
  datadog: {
    applicationId: '616bf6b7-ade9-4f32-86eb-d3fb01798310',
    clientToken: 'pub181898f1f8b0470529c74bc8729111a0',
    appName: 'eclipse.ui'
  },
  settings: {
    apiEndpoint: {
      v1: '/v1/',
      v2: '/api/v2/'
    },
    notificationEndpoint: '',
    orionAdvisorEndPoint: 'https://testapi.orionadvisor.com/',
    orionSupportUrl: 'https://orionadvisorservices--uat.sandbox.my.site.com/OrionSupportApp/s/',
    communitiesUrl: 'https://test.communities.orion.com/webcommunities/#/?oas_token=',
    astroServiceUrl: 'https://astroapi.review.orioneclipse.com/',
    agGridLicenseKey: 'Using_this_AG_Grid_Enterprise_key_( AG-041412 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Orion Advisor Technology, LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 40 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 June 2024 )____[v2]_MTcxOTA5NzIwMDAwMA==fdfd0690bd2138aef2c31a6f58afc2bd',
    modelColors: [
      '#007FFF',
      '#83F52C',
      '#FFFF00',
      '#00FFFF',
      '#ffd8f9',
      '#bf3eff',
      '#eee8cd',
      '#FE2EF7',
      '#F89812',
      '#12DDF8',
      '#F82D12',
      '#9370DB',
      '#696969',
      '#B0E0E6',
      '#FF00FF',
      '#C0C0C0',
      '#A52A2A',
      '#483D8B',
      '#DA70D6',
      '#DEB887'
    ],
    searchProperties: [
      {
        moduleName: 'securities',
        top: 20
      }
    ],
    tradeToolSettings: {
      bucketSettings: {
        enableRequestToBucket: true,
        accountsRequest: 1000,
        portfoliosRequest: 500,
        modelsRequest: 100
      },
      search: {
        enablesecuritySearchBucket: true,
        securitySearchRequestBucketSize: 1000
      },
      TLHSettings: {
        enableRequestToBucket: true,
        portfoliosRequests: 250
      }
    },
    tradeOrderSettings: {
      bucketSettings: {
        enableRequestToBucket: false,
        processRequest: 100
      }
    },
    decimalsForDollar: 2,
    decimalsForShares: 4,
    decimalsForPercent: 4,
    fallbackIntervalInSeconds: 60
  }


};
