import { ColDef } from '@ag-grid-community/core';

export enum DataType {
  Number,
  String,
  Boolean,
  Date,
  Enum
}

export interface IColumnFilter {
  key: string;
  headerName: string;
  values?: any[];
  description?: string;
  condition?: any;
  colDef?: ColDef;
  dataType: DataType;
  apiDataType?: DataType;
  comparator: string;
  enabled: boolean;
  // added by UI
  id: number;
  displayDescription: string;
}
