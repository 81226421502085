import {
  CellValueChangedEvent, ColumnState,
  GetContextMenuItemsParams, GridApi,
  GridOptions,
  ICellRendererParams, IRowNode, MenuItemDef, ProcessCellForExportParams, RowClickedEvent, ValueGetterParams,
} from '@ag-grid-community/core';
import { IIdName, ITradeAwayGroupSelected, ITradeBlock } from '../../../models/tom';
import * as constants from '../../../libs/app.constants';
import * as _ from 'lodash';
import moment from 'moment';
import { SecurityTypeName } from '../../../libs/app.constants';
import { FixMessagesComponent } from './fixmessages.component';
import { MassEditStatusBarComponent } from '../blocks/mass-edit/mass-edit-status-bar/mass-edit-status-bar.component';

export class TradeBlockHelper {
  static orderStatus = [] as IIdName[];
  static allocationStatus = [] as IIdName[];

  static cellRendererForNestedObject(params: ICellRendererParams<ITradeBlock>): string {
    const splitKey = params.colDef.field.split('.');
    let value = '';
    if (params.node.group && params.node?.aggData && params.node?.aggData[splitKey[0]]) {
      value = params.node.aggData[splitKey[0]][splitKey[1]];
    } else if (params?.data && params?.data[splitKey[0]]) {
      value = params.data[splitKey[0]][splitKey[1]];
    }
    TradeBlockHelper.setBlockValue(params, value);
    return value;
  }

  static cellRendererForGain(params: ICellRendererParams<ITradeBlock>, allBlocks: ITradeBlock[]): number {
    const blockData = TradeBlockHelper.getBlockData(params);
    if (blockData?.isTradeAwayBlock) {
      let totalGain = null;
      allBlocks.forEach(block => {
        if (block.tradeAwayBlockId === blockData.id && block[params.colDef.field]) {
          totalGain += Number(block[params.colDef.field]);
        }
      });
      TradeBlockHelper.setBlockValue(params, totalGain);
      return totalGain;
    }
    return blockData ? blockData[params.colDef.field] : null;
  }

  static cellRendererForDate(params: ICellRendererParams<ITradeBlock>): string {
    const block = TradeBlockHelper.getBlockData(params);
    let value = '';
    if (block) {
      const date = block[params.colDef.field];
      value = TradeBlockHelper.getFormattedDateTime(date);
      TradeBlockHelper.setBlockValue(params, value);
    }
    return value;
  }

  static updateFieldForTradeAwayBlocks(blocks: ITradeBlock[], field: string): void {
    for (const block of blocks) {
      if (block.isTradeAwayBlock) {
        TradeBlockHelper.updateFieldForParentAndChildBlocks(block, blocks, field);
      }
    }
  }

  /**
   * Updates the specified field based on the consistency of child block values.
   * Empties child block values.
   *
   * @param parentBlock {ITradeBlock} - The parent trade block.
   * @param allBlocks {ITradeBlock[]} - The array of all trade blocks.
   * @param field {string} - The field to be updated.
  */
  static updateFieldForParentAndChildBlocks(parentBlock: ITradeBlock, allBlocks: ITradeBlock[], field: string): void {
    const childBlocks = allBlocks.filter(block => block.tradeAwayBlockId === parentBlock.id);

    const areChildrenValuesSame = childBlocks.every(
      child => child[field] === childBlocks[0]?.[field]
    );

    if (areChildrenValuesSame) {
      parentBlock[field] = childBlocks[0]?.[field];
    } else {
      parentBlock[field] = '';
      for (const child of childBlocks) {
        child[field] = '';
      }
    }
  }

  static formatCurrencyCellRendererForAmount(params: ICellRendererParams<ITradeBlock>, allBlocks: ITradeBlock[]): string {
    const blockData = TradeBlockHelper.getBlockData(params);

    if (!blockData) {
      return null;
    }

    const currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    if (blockData.isTradeAwayBlock) {
      const totalAmount = allBlocks
        .filter(block => block.tradeAwayBlockId === blockData.id)
        .reduce((sum, block) => sum + Number(block[params.colDef.field]), 0);

      TradeBlockHelper.setBlockValue(params, totalAmount);
      return currencyFormat.format(totalAmount);
    }

    return currencyFormat.format(blockData[params.colDef.field]);
  }

  static getFormattedDateTime(date: string): string {
    if (!date || date === '0000-00-00 00:00:00' || date === '0000-00-00' || date === '0') {
      return null;
    }
    const dt = new Date(date);
    if (isNaN(dt.getMonth())) {
      return null;
    }
    return moment(dt).format('MM/DD/YYYY hh:mm:ss A');
  }

  static isTradeAwayBlockChild(node: IRowNode<ITradeBlock>): boolean {
    return node.group ? Boolean(node.aggData?.tradeAwayBlockId) : Boolean(node.data?.tradeAwayBlockId);
  }

  static isTradeAwayBlock(params: ICellRendererParams<ITradeBlock> | ValueGetterParams<ITradeBlock> | CellValueChangedEvent<ITradeBlock>): boolean {
    return params.node.group ? Boolean(params.node.aggData?.isTradeAwayBlock) : Boolean(params.node.data?.isTradeAwayBlock);
  }

  static isAnyTradeAwayBlock(blocks: ITradeBlock[]): boolean {
    return blocks.some(block => block.isTradeAwayBlock !== false);
  }

  static isTradeAwayBlocks(blocks: ITradeBlock[]): boolean {
    return blocks.every(block => block.isTradeAwayBlock);
  }

  static canGroupSelectedRow(blocks: ITradeBlock[]): boolean {
    const groupSelectedBlockDetails = TradeBlockHelper.getGroupSelectedBlocks(blocks);
    const firstRow = groupSelectedBlockDetails[0];
    const hasDifferentValues = groupSelectedBlockDetails.some(b => b.actionId !== firstRow.actionId || b.securityId !== firstRow.securityId
      || b.orderStatusId !== constants.OrderStatus.NotSent || b.allocationStatusId !== constants.OrderStatus.NotSent || b.orderTypeId !== firstRow.orderTypeId
      || b.execType !== firstRow.execType || b.settlementTypeId !== firstRow.settlementTypeId || b.handlInst !== firstRow.handlInst || b.executionInst !== firstRow.executionInst
      || b.durationId !== firstRow.durationId || b.positionEffectId !== firstRow.positionEffectId || b.cfiCodeId !== firstRow.cfiCodeId
      || b.avgPrice !== firstRow.avgPrice || b.securityType === SecurityTypeName.Option
    );
    return !hasDifferentValues;
  }

  static getGroupSelectedBlocks(blocks: ITradeBlock[]): ITradeAwayGroupSelected[] {
    const groupSelectedBlocks = [];
    for (const block of blocks) {
      const groupSelectedBlock = TradeBlockHelper.getGroupSelectedBlockDetails(block);
      groupSelectedBlocks.push(groupSelectedBlock);
    }
    return groupSelectedBlocks;
  }

  static getGroupSelectedBlockDetails(block: ITradeBlock): ITradeAwayGroupSelected {
    return {
      get actionId(): number {
        return block.action.id;
      },
      get securityId(): number {
        return block.security.id;
      },
      get orderStatusId(): number {
        return TradeBlockHelper.getOrderStatus(block);
      },
      get allocationStatusId(): number {
        return TradeBlockHelper.getAllocationStatus(block);
      },
      get orderTypeId(): number {
        return block.orderType.id;
      },
      get execType(): string {
        return block.execType;
      },
      get settlementTypeId(): number {
        return block.settlementType.id;
      },
      get handlInst(): string {
        return block.handlInst;
      },
      get executionInst(): string {
        return block.executionInst;
      },
      get durationId(): number {
        return block.durationId ?? block.duration?.id;
      },
      get positionEffectId(): number {
        return block.positionEffect.id;
      },
      get cfiCodeId(): number {
        return block.cfiCode.id;
      },
      get avgPrice(): number {
        return Number(block.avgPrice);
      },
      get securityType(): string {
        return block.security.securityType;
      },
    };
  }

  static getOrderStatus(block: ITradeBlock): number {
    return block.orderStatusId ||
      (typeof block.orderStatus === 'string'
        ? TradeBlockHelper.orderStatus.find(status => status.name === block.orderStatus.toString())?.id
        : block.orderStatus?.id);
  }

  static getAllocationStatus(block: ITradeBlock): number {
    return block.allocationStatusId ||
      (typeof block.allocationStatus === 'string'
        ? TradeBlockHelper.allocationStatus.find(status => status.name === block.allocationStatus.toString())?.id
        : block.allocationStatus?.id);
  }

  static getSelectedRowsFromParams(params: ICellRendererParams<ITradeBlock> | GetContextMenuItemsParams<ITradeBlock>): ITradeBlock[] {
    return params.api.getSelectedNodes().map(node => node.group ? node.aggData : node.data);
  }

  static getSelectedRowsFromGridOption(gridApi: GridApi): ITradeBlock[] {
    return gridApi.getSelectedNodes().map(node => node.group ? node.aggData : node.data);
  }

  static getParentAndChildOfTradeAwayBlocks(selectedRows: ITradeBlock[], alteredBlocks: ITradeBlock[]): ITradeBlock[] {
    const tradeBlockIds = selectedRows.map(block => block.id);
    const childBlocks = alteredBlocks.filter(block => tradeBlockIds.includes(block.tradeAwayBlockId));
    return [...selectedRows, ...childBlocks];
  }

  static getBlockData(params: ICellRendererParams<ITradeBlock> | CellValueChangedEvent<ITradeBlock> | RowClickedEvent<ITradeBlock> | ValueGetterParams<ITradeBlock> | GetContextMenuItemsParams<ITradeBlock>): ITradeBlock {
    return params?.node?.group ? params.node.aggData : params?.node?.data;
  }

  static calculateCumQty(orderQty: number, totalCumQty: number, index: number, totalBlocks: number): number {
    let cumQty = TradeBlockHelper.rounding(totalCumQty / (totalBlocks - index));
    cumQty = cumQty > orderQty ? orderQty : cumQty;
    return cumQty;
  }

  static updateChildrenBlockDataOfTradeAwayBlock(event: CellValueChangedEvent<ITradeBlock> | ICellRendererParams<ITradeBlock>, fieldName: string): void {
    const block = TradeBlockHelper.getBlockData(event);
    const childBlocks = TradeBlockHelper.getChildBlocks(event, block.id);
    if (block.isTradeAwayBlock && childBlocks?.length) {
      if (event.colDef.field === 'cumQty') {
        TradeBlockHelper.updateCumQty(block, childBlocks);
      } else {
        TradeBlockHelper.updateChildrenData(block, childBlocks, fieldName);
      }
      event.api.applyTransactionAsync({ update: childBlocks });
    }
  }

  static updateCumQty(block: ITradeBlock, childBlocks: ITradeBlock[]): void {
    childBlocks = _.sortBy(childBlocks, 'orderQty');
    let totalCumQty = Number(block.cumQty);
    const totalBlocks = childBlocks.length;
    for (const [index, childrenBlock] of childBlocks.entries()) {
      childrenBlock.cumQty = TradeBlockHelper.calculateCumQty(childrenBlock.orderQty, totalCumQty, index, totalBlocks);
      childrenBlock.leavesQty = TradeBlockHelper.rounding(childrenBlock.orderQty - childrenBlock.cumQty);
      totalCumQty = TradeBlockHelper.rounding(totalCumQty - childrenBlock.cumQty);
    }
  }

  static rounding(value: number): number {
    return Number(value.toFixed(constants.TRADE_ORDER_DECIMAL_PLACE.SHARE_PLACE));
  }

  static updateChildrenData(block: ITradeBlock, childBlocks: ITradeBlock[], columnName: string): void {
    for (const childrenBlock of childBlocks) {
      childrenBlock[columnName] = block[columnName];
    }
  }

  static getTradeAwayBlockTradeOrderQty(blocks: ITradeBlock[], tradeAwayBlockId: number): number {
    return _.sumBy(blocks.filter(block => block.tradeAwayBlockId === tradeAwayBlockId), 'tradeOrderQty');
  }

  static updateTradeAwayColumnState(columnStates: ColumnState[]): ColumnState[] {
    const columnState = columnStates.find(column => column.colId === 'tradeAwayBlockId');
    if (columnState) {
      columnState.rowGroup = true;
      columnState.rowGroupIndex = 0;
    } else {
      columnStates.push({
        colId: 'tradeAwayBlockId',
        rowGroup: true,
        rowGroupIndex: 0
      });
    }
    return columnStates;
  };

  static processCellForExport(cell: ProcessCellForExportParams): string {
    const block = cell.node.aggData || cell.node.data;
    const colDef = cell.column.getColDef();
    const colId = cell.column.getColId();
    const fieldValue = block?.[colDef.field];

    if (TradeBlockHelper.isDateTimeType(colDef.field)) {
      return TradeBlockHelper.getFormattedDateTime(cell.value);
    }

    if (block?.isTradeAwayBlock && colId === 'ag-Grid-AutoColumn') {
      return cell.value;
    }

    if (typeof fieldValue === 'object') {
      return fieldValue?.name || '';
    }

    return _.get(block, colDef.field, '');
  }

  static hasMatchingOrderAndAllocationStatus(block: ITradeBlock, status: string): boolean {
    const orderStatus = block?.orderStatus?.name?.toLowerCase() || block?.orderStatus?.toString()?.toLowerCase();
    const allocationStatus = block?.allocationStatus?.name?.toLowerCase() || block?.allocationStatus?.toString()?.toLowerCase();
    return orderStatus === status && allocationStatus === status;
  }

  static statusRenderer(params: ICellRendererParams<ITradeBlock>): string {
    const block = TradeBlockHelper.getBlockData(params);
    let result = '<span>';
    if (block && (!block.tradeAwayBlockId || params.colDef.field !== 'isAutoAllocate')) {
      const value = block[params.colDef.field];
      if (value) {
        result += '<i class="fas fa-check-circle text-success" aria-hidden="true"></i>';
      } else {
        result += '<i class="fas fa-times-circle text-danger" aria-hidden="true"></i>';
      }
    }
    return `${result}</span>`;
  }

  static AddFixMessageMenuItem(block: ITradeBlock, contextResult: MenuItemDef[], displayFixMessages: boolean, fixMessagesComponent: FixMessagesComponent): void {
    const id = block.tradeAwayBlockId ? block.tradeAwayBlockId : block.id;
    contextResult.push({
      name: 'FIX Messages',
      icon: '<i class="fas fa-list-alt" aria-hidden="true"></i>',
      action: () => {
        displayFixMessages = true;
        fixMessagesComponent.getAllFixMessages(id);
      }
    });
  }

  static AddEventListeners(eCell: HTMLDivElement, eSelect: HTMLSelectElement, eLabel: Text, editing: boolean): void {
    eCell.addEventListener('click', () => {
      if (!editing) {
        eCell.removeChild(eLabel);
        eCell.appendChild(eSelect);
        eSelect.focus();
        editing = true;
      }
    });

    eSelect.addEventListener('blur', () => {
      if (editing) {
        editing = false;
        eCell.removeChild(eSelect);
        eCell.appendChild(eLabel);
      }
    });
  }

  static setMassEditGridOptions(blockGridRowCount: number): GridOptions {
    return {
      statusBar: {
        statusPanels: [
          {
            key: 'customStatusBar',
            statusPanel: MassEditStatusBarComponent,
            align: 'left',
            statusPanelParams: {
              totalRowsInBlockGrid: blockGridRowCount
            }
          }
        ],
      },
      defaultColDef: {
        cellStyle: { textAlign: 'center' },
        resizable: true,
        sortable: true,
        floatingFilter: false,
        filter: 'agTextColumnFilter',
        aggFunc: 'sum',
        allowedAggFuncs: ['sum', 'avg', 'min', 'max']
      },
      suppressContextMenu: true
    };
  }

  static isOrderStatus(gridRow: ITradeBlock, status: string): boolean {
    const statusName = gridRow.orderStatus.name.toLowerCase();
    return (statusName === status);
  }

  static isOrderStatusSentAndNotFilled(tradeBlock: ITradeBlock) {
    return TradeBlockHelper.isOrderStatus(tradeBlock, constants.PENDINGNEW) || TradeBlockHelper.isOrderStatus(tradeBlock, constants.PARTIALLYFILLED)
      || TradeBlockHelper.isOrderStatus(tradeBlock, constants.PENDINGREPLACE) || TradeBlockHelper.isOrderStatus(tradeBlock, constants.SENT);
  }

  static isBlockEditable(block: ITradeBlock): boolean {
    const editableStatuses = [constants.NOTSENT, constants.PENDINGNEW, constants.PENDINGCANCEL, constants.PENDINGREPLACE, constants.SENT, constants.PARTIALLYFILLED];
    return editableStatuses.some(status => TradeBlockHelper.isOrderStatus(block, status));
  }

  static areBlocksActionsAndTickersSame(blocks: ITradeBlock[]): boolean {
    return blocks.every(block => TradeBlockHelper.isBlockEditable(block) &&
      block.action?.id === blocks[0].action?.id &&
      block.security?.symbol === blocks[0].security?.symbol);
  }

  static isEditContextMenuOptionVisible(selectedRows: ITradeBlock[], massBlockEditingFeatureFlag: boolean): boolean {
    return (selectedRows.length === 1 && TradeBlockHelper.isBlockEditable(selectedRows[0])) ||
      (massBlockEditingFeatureFlag && selectedRows.length > 1);
  }

  private static setBlockValue(params: ICellRendererParams<ITradeBlock>, value: string | number): void {
    const block = TradeBlockHelper.getBlockData(params);
    if (block) {
      block[params.colDef.field] = value;
    }
  }

  private static getChildBlocks(event: CellValueChangedEvent<ITradeBlock> | ICellRendererParams<ITradeBlock>, tradeAwayBlockId: number): ITradeBlock[] {
    const childBlocks = [];

    event.api.forEachNode(node => {
      if (node.data?.tradeAwayBlockId === tradeAwayBlockId || node?.aggData?.tradeAwayBlockId === tradeAwayBlockId) {
        childBlocks.push(node.data ?? node.aggData);
      }
    });
    return childBlocks;
  }

  private static isDateTimeType(field: string): boolean {
    return field === 'createdDate' || field === 'editedDate' || field === 'optionMaturityDate' || field === 'transactionTime'
      || field === 'expireTime' || field === 'fullSettDate' || field === 'maturityDate';
  }

  static getSftpBlockIds(blockIds: number[], blocks: ITradeBlock[]): number[] {
    const tradeIds = [];
    _.forEach(blockIds, function (id: number) {
      const found = blocks.find(block => block?.id === id);
      // return sftp objects
      if (found?.tradeExecutionTypeId === constants.TRADE_EXECUTION_TYPE.Pending_Cancel) {
        tradeIds.push(id);
      }
    });

    return tradeIds;
  }

  static getNonSftpBlockIds(blockIds: number[], blocks: ITradeBlock[]): number[] {
    const tradeIds = [];
    _.forEach(blockIds, function (id: number) {
      const found = blocks.find(block => block?.id === id);
      // return non-sftp objects
      if (found?.tradeExecutionTypeId !== constants.TRADE_EXECUTION_TYPE.Pending_Cancel) {
        tradeIds.push(id);
      }
    });
    return tradeIds;
  }
}
