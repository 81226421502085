import { Component, Output, EventEmitter } from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {TradeToolsService} from '../../services/tradetools.service';

@Component({
  selector: 'eclipse-trade-groups-portfolio-auto-complete',
  templateUrl: './tradegroupsportfolio.autocomplete.component.html'
})
export class TradeGroupsPortfolioAutoCompleteComponent extends BaseComponent {

  suggestions: any[] = [];
  selectedItem: string;

  @Output() parentCallback = new EventEmitter();
  constructor(private _tradeToolsService: TradeToolsService) {
    super();
  }

  /** To get trade groups portfolios for search autoComplete  */
  loadSuggestions(event) {
    this.suggestions = [];
    this.suggestions = this._tradeToolsService.getTradeGroupsPortfolios();
  }

  /** Fires on item select */
  onTrageGroupsPortfolioSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({ value: this.selectedItem });
    // this._childSubscriber.process.emit({value: this.selectedItem});
  }

}
