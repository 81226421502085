export enum PreferenceEnums {
  PriorityRankingSortedlist,
  TaxLotDepletionMethodSortedlist,
  LocationOptimizationDataGrid,
  CommunityStrategistEnabledListCascadingControl,
  securities,
  Dropdown,
  Textbox,
  Checkbox,
  Firm,
  Custodian,
  Team,
  Model,
  Portfolio,
  Account,
  communityStrategist,
  locationOptimization,
  Default,
  Custom,
  sortedlist,
  master,
  SecurityDataGrid,
  securityPreference,
  RedemptionFeeDataGrid,
  redemptionFeePreference,
  CustodianRedemptionFeeDataGrid,
  custodianRedemptionFeePreference,
  TransactionFeeDataGrid,
  transactionFeePreference,
  TradeMinDataGrid,
  tradeMinPreference,
  TradeMaxDataGrid,
  tradeMaxPreference,
  dividendReinvestPreference,
  DividendReinvestDataGrid,
  capitalGainReinvestPreference,
  CapitalGainReinvestDataGrid,
  tradeSettingsPreference, /**23-6-17:: Trade Settings Preference */
  TradeSettingsDataGrid, /**23-6-17:: Trade Settings Preference */
  defaultPrimaryTeamPreference, /** 7-8-17:: Default Primary Team preference */
  DefaultPrimaryTeamDropdownSearch, /** 7-8-17:: Default Primary Team preference */
  SystematicMultiplierLink,
  BillingCashMultiplierLink,
  MoneyMarketAllocationGrid,
  MoneyMarketFundsGrid
}

export enum BuyPriority{
  DoNotBuy = 1,
  HardToBuy = 2,
  CanBuy = 3,
  BuyToTarget = 4,
  PriorityBuy = 5,
  PreferredIfHeld = 6
}

export enum SellPriority {
  DoNotSell = 1,
  HardToSell = 2,
  SellIfNoGain = 3,
  CanSell = 4,
  SellToTarget = 5,
  PrioritySell = 6,
  AlwaysSellAll = 7
}

export enum PREFERENCE_NAME {
  ALLOW_WASH_SALES = 'allowWashSales',
  ALLOW_WASH_SALES_ON_100_PERCENT_SELL = 'allowWashSalesOnA100PercentSell',
  ALLOW_WASH_SALES_UPTO_X_PERCENT_OF_TRADE_AMOUNT = 'allowWashSalesUpToXPercentOfTradeAmount',
  LOWEST_ESTIMATED_TAX = 'lowestEstimatedTax',
  MONEY_MARKET_TRADING = 'moneyMarketTrading',
  MONEY_MARKET_ALLOCATION = 'moneyMarketAllocation',
  MONEY_MARKET_FUNDS = 'moneyMarketFunds',
  ASTRO_INVESTOR_PREFERENCES = 'originalAstroInvestorPreferences'
}

export enum EntityType {
  Firm = 1,
  Custodian = 2,
  Team = 4,
  Model = 8,
  SecuritySet = 12,
  Portfolio = 16,
  Account = 32
};
