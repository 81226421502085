import {Injectable} from '@angular/core';
import {OEHttpClient} from '../core';
import {
  IComplianceTradeRequest,
  IComplianceTradeUpdateRequest,
  ITradeReviewRequest
} from '../models/compliance';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  private _complianceBaseUrl = 'v2/TradeOrder/Compliance';

  constructor(private readonly _httpClient: OEHttpClient) {
  }

  complianceTradeReview(complianceTradeReviewRequest: IComplianceTradeRequest<ITradeReviewRequest>): Observable<any> {
    return this._httpClient.postData(`${this._complianceBaseUrl}/Review`, complianceTradeReviewRequest);
  }

  complianceTradeUpdate(trades: IComplianceTradeRequest<IComplianceTradeUpdateRequest>, type: string): Observable<any> {
    return this._httpClient.postData(`${this._complianceBaseUrl}/${type}`, trades);
  }
}
