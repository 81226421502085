import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

/**
 * Eclipse route reuse strategy.  Provides a way to customize when activated routes get reused.
 */
export class EclipseRouteReuseStrategy implements RouteReuseStrategy {
  private routeStore = new Map<string, DetachedRouteHandle>();

  private routePartsToReuse: string[] = [
    'tradetool'
  ];

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig.path;
    return path && this.routePartsToReuse.includes(path);
  }
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.routeStore.set(route.routeConfig.path, handle);
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig.path;
    return (
      path && this.routePartsToReuse.includes(path) && !!this.routeStore.get(path)
    );
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path = route.routeConfig.path;
    return this.routeStore.get(path);
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // If not implicitly defining the route reuse option, default to the behaviour
    // of reuse when navigating to the exact same route
    if (future.data.shouldReuseRoute === undefined) {
      return future.routeConfig === curr.routeConfig;
    } else { // If defining the route reuse option, reuse only if the config allows it
      // and the current and future routes use the same path.
      const isSamePath = curr.routeConfig?.path === future.routeConfig?.path;
      return !!future.data.shouldReuseRoute && isSamePath;
    }
  }
}
