<div class="firm-security-setting-header">
  <div class="form-inline">
    <div class="form-group" [hidden]="!canEdit">
        <span class="add-button">Add New Allocation
          <a href="javascript:void(0)" (click)="addAllocation()"><i class="fas fa-plus-circle"
                                                                    aria-hidden="true"></i></a>
        </span>
    </div>

    <div class="grid-control">
      <ag-grid-angular style="width: 100%; height: 150px;" class="ag-theme-alpine"
                       [gridOptions]="gridOptions"
                       rowSelection="single"
                       [columnDefs]="columnDefinitions"
                       [rowData]="moneyMarketAllocationPreference.moneyMarketAllocations"
                       [context]="gridContext"
                       [getContextMenuItems]="getContextMenuItems"
                       (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
<p-dialog header="Money Market Allocation" modal="true" [resizable]="false" [(visible)]="isShowEditPreferencePopup"
          class="ui-dialog-xl" (onHide)="cancel()">
  <div class="col-sm-12 security-preferences-modal-body">
    <div class="row">
      <fieldset class="col-md-12 preference-fieldset">
        <legend>Money Market Allocation</legend>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>From(%)</label>
              <input [(ngModel)]="moneyMarketAllocation.moneyMarketCashValueFrom" class="form-control" [min]="0"
                     (keyup)="validatePercent('moneyMarketCashValueFrom')"
                     (keypress)="checkPercentage($event, moneyMarketAllocation.moneyMarketCashValueFrom)">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label>To(%)</label>
              <input [(ngModel)]="moneyMarketAllocation.moneyMarketCashValueTo" class="form-control" [min]="0"
                     (keyup)="validatePercent('moneyMarketCashValueTo')"
                     (keypress)="checkPercentage($event, moneyMarketAllocation.moneyMarketCashValueTo)">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>MM Min(%)</label>
              <input [(ngModel)]="moneyMarketAllocation.moneyMarketMinValue" class="form-control" [min]="0"
                     [max]="100"
                     (keyup)="validatePercent('moneyMarketMinValue')"
                     (keypress)="checkPercentage($event, moneyMarketAllocation.moneyMarketMinValue)">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label>MM Target(%)</label>
              <input [(ngModel)]="moneyMarketAllocation.moneyMarketTargetValue" class="form-control" [min]="0"
                     [max]="100"
                     (keyup)="validatePercent('moneyMarketTargetValue')"
                     (keypress)="checkPercentage($event, moneyMarketAllocation.moneyMarketTargetValue)">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label>MM Max(%)</label>
              <input [(ngModel)]="moneyMarketAllocation.moneyMarketMaxValue" class="form-control" [min]="0"
                     [max]="100"
                     (keyup)="validatePercent('moneyMarketMaxValue')"
                     (keypress)="checkPercentage($event, moneyMarketAllocation.moneyMarketMaxValue)">
            </div>
          </div>
        </div>
      </fieldset>
    </div>

  </div>
  <p-footer>
      <span class="text-danger" *ngIf="isShowErrorMessage">
                {{errorMessage}}
              </span>
    <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="cancel()"
            label="Cancel"></button>
    <button type="button" pButton icon="fas fa-check" (click)="addToGrid()"
            label="Add"></button>
  </p-footer>
</p-dialog>
