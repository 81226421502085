import {Component, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../core/base.component';
import {IAutoSelectCustodian} from '../../../models/custodian';
import {CustodianService} from '../../../services/custodian.service';
import {AutocompleteHubService} from '../shared/autocomplete-hub.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'eclipse-custodian-autocomplete',
  templateUrl: './custodian-autocomplete.component.html',
  styleUrls: ['./custodian-autocomplete.component.scss']
})
export class CustodianAutocompleteComponent extends BaseComponent {
  custodianList: IAutoSelectCustodian[] = [];
  custodianFilterList: IAutoSelectCustodian[] = [];
  selectedCustodian: IAutoSelectCustodian;
  private clearAutoSearchDataSubscription: Subscription;

  constructor(private readonly _custodianService: CustodianService,
              private readonly _autocompleteHubService: AutocompleteHubService) {
    super();
  }

  ngOnInit(): void {
    this.getCustodiansData();
    this.subscribeClearAutoSearchData();
  }

  ngOnDestroy(): void {
    this.clearAutoSearchDataSubscription.unsubscribe();
  }

  private subscribeClearAutoSearchData(): void {
    this.clearAutoSearchDataSubscription = this._autocompleteHubService.clearSearchData.subscribe(() => {
      this.selectedCustodian = null;
    });
  }

  private getCustodiansData(): void {
    this._custodianService.getCustodians(true)
      .subscribe((model: IAutoSelectCustodian[]) => {
        this.custodianList = model;
        this.custodianList.push({id: -1, name: 'ALL'});
      });
  }

  autoCustodianSearch(event: any): void {
    const query = event.query;
    this.custodianFilterList = [];
    this.custodianList.forEach(data => {
      if (data.name.toLowerCase().match(`^.*${query.toLowerCase()}.*$`) || Number(data.id) === Number(query)) {
        this.custodianFilterList.push({id: data.id, name: data.name});
      }
    });
  }

  onCustodianSelect(item: IAutoSelectCustodian): void {
    this._autocompleteHubService.requestToGetSelectedCustodian(item);
  }
}
