import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../core/base.component';
import { IHiddenSecurityPreference, ISecurityPreference } from '../../../../models/preferences/securityPreference';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { CustomNoRowsOverlay } from '../../../gridextensions/custom-no-rows-overlay';
import * as Const from '../../../../libs/app.constants';
import { ISecurity, ISellBuyPriorityMap } from '../../../../models/security';
import { SecurityService } from '../../../../services/security.service';
import { ITlh } from '../../../../models/tlh';
import { BuyPriority, SellPriority } from '../../../../libs/preference.enums';
import { AstroHoldingRestrictionType } from '../../../../models/astro';
import { SecurityPreferenceHelper } from '../security-preference-helper';
import { SecurityPreferenceHubService } from '../security-preference-hub.service';
import { ICustodian } from '../../../../models/custodian';
import { Utils } from '../../../../core/functions';
import { Subscription } from 'rxjs';
import {
  SecurityPreferenceEquivalentComponent
} from '../security-preference-equivalent/security-preference-equivalent.component';
import { AutocompleteHubService } from '../../../../shared/search/shared/autocomplete-hub.service';
import { ICompleteMethodEvent } from '../../../../models/event';
import { SECURITY_STATUS } from '../../../../libs/security.constants';

@Component({
  selector: 'eclipse-security-preference-add-edit',
  templateUrl: './security-preference-add-edit.component.html',
  styleUrls: ['./security-preference-add-edit.component.scss']
})
export class SecurityPreferenceAddEditComponent extends BaseComponent {
  @Input() preferenceLevel;
  @Input() securityPreferencesHiddenMap: IHiddenSecurityPreference;
  @Output() addPreferenceToGrid = new EventEmitter<ISecurityPreference>();
  securityPreference: ISecurityPreference;
  displaySecPref: boolean = false;
  canShow: boolean = true;
  isDetailEditMode: boolean = true;
  private gridApiAlternative: GridApi;
  private gridApiTLH: GridApi;
  gridTlhOptions: GridOptions;
  tlhColumnDefs: ColDef[];
  alternativeGridOptions: GridOptions;
  columnAlternativeDefs: ColDef[];
  type: string = '$';
  custodianFeeType: string = '$';
  selectedAlternativeCustodian: any;
  alternatives: any[] = [];
  selectedDeferred: any;
  selectedTax: any;
  selectedExempt: any;
  showAlternativeErrMsg: boolean;
  errAlternativeMessage: string;
  showErrMsg: boolean;
  errMessage: string;
  tlhList: ITlh[] = [];
  selectedTlhSecurity: any;
  custodianAlternativeFilterList: any[] = [];
  custodianList: any[] = [];
  custodianFilterList: any[] = [];
  selectedCustodian: any;
  filteredTlhSecurityResult: ISecurity[];
  btnDisableSecurity: boolean;
  showAstroMinMaxFields = false;
  yesOrNOoData: any[] = [];
  prevSellPriority: number;
  buyPriorityList: any[] = [];
  sellPriorityList: any[] = [];
  taxableSellPriorityList: any[] = [];
  taxableBuyPriorityList: any[] = [];
  ALWAYS_SELL_ALL = Const.sellPriority.ALWAYS_SELL_ALL;
  @ViewChild(SecurityPreferenceEquivalentComponent) securityPreferenceEquivalentComponent: SecurityPreferenceEquivalentComponent;
  private sellAndBuyPrioritySubscription: Subscription;
  private taxableSellAndBuyPrioritySubscription: Subscription;
  private custodianSubscription: Subscription;

  constructor(private readonly _securityService: SecurityService,
    private readonly _securityPreferenceHubService: SecurityPreferenceHubService,
    private readonly _autoCompleteHubService: AutocompleteHubService) {
    super();
    this.gridTlhOptions = <GridOptions>{
      ...this.defaultGridOptions,
      suppressContextMenu: true,
      noRowsOverlayComponent: CustomNoRowsOverlay,
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: () => this.getNoRowsMessage('TLH Alternate'),
      }
    };
    this.alternativeGridOptions = <GridOptions>{
      ...this.defaultGridOptions,
      suppressContextMenu: true,
      noRowsOverlayComponent: CustomNoRowsOverlay,
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: () => this.getNoRowsMessage('Tax Alternates'),
      }
    };
    this.securityPreference = <ISecurityPreference>{
      taxableDivReInvest: null,
      taxDefDivReinvest: null,
      taxExemptDivReinvest: null,
      capGainReinvestTaxable: null,
      capGainsReinvestTaxDef: null,
      capGainsReinvestTaxExempt: null,
      excludeHolding: null,
      sellPriority: null,
      buyPriority: null,
      tBuyPriority: null,
      tSellPriority: null,
      teBuyPriority: null,
      teSellPriority: null,
      tdBuyPriority: null,
      tdSellPriority: null,
      doNotTLH: null,
      coveredCallPercent: null,
      protectivePutPercent: null
    };
  }

  private static deleteAlternativeCellRenderer(params) {
    let result = '<span>';
    result += `<i class="fas fa-times red cursor" aria-hidden="true" id =${params.node.data.id} value=${params.node.data.id
    } custodianId =${params.node.data.custodianId} title="Delete Alternative"></i>`;
    return result;
  }

  private static deleteCellRenderer(params) {
    let result = '<span>';
    result += `<i class="fas fa-times red cursor" aria-hidden="true" id =${params.node.data.id} value=${params.node.data.id} custodianId =${params.node.data.custodianId} title="Delete TLH"></i>`;
    return result;

  }

  private static tlhRankEditor(params, self): HTMLInputElement | string {
    if (self.isDetailEditMode) {
      return SecurityPreferenceAddEditComponent.rankEditorRenderer(params);
    } else {
      return params.data[params.colDef.field];
    }
  }

  private static rankEditorRenderer(params): HTMLInputElement {
    const eInput = document.createElement('input');
    eInput.className = 'form-control grid-input';

    // eslint-disable-next-line eqeqeq
    eInput.value = params.data[params.colDef.field] == undefined ? '' : params.data[params.colDef.field];

    eInput.addEventListener('blur', function () {
      if (eInput.value === '0') {
        eInput.value = '';
      }
      params.data[params.colDef.field] = isNaN(parseFloat(eInput.value)) ? '' : parseFloat(eInput.value);
    });

    eInput.addEventListener('keypress', function (event) {
      const result = Utils.isNumericKey(event, false);

      if (result.valid) { // if 0-9
        if (result.numericValue === 0) { // if 0
          if (event.currentTarget['value'] === undefined || event.currentTarget['value'] === null || event.currentTarget['value'] === '') { // if value is empty
            event.preventDefault();
            return false;
          }
        }
      } else {
        event.preventDefault();
        return false;
      }
    });

    return eInput;
  }

  private static hasDuplicatePriorities(tlhSecurities) {
    const valuesSoFar = [];
    for (let i = 0; i < tlhSecurities.length; ++i) {
      const value = tlhSecurities[i].priority;
      if (valuesSoFar.indexOf(value) !== -1) {
        return true;
      }
      valuesSoFar.push(value);
    }
    return false;
  }

  ngOnInit(): void {
    this.getYesOrNoOptions();
    this.createAlternativeGrid();
    this.getPriorities();
    this.getCustodians();
    if (this.gridApiAlternative) {
      this.gridApiAlternative.setGridOption('columnDefs', this.columnAlternativeDefs);
    }
    this.createTlhGrid();
    if (this.gridApiTLH) {
      this.gridApiTLH.setGridOption('columnDefs', this.tlhColumnDefs);
    }
  }

  ngOnDestroy(): void {
    this.sellAndBuyPrioritySubscription?.unsubscribe();
    this.custodianSubscription?.unsubscribe();
    this.taxableSellAndBuyPrioritySubscription?.unsubscribe();
  }

  onAlternativeGridReady(event: GridReadyEvent) {
    this.gridApiAlternative = event.api;
  }

  onTLHGridReady(event: GridReadyEvent) {
    this.gridApiTLH = event.api;
  }

  /**
   * Redemption Fee Type Change event
   */
  onFeeTypeChange(event) {
    if (event.srcElement.value === '%') {
      this.type = '%';
      if (this.securityPreference.redemptionFeeAmount !== undefined && this.securityPreference.redemptionFeeAmount !== ''
        && this.securityPreference.redemptionFeeAmount > 100) {
        this.securityPreference.redemptionFeeAmount = 100;
      }
    } else {
      this.type = '$';
    }

  }

  /**
   * Custodian Redemption Fee Type Change event
   */
  onCustodianFeeTypeChange(event) {
    if (event.srcElement.value === '%') {
      this.custodianFeeType = '%';
      if (this.securityPreference.custodianRedemptionFeeAmount !== undefined && this.securityPreference.custodianRedemptionFeeAmount !== ''
        && this.securityPreference.custodianRedemptionFeeAmount > 100) {
        this.securityPreference.custodianRedemptionFeeAmount = 100;
      }
    } else {
      this.custodianFeeType = '$';
      this.securityPreference.custodianRedemptionFeeMaxAmount = null;
      this.securityPreference.custodianRedemptionFeeMinAmount = null;
    }
  }

  /** Handle the special characters */
  setValid(event) {
    return !(event.key.toLowerCase() === 'e' || event.key === '+' || event.key === ',' || event.key === '/'
      || event.key === '?' || event.key === '*');
  }

  validatePercent(value: ISecurityPreference): void {
    if (value.redemptionFeeAmount > 100) {
      this.securityPreference.redemptionFeeAmount = 100;
    } else if (value.sellTradeMinPctBySecurity > 100) {
      this.securityPreference.sellTradeMinPctBySecurity = 100;
    } else if (value.buyTradeMinPctBySecurity > 100) {
      this.securityPreference.buyTradeMinPctBySecurity = 100;
    } else if (value.sellTradeMaxPctBySecurity > 100) {
      this.securityPreference.sellTradeMaxPctBySecurity = 100;
    } else if (value.buyTradeMaxPctBySecurity > 100) {
      this.securityPreference.buyTradeMaxPctBySecurity = 100;
    } else if (value.custodianRedemptionFeeAmount > 100) {
      this.securityPreference.custodianRedemptionFeeAmount = 100;
    }
  }

  /**
   * Taxable Alternate on selection changed event
   */
  handleSelectedTax(tax) {
    this.selectedTax = tax;
  }

  /**
   * TTax Exempt on selection changed event
   */
  handleSelectedExempt(exempt) {
    this.selectedExempt = exempt;
  }

  /**
   * TTax Deferred on selection changed event
   */
  handleSelectedDeferred(deferred) {
    this.selectedDeferred = deferred;
  }

  /*Auto search for custodian*/
  autoCustodianSearch(event, type) {
    const query = event.query;
    this.custodianFilterList = [];
    this.custodianAlternativeFilterList = [];
    this.custodianList.forEach(data => {
      // eslint-disable-next-line eqeqeq, prefer-template
      if (data.name.toLowerCase().match('^.*' + query.toLowerCase() + '.*$') || data.id == query) {
        // eslint-disable-next-line eqeqeq
        if (type == 'TLH') {
          this.custodianFilterList.push({id: data.id, name: data.name});
          // eslint-disable-next-line eqeqeq
        } else if (type == 'Alternate') {
          this.custodianAlternativeFilterList.push({id: data.id, name: data.name});
        }
      }
    });
  }

  autoTlhSecuritySearch(event: ICompleteMethodEvent): void {
    this._securityService.searchSecurityFromOrionEclipse(event.query, SECURITY_STATUS.OPEN, false)
      .subscribe((securitiesResult: ISecurity[]) => {
        this.filteredTlhSecurityResult = securitiesResult;
        this.filteredTlhSecurityResult = this.filteredTlhSecurityResult.filter(record => record.id !== this.securityPreference.id);
      });
  }

  handleTlhSelectedSecurity(security: ISecurity): void {
    this.selectedTlhSecurity = security;
    this.btnDisableSecurity = !security.name;
  }

  /*Add tlh security in grid*/
  addTlh(security) {
    if (!this.selectedCustodian?.id) {
      this.selectedCustodian = {id: -1, name: 'ALL'};
    }
    let isDataAlready;
    if (this.tlhList) {
      isDataAlready = this.tlhList.find(data => data.id === security.id && data.custodianId === this.selectedCustodian.id);
    } else {
      this.tlhList = [];
    }

    if (!isDataAlready) {
      const tlh = <ITlh>{};
      tlh.custodian = this.selectedCustodian.name;
      tlh.custodianId = this.selectedCustodian.id;
      tlh.id = security.id;
      tlh.name = security.name;
      tlh.symbol = security.symbol;
      tlh.securityType = security.securityType;

      this.tlhList.push(tlh);

      this.gridApiTLH.setGridOption('rowData', this.tlhList);
      this.gridApiTLH.sizeColumnsToFit();
      this.resetAutocomplete();
    } else {
      this.errMessage = 'Security with same Custodian and Account Type is already present';
      this.showErrMsg = true;
    }
  }

  addToAlternativeGrid() {
    if (!(this.selectedAlternativeCustodian && this.selectedAlternativeCustodian.id)) {
      this.selectedAlternativeCustodian = {id: -1, name: 'ALL'};
    }
    const alternative = this.alternatives.find(element => element.id === this.securityPreference.id
      && element.custodianId === this.selectedAlternativeCustodian.id);
    const isAlreadyPresent = !!alternative;
    const haveAlternative = (this.selectedTax && this.selectedTax.id)
      || (this.selectedExempt && this.selectedExempt.id)
      || (this.selectedDeferred && this.selectedDeferred.id);

    if (!isAlreadyPresent && haveAlternative) {
      const newAlternative = {
        id: this.securityPreference.id,
        symbol: this.securityPreference.symbol,
        custodianId: this.selectedAlternativeCustodian ? this.selectedAlternativeCustodian.id : -1,
        custodian: this.selectedAlternativeCustodian ? this.selectedAlternativeCustodian.name : 'ALL',
        tSecurity: (this.selectedTax && this.selectedTax.id) ? this.selectedTax : null,
        tdSecurity: (this.selectedDeferred && this.selectedDeferred.id) ? this.selectedDeferred : null,
        teSecurity: (this.selectedExempt && this.selectedExempt.id) ? this.selectedExempt : null
      };
      this.alternatives.push(newAlternative);
      this.gridApiAlternative.setGridOption('rowData', this.alternatives);
      this.gridApiAlternative.sizeColumnsToFit();
      this.reset();
    } else {
      this.showError(isAlreadyPresent, haveAlternative);
      this.selectedAlternativeCustodian = null;
    }
  }

  validateAstroMinMaxPercents(value: ISecurityPreference, field: string) {
    if (value.astroRestrictionMin < 0) {
      this.securityPreference.astroRestrictionMin = 0;
    } else if (value.astroRestrictionMin > 100) {
      this.securityPreference.astroRestrictionMin = 100;
    }

    if (value.astroRestrictionMax < 0) {
      this.securityPreference.astroRestrictionMax = 0;
    } else if (value.astroRestrictionMax > 100) {
      this.securityPreference.astroRestrictionMax = 100;
    }

    if (value.astroRestrictionMin > value.astroRestrictionMax) {
      if (field === 'min') {
        this.securityPreference.astroRestrictionMin = this.securityPreference.astroRestrictionMax;
      } else {
        this.securityPreference.astroRestrictionMax = this.securityPreference.astroRestrictionMin;
      }
    }
  }

  validateMinAmount(value) {
    // eslint-disable-next-line eqeqeq
    if (value.custodianRedemptionFeeMaxAmount != null && value.custodianRedemptionFeeMinAmount > value.custodianRedemptionFeeMaxAmount) {
      this.securityPreference.custodianRedemptionFeeMinAmount = value.custodianRedemptionFeeMaxAmount;
    }
  }

  validateMaxAmount(value) {
    // eslint-disable-next-line eqeqeq
    if (value.custodianRedemptionFeeMinAmount != null && value.custodianRedemptionFeeMaxAmount < value.custodianRedemptionFeeMinAmount) {
      this.securityPreference.custodianRedemptionFeeMaxAmount = value.custodianRedemptionFeeMinAmount;
    }
  }

  setDropdownSelectedOption(event, taxexemeptname) {
    // eslint-disable-next-line eqeqeq
    if (event == '0: null' || event == 'null') {
      switch (taxexemeptname) {

        case 'taxExemptDivReinvest':
          this.securityPreference.taxExemptDivReinvest = null;
          break;

        case 'taxDefDivReinvest':
          this.securityPreference.taxDefDivReinvest = null;
          break;

        case 'taxableDivReInvest':
          this.securityPreference.taxableDivReInvest = null;
          break;

        case 'capGainReinvestTaxable':
          this.securityPreference.capGainReinvestTaxable = null;
          break;

        case 'capGainsReinvestTaxDef':
          this.securityPreference.capGainsReinvestTaxDef = null;
          break;

        case 'capGainsReinvestTaxExempt':
          this.securityPreference.capGainsReinvestTaxExempt = null;
          break;

        case 'excludeHolding':
          this.securityPreference.excludeHolding = null;
          break;

        case 'sellPriority':
          this.securityPreference.sellPriority = null;
          break;

        case 'buyPriority':
          this.securityPreference.buyPriority = null;
          break;
      }
    }

    switch (taxexemeptname) {
      case 'sellPriority':
      case 'buyPriority':
        this.updateAstroRestriction(this.securityPreference);
        this.setShowAstroMinMaxField();
        break;
    }
  }

  sellPriorityChange(value) {
    if (this.prevSellPriority === this.ALWAYS_SELL_ALL) {
      this.securityPreference.buyPriority = null;
    }
    if (value === 'null') {
      this.securityPreference.sellPriority = null;
    } else if (value === this.ALWAYS_SELL_ALL) {
      this.securityPreference.buyPriority = Const.buyPriority.DO_NOT_BUY;
    }
    this.prevSellPriority = this.securityPreference.sellPriority;

    this.updateAstroRestriction(this.securityPreference);
    this.setShowAstroMinMaxField();
  }

  updateAstroRestriction(securityPreference: ISecurityPreference) {
    if (this.preferenceLevel !== 'Account' && this.preferenceLevel !== 'Portfolio') {
      return;
    }

    const sellPriority = securityPreference.sellPriority as SellPriority | null;
    const buyPriority = securityPreference.buyPriority as BuyPriority | null;

    /*
      -999 is a magic number used by ASTRO for ASTRO restrictions
      on the securities.
    */

    //Reset the Min and Max if they are null or the magic ASTRO number of -999
    if (securityPreference.astroRestrictionMin === -999 || securityPreference.astroRestrictionMin === null) {
      securityPreference.astroRestrictionMin = 0;
    }
    if (securityPreference.astroRestrictionMax === -999 || securityPreference.astroRestrictionMax === null) {
      securityPreference.astroRestrictionMax = 0;
    }

    if (sellPriority === SellPriority.AlwaysSellAll) {
      //Set the ASTRO Restriction to "Sell All Shares"
      securityPreference.astroRestrictionType = AstroHoldingRestrictionType.SellAllShares;
    } else if (buyPriority === BuyPriority.DoNotBuy && sellPriority === SellPriority.DoNotSell) {
      //Set the ASTRO Restriction to "Do Not Trade"
      securityPreference.astroRestrictionMin = -999;
      securityPreference.astroRestrictionMax = -999;
      securityPreference.astroRestrictionType = AstroHoldingRestrictionType.DoNotTrade;
    } else if (buyPriority !== BuyPriority.DoNotBuy && sellPriority === SellPriority.DoNotSell) {
      //Set the ASTRO Restriction to "Do Not Sell"
      securityPreference.astroRestrictionMin = -999;
      securityPreference.astroRestrictionMax = null;
      securityPreference.astroRestrictionType = AstroHoldingRestrictionType.DoNotSell;
    } else if (buyPriority === BuyPriority.DoNotBuy && sellPriority !== SellPriority.DoNotSell) {
      //Set the ASTRO Restriction to "Do Not Buy"
      securityPreference.astroRestrictionMin = null;
      securityPreference.astroRestrictionMax = -999;
      securityPreference.astroRestrictionType = AstroHoldingRestrictionType.DoNotBuy;
    } else if (buyPriority && sellPriority) {
      securityPreference.astroRestrictionType = AstroHoldingRestrictionType.Percent;
    } else {
      //Remove ASTRO Restriction
      securityPreference.astroRestrictionMin = null;
      securityPreference.astroRestrictionMax = null;
      securityPreference.astroRestrictionType = AstroHoldingRestrictionType.None;
    }
  }

  setShowAstroMinMaxField() {
    this.showAstroMinMaxFields = false;
    const sellPriority = this.securityPreference.sellPriority as SellPriority | null;
    const buyPriority = this.securityPreference.buyPriority as BuyPriority | null;

    this.showAstroMinMaxFields =
      sellPriority !== SellPriority.AlwaysSellAll &&
      buyPriority !== BuyPriority.DoNotBuy &&
      sellPriority !== SellPriority.DoNotSell &&
      buyPriority !== null &&
      sellPriority !== null;
  }

  getYesOrNoOptions() {
    this.yesOrNOoData = this._securityService.getYesOrNoValuesForPreferences();
  }

  astroRestrictionTypeDisplay(restrictionType: AstroHoldingRestrictionType): string {
    return SecurityPreferenceHelper.astroRestrictionTypeDisplay(restrictionType);
  }

  getNoRowsMessage(field: string): string {
    let value = Const.Messages.agGrid.noRowToShow;
    if (this.securityPreference?.custodialCash) {
      value = Const.Messages.agGrid.cashSecurityMessage(field);
    }
    return value;
  }

  showError(isAlreadyPresent, haveAlternative) {
    if (!haveAlternative) {
      this.showAlternativeErrMsg = true;
      this.errAlternativeMessage = 'Please select security alternatives. ';
    } else if (isAlreadyPresent) {
      this.showAlternativeErrMsg = true;
      this.errAlternativeMessage = 'Security with same custodian has already present.';
    }
  }

  reset() {
    this.showAlternativeErrMsg = false;
    this.errAlternativeMessage = '';
    this.showErrMsg = false;
    this.selectedTax = null;
    this.selectedExempt = null;
    this.selectedDeferred = null;
    this.selectedAlternativeCustodian = null;
    this._autoCompleteHubService.requestToClearSearchData();
  }

  /*reset Tlh alternative data*/
  resetAutocomplete() {
    this.showErrMsg = false;
    this.btnDisableSecurity = true;
    this.selectedCustodian = null;
    this.selectedTlhSecurity = '';
  }

  addToGrid() {
    this.securityPreference.isAdd = true; // SECURITY PREFERENCES BUCKETING ARRAYS
    this.securityPreference.isEdit = false;
    this.securityPreference.tlh = [];
    if (this.securityPreference.id) {
      this.validateTLH();
      this.setRedemptionFeeForGrid();
      this.securityPreference.alternatives = this.alternatives;
      this.securityPreference.taxableAlternate = this.alternatives[0] ? this.alternatives[0].tSecurity : null;
      this.securityPreference.taxDeferredAlternate = this.alternatives[0] ? this.alternatives[0].tdSecurity : null;
      this.securityPreference.taxExemptAlternate = this.alternatives[0] ? this.alternatives[0].teSecurity : null;
      if (!this.showErrMsg) {
        this.addPreferenceToGrid.emit(this.securityPreference);
        this.addEquivalentToGrid();
        this.displaySecPref = false;
      }
    }
  }

  validateTLH(): void {
    if (this.tlhList) {
      const inValidTLHList = this.tlhList.filter(t => t.priority === null || t.priority === undefined || t.priority.toString() === '');
      if (inValidTLHList && !inValidTLHList.length) {
        if (!SecurityPreferenceAddEditComponent.hasDuplicatePriorities(this.tlhList)) {
          this.securityPreference.tlh = this.tlhList;
          this.showErrMsg = false;
        } else {
          this.errMessage = 'Duplicate priorities are not allowed';
          this.showErrMsg = true;
        }
      } else {
        this.errMessage = 'Prorities are required';
        this.showErrMsg = true;
      }
    }
  }

  cancel() {
    this.displaySecPref = false;
    this.securityPreference = SecurityPreferenceHelper.bindSecurityEmptyData(this.securityPreference);
    this.tlhList = [];
    this.reset();
    this.resetEquivalentData();
  }

  setRedemptionFeeForGrid() {
    if (this.securityPreference.redemptionFeeAmount) {
      this.securityPreference.redemptionFeeTypeId = this.getRedemptionFeeTypeId(this.type);
    } else {
      this.securityPreference.redemptionFeeTypeId = null;
    }

    if (this.preferenceLevel && this.preferenceLevel.toLowerCase() === 'custodian' && this.securityPreference.custodianRedemptionFeeAmount) {
      this.securityPreference.custodianRedemptionFeeTypeId = this.getRedemptionFeeTypeId(this.custodianFeeType);
    } else {
      this.securityPreference.custodianRedemptionFeeTypeId = null;
    }
  }

  getRedemptionFeeTypeId(type: string): number {
    return type === '$' ? 1 : 2;
  }

  /** Hostlistner  */
  @HostListener('click', ['$event.target'])
  public onClick(targetElement) {
    if (targetElement.title === 'Delete Preferences') {

    }
    if (targetElement.title === 'Delete TLH') {
      // eslint-disable-next-line radix
      const id = parseInt(targetElement.outerHTML.split('id="')[1].split('value="')[0].replace(/['"]+/g, '').toString());
      // eslint-disable-next-line radix
      const custodianId = parseInt(targetElement.outerHTML.split('custodianid="')[1].split('value="')[0].replace(/['"]+/g, '').toString());
      this.deleteTlh(id, custodianId);
    }
    if (targetElement.title === 'Delete Alternative') {
      // eslint-disable-next-line radix
      const id = parseInt(targetElement.outerHTML.split('id="')[1].split('value="')[0].replace(/['"]+/g, '').toString());
      // eslint-disable-next-line radix
      const custodianId = parseInt(targetElement.outerHTML.split('custodianid="')[1].split('value="')[0].replace(/['"]+/g, '').toString());
      this.deleteAlternative(id, custodianId);
    }
  }

  deleteAlternative(id, custodianId) {
    const alternatives = [];
    this.alternatives.forEach(record => {
      // eslint-disable-next-line eqeqeq
      if (record.custodianId != custodianId) {
        alternatives.push(record);
      }
    });
    this.alternatives = alternatives;
    this.gridApiAlternative.setGridOption('rowData', this.alternatives);
  }

  deleteTlh(id, custodianId) {
    const newTlh = [];
    this.tlhList.forEach(record => {
      // eslint-disable-next-line eqeqeq
      if (record.id != id || record.custodianId != custodianId) {
        newTlh.push(record);
      }
    });
    this.tlhList = newTlh;
    this.gridApiTLH.setGridOption('rowData', this.tlhList);
    // eslint-disable-next-line eqeqeq
    const inValidTLHList = this.tlhList.filter(t => t.priority == null || t.priority == undefined || t.priority.toString() == '');
    // eslint-disable-next-line eqeqeq
    if (inValidTLHList.length == 0) {
      if (!SecurityPreferenceAddEditComponent.hasDuplicatePriorities(this.tlhList)) {
        this.showErrMsg = false;
      } else {
        this.errMessage = 'Duplicate priorities are not allowed';
        this.showErrMsg = true;
      }
    } else {
      this.errMessage = 'Prorities are required';
      this.showErrMsg = true;
    }
  }

  resetForm() {
    this.securityPreference = <ISecurityPreference>{};
    this.selectedTax = null;
    this.selectedExempt = null;
    this.selectedDeferred = null;
    this.tlhList = [];
    this.alternatives = [];
    this.selectedAlternativeCustodian = null;
    this.resetAutocomplete();
    this.reset();
  }

  addSecurityPreference(security: ISecurity): void {
    this.resetForm();
    this.displaySecPref = true;
    this.type = '$';
    this.custodianFeeType = '$';
    this.securityPreference = SecurityPreferenceHelper.bindSecurityEmptyData(security);
    this.showSecurityPreferenceEquivalent();
  }

  editSecurityPreference(securityPreference: ISecurityPreference): void {
    this.resetForm();
    this.displaySecPref = true;
    this.securityPreference = securityPreference;
    this.prevSellPriority = securityPreference.sellPriority;
    this.securityPreference.tBuyPriority = securityPreference.tBuyPriority !== null ? securityPreference.tBuyPriority : 0;
    this.securityPreference.tSellPriority = securityPreference.tSellPriority !== null ? securityPreference.tSellPriority : 0;
    this.securityPreference.teBuyPriority = securityPreference.teBuyPriority !== null ? securityPreference.teBuyPriority : 0;
    this.securityPreference.teSellPriority = securityPreference.teSellPriority !== null ? securityPreference.teSellPriority : 0;
    this.securityPreference.tdBuyPriority = securityPreference.tdBuyPriority !== null ? securityPreference.tdBuyPriority : 0;
    this.securityPreference.tdSellPriority = securityPreference.tdSellPriority !== null ? securityPreference.tdSellPriority : 0;
    this.securityPreference.equivalents = securityPreference?.equivalents?.length ? securityPreference.equivalents : [];

    this.securityPreference.doNotTLH = securityPreference.doNotTLH || securityPreference.doNotTLH === false
      ? securityPreference.doNotTLH : null;

    this.tlhList = securityPreference.tlh;
    this.alternatives = securityPreference.alternatives;
    this.gridApiAlternative.setGridOption('rowData', this.alternatives);
    this.gridApiTLH.setGridOption('rowData', this.tlhList);
    this.gridApiAlternative.sizeColumnsToFit();
    this.gridApiTLH.sizeColumnsToFit();

    this.updateAstroRestriction(this.securityPreference);
    this.setShowAstroMinMaxField();
    if (this.securityPreference.redemptionFeeTypeId === 1) {
      this.type = '$';
    } else {
      this.type = '%';
    }

    if (this.securityPreference.custodianRedemptionFeeTypeId === 1) {
      this.custodianFeeType = '$';
    } else {
      this.custodianFeeType = '%';
    }
    this.showSecurityPreferenceEquivalent();
  }

  /** Get priorities */
  getPriorities() {
    this._securityPreferenceHubService.sellAndBuyPriority.subscribe((sellAndBuyPriority: ISellBuyPriorityMap) => {
      this.sellPriorityList = sellAndBuyPriority.sellPriorities;
      this.buyPriorityList = sellAndBuyPriority.buyPriorities;
    });

    this._securityPreferenceHubService.taxableSellAndBuyPriority.subscribe((sellAndBuyPriority: ISellBuyPriorityMap) => {
      this.taxableSellPriorityList = sellAndBuyPriority.sellPriorities;
      this.taxableBuyPriorityList = sellAndBuyPriority.buyPriorities;
    });
  }

  getCustodians(): void {
    this._securityPreferenceHubService.custodian.subscribe((custodians: ICustodian[]) => {
      this.custodianList = custodians;
    });
  }

  resetSecurityPreferenceData(): void {
    this.alternatives = [];
    this.tlhList = [];
    this.securityPreference = <ISecurityPreference>{
      id: null,
      securityName: null,
      securityType: null,
      symbol: null,
      redemptionFeeTypeId: null,
      redemptionFeeAmount: null,
      redemptionFeeDays: null,
      sellTradeMinAmtBySecurity: null,
      sellTradeMinPctBySecurity: null,
      buyTradeMinAmtBySecurity: null,
      buyTradeMinPctBySecurity: null,
      buyTradeMaxAmtBySecurity: null,
      buyTradeMaxPctBySecurity: null,
      sellTradeMaxAmtBySecurity: null,
      sellTradeMaxPctBySecurity: null,
      taxableAlternate: null,
      taxDeferredAlternate: null,
      taxExemptAlternate: null,
      taxableDivReInvest: null,
      taxDefDivReinvest: null,
      taxExemptDivReinvest: null,
      capGainReinvestTaxable: null,
      capGainsReinvestTaxExempt: null,
      capGainsReinvestTaxDef: null,
      sellTransactionFee: null,
      buyTransactionFee: null,
      custodianRedemptionFeeTypeId: null,
      custodianRedemptionDays: null,
      custodianRedemptionFeeAmount: null,
      sellPriority: null,
      buyPriority: null,
      tBuyPriority: null,
      tSellPriority: null,
      teBuyPriority: null,
      teSellPriority: null,
      tdBuyPriority: null,
      tdSellPriority: null,
      minInitialBuyAmount: null,
      alternatives: [],
      tlh: [],
      coveredCallPercent: null,
      protectivePutPercent: null
    };
  }

  private createAlternativeGrid() {
    this.columnAlternativeDefs = [
      <ColDef>{
        headerName: 'Security',
        width: 165,
        headerTooltip: 'Security',
        field: 'symbol',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Custodian',
        width: 165,
        headerTooltip: 'Custodian Name',
        field: 'custodian',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Taxable Security',
        width: 150,
        headerTooltip: 'Taxable Security',
        field: 'tSecurity.symbol',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Tax Deferred Security',
        width: 200,
        headerTooltip: 'Tax Deferred Security',
        field: 'tdSecurity.symbol',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Tax Exempt Security',
        width: 200,
        headerTooltip: 'Tax Exempt Security',
        field: 'teSecurity.symbol',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Delete',
        width: 92,
        headerTooltip: 'Delete',
        sortable: false,
        cellClass: 'text-center',
        cellRenderer: SecurityPreferenceAddEditComponent.deleteAlternativeCellRenderer
      },
    ];
  }

  private createTlhGrid() {

    const self = this;
    this.tlhColumnDefs = [
      <ColDef>{
        headerName: 'Priority', width: 100, headerTooltip: 'Priority', field: 'priority', filter: 'agTextColumnFilter',
        cellRenderer: function (params) {
          return SecurityPreferenceAddEditComponent.tlhRankEditor(params, self);
        }, cellClass: 'text-center'
      },
      <ColDef>{
        headerName: 'Custodian',
        width: 150,
        headerTooltip: 'Custodian Name',
        field: 'custodian',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Security Name',
        width: 230,
        headerTooltip: 'Security Name',
        field: 'name',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Symbol',
        width: 140,
        headerTooltip: 'Symbol',
        field: 'symbol',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Type',
        width: 100,
        headerTooltip: 'Type',
        field: 'securityType',
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Delete', width: 100, headerTooltip: 'Delete', sortable: false,
        cellRenderer: SecurityPreferenceAddEditComponent.deleteCellRenderer, cellClass: 'text-center'
      },
    ];
  }

  private addEquivalentToGrid(): void {
    this.securityPreference.equivalents = this.securityPreferenceEquivalentComponent?.equivalents;
    this._securityPreferenceHubService.addSecurityPreferenceForEquivalent.next(this.securityPreference.equivalents);
    this.resetEquivalentData();
  }

  private showSecurityPreferenceEquivalent(): void {
    this.securityPreferenceEquivalentComponent?.showSecurityPreferenceEquivalent(this.securityPreference);
  }

  private resetEquivalentData(): void {
    this.securityPreferenceEquivalentComponent?.cancel();
  }
}
