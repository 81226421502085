<a href="javascript:void(0)" class="btn-prnt-inhrt"><i class="far fa-lightbulb" (click)="isShowInheritedPopup=true"
                                                       [id]="componentType" aria-hidden="true"></i></a>
<p-dialog [header]="headerName" [resizable]="false" [(visible)]="isShowInheritedPopup" class="ui-dialog-lg"
          [closable]="true" modal="false" (onHide)="isShowInheritedPopup=false">
  <div [ngSwitch]="componentType">
    <div class="row fullWidth-grid-parent margin-top-15">
      <div *ngSwitchCase="'MoneyMarketFundsGrid'" class="col-md-12">
        <eclipse-money-market-fund
          [canEdit]="false"
          [isInheritedView]="true"
          [moneyMarketFundPreference]="moneyMarketFundPreference">
        </eclipse-money-market-fund>
      </div>
      <div *ngSwitchCase="'MoneyMarketAllocationGrid'" class="col-md-12">
        <eclipse-money-market-preference
          [canEdit]="false"
          [isInheritedView]="true"
          [moneyMarketAllocationPreference]="moneyMarketAllocationPreference">
        </eclipse-money-market-preference>
      </div>
    </div>
  </div>
  <p-footer>
    <button pButton (click)="isShowInheritedPopup=false" label="Close"></button>
  </p-footer>
</p-dialog>
