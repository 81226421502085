import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {
  BillingCashAutoRebalanceFrequency,
  IBillingCashMultiplierPreference, IBillingCashMultiplierRebalance
} from '../../models/preferences/billingcash';
import {Utils} from '../../core/functions';

@Component({
  selector: 'eclipse-billing-cash-multiplier-grid',
  templateUrl: './billing-cash-multiplier-grid.component.html',
  styleUrls: ['./billing-cash-multiplier-grid.component.scss']
})
export class BillingCashMultiplierGridComponent extends BaseComponent {
  @Input() preferenceData: IBillingCashMultiplierPreference;
  @Input() canEdit: boolean = false;
  public rebalanceData: IBillingCashMultiplierRebalance[];
  public errorMessage: string;
  public isValid = true;

  constructor() {
    super();
  }

  ngOnInit() {
    this.bindBillingCashMultiplierData();
  }

  private createPlaceholderRebalanceItems(data: IBillingCashMultiplierRebalance[]): IBillingCashMultiplierRebalance[] {
    const values: IBillingCashMultiplierRebalance[] = [];
    // for each type of rebalance frequency, create a placeholder object if one doesn't already exist
    Object.values(BillingCashAutoRebalanceFrequency).forEach(freq => {
      if (!isNaN(Number(freq))) {
        const item = data.find(s => s.autoRebalanceTypeId === freq);
        if (!item) {
          values.push(<IBillingCashMultiplierRebalance>{
            id: 0,
            autoRebalanceTypeId: freq,
          });
        } else {
          values.push(item);
        }
      }
    });
    return values;
  }

  bindBillingCashMultiplierData(): void {
    if (this.preferenceData && this.preferenceData.billingCashMultiplierPreferences) {
      this.rebalanceData = this.createPlaceholderRebalanceItems(this.preferenceData.billingCashMultiplierPreferences);
    } else {
      this.rebalanceData = [];
    }
    this.resetForm();
  }

  resetForm(): void {
    this.isValid = true;
    this.errorMessage = null;
  }

  validate(value: IBillingCashMultiplierRebalance): boolean {
    this.errorMessage = null;
    this.preferenceData.resetToParent = false;

    this.isValid = this.validateAll();

    // if the a value doesn't exist for the current auto-rebalance frequency, add it to the preference now
    if (this.isValid && !this.preferenceData.billingCashMultiplierPreferences.find(s => s.autoRebalanceTypeId === value.autoRebalanceTypeId)) {
      this.preferenceData.billingCashMultiplierPreferences.push(value);
    }

    return this.isValid;
  }

  validateAll(): boolean {
    this.rebalanceData.forEach(row => {
      // validate each of the types of frequencies to ensure they're all valid
      const min = row.minimum;
      const max = row.maximum;
      const target = row.target;

      if (min !== null && min !== undefined && target !== null && target !== undefined && min > target) { // min must be < target
        this.errorMessage = `${this.getRebalanceFrequencyDisplayName(row)} Auto-Rebal minimum must be less than target.`;
      } else if (min !== null && min !== undefined && max !== null && max !== undefined && min > max) { // min must be < max
        this.errorMessage = `${this.getRebalanceFrequencyDisplayName(row)} Auto-Rebal minimum must be less than maximum.`;
      } else if (target !== null && target !== undefined && max !== null && max !== undefined && target > max) { // target must be < max
        this.errorMessage = `${this.getRebalanceFrequencyDisplayName(row)} Auto-Rebal target must be less than maximum.`;
      }
    });

    this.isValid = !this.errorMessage;
    return this.isValid;
  }

  getRebalanceFrequencyDisplayName(item: IBillingCashMultiplierRebalance): string {
    switch (item.autoRebalanceTypeId) {
      case BillingCashAutoRebalanceFrequency.None:
        return 'No';
      case BillingCashAutoRebalanceFrequency.Monthly:
        return 'Monthly';
      case BillingCashAutoRebalanceFrequency.Quarterly:
        return 'Quarterly';
      case BillingCashAutoRebalanceFrequency.SemiAnnual:
        return 'Semi Annual';
      case BillingCashAutoRebalanceFrequency.Annual:
        return 'Annual';
      default:
        return 'Unknown';
    }
  }

  validatePercent(event, item: IBillingCashMultiplierRebalance) {
    if (item.toleranceBand !== null && item.toleranceBand !== undefined) {
      item.toleranceBand = Math.min(Math.abs(+Utils.toFixed(item.toleranceBand, 2)), 100);
    }
    return this.validateEventField(event);
  }

  validateEventField(event) {
    return event.key !== undefined && this.isValidDecimalCharacter(event);
  }

  isValidDecimalCharacter(event): boolean {
    let isValid = !(event.key.toLowerCase() === 'e'
      || event.key === '+'
      || event.key === ','
      || event.key === '/'
      || event.key === '?'
      || event.key === '*'
      || event.key === '=');

    if (event.target.value.includes('.')) {
      if (event.key === '.') {
        isValid = false;
      } else if (event.key === '0' && event.target.value.toString().indexOf('.') !== -1) {
        const decimalValue = event.target.value.toString().split('.')[1];
        if (decimalValue.length === 2) {
          isValid = false;
        }
      }
    }
    return isValid;
  }
}
