import { Component, Input, ViewChild } from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {IPreferenceCategory, IPreferenceEvent, IPreferences, IPreferenceSubCategory} from '../../models/preferences/preference';
import {ICustomCommunityPreference} from '../../models/preferences/preferenceCommunity';
import {ILocationOptimizationCustom} from '../../models/preferences/locationOptimization';
import {ISecurityPreferencesGet } from '../../models/preferences/securityPreference';
import { IRedemptionFeePreferencesGet } from '../../models/preferences/redemptionfeepreference';
import { ICustodianRedemptionFeePreferencesGet } from '../../models/preferences/custodianredemptionfeepreference';
import { ITransactionFeePreferencesGet } from '../../models/preferences/transactionfeepreference';
import { ITradeMinPreferencesGet } from '../../models/preferences/trademinpreference';
import { ITradeMaxPreferencesGet } from '../../models/preferences/trademaxpreference';
import { IDividendReinvestPreferencesGet } from '../../models/preferences/dividendreinvestpreference';
import { ICapitalGainReinvestPreferencesGet } from '../../models/preferences/capitalgainreinvestpreference';
import { ITradeSettingsPreferencesGet } from '../../models/preferences/tradesettingspreference';
import { IDefaultPrimaryTeamPreferencesGet } from '../../models/preferences/defaultprimaryteampreference';
import { DynamicControlComponent } from './dynamiccontrol.component';
import { PreferenceService } from '../../services/preference.service';
import { IPreferenceDataVM } from '../../viewModels/preference.data';
import { ISystematicMultiplierPreference } from '../../models/preferences/systematic';
import { IBillingCashMultiplierPreference } from '../../models/preferences/billingcash';
import * as preferenceEnum from '../../libs/preference.enums';
import { PreferenceHelper } from '../preference/preference.helper';
import { Utils } from '../../core/functions';
import {
  IMoneyMarketFundPreference,
  IMoneyMarketAllocationPreference
} from '../../models/preferences/moneyMarketPreference';

@Component({
  selector: 'eclipse-dynamic-wrapper',
  templateUrl: './dynamicwrapper.component.html',
  providers: [PreferenceService]
})
export class DynamicWrapperComponent {

  @Input() preferences: any;
  toleranceSettingsSell: IPreferences[];
  toleranceSettingsBuy: IPreferences[];
  categories: IPreferenceCategory[] = [];
  @Input() preferenceCommunities: ICustomCommunityPreference;
  @Input() locationOptimizations: ILocationOptimizationCustom;
  @Input() SecurityPrefResultWrapper: ISecurityPreferencesGet;
  @Input() RedemptionFeePrefResultWrapper: IRedemptionFeePreferencesGet;
  @Input() CustodianRedemptionFeePrefResultWrapper: ICustodianRedemptionFeePreferencesGet;
  @Input() TransactionFeePrefResultWrapper: ITransactionFeePreferencesGet;
  @Input() TradeMinPrefResultWrapper: ITradeMinPreferencesGet;
  @Input() TradeMaxPrefResultWrapper: ITradeMaxPreferencesGet;
  @Input() DividendReinvestPrefResultWrapper: IDividendReinvestPreferencesGet;
  @Input() CapitalGainReinvestPrefResultWrapper: ICapitalGainReinvestPreferencesGet;
  @Input() TradeSettingsPrefResultWrapper: ITradeSettingsPreferencesGet;
  @Input() DefaultPrimaryTeamsPrefResultWrapper: IDefaultPrimaryTeamPreferencesGet;
  @Input() SystematicMultiplierPrefResultWrapper: ISystematicMultiplierPreference;
  @Input() BillingCashMultiplierPrefResultWrapper: IBillingCashMultiplierPreference;
  MoneyMarketAllocationPreferenceWrapper: IMoneyMarketAllocationPreference;
  MoneyMarketFundPreferenceWrapper: IMoneyMarketFundPreference;
  @Input() SecurityTypes: any[];
  @Input() levelName: string;
  @Input() levelId: number;
  form: UntypedFormGroup;
  perfpermissionMode; string;
  subCategories: IPreferenceSubCategory[] = [];
  @ViewChild(DynamicControlComponent) dynamicformcontrolComponent: DynamicControlComponent;
  selectedIds: number[];
  public searchText: string;

  constructor(private _preferenceHelper: PreferenceHelper) { }

  setFormData(preferenceVM: IPreferenceDataVM, isGlobalReset: boolean) {
    this.levelName = preferenceVM.levelPreferences.level;
    this.levelId = preferenceVM.levelPreferences.id;
    if (!isGlobalReset) { PreferenceService.setFormDefaultsForInheriteds(preferenceVM.levelPreferences.preferences); }

    const prefMACSell = preferenceVM.levelPreferences.preferences.find(item => item.subCategoryType !== undefined && item.subCategoryType !== null && item.subCategoryType.startsWith('Multi Asset Class Sell', 0));
    if (prefMACSell) {
      prefMACSell.watermarkText = 'MACSell';
    }
    const prefMACBuy = preferenceVM.levelPreferences.preferences.find(item => item.subCategoryType !== undefined && item.subCategoryType !== null && item.subCategoryType.startsWith('Multi Asset Class Buy', 0));
    if (prefMACBuy) {
      prefMACBuy.watermarkText = 'MACBuy';
    }

    preferenceVM.levelPreferences.preferences.forEach(function (item) {
      // eslint-disable-next-line eqeqeq
      if (item.subCategoryType != undefined && item.subCategoryType != null && item.subCategoryType.startsWith('Multi Asset Class Sell', 0)) { item.componentType = 'MACSELL'; }
    });
    preferenceVM.levelPreferences.preferences.forEach(function (item) {
      // eslint-disable-next-line eqeqeq
      if (item.subCategoryType != undefined && item.subCategoryType != null && item.subCategoryType.startsWith('Multi Asset Class Buy', 0)) { item.componentType = 'MACBUY'; }
    });
    this.preferences = preferenceVM.levelPreferences.preferences;
    this.toleranceSettingsSell = preferenceVM.levelPreferences.preferences.filter(this.filterMACPreferences('Multi Asset Class Sell'));
    this.toleranceSettingsBuy = preferenceVM.levelPreferences.preferences.filter(this.filterMACPreferences('Multi Asset Class Buy'));
    this.toleranceSettingsBuy.sort(this.sortByOrder()); // sort() mutates the array

    this.form = this.toFormGroup(this.preferences);
    const visiblePreferences = this.preferences.filter(p => p.valueType !== 'hidden');
    // keep only the categories that have visible preferences
    this.categories = preferenceVM.preferenceCategories.filter(cat => {
      return visiblePreferences.filter(p => p.categoryType === cat.name).length > 0;
    });
    // eslint-disable-next-line eqeqeq
    this.preferenceCommunities = (preferenceVM.preferenceCommunities != null) ? preferenceVM.preferenceCommunities : <ICustomCommunityPreference>{};
    // eslint-disable-next-line eqeqeq
    this.locationOptimizations = (preferenceVM.preferenceLocationOptimization != null) ? preferenceVM.preferenceLocationOptimization : <ILocationOptimizationCustom>{};
    this.RedemptionFeePrefResultWrapper = preferenceVM.preferenceRedemptionFeeData;
    this.CustodianRedemptionFeePrefResultWrapper = preferenceVM.preferenceCustodianRedemptionFeeData;
    this.TransactionFeePrefResultWrapper = preferenceVM.preferenceTransactionFeeData;
    this.perfpermissionMode = preferenceVM.prefPermissonMode;
    this.TradeMinPrefResultWrapper = preferenceVM.preferenceTradeMinData;
    this.TradeMaxPrefResultWrapper = preferenceVM.preferenceTradeMaxData;
    this.DividendReinvestPrefResultWrapper = preferenceVM.preferenceDividendReinvestData;
    this.CapitalGainReinvestPrefResultWrapper = preferenceVM.preferenceCapitalGainReinvestData;
    this.SystematicMultiplierPrefResultWrapper = preferenceVM.preferenceSystematicMultiplierData;
    this.BillingCashMultiplierPrefResultWrapper = preferenceVM.preferenceBillingCashMultiplierData;
    this.TradeSettingsPrefResultWrapper = preferenceVM.preferenceTradeSettingsData;
    this.DefaultPrimaryTeamsPrefResultWrapper = preferenceVM.preferenceDefaultPrimaryTeamData;
    this.subCategories = preferenceVM.preferenceSubCategories;
    this.subCategories.push({ id: +(this.subCategories[this.subCategories.length - 1]['id'] + 1), name: null, order: +(this.subCategories[this.subCategories.length - 1]['order'] + 1) });
    this.SecurityTypes = preferenceVM.securityTypes;
    this.MoneyMarketAllocationPreferenceWrapper = preferenceVM.moneyMarketAllocationPreferenceData;
    this.MoneyMarketFundPreferenceWrapper = preferenceVM.moneyMarketFundPreferenceData;
    this._preferenceHelper.hideUnHideMoneyMarketPreference(this.preferences);
  }

  private filterMACPreferences(macPreferenceSetting: string): (value: IPreferences, index: number, array: IPreferences[]) => any {
    // eslint-disable-next-line eqeqeq
    return p => p.subCategoryType == macPreferenceSetting;
  }

  private sortByOrder(): (a: IPreferences, b: IPreferences) => number {
    return (p1, p2) => {
      if (p1.displayOrder > p2.displayOrder) {
        return 1;
      }
      if (p1.displayOrder < p2.displayOrder) {
        return -1;
      }
      return 0;
    };
  }

  // toFormGroup(preference: IPreference<any>[]) {
  toFormGroup(preference: IPreferences[]) {
    // console.log(preference);
    const group: any = {};
    preference.forEach(pfsettings => {
      group[pfsettings.name] = pfsettings.required ? new UntypedFormControl(pfsettings.value || '', Validators.compose([Validators.required]))
        : new UntypedFormControl(pfsettings.value || '');
      // eslint-disable-next-line eqeqeq
      if (pfsettings.indicatorOptions != undefined && pfsettings.indicatorOptions.length > 0) {
        group[pfsettings.name + pfsettings.componentName] = new UntypedFormControl(pfsettings.indicatorValue || '', Validators.compose([Validators.required]));
      }
    });
    return new UntypedFormGroup(group);
  }

  /** Set the selected Level ids */
  setSelectedIds(selectedIds: any[]) {
    this.selectedIds = selectedIds;
  }

  // SF-154198 enable disable Tax Lot Depletion Method method based on lowest estimated tax.
  checkForTaxMethods(event) {
    const preference = this.preferences.find(pref => pref.componentName === 'TaxLotDepletionMethodSortedlist');
    if (preference) {
      // eslint-disable-next-line eqeqeq
      if (event == 'true') {
        preference.disabled = true;
      } else {
        preference.disabled = false;
      }

    }
  }

  /**
   * Returns true if the category name or any preferences in it match the search text
   * @param preferenceCategory
   */
  public matchCategoryName(preferenceCategory: IPreferenceCategory): boolean {
    if (!this.searchText || this.searchText === '') {
      return true;
    }
    const search = this.searchText.toLowerCase();
    const preferencesMatching = this.preferences.filter(pref => pref.displayName.toLowerCase().indexOf(search) >= 0);
    return !!preferencesMatching.filter(it => it.categoryType === preferenceCategory.name).length;
  }

  /**
   * Returns true if the subcategory name or any preferences in it match the search text
   * @param subCategoryPreference
   */
  public matchSubCategoryName(subCategoryPreference: IPreferences): boolean {
    if (!this.searchText || this.searchText === '') {
      return true;
    }
    const search = this.searchText.toLowerCase();
    return !!subCategoryPreference.value.filter(pref => pref.displayName.toLowerCase().indexOf(search) >= 0).length;
  }

  /**
   * Returns true if the preferences name matches the search text
   * @param preference
   */
  public matchPreferenceName(preference: IPreferences): boolean {
    if (!this.searchText || this.searchText === '') {
      return true;
    }
    const search = this.searchText.toLowerCase();
    return preference.displayName.toLowerCase().indexOf(search) >= 0;
  }

  updatePreferences(event: IPreferenceEvent): void {
    const value = Utils.getBooleanValue(event?.value);
    const prefName = event?.name;
    if (prefName === preferenceEnum.PREFERENCE_NAME.LOWEST_ESTIMATED_TAX) {
      this.checkForTaxMethods(event?.value);
    } else if (prefName === preferenceEnum.PREFERENCE_NAME.ALLOW_WASH_SALES) {
      this._preferenceHelper.updateWashSalesPreference(value, this.preferences);
    } else if (prefName === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_TRADING) {
      this._preferenceHelper.hideUnHideMoneyMarketPreference(this.preferences);
    }
  }

}
