import { Pipe, PipeTransform } from '@angular/core';
import { IDashboardCategory, IDashboardDetail } from '../../models/dashboard';
import { ViewTypeEnum } from "../../libs/app.constants";

/**
 * Pipe used to filter dashboard items.  By default, returns all active items.
 * If a category is deselected it will be excluded from the results.
 * If the disableFiltering flag is set to true, the category selected flag will be ignored.
 */
@Pipe({
  name: 'dashboardItemFilter',
  pure: false
})
export class DashboardItemFilterPipe implements PipeTransform {
  transform(items: IDashboardDetail[], categories: IDashboardCategory[], disableFiltering: boolean): IDashboardDetail[] {
    const activeItems = items?.filter(item => !item.isDeleted);
    if (disableFiltering || !items || !categories || categories.find(c => c.id === -1)?.selected) {
      return activeItems;
    }
    // ids of the selected dashboard categories
    const selectedCategories = categories.filter(c => c.selected);
    const selectedCategoryIds = selectedCategories.map(c => c.id);
    // return only the items that belong to selected categories and are not hidden
    return activeItems
      .filter(item => {
        if(item.userGridViewId) {
          switch(<ViewTypeEnum>item.userGridView?.viewTypeId) {
            case ViewTypeEnum.AccountListDynamicView:
              return !!selectedCategories.find(cat => cat.name === 'Accounts');
            case ViewTypeEnum.PortfolioListDynamicView:
              return !!selectedCategories.find(cat => cat.name === 'Portfolios');
            default:
              return false;
          }
        } else {
          return selectedCategoryIds.indexOf(item.dashboardField.dashboardCategoryId) !== -1;
        }
      });
  }
}
