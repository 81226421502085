<div class="form-group">
  <div class="flex">
    <div class="flex-column">
      <p-checkbox id="includeCostBasis" name="includeCostBasis" ngDefaultControl binary="true"
                  [(ngModel)]="isCostBasis" label="Include Cost Basis"></p-checkbox>
      <p-checkbox id="isTradeBlock" name="isTradeBlock" ngDefaultControl binary="true" class="m-l-20"
                  [(ngModel)]="isTradeBlock" label="Include Trade Blocked Account"></p-checkbox>
      <p-checkbox id="isExcludeAsset" name="isExcludeAsset" ngDefaultControl binary="true" class="m-l-20"
                  [(ngModel)]="isExcludeAsset" label="Show Excluded Assets"></p-checkbox>
    </div>
    <button pButton (click)="refreshClick()" [disabled]="selectedModelId == undefined" class="p-button-sm btn-reload" icon="fas fa-sync"></button>
  </div>
  <div class="m-t-10">
    <p-dropdown id="action" class="form-control" [options]="filteredModels" [(ngModel)]="selectedModelId"
                optionValue="id" optionLabel="name" placeholder="Select a Model"
                (onChange)="onModelSelect($event.value)" appendTo="body">
    </p-dropdown>
    <div class="m-t-15">
      <div class="row" *ngIf="summary != undefined">
        <div class="col-md-4">
          <span>Current Cash</span>
          <label>{{summary.cashValue | currency}}</label>
        </div>
        <div class="col-md-4">
          <span>Cash Difference</span>
          <label>{{summary.cashDifference | currency}}</label>
        </div>
        <div class="col-md-4">
          <span>Total Cash</span>
          <label>{{summary.totalCash | currency}}</label>
        </div>
      </div>
      <div class="row margin-top-5">
        <div *ngIf="summary != undefined" class="col-md-4">
          <span>Cost Basis</span>
          <label>{{summary.costBasis | currency}}</label>
        </div>
        <div *ngIf="summary != undefined" class="col-md-4">
          <span>Market Value</span>
          <label>{{summary.marketValue | currency}}</label>
        </div>
        <div *ngIf="summary != undefined" class="col-md-4">
          <span>Tolerance Band</span>
          <div>
            <div class="flex" *ngIf="showCalculateBox">
              <p-inputNumber [(ngModel)]="targetTolerance" [min]="0" [max]="100" [step]="0.01" [minFractionDigits]="0" [maxFractionDigits]="2"
                             (onBlur)="validateTolerance(targetTolerance)"
                             inputId="targetTolerance" mode="decimal" class="p-inputtext-sm">
              </p-inputNumber>
              <button pButton icon="fas fa-times" (click)="recalculateTolerance(true)"></button>
            </div>
            <div class="form-group custom-form-group" *ngIf="!showCalculateBox">
              <button type="button" pButton (click)="showCalculateBox=true" class="p-button-sm">Modify</button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <eclipse-analytics-duration *ngIf="portfolioAnalytics?.length"
                                      [portfolioAnalytics]="portfolioAnalytics"></eclipse-analytics-duration>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="!selectedModelId" class="fullList-scroll m-t-10">
  <p-tabView styleClass="tabs-only">
    <ng-container *ngFor="let item of modelLevels">
     <p-tabPanel [id]="item.name" tabName="{{item.name}}" [selected]="item.name === selectedTab">
        <ng-template pTemplate="header">
          <a (click)="onTabChange(item.name)">{{item.displayName}}</a>
        </ng-template>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
  <div id="maSecurity">
      <div [hidden]="!summary">
        <div class="responsive-table">
          <ag-grid-angular #agGridMA style="width: 100%; height: 220px;" class="ag-theme-alpine"
                           [gridOptions]="tabGrid.gridOptions" [columnDefs]="tabGrid.columnDefs" [rowData]="tabGrid.gridRowData"
                           (rowClicked)="onRowSelected($event)" [getRowClass]="getRowClass" suppressContextMenu="true" (cellClicked)="onCellClicked($event)"
                           [context]="gridContext" rowSelection="single" (gridReady)="onTabGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
</div>
<div *ngIf="!!selectedModelId && isRowSelected" class="m-t-10">
  <p-tabView styleClass="tabs-only">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <a (click)="onPortfolioTabChange(1)">Portfolios </a>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <a (click)="onPortfolioTabChange(2)">Sleeves </a>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
  <div id="maPortfolios">
      <div class="text-right">
        <div class="form-group">
          <p-radioButton id="radioPercent" label="Percent" name="radioType" class="m-r-5" value="1"
                         [(ngModel)]="selectedCellValueType" (onClick)="selectCellValueType(1)"></p-radioButton>
          <p-radioButton id="radioDollars" label="Dollars" name="radioType" class="m-r-5" value="2"
                         [(ngModel)]="selectedCellValueType" (onClick)="selectCellValueType(2)"></p-radioButton>
          <p-radioButton id="radioShares" label="Shares" name="radioType" class="m-r-5" value="3"
                         [(ngModel)]="selectedCellValueType" (onClick)="selectCellValueType(3)"></p-radioButton>
        </div>
      </div>
      <div>
        <ag-grid-angular #agGridPf style="width: 100%; height: 220px;" class="ag-theme-alpine" (gridReady)="onGridReady($event)"
                         [gridOptions]="gridOptions" [columnDefs]="columnDefs" [rowData]="gridRowData"
                         suppressContextMenu="true" rowSelection="single">
        </ag-grid-angular>
      </div>
    </div>
</div>
