import { Injectable } from '@angular/core';
import { IPreferences } from '../../models/preferences/preference';
import { SessionHelper } from '../../core';
import * as constants from '../../libs/app.constants';
import * as preferenceEnum from '../../libs/preference.enums';
import { Utils as Util } from '../../core/functions';

@Injectable({
  providedIn: 'root'
})
export class PreferenceHelper {

  constructor(private sessionHelper: SessionHelper) {
  }

  public hasFirmPermission(roleType: string): boolean {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
        const permission = this.sessionHelper.getPermission(constants.PRIV_FIRMPREF);
        return permission && permission.canRead;
      case 'TEAM ADMIN':
      case 'USER':
      default:
        return false;
    }
  }

  public hasCustodianPermission(roleType: string): boolean {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
        const permission = this.sessionHelper.getPermission(constants.PRIV_CUSTODIANPREF);
        return permission && permission.canRead;
      case 'TEAM ADMIN':
      case 'USER':
      default:
        return false;
    }
  }

  public hasTeamPermission(roleType: string): boolean {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
      case 'TEAM ADMIN':
        const permission = this.sessionHelper.getPermission(constants.PRIV_TEAMPREF);
        return permission && permission.canRead;
      case 'USER':
      default:
        return false;
    }
  }

  public hasModelPermission(roleType: string): boolean {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
      case 'TEAM ADMIN':
      case 'USER':
        const permission = this.sessionHelper.getPermission(constants.PRIV_MODELPREF);
        return permission && permission.canRead;
      default:
        return false;
    }
  }

  public hasPortfolioPermission(roleType: string): boolean {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
      case 'TEAM ADMIN':
      case 'USER':
        const permission = this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF);
        return permission && permission.canRead;
      default:
        return false;
    }
  }

  public hasAccountPermission(roleType: string): boolean {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
      case 'TEAM ADMIN':
      case 'USER':
        const permission = this.sessionHelper.getPermission(constants.PRIV_ACCOUNTPREF);
        return permission && permission.canRead;
      default:
        return false;
    }
  }

  /** Redirect to specific preference based on User role and privileges*/
  redirectPrefMenuBasedOnUserRoleAndPriv(roleType: string, eclipseAdminPrefRoute: string) {
    switch (roleType) {
      case 'ORION ADMIN':
      case 'FIRM ADMIN':
        if (this.sessionHelper.getPermission(constants.PRIV_FIRMPREF).canRead) {
          return `${eclipseAdminPrefRoute}/firm`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_FIRMPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_CUSTODIANPREF).canRead) {
          return `${eclipseAdminPrefRoute}/custodian`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_FIRMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_CUSTODIANPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead) {
          return `${eclipseAdminPrefRoute}/team`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_FIRMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_CUSTODIANPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead) {
          return `${eclipseAdminPrefRoute}/model`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_FIRMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_CUSTODIANPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF).canRead) {
          return `${eclipseAdminPrefRoute}/portfolio`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_FIRMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_CUSTODIANPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_ACCOUNTPREF).canRead) {
          return `${eclipseAdminPrefRoute}/account`;
        }
        break;
      case 'TEAM ADMIN':
        /** FOR TEAM ADMIN Firm/Cutodian preferences will never be shown
         * irrespective of previleges set for these
         */
        if (this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead) {
          return `${eclipseAdminPrefRoute}/team`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead) {
          return `${eclipseAdminPrefRoute}/model`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF).canRead) {
          return `${eclipseAdminPrefRoute}/portfolio`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_TEAMPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_ACCOUNTPREF).canRead) {
          return `${eclipseAdminPrefRoute}/account`;
        }
        break;
      case 'USER':
        /** FOR normal USER Firm/Cutodian/Team preferences will never be shown
         * irrespective of previleges set for these
         */
        if (this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead) {
          return `${eclipseAdminPrefRoute}/model`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF).canRead) {
          return `${eclipseAdminPrefRoute}/portfolio`;
        } else if (!this.sessionHelper.getPermission(constants.PRIV_MODELPREF).canRead
          && !this.sessionHelper.getPermission(constants.PRIV_PORTFOLIOPREF).canRead
          && this.sessionHelper.getPermission(constants.PRIV_ACCOUNTPREF).canRead) {
          return `${eclipseAdminPrefRoute}/account`;
        }
        break;
    }
  }

  updateWashSalesPreference(value: boolean, preferences: IPreferences[]): void {
    for (const preference of preferences) {
      if (preferenceEnum.PREFERENCE_NAME.ALLOW_WASH_SALES_ON_100_PERCENT_SELL === preference.name
        || preferenceEnum.PREFERENCE_NAME.ALLOW_WASH_SALES_UPTO_X_PERCENT_OF_TRADE_AMOUNT === preference.name) {
        preference.isHidden = value;
      }
    }
  }

  hideUnHideMoneyMarketPreference(preferences: IPreferences[]): void {
    const moneyMarketTradingPreference = preferences.find(p => p.name === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_TRADING);
    const moneyMarketTargetAllocation = preferences.find(p => p.name === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_ALLOCATION);
    const moneyMarketTargetFund = preferences.find(p => p.name === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_FUNDS);
    const isShowMoneyMarketTargetPreference = Util.convertIntoBooleanValue(moneyMarketTradingPreference?.value) || Util.convertIntoBooleanValue(moneyMarketTradingPreference?.indicatorValue);

    if (moneyMarketTargetAllocation) {
      moneyMarketTargetAllocation.isHidden = !isShowMoneyMarketTargetPreference;
    }
    if (moneyMarketTargetFund) {
      moneyMarketTargetFund.isHidden = !isShowMoneyMarketTargetPreference;
    }
  }

  hasMoneyMarketPreference(preference: IPreferences[]): boolean {
    return preference.some(p => p.name === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_ALLOCATION.toString() || p.name === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_FUNDS.toString());
  }

  hasInvestorPreferences(preference: IPreferences[]): boolean {
    return preference.some(preference => preference.name === preferenceEnum.PREFERENCE_NAME.ASTRO_INVESTOR_PREFERENCES.toString());
  }

  preserveSpace(preferences: IPreferences[]): void {
    const moneyMarketAllocationPreference = preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_ALLOCATION.toString();

    for (const pref of preferences) {
      if (moneyMarketAllocationPreference === pref.name) {
        pref.helpText = pref.helpText.replace(/  /g, '\u00A0\u00A0');
      }
    }
  }
}
