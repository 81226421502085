import {Injectable} from '@angular/core';
import {ILogin, IUser, IRole, IRolePrivilege} from '../models/users.models';

@Injectable({
  providedIn: 'root'
})
export class SessionHelper {
  private _storage: Storage;
  private _tokenKey: string = 'accessTokenInfo';

  constructor() {
    this._storage = sessionStorage;
  }

  set(key: string, value: any) {
    this._storage.setItem(key, JSON.stringify(value));
  }

  get<T>(key: string): T {
    const item = this._storage.getItem(key);
    if (!item || item === 'undefined' || item === null) {
      return null;
    }
    return <T>JSON.parse(item);
  }

  getUser() {
    const user = this._storage.getItem('user');
    if (!user || user === 'undefined' || user === null) {
      return null;
    }
    return <IUser>JSON.parse(user);
  }

  getPrivileges(hasAccess: boolean = false, contains: string = '') {
    const role = this.get<IRole>('role');
    if (!role || !role.privileges) {
      return [];
    }
    const permissions = role.privileges;
    if (hasAccess) {
      return permissions.filter(m => (contains === '' || m.code.indexOf(contains) >= 0)
        && (m.canAdd || m.canDelete || m.canRead || m.canUpdate));
    }
    return permissions;
  }

  isAuthenticated() {
    const token = this._storage.getItem('accessTokenInfo');
    if (!token || token === 'undefined' || token === null) {
      return false;
    }
    return JSON.parse(token).isAuthenticated;
  }

  removeAll() {
    this._storage.clear();
  }

  removeItem(key: string) {
    this._storage.removeItem(key);
  }

  public isTokenExpired(token?: string, offsetSeconds?: number) {
    const expiresIn = this.get<ILogin>(this._tokenKey).expires_in;
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(expiresIn);
    offsetSeconds = offsetSeconds || 0;
    if (date === null) {
      return false;
    }
    // Token expired?
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }

  /** Role Helper Methods */

  /**
   * get permission by given privilege code
   */
  getPermission(privilegeCode: string): IRolePrivilege {
    const role = this.get<IRole>('role');
    if (!role || !role.privileges) {
      return null;
    }
    let permissions = role.privileges;
    permissions = permissions.filter(pri => pri.code.toUpperCase() === privilegeCode.toUpperCase());
    return (permissions.length > 0) ? permissions[0] : null;
  }

  /** * returns access token for the given key */
  getAccessToken(key: string): any {
    const item = this._storage.getItem(key);
    if (item && item !== 'undefined') {
      return JSON.parse(this._storage.getItem(key));
    }
    return;
  }
}
