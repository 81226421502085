import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import {Utils} from '../../core/functions';

export interface IStackedProgressBarItem {
  label?: string;
  value: number;
  color?: string;
  class?: string;
}

@Component({
  selector: 'eclipse-stacked-progress-bar',
  templateUrl: './stacked-progress-bar.component.html',
  styleUrls: ['./stacked-progress-bar.component.scss']
})
export class StackedProgressBarComponent implements OnInit {
  @Input() public data: IStackedProgressBarItem[] = [];
  @Input() public toolTipPosition: string = 'right';
  @Input() public toolTipIncludeValue: boolean = true;

  public displayItems: IStackedProgressBarItem[] = [];
  public totalValue = 0;

  constructor() { }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData(): void {
    this.displayItems = this.data.filter(d => !!d.value); // remove all zero value items
    this.totalValue = this.displayItems.reduce((sum, d) => sum + d.value, 0);
  }

  getPercent(value: number): number {
    return 100 * value / this.totalValue;
  }

  getBgColor(item: IStackedProgressBarItem): string {
    return _.get(item, 'color', null);
  }

  getLabelValue(item: IStackedProgressBarItem): string {
    const label = _.get(item, 'label', '');

    if(this.toolTipIncludeValue) {
      const value = Utils.roundDecimal(+_.get(item, 'value', ''));
      return `${label}
(${value})`;
    } else {
      return label;
    }
  }
}
