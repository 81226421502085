<p-dialog [(visible)]="showSmaWeightingsPopup" header="Edit SMA Weightings" modal="true" [draggable]="false"
  [resizable]="false" class="ui-dialog-xl" (onHide)="onSmaEditCancel()">
  <div class="row">
    <div class="col-md-4">
      <div class="add-row-text text-info"> Add Row
        <a href="javascript:void(0)" class="add-btn-link" (click)="onAddRow()">
          <i class="fas fa-plus-circle" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div class="col-md-8 pull-right">
      <div class="text-right">
        <div class="form-group form-inline">
          <label class="cate-lable">Select Level</label>
          <select [(ngModel)]="SmaWeightingsModel.selectedLevel" class="form-control"
            (change)="onSmaSelectLevelChange()">
            <option *ngFor="let modelType of SmaWeightingsModel.modelTypes" value={{modelType.id}}> {{modelType.name}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="sma-weightings-table col-md-12">
      <div class="margin-top-15">
        <ag-grid-angular #agGrid class="ag-theme-alpine sma-grid" (gridReady)="onGridReady($event)"
          [rowData]="SmaWeightingsModel.smaWeightings"
          [gridOptions]="smaWeightingsGridOptions"
          [columnDefs]="smaWeightingsColumnDefs"
          [context]="gridContext">
        </ag-grid-angular>
      </div>
      <span class="col-sm-5 col-sm-push-3 margin-left-05">Total Percent : {{SmaWeightingsModel.smaTotalPercent}}%</span>
    </div>
  </div>
  <p-footer>
    <div class="row">
      <div class="col-md-7 margin-top-05">
        <span *ngIf="SmaWeightingsModel.isSmaValidationError"
          class="text-danger">{{SmaWeightingsModel.smaValidationError}}</span>
      </div>
      <div class="col-md-5">
        <button type="button" pButton data-dismiss="modal" [disabled]="SmaWeightingsModel.isSmaValidationError"
          (click)="onSmaWeightingsSave()" label="Save">
        </button>
        <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="onSmaEditCancel()"
          label="Cancel">
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="Save SMA Weightings" modal="true" [draggable]=false [resizable]=false
  [(visible)]="isShowSmaSaveWarningPopup" class="ui-dialog-lg">
  <p>
    This will refresh analytics, any unsaved trades will be deleted.
  </p>
  <p-footer>
    <button type="button" pButton (click)="onSmaWeightingsSaveConfirm()" label="Save Trades and Run Analytics"></button>
    <button type="button" pButton (click)="onSmaWeightingsSaveConfirm(true)"
      label="Delete Trades and Run Analytics"></button>
    <button type="button" pButton class="p-button-secondary" (click)="onSmaEditCancel()" label="Cancel"></button>
  </p-footer>
</p-dialog>
