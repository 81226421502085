<div class="app" [ngClass]="{'has-compact-menu': hasCompactMenu}">
  <aside class="app-aside app-aside-expand-md app-aside-dark">
    <!-- .aside-content -->
    <div class="aside-content">
      <!-- .aside-menu -->
      <div class="aside-menu overflow-hidden ps">
        <!-- .stacked-menu -->
        <nav id="stacked-menu" class="stacked-menu stacked-menu-has-hoverable"
             [ngClass]="{'stacked-menu-has-collapsible': !hasCompactMenu, 'stacked-menu-has-compact': hasCompactMenu  }">
          <!-- .menu -->
          <ul class="menu">
            <!-- Root level:  Portfolios, Accounts, Trades, etc -->
            <li *ngFor="let item of sideBarMenus" class="menu-item"
                [attr.data-test-id]="item.id"
                [ngClass]="{'has-child': item.children, 'has-open': hasCompactMenu && item.hasOpen, 'has-active': item.hasOpen || isActive(item), 'active': isActive(item)}"
                (mouseout)="closeSubMenu(item)" (mouseover)="showSubMenu(item)">
              <span *ngIf="item.type === 'Route'" class="menu-link"
                    [ngClass]="{'cursor':!item.children?.length}"
                    (click)="onLinkClick(item, item.children?.length ? null : $event)">
                <a class="menu-icon">
                  <i aria-hidden="true" class="{{item.icon}}"></i>
                </a>
                <span class="menu-text">{{item.text}}</span>
              </span>
              <span *ngIf="item.type === 'External'" class="menu-link" >
                <a class="menu-icon" [href]="item.link" target="_blank" [title]="item.text">
                  <i aria-hidden="true" class="{{item.icon}}"></i>
                </a>
                <a class="menu-text external-link-text" [href]="item.link" target="_blank">{{item.text}}</a>
              </span>

              <!-- child menu -->
              <ul *ngIf="item.children?.length" class="menu" (mouseover)="onMouseOverChildMenu(item)"
                  (mouseout)="onMouseOutChildMenu(item)">
                <li class="menu-subhead">{{item.text}}</li>
                <li *ngFor="let childItem of item.children" class="menu-item bg-gray-mid p-y-5"
                    [attr.data-test-id]="childItem.id"
                    [ngClass]="{'has-child':childItem.children, 'has-open': childItem.hasOpen, 'has-active': childItem.hasOpen || isActive(childItem), 'active': isActive(childItem)}">
                  <a *ngIf="childItem.link" class="menu-link" [routerLink]="childItem.link"
                     (click)="onLinkClick(childItem)"
                     (mouseover)="showSubMenu(childItem)"
                     (mouseout)="closeSubMenu(childItem)">
                    {{childItem.text}}
                    <span class="badge badge-pill" [ngClass]="{'badge-primary': counter?.total > 0}"
                          *ngIf="childItem.counter | async as counter">
                      <span *ngIf="counter?.delta">{{counter.delta.count}}
                        <i [ngClass]="{'text-danger': counter.delta.isDown, 'text-success': !counter.delta.isDown}"
                           class="fas fa-caret-{{counter.delta.isDown ? 'down' : 'up'}}" aria-hidden="true"></i>
                      </span>
                      <span> {{counter.total}}</span>
                    </span>
                  </a>
                  <a *ngIf="!childItem.link" class="menu-link" (click)="onLinkClick(childItem)"
                     (mouseover)="showSubMenu(childItem)"
                     (mouseout)="closeSubMenu(childItem)">
                    {{childItem.text}}
                    <span class="badge badge-pill" [ngClass]="{'badge-primary': counter?.total > 0}"
                          *ngIf="childItem.counter | async as counter">
                      <span *ngIf="counter.delta">{{counter.delta.count}}
                        <i [ngClass]="{'text-danger': counter.delta.isDown, 'text-success': !counter.delta.isDown}"
                           class="fas fa-caret-{{counter.delta.isDown ? 'down' : 'up'}}" aria-hidden="true"></i>
                      </span>
                      <span>{{counter.total}}</span>
                    </span>
                  </a>

                  <!-- grand child menu -->
                  <ul *ngIf="childItem.children" class="menu" (mouseover)="onMouseOverChildMenu(childItem)"
                      (mouseout)="onMouseOutChildMenu(childItem)">
                    <li *ngFor="let grandChildItem of childItem.children" class="menu-item bg-gray-light p-y-5"
                        [attr.data-test-id]="grandChildItem.id"
                        [routerLinkActive]="['active', 'has-active']" [routerLinkActiveOptions]="{exact: false}">
                      <a *ngIf="item.type === 'Route'" class="menu-link" [routerLink]="grandChildItem.link"
                         (click)="onLinkClick(grandChildItem)" (mouseover)="showSubMenu(grandChildItem)"
                         (mouseout)="closeSubMenu(grandChildItem)">
                        <span class="menu-text">{{grandChildItem.text}}</span>
                        <span class="badge badge-pill" [ngClass]="{'badge-primary': counter?.total > 0}"
                              *ngIf="grandChildItem.counter | async as counter">
                          <span *ngIf="counter.delta">{{counter.delta.count}}
                            <i [ngClass]="{'text-danger': counter.delta.isDown, 'text-success': !counter.delta.isDown}"
                               class="fas fa-caret-{{counter.delta.isDown ? 'down' : 'up'}}" aria-hidden="true"></i>
                          </span>
                          <span>{{counter.total}}</span>
                        </span>
                      </a>
                      <a *ngIf="item.type === 'External'" class="menu-link" (click)="onLinkClick(grandChildItem)"
                         (mouseover)="showSubMenu(grandChildItem)" [href]="grandChildItem.link"
                         (mouseout)="closeSubMenu(grandChildItem)">
                        <span class="menu-text">{{grandChildItem.text}}</span>
                      </a>
                    </li>
                  </ul>
                  <!-- /grand child menu -->
                </li>
              </ul>
              <!-- /child menu -->

            </li>

          </ul>
          <!-- /.menu -->
        </nav>
        <!-- /.stacked-menu -->
      </div>
      <!-- /.aside-menu -->

      <!-- Compact menu -->
      <footer class="aside-footer border-top sidebar-footer-padding">
        <button class="bs4-button sidebar-button" (click)="changeMenu()" title="Toggle Navigation">
          <i aria-hidden="true" class="fa"
             [ngClass]="{'fa-angle-double-right': hasCompactMenu,'fa-angle-double-left':!hasCompactMenu}"></i>
        </button>
        <span class="d-compact-menu-none sidebar-button-text" (click)="changeMenu()"> Collapse Sidebar</span>
      </footer>
      <!-- /Compact menu -->
    </div>
    <!-- /.aside-content -->
  </aside>
</div>

