import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EntityEditorService } from './entity-editor.service';
import { take } from 'rxjs/operators';
import { IEntityEditorConfig } from "./entity-editor";

/**
 * Monitors the router for navigation that includes the entity editor query parameters.
 */
@Component({
  selector: 'eclipse-entity-editor-monitor',
  template: '',
})
export class EntityEditorMonitorComponent {
  constructor(private readonly _route: ActivatedRoute, private readonly _router: Router, private readonly _entityEditor: EntityEditorService) {
    // to show editor if user reloads the page or if the initial login has the entity editor params.
    this._route.queryParams
       .pipe(take(1)) // take only the first value and complete, to avoid a memory leak
      .subscribe(params => this.handleQueryParameterChanges(params));
  }

  handleQueryParameterChanges(newParams: Params): void {
    if (!!newParams.editEntity) {
      // need to validate the type and id better
      const entityParts = newParams.editEntity.split('-');
      if (entityParts.length !== 2) {
        console.error('Invalid editor config format.  Must be `EntityType`-`EntityID`', newParams.entityEdit);
        return;
      }
      const entityType = entityParts[0];
      const entityId = +entityParts[1];

      if(!EntityEditorService.isSupportedEntityType(entityType)) {
        console.error('Invalid editor parameter (entity type)', entityType);
        return;
      } else if (entityId === null || entityId === undefined) {
        console.error('Invalid editor parameter (entity id)', entityId);
        return;
      }

      const editorConfig: IEntityEditorConfig = {
        entityType: EntityEditorService.entityTypeFromString(entityType),
        data: {
          id: entityId,
        }
      };

      if (newParams.editor !== undefined) {
        editorConfig.data.newEditor = 1;
      }

      this._entityEditor.show(editorConfig);
    }
  }

  /**
   * Router has popped a state (user navigated backwards).
   * Check if the next state on the stack has an entity editor config, and if found, show the entity editor.
   * This is to handle scenarios where the user has the editor open, navigates to a different page (which then hides the editor),
   * and then navigates back in the browser.
   * @param event
   */
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (event instanceof PopStateEvent) {
      // If the previous state has an editor config, show the editor.
      if (event.state?.previousParams?.config) {
        const config = event.state?.previousParams?.config;
        config.skipAddingQueryParameter = true;
        this._entityEditor.show(event.state?.previousParams?.config);
      }
    }
  }
}
