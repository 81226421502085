export const RebalanceFocusOption = {
    DISPLAY_NAME: {
        CATEGORY: 'Categories',
        CLASS: 'Classes',
        SUBCLASS: 'Sub Classes',
        SECURITY_SET: 'Security Sets',
        SLEEVE_STRATEGY: 'Sleeve Strategies',
        SLEEVE_MODEL: 'Sleeve Models'
    },
    ID: {
        CATEGORY: 1,
        CLASS: 2,
        SUBCLASS: 3,
        SECURITY_SET: 4,
        SLEEVE_STRATEGY: 5,
        SLEEVE_MODEL: 6
    }
};

export const TradeFilterMethod = {
    PORTFOLIO: 'portfolio',
    SLEEVE: 'sleeve',
    ACCOUNT: 'account',
    TRADE_GROUP: 'tradeGroups',
    MODEL: 'model',
    EXCEL_IMPORT: 'excelImport',
    SLEEVE_PORTFOLIO: 'sleevePortfolio',
    TRADE_GROUPS_FOR_ACCOUNT: 'tradeGroupsForAccount',
    TRADE_GROUPS_FOR_PORTFOLIO: 'tradeGroupsForPortfolio',
    INDIVIDUAL_SLEEVE: 'individualSleeve'
};

export const rebalanceTypeOption = {
    STANDARD_REBALANCE: 0,
    FULL_LOCATION_REBALANCE: 1,
    PARTIAL_LOCATION_REBALANCE: 2,
    FOCUSED_REBALANCE: 3,
    JOURNAL_ONLY_CASH_REBALANCE: 4,
    JOURNAL_CASH_AND_HOLDINGS_REBALANCE: 5,
    MONEY_MARKET_REBALANCE: 6
};

export const maximumCapitalGainsAllowedOption = {
    DEFAULT: 0,
    NO_LIMIT: 1,
    PORTFOLIO_MAX_GAIN: 2,
    MAX_GAIN_AMOUNT: 3
};

export const ALLOW_WASH_SALE = {
    DEFAULT: 0,
    YES: 1,
    NO: 2
};

export const ROUNDING = {
    DEFAULT: 0,
    NONE: 1
};

export const SLEEVE_ACCOUNT_METHOD = {
    INDIVIDUAL_SLEEVES: 'IndividualSleeves',
    DISTRIBUTION_SLEEVE: 'DistributionSleeve',
    CONTRIBUTION_SLEEVE: 'ContributionSleeve'
};

export const SECURITY_SUB_MODEL_FIELD_NAME = {
  MODEL_ELEMENT_ID: {
    CATEGORY: 'categoryModelElementId',
    CLASS: 'classModelElementId',
    SUBCLASS: 'subClassModelElementId',
    SECURITYSET: 'securitySetModelElementId'
  },
  MODEL_DETAIL_ID: {
    CATEGORY: 'categoryModelDetailId',
    CLASS: 'classModelDetailId',
    SUBCLASS: 'subClassModelDetailId',
    SECURITYSET: 'securitySetModelDetailId'
  }
};

export const ERROR_MESSAGE_POPUP = {
  ENTER_CAPITAL_GAIN_AMOUNT: 'You must enter the dollar amount for maximum capital gains.'
};
