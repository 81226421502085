import { Directive, ElementRef, HostListener, ViewContainerRef } from '@angular/core';
import { CellKeyDownEvent, GridApi, GridOptions, IsExternalFilterPresentParams } from '@ag-grid-community/core';
import { Input } from '@angular/core';
import { Utils } from '../../core/functions';
import { TradeBlockHelper } from '../../components/tradeorder/shared/trade-block-helper';

/**
 * Enables selecting all rows in an ag-grid by using the Ctrl+A key combination.
 * Note:  if they grid is filtered, only the filtered rows are selected.
 * Requires [gridOptions] also be bound on the grid.
 */
@Directive({
  selector: '[enableKeyboardSelectAllRows]'
})
export class AgGridSelectAllRowsDirective {
  @Input() isIgnoreChildBlock: string;

  @HostListener('cellKeyDown', ['$event']) onHandler(cellKeyDownEvent: CellKeyDownEvent): void {
    const event = cellKeyDownEvent.event as KeyboardEvent;
    if (Utils.isCtrlKey(event) && (event.key === 'a' || event.code === 'KeyA') && cellKeyDownEvent.api) {
      const gridApi = cellKeyDownEvent.api;
      const params = <IsExternalFilterPresentParams>{
        api: gridApi,
        context: gridApi.getGridOption('context')
      };
      const isExternalFilterPresent = gridApi.getGridOption('isExternalFilterPresent');
      if (gridApi.isColumnFilterPresent() ||
        (!!isExternalFilterPresent && isExternalFilterPresent(params))) {
        this.checkFlagAndSelectAllFiltered(gridApi);
      } else {
        this.checkFlagAndSelectAll(gridApi);
      }
    }
  }

  private checkFlagAndSelectAll(gridApi: GridApi): void {
    if (this.isIgnoreChildBlock) {
      gridApi.forEachNode(node => {
        if (!TradeBlockHelper.isTradeAwayBlockChild(<any>node)) {
          node.setSelected(true);
        }
      });
    } else {
      gridApi.selectAll();
    }
  }

  private checkFlagAndSelectAllFiltered(gridApi: GridApi): void {
    if (this.isIgnoreChildBlock) {
      gridApi.forEachNodeAfterFilter(node => {
        if (!TradeBlockHelper.isTradeAwayBlockChild(<any>node)) {
          node.setSelected(true);
        }
      });
    } else {
      gridApi.selectAllFiltered();
    }
  }
}
