<p-dialog [(visible)]="showImportPopUp" header="Import {{moduleName}} File" [resizable]="false" modal="true"
          class="ui-dialog-lg">
  <div class="ui-dialog-content col-sm-12 form-group">
    <div class="row">
      <div class="col-sm-8">
        <div (dragover)="dragFile($event)" (drop)="dropFile($event)">
          <div class="choose-file-small-box file-upload-input-style">
              <span pButton class="p-button-sm"> Choose File
                 <input type="file" name="importFile" id="import-file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        #fileImportInput (change)="importCsvFile($event)"/>
                </span><br> or drag and drop here
            <div *ngIf="!disableUploadBtn">
              <p *ngIf="checkDragFile">{{dragFileName}}</p>
              <p *ngIf="!checkDragFile">{{file.name}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 import-csv">
        <a *ngIf="path" pButton href="{{path}}" class="pull-right" icon="fas fa-download" label="Blank Template"></a>
        <a *ngIf="!path" pButton href="javascript:void(0);" (click)="parentBlankTemplateCallBack.emit()"
           class="pull-right" icon="fas fa-download" label="Blank Template"></a>
        <button pButton class="m-t-10 pull-right" (click)="exportFileData()" icon="fas fa-download"
                label="Existing Data"></button>

        <button pButton class="p-button-secondary pull-right about-import-button" label="About This Import" (click)="parentAboutImportCallBack.emit()"></button>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="pull-left">
      <em class="securityError danger" *ngIf="showFileUploadError">{{fileUploadError}}</em>
    </div>
    <button type="button" pButton icon="fas fa-check" [disabled]="disableUploadBtn"
            (click)="uploadTemplate()" label="Upload {{moduleName}}"></button>
    <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="cancelImport()"
            label="Cancel"></button>
  </p-footer>
</p-dialog>
