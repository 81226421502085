import { Component, Input } from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {IPreferences} from '../../models/preferences/preference';

@Component({
  selector: 'eclipse-mac-sell-preference',
  templateUrl: './macsell.component.html'
})
export class MacSellComponent extends BaseComponent {
  selectedSetting: any;
  toleranceSeleted: string;
  @Input() toleranceSettingsSell: IPreferences[];
  @Input() preferenceViewModel: IPreferences[];
  toleranceSettingSellOptions: any[];
  objToleranceSettingSell: any[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
    // eslint-disable-next-line eqeqeq
    this.toleranceSeleted = this.toleranceSettingsSell.filter(s => s.name == 'toleranceSettingSell')[0].selectedOptions[0].name;
    // eslint-disable-next-line eqeqeq
    this.toleranceSettingSellOptions = this.toleranceSettingsSell.filter(s => s.name == 'toleranceSettingSell')[0].options;
    this.toleranceSettingsSell.forEach((setting, index) => {
      // Code from here onward will only run for entries that aren't
      // the first entry
      if (index > 0) {
        this.objToleranceSettingSell.push(setting);
      }
    });
  }

  dropdownSelectedOption(event) {
    this.toleranceSeleted = event.currentTarget.value;
    // eslint-disable-next-line eqeqeq
    const optionSel = this.toleranceSettingSellOptions.find(o => o.name == this.toleranceSeleted);
    const preff = this.preferenceViewModel;
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).selectedOptions.splice(0, 1, optionSel);
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = false;
  }
  /** Set Checkbox value */
  setCheckBoxValue(event) {
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value = (event.target.checked) ? 'true' : 'false';
    // eslint-disable-next-line eqeqeq
    this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = false;
  }
  setResetControl(event, dynamicControlData) {
    // eslint-disable-next-line eqeqeq
    if (dynamicControlData.componentName == 'Dropdown') {
      if (dynamicControlData.inheritedSelectedOptions.length > 0 && dynamicControlData.isInherited) {
        const InheritValue = dynamicControlData.inheritedSelectedOptions[0]['id'];
        // (document.getElementById(event.currentTarget.id))["value"] = InheritValue;
        // eslint-disable-next-line eqeqeq
        this.preferenceViewModel.find(p => p.name == event.currentTarget.id).selectedOptions = this.preferenceViewModel.find(p => p.name == event.currentTarget.id).options[0];
        // eslint-disable-next-line eqeqeq
        this.preferenceViewModel.find(p => p.name == event.currentTarget.id).value = InheritValue;
        // eslint-disable-next-line eqeqeq
        this.preferenceViewModel.find(p => p.name == event.currentTarget.id).resetToParent = true;
        // this.form.controls[event.currentTarget.id]["_value"] = InheritValue;
        // eslint-disable-next-line eqeqeq
        this.toleranceSeleted = this.preferenceViewModel.find(p => p.name == event.currentTarget.id).options[0].name;
      }
    }
  }

}
