import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ConfigService, EnvironmentType } from '../config/config.service';

/**
 * Provides a strategy for updating the page title.  Uses the `title` provided on the
 * nearest route on the primary outlet.
 *
 * Sets page title using the format:  [Environment] Eclipse - PageName
 * Where [Environment] will display for non-production environments.
 *
 */
@Injectable()
export class TitleTemplateStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private readonly configService: ConfigService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const titleFromRouterState = this.buildTitle(routerState);

    // Prepend the environment name to the title
    const environmentType = this.configService.getEnvironment();
    let environmentName = '';
    if (this.configService.isLocal()) {
      environmentName = `[Local] `;
    } else if (environmentType !== EnvironmentType.Production) {
      environmentName = `[${(EnvironmentType[environmentType] || 'unknown')}] `;
    }

    let title = `${environmentName}Eclipse`;
    if (titleFromRouterState) {
      title += ` - ${titleFromRouterState}`;
    }

    this.title.setTitle(title);
  }
}
