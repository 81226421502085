<div [ngSwitch]="displayPermission">
  <div *ngSwitchCase="true">
    <div class="firm-security-setting-header">
      <div class="form-inline">
        <div class="form-group">
          <select class="form-control watermarked gray" [(ngModel)]="selectedSecurityTypeId" (change)="handleSelectedSecurityType($event.target.value)">
            <option value="null" selected>Please Select</option>
            <option *ngFor="let st of securityTypes" value={{st.id}}>{{st.name}}</option>
          </select>
          <button type="button" pButton class="customBtn m-l-10" title="Set Preferences" (click)="setPrefPopup(selectedSecurityType)"
                  [disabled]="btnDisableSetPref" label="Add"></button>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="true" class="grid-control">
    <ag-grid-angular #agGrid style="width: 100%; height: 100px;" class="ag-theme-alpine"  [gridOptions]="gridOptions" rowSelection="single"
                     [columnDefs]="columnDefs" [rowData]="PrefrowData" (rowClicked)="rowSelected($event)" [getContextMenuItems]="getContextMenuItems" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <div *ngSwitchCase="false" class="grid-control">
    <ag-grid-angular class="ag-theme-alpine" #agGrid style="width: 100%; height: 100px;" [columnDefs]="columnDefs" [rowData]="PrefrowData"
                     [gridOptions]="gridOptions" [suppressContextMenu]="true" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <p-dialog header="Trade Settings" modal="true" [resizable]="false" [(visible)]="displayTradeSettingsPref" class="ui-dialog-xl">
    <div class="col-sm-12 security-preferences-modal-body">
      <div class="col-sm-6">
        <div class="form-group">
          Security Type:<span class="margin-left-05">{{selectedSecurityType}}</span>
        </div>
      </div>
      <div class="row">
        <fieldset class="col-md-12 security-preferences-fieldset">
          <legend>Trade Settings</legend>
          <div class="col-sm-3">
            <div class="form-group">
              Sell Priority:
              <select id="ddlType" class="form-control" [(ngModel)]="tradeSettingsPref.sellPriority" (ngModelChange)="setDropdownSelectedOption($event,'sellPriority')">
                <option value=null>Select</option>
                <option *ngFor="let r of sellPriorityList" [ngValue]="r.id" >
                  {{r.displayName}}
                </option></select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              Buy Priority:
              <select id="ddlType" class="form-control" [(ngModel)]="tradeSettingsPref.buyPriority" (ngModelChange)="setDropdownSelectedOption($event,'buyPriority')">
                <option value=null>Select</option>
                <option *ngFor="let r of buyPriorityList" [ngValue]="r.id" >
                  {{r.displayName}}
                </option></select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              Excluded from Trading:
              <select id="ddlType" [disabled]="fromParentPrefLevel === 'model' || fromParentPrefLevel === 'custodian'" class="form-control" [(ngModel)]="tradeSettingsPref.excludedFromTrading"
                      (ngModelChange)="setDropdownSelectedOption($event,'excludedFromTrading')">
                <option *ngFor="let r of yesOrNOoData" [ngValue]="r.value" >
                  {{r.name}}
                </option></select>
            </div>
          </div>
          <div *ngIf="displayPermission == 'false'" class="col-sm-3">
            <div class="form-group">
              Inherited from:
              <label>{{tradeSettingsPref.inheritedFrom}}</label>
            </div>
          </div>
        </fieldset>
      </div>

    </div>
    <p-footer>
      <button type="button" pButton class="p-button-secondary" icon="fas fa-times" (click)="cancel()" label="Cancel"></button>
      <button type="button" pButton icon="fas fa-check" (click)="addtoGrid()" label="Add"></button>
    </p-footer>
  </p-dialog>
</div>
