<div class="security-preference-tab">
  <p-tabView styleClass="tabs-only" [activeTabName]="selectedTabName">
    <p-tabPanel *ngFor="let tab of securityPreferenceTabMap" tabName="{{tab.tabName}}">
      <ng-template pTemplate="header">
        <a [id]="tab.id" [href]="tab.href" (click)="onTabChange(tab)">{{tab.tabName}}</a>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>

<div>
  <div [hidden]="selectedTabName !== 'pills-all-tab'">
    <eclipse-security-preference
      [displayPermission]="displayPermission"
      [preferenceLevel]="preferenceLevel">
    </eclipse-security-preference>
  </div>

  <div class="security-preference-tab-content" [hidden]="selectedTabName !== 'pills-target-tab'">
    <eclipse-targeted-security
      [preferenceLevelId]="preferenceLevelId"
      [preferenceLevel]="preferenceLevel">
    </eclipse-targeted-security>
  </div>

  <div class="security-preference-tab-content" [hidden]="selectedTabName !== 'pills-holding-tab'">
    <eclipse-holding-security
      [preferenceLevelId]="preferenceLevelId"
      [preferenceLevel]="preferenceLevel">
    </eclipse-holding-security>
  </div>

  <div class="security-preference-tab-content" [hidden]="selectedTabName !== 'pills-esg-tab'">
    <eclipse-esg-restricted-securities
      [preferenceLevelId]="preferenceLevelId"
      [preferenceLevel]="preferenceLevel">
    </eclipse-esg-restricted-securities>
  </div>
</div>
