import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IAnalyticsCountUpdate} from '../../../models/AnalyticsCountUpdate';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsCountUpdateHubService {

  private readonly _showAndHideCountMessageNotification: Subject<boolean> = new Subject();
  public get showAndHideCountMessageNotification(): Observable<boolean> {
    return this._showAndHideCountMessageNotification.asObservable();
  }

  private readonly _updateAnalyticsCountUpdate: Subject<IAnalyticsCountUpdate> = new Subject();
  public get updateAnalyticsCountUpdate(): Observable<IAnalyticsCountUpdate> {
    return this._updateAnalyticsCountUpdate.asObservable();
  }

  private readonly _removeIdsFromListAndUpdateCount: Subject<IAnalyticsCountUpdate> = new Subject();
  public get removeIdsFromListAndUpdateCount(): Observable<IAnalyticsCountUpdate> {
    return this._removeIdsFromListAndUpdateCount.asObservable();
  }

  constructor() {
  }

  public requestShowAndHideCountMessageNotification(flag: boolean): void {
    this._showAndHideCountMessageNotification.next(flag);
  }

  public requestUpdateAnalyticsCountUpdate(analyticsCountUpdate: IAnalyticsCountUpdate): void {
    this._updateAnalyticsCountUpdate.next(analyticsCountUpdate);
  }

  public requestRemoveIdsFromListAndUpdateCount(analyticsCountUpdate: IAnalyticsCountUpdate): void {
    this._removeIdsFromListAndUpdateCount.next(analyticsCountUpdate);
  }
}
