import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {IAppVersion} from './interfaces';
import {share} from 'rxjs/operators';
import { development } from "../../environments/development";

@Injectable()
export class VersionCheckService {
  currentHash: string = null;

  private versionChangedObserver: Observer<void>;
  public versionChanged: Observable<void>; // emits a change if the application version changes
  public newVersionAvailable: boolean = false;
  private currentVersionSubject: BehaviorSubject<IAppVersion> = new BehaviorSubject<IAppVersion>(null);
  public readonly currentVersion$: Observable<IAppVersion>;
  public get currentVersion(): IAppVersion {
    return this.currentVersionSubject.value;
  }

  constructor(private http: HttpClient) {
    this.currentVersion$ = this.currentVersionSubject.asObservable().pipe(share());
    this.versionChanged = new Observable<void>((sender: Observer<void>) => {
      this.versionChangedObserver = sender;
    });
    this.versionChanged.subscribe();
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 5 minutes
   */
  public initVersionCheck(url: string, frequency: number = 1000 * 60 * 5): void {
    // Don't initiate version checking on local builds
    if (development.local) {
      return;
    }
    setInterval(() => {
      this.checkVersion(url, new Date().getTime());
    }, frequency);
    this.checkVersion(url, new Date().getTime());
  }

  private static createNoCacheHeader() {
    let noCacheHeaders = new HttpHeaders();
    noCacheHeaders = noCacheHeaders.append('Cache-Control', 'no-cache');
    noCacheHeaders = noCacheHeaders.append('Pragma', 'no-cache');
    noCacheHeaders = noCacheHeaders.append('Expires', '0');

    return noCacheHeaders;
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  checkVersion(url: string, timestamp: number): void {
    // timestamp these requests to invalidate caches
    this.http.get(`${url}?t=${timestamp}`, {headers: VersionCheckService.createNoCacheHeader()})
      .subscribe(
        (appVersion: IAppVersion) => {
          const timestamp = appVersion.timestamp;
          const hash = appVersion.hash;
          const hashTimestamp = hash + timestamp;

          // if this version is ignored, don't force a version change alert
          if (appVersion.ignore && appVersion.ignore === 'Yes') {
            return;
          }

          // If hashes don't match, then a new version was deployed
          if (VersionCheckService.hasHashChanged(this.currentHash, hashTimestamp)) {
            this.emitVersionChanged();
          }
          // store the new hash to prevent triggering versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hashTimestamp;
          this.currentVersionSubject.next(appVersion);
        }
      );
  }

  public emitVersionChanged(): void {
    this.versionChangedObserver.next(null);
    this.newVersionAvailable = true;
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  static hasHashChanged(currentHash: string, newHash: string): boolean {
    if (!currentHash) {
      return false;
    }
    return currentHash !== newHash;
  }
}
