import {Component, Input, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../core/base.component';
import {EquivalentImportExportService} from '../../../../services/equivalent-import-export.service';
import {IMPORT_EXPORT_MODULE_NAME, ModuleName, RELATED_TYPE} from '../../../../libs/app.constants';
import {FileImportExportComponent} from '../../../filehandler/file.importexport.component';
import {
  IEquivalentExportFileData,
  IEquivalentExportResponse, IEquivalentFileDownload
} from '../../../../models/preferences/securityPreferenceEquivalents';
import {AlertService} from '../../../../core';
import {IModelExcelImport} from '../../../../models/modeling/modelexcelimport';
import * as _ from 'lodash';

@Component({
  selector: 'eclipse-equivalent-import-export',
  templateUrl: './equivalent-import-export.component.html',
  styleUrls: ['./equivalent-import-export.component.scss']
})
export class EquivalentImportExportComponent extends BaseComponent {

  commonHeaders = ['Targeted', 'Equivalent', 'Equivalent Buy Priority', 'Equivalent Sell Priority', 'Delete'];
  portfolioHeader = ['Portfolio ID'];
  accountHeader = ['Account ID'];
  ids: number[];
  path: string;
  moduleName: string;
  @Input() downloadFileName: string;
  @ViewChild(FileImportExportComponent) fileImportExportComponent: FileImportExportComponent;
  showAboutImportPopUp: boolean;
  preferenceLevel: string;

  constructor(private readonly _equivalentImportExportService: EquivalentImportExportService,
              private readonly _alertService: AlertService) {
    super();
  }

  showEquivalentImportExportPopUp(moduleName: string): void {
    this.moduleName = moduleName;
    this.fileImportExportComponent.showPopUp(moduleName);
    this.fileImportExportComponent.ids = this.ids;
    this.preferenceLevel = moduleName === IMPORT_EXPORT_MODULE_NAME.PORTFOLIO_EQUIVALENTS ? ModuleName.Portfolio : ModuleName.Account;
  }

  exportPortfolioEquivalentData(): void {
    const relatedType = this.moduleName === IMPORT_EXPORT_MODULE_NAME.PORTFOLIO_EQUIVALENTS ? RELATED_TYPE.PORTFOLIO : RELATED_TYPE.ACCOUNT;
    this.ids = _.uniq(this.ids);
    this._equivalentImportExportService.exportEquivalentData(this.ids, relatedType)
      .subscribe((equivalents: IEquivalentExportResponse[]) => {
        equivalents = _.orderBy(equivalents, 'relatedTypeId');
        const equivalentsFileData = this.createEquivalentExportFileData(equivalents);
        const headers = this.getExportFileHeaders();
        this.fileImportExportComponent.exportToExcel(equivalentsFileData, headers);
      });
  }

  getExportFileHeaders(): string[] {
    let headers = this.accountHeader;
    if (this.moduleName === IMPORT_EXPORT_MODULE_NAME.PORTFOLIO_EQUIVALENTS) {
      headers = [...this.portfolioHeader, ...this.commonHeaders];
    } else {
      headers = [...headers, ...this.commonHeaders];
    }
    return headers;
  }

  createEquivalentExportFileData(equivalents: IEquivalentExportResponse[]): IEquivalentExportFileData[] {
    const equivalentsFileData = [] as IEquivalentExportFileData[];
    for (const equivalent of equivalents) {
      equivalentsFileData.push({
        relatedTypeId: equivalent.relatedTypeId,
        targetedSecurity: equivalent.targetedSecuritySymbol,
        equivalentSecurity: equivalent.equivalentSecuritySymbol,
        buyPriority: equivalent.buyPriorityName || 'Default',
        sellPriority: equivalent.sellPriorityName || 'Default',
        isDeleted: null
      });
    }
    return equivalentsFileData;
  }


  importSecurityEquivalent(file: IModelExcelImport): void {
    this._equivalentImportExportService.importEquivalentTemplate(file)
      .subscribe(message => {
          this._alertService.alert.emit([{typeId: 1, message: message}]);
        },
        error => {
          const errorLog = JSON.parse(error).message;
          this._alertService.alert.emit([{typeId: 4, message: errorLog}]);
        });
  }

  getBlankTemplate(): void {
    const relatedType = this.moduleName === IMPORT_EXPORT_MODULE_NAME.PORTFOLIO_EQUIVALENTS ? RELATED_TYPE.PORTFOLIO : RELATED_TYPE.ACCOUNT;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this._equivalentImportExportService.exportEquivalentBlankTemplate(relatedType)
      .subscribe((data: IEquivalentFileDownload) => {
        if (data.isSuccess) {
          this.downloadByteArrayFile(data.content, this.downloadFileName, fileType);
        } else {
          this._alertService.alert.emit([{typeId: 4, message: 'File Not Found.'}]);
        }
      });
  }
}
