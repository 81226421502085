<p-dialog header="Exclude from" [resizable]="false" [(visible)]="showExcludeFilter" [modal]="true"
          class="ui-dialog-xl" (onHide)="closeExcludeFilterPopUp()">
  <div>
    <h4><strong> Bar Charts </strong></h4>
    <ng-template *ngIf="filters && filters.length > 0" ngFor [ngForOf]="filters" let-filter="$implicit" let-index="index">
        <div class="exclude-filter left-margin">
            <label class="container">{{filter.name}}
              <input type="checkbox" [value]="filter.value" [(ngModel)]="filter.value" (change)="setDefaultOption(filter)">
              <span class="checkmark"></span>
            </label>
            <ul class="form-inline tabs-data">
              <li *ngFor="let option of filter.options" class="radio purple_radio">
                <label><input type="radio" [disabled]="!filter.value" name="{{filter.name}}" [checked]="filter.selectedOption == option.field" (click)="onOptionChange(filter, option.field)">
                  <span class="circle"></span><span class="check"></span> {{option.name}}
                </label>
              </li>
              <li *ngIf="filter.showDateField" class="calender">
                <p-calendar [hidden]="!filter.value" [showIcon]="true" [monthNavigator]="true" [yearRange]="getYearRange(10)" [locale]="en" [minDate]="todayDate" placeholder="Select Date" [yearNavigator]="true"
                [(ngModel)]="filter.selectedValue" appendTo="body"
                [readonlyInput]="true"></p-calendar>
              </li>
            </ul>
        </div>
    </ng-template>
    <span *ngIf="errMessage" class="text-danger"> {{errMessage}}</span>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-secondary" data-dismiss="modal" (click)="closeExcludeFilterPopUp()" label="Cancel"></button>
    <button pButton [disabled]="filters && filters.length == 0"  type="button" data-dismiss="modal" (click)="saveExcludeFilter()" label="Save"></button>
  </p-footer>
</p-dialog>
