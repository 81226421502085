<div *ngIf="displayControl" class="form-group">
  <div style="display: flex;">
    <ng-container *ngIf="!!model && savedViews?.length">
      <p-dropdown ariaLabel="View list" id="selectView" class="form-control" [options]="savedViews" [(ngModel)]="model.id"
                  optionValue="id" optionLabel="name" [style]="{'width': '100%'}"
                  (onChange)="onCustomViewChange($event)" appendTo="body">
        <ng-template let-item pTemplate="selectedItem">
          {{item.name}} <span *ngIf="item.isPublic">*</span>
        </ng-template>
        <ng-template let-pc pTemplate="item">
          {{pc.name}} <span *ngIf="pc.isPublic">*</span>
        </ng-template>
      </p-dropdown>
      <div style="padding: 8px 0;">
        <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
        <button type="button" aria-label="Save and Update View" title="Save and Update View" pButton icon="far fa-angle-down" iconPos="right" (click)="buildMenu(); menu.toggle($event)">
          <i class="fas fa-edit" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<p-dialog [header]="popupText" [(visible)]="displaySaveViewAsDialog" modal="true"
          [resizable]=false appendTo="body" class="ui-dialog-lg" [closable]="false">
  <div class="form-group">
    <label class="col-md-6 col-sm-6 control-label">{{popupText.trim()}} as</label>
    <div class="col-md-6 col-sm-6">
      <input id="newViewName" type="text" [(ngModel)]="view.name" placeholder="Enter View Name" value="" class="form-control" pInputText
             (keyup)="hideError()" [autofocus]="true"> <span *ngIf="isNameRequired" class="securityError error"></span>
      <span *ngIf="isViewNameExists" class="securityError error">View name already exists</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-md-offset-6">
      <p-checkbox name="default" ngDefaultControl [(ngModel)]="view.isDefault" binary="true" label="Set as default"></p-checkbox>
    </div>
    <div class="col-md-6 col-md-offset-6" *ngIf="canCreatePublicViews">
      <p-checkbox name="public" ngDefaultControl [(ngModel)]="view.isPublic" binary="true" [trueValue]="1" [falseValue]="0" label="Public"></p-checkbox>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton class="p-button-secondary" icon="fas fa-times" label="Cancel" (click)="closeSaveViewDialog()"></button>
    <button type="submit" pButton label="Save" icon="fas fa-check" (click)="saveViewAs()"></button>
  </p-footer>
</p-dialog>

<p-dialog [visible]="model?.exitWarning?.show" modal="true" [draggable]=false
          [resizable]=false class="ui-dialog-md" [closable]="false">
  <div>You seem to have made some change(s) in the view. Do you want to save the view?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(false)"
            label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="confirmClick(true)"
            label="Yes"></button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="displayUpdateConfirmDialog" modal="true" [draggable]=false
          [resizable]=false class="ui-dialog-md" [closable]="false">
  <div>You seem to have made some change(s) in the view. Do you want to update the view?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="confirmClick(true)"
            label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="confirmClick(false)"
            label="Yes"></button>
  </p-footer>
</p-dialog>

<p-dialog header="Delete View" [(visible)]="displayDeleteConfirmDialog" modal="true"
          [draggable]=false [resizable]=false class="ui-dialog-md"  (onHide)="displayDeleteConfirmDialog=false">
    <div>This action will permanently delete the selected view. Do you wish to continue?</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" class="p-button-secondary"
            (click)="displayDeleteConfirmDialog=false"
            label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="deleteView(model?.id)"
            label="Yes"></button>
  </p-footer>
</p-dialog>
