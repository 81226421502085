import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { NavigationExtras, Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardCategory, DashboardEntityModule, IDashboardDetail } from '../../models/dashboard';
import { IAccountModalSummary, IDashboardSummary, IModalSummary } from '../../models/mainDashboard';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDashboardCustomize } from '../../models/dashboardfilter';
import * as Consts from '../../libs/app.constants';
import { SessionHelper } from '../../core';

@Component({
  selector: 'eclipse-metric-dashboard-item',
  templateUrl: './metric-dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss'],
})
export class MetricDashboardItemComponent extends BaseComponent {
  private readonly _router: Router = inject(Router);
  private readonly _dashboardService: DashboardService = inject(DashboardService);

  @Input() isEditing: boolean;
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCustomizing: EventEmitter<IDashboardCustomize> = new EventEmitter<IDashboardCustomize>();
  public moduleSummary: IModalSummary;
  public dataValue: number;
  public percentValue: number;
  public isCustomizable: boolean = false;
  public canEditOptions: boolean = false;
  public dashboardLabelCategory: string = null;
  public entityModule: DashboardEntityModule;
  private dataStream$: Subscription;
  private overriddenFilter: boolean = false;
  private lastSummaryValue = null;

  constructor(private readonly sessionHelper: SessionHelper) {
    super('', sessionHelper);
  }

  private _isCustomizing: boolean = false;

  public get isCustomizing(): boolean {
    return this._isCustomizing;
  }

  @Input()
  public set isCustomizing(value: boolean) {
    this._isCustomizing = value;
    if (value) {
      this.showOptions();
    }
  }

  private _dashboardSummary: IDashboardSummary;

  @Input()
  public set dashboardSummary(value: IDashboardSummary) {
    this._dashboardSummary = value;
    this.refreshModuleSummary();
  }

  private _detail: IDashboardDetail;

  public get detail(): IDashboardDetail {
    return this._detail;
  }

  @Input()
  public set detail(value: IDashboardDetail) {
    this._detail = value;
    this.entityModule = this.getEntityModule();
    this.refreshModuleSummary();
  }

  public get moduleSummaryTotal(): number {
    if (!this.moduleSummary || !this.detail) {
      return 0;
    }
    switch (this.detail.dashboardField?.dashboardCategoryId) {
      case DashboardCategory.ASTRO:
        return (<IAccountModalSummary>this.moduleSummary).totalAstro;
      default:
        return this.moduleSummary.total;
    }
  }

  ngOnInit() {
    this.attachDataStream();
  }

  ngOnDestroy(): void {
    this.detachDataStream();
  }

  attachDataStream() {
    this.detachDataStream();

    let obs: Observable<any> = null;
    switch (this.detail?.dashboardField?.dashboardCategoryId) {
      case DashboardCategory.Portfolios:
        obs = this._dashboardService.portfolioDashboardData;
        break;
      case DashboardCategory.Accounts:
      case DashboardCategory.ASTRO:
        obs = this._dashboardService.accountDashboardData
          .pipe(map(result => result.bars));
        break;
      case DashboardCategory.Trades:
        obs = this._dashboardService.tradeOrderDashboardData
          .pipe(map(result => {
            const tradeObj = {};
            result.tomFilterSummary.forEach(f => tradeObj[f.filterType] = f.value);
            return tradeObj;
          }));
        break;
      case DashboardCategory.Securities:
        // We only have one security dashboard item and it comes from the existing dashboard summary.
        // When more security items are added, a dashboard endpoint will need to be created and utilized here.
        // obs = this._dashboardService.securityDashboardData;
        const securityObj = {};
        securityObj[this.detail.dashboardField.fieldName] = this.moduleSummary?.warning;
        obs = of(securityObj);
        break;
      case DashboardCategory.Models:
        obs = this._dashboardService.modelDashboardData;
        break;
      default:
        return;
    }

    this.dataStream$ = obs.subscribe(result => {
      this.lastSummaryValue = result;
      this.dataValue = result[this.detail.dashboardField.fieldName];
      this.calculateValues();
      if (!!this.detail.dashboardField.filterLabelShortCode) {
        this.setDashboardFilterCategory();
      }
    });
  }

  detachDataStream(): void {
    if (this.dataStream$ && !this.dataStream$.closed) {
      this.dataStream$.unsubscribe();
    }
  }

  calculateValues(): void {
    this.percentValue = 0;
    const moduleTotal = this.moduleSummaryTotal;
    if (!moduleTotal || !this.dataValue) {
      return;
    }
    this.percentValue = (this.dataValue / moduleTotal) * 100;
  }

  refreshModuleSummary(): void {
    this.moduleSummary = this._dashboardSummary?.warningsSummary.find(s => s.moduleName === this.entityModule.name);
    if (!!this.moduleSummary) {
      this.attachDataStream();
    }
  }

  /**
   * Returns the name of the module from the moduleSummary that corresponds with the
   * dashboard category for this item.
   */
  getEntityModule(): DashboardEntityModule {
    switch (this.detail?.dashboardField?.dashboardCategoryId) {
      case DashboardCategory.Portfolios:
        return {name: 'Portfolios', icon: 'fas fa-fw fa-cubes'};
      case DashboardCategory.Accounts:
      case DashboardCategory.ASTRO:
        return {name: 'Accounts', icon: 'fas fa-fw fa-briefcase'};
      case DashboardCategory.Trades:
        return {name: 'TradeOrders', icon: 'fas fa-fw fa-exchange'};
      case DashboardCategory.Securities:
        return {name: 'Securities', icon: 'fas fa-fw fa-archive'};
      case DashboardCategory.Models:
        return {name: 'Models', icon: 'fas fa-fw fa-code-branch fa-flip-vertical'};
    }
    return { name: `NOT FOUND -${this.detail?.dashboardField?.dashboardCategoryId || 0}-`, icon: 'fas fa-fw fa-exclamation-triangle'};
  }

  public navigate(): void {
    const path = this.getNavigationPath();
    const queryParams = this.getQueryParams();
    this._router.navigate(path, queryParams);
  }

  public getNavigationPath(): string[] {
    switch (this.detail.dashboardField?.dashboardCategoryId) {
      case DashboardCategory.Portfolios:
        return ['/eclipse', 'portfolio', 'list'];
      case DashboardCategory.Accounts:
        return ['/eclipse', 'account', 'list'];
      case DashboardCategory.ASTRO:
        const astroPath = ['/eclipse', 'account', 'alerts'];
        if (!!this.detail.dashboardField.filterTypeId) {
          astroPath.push(this.detail.dashboardField.filterTypeId.toString());
        }
        return astroPath;
      case DashboardCategory.Trades:
        const tradePath = ['/eclipse', 'tradeorder', 'list'];
        if (!!this.detail.dashboardField.filterTypeId) {
          tradePath.push(this.detail.dashboardField.filterTypeId.toString());
        }
        return tradePath;
      case DashboardCategory.Securities:
        const securityPath = ['/eclipse', 'security', 'maintenance', 'list'];
        if (!!this.detail.dashboardField.filterTypeId) {
          securityPath.push(this.detail.dashboardField.filterTypeId.toString());
        }
        return securityPath;
      case DashboardCategory.Models:
        return ['/eclipse', 'model', 'list'];
      default:
        break;
    }
    return null;
  }

  public getQueryParams(): NavigationExtras {
    if (!this.detail.dashboardField.filterTypeId) {
      return undefined;
    }
    switch (this.detail.dashboardField?.dashboardCategoryId) {
      case DashboardCategory.Portfolios:
      case DashboardCategory.Accounts:
      case DashboardCategory.Models:
        return {queryParams: {filter: this.detail.dashboardField.filterTypeId}};
      case DashboardCategory.Trades:
      case DashboardCategory.Securities:
      case DashboardCategory.ASTRO:
      default:
        break;
    }
    return undefined;
  }

  /**
   * Sends a request to the parent container to delete this item.
   */
  public requestDelete(): void {
    this.onDelete.next(this.detail.dashboardFieldId);
  }

  setDashboardFilterCategory(): void {
    switch (this.detail?.dashboardField?.dashboardCategoryId) {
      case DashboardCategory.Portfolios:
        const customizableFilters = this.lastSummaryValue?.overRiddenfirmDefault;
        const customized = customizableFilters[this.detail.dashboardField.filterLabelShortCode];
        if (customized !== undefined) {
          this.setCustomizedCategory(customized, 'portfolio');
        }
        break;
      case DashboardCategory.Accounts:
      case DashboardCategory.Trades:
      case DashboardCategory.Securities:
      case DashboardCategory.Models:
      case DashboardCategory.ASTRO:
      default:
        break;
    }
  }

  /**
   * Sets values that allow a category to be customized by the user.
   * @param isCustomized is the category already customized
   * @param categoryName
   */
  public setCustomizedCategory(isCustomized: boolean, categoryName: string): void {
    this.dashboardLabelCategory = categoryName;
    this.overriddenFilter = isCustomized;
    this.isCustomizable = true;
    this.canEditOptions = !!this.getPermission(Consts.PRIV_DASHBOARD_DEFAULTS)?.canUpdate;
  }

  showOptions(): void {
    this.onCustomizing.emit({
      type: this.dashboardLabelCategory,
      filterId: this.detail.dashboardField.filterTypeId,
      overridden: this.overriddenFilter
    });
  }
}
