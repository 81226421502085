<div class="p-x-5">
  <p-tabView styleClass="tabs-only">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <a class="tab-icon" (click)="currentTab = 'modelTolerance'" pTooltip="Model Tolerance"><i class="fas fa-crosshairs" aria-hidden="true"></i></a>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <a class="tab-icon" (click)="currentTab = 'modelAnalysis'" pTooltip="Model Analysis"><i class="fas fa-cubes" aria-hidden="true"></i></a>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
  <section class="p-y-10" [ngStyle]="{display: currentTab === 'modelTolerance' ? 'block' : 'none'}">
    <eclipse-model-tolerance #modelTolerance></eclipse-model-tolerance>
  </section>
  <section class="p-y-10" [ngStyle]="{display: currentTab === 'modelAnalysis' ? 'block' : 'none'}">
    <eclipse-tradeorder-model-analysis #modelAnalysis></eclipse-tradeorder-model-analysis>
  </section>
</div>
