import {
  AfterViewChecked,
  Component,
  ComponentRef, ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  EditorEntityType,
  EntityEditorService,
  IEntityEditorComponent,
  IEntityEditorConfig,
} from '../../../shared/entity-editor';
import * as Consts from '../../../libs/app.constants';
import { SessionHelper } from '../../../core';
import { Subject, Subscription } from 'rxjs';
import { ITabNav } from '../../../viewModels/tabnav';
import { IToken } from '../../../models/portfolio';
import { ConfigService } from '../../../config/config.service';
import { IEntityChangedEvent } from '../../../shared/portfoliotree/portfolio-tree-item';
import { EntityType } from '../../../libs/preference.enums';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';
import { Utils as Util } from '../../../core/functions';
import { ActionMenuUtilities } from '../../../shared/actions/actions-menu';

@Component({
  selector: 'eclipse-account-editor',
  templateUrl: './account-editor.component.html',
})
export class AccountEditorComponent implements IEntityEditorComponent, AfterViewChecked {
  @ViewChild('entityhost', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef;
  @ViewChild('menu') menuElement: ElementRef;

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _visible: boolean = false;
  @Input()
  public get visible(): boolean {
    return this._visible;
  }

  public set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);
  }

  public get model(): ITabNav {
    return this.componentInstance?.tabsModel;
  }

  accountComponentRef: ComponentRef<any>;
  componentInstance: any;
  entityEditorConfig: IEntityEditorConfig;
  componentCloseSubscription: Subscription;
  accountEditPermission = false;
  portfolioEditPermission = false;
  private destroyed$: Subject<void> = new Subject<void>();
  massLiquidateFF: boolean;
  sleeveTransferAccountsFF: boolean;

  constructor(private readonly sessionHelper: SessionHelper, private readonly _entityEditorService: EntityEditorService, private readonly _splitIoService: SplitIoService) {
    const accountPriv = Util.getPermission(Consts.PRIV_ACCOUNTS);
    this.accountEditPermission = !!accountPriv?.canUpdate;
    this.portfolioEditPermission = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;

    this._splitIoService.flagsEnabled([
      'mass_liquidation_f211351',
      'MS_sleeve_transfer_accounts_4823'
    ])
      .subscribe((flags: { [key: string]: boolean }) => {
        this.massLiquidateFF = flags['mass_liquidation_f211351'];
        this.sleeveTransferAccountsFF = flags['MS_sleeve_transfer_accounts_4823'];
      });
  }

  async ngAfterViewInit() {
    // Create the existing account detail page for now.  In the future we'll replace the entire HTML template for this component with the new editor.
    this.accountComponentRef = await this.createComponent();
    if (!this.accountComponentRef) {
      return;
    }
    this.componentInstance = this.accountComponentRef.instance;
    this.componentInstance.hostedInEditor = true;
    this.componentInstance.accountId = +this.entityEditorConfig?.data?.id;
    this.componentCloseSubscription = this.componentInstance.onClose?.subscribe(val => {
      this.visible = false;
    });
  }

  ngAfterViewChecked() {
    ActionMenuUtilities.removeDuplicateMenuDividers(this.menuElement);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.componentCloseSubscription?.unsubscribe();
    this.viewContainerRef.clear();
    this.componentInstance?.destroy?.();
    this.accountComponentRef?.destroy?.()
    this.accountComponentRef = null;
    this.componentInstance = null;
  }

  public onClose(): void {
    if(this.componentInstance?.changedEntityMessage) {
      this._entityEditorService.entityChangedMessage(this.componentInstance.changedEntityMessage)
    }
    this._entityEditorService.close();
  }

  private async createComponent() {
    this.viewContainerRef.clear();
    if (!this.entityEditorConfig.skipAddingQueryParameter) {
      this._entityEditorService.addQueryParameter();
    }
    const privilege = this.sessionHelper.getPermission(Consts.PRIV_ACCOUNTS);
    if (privilege?.canUpdate && !!this.entityEditorConfig.data?.editMode) {
      const {AccountDetailComponent} = await import('./accountDetail.component');
      const comp = this.viewContainerRef.createComponent(AccountDetailComponent);
      return comp;

    } else if (privilege?.canRead) {
      const {AccountViewComponent} = await import('../view/accountview.component');
      return this.viewContainerRef.createComponent(AccountViewComponent);
    }
    console.error('No Account component was created.  Check user account permissions:', privilege);
    return null;
  }

  /** To view past transaction of account */
  viewPastTransaction(param) {
    // fetch account details by account id.  This will return back the firmId of the account.
    // the firmId is then passed into the loginService to get correct token.
    const accountId = param.id;
    this.componentInstance._accountService.getAccountById(accountId)
      .subscribe(
        (data) => {
          const accountFirmId = data.orionConnectFirmId;
          this.componentInstance._loginAsService.getFirmToken(accountFirmId)
            .subscribe((token: IToken) => {
              this.openOCTransactions(token);
            });
        });
  }

  private openOCTransactions(token) {
    if (this.model.externalId !== undefined) {
      // eslint-disable-next-line prefer-template
      const baseUrl = ConfigService.settings.orionAdvisorEndPoint + 'orionconnectapp/integration.html?p=' + encodeURIComponent('/portfolio/transactions?entity=7&entityId=' + this.model.externalId) + '&t=';
      const url = baseUrl + token.orion_access_token;
      this.componentInstance.window.open(url);
    }
  }

  public allowRebalancePortfolio() {
    return this.model.action !== 'D' && (this.model.id || this.model.ids) && this.model?.rebalancerToolPermission;
  }

  public allowRebalance() {
    return ((this.model.id === undefined && this.model.ids === undefined) && this.model?.action !== 'V' && !this.model.sleeves?.length) && this.model?.rebalancerToolPermission;
  }

  public allowRebalanceSleeves() {
    return this.model.sleeves?.length && this.model?.rebalancerToolPermission;
  }

  public allowCashNeedsPortfolio() {
    return this.model.action !== 'D' && (this.model.id || this.model.ids) && this.model?.cashNeedsToolPermission;
  }

  public allowCashNeeds() {
    return (this.model.id === undefined && this.model.ids === undefined) && this.model?.action !== 'V' && this.model?.cashNeedsToolPermission;
  }

  onTreeEntityChanged(newEntity: IEntityChangedEvent): void {
    switch (newEntity.entityType) {
      case EntityType.Portfolio:
        this._entityEditorService.show({
          entityType: EditorEntityType.Portfolio,
          data: {id: newEntity.id, editMode: !!this.portfolioEditPermission},
        });
        break;
      case EntityType.Account:
        this._entityEditorService.show({
          entityType: EditorEntityType.Account,
          data: {id: newEntity.id, editMode: false}, // always go to the View page by default
        });
        break;
    }
  }
}
