import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IPreferences } from '../../models/preferences/preference';
import { ICustomCommunityPreference } from '../../models/preferences/preferenceCommunity';
import { ISecurityPreferencesGet } from '../../models/preferences/securityPreference';
import { IRedemptionFeePreferencesGet } from '../../models/preferences/redemptionfeepreference';
import { ICustodianRedemptionFeePreferencesGet } from '../../models/preferences/custodianredemptionfeepreference';
import { ITransactionFeePreferencesGet } from '../../models/preferences/transactionfeepreference';
import { ITradeMinPreferencesGet } from '../../models/preferences/trademinpreference';
import { ITradeMaxPreferencesGet } from '../../models/preferences/trademaxpreference';
import { IDividendReinvestPreferencesGet } from '../../models/preferences/dividendreinvestpreference';
import { ICapitalGainReinvestPreferencesGet } from '../../models/preferences/capitalgainreinvestpreference';
import { ITradeSettingsPreferencesGet } from '../../models/preferences/tradesettingspreference';
import { IDefaultPrimaryTeamPreferencesGet } from '../../models/preferences/defaultprimaryteampreference';
import { CommunityStrategistComponent } from '../communitystrategist/communitystrategist.component';
import { LocationOptimizationComponent } from '../locationoptimization/locationoptimization.component';
import { RedemptionFeeComponent } from '../redemptionfee/redemptionfee.component';
import { CustodianRedemptionFeeComponent } from '../custodianredemptionfee/custodianredemptionfee.component';
import { TransactionFeeComponent } from '../transactionfee/transactionfee.component';
import { TradeMaxComponent } from '../trademax/trademax.component';
import { TradeMinComponent } from '../trademin/trademin.component';
import { DividendReInvestComponent } from '../dividendreinvest/dividendreinvest.component';
import { CapitalGainReInvestComponent } from '../capitalgainreinvest/capitalgainreinvest.component';
import { TradeSettingsComponent } from '../tradesettings/tradesettings.component';
import { DefaultPrimaryTeamComponent } from '../defaultprimaryteam/defaultprimaryteam.component';
import { PreferencesBusinessObjects } from '../../businessobjects/preference.businessobjects';
declare let $: any;
import { PreferenceService } from '../../services/preference.service';
import { ISystematicMultiplierPreference } from '../../models/preferences/systematic';
import { SystematicMultiplierGridComponent } from '../systematicmultiplier/systematic-multiplier-grid.component';
import { AstroService } from '../../services/astro.service';
import { IBillingCashMultiplierPreference } from '../../models/preferences/billingcash';
import { BillingCashMultiplierGridComponent } from '../billingcashmultiplier/billing-cash-multiplier-grid.component';
import {
  IMoneyMarketFundPreference,
  IMoneyMarketAllocationPreference
} from '../../models/preferences/moneyMarketPreference';

@Component({
  selector: 'eclipse-dynamic-control-ready-only',
  templateUrl: './dynamiccontrolreadyonly.component.html'
})
export class DynamicControlReadyOnlyComponent  {
  indicatorControlValue;
  checkboxlst: any = [];
  errorMessage: string = '';
  @Input() dynamicControlData: IPreferences;
  @Input() form: UntypedFormGroup;
  @Input() communityStrategies: ICustomCommunityPreference;
  @Input() LocationOptimizations: any[];
  @Input() SecurityPrefResult: ISecurityPreferencesGet;
  @Input() RedemptionFeePrefResult: IRedemptionFeePreferencesGet;
  @Input() CustodianRedemptionFeePrefResult: ICustodianRedemptionFeePreferencesGet;
  @Input() TransactionFeePrefResult: ITransactionFeePreferencesGet;
  @Input() TradeMinPrefResult: ITradeMinPreferencesGet;
  @Input() TradeMaxPrefResult: ITradeMaxPreferencesGet;
  @Input() DividendReinvestPrefResult: IDividendReinvestPreferencesGet;
  @Input() CapitalGainReinvestPrefResult: ICapitalGainReinvestPreferencesGet;
  @Input() TradeSettingsPrefResult: ITradeSettingsPreferencesGet;
  @Input() DefaultPrimaryTeamsPrefResult: IDefaultPrimaryTeamPreferencesGet;
  @Input() SystematicMultiplierPrefResult: ISystematicMultiplierPreference;
  @Input() BillingCashMultiplierPrefResult: IBillingCashMultiplierPreference;
  @Input() MoneyMarketAllocationPreference: IMoneyMarketAllocationPreference;
  @Input() MoneyMarketFundPreference: IMoneyMarketFundPreference;
  @Input() secTypes: any[];
  @Input() levelName: string;
  @ViewChild(CommunityStrategistComponent) communitystrategistComponent: CommunityStrategistComponent;
  @ViewChild(LocationOptimizationComponent) locationoptimizationComponent: LocationOptimizationComponent;
  @ViewChild(RedemptionFeeComponent) redemptionfeeComponent: RedemptionFeeComponent;
  @ViewChild(CustodianRedemptionFeeComponent) custodianredemptionfeeComponent: CustodianRedemptionFeeComponent;
  @ViewChild(TransactionFeeComponent) transactionfeeComponent: TransactionFeeComponent;
  @ViewChild(TradeMinComponent) trademinComponent: TradeMinComponent;
  @ViewChild(TradeMaxComponent) trademaxComponent: TradeMaxComponent;
  @ViewChild(DividendReInvestComponent) dividendReinvestComponent: DividendReInvestComponent;
  @ViewChild(CapitalGainReInvestComponent) capitalGainReinvestComponent: CapitalGainReInvestComponent;
  @ViewChild(TradeSettingsComponent) tradeSettingsComponent: TradeSettingsComponent;
  @ViewChild(DefaultPrimaryTeamComponent) defaultPrimaryTeamComponent: DefaultPrimaryTeamComponent;
  @ViewChild(SystematicMultiplierGridComponent) systematicMultiplierGridComponent: SystematicMultiplierGridComponent;
  @ViewChild(BillingCashMultiplierGridComponent) billingCashMultiplierGridComponent: BillingCashMultiplierGridComponent;
  @Input() isEnableSecPref: boolean = false;
  @Input() levelId: number;
  @Input() selectedLeveIds: number[];
  dispSecGrid: boolean = false;
  displaySystematicMultiplier: boolean = false;
  displayBillingCashMultiplier: boolean = false;

  constructor(private preferencesbo: PreferencesBusinessObjects, private _preferenceService: PreferenceService, private _astroService: AstroService) { }

  ngOnInit() {
    $.material.init();
    this.indicatorControlValue = this.dynamicControlData.name + this.dynamicControlData.componentName;
    this.setIndicatorValue();
  }

  setIndicatorValue() {
    // eslint-disable-next-line eqeqeq
    if (this.dynamicControlData.indicatorOptions != null
      && this.dynamicControlData.indicatorOptions.length > 0) {
      // eslint-disable-next-line eqeqeq
      if (this.dynamicControlData.indicatorValue == null) {
        this.dynamicControlData.indicatorValue = this.dynamicControlData.indicatorOptions[0].name;
      }

      for (let i = 0; i < this.dynamicControlData.indicatorOptions.length; i++) {
        const option = this.dynamicControlData.indicatorOptions[i];
        // eslint-disable-next-line eqeqeq
        if (option.name == this.dynamicControlData.indicatorValue) {
          // eslint-disable-next-line eqeqeq
          this.dynamicControlData.maxValue = option.maxValue == null ? Number.MAX_VALUE : option.maxValue;
          // eslint-disable-next-line eqeqeq
          this.dynamicControlData.minValue = option.minValue == null ? 0 : option.minValue;
          break;
        }
      }
    }
  }

  showTooltip(event) {
    if (event.isInherited) {
      // eslint-disable-next-line eqeqeq
      if (event.value == event.inheritedValue) {
        return event.inheritedFrom;
      }
    }
    return this.levelName;
  }
  setcheckboxchecked(param) {
    // eslint-disable-next-line eqeqeq
    return (param == 'true');
  }

  setCheckBoValue(event) {
    this.dynamicControlData.value = (event.target.checked) ? 'true' : 'false';
  }

  checkboxChecked(opt, dynamicControlData) {
    // eslint-disable-next-line eqeqeq
    return (this.form['value'][dynamicControlData.name]['value'] == opt.id);
  }
}
