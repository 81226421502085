<div [ngSwitch]="displayPermission">
  <div *ngSwitchCase="true">
    <div class="field-control">
        <p-autoComplete class="autocomplete-search" [(ngModel)]="teamName" appendTo="body" [suggestions]="teamsSuggesions" placeholder="Search Team"
                        [dropdown]="true" (onDropdownClick)="getTeamList($event,'dropDownClick')" (completeMethod)="getTeamList($event,'search')" (onSelect)="teamChange($event.id)" field="name" [minLength]="1">
          <ng-template let-teamSearch pTemplate="item">
            <span>{{ teamSearch.name }}</span>
          </ng-template>
        </p-autoComplete>
    </div>
  </div>
</div>
