import {Directive, ElementRef, HostListener, inject, Input, OnDestroy, Renderer2} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';

@Directive({
    selector: 'span[copyToClipboard], p[copyToClipboard], label[copyToClipboard]',
})
export class CopyToClipboardDirective implements OnDestroy {
    @Input('copyToClipboard') copyToClipboard: boolean | '' = true;
    private copyIcon: HTMLElement;
    private removeClickListener: () => void;

    private element: ElementRef = inject(ElementRef);
    private renderer: Renderer2 = inject(Renderer2);
    private clipboard: Clipboard = inject(Clipboard);

    @HostListener('mouseenter') onMouseEnter() {
        if (this.copyToClipboard === '' || this.copyToClipboard === true) {
            this.createCopyIcon();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.copyIcon) {
            this.renderer.removeChild(this.element.nativeElement, this.copyIcon);
        }
    }

    ngOnDestroy() {
        if (this.removeClickListener) {
            this.removeClickListener();
        }
        if (this.copyIcon) {
            this.renderer.removeChild(this.element.nativeElement, this.copyIcon);
        }
    }

    private copyTextToClipboard() {
        const textToCopy = this.element.nativeElement.innerText.trim();
        if (textToCopy) {
            this.clipboard.copy(textToCopy);
        }
    }

    private createCopyIcon() {
        if (this.copyIcon) {
            this.renderer.appendChild(this.element.nativeElement, this.copyIcon);
            return;
        }
        this.copyIcon = this.renderer.createElement('span');
        const icon = this.renderer.createElement('i');
        this.renderer.addClass(icon, 'fas');
        this.renderer.addClass(icon, 'fa-fw');
        this.renderer.addClass(icon, 'fa-copy');
        this.renderer.appendChild(this.copyIcon, icon);
        this.renderer.setStyle(this.copyIcon, 'cursor', 'pointer');
        this.renderer.setStyle(this.copyIcon, 'margin-left', '4px');
        this.removeClickListener = this.renderer.listen(this.copyIcon, 'click', (event) => {
            event.stopPropagation();
            this.copyTextToClipboard();
        });
        this.renderer.appendChild(this.element.nativeElement, this.copyIcon);
    }
}
