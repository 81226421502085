import { Component } from '@angular/core';
import {SessionHelper} from '../../core';

@Component({
  selector: 'eclipse-notfound',
  templateUrl: './notfound.component.html'
})
export class NotFoundComponent  {

  isAuthenticated: boolean;

  constructor(private _sessionHelper: SessionHelper) {
    this.isAuthenticated = _sessionHelper.isAuthenticated();
  }

}
