import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import * as Consts from '../../libs/app.constants';
import {PortfolioService} from '../../services/portfolio.service';
import {NotificationService} from '../../services/notification.service';
import {AnalyticsStatus} from '../../libs/app.constants';
import {Utils} from '../../core/functions';
import * as _ from 'lodash';

@Component({
  selector: 'eclipse-model-analytic-analyzer',
  templateUrl: './modelanalyticanalyzer.component.html'
})

export class ModelAnalyticAnalyzer extends BaseComponent {
  @Input() needAnalytics: number;
  @Input() portfolioId: number;
  @Input() status: string;
  @Output() callRefreshData = new EventEmitter();
  duration: string;
  portfolioFlags: any[] = [];
  @Input() isMultiple: boolean;
  isDisableButton: boolean = false;
  @Output() analyticsStatus = new EventEmitter();
  @Output() updateTrades = new EventEmitter();
  private connection;
  analyticStatusConnection;
  @Input()  isFromTactical: boolean;

  private _currentPortfolioId: number;
  get currentPortfolioId(): number {
    return this._currentPortfolioId;
  }
  set currentPortfolioId(value: number) {
    this._currentPortfolioId = value;
  }

  constructor(private _portfolioService: PortfolioService, private notificationService: NotificationService) {
    super(Consts.PRIV_MODELS);
  }

  ngOnInit() {
    this.subscribeToNotifications();
    this.subscribeStatusToNotifications();
  }

  ngOnDestroy() {
    if (this.connection) {
      this.connection.unsubscribe();
    }
    this.analyticStatusConnection.unsubscribe();
  }

  subscribeToNotifications() {
    this.connection = this.notificationService.analyticsDuartionEmitter.subscribe(notification => {
      const notificationData = notification;
      if (notificationData && notificationData.portfolioIds) {
        let portfolioIds = notificationData.portfolioIds;
        if (!(portfolioIds instanceof Array)) {
          portfolioIds = portfolioIds.split(',');
        }
        portfolioIds = portfolioIds.map(Number);
        if (this.isMultiple) {
          portfolioIds.forEach(pId => {
            // eslint-disable-next-line eqeqeq
            const flagPortfolio = this.portfolioFlags.find(flag => flag.portfolioId == pId);
            if (flagPortfolio) {
              this.duration = notificationData.duration;
              return;
            }
          });
        } else {
          // eslint-disable-next-line eqeqeq
          const portfolio = portfolioIds.find(p => p == this.portfolioId);
          if (portfolio) {
            this.duration = notificationData.duration;
          }
        }
      // eslint-disable-next-line eqeqeq
      } else if (notificationData.trigger ==  'refresh_analytics' || notificationData.trigger ==  'full_import'
        // eslint-disable-next-line eqeqeq
        || notificationData.trigger ==  'partial_import') {
        this.duration = notificationData.duration;
      }
    });
  }

  runPortfolioAnalysis(portfolioIds) {
    if (portfolioIds) {
      if(this.isFromTactical) {
        this.currentPortfolioId = portfolioIds[0];
      }
      this.isDisableButton = true;
        this._portfolioService.runPortfolioAnalysis(portfolioIds)
        .subscribe(() => { },
          (_error) => this.isDisableButton = false
          );
    }
  }
  runAnalysis() {
    const portfolioIds = [];
    // eslint-disable-next-line eqeqeq
    const portfoliosFlag = this.portfolioFlags.filter(p => p.needAnalytics == 3 || p.needAnalytics == 1);
    portfoliosFlag.forEach(p => {
      portfolioIds.push(p.portfolioId);
    });
    this.runPortfolioAnalysis(portfolioIds);
  }

  getPortfolioFlags(flags) {
    if (flags && flags.length > 0) {
      this.portfolioFlags = flags;
      // eslint-disable-next-line eqeqeq
      const haveErrors = !!flags.find(p => p.needAnalytics == 3);
      // eslint-disable-next-line eqeqeq
      const haveNeedAnalytics = !!flags.find(p => p.needAnalytics == 1);
      // eslint-disable-next-line eqeqeq
      const inProgress = !!flags.find(p => p.needAnalytics == 2);
      if (inProgress) {
        this.isDisableButton = false;
        this.needAnalytics = 2;
      } else if (haveNeedAnalytics) {
        this.needAnalytics = 1;
      } else if (haveErrors) {
        this.needAnalytics = 3;
      } else {
        this.needAnalytics = 0;
      }
      let haveNotNeedAnalytics = false;
      // eslint-disable-next-line eqeqeq
      if (this.needAnalytics == 3) {
        // eslint-disable-next-line eqeqeq
        haveNotNeedAnalytics = !!flags.find(p => p.needAnalytics == 0);
      }
      let disableNextButton = false;
      // eslint-disable-next-line eqeqeq
      disableNextButton = (!(this.needAnalytics == 0 || haveNotNeedAnalytics));
      this.analyticsStatus.emit(disableNextButton);
    } else {
      this.resetAnalytics();
      this.analyticsStatus.emit(true);
    }
  }

  resetAnalytics() {
    this.needAnalytics = null;
    this.portfolioId = null;
    this.status = null;
    this.portfolioFlags = [];
  }

  subscribeStatusToNotifications() {
    this.analyticStatusConnection = this.notificationService.getAnalyticsStatus.subscribe(event => {
      if (Utils.isFullAnalytics(event.trigger)) {
        this.updateAnalysisStatusForFullAnalytics(event);
      } else {
        this.updateAnalyticsStatusFromPortfolioFlag(event);
      }
      const haveNotNeedAnalytics = this.portfolioFlags?.some(p => p.needAnalytics === AnalyticsStatus.Good);
      const disableNextButton = (!(this.needAnalytics === AnalyticsStatus.Good || (haveNotNeedAnalytics && event.isDone)));
      this.analyticsStatus.emit(disableNextButton);
      this.callRefreshData.emit(event);
      if (event.isDone) {
        this.duration = '00:00:00';
      }
    });
  }

  updateAnalysisStatusForFullAnalytics(event: any): void {
    if (event.needAnalytics === AnalyticsStatus.Running) {
      this.needAnalytics = AnalyticsStatus.Running;
    } else if (event.isDone) {
      const portfolioIds = this.getPortfolioIdsForFlags();
      if (portfolioIds?.length) {
        this._portfolioService.getPortfolioFlag(portfolioIds)
          .subscribe(data => {
            event.portfolioFlag = data;
            this.updateAnalyticsStatusFromPortfolioFlag(event);
          });
      }
    }
  }

  getPortfolioIdsForFlags(): number [] {
    let portfolioIds = [];
    if (this.isMultiple) {
      portfolioIds = this.portfolioFlags.map(p => p.portfolioId);
    } else {
      portfolioIds.push(this.portfolioId);
    }
    return _.uniq(portfolioIds.filter(p => p));
  }

  updateAnalyticsStatusFromPortfolioFlag(event: any): void {
    if (event.portfolioFlag && event.portfolioFlag.length > 0) {
      if (this.isMultiple) {
        this.changePortfolioFlags(event.portfolioFlag);
        this.updateTrades.emit(this.portfolioFlags);
      } else {
        const portfolioFlag = event.portfolioFlag.find(p => Number(p.portfolioId) === Number(this.portfolioId));
        if (portfolioFlag) {
          this.needAnalytics = portfolioFlag.needAnalytics;
          this.status = portfolioFlag.failedReason;
        }
        if (this.needAnalytics === AnalyticsStatus.Running) {
          this.isDisableButton = false;
        }
        this.updateAnalyticsStatusForTactical(event);
        this.updateTrades.emit(portfolioFlag);
      }
    } else {
      this.isDisableButton = false;
    }
  }

  updateAnalyticsStatusForTactical(event: any): void {
    if (this.isDisableButton && this.isFromTactical && this.currentPortfolioId) {
      const portfolioFlag = event.portfolioFlag.find(p => Number(p.portfolioId) === Number(this.currentPortfolioId));
      if (portfolioFlag && portfolioFlag.needAnalytics === AnalyticsStatus.Running) {
        this.isDisableButton = false;
      }
    }
  }

  changePortfolioFlags(flags) {
    flags.forEach(newFlag => {
      this.portfolioFlags.forEach(oldFlag => {
        // eslint-disable-next-line eqeqeq
        if (newFlag.portfolioId == oldFlag.portfolioId) {
          oldFlag.needAnalytics = newFlag.needAnalytics;
          oldFlag.failedReason = newFlag.failedReason;
        }
      });
    });
    this.getPortfolioFlags(this.portfolioFlags);
  }
}
