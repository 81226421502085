import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IPreferences } from '../../models/preferences/preference';
import { ICustomCommunityPreference } from '../../models/preferences/preferenceCommunity';
import { ILocationOptimizationCustom } from '../../models/preferences/locationOptimization';
import { ISecurityPreferencesGet } from '../../models/preferences/securityPreference';
import { IRedemptionFeePreferencesGet } from '../../models/preferences/redemptionfeepreference';
import { ICustodianRedemptionFeePreferencesGet } from '../../models/preferences/custodianredemptionfeepreference';
import { ITransactionFeePreferencesGet } from '../../models/preferences/transactionfeepreference';
import { ITradeMinPreferencesGet } from '../../models/preferences/trademinpreference';
import { ITradeMaxPreferencesGet } from '../../models/preferences/trademaxpreference';
import { IDividendReinvestPreferencesGet } from '../../models/preferences/dividendreinvestpreference';
import { ICapitalGainReinvestPreferencesGet } from '../../models/preferences/capitalgainreinvestpreference';
import { ITradeSettingsPreferencesGet } from '../../models/preferences/tradesettingspreference';
import { IDefaultPrimaryTeamPreferencesGet } from '../../models/preferences/defaultprimaryteampreference';
import { CommunityStrategistComponent } from '../communitystrategist/communitystrategist.component';
import { LocationOptimizationComponent } from '../locationoptimization/locationoptimization.component';
import { RedemptionFeeComponent } from '../redemptionfee/redemptionfee.component';
import { CustodianRedemptionFeeComponent } from '../custodianredemptionfee/custodianredemptionfee.component';
import { TransactionFeeComponent } from '../transactionfee/transactionfee.component';
import { TradeMinComponent } from '../trademin/trademin.component';
import { TradeMaxComponent } from '../trademax/trademax.component';
import { DividendReInvestComponent } from '../dividendreinvest/dividendreinvest.component';
import { CapitalGainReInvestComponent } from '../capitalgainreinvest/capitalgainreinvest.component';
import { TradeSettingsComponent } from '../tradesettings/tradesettings.component';
import { DefaultPrimaryTeamComponent } from '../defaultprimaryteam/defaultprimaryteam.component';
import { AlertService } from '../../core';
import { PreferenceService } from '../../services/preference.service';
import { Utils as Util } from '../../core/functions';
import { ISecurityPreferenceExcelImport } from '../../models/preferences/securitypreferenceexcelimport';
import { ISystematicMultiplierPreference } from '../../models/preferences/systematic';
import { SystematicMultiplierGridComponent } from '../systematicmultiplier/systematic-multiplier-grid.component';
import { IBillingCashMultiplierPreference } from '../../models/preferences/billingcash';
import { BillingCashMultiplierGridComponent } from '../billingcashmultiplier/billing-cash-multiplier-grid.component';
import * as preferenceEnum from '../../libs/preference.enums';
import {
  IMoneyMarketFundPreference,
  IMoneyMarketAllocationPreference,
  IMoneyMarketFunds
} from '../../models/preferences/moneyMarketPreference';
import { PreferenceEnums } from '../../libs/preference.enums';

@Component({
  selector: 'eclipse-dynamic-control',
  templateUrl: './dynamiccontrol.component.html',
  styleUrls: ['./dynamiccontrol.component.scss']
})
export class DynamicControlComponent {
  el: HTMLElement;
  indicatorControlValue;
  checkboxlst: any = [];
  errorMessage: string = '';
  @Input() preferenceResults: IPreferences[];
  @Input() toleranceSettingsBuy: IPreferences[];
  @Input() toleranceSettingsSell: IPreferences[];
  @Input() dynamicControlData: IPreferences;
  @Input() form: UntypedFormGroup;
  @Input() communityStrategies: ICustomCommunityPreference;
  @Input() LocationOptimizations: ILocationOptimizationCustom;
  @Input() RedemptionFeePrefResult: IRedemptionFeePreferencesGet;
  @Input() CustodianRedemptionFeePrefResult: ICustodianRedemptionFeePreferencesGet;
  @Input() TransactionFeePrefResult: ITransactionFeePreferencesGet;
  @Input() levelName: string;
  @Input() TradeMinPrefResult: ITradeMinPreferencesGet;
  @Input() TradeMaxPrefResult: ITradeMaxPreferencesGet;
  @Input() DividendReinvestPrefResult: IDividendReinvestPreferencesGet;
  @Input() CapitalGainReinvestPrefResult: ICapitalGainReinvestPreferencesGet;
  @Input() TradeSettingsPrefResult: ITradeSettingsPreferencesGet;
  @Input() DefaultPrimaryTeamsPrefResult: IDefaultPrimaryTeamPreferencesGet;
  @Input() SystematicMultiplierPrefResult: ISystematicMultiplierPreference;
  @Input() BillingCashMultiplierPrefResult: IBillingCashMultiplierPreference;
  @Input() secTypes: any[];
  @Input() MoneyMarketAllocationPreference: IMoneyMarketAllocationPreference;
  @Input() MoneyMarketFundPreference: IMoneyMarketFundPreference;
  @ViewChild(CommunityStrategistComponent) communitystrategistComponent: CommunityStrategistComponent;
  @ViewChild(LocationOptimizationComponent) locationoptimizationComponent: LocationOptimizationComponent;
  @ViewChild(RedemptionFeeComponent) redemptionfeeComponent: RedemptionFeeComponent;
  @ViewChild(CustodianRedemptionFeeComponent) custodianredemptionfeeComponent: CustodianRedemptionFeeComponent;
  @ViewChild(TransactionFeeComponent) transactionfeeComponent: TransactionFeeComponent;
  @ViewChild(TradeMinComponent) trademinComponent: TradeMinComponent;
  @ViewChild(TradeMaxComponent) trademaxComponent: TradeMaxComponent;
  @ViewChild(DividendReInvestComponent) dividendReinvestComponent: DividendReInvestComponent;
  @ViewChild(CapitalGainReInvestComponent) capitalGainReinvestComponent: CapitalGainReInvestComponent;
  @ViewChild(TradeSettingsComponent) tradeSettingsComponent: TradeSettingsComponent;
  @ViewChild(DefaultPrimaryTeamComponent) defaultPrimaryTeamComponent: DefaultPrimaryTeamComponent;
  @ViewChild('systematicMultiplier') systematicMultiplierComponent: SystematicMultiplierGridComponent;
  @ViewChild('billingCashMultiplier') billingCashMultiplierComponent: BillingCashMultiplierGridComponent;

  @Input() isEnableSecPref: boolean = false;
  @Input() levelId: number;
  @Input() selectedLeveIds: number[];
  @Input() securityTypes: any[];
  @Output() updatePreference = new EventEmitter();
  successMessage: string;
  showErrorMessage: boolean;
  showSuccessMessage: boolean;
  apiSecurityPreference: any;
  saveButtonIsDisabled: boolean;
  displayConfirm: boolean;

  constructor(el: ElementRef, private _alertService: AlertService, private _preferenceService: PreferenceService) {
    this.el = el.nativeElement;
    this.displayConfirm = false;
  }

  ngOnInit() {
    this.indicatorControlValue = this.dynamicControlData.name + this.dynamicControlData.componentName;
    this.setIndicatorValue();
    this.setClonedInheritedCustomComponents();
    this.saveButtonIsDisabled = true;
  }

  /** Set indicator value */
  setIndicatorValue() {
    // eslint-disable-next-line eqeqeq
    if (this.dynamicControlData.indicatorOptions != null
      && this.dynamicControlData.indicatorOptions.length > 0) {
      // eslint-disable-next-line eqeqeq
      if (this.dynamicControlData.indicatorValue == null) {
        this.dynamicControlData.indicatorValue = this.dynamicControlData.indicatorOptions[0].name;
      }
      for (let i = 0; i < this.dynamicControlData.indicatorOptions.length; i++) {
        const option = this.dynamicControlData.indicatorOptions[i];
        // eslint-disable-next-line eqeqeq
        if (option.name == this.dynamicControlData.indicatorValue) {
          // eslint-disable-next-line eqeqeq
          this.dynamicControlData.maxValue = option.maxValue == null ? Number.MAX_VALUE : option.maxValue;
          // eslint-disable-next-line eqeqeq
          this.dynamicControlData.minValue = option.minValue == null ? 0 : option.minValue;
          break;
        }
      }
    }
  }

  /** Get inherited value */
  getInheritedValue() {
    if (this.dynamicControlData.inheritedValue && this.dynamicControlData.isInherited) {
      let value = this.dynamicControlData.inheritedValue;
      // eslint-disable-next-line eqeqeq
      if (value == 'true' || value == 'false') {
        // eslint-disable-next-line eqeqeq
        value = (value == 'true');
      // eslint-disable-next-line eqeqeq
      } else if (value == '0') {
        value = +value;
      }
      return value;
    } else {
      return '';
    }
  }

  /** Get inherited indicator value */
  getInheritedIndicatorValue() {
    if (this.dynamicControlData.inheritedIndicatorValue) {
      return this.dynamicControlData.inheritedIndicatorValue;
    } else {
      return '';
    }
  }

  /** Show tool tip */
  showTooltip(event) {
    return (event.resetToParent) ? event.inheritedFrom : this.levelName;
  }

  /**Show tooltip for dropdown */
  showTooltipdropdown(event) {
    return (event.resetToParent) ? event.inheritedFrom : this.levelName;
  }

  /** Set dropdown for selected option */
  setDropdownSelectedOption(event) {
    this.dynamicControlData.resetToParent = false;
    // eslint-disable-next-line eqeqeq
    if (event.target.value != '') {
      // eslint-disable-next-line radix
      this.dynamicControlData.selectedOptions = [{ id: parseInt(event.target.value) }];
    } else {
      this.dynamicControlData.selectedOptions = [];
    }
  }

  /**Set Reset control */
  setResetControl(event) {
    // has-error
    this.emptyError();
    // eslint-disable-next-line eqeqeq
    if (this.dynamicControlData.componentType == 'custom') {
      // eslint-disable-next-line eqeqeq
      if (this.dynamicControlData.valueType == 'SortedList') {
        if (this.dynamicControlData.inheritedSelectedOptions.length > 0 && this.dynamicControlData.isInherited) {
          this.dynamicControlData.selectedOptions = [];
          this.dynamicControlData.selectedOptions = Util.deepClone(this.dynamicControlData.inheritedSelectedOptions);
          this.dynamicControlData.isNotReSetToParent = true;
        } else {
          this.dynamicControlData.selectedOptions = (<any>Object).values(this.dynamicControlData.options);
          this.dynamicControlData.isNotReSetToParent = false;
        }
        this.dynamicControlData.resetToParent = true;
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'CommunityStrategistEnabledListCascadingControl') {
        this.communityStrategies.resetToParent = true;
        if (Object.keys(this.communityStrategies.inheritedStrategists).length > 0 && this.dynamicControlData.isInherited) {
          this.communityStrategies.strategists = Util.deepClone(this.communityStrategies.inheritedStrategists);
        } else {
          this.communityStrategies.strategists.strategistIds = null;
          this.communityStrategies.strategists.modelAccessLevel = null;
          this.communityStrategies.strategists.communityModels = null;
        }
        this.communitystrategistComponent.getInheritedOptions(this.communityStrategies.strategists);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'RedemptionFeeDataGrid') {
        this.RedemptionFeePrefResult.resetToParent = true;
        this.RedemptionFeePrefResult.redemptionFeePreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.RedemptionFeePrefResult.inheritedRedemptionFeePreferences) : null;
        this.redemptionfeeComponent.bindRedemptionFeeData(this.RedemptionFeePrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'CustodianRedemptionFeeDataGrid') {
        this.CustodianRedemptionFeePrefResult.resetToParent = true;
        this.CustodianRedemptionFeePrefResult.custodianRedemptionFeePreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.CustodianRedemptionFeePrefResult.inheritedCustodianRedemptionFeePreferences) : null;
        this.custodianredemptionfeeComponent.bindRedemptionFeeData(this.CustodianRedemptionFeePrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'TransactionFeeDataGrid') {
        this.TransactionFeePrefResult.resetToParent = true;
        this.TransactionFeePrefResult.transactionFeePreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.TransactionFeePrefResult.inheritedTransactionFeePreferences) : null;
        this.transactionfeeComponent.bindTransactionFeeData(this.TransactionFeePrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'LocationOptimizationDataGrid') {
        this.LocationOptimizations.resetToParent = true;
        if (Object.keys(this.LocationOptimizations.inheritedAssetTypes).length > 0 && this.dynamicControlData.isInherited) {
          this.LocationOptimizations.assetTypes = Util.deepClone(this.LocationOptimizations.inheritedAssetTypes);
          this.LocationOptimizations.inheritedFrom = this.LocationOptimizations.assetTypes[0] ? this.LocationOptimizations.assetTypes[0].inheritedFrom : null;
        } else {
          this.LocationOptimizations.inheritedFrom = this.LocationOptimizations.inheritedFromLevel;
          this.LocationOptimizations.assetTypes = [];
        }
        this.locationoptimizationComponent.rebindControls(this.LocationOptimizations.assetTypes);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'TradeMinDataGrid') {
        this.TradeMinPrefResult.resetToParent = true;
        this.TradeMinPrefResult.tradeMinPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.TradeMinPrefResult.inheritedTradeMinPreferences) : null;
        this.trademinComponent.bindTradeMinData(this.TradeMinPrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'TradeMaxDataGrid') {
        this.TradeMaxPrefResult.resetToParent = true;
        this.TradeMaxPrefResult.tradeMaxPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.TradeMaxPrefResult.inheritedTradeMaxPreferences) : null;
        this.trademaxComponent.bindTradeMaxData(this.TradeMaxPrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'DividendReinvestDataGrid') {
        this.DividendReinvestPrefResult.resetToParent = true;
        this.DividendReinvestPrefResult.dividendReinvestPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.DividendReinvestPrefResult.inheritedDividendReinvestPreferences) : null;
        this.dividendReinvestComponent.bindDividendReinvestData(this.DividendReinvestPrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'CapitalGainReinvestDataGrid') {
        this.CapitalGainReinvestPrefResult.resetToParent = true;
        this.CapitalGainReinvestPrefResult.capitalGainReinvestPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.CapitalGainReinvestPrefResult.inheritedCapitalGainReinvestPreferences) : null;
        this.capitalGainReinvestComponent.bindCapitalGainReinvestData(this.CapitalGainReinvestPrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'TradeSettingsDataGrid') {
        this.TradeSettingsPrefResult.resetToParent = true;
        this.TradeSettingsPrefResult.tradeSettingsPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.TradeSettingsPrefResult.inheritedTradeSettingsPreferences) : null;
        this.tradeSettingsComponent.bindTradeSettingsData(this.TradeSettingsPrefResult);
      // eslint-disable-next-line eqeqeq
      } else if (this.dynamicControlData.componentName == 'DefaultPrimaryTeamDropdownSearch') {
        this.DefaultPrimaryTeamsPrefResult.resetToParent = true;
        this.DefaultPrimaryTeamsPrefResult.defaultPrimaryTeamPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.DefaultPrimaryTeamsPrefResult.inheritedDefaultPrimaryTeamPreferences) : null;
        this.defaultPrimaryTeamComponent.bindDefaultPrimaryTeamssData(this.DefaultPrimaryTeamsPrefResult);
      } else if (this.dynamicControlData.componentName === 'SystematicMultiplierLink') {
        this.SystematicMultiplierPrefResult.resetToParent = true;
        this.SystematicMultiplierPrefResult.systematicMultiplierPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.SystematicMultiplierPrefResult.inheritedSystematicMultiplierPreferences) : null;
        this.systematicMultiplierComponent.bindSystematicMultiplierData();
      } else if (this.dynamicControlData.componentName === 'BillingCashMultiplierLink') {
        this.BillingCashMultiplierPrefResult.resetToParent = true;
        this.BillingCashMultiplierPrefResult.billingCashMultiplierPreferences = (this.dynamicControlData.isInherited) ? Util.deepClone(this.BillingCashMultiplierPrefResult.inheritedBillingCashMultiplierPreferences) : null;
        this.billingCashMultiplierComponent.bindBillingCashMultiplierData();
      } else if (this.dynamicControlData.componentName === PreferenceEnums.MoneyMarketAllocationGrid.toString()) {
        this.MoneyMarketAllocationPreference.resetToParent = true;
        this.MoneyMarketAllocationPreference.moneyMarketAllocations = this.dynamicControlData.isInherited ? Util.deepClone(this.MoneyMarketAllocationPreference.inheritedMoneyMarketAllocations) : [];
      } else if (this.dynamicControlData.componentName === PreferenceEnums.MoneyMarketFundsGrid.toString()) {
        this.MoneyMarketFundPreference.resetToParent = true;
        this.MoneyMarketFundPreference.moneyMarketFunds = this.dynamicControlData.isInherited ? Util.deepClone(this.MoneyMarketFundPreference.inheritedMoneyMarketFunds) : [];
      }
    } else {
      // eslint-disable-next-line eqeqeq
      if (this.dynamicControlData.componentName == 'Dropdown') {
        if (this.dynamicControlData.inheritedSelectedOptions.length > 0 && this.dynamicControlData.isInherited) {
          const InheritValue = this.dynamicControlData.inheritedSelectedOptions[0]['id'];
          (document.getElementById(event.currentTarget.id))['value'] = InheritValue;
          this.dynamicControlData.selectedOptions = [{ id: InheritValue, order: 0 }];
          this.form.controls[event.currentTarget.id].setValue(InheritValue);
        } else {
          (document.getElementById(event.currentTarget.id))['value'] = '';
          this.dynamicControlData.value = '';
          this.dynamicControlData.selectedOptions = [{ id: '', order: 0 }];
          this.form.controls[event.currentTarget.id].setValue('');
        }
        this.dynamicControlData.resetToParent = true;
      } else {
        // get Inherited value
        const InheritValue = this.getInheritedValue();
        (document.getElementById(event.currentTarget.id))['value'] = InheritValue;
        this.dynamicControlData.value = InheritValue;
        this.dynamicControlData.selectedOptions = InheritValue;
        this.form.controls[event.currentTarget.id].setValue(InheritValue);
        // eslint-disable-next-line eqeqeq
        if (this.dynamicControlData.inheritedIndicatorValue != null) {
          this.dynamicControlData.indicatorValue = this.dynamicControlData.inheritedIndicatorValue;
        }
        // indicator Value set MAX & MIN value
        for (let i = 0; i < this.dynamicControlData.indicatorOptions.length; i++) {
          const option = this.dynamicControlData.indicatorOptions[i];
          // eslint-disable-next-line eqeqeq
          if (option.name == this.dynamicControlData.indicatorValue) {
            // eslint-disable-next-line eqeqeq
            this.dynamicControlData.maxValue = option.maxValue == null ? Number.MAX_VALUE : option.maxValue;
            // eslint-disable-next-line eqeqeq
            this.dynamicControlData.minValue = option.minValue == null ? 0 : option.minValue;
            break;
          }
        }
        this.dynamicControlData.resetToParent = true;
        this.checkForTaxMethods();
      }
    }
    // UpdateValue is neccessary
    this.form.controls[event.currentTarget.id].updateValueAndValidity();
    this.el.firstElementChild.firstElementChild.classList.remove('has-error');
    this.updatePreference.emit({ name: this.dynamicControlData.name, value: this.dynamicControlData.value });
  }

  /** Set Radio control */
  setToRadioControl(param, dynamicControlData) {
    this.dynamicControlData.value = param.id;
    this.dynamicControlData.selectedOptions = param.id;
    this.form.controls[dynamicControlData.name].setValue(param.id);
    // UpdateValue is neccessary
    this.form.controls[dynamicControlData.name].updateValueAndValidity();
  }

  /** Set checkbox checked value */
  setcheckboxchecked(param) {
    // eslint-disable-next-line eqeqeq
    if (param == 'true') {
      return true;
    } else {
      return false;
    }
  }

  /** Set Checkbox value */
  setCheckBoValue(event) {
    this.checkForTaxMethods();
    if (this.dynamicControlData.name === preferenceEnum.PREFERENCE_NAME.ALLOW_WASH_SALES
      || this.dynamicControlData.name === preferenceEnum.PREFERENCE_NAME.MONEY_MARKET_TRADING) {
      this.updatePreference.emit({ name: this.dynamicControlData.name, value: this.dynamicControlData.value });
    }
    this.dynamicControlData.resetToParent = false;
  }

  /** Set Checkbox control */
  setToCheckBoxControl(param, dynamicControlData, selectedOptions) {
    const idx = selectedOptions.indexOf(param);
    if (idx > -1) {
      selectedOptions.splice(idx, 1);
    } else {
      selectedOptions.push(param);
    }
    this.dynamicControlData.value = this.checkboxlst.toString();
    this.form.controls[dynamicControlData.name].setValue(selectedOptions);
    this.form.controls[dynamicControlData.name].updateValueAndValidity();
  }

  /** Get radio button checked values */
  getRadioChecked(opt, dynamicControlData) {
    // eslint-disable-next-line eqeqeq
    if (this.form['value'][dynamicControlData.name]['value'] == opt.id) {
      return true;
    } else {
      return false;
    }
  }

  /** Validate Text box */
  validateTextBox(event) {
    this.errorMessage = 'please enter valid data!.';
    this.dynamicControlData.isValid = false;
  }

  /**Set Empty Error */
  emptyError() {
    this.dynamicControlData.isValid = true;
    this.errorMessage = '';
    this.dynamicControlData.resetToParent = false;
    return this.compareMinMidMaxData();
  }

  /** Set Valid :: contol validations */
  setValid(event) {
    this.dynamicControlData.resetToParent = false;
    /** For resolving mozilla issue added the below condition for backspace */
    // eslint-disable-next-line eqeqeq
    if (event.keyCode == 8) { return true; }
    // eslint-disable-next-line eqeqeq
    if (this.dynamicControlData.valueType == 'Number') {
      // eslint-disable-next-line eqeqeq
      if (event.key.toLowerCase() == 'e' || event.key == '+' || event.key == ',' || event.key == '/'
        // eslint-disable-next-line eqeqeq
        || event.key == '?' || event.key == '*') {
        return false;
      }
      if (this.dynamicControlData.value.includes('.')) {
        // eslint-disable-next-line eqeqeq
        if (event.key == '.') {
          return false;
        }
      }
      // check for Substract symbol (-)
      // eslint-disable-next-line eqeqeq
      if (event.key == '-') {
        // check for negative value for Min value field
        // eslint-disable-next-line eqeqeq
        if (this.dynamicControlData.minValue != null && this.dynamicControlData.minValue < 0) {
          // check for already substract symbol (-) is present
          if (this.dynamicControlData.value.includes('-')) {
            return false;
          } else {
            // check for first time the value is added
            // eslint-disable-next-line eqeqeq
            if (event.target.value != '') {
              const regexDashvalidate = /^-(?:\d*\.\d{1,2}|\d+)$/;
              return regexDashvalidate.test(event.key + event.target.value);
            }
            return true;
          }
        }
      }
      // eslint-disable-next-line eqeqeq
      if (event.key != '.') {
        // eslint-disable-next-line eqeqeq
        if (this.dynamicControlData.minValue != null && this.dynamicControlData.minValue < 0) {
          // eslint-disable-next-line eqeqeq
          if (event.target.value != '' && this.dynamicControlData.value.includes('-')) {
            const regexDotvalidate = /^-(?:\d*\.\d{1,2}|\d+)$/;
            return regexDotvalidate.test(event.target.value + event.key);
          }
        }
        const regexvalidate = /^(?:\d*\.\d{1,2}|\d+)$/;
        return regexvalidate.test(event.target.value + event.key);
      }
    // eslint-disable-next-line eqeqeq
    } else if (this.dynamicControlData.valueType == 'number') {
      // eslint-disable-next-line eqeqeq
      if (event.key.toLowerCase() == 'e' || event.key == '-' || event.key == '+' || event.key == ',' || event.key == '/'
        // eslint-disable-next-line eqeqeq
        || event.key == '?' || event.key == '*' || event.key == '.') {
        return false;
      }
      // eslint-disable-next-line radix
      const parsetxt = parseInt(event.target.value + event.key);
      this.dynamicControlData.resetToParent = false;
      return (!isNaN(parsetxt) && parsetxt >= event.target.min && parsetxt <= event.target.max);
    // eslint-disable-next-line eqeqeq
    } else if (this.dynamicControlData.valueType == 'text') {
      return true;

    }
  }

  /** Set dynamic data value */
  setDynamicDataValue(event) {
    this.dynamicControlData.value = undefined;
    this.dynamicControlData.selectedIndicatorValue = event.target.value;

    for (let i = 0; i < this.dynamicControlData.indicatorOptions.length; i++) {
      const option = this.dynamicControlData.indicatorOptions[i];
      // eslint-disable-next-line eqeqeq
      if (option.name == this.dynamicControlData.selectedIndicatorValue) {
        // eslint-disable-next-line eqeqeq
        this.dynamicControlData.maxValue = option.maxValue == null ? Number.MAX_VALUE : option.maxValue;
        // eslint-disable-next-line eqeqeq
        this.dynamicControlData.minValue = option.minValue == null ? 0 : option.minValue;
        break;
      }
    }
  }

  /** Compare Min, Mid and Max data */
  compareMinMidMaxData() {
    switch (this.dynamicControlData.name) {
      case 'taxableRebalanceCashMin':
      case 'taxableRebalanceCashMid':
      case 'taxableRebalanceCashMax':
      case 'taxExemptRebalanceCashMin':
      case 'taxExemptRebalanceCashMid':
      case 'taxExemptRebalanceCashMax':
      case 'taxDeferredRebalanceCashMin':
      case 'taxDeferredRebalanceCashMid':
      case 'taxDeferredRebalanceCashMax':
        return this.compareMinMidMax();
      case 'optionTradingExpirationStart':
      case 'optionTradingExpirationEnd':
        return this.compareOptionExpiration();
      default:
        return true;
    }
  }

  compareOptionExpiration(): boolean {
    let startValue = this.form.controls['optionTradingExpirationStart']['value'];
    let endValue = this.form.controls['optionTradingExpirationEnd']['value'];
    startValue = startValue === '' ? null : +startValue;
    endValue = endValue === '' ? null : +endValue;
    const isValid = startValue === null || endValue === null || startValue < endValue;
    if (!isValid) {
      this._alertService.alert.emit([{ typeId: 4, message: 'Invalid Option Expiration: Start must be less than End' }]);
    }
    return isValid;
  }

  /**Compare Min, Mid and Max contols */
  compareMinMidMax() {
    let cashMin = 0;
    let cashMid = 0;
    let cashMax = 0;
    const truemin = true;
    // taxableRebalance compare
    // eslint-disable-next-line eqeqeq
    if (this.dynamicControlData.name == 'taxableRebalanceCashMin' || this.dynamicControlData.name == 'taxableRebalanceCashMid' || this.dynamicControlData.name == 'taxableRebalanceCashMax') {
      cashMin = this.form.controls['taxableRebalanceCashMin']['value'];
      cashMid = this.form.controls['taxableRebalanceCashMid']['value'];
      cashMax = this.form.controls['taxableRebalanceCashMax']['value'];
      if (!this.getcashResult(cashMin, cashMid, cashMax, 'Taxable Rebalance')) {
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxableRebalanceCashMin').isValid = false;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxableRebalanceCashMid').isValid = false;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxableRebalanceCashMax').isValid = false;
        return false;
      } else {
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxableRebalanceCashMin').isValid = true;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxableRebalanceCashMid').isValid = true;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxableRebalanceCashMax').isValid = true;
        return true;
      }
    // eslint-disable-next-line eqeqeq
    } else if (this.dynamicControlData.name == 'taxExemptRebalanceCashMin' || this.dynamicControlData.name == 'taxExemptRebalanceCashMid' || this.dynamicControlData.name == 'taxExemptRebalanceCashMax') {
      cashMin = this.form.controls['taxExemptRebalanceCashMin']['value'];
      cashMid = this.form.controls['taxExemptRebalanceCashMid']['value'];
      cashMax = this.form.controls['taxExemptRebalanceCashMax']['value'];
      if (!this.getcashResult(cashMin, cashMid, cashMax, 'Tax Exempt Rebalance')) {
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxExemptRebalanceCashMin').isValid = false;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxExemptRebalanceCashMid').isValid = false;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxExemptRebalanceCashMax').isValid = false;
        return false;
      } else {
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxExemptRebalanceCashMin').isValid = true;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxExemptRebalanceCashMid').isValid = true;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxExemptRebalanceCashMax').isValid = true;
        return true;
      }
    // eslint-disable-next-line eqeqeq
    } else if (this.dynamicControlData.name == 'taxDeferredRebalanceCashMin' || this.dynamicControlData.name == 'taxDeferredRebalanceCashMid' || this.dynamicControlData.name == 'taxDeferredRebalanceCashMax') {
      cashMin = this.form.controls['taxDeferredRebalanceCashMin']['value'];
      cashMid = this.form.controls['taxDeferredRebalanceCashMid']['value'];
      cashMax = this.form.controls['taxDeferredRebalanceCashMax']['value'];
      if (!this.getcashResult(cashMin, cashMid, cashMax, 'Tax Deferred Rebalance')) {
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxDeferredRebalanceCashMin').isValid = false;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxDeferredRebalanceCashMid').isValid = false;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxDeferredRebalanceCashMax').isValid = false;
        return false;
      } else {
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxDeferredRebalanceCashMin').isValid = true;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxDeferredRebalanceCashMid').isValid = true;
        // eslint-disable-next-line eqeqeq
        this.preferenceResults.find(x => x.name == 'taxDeferredRebalanceCashMax').isValid = true;
        return true;
      }
    }
  }

  /** Get cash result */
  getcashResult(cashMin, cashMid, cashMax, propertytype) {
    // eslint-disable-next-line eqeqeq
    if (cashMin == '' && cashMid == '' && cashMax == '') {
      return true;
    // eslint-disable-next-line eqeqeq
    } else if (cashMin != '' && cashMid != '' && cashMax != '' && +cashMin >= 0.0 && +cashMid >= 0.0 && +cashMax >= 0.0) { // all fields are not null or 0
      if (!((+cashMin <= +cashMid) && (+cashMid <= +cashMax) && (+cashMax >= +cashMin))) {
        // eslint-disable-next-line prefer-template
        this._alertService.alert.emit([{ typeId: 4, message: 'Invalid ' + propertytype + '  MIN <= MID <= MAX' }]);
        return false;
      } else {
        return true;
      }
    // eslint-disable-next-line eqeqeq
    } else if (cashMin != '' && cashMid != '' && cashMax != '' && +cashMin == 0 && +cashMid == 0 && +cashMax == 0) {  // all are empty/null
      return true;
 } else {  // any one fields has the value
      // eslint-disable-next-line prefer-template
      this._alertService.alert.emit([{ typeId: 4, message: 'Invalid ' + propertytype + ' MIN, MID, MAX should have values' }]);
      return false;
    }
  }

  /** Ticker function */
  tickerFunc(tick) {
    // eslint-disable-next-line eqeqeq
    if (tick == 0) {
      this.setMessageDefaults();
    }
  }

  /** Set the success and error messages to defaults and empty */
  setMessageDefaults() {
    this.errorMessage = '';
    this.successMessage = '';
    this.showSuccessMessage = false;
    this.showErrorMessage = false;
  }

  /************************************ MODEL IMPORT SECTION - START *******************************/

  /******* VARIABLES : MODEL IMPORT *******/
  file: ISecurityPreferenceExcelImport = <ISecurityPreferenceExcelImport>{};
  private displayImportModel: boolean;
  private disableUploadBtn: boolean = true;

  copyErrorMessage: boolean = false;
  areErrors: boolean = false;
  isError: boolean = false;

  importedSecurityPrefResult: ISecurityPreferencesGet;

  /** Check whether json object is array or not */
  isArray(what) {
    return Object.prototype.toString.call(what) === '[object Array]';
  }

  /** Fires when cancel is clicked */
  cancelImport() {
    this.displayImportModel = false;
    this.disableUploadBtn = true;
  }

  /** Boolean Fields for enabling pop-ups */
  isEnableLocOpt: boolean = false;
  isEnableComStr: boolean = false;
  isEnableSecSet: boolean = false;
  isEnableRedFee: boolean = false;
  isEnableCustRedFee: boolean = false;
  isEnableTransFee: boolean = false;
  isEnableTradeMin: boolean = false;
  isEnableTradeMax: boolean = false;
  isEnableDivReinv: boolean = false;
  isEnableCapReinv: boolean = false;
  isEnableTradeSet: boolean = false;
  isEnableDefaultPrimTeam: boolean = false;
  isEnableInheritedSystematicMultiplier: boolean = false;
  isEnableSystematicMultiplier: boolean = false;
  isEnableInheritedBillingCashMultiplier: boolean = false;
  isEnableBillingCashMultiplier: boolean = false;
  inheritedFromTxt: string = '';

  dataSource: any[] = [];
  optimizations: any[] = [{ id: 1, value: '1' }, { id: 2, value: '2' }, { id: 3, value: '3' }, { id: 4, value: 'Never' }];

  @Input() clonedCommunityStrategies: ICustomCommunityPreference;
  @Input() clonedRedemptionFeePrefResult: IRedemptionFeePreferencesGet;
  @Input() clonedCustodianRedemptionFeePrefResult: ICustodianRedemptionFeePreferencesGet;
  @Input() clonedTradeMinPrefResult: ITradeMinPreferencesGet;
  @Input() clonedTradeMaxPrefResult: ITradeMaxPreferencesGet;
  @Input() clonedTransactionFeePrefResult: ITransactionFeePreferencesGet;
  @Input() clonedDividendReinvestPrefResult: IDividendReinvestPreferencesGet;
  @Input() clonedCapitalGainReinvestPrefResult: ICapitalGainReinvestPreferencesGet;
  @Input() clonedTradeSettingsPrefResult: ITradeSettingsPreferencesGet;
  @Input() clonedDefaultPrimaryTeamPrefResult: IDefaultPrimaryTeamPreferencesGet;
  @Input() clonedSystematicMultiplierPrefResult: ISystematicMultiplierPreference;
  @Input() clonedBillingCashMultiplierPrefResult: IBillingCashMultiplierPreference;
  @Input() clonedLocationOptimizations: ILocationOptimizationCustom;
  @Input() cloneMoneyMarketAllocationPreference: IMoneyMarketAllocationPreference;
  @Input() cloneMoneyMarketFundPreference: IMoneyMarketFundPreference;
  clonedSecurityTypes: any[]; /** Security Types :: New approach  */

  /** view location optimization pop-up to show inherited values */
  viewInheritedPopups() {
    this.clonedSecurityTypes = Util.deepClone(this.securityTypes);
    this.enablePopups();
  }

  /** View Security inherited pop-ups */
  viewSecurityInheritedPopup() {
    this.isEnableSecSet = true;
  }

  /** Enable Security inherited Popup */
  enableSecurityInheritedPopup() {
    this.inheritedFromTxt = '';
    this.isEnableSecSet = true;
  }

  /** Enable pop-ups based on component name for viewing parent preferences */
  enablePopups() {
    /** Custom components enabling  */
    switch (this.dynamicControlData.componentName) {
      case 'CommunityStrategistEnabledListCascadingControl':
        this.isEnableComStr = true;
        break;
      case 'SecurityDataGrid':
        this.isEnableSecSet = true;
        break;
      case 'RedemptionFeeDataGrid':
        this.isEnableRedFee = true;
        break;
      case 'CustodianRedemptionFeeDataGrid':
        this.isEnableCustRedFee = true;
        break;
      case 'TransactionFeeDataGrid':
        this.isEnableTransFee = true;
        break;
      case 'LocationOptimizationDataGrid':
        this.isEnableLocOpt = true;
        break;
      case 'TradeMinDataGrid':
        this.isEnableTradeMin = true;
        break;
      case 'TradeMaxDataGrid':
        this.isEnableTradeMax = true;
        break;
      case 'DividendReinvestDataGrid':
        this.isEnableDivReinv = true;
        break;
      case 'CapitalGainReinvestDataGrid':
        this.isEnableCapReinv = true;
        break;
      case 'TradeSettingsDataGrid':
        this.isEnableTradeSet = true;
        break;
      case 'DefaultPrimaryTeamDropdownSearch':
        this.isEnableDefaultPrimTeam = true;
        break;
      case 'SystematicMultiplierLink':
        this.isEnableInheritedSystematicMultiplier = true;
        break;
      case 'BillingCashMultiplierLink':
        this.isEnableInheritedBillingCashMultiplier = true;
        break;
    }
  }

  /** Clone the result sets of all custom components for Inherited Pop-up display purpose */
  setClonedInheritedCustomComponents() {
    switch (this.dynamicControlData.componentName) {
      case 'CommunityStrategistEnabledListCascadingControl':
        this.clonedCommunityStrategies = Util.deepClone(this.communityStrategies);
        // eslint-disable-next-line eqeqeq
        this.clonedCommunityStrategies.strategists = (this.communityStrategies.inheritedStrategists != null || this.communityStrategies.inheritedStrategists != undefined) ?
          Util.deepClone(this.communityStrategies.inheritedStrategists) : null;
        this.inheritedFromTxt = this.clonedCommunityStrategies.strategists.inheritedFrom;
        break;
      case 'LocationOptimizationDataGrid':
        this.clonedLocationOptimizations = Util.deepClone(this.LocationOptimizations);
        // eslint-disable-next-line eqeqeq
        this.clonedLocationOptimizations.assetTypes = (this.LocationOptimizations.inheritedAssetTypes != null || this.LocationOptimizations.inheritedAssetTypes != undefined) ?
          Util.deepClone(this.LocationOptimizations.inheritedAssetTypes) :
          null;
        break;
      case 'SecurityDataGrid':
        break;
      case 'RedemptionFeeDataGrid':
        this.clonedRedemptionFeePrefResult = Util.deepClone(this.RedemptionFeePrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedRedemptionFeePrefResult.redemptionFeePreferences = (this.RedemptionFeePrefResult.inheritedRedemptionFeePreferences != null || this.RedemptionFeePrefResult.inheritedRedemptionFeePreferences == undefined) ?
          Util.deepClone(this.RedemptionFeePrefResult.inheritedRedemptionFeePreferences) : null;
        break;
      case 'CustodianRedemptionFeeDataGrid':
        this.clonedCustodianRedemptionFeePrefResult = Util.deepClone(this.CustodianRedemptionFeePrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedCustodianRedemptionFeePrefResult.custodianRedemptionFeePreferences = (this.CustodianRedemptionFeePrefResult.inheritedCustodianRedemptionFeePreferences != null || this.CustodianRedemptionFeePrefResult.inheritedCustodianRedemptionFeePreferences != undefined) ?
          Util.deepClone(this.CustodianRedemptionFeePrefResult.inheritedCustodianRedemptionFeePreferences) : null;
        break;
      case 'TransactionFeeDataGrid':
        this.clonedTransactionFeePrefResult = Util.deepClone(this.TransactionFeePrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedTransactionFeePrefResult.transactionFeePreferences = (this.TransactionFeePrefResult.inheritedTransactionFeePreferences != null || this.TransactionFeePrefResult.inheritedTransactionFeePreferences != undefined) ?
          Util.deepClone(this.TransactionFeePrefResult.inheritedTransactionFeePreferences) : null;
        break;
      case 'TradeMinDataGrid':
        this.clonedTradeMinPrefResult = Util.deepClone(this.TradeMinPrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedTradeMinPrefResult.tradeMinPreferences = (this.TradeMinPrefResult.inheritedTradeMinPreferences != null || this.TradeMinPrefResult.inheritedTradeMinPreferences != undefined) ?
          Util.deepClone(this.TradeMinPrefResult.inheritedTradeMinPreferences) : null;
        break;
      case 'TradeMaxDataGrid':
        this.clonedTradeMaxPrefResult = Util.deepClone(this.TradeMaxPrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedTradeMaxPrefResult.tradeMaxPreferences = (this.TradeMaxPrefResult.inheritedTradeMaxPreferences != null || this.TradeMaxPrefResult.inheritedTradeMaxPreferences != undefined) ?
          Util.deepClone(this.TradeMaxPrefResult.inheritedTradeMaxPreferences) : null;
        break;
      case 'DividendReinvestDataGrid':
        this.clonedDividendReinvestPrefResult = Util.deepClone(this.DividendReinvestPrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedDividendReinvestPrefResult.dividendReinvestPreferences = (this.DividendReinvestPrefResult.inheritedDividendReinvestPreferences != null || this.DividendReinvestPrefResult.inheritedDividendReinvestPreferences != undefined) ?
          Util.deepClone(this.DividendReinvestPrefResult.inheritedDividendReinvestPreferences) : null;
        break;
      case 'CapitalGainReinvestDataGrid':
        this.clonedCapitalGainReinvestPrefResult = Util.deepClone(this.CapitalGainReinvestPrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedCapitalGainReinvestPrefResult.capitalGainReinvestPreferences = (this.CapitalGainReinvestPrefResult.inheritedCapitalGainReinvestPreferences != null || this.CapitalGainReinvestPrefResult.inheritedCapitalGainReinvestPreferences != undefined) ?
          Util.deepClone(this.CapitalGainReinvestPrefResult.inheritedCapitalGainReinvestPreferences) : null;

        break;
      case 'TradeSettingsDataGrid':
        this.clonedTradeSettingsPrefResult = Util.deepClone(this.TradeSettingsPrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedTradeSettingsPrefResult.tradeSettingsPreferences = (this.TradeSettingsPrefResult.inheritedTradeSettingsPreferences != null || this.TradeSettingsPrefResult.inheritedTradeSettingsPreferences != undefined) ?
          Util.deepClone(this.TradeSettingsPrefResult.inheritedTradeSettingsPreferences) : null;
        break;
      case 'DefaultPrimaryTeamDropdownSearch':
        this.clonedDefaultPrimaryTeamPrefResult = Util.deepClone(this.DefaultPrimaryTeamsPrefResult);
        // eslint-disable-next-line eqeqeq
        this.clonedDefaultPrimaryTeamPrefResult.defaultPrimaryTeamPreferences = (this.clonedDefaultPrimaryTeamPrefResult.inheritedDefaultPrimaryTeamPreferences != null || this.clonedDefaultPrimaryTeamPrefResult.inheritedDefaultPrimaryTeamPreferences != undefined) ?
          Util.deepClone(this.clonedDefaultPrimaryTeamPrefResult.inheritedDefaultPrimaryTeamPreferences) : null;
        break;
      case 'SystematicMultiplierLink':
        this.clonedSystematicMultiplierPrefResult = Util.deepClone(this.SystematicMultiplierPrefResult);
        this.clonedSystematicMultiplierPrefResult.systematicMultiplierPreferences = (this.SystematicMultiplierPrefResult.inheritedSystematicMultiplierPreferences !== null || this.SystematicMultiplierPrefResult.inheritedSystematicMultiplierPreferences !== undefined) ?
          Util.deepClone(this.SystematicMultiplierPrefResult.inheritedSystematicMultiplierPreferences) : null;
        break;
      case 'BillingCashMultiplierLink':
        this.clonedBillingCashMultiplierPrefResult = Util.deepClone(this.BillingCashMultiplierPrefResult);
        this.clonedBillingCashMultiplierPrefResult.billingCashMultiplierPreferences = (this.BillingCashMultiplierPrefResult.inheritedBillingCashMultiplierPreferences !== null || this.BillingCashMultiplierPrefResult.inheritedBillingCashMultiplierPreferences !== undefined) ?
          Util.deepClone(this.BillingCashMultiplierPrefResult.inheritedBillingCashMultiplierPreferences) : null;
        break;
      case PreferenceEnums.MoneyMarketAllocationGrid.toString():
        this.cloneMoneyMarketAllocationPreference = Util.deepClone(this.MoneyMarketAllocationPreference);
        this.cloneMoneyMarketAllocationPreference.moneyMarketAllocations = this.MoneyMarketAllocationPreference.inheritedMoneyMarketAllocations ?
          Util.deepClone(this.MoneyMarketAllocationPreference.inheritedMoneyMarketAllocations) : [];
        break;
      case PreferenceEnums.MoneyMarketFundsGrid.toString():
        this.cloneMoneyMarketFundPreference = Util.deepClone(this.MoneyMarketFundPreference);
        this.cloneMoneyMarketFundPreference.moneyMarketFunds = this.MoneyMarketFundPreference.inheritedMoneyMarketFunds ?
          Util.deepClone(this.MoneyMarketFundPreference.inheritedMoneyMarketFunds) : [];
        break;
    }
  }

    // SF-154198 enable disable Tax Lot Depletion Method method based on lowest estimated tax.
  checkForTaxMethods() {
    if (this.dynamicControlData.name === 'lowestEstimatedTax') {
      this.updatePreference.emit({name: this.dynamicControlData.name, value: this.dynamicControlData.value});
    }
  }
}
