import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TomService } from '../../services/tom.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ITradeOrderImport, ITradeOrderMessage } from '../../models/tom';
import * as _ from 'lodash';

@Component({
  selector: 'eclipse-trade-file-import-panel',
  templateUrl: './fileimportpanel.component.html',
})
export class TradeFileImportPanelComponent extends BaseComponent {
  private checkDragFile: boolean = true;
  private dragFileName: string;
  private file: File;
  private saveTradesSubscription: Subscription;
  private tradeOrderErrorMessages: ITradeOrderMessage[];

  public tradesData: any;
  public tradeOrders: ITradeOrderImport[] = [];

  @Input() importType: 'QuickTrade' | 'FixedIncome';
  @Input() trades: any;
  @Input() portfolioFlag: any;
  @Input() disableTrade: any;
  @Input() fileUploadError: string;
  @Input() showFileUploadError: boolean = false;
  @Input() isFixedIncome: boolean = false;
  /**
   * Emits when a file has been selected and is ready for the consumer to process it.
   */
  @Output() uploadFileNotify = new EventEmitter<string>();
  /**
   * Emits when the trades imported from a file have been submitted for saving.
   */
  @Output() onSubmitNotify = new EventEmitter<void>();

  constructor(private readonly _tradeService: TomService) {
    super();
  }

  /** Fires when file is dragged */
  dragFile(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /** Fires when file is dropped */
  dropFile(event) {
    // eslint-disable-next-line eqeqeq
    if (event.dataTransfer.files.length != 1) {
      this.fileUploadError = 'Only one file can be uploaded at a time.';
      this.showFileUploadError = true;
    } else {
      this.checkDragFile = true;
      this.dragFileName = event.dataTransfer.files[0].name;
      this.selectedModelFile(event.dataTransfer.files);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  selectedTemplate(event) {
    this.selectedModelFile(event.target.files);
  }

  // import excel to generate trade file
  selectedModelFile(file) {
    this.showFileUploadError = false;
    const selectedFile = file[0];
    if (selectedFile && this.isValidExcelFile(selectedFile.type)) {
      this.processSelectedModelFile(selectedFile);
      this.file = null; // reset the selected file, in case the user has to upload the same one again
    } else {
      this.showFileUploadError = true;
      this.fileUploadError = 'Only (.xlsx/.csv) files are allowed.';
    }
  }

  onReset() {
    this.trades = null;
    this.portfolioFlag = null;
    this.fileUploadError = null;
    this.showFileUploadError = false;
    this.checkDragFile = true;
    this.dragFileName = null;
    this.file = null;
  }

  onSave() {
    this.disableTrade = true;
    if (this.isFixedIncome) {
      this.saveValidFixedIncomeTradeOrders();
    } else {
      this.saveValidTradeOrders();
    }
  }

  private getAllTradeOrderMessages(): Observable<ITradeOrderMessage[]> {
    // if the messages were already retrieved, just return the local copy instead of re-querying the api
    if (this.tradeOrderErrorMessages) {
      return of<ITradeOrderMessage[]>(this.tradeOrderErrorMessages);
    }
    return this._tradeService.getAllTradeOrderMessages();
  }

  processSelectedModelFile(selectedFile) {
    const servicesToExecute: Observable<any>[] = [];
    servicesToExecute.push(this.getAllTradeOrderMessages());

    if (this.isFixedIncome) {
      servicesToExecute.push(this._tradeService.importFixedIncomeTradeFile(selectedFile));
    } else {
      servicesToExecute.push(this._tradeService.importTradeFile(selectedFile));
    }

    forkJoin(servicesToExecute)
      .subscribe({
        next: (data: any) => {
          if (data[0]) {
            this.tradeOrderErrorMessages = data[0];
          }
          this.tradesData = data[1];
          if (this.tradesData && this.tradesData.trades) {
            this.tradesData.trades.forEach(trade => {
              if (trade.isValid) {
                this.disableTrade = false;
              }
              // consolidate the trade errors into a single string (v2 validation only)
              if (trade.error && Array.isArray(trade.error)) {
                trade.errorMessage = trade.error ? trade.error.map(err => {
                  const errMsg = _.find(this.tradeOrderErrorMessages, msg => msg.id === err.errorMessageId);
                  if (!errMsg) {
                    return 'Unknown error';
                  }
                  err.errorMessage = errMsg.message;
                  const errArgs = err.errorMessageArguments.split(';');
                  for (let i: number = 0, len = errArgs.length; i < len; i++) {
                    err.errorMessage = err.errorMessage.replace(`{${i}}`, errArgs[i]);
                  }
                  return err.errorMessage;
                }).join('\n') : null;
              }
            });
          }
          if (this.isFixedIncome) {
            this.tradeOrders = this.tradesData.trades;
          } else {
            this.tradeOrders = this.tradesData.trades || [];
          }
          this.trades = this.tradeOrders;
          this.portfolioFlag = this.tradesData && this.tradesData.portfolioFlag ? this.tradesData.portfolioFlag.filter(flag => flag.needAnalytics !== 0) : null;
        },
        error: (error) => {
          this.showFileUploadError = true;
          let errJson;
          try {
            errJson = JSON.parse(error);
          } catch (e) {
            console.log(e);
          }
          this.fileUploadError = errJson && errJson.message ? errJson.message : error;
        },
      });
  }

  saveValidTradeOrders() {
    // if the trades save is already in flight, ditch this request and wait for the other one to complete
    if (this.saveTradesSubscription && !this.saveTradesSubscription.closed) {
      return;
    }
    this.tradeOrders = this.tradeOrders.filter(t => !!t.isValid);
    this.tradesData.trades = this.tradeOrders;      // OE-3543 send valid trades in api.
    if (this.tradesData.trades.length > 0) {
      this.tradesData.trades.map(t => delete t.errorMessage); // delete the errorMessage property since it was added on the UI, for the UI.
      this.saveTradesSubscription = this._tradeService.saveUploadTrades({
        tradesAreAlreadyValidated: true,
        trades: this.tradesData.trades,
      })
        .subscribe(() => {
          this.trades = null;
          this.tradesData = null;
          this.onSubmitNotify?.emit();
        });
    }
  }

  saveValidFixedIncomeTradeOrders() {
    // if the trades save is already in flight, ditch this request and wait for the other one to complete
    if (this.saveTradesSubscription && !this.saveTradesSubscription.closed) {
      return;
    }

    this.tradeOrders = this.tradeOrders.filter(t => !!t.isValid);
    if (this.isFixedIncome && this.tradeOrders && this.tradeOrders.length > 0) {
      this.tradeOrders = this.tradeOrders.filter(t => !!t.isValid);
    }
    this.tradesData.trades = this.isFixedIncome ? this.tradeOrders : this.tradeOrders;
    this.tradesData.isFixedIncome = this.isFixedIncome;
    if (this.tradesData.trades.length > 0) {
      this.saveTradesSubscription = this._tradeService.saveUploadFixedIncomeTrades(this.tradesData)
        .subscribe(() => {
          this.trades = null;
          this.tradesData = null;
          this.portfolioFlag = null;
          this.onSubmitNotify?.emit();
        });
    }
  }
}
