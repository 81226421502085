<p-autoComplete [(ngModel)]="selectedItem" [suggestions]="suggestions" placeholder="Trade Group Name"
                (completeMethod)="loadSuggestions($event)" (onSelect)="onTradeGroupSelect($event)" field="name" class="autocomplete-search" [minLength]="1">
  <ng-template let-selectedItem pTemplate="selectedItemTemplate">
    <ul class="ui-helper-clearfix show-hollding-dropdown">
      <li class="vertical-center">
        <i class="fas fa-briefcase text-info" aria-hidden="true"></i>
        <span>{{selectedItem.id + " : " +selectedItem.name}}</span>
      </li>
    </ul>
  </ng-template>
</p-autoComplete>
