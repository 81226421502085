import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IPortfolioTree, IPortfolioTreeAccount } from '../../models/portfolio';
import { PortfolioService } from '../../services/portfolio.service';
import { Subscription } from 'rxjs';
import { IAccountTreeItem, IEntityChangedEvent, IPortfolioTreeItem, ITreeItem } from './portfolio-tree-item';
import { EntityType } from '../../libs/preference.enums';

@Component({
  selector: 'eclipse-portfolio-tree-dropdown',
  templateUrl: './portfolio-tree.component.html',
})
export class PortfolioTreeComponent implements OnDestroy {
  @Output() entityChanged: EventEmitter<IEntityChangedEvent> = new EventEmitter<IEntityChangedEvent>();

  portfolioTreeSubscription: Subscription;
  entityTypes = EntityType; // Exposes the EntityType enum so it can be used in the template
  hierarchy: ITreeItem[];

  private _disabled: boolean = false;
  @Input()
  public get disabled(): boolean {
    return this._disabled || !this.hierarchy?.length; // disabled if the list is empty or manually disabled
  }

  public set disabled(value: boolean) {
    this._disabled = value;
  }

  private _portfolioId: number;
  @Input()
  /**
   * The id of the portfolio for which this tree has been loaded.
   * Used as the root of the tree.
   */
  public get portfolioId(): number {
    return this._portfolioId;
  }

  public set portfolioId(value: number) {
    this._portfolioId = value;
    if (value) {
      this.getPortfolioTree();
    } else {
      this.hierarchy = null;
    }
  }

  private _accountId: number;
  @Input()
  /**
   * The id of the account for which this tree has been loaded.
   * If defined, this account will be initially selected
   */
  public get accountId(): number {
    return this._accountId;
  }

  public set accountId(value: number) {
    this._accountId = value;
    if (value) {
      this.getPortfolioTree();
    } else {
      this.hierarchy = null;
    }
  }

  private _selectedHierarchyEntity: any;
  /**
   * The currently selected item in the dropdown
   */
  public get selectedHierarchyEntity(): any {
    return this._selectedHierarchyEntity;
  }

  public set selectedHierarchyEntity(value: any) {
    this._selectedHierarchyEntity = value;
  }

  constructor(private readonly _portfolioService: PortfolioService) {
  }

  ngOnDestroy(): void {
    this.portfolioTreeSubscription?.unsubscribe();
  }

  /**
   * The selected item in the dropdown has changed.
   * @param evt
   */
  onChange(evt: any): void {
    const selectedItem = this.hierarchy.find(x => x.uniqueId === evt.value);
    this.entityChanged.emit({id: selectedItem.id, entityType: selectedItem.entityType});
  }

  /**
   * Retrieves the portfolio tree from the server.
   */
  getPortfolioTree(): void {
    this.portfolioTreeSubscription?.unsubscribe();
    this.hierarchy = null;
    this.portfolioTreeSubscription = this._portfolioService.getPortfolioTree(this.portfolioId, this.accountId)
      .subscribe({
          next: result => {
            const items = [];
            if(result.id) {
              const portfolioTreeItem = this.convertPortfolioTreeToPortfolioTreeItem(result);
              items.push(portfolioTreeItem);
            }
            if(result.accounts?.length) {
              const accountTreeItems = this.convertPortfolioTreeTreeAccountsToAccountTreeItems(result.accounts);
              items.push(...accountTreeItems);
            }
            this.hierarchy = items;
            this.setSelectedEntity();
          },
        },
      );
  }

  /**
   * Sets the selected entity based on the ids provided.
   * If only a portfolio id is available, the portfolio is selected.
   * If an account id is provided, the account is selected.
   */
  setSelectedEntity(): void {
    let item;
    if (this.accountId) {
      item = this.hierarchy.find(f => f.entityType === EntityType.Account && f.id === this.accountId);
    } else {
      item = this.hierarchy.find(f => f.entityType === EntityType.Portfolio && f.id === this.portfolioId);
    }
    if (item) {
      this.selectedHierarchyEntity = item.uniqueId;
    }
  }

  public convertPortfolioTreeToPortfolioTreeItem(treeItem: IPortfolioTree): IPortfolioTreeItem {
    // prefix with P to retain uniqueness in the dropdown (portfolio and account ids could potentially be the same)
    return <IPortfolioTreeItem>{
      uniqueId: `P-${treeItem.id}`,
      id: treeItem.id,
      displayId: treeItem.id.toString(),
      name: treeItem.name,
      totalValue: treeItem.value,
      entityType: EntityType.Portfolio,
    };
  }

  public convertPortfolioTreeTreeAccountsToAccountTreeItems(treeItems: IPortfolioTreeAccount[]): IAccountTreeItem[] {
    // prefix with A to retain uniqueness in the dropdown (portfolio and account ids could potentially be the same)
    return treeItems?.map(a => {
      return <IAccountTreeItem>{
        uniqueId: `A-${a.id}`,
        id: a.id,
        displayId: a.accountId,
        accountId: a.accountId,
        accountNumber: a.accountNumber,
        name: a.name,
        totalValue: a.value,
        accountType: a.accountType,
        entityType: EntityType.Account,
      };
    }) || [];
  }
}
