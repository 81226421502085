import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { PortfolioEditorService } from './portfolio-editor.service';
import { Utils as Util } from '../../../core/functions';
import { EntityType as CompareToolEntityType } from '@oat/web-components/shared';
import { PortfolioEntity } from './types';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'eclipse-portfolio-editor-compare-tool',
  templateUrl: './portfolio-compare-tool.component.html'
})
export class PortfolioCompareToolComponent extends BaseComponent {
  public portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public portfolio$: Observable<PortfolioEntity>;
  public compareEntityId: number;
  public compareEntityType: CompareToolEntityType;
  constructor() {
    super();

    this.portfolio$ = this.portfolioEditorService.portfolio$
      .pipe(tap(portfolio => {
        const isPortfolioGroup = portfolio.portfolioGroupId !== null;
        if (Util.isNull(this.compareEntityType)) {
          this.compareEntityType = isPortfolioGroup ? CompareToolEntityType.PortfolioGroup : CompareToolEntityType.Registration;
        }
        if (Util.isNull(this.compareEntityId)) {
          this.compareEntityId = isPortfolioGroup ? portfolio.portfolioGroupId : portfolio.registrationId;
        }
      }));
  }

  ngOnDestroy() {
    this.portfolio$ = null;
  }
}
