import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {OEHttpClient} from '../core';
import {IUser} from '../models/user';

declare let aptrinsic: any;

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _loginEndPoint = 'v1/admin/Token';
  private _logoutEndPoint = 'v1/admin/logout';

  constructor(private _httpClient: HttpClient, private _oeHttpClient: OEHttpClient) {
  }

  login(username: string, password: string) {
    let authHeaders = new HttpHeaders();
    authHeaders = this.createAuthorizationHeader(authHeaders, username, password);

    return this._oeHttpClient.login(this._loginEndPoint, authHeaders);
  }

  logout() {
    return this._oeHttpClient.getData(this._logoutEndPoint);
  }

  registerGainsightSession(user: IUser): void {
    const splitUsername = user.name.split(' '); // split the name string into 2 separate strings
    const nameArray = [splitUsername.shift(), splitUsername.join(' ')];
    const fname = nameArray[0]; // first part of the name until the first empty space
    const lname = nameArray[1] || ''; // remainder of the name string

    // Gainsight PX Tracking https://support.gainsight.com/PX/API/02Track_your_Users_and_Accounts
    // https://support.gainsight.com/PX/API/03Work_with_the_Gainsight_PX_Web_SDK#Identify_Call_Best_Practices
    // @ts-ignore
    aptrinsic('identify', {
      id: user.id.toString(), // must be a string per gainsight best practices doc referenced above
      firstName: fname,
      lastName: lname,
      email: user.email,
      accountId: user.userLoginId,
      role: user.role.roleType
    }, {
      id: user.firmId.toString(), // must also be a string per gainsight best practices doc referenced above
      name: user.firmName
    });
  }

  /**
   * Creates authorization header with basic authorization
   */
  createAuthorizationHeader(headers: HttpHeaders, username: string, password: string) {
    headers = headers.set('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Basic ${btoa(`${username}:${password}`)}`);
    return headers;
  }

  // This method is used to authorize Eclipse connect user with Eclipse App.
  authorizeOrionConnectToken(accessToken: string) {
    let authHeaders = new HttpHeaders();
    authHeaders = authHeaders.set('Accept', 'application/json');
    authHeaders = authHeaders.append('Content-Type', 'application/json');
    authHeaders = authHeaders.append('Authorization', `Session ${accessToken}`);

    const endpoint = this._oeHttpClient.getApiUrl(this._loginEndPoint);

    return this._httpClient.get(endpoint, {headers: authHeaders, observe: 'body'});
  }
}
