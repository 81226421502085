<div class="main">
  <eclipse-page-header pageName="Error Logs" [allowExcelExport]="true" (exportToExcel)="exportToExcel()">
    <div slot="action">
      <div class="actionBtn btn-group pull-right">
        <button data-target="#" label="Actions" iconPos="left" icon="fas fa-sparkles" pButton
                class="p-button-text p-button-secondary" data-toggle="dropdown" aria-expanded="false"></button>
        <ul class="dropdown-menu">
          <li><a href="javascript:void(0)" (click)="getErrorData()"><i class="fas fa-sync" aria-hidden="true"></i>
            Refresh Grid</a></li>
            <li><a href="javascript:void(0)" (click)="shouldShowConfirmResyncDialog=true"><i class="fas fa-sync" aria-hidden="true"></i>
              Resync Accounts from Orion Connect</a></li>
        </ul>
      </div>
    </div>
  </eclipse-page-header>
  <div class="dashboard-content">
    <div class="tab-content">
      <div class="tab-pane fade in active grid-height">
        <div class="agcontainer">
          <ag-grid-angular #agGrid class="ag-theme-alpine grid-height-autosizeThr"
                           [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
                           [columnDefs]="columnDefs" [rowData]="errorLogsData"
                           [suppressContextMenu]="true">
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <p-dialog header="Confirm Resync" [(visible)]="shouldShowConfirmResyncDialog" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
      <div>Are you sure you want to sync all of the errored accounts?</div>
      <p-footer>
        <button type="button" pButton (click)="shouldShowConfirmResyncDialog=false" label="Cancel"></button>
        <button type="button" pButton (click)="resyncAccounts()" label="Ok"></button>
      </p-footer>
    </p-dialog>
    <p-dialog header="Resync Request Status" [(visible)]="shouldShowResyncStatusDialog" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
      <div>{{resyncStatus}}</div>
      <div hidden>{{resyncErrorMessage}}</div>
      <p-footer>
        <button type="button" pButton (click)="shouldShowResyncStatusDialog=false" [disabled]="!shouldEnableResyncStatusOkButton" label="Ok"></button>
      </p-footer>
    </p-dialog>
  </div>
</div>
