import { Component, Input } from '@angular/core';
import {forkJoin} from 'rxjs';
import {PreferenceService} from '../../services/preference.service';
import {CommunityService} from '../../services/community.service';
import {BaseComponent} from '../../core/base.component';
import {ICommunityStrategist} from '../../models/community';
import {ICustomCommunityPreference, IPreferenceCommunity} from '../../models/preferences/preferenceCommunity';
import {Utils} from '../../core/functions';

@Component({
  selector: 'eclipse-community-strategist',
  templateUrl: './communitystrategist.component.html',
  providers: [PreferenceService, CommunityService]
})
export class CommunityStrategistComponent extends BaseComponent {

  communityStrategist: ICommunityStrategist[] = [];
  communities: any[] = [];
  modelAccessLevel: number;
  selectedStrategistItems: any[] = [];
  modelStrategistItems: any[] = [];
  errorMessage: string;
  communitiesAllowedStratToolTip: string;
  communitiesAllowedStratModelsToolTip: string;
  @Input() displayPermission: string;
  @Input() CommunityStrategies: ICustomCommunityPreference;

  // ** Community Strategist list
  selectedStrategitNames: any[] = [];
  selecetdModel: string;
  selectedCommunityNames: any[] = [];

  constructor(private _preferenceService: PreferenceService, private _communityService: CommunityService) {
    super();
    this.modelAccessLevel = 1;
    this.CommunityStrategies = <ICustomCommunityPreference>{};
  }

  ngOnInit() {
    this.communitiesAllowedStratToolTip = `Allows users to permission strategists for the firm/team. Strategists must be subscribed to in communities for them to be available.

    Enabling a strategist means that portfolios in that firm or team will be able to assign the community models.

    Available at: Firm, Team level.`;

    this.communitiesAllowedStratModelsToolTip = `Allows users to permission models from the selected strategists (from preference "Communities - Allowed Strategists").

    If "ALL" is chosen, the firm/team has access to all models from the selected strategist(s).
    If "SELECTED" is chosen only permissioned models from the selected strategist(s) are accessible by the firm/team.

    Available at: Firm, Team Level`;

    this.getStrategists();
  }

  /** get Inherited Options */
  getInheritedOptions(communityStrategies: IPreferenceCommunity) {
    if (Object.keys(communityStrategies).length > 0) {
      this.CommunityStrategies.strategists = communityStrategies;
      this.getStrategists();
    } else {
      this.selectedStrategistItems = [];
      this.modelAccessLevel = 1;
    }
  }

  /**load Stragies and models based on strategy id */
  getStrategists() {
    this.selectedStrategitNames = [];
    this.selecetdModel = '';
    this.selectedCommunityNames = [];
    this.modelStrategistItems = [];
    this.communities = [];
    const apiArray = [];
    apiArray.push(this._communityService.getCommunityStrategist());
    if (Object.keys(this.CommunityStrategies.strategists).length > 0) {
      apiArray.push(this._communityService.getCommunityModelByStrategistId(this.CommunityStrategies.strategists.strategistIds));
    }

    forkJoin(apiArray)
      .subscribe((model: any[]) => {
        const items = [];
        model[0].forEach(element => {
          items.push({ label: element.name, value: element.id });
          items.sort((t1, t2) => {
            if (t1.label > t2.label) { return 1; }
            if (t1.label < t2.label) { return -1; }
            return 0;
          });
        });
        this.modelStrategistItems = items;
        // eslint-disable-next-line eqeqeq
        const convertedstrategistIds = (this.CommunityStrategies.strategists.strategistIds != null) ?
          Utils.toPrimitiveInt(this.CommunityStrategies.strategists.strategistIds) : null;

        // eslint-disable-next-line eqeqeq
        if (convertedstrategistIds != null) {
          model[1].forEach(element => {
            this.communities.push({ communityId: element.id, community: element.name, strategistId: element.strategistId, isSelected: false });
            // eslint-disable-next-line no-shadow
            this.communities.forEach(element => {
              // eslint-disable-next-line eqeqeq
              if (this.CommunityStrategies.strategists.communityModels != null) {
                // eslint-disable-next-line eqeqeq
                const match = this.CommunityStrategies.strategists.communityModels.filter(record => record == element.communityId);
                // eslint-disable-next-line eqeqeq
                element.isSelected = match.length > 0 && match.length != undefined;
              }
            });
          });
        }

        // Adding Data for View
        if (!this.displayPermission) {
          // eslint-disable-next-line eqeqeq
          if (this.modelStrategistItems != null || this.modelStrategistItems != undefined) {
            this.modelStrategistItems.forEach(communityItem => {
              // eslint-disable-next-line eqeqeq
              if (this.CommunityStrategies.strategists != null || this.CommunityStrategies.strategists != undefined) {
                // eslint-disable-next-line eqeqeq
                if (this.CommunityStrategies.strategists.strategistIds != null || this.CommunityStrategies.strategists.strategistIds != undefined) {
                  // eslint-disable-next-line eqeqeq
                  if (Object.keys(this.CommunityStrategies.strategists.strategistIds.filter(x => x == communityItem.value)).length > 0) {
                    this.selectedStrategitNames.push(communityItem.label);
                  }
                }
              }
            });
          }
          if (Object.keys(this.communities).length > 0) {
            this.selecetdModel = 'Selected Model';
            this.communities.filter(x => x.isSelected).forEach(communitiesItem => {
              this.selectedCommunityNames.push(communitiesItem.community);
            });
          } else {
            this.selecetdModel = 'All';
          }
        }
        this.selectedStrategistItems = this.CommunityStrategies.strategists.strategistIds;
        this.modelAccessLevel = this.CommunityStrategies.strategists.modelAccessLevel;
      });
  }

  /**On strategist drop down change */
  private onStrategistChange(selectedVal) {
    // eslint-disable-next-line eqeqeq
    if (selectedVal != null) {
      if (Object.keys(selectedVal).length > 0) {
        /** push the array list values */
        this.selectedStrategistItems = selectedVal;
        this.modelAccessLevel = 1;
        this.CommunityStrategies.strategists.strategistIds = selectedVal;
        this.CommunityStrategies.strategists.communityModels = [];
        this.CommunityStrategies.strategists.modelAccessLevel = this.modelAccessLevel;
        this.CommunityStrategies.strategists.communityModels = [];
      } else {
        /** push the [] */
        this.selectedStrategistItems = null;
        this.modelAccessLevel = null;
        this.communities = [];
        this.CommunityStrategies.strategists.strategistIds = [];
        this.CommunityStrategies.strategists.modelAccessLevel = null;
        this.CommunityStrategies.strategists.communityModels = [];
      }
    } else {
      /** push null as is */
      this.selectedStrategistItems = null;
      this.modelAccessLevel = null;
      this.communities = [];
      this.CommunityStrategies.strategists.strategistIds = null;
      this.CommunityStrategies.strategists.modelAccessLevel = null;
      this.CommunityStrategies.strategists.communityModels = null;
    }
  }

  /** On model access level drop down change*/
  private onModelAccessLevelChange(value) {
    // eslint-disable-next-line radix
    this.modelAccessLevel = parseInt(value);
    this.communities = [];
    this.bindCommunities();
  }

  /**on model change bind communities */
  private bindCommunities() {
    this.selectedStrategistItems.forEach(element => {
      this.getCommunities(element);
    });
  }

  /**Fires on community check box check  */
  private onCommunityChkChange(event) {
    // eslint-disable-next-line radix
    const id = parseInt(event.target.value);
    // eslint-disable-next-line eqeqeq
    if (event.currentTarget.checked == true) {
      this.CommunityStrategies.strategists.communityModels.push(id);
    } else {
      this.CommunityStrategies.strategists.communityModels.splice(this.CommunityStrategies.strategists.communityModels.indexOf(id), 1);
    }
  }

  /**get communities by strategistIds */
  private getCommunities(strategistId) {
    // eslint-disable-next-line eqeqeq
    if (this.modelAccessLevel == 2) {
      this._communityService.getCommunityModelByStrategistId(strategistId)
        .subscribe(model => {
          model.forEach(element => {
            this.communities.push({
              communityId: element.id, community: element.name, strategistId: element.strategistId, isSelected: false
            });
          });
        });
    }
    this.CommunityStrategies.strategists.modelAccessLevel = this.modelAccessLevel;
    this.CommunityStrategies.strategists.communityModels = [];
  }

}
