import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'eclipse-ag-chip-renderer',
  template: `
    <a *ngIf="!hideIfZero || (hideIfZero && !!params.value)" (click)="chipClicked()" href="javascript:void(0);">
      <p-chip label="{{params.value}}" [styleClass]="styleClass" [style]="{'margin': '0px'}" [icon]="icon(params)"></p-chip>
    </a>
  `
})
export class ChipRendererComponent implements ICellRendererAngularComp {
  public params: any;

  public hideIfZero: boolean = false;
  public styleClass = '';
  private _defaultIcon: string = '';
  /**
   * Defines the icon displayed in the chip.
   * Can be either a string or a function that returns a string.
   * @param params
   */
  public icon = (params) => { return this._defaultIcon; };

  agInit(params: any): void {
    this.params = params;
    if (!!params.styleClass) {
      if(params.styleClass instanceof Function) {
        this.styleClass = params.styleClass(params);
      } else {
        this.styleClass = params.styleClass;
      }
    }
    if (!!params.icon) {
      if(params.icon instanceof Function) {
        this.icon = params.icon;
      } else {
        this._defaultIcon = params.icon;
      }
    }
    if (params.hideIfZero !== undefined){
      this.hideIfZero = !!params.hideIfZero;
    }
  }

  chipClicked() {
    if (!!this.params.onClick) {
      this.params.onClick(this.params);
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
