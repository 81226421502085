import { Injectable } from '@angular/core';
import { AuthService, OEHttpClient, SessionHelper } from '../core';
import { IGlobalSearchResults } from '../models/globalsearch';
import * as Consts from '../libs/app.constants';
import {  map } from 'rxjs/operators';
import { SplitIoService } from '../core/feature-flag/splitio.service';
import { EditorEntityType } from '../shared/entity-editor';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  private _globalSearchEndpoint = 'v2/globalSearch/globalSearchList';

  constructor(private _httpClient: OEHttpClient, private readonly sessionHelper: SessionHelper) {
  }

  getDashboardPages() {
    const dashboardPages: IGlobalSearchResults[] = [
      {
        id: 1,
        name: 'Dashboard Overview', dispalyText: 'Dashboard Overview',
        category: 'page',
        module: '',
        uiClass: 'fa-paper-plane',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/dashboard'
      }
    ];

    return dashboardPages;
  }

  getPortfolioPages() {
    const portfolioPages: IGlobalSearchResults[] = [
      {
        id: 3,
        name: 'Portfolio: All Portfolios', dispalyText: 'Portfolio: All Portfolios',
        category: 'page',
        module: 'Portfolio',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/portfolio/list'
      }];
    return portfolioPages;
  }

  getAccountPages() {
    const accountPages: IGlobalSearchResults[] = [
      {
        id: 6,
        name: 'Accounts: All Accounts', dispalyText: 'Accounts: All Accounts',
        category: 'page',
        module: 'Accounts',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/account/list'
      }
    ];

    return accountPages;
  }

  getModelMaintenancePages() {
    const modelMaintenancePages: IGlobalSearchResults[] = [
      {
        id: 10,
        name: 'Model Maintenance: All Models', dispalyText: 'Model Maintenance: All Models',
        category: 'page',
        module: 'Model Maintenance',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/model/list'
      },
      {
        id: 11,
        name: 'Model Maintenance: Model Details', dispalyText: 'Model Maintenance: Model Details',
        category: 'page',
        module: 'Model Maintenance',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/model/view'
      }
    ];
    return modelMaintenancePages;
  }

  getModelCreationPages() {
    const modelCreationPages: IGlobalSearchResults[] = [
      {
        id: 12,
        name: 'Model Creation: Model Information', dispalyText: 'Model Creation: Model Information',
        category: 'page',
        module: 'Model Creation',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/model/modelinfoadd'
      },
      {
        id: 13,
        name: 'Model Creation: Model Structure', dispalyText: 'Model Creation: Model Structure',
        category: 'page',
        module: 'Model Creation',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/model/modelinfoadd'
      }
    ];
    return modelCreationPages;
  }

  getSecuritiesPages() {
    const securitiesPages: IGlobalSearchResults[] = [
      {
        id: 15,
        name: 'Securities: All Securities', dispalyText: 'Securities: All Securities',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/security/maintenance/list'
      },
      {
        id: 16,
        name: 'Securities: Securities Detail', dispalyText: 'Securities: Securities Detail',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/security/maintenance/view'
      },
      {
        id: 17,
        name: 'Securities: All Securities Set', dispalyText: 'Securities: All Securities Set',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/strategy/securityset/list'
      },
      {
        id: 18,
        name: 'Securities: Securities Set Detail', dispalyText: 'Securities: Securities Set Detail',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/strategy/securityset/view'
      },
      {
        id: 19,
        name: 'Securities: Category Maintenance', dispalyText: 'Securities: Category Maintenance',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/security/asset/category'
      },
      {
        id: 20,
        name: 'Securities: Class Maintenance', dispalyText: 'Securities: Class Maintenance',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/security/asset/class'
      },
      {
        id: 21,
        name: 'Securities: Sub Class Maintenance', dispalyText: 'Securities: Sub Class Maintenance',
        category: 'page',
        module: 'Securities',
        uiClass: 'fa-sticky-note',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/security/asset/subclass'
      }
    ];
    return securitiesPages;
  }

  getAdministratorPages() {
    const administratorPages: IGlobalSearchResults[] = [
      {
        id: 23,
        name: 'Administrator: All Teams', dispalyText: 'Administrator: All Teams',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/manageusers/team/list'
      },
      {
        id: 24,
        name: 'Administrator: Team Details', dispalyText: 'Administrator: Team Details',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/manageusers/team/view'
      },
      {
        id: 25,
        name: 'Administrator: All Users', dispalyText: 'Administrator: All Users',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/manageusers/user/list'
      },
      {
        id: 26,
        name: 'Administrator: User Details', dispalyText: 'Administrator: User Details',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/manageusers/user/view'
      },
      {
        id: 27,
        name: 'Administrator: All Roles', dispalyText: 'Administrator: All Roles',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/manageusers/role/list'
      },
      {
        id: 28,
        name: 'Administrator: Role Details', dispalyText: 'Administrator: Role Details',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/manageusers/role/view'
      },
      {
        id: 29,
        name: 'Administrator: Firm Settings', dispalyText: 'Administrator: Firm Settings',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/preferences/firm'
      },
      {
        id: 30,
        name: 'Administrator: Custodian Settings', dispalyText: 'Administrator: Custodian Settings',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/preferences/custodian'
      },
      {
        id: 31,
        name: 'Administrator: Team Settings', dispalyText: 'Administrator: Team Settings',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/preferences/team'
      },
      {
        id: 32,
        name: 'Administrator: Model Settings', dispalyText: 'Administrator: Model Settings',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/preferences/model'
      },
      {
        id: 33,
        name: 'Administrator: Portfolio Settings', dispalyText: 'Administrator: Portfolio Settings',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/preferences/portfolio'
      },
      {
        id: 34,
        name: 'Administrator: Account Settings', dispalyText: 'Administrator: Account Settings',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/preferences/account'
      },
      {
        id: 35,
        name: 'Administrator: All Custodians', dispalyText: 'Administrator: All Custodians',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/custodian/list'
      },
      {
        id: 36,
        name: 'Administrator: Custodian Details', dispalyText: 'Administrator: Custodian Details',
        category: 'page',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/admin/custodian/view'
      }
    ];
    return administratorPages;
  }

  getTradePages() {
    const tradesPages: IGlobalSearchResults[] = [
      {
        id: 37,
        name: 'Trades: Trade Orders',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/list',
        dispalyText: 'Trades: Trade Orders',
      },
      {
        id: 38,
        name: 'Trades: Awaiting Acceptance', dispalyText: 'Trades: Awaiting Acceptance',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/awaiting'
      },
      {
        id: 39,
        name: 'Trades: Trade Files', dispalyText: 'Trades: Trade Files',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/tradefile'
      },
      {
        id: 40,
        name: 'Trades: Blocks', dispalyText: 'Trades: Blocks',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/blocks'
      },
      {
        id: 41,
        name: 'Trades: Dashboard', dispalyText: 'Trades: Dashboard',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/dashboard'
      },
      {
        id: 42,
        name: 'Trades: Allocations', dispalyText: 'Trades: Allocations',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/allocations'
      },
      {
        id: 43,
        name: 'Trades: Pending', dispalyText: 'Trades: Pending',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/pending'
      },
      {
        id: 44,
        name: 'Trades: Closed', dispalyText: 'Trades: Closed',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/closed'
      },
      {
        id: 45,
        name: 'Trades: Instances', dispalyText: 'Trades: Instances',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/instances'
      },
      {
        id: 46,
        name: 'Trades: Deleted', dispalyText: 'Trades: Deleted',
        category: 'page',
        module: 'Trades',
        uiClass: 'fa-exchange',
        type: 'page',
        value: 0,
        routeUrl: '/eclipse/tradeorder/deleted'
      }
    ];

    return tradesPages;
  }

  getGlobalDataSearchResults(searchString: string, selectedOptions: string[]) {
    const observableBatch = [];
    let searchResults: IGlobalSearchResults[] = []; // this.getGlobalSearchModulePageResults(searchString);

    // Also Get List of Pages of each module, filter them and add to results

    // Dashboard Pages
    const dashboardPageList = this.getDashboardPages();
    const dashboardModulePages = dashboardPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
    dashboardModulePages.forEach(modulePage => {
      searchResults.push(modulePage);
    });

    let searchParams = 0;
    const portfolioPriv = this.sessionHelper.getPermission(Consts.PRIV_PORTFOLIOS);
    if ((selectedOptions.indexOf('All') !== -1 || selectedOptions.indexOf('Portfolios') !== -1) && portfolioPriv.canRead) {
      searchParams += 1;
      // portfolio module
      const portfolioModule = [];
      const portfolioModuleSearch = portfolioModule.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      portfolioModuleSearch.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Portfolio Pages
      const portfolioPageList = this.getPortfolioPages();
      const portoflioModulePages = portfolioPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      portoflioModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });
    }

    const accountPriv = this.sessionHelper.getPermission(Consts.PRIV_ACCOUNTS);
    if ((selectedOptions.indexOf('All') !== -1 || selectedOptions.indexOf('Accounts') !== -1) && accountPriv.canRead) {
      searchParams += 2;
      // account module
      const accountModule = [];

      const accountModuleSearch = accountModule.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      accountModuleSearch.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Account Pages
      const accountPageList = this.getAccountPages();
      const accountModulePages = accountPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      accountModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });
    }

    if (selectedOptions.indexOf('All') !== -1 || selectedOptions.indexOf('Models') !== -1) {
      searchParams += 4;
      // model modules
      const modelModule = [
        {
          id: 6,
          name: 'Overview: Model Creation', dispalyText: 'Overview: Model Creation',
          category: 'module',
          module: 'Model Creation',
          uiClass: 'fa-edit',
          type: 'module',
          value: 0,
          routeUrl: '/eclipse/model/modelinfoadd'
        }];


      const modelModuleSearch = modelModule.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      modelModuleSearch.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Model Maintenance Pages
      const modelMaintenacePageList = this.getModelMaintenancePages();
      let modelModulePages = modelMaintenacePageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      modelModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Model Creation Pages
      const modelCreationPageList = this.getModelCreationPages();
      modelModulePages = modelCreationPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      modelModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Model Simple Search
      // let modelSearch = this._httpClient.getData(this._modelEndPoint + '?search=' + searchString)
      //   ;
      // observableBatch.push(modelSearch);
    }

    if (selectedOptions.indexOf('All') !== -1 || selectedOptions.indexOf('Administrator') !== -1) {
      // administrator module
      const administratorModule = [{
        id: 7,
        name: 'Overview: Administrator', dispalyText: 'Overview: Administrator',
        category: 'module',
        module: 'Administrator',
        uiClass: 'fa-building',
        type: 'module',
        value: 0,
        routeUrl: '/eclipse/admin/dashboard'
      }];
      const administratorModuleSearch = administratorModule.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      administratorModuleSearch.forEach(modulePage => {
        searchResults.push(modulePage);
      });


      // Administrator Pages
      const administratorPageList = this.getAdministratorPages();
      const administratorModulePages = administratorPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      administratorModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });
    }

    if (selectedOptions.indexOf('All') !== -1 || selectedOptions.indexOf('Trades') !== -1) {
      // trades module
      const tradesModule = [{
        id: 4,
        name: 'Overview: Trade Order',
        category: 'module',
        module: 'Trade Order',
        uiClass: 'fa-exchange',
        type: 'module',
        value: 0,
        routeUrl: '/eclipse/tradeorder/list',
        dispalyText: 'Overview: Trade Order'
      }];
      const tradesModuleSearch = tradesModule.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      tradesModuleSearch.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Trades Pages
      const tradesPageList = this.getTradePages();
      const tradesModulePages = tradesPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      tradesModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });
    }

    if (selectedOptions.indexOf('All') !== -1 || selectedOptions.indexOf('Securities') !== -1) {
      // securities module
      const securitiesModule = [];

      const securitiesModuleSearch = securitiesModule.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      securitiesModuleSearch.forEach(modulePage => {
        searchResults.push(modulePage);
      });

      // Securities Pages
      const securitiesPageList = this.getSecuritiesPages();
      const securityModulePages = securitiesPageList.filter(element => { return element.name.toLowerCase().indexOf(searchString) > -1; });
      securityModulePages.forEach(modulePage => {
        searchResults.push(modulePage);
      });
    }

    return this._httpClient.getData(`${this._globalSearchEndpoint}?searchParameters=${searchParams}&includeSleeves=true&search=${searchString}&includeDisabled=true`)
        .pipe(map((globalSearchResults: any) => {
          // any portfolios
          if (globalSearchResults.portfolios && globalSearchResults.portfolios.length > 0) {
            globalSearchResults.portfolios.forEach(result => {
              let routeUrl;
              if ((!portfolioPriv.canUpdate)) {
                routeUrl = '/eclipse/portfolio/search/';
              } else {
                routeUrl = '/eclipse/portfolio/detailSearch/';
              }
              const portfolioData = <IGlobalSearchResults>{
                id: result.id,
                name: result.name, dispalyText: `${result.id} : ${result.name}`,
                category: 'portfoliodata',
                module: 'portfolio',
                uiClass: 'fa-cubes',
                type: 'data',
                value: 0,
                routeUrl: routeUrl + result.id,
                entityEditorConfig: {
                  entityType: EditorEntityType.Portfolio,
                  data: {
                    id: result.id,
                    editMode: !!portfolioPriv.canUpdate,
                  }
                }
              };

              searchResults = searchResults.concat(portfolioData);
            });
          }

          // any accounts
          if (globalSearchResults.accounts && globalSearchResults.accounts.length > 0) {
            globalSearchResults.accounts.forEach(result => {
              const accountData = <IGlobalSearchResults>{
                id: result.id,
                name: result.name, dispalyText: `${result.accountId} : ${result.name} (${result.accountNumber})`,
                category: 'accountdata',
                module: 'account',
                uiClass: 'fa-briefcase',
                type: 'data',
                value: 0,
                routeUrl: `/eclipse/account/search/${result.id}`
              };

              accountData.entityEditorConfig = {
                entityType: EditorEntityType.Account,
                data: {
                  id: result.id,
                  editMode: false //always go to the Account View page
                }
              };

              searchResults = searchResults.concat(accountData);
            });
          }

          // any models
          if (globalSearchResults.models && globalSearchResults.models.length > 0) {
            globalSearchResults.models.forEach(result => {
              const modelData = <IGlobalSearchResults>{
                id: result.id,
                name: result.name, dispalyText: `${result.id} : ${result.name}`,
                category: 'modeldata',
                module: 'models',
                uiClass: 'fa-flip-vertical fa-code-branch',
                type: 'data',
                value: 0,
                routeUrl: `/eclipse/model/search/${result.id}`
              };
              searchResults = searchResults.concat(modelData);
            });
          }
          return searchResults;
      }));
    }
}
